
import {switchMap} from 'rxjs/operators';

import {debounceTime} from 'rxjs/operators';
import {Component, TemplateRef, Input} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { Notifications } from '../notifications';
import { NotificationsService } from '../services/notifications.service';
@Component({
  selector: 'app-notifications-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [NotificationsService, DialogService]
})
export class NotificationsListComponent {
displayedColumns = [
// 'id',
// 'notifiable_id',
'title',
'message',
'created_at',
// 'data',
]
  dataSource = new NotificationsDataSource();
  notificationsList = new Array<Notifications>();
  notificationsFormGroup: FormGroup;
  notificationsSearchForm: FormGroup;
  isError: boolean = false;
  errorMessages = new Array<string>();
  modalRef: BsModalRef;
  notificationsSearchStr: string;
  per_page: number;
  total: number;
  constructor(private router: Router,
            private _formBuilder: FormBuilder,
            private modalService: BsModalService,
            private _snackBarService: MatSnackBar,
            private _dialogService: DialogService,
            private notificationsService: NotificationsService
            ) { }
  ngOnInit() {
    this.getResponse();
    this.notificationsFormGroup = this._formBuilder.group({
      id: ['', []],
      type: ['', []],
      notifiable_id: ['', []],
      notifiable_type: ['', []],
      data: ['', []],
      read_at: ['', []],
      });
    this.notificationsSearchForm = this._formBuilder.group({
      search: ['', []],
    });
    this.notificationsSearchForm.controls.search.valueChanges.pipe(
      debounceTime(400),
      switchMap(term => this.notificationsService.search(term
            )),).subscribe(
          response => {
          this.notificationsList = response.data;
          data = this.notificationsList;
          this.dataSource = new NotificationsDataSource();
          this.total = response.total;
          this.per_page = response.per_page;
        },
        error => {
          this._dialogService.openAlert({'title' : 'Error', 'message' : error});
        }
      );
  }
  getResponse(pageNo =1) {
    return this.notificationsService.getList(
          ).subscribe(
      response => {
     //  console.log(response);
     //  console.log(this.notificationsList);
        this.notificationsList = response.data.data;
        data = this.notificationsList;
        this.dataSource =response.data.data;
        this.total = response.data.total;
        this.per_page = response.data.per_page;
    },
      error => {
        this._dialogService.openAlert({'title' : 'Error', 'message' : error});
      }
    );
  }
  getResponseByPage(event) {
    this.getResponse(event.pageIndex + 1);
  }
openAddModal(template: TemplateRef<any>){
    this.notificationsFormGroup.reset();
    this.modalRef = this.modalService.show(template);
  }
  openEditModal(template: TemplateRef<any>,  aRow){
    this.notificationsFormGroup.reset();
    this.notificationsFormGroup.patchValue(aRow);
    this.notificationsFormGroup.patchValue({data:aRow.data.data.message});
    this.modalRef = this.modalService.show(template);
  }
  submitNotificationsForm(){
    if(!this.notificationsFormGroup.valid){
  		this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(this.notificationsFormGroup);
      appGlobals.markFormGroupTouched(this.notificationsFormGroup);
    }
    else{
      const formData = this.notificationsFormGroup.value;
      if(formData.id){
	      this.notificationsService.updateNotifications(formData).subscribe(
          response => {
            if(response.status === 200){
              const foundIndex = this.notificationsList.findIndex(x => x.id === formData.id);
              this.notificationsList[foundIndex] = formData;
              this.dataSource = new NotificationsDataSource();
              this.modalRef.hide();
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
	    }else{
	      this.notificationsService.addNotifications(formData).subscribe(
          response  =>  {
            if(response.status === 200){
              this.notificationsList.unshift(response.data);
              data = this.notificationsList;
              this.dataSource = new NotificationsDataSource();
              this.modalRef.hide();
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
      }
    }
  }
  deleteNotifications(id){
  }
  editNotifications(id){
    this.router.navigate(['/notifications/edit', id]);
  }
  viewNotifications(id){
    this.router.navigate(['/notifications/view', id]);
  }
}
let data: Notifications[];
export class NotificationsDataSource extends DataSource<any> {
  connect(): Observable<Notifications[]> {
    return of(data);
  }
  disconnect() { }
}
