import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class ConsignmentNotesService {
  geteWayBillDetailsFromExternal(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/geteWayBillDetailsFromExternal','POST', 0, data);
  }
  syncConsignmentNote(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/syncConsignmentNote','POST', 0, data);
  }
  syncConsignmentNoteArrival(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/syncConsignmentNoteArrival','POST', 0, data);
  }
  syncConsignmentNoteStockUpdate(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/syncConsignmentNoteStockUpdate','POST', 0, data);
  }
  syncConsignmentNotePod(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/syncConsignmentNotePod','POST', 0, data);
  }

  getListWithTrackingPod(data: any): any {
    return this.serviceHttp.getResponse('consignmentNotes/withTrackingPod','POST', 0, data);
  }
  getListWithTrackingBill(data: any): any {
    return this.serviceHttp.getResponse('consignmentNotes/withTrackingBill','POST', 0, data);
  }
  updateSkipEWay(data: any) : any {
    return this.serviceHttp.getResponse('consignmentNotes/updateSkipEWay','POST', 0, data);
  }

  constructor(private serviceHttp:HttpService) { }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel('consignmentNotes/exportList', 'POST', 0, data);
  }
  podstatusreport(data): any {
    return this.serviceHttp.getResponseExcel('podstatusreport', 'POST', 0, data);
  }
  updateInvoice(data): any {
    return this.serviceHttp.getResponse('consignmentNotes/updateInvoice', 'POST', 0, data);
  }
  updateInvoicePaid(data): any {
    return this.serviceHttp.getResponse('consignmentNotes/updatePayment', 'POST', 0, data);
  }
  markSubmitted(data): any {
    return this.serviceHttp.getResponse('consignmentNotes/markSubmitted', 'POST', 0, data);

  }
  getList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/page','POST', 0, data);
  }
  getConsignmentNotesById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('consignmentNotes/'+ id, 'GET');
  }
  downloadConsignmentAsPDF(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponseExcel('generate-pdf/'+ id, 'GET');
    // return this.serviceHttp.getResponseExcel('generateKN-pdf/'+ id, 'GET');
    //generateKN-pdf
  }
  previewConsignmentAsPDF(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponseExcel('preview-pdf/'+ id, 'GET');
    // return this.serviceHttp.getResponseExcel('generateKN-pdf/'+ id, 'GET');
    //generateKN-pdf
  }
  previewConsignmentAsHtml(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('testgenerateKN-pdf/'+ id, 'GET');

  }
  downloadKNConsignmentAsPDF(id): Observable<ResponseModel> {
    // return this.serviceHttp.getResponseExcel('generate-pdf/'+ id, 'GET');
    return this.serviceHttp.getResponseExcel('generateKN-pdf/'+ id, 'GET');
    //generateKN-pdf
  }
  addConsignmentNotes(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('consignmentNotes', 'POST', 0, data);
  }
  sendConsignmentAsPDF(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/sendConsignmentAsPDF', 'POST', 0, data);
  }



  search(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/search', 'POST', 0, data);
  }
  searchCNoteList(search:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/search', 'POST', 0,{"search":search});
  }
  updateConsignmentNotes(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/'+data.id, 'PUT', 0, data);
  }
  lookupConsignmentNo(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentno/search', 'POST', 0, {search:str});
  }
  lookupConsignmentNoData(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentno/search', 'POST', 0, data);
  }
  podUpload(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('podupload', 'POST', 0, data);
  }
  updateStatus(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentNotes/updateStatusPost', 'POST', 0, data);
  }
}
