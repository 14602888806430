import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class BidsService {
  constructor(private serviceHttp:HttpService) { }
  getBids(pageNo:number, bid_bid_details_id?: any, bid_selected?: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bids/page', 'POST', 0, {page:pageNo, bid_details_id:bid_bid_details_id, selected: bid_selected});
  }
  getList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails?page='+pageNo, 'GET');
  }
  getBidNameList(): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('groups', 'GET');
  }
  getBidsById(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bid/selecteduser/'+ id, 'GET');
  }
  getBidById(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/'+ id, 'GET');
  }
  addBids(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bidDetails', 'POST', 0, data);
  }
  updateBids(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/'+data.id, 'PUT', 0, data);
  }
  searchBidsList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/search', 'POST', 0, {search:str});
  }
  searchBidUsers(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('biduser/search', 'POST', 0, {search:str});
  }
  bidUpdate(data, id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bids/user/selected/'+ id, 'PUT', 0, data);
  }
  bidGroupUpdate(data, id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('biduser/'+ id, 'PUT', 0, data);
  }
  getBidsUserById(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('biduser/'+ id, 'GET');
  }
  deleteBids(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/delete/'+ id, 'DELETE');
  }
  updateBidTruck(data){
    return this.serviceHttp.getResponse('bids/addtruck', 'POST', 0, data);
  }
}
