import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class ConsignmentTripTrackingsService {
  constructor(private serviceHttp:HttpService) { }
  getList(pageNo = 1
    ,consignmentTripTrackings_trips_id?: any
    ,consignmentTripTrackings_consignment_notes_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/page', 'POST', 0, {page: pageNo
        ,trips_id: consignmentTripTrackings_trips_id
        ,consignment_notes_id: consignmentTripTrackings_consignment_notes_id
      });
  }
  search(str:string, consignmentTripTrackings_trips_id?: any,
    consignmentTripTrackings_consignment_notes_id?: any ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/search', 'POST', 0,
    {search:str, trips_id: consignmentTripTrackings_trips_id, consignment_notes_id: consignmentTripTrackings_consignment_notes_id
    });
  }
  getConsignmentTripTrackingsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('consignmentTripTrackings/'+ id, 'GET');
  }
  addConsignmentTripTrackings(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('consignmentTripTrackings', 'POST', 0, data);
  }
  updateConsignmentTripTrackings(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/' + data.id, 'PUT', 0, data);
  }
  searchConsignmentTripTrackings(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/search', 'POST', 0, {search:str});
  }
  deleteTrackings(id:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/'+id, 'DELETE');
  }
  getListByTrip(id:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/Trip/'+id, 'GET');
  }
  updateLocation(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/locationUpdate', 'POST', 0, data);
  }
  updateStatus(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/statusUpdate', 'POST', 0, data);
  }
  getLastUpdate(trips_id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('consignmentTripTrackings/getLastUpdate/'+ trips_id, 'GET');
  }
  TrackCurrentTruck(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tripstracking/'+ id, 'GET');
}
}
