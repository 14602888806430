<div class=" content_area_list container inne_area_list mobScroll mob-p-default ">
  <div class="row">
	   <div class="example-header col-sm-9">
		<mat-form-field floatPlaceholder="never">
		  <input matInput #filter placeholder="Search Notifications">
            <form [formGroup]="notificationsSearchForm">
        <input matInput #filter placeholder="Search Notifications" formControlName="search">
      </form>
		</mat-form-field>
	   </div>
	   <!--<div class="col-sm-3 text-center mTop15">
     <button mat-raised-button color="primary"  (click)="openAddModal(editNotifications)">Add notifications</button>
     --> <!--<button mat-raised-button color="primary" [routerLink]="['/notifications/add']">Add New Notifications</button> -->
	   <!--</div> -->
  </div>
  <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
    <mat-table #table class="table_list" [dataSource]="dataSource">
    <!-- <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="notifiable_id">
        <mat-header-cell *matHeaderCellDef> Notifiable ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.notifiable_type}} {{element.notifiable_id}} </mat-cell>
      </ng-container> -->
    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.data.title}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.data.message}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.created_at?.toLocaleString('en-IN') }} </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="data">
      <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.data.data}} </mat-cell>
    </ng-container> -->
	 <!-- edit Column -->
    <!--<ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
      <mat-cell *matCellDef="let element">
      <a mat-icon-button color="primary" (click)="openEditModal(editNotifications, element)" ><i class="ti-pencil-alt"></i></a>
	    --> <!-- <a mat-icon-button color="primary" [routerLink]="['/notifications/edit',element.id]" routerLinkActive="active"><i class="ti-pencil-alt"></i></a> -->
	  <!--</mat-cell>
    </ng-container>-->
	 <!-- view Column
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="primary"  [routerLink]="['/notifications/view',element.id]" routerLinkActive="active"><i class="fa fa-eye"></i></a>
	  </mat-cell>
    </ng-container>
	-->
	<!-- Delete Column
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef> Delete</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="warn" (click)="deleteNotifications(element.id)"><i class="ti-trash"></i></a>
	  </mat-cell>
    </ng-container>
    -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total"
              [pageSize]="per_page" (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
<ng-template #editNotifications>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Notifications</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
 <!--    <div class="alert alert-danger" *ngIf="isError">
            <strong>Alert!</strong>
            <ul>
              <li *ngFor="let message of errorMessages">{{message}}</li>
            </ul>
          </div> -->
        <form [formGroup]="notificationsFormGroup">
              	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"  required   matInput placeholder="Type"  formControlName="type">
			<mat-error *ngIf="!notificationsFormGroup.get('type').valid && notificationsFormGroup.get('type').touched">
                          <span *ngIf="notificationsFormGroup.get('type').errors.required"> Type is required</span>
                        </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="number"  required   matInput placeholder="Notifiable Id"  formControlName="notifiable_id">
			<mat-error *ngIf="!notificationsFormGroup.get('notifiable_id').valid && notificationsFormGroup.get('notifiable_id').touched">
                          <span *ngIf="notificationsFormGroup.get('notifiable_id').errors.required"> Notifiable Id is required</span>
                        </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"  required   matInput placeholder="Notifiable Type"  formControlName="notifiable_type">
			<mat-error *ngIf="!notificationsFormGroup.get('notifiable_type').valid && notificationsFormGroup.get('notifiable_type').touched">
                          <span *ngIf="notificationsFormGroup.get('notifiable_type').errors.required"> Notifiable Type is required</span>
                        </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                    <textarea matInput placeholder="Data" value="" formControlName="data"  required   autoResize="true"></textarea>
			<mat-error *ngIf="!notificationsFormGroup.get('data').valid && notificationsFormGroup.get('data').touched">
                          <span *ngIf="notificationsFormGroup.get('data').errors.required"> Data is required</span>
                        </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Read At"  formControlName="read_at">
			<mat-error *ngIf="!notificationsFormGroup.get('read_at').valid && notificationsFormGroup.get('read_at').touched">
                          <span *ngIf="notificationsFormGroup.get('read_at').errors.required"> Read At is required</span>
                        </mat-error>
			</mat-form-field>
		</div>
</div>
              <div class="row mBtm10">
                  <div class="col-sm-6 text-left">
                   <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
                  </div>
                        <div class="col-sm-6 text-right">
                     <button mat-raised-button color="primary" (click)="submitNotificationsForm()">Submit</button>
                  </div>
                </div>
          </form>
      </div>
  </ng-template>
