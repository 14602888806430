import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    if (!updates.isEnabled) {
   //  console.log(' check service swUpdate is not enabled');
    }
    else{
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(0.5 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      // console.log('check for update 30 mins ');
      // console.log(appIsStable$);
      // console.log(everySixHours$);
      // console.log(everySixHoursOnceAppIsStable$);
      // everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
      everySixHours$.subscribe(() => updates.checkForUpdate());

    }

  }
}
