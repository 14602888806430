import { Users } from '../users/users';
import { PartnerContacts } from '../partnerContacts/partnerContacts';
import { PartnerBanks } from '../partnerBanks/partnerBanks';
import { Validators } from '@angular/forms';
import { pan_pattern, gst_pattern, safeDate } from '../../core/globalfunctions';
import { PartnerVehicles } from '../partnerVehicles/partnerVehicles';
export class Partners {
  id : number;
  partner_type_cd : string;
  name : string;
  partner_company_name: string;
  pan_no : string;
  street : string;
  map_locality_street: string;
  vicinity: string;
  place_name: string;
  place_id: string;


  city : string;
  state_cd : string;
  pincode : string;
  partner_tier_cd : string;
  partner_status_cd : string;
  verified_by : number;
  verified_at : Date;
  webxpress_partner_code : string;
  users_id : number;
  partner_category_cd : string;
  website : string;
  operations_executive_id : number;
  base_location : string;
  base_location_lat : string;
  base_location_long : string;
  verification_status : string;
  gst_no : string;
  remarks : string;
  users: Users;
  partner_contacts: PartnerContacts[];
  partner_banks: PartnerBanks[];
  pan_card_scan: string;
  tds_declaration_scan: string;
  advance_percent: number;
  partner_vehicles: PartnerVehicles[];
  ops_executive_name: string;
  ops_executive: Users;
  thc_hire_amt_sum: number;
  thc_paid_amt_sum: number;
  truck_hire_challans_as_broker_count: number;
  truck_hire_challans_count: number;
  trucks_count: number;
  truck_hire_challans_as_broker_active_count: number;
  truck_hire_challans_active_count: number;
  thc_broker_hire_amt_sum: number;
  thc_broker_paid_amt_sum: number;
  static commandsAllIndex = [
  ];
  static allStatuses = [
  ];
  constructor(json? : any){
    this.fill(json);
  }
  static toArray(jsons : any[]) : Partners[] {
      let partners : Partners[] = [];
      if (jsons != null) {
          for (let json of jsons) {
              partners.push(new Partners(json));
          }
      }
      return partners;
  }
  public fill(json? : any) {
    if (json != null) {
        this.id = json.id;
        this.partner_type_cd = json.partner_type_cd;
        this.name = json.name;
        this.pan_no = json.pan_no;
        this.gst_no = json.gst_no;
        this.street = json.street;
        this.map_locality_street = json.map_locality_street;
        this.vicinity = json.vicinity;
        this.place_name = json.place_name;
        this.place_id = json.place_id;
        this.city = json.city;
        this.state_cd = json.state_cd;
        this.pincode = json.pincode;
        this.partner_tier_cd = json.partner_tier_cd;
        this.partner_status_cd = json.partner_status_cd;
        this.verified_by = json.verified_by;
        safeDate(this, json,  'verified_at');

        this.webxpress_partner_code = json.webxpress_partner_code;
        if(this.webxpress_partner_code) {
         this.partner_company_name = this.webxpress_partner_code + ' ' + this.name;
        }
        else {
          this.partner_company_name = this.name;
        }
        this.users_id = json.users_id;
        this.partner_category_cd = json.partner_category_cd;
        this.website = json.website;
        this.operations_executive_id = json.operations_executive_id;
        this.base_location = json.base_location;
        this.base_location_lat = json.base_location_lat;
        this.base_location_long = json.base_location_long;
        this.remarks = json.remarks;
        if(json.users){
          this.users = new Users(json.users);
        }
        if(json.partner_contacts){
          this.partner_contacts = PartnerContacts.toArray(json.partner_contacts);
        }
        if(json.partner_banks){
          this.partner_banks = PartnerBanks.toArray(json.partner_banks);
        }
        this.pan_card_scan = json.pan_card_scan;
        this.tds_declaration_scan = json.tds_declaration_scan;
        this.advance_percent = json.advance_percent;
        if(json.partner_vehicles) {
          this.partner_vehicles = PartnerVehicles.toArray(json.partner_vehicles);
        }
        this.ops_executive_name = json.ops_executive_name;
        if(json.ops_executive) {
          this.ops_executive = new Users(json.ops_executive);
        }
        this.thc_hire_amt_sum =  +json.thc_hire_amt_sum;
        this.thc_paid_amt_sum =  +json.thc_paid_amt_sum;
        this.truck_hire_challans_as_broker_count =  +json.truck_hire_challans_as_broker_count;
        this.truck_hire_challans_count =  +json.truck_hire_challans_count;
        this.truck_hire_challans_as_broker_active_count =  +json.truck_hire_challans_as_broker_active_count;
        this.truck_hire_challans_active_count =  +json.truck_hire_challans_active_count;

        this.thc_broker_hire_amt_sum =  +json.thc_broker_hire_amt_sum;
        this.thc_broker_paid_amt_sum =  +json.thc_broker_paid_amt_sum;

    }
  }
}
export let partnersFormControls = {
  id: ['', []],
  partner_type_cd: ['', [Validators.required]],
  name: ['', [Validators.required, Validators.minLength(4)]],
  pan_no: ['', [Validators.pattern(pan_pattern)]],
  gst_no: ['', [Validators.pattern(gst_pattern)]],
  partner_tier_cd: ['CLASSIC', []],
  partner_status_cd: ['ACTIVE', []],
  verified_by: ['', []],
  verified_at: ['', []],
  verification_status:['',[]],
  webxpress_partner_code: ['', []],
  users_id: ['', []],
  partner_category_cd: ['General', []],
  website: ['', []],
  operations_executive_id: ['', [Validators.required]],
  ops_executive_name: ['', []],
  base_location: ['', []],
  base_location_lat: ['', []],
  base_location_long: ['', []],
  remarks: ['', []],
  tds_declaration_scan:['', []],
  pan_card_scan:['',[]],
  advance_percent: ['', []],
};
