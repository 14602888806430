import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { InvoicesService } from '../services/invoices.service';
import { Invoices, invoicesFormControls } from '../invoices';
import { ConsignmentNotes } from '../../consignmentNotes/consignmentNotes';
import { InvoiceLines, invoiceLinesFormControls } from '../../invoiceLines/invoiceLines';
import { Customers } from '../../customers/customers';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { CustomersService } from '../../customers/services/customers.service';
import { AppSettings } from '../../../core/config';
import { FileTransferService } from '../../../core/services/file-transfer.service';
@Component({
  selector: 'app-invoices-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  providers: [InvoicesService, DialogService, FileTransferService, ConsignmentNotesService, CustomersService]
})
export class InvoicesEditComponent implements OnInit{
  @Input('customers_id')
  public customers_id: number;
@Input('consignment_notes_id')
  public consignment_notes_id: number;
@Input('invoices_id')
  public invoices_id: number;
@Input('invoice_operation')
  public invoice_operation = 'generate';
@Input('entity')
  public entity = new Invoices();
@Input('cNoteBillIn')
  public cNoteBillIn: ConsignmentNotes;
@Output() public onEntitySaveComplete: EventEmitter<any> = new EventEmitter();
@Output() public onCloseCall: EventEmitter<any> = new EventEmitter();

@Output() public onEntitySaveError: EventEmitter<any> = new EventEmitter();
@ViewChild('editEntity', { static: true }) editEntity: TemplateRef<any>;
@ViewChild('modalHeader', { static: true }) modalHeader: TemplateRef<any>;

modalRef: BsModalRef;
currentTemplate:  TemplateRef<any>;
currentPage: number;
imageUrl: String = AppSettings.IMAGE_ENDPOINT

  editBill = true;
  entityFormGroup: FormGroup;
  entityType = Invoices;
  isError: boolean = false;
  errorMessages = new Array<string>();

  enableLineAdd: boolean;
  consignmentNotesList= new Array<ConsignmentNotes>(); //: ConsignmentNotes[];
  cNoteBill: ConsignmentNotes;
  invoiceLinesFormGroup: FormGroup;
  invoiceList: Invoices[];
  existingInvoiceLines: InvoiceLines[];
  customersList = new Array<Customers>();

  constructor(
            private router: Router,
            private route: ActivatedRoute,
            private _formBuilder: FormBuilder,
            private modalService: BsModalService,
            private _snackBarService: MatSnackBar,
            private _dialogService: DialogService,
            protected fileTransferService: FileTransferService,
            private entityService: InvoicesService,
            protected consignmentNotesService: ConsignmentNotesService,
            protected customersService: CustomersService,
    ) { }
  ngOnInit() {

    this.route.params.subscribe(params => {
          if(params.id){
            this.getResponse(params.id);
          }
    });

    // this.entityFormGroup = this._formBuilder.group(invoicesFormControls);
    // this.entityFormGroup.addControl(
    //   'invoice_lines',
    //   this._formBuilder.array([])
    // );
    this.invoiceLinesFormGroup = this._formBuilder.group(invoiceLinesFormControls);
    this.getNewEntityFormGroup();

    if(this.entity && this.entity.id){
      this.patchExistingEntity(this.entity);
    }
    else if(this.invoices_id){
      this.getResponse(this.invoices_id);
    }
    else if(this.cNoteBillIn) {
      // this.setConsignmentForBilling(this.cNoteBill);
      this.entity  = new Invoices();
   //  console.log(this.cNoteBillIn);
      this.customers_id = this.cNoteBillIn.customers_id;
      this.setCustomer(this.cNoteBillIn.bookings.customers);
      this.getEligibleConsignmentsForBilling();
      this.addConsignmentForBilling(this.cNoteBillIn);
    }
    else if(this.consignment_notes_id){
      this.getResponseConsignment(this.consignment_notes_id);
    }
    if(this.invoice_operation == 'generate') {
      this.currentTemplate = this.editEntity;
    }
  }
  setinvoiceStatusCd(event){
    this.entityFormGroup.patchValue({
      invoice_status_cd:event
    });
  }
  getResponse(id) {
    return this.entityService.getEntityById(id).subscribe(
      response => {
     //  console.log(response.data);
        this.entity  = new Invoices(response.data);
        this.patchExistingEntity(this.entity);
      }
    );
  }
  get getContentTemplate() {
    if (this.currentTemplate) {
      return this.currentTemplate;
    }
    return false;
  }
  closeModalGeneric(){
    if(this.modalRef &&  this.modalRef != null){
      const hideResult = this.modalRef.hide();
    }
    else if(this.currentTemplate) {
      this.currentTemplate = null;
    }
    this.onCloseCall.emit();
  }
  submitEntityForm(aData) {
 //  console.log(aData);
    if (!this.entityFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.entityFormGroup
      );
      appGlobals.markFormGroupTouched(this.entityFormGroup);
    } else {
      this.entity.fill(aData);
      if(this.entity.id){
          this.entityService.updateEntity(this.entity).subscribe(response => {
            if (response.status === 200) {
              this.onEntitySaveComplete.emit('Invoice updated');
              this.closeModalGeneric();
              // this.getResponse(this.currentPage);
              this._snackBarService.open('Bill saved', 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });

            } else {
              this._dialogService.openAlert({
                title: 'Error',
      message: response.message
              });
            }

          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
        );
      }
      else {
        this.entityService.addEntity(this.entity).subscribe(response => {
          if (response.status === 200) {
            this.onEntitySaveComplete.emit('Invoice added');
            this.closeModalGeneric();
            // this.getResponse(this.currentPage);
            this._snackBarService.open('Bill created', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });

          } else {
            this._dialogService.openAlert({
              title: 'Error',
    message: response.message
            });

          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
      );
      }

    }
  }
  getAllEntityStatuses(){
    return Invoices.allStatuses;
  }
  observeWebXpressBill() {
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.controls.customers_name,
      'customersList',
      'getCustomerSearchService',
      'setCustomerList',

    );
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.controls.webxpress_invoice_code,
      'invoiceList',
      'getInvoiceSearchService',
      'setInvoiceListForPayment',
      'webxpress_invoice_code', '', 'invoiceCheck'
    );
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.get('invoice_line_search').get('consignment_no_search'),
      'consignmentNotesList',
      'getConsignmentSearchService',
      'setConsignmentList', 'consignment_no'
    );
  }
  getCustomerSearchService(searchTerm) {
    // console.log('consignment search service ' + searchTerm);
    let aData  = {search:searchTerm}
    return this.customersService.lookupCustomerList(searchTerm);
  }
  setCustomerList(aData) {
    // console.log(aData);
    if (aData.data) {
      this.customersList = Customers.toArray(aData.data);
    }
  }
  getEligibleConsignmentsForBilling(){
    return this.getConsignmentSearchService().subscribe(
      response => {
     //  console.log(response.data);
        if (response.data) {
          this.consignmentNotesList = ConsignmentNotes.toArray(response.data);
        }
      }
    );
  }
  getConsignmentSearchService(searchTerm?) {
    // console.log('consignment search service ' + searchTerm);
    let aData  = {search:searchTerm, customers_id: this.customers_id,
    consignment_status_cd: ['POD GENERATED', 'ePOD UPLOADED', 'HARD POD RECEIVED', 'POD REVIEWED']};
 //  console.log(aData);
    return this.consignmentNotesService.lookupConsignmentNoData(aData);
  }
  setConsignmentList(aData) {
    // console.log(aData);
    if (aData.data) {
      this.consignmentNotesList = ConsignmentNotes.toArray(aData.data);
    }
    else {
      this.consignmentNotesList = [];
      if(aData.status == 300) {
        // this._dialogService.openAlert({ title: 'Error', message: aData.message });
      }
    }
  }
  getResponseConsignment(anId){
    return this.consignmentNotesService.getConsignmentNotesById(anId).subscribe(
      response => {
     //  console.log(response.data);
        this.setConsignmentForBilling(response.data);
        this.customers_id = this.cNoteBill.customers_id;
        this.setCustomer(this.cNoteBill.bookings.customers);
      }
    );
  }
  setInvoiceListForPayment(aData) {
 //  console.log(aData);
    if (aData.data) {
      this.invoiceList = this.entityType.toArray(aData.data);
    }
  }
  invoiceCheck(aInvoiceNo) {
    return (aInvoiceNo.length > 2) ;
    // console.log(aInvoiceNo);
    // if (aInvoiceNo && aInvoiceNo != null && aInvoiceNo.length > 14) {
    //   return true;
    // }
    // return false;
  }
  getInvoiceRefSearchService(searchTerm) {
    // console.log('invoice search service ' + searchTerm);
    return this.entityService.lookupEntity({ 'invoice_ref': searchTerm, customers_id: this.customers_id });
  }
  getInvoiceSearchService(searchTerm) {
    // console.log('invoice search service ' + searchTerm);
    return this.entityService.lookupEntity({ webxpress_invoice_code: searchTerm, customers_id: this.customers_id });
  }
  setCustomer(customer) {
    // console.log(invoice);
    this.customers_id = customer.id;
    this.entityFormGroup.patchValue({customers_id: customer.id});
    this.entityFormGroup.patchValue({customers_name: customer.name}, {emitEvent: false});

  }
  setInvoice(invoice) {
    // console.log(invoice);
    this.entity = new Invoices(invoice);
    //this.doPostEntityDetailResponse();
    this.patchExistingEntity(this.entity);

  }
  patchExistingEntity(aEntity){
    this.getNewEntityFormGroup();
    // console.log(this.entityFormGroup);
    // console.log(aEntity);
    this.entityFormGroup.patchValue(aEntity,{emitEvent: false});
    this.entityFormGroup.patchValue({customers_name: aEntity.customer.name},{emitEvent: false});
    if(!aEntity.invoice_lines){
      aEntity.invoice_lines  =  [];
    }
    this.existingInvoiceLines = aEntity.invoice_lines;
    let invoiceLinesFormArray = [];
    aEntity.invoice_lines.forEach(element => {
      let aLineFormGroup = this._formBuilder.group(invoiceLinesFormControls);
      aLineFormGroup.patchValue(element);
      invoiceLinesFormArray.push(aLineFormGroup);
    });
    this.entityFormGroup.setControl(
      'invoice_lines',
      this._formBuilder.array(invoiceLinesFormArray)
    );
    // console.log(invoiceLinesFormArray);
    // console.log(this.entityFormGroup);

    if(this.cNoteBill){
      if( (this.entity.customers_id != this.cNoteBill.customers_id)){
        alert('LR does not belong to same customer.');
        throw 'LR does not belong to same customer.';
      }
    }
 //  console.log(this.entityFormGroup);

    return this.entityFormGroup;
  }
  getNewEntityFormGroup(){
    this.entityFormGroup = this._formBuilder.group(invoicesFormControls);
    const currentDate = new Date();
    this.entityFormGroup.patchValue({ invoice_dt: currentDate });
    this.entityFormGroup.patchValue({
      invoice_status_cd: 'BILL GENERATED'
    });
    // this.entityFormGroup.addControl(
    //   'invoice_lines',
    //   this._formBuilder.array([])
    // );
    this.entityFormGroup.addControl(
      'invoice_line_search',
      this._formBuilder.group([])
    );
    // this.entityFormGroup.add

    this.entityFormGroup.controls['invoice_lines'] = this._formBuilder.array([]);
    this.entityFormGroup.controls['invoice_line_search'] = this._formBuilder.group([]);
    this.getInvoiceLineSearchForm();
    this.observeWebXpressBill();
    // console.log(this.entityFormGroup);
    return this.entityFormGroup;
  }
  getInvoiceLineSearchForm(){
    this.invoiceLinesFormGroup.reset();
    if (this.cNoteBill) {
      this.patchConsignmentNoteToInvoiceLineForm(this.cNoteBill);
    }
    this.entityFormGroup.controls['invoice_line_search'] = this.invoiceLinesFormGroup;
    // this.entityFormGroup.setControl(
    //   'invoice_line_search',
    //   this.invoiceLinesFormGroup
    // );

  }
  patchConsignmentNoteToInvoiceLineForm(aCNote: ConsignmentNotes){
    // this.invoiceLinesFormGroup =  this._formBuilder.group(invoiceLinesFormControls);
    const aInvoiceLine = this.createInvoiceLineFromConsignment(aCNote);
    this.invoiceLinesFormGroup.patchValue(aInvoiceLine);
    this.invoiceLinesFormGroup.patchValue({consignment_no_search: aCNote.consignment_no}, {emitEvent: false});
    return this.invoiceLinesFormGroup;
  }
  createInvoiceLineFromConsignment(aData: ConsignmentNotes){
    let aInvoiceLine = new InvoiceLines();
    if(this.entity.id){
      aInvoiceLine.invoices_id = this.entity.id;
    }
    aInvoiceLine.invoice_lines_status_cd = 'BILL GENERATED';

    aInvoiceLine.freight_amt = +aData.freight_amt;
    aInvoiceLine.loading_charges = +aData.loading_charges;
    aInvoiceLine.unloading_charges = +aData.unloading_charges;
    aInvoiceLine.detention_charges = +aData.detention_charges;
    aInvoiceLine.detention_days = +aData.detention_days;
    //aInvoiceLine.invoice_total_amt = this.cNote.freight_amt;
    aInvoiceLine.misc_charges_amt = +aData.misc_charges_amt;
    aInvoiceLine.invoice_line_amt = (+aData.freight_amt)
    + (+aData.loading_charges)
    + (+aData.unloading_charges)
    + (+aData.detention_charges)
    + (+aData.misc_charges_amt);
    aInvoiceLine.consignment_notes_id = aData.id;
    aInvoiceLine.consignment_note = aData;
    return aInvoiceLine;
  }
  getLocaleCurrency(anAmount: number){
    if(anAmount) {
      return (~~anAmount).toLocaleString('en-IN');
    }

  }
  getLocaleDate(aDate: Date){
    if(aDate) {
      return aDate.toLocaleDateString('en-IN')
    }
  }
  getFormattedDate(aDate: Date, aFormat = 'dd MMM hh:mm a'){
    return appGlobals.getFormattedDate(aDate, aFormat);
  }

  setConsignmentForBilling(aData){
    // console.log(aData);
    // console.log(this.consignmentNotesList);
    this.cNoteBill  = new ConsignmentNotes(aData);
    this.customers_id = this.cNoteBill.customers_id;
    // this.invoiceLinesFormGroup =  this._formBuilder.group(invoiceLinesFormControls);
    if (this.cNoteBill) {
      this.patchConsignmentNoteToInvoiceLineForm(this.cNoteBill);
    }
  }
  addConsignmentForBilling(aData){
    this.cNoteBill  = new ConsignmentNotes(aData);
    if(this.cNoteBill != null && aData != null && this.cNoteBill.consignment_no ) {
      const aInvoiceLine = this.createInvoiceLineFromConsignment(this.cNoteBill);
      aInvoiceLine.invoices_id = this.entity.id;
   //  console.log(aInvoiceLine);
      let aLineFormGroup = this._formBuilder.group(invoiceLinesFormControls);
      aLineFormGroup.patchValue(aInvoiceLine);
      let  invoiceLinesFormArray = <FormArray>this.entityFormGroup.controls.invoice_lines;
      invoiceLinesFormArray.push(aLineFormGroup);
      this.entityFormGroup.controls.invoice_lines = invoiceLinesFormArray;

      let invTotal = this.entityFormGroup.get('invoice_total_amt').value;
      if(!invTotal) {
        invTotal = 0;
      }
      const   aTotal = invTotal  + (+aInvoiceLine.invoice_line_amt || 0);
      this.entityFormGroup.patchValue({invoice_total_amt: aTotal});

      if(!this.entity.invoice_lines){
        this.entity.invoice_lines  =  [];
      }
      this.entity.invoice_lines.push(aInvoiceLine);
      this.entity.invoice_total_amt = aTotal;
      // this.entity.invoice_total_amt
      this.cNoteBill= null;
      this.getInvoiceLineSearchForm();
      // this.observeWebXpressBill();
    }
    else {
      alert('Please select consignment to add');
    }

    // console.log(this.entity);
  }
  uploadInvoice(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'invoice_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
        this.entity['invoice_scan'] = response.data.filename;
      });
  }
  uploadSubmission(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'submission_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          submission_scan: response.data.filename
        });
        this.entity['submission_scan'] = response.data.filename;
      });
  }
  uploadPayment(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'payment_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          payment_scan: response.data.filename
        });
        this.entity['payment_scan'] = response.data.filename;
      });
  }
  uploadPaymentScan(event, entityId = false){
    this.uploadPayment(event, entityId);
  }
  uploadSubmissionScan(event, entityId = false){
    this.uploadSubmission(event, entityId);
  }
  uploadInvoiceScan(event, entityId = false){
    this.uploadInvoice(event, entityId);
  }
  submitInvoicesForm(){
    if(!this.entityFormGroup.valid){
  		this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(this.entityFormGroup);
      appGlobals.markFormGroupTouched(this.entityFormGroup);
    }
    else{
      const formData = this.entityFormGroup.value;
      if(!this.entity){
        this.entity = new Invoices();
      }
      this.entity.fill(formData);
      if(this.entity.id){
	      this.entityService.updateEntity(this.entity).subscribe(
          response => {
            if(response.status === 200){
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.router.navigate(['invoices/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
	    }else{
	      this.entityService.addEntity(this.entity).subscribe(
          response  =>  {
            if(response.status === 200){
              this.entity  = new Invoices(response.data);
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.router.navigate(['invoices/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
      }
    }
  }
}
