import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class BidDetailsService {
  constructor(private serviceHttp:HttpService) { }
  getList(pageNo = 1
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/page', 'POST', 0, {page: pageNo
      });
  }
  search(str:string ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/search', 'POST', 0, {search:str });
  }
  getBidDetailsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bidDetails/'+ id, 'GET');
  }
  addBidDetails(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bidDetails', 'POST', 0, data);
  }
  updateBidDetails(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bidDetails/' + data.id, 'PUT', 0, data);
  }
}
