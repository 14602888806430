import { Component, OnInit, Input, } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { StorageService } from '../../core/services/storage.service';
import { Users } from '../../manage/users/users';
import { CustomerContacts } from '../../manage/customerContacts/customerContacts';
import { AuthenticatorService } from '../../core/services/authenticator.service';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
  providers: [AuthenticatorService]
})
export class LeftMenuComponent{

  icon: boolean = false;
  customCollapsedHeight: string = '50px';
  customExpandedHeight: string = '50px';

  isShow :boolean;
  IsExpanded1 :boolean;
  IsExpanded2 :boolean;
  IsExpanded3 :boolean;
  IsExpandedT :boolean;
  IsExpandedTask: boolean;
  user_id: number;
  user: Users;
  company_name: string;
  avatar_url = 'assets/img/user.png';
  customerContact: CustomerContacts;
  IsExpandedReports: boolean;
  IsExpandedTrip: boolean;
  panelOpenState = false;
  IsExpandedTripRequests = false;
  displayIcon:boolean;
  constructor(private commonService:CommonService,
    public authenticatorService: AuthenticatorService){
    // console.log('left menu constructor');
    this.displayIcon = false;
  }
  setUserParameters() {
    this.user = StorageService.getUser();
    this.user_id = (this.user && this.user!= null) ? this.user.id : 0;
    if(this.user  && this.user!= null) {
      if(this.user.avatar_location) {
        this.avatar_url = this.user.avatar_location;
      }
      else{
        this.avatar_url = 'https://ui-avatars.com/api/?rounded=true&name=' + this.user.name;
      }

    }
    this.company_name = StorageService.getItem('company_name');
    console.log("company name : ",this.company_name)
    if(this.user && this.user!= null && (!this.company_name) || this.company_name == null){
      if(this.user.work_area_cd && this.user.work_area_cd[0]){
        this.company_name = this.user.work_area_cd[0];
      }
    }

    this.commonService.castLogin$.subscribe(isShow=>{
      setTimeout(()=>{
        this.isShow = isShow;
      });
    });
  }
  ngOnInit() {
    // console.log('left menu on init');
    this.setUserParameters();
  }
  toggle1() {
    this.IsExpanded1 = !this.IsExpanded1;
   }
   toggle2() {
    this.IsExpanded2 = !this.IsExpanded2;
   }
   toggle3() {
    this.IsExpanded3 = !this.IsExpanded3;
   }
   toggleT() {
    this.IsExpandedT = !this.IsExpandedT;
   }
   toggleReport(anEvent){
     anEvent.preventDefault();
     this.IsExpandedReports = !this.IsExpandedReports;
   }
   checkRole(arr){
     return StorageService.checkRole(arr);
   }
   userIsLE(){
     return StorageService.userIsLE();
   }
   userIsTacking(){
    return StorageService.userIsCT();
  }
  toggleDrawer(){
    // this.authenticatorService.selectedMenuName.next('demo');
    localStorage.setItem('selectedMenu', "dummy Name");
    this.commonService.toggle();
    // console.log("toggle works . . .")
  }
}


