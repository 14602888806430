<div class="row pTop10">
  <div class="col-sm-3">
    <mat-button-toggle-group #group="matButtonToggleGroup" (change)="showImages($event)" vertical>
      <mat-button-toggle value="truck" *ngIf="truck">Truck Documents</mat-button-toggle>
      <mat-button-toggle value="driver" *ngIf="driver">Driver Documents</mat-button-toggle>
      <mat-button-toggle value="broker" *ngIf="broker">Broker Documents</mat-button-toggle>
      <mat-button-toggle value="owner" *ngIf="partner">Owner Documents</mat-button-toggle>
      <mat-button-toggle value="thc" *ngIf="truckHireChallan">Thc Documents</mat-button-toggle>
      <mat-button-toggle value="consignment" *ngIf="consignmentNote">Consignment Documents</mat-button-toggle>
    </mat-button-toggle-group>

  </div>
  <div class="col-sm-9">
        <mat-card *ngFor="let postcard of images; let index = index" (click)="display_postcard(index)">
          <mat-card-header>
            <mat-card-title>{{postcard.label}}</mat-card-title>
          </mat-card-header>
          <span *ngIf="!postcard.isPDF">
            <img mat-card-image src="{{postcard.url}}" alt="{{postcard.label}}" class="postcards"
              (click)="display_postcard(index)"></span>
          <span *ngIf="postcard.isPDF">
                  <pdf-viewer [src]="postcard.url" [page]="page" [original-size]="false" style="display: block;"></pdf-viewer>
          </span>
        </mat-card>
    </div>
</div>
