<div class="modal-gallery">
  <!-- <mat-tab-group selectedIndex={{selectedTabIndex}} (focusChange)="selectedTab($event)">
    <mat-tab label="All">

    </mat-tab>
  </mat-tab-group> -->
  <div class="row">
    <div class="col">
      <mat-button-toggle-group class="AssignTruckToggle"
      #group="matButtonToggleGroup" (change)="showImages($event)"
      value="all">
      <mat-button-toggle value="all" class="gallery-toggle-buttons" >All</mat-button-toggle>
      <mat-button-toggle value="consignment" class="gallery-toggle-buttons" *ngIf="hasCnDocuments()">Consignment</mat-button-toggle>
      <mat-button-toggle value="thc" class="gallery-toggle-buttons" *ngIf="hasThcDocuments()">THC</mat-button-toggle>
        <mat-button-toggle value="truck" class="gallery-toggle-buttons" *ngIf="hasTruckDocuments()">
          Truck</mat-button-toggle>
        <mat-button-toggle value="driver" class="gallery-toggle-buttons" *ngIf="hasDriverDocuments()">Driver</mat-button-toggle>
        <mat-button-toggle value="broker" class="gallery-toggle-buttons" *ngIf="hasBrokerDocuments()">Broker</mat-button-toggle>
        <mat-button-toggle value="owner" class="gallery-toggle-buttons" *ngIf="hasOwnerDocuments()">Owner</mat-button-toggle>


      </mat-button-toggle-group>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col"> -->
      <ng-container *ngTemplateOutlet="showImagesGallery;context:{imageSet: images}"></ng-container>
    <!-- </div>
  </div> -->
</div>
<ng-template #showImagesGallery let-imageSet="imageSet">
  <div class="row" *ngIf="checkImagesExists(imageSet)">
    <div class="col d-flex mt-3" *ngFor="let postcard of imageSet; let index = index">
      <mat-card class="w-100">
        <mat-card-header>
          <mat-card-title>{{postcard.label}}</mat-card-title>
        </mat-card-header>
        <mat-card-content mat-card-content *ngIf="!postcard.isPDF">
          <!-- <img mat-card-image [src]="postcard.url | secure | async" alt="{{postcard.label}}" class="postcards"
            (click)="display_postcard(index)"> -->
          <ngx-img-zoom mat-card-image [zoomImageSrc]="postcard.url" [previewImageSrc]="postcard.url"
            imgStyle="width:200px; height:200px; bottom:0;  left:0;  right:0;  top:0;  margin:0 auto; border:1px solid #dcdcdc;"
            resultStyle="width:380px; height:380px; background-repeat: no-repeat; z-index: 2; position:absolute;
                           -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 206px;left:-58px;"
            lensStyle="width:155px; height:155px" enableZoom=true containerStyle="width:100%;"></ngx-img-zoom>
        </mat-card-content>
        <mat-card-content *ngIf="postcard.isPDF">
          <pdf-viewer [src]="postcard.url" [page]="page" [original-size]="false" class="postcards"
            style="display: block;"></pdf-viewer>
        </mat-card-content>
        <mat-card-actions >
          <button mat-button mat-raised-button (click)="display_postcard(index)"> Open </button>
          <!-- <button mat-button> Comment </button>   -->
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="row" *ngIf="!checkImagesExists(imageSet)">
    <div class="col">
      No images found.
    </div>
  </div>
</ng-template>
