/// <reference types="@types/googlemaps" />
import { Component } from '@angular/core';
// import { } from 'googlemaps';
import { MapComponent } from './map.component';
declare var google: any;

@Component({
  selector: 'app-map-req',
  templateUrl: './map.req.component.html',
  styleUrls: ['./map.component.css']
})
export class MapReqComponent extends MapComponent{

}
