import { Invoices } from '../invoices/invoices';
import { ConsignmentNotes } from '../consignmentNotes/consignmentNotes';
import { safeDate } from '../../core/globalfunctions';
export class InvoiceLines {
  id: number;
  consignment_notes_id: number;
  invoices_id: number;
  consignment_note: ConsignmentNotes;
  consignment_no: string;
  webxpress_invoice_line_code: string;
  invoice_line_ref: string;

  invoice_lines_status_cd: string;
  invoice_line_amt: number;

  payment_due_dt: Date;
  payment_dt: Date;
  payment_ref: string;
  payment_amt: number;
  diff_amt: number;
  diff_reason: string;
  invoice: Invoices;
  loading_charges: number;
  unloading_charges: number;
  detention_charges: number;
  detention_days: number;
  misc_charges_amt: number;

  freight_amt: number;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): InvoiceLines[] {
    let invoiceLines: InvoiceLines[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        invoiceLines.push(new InvoiceLines(json));
      }
    }
    return invoiceLines;
  }
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.consignment_notes_id = json.consignment_notes_id;
      this.invoices_id = json.invoices_id;
      if (json.invoice != null) {
        this.invoice = new Invoices(json.invoice);
      }
      if (json.consignment_note != null) {
        this.consignment_note = new ConsignmentNotes(json.consignment_note);
      }
      this.consignment_no = json.consignment_no;
      this.invoice_lines_status_cd = json.invoice_lines_status_cd;
      this.invoice_line_amt = json.invoice_line_amt;
      this.invoice_line_ref = json.invoice_line_ref;
      this.payment_amt = json.payment_amt;
      safeDate(this, json,  'payment_dt');
      safeDate(this, json,  'payment_due_dt');

      this.payment_ref = json.payment_ref;
      this.webxpress_invoice_line_code = json.webxpress_invoice_line_code;
      this.diff_amt = json.diff_amt;
      this.diff_reason = json.diff_reason;
      this.loading_charges = json.loading_charges;
      this.unloading_charges = json.unloading_charges;
      this.detention_charges = json.detention_charges;
      this.detention_days = json.detention_days;
      this.misc_charges_amt = json.misc_charges_amt;
      this.freight_amt = json.freight_amt;

    }
  }
}
export const invoiceLinesFormControls = {
  id: ['', []],
  invoices_id: ['', []],
  consignment_notes_id: ['', []],
  invoice_lines_status_cd: ['', []],
  invoice_line_amt: ['', []],
  invoice_line_ref: ['', []],
  payment_amt: ['', []],
  payment_due_dt: ['', []],
  payment_dt: ['', []],
  payment_ref: ['', []],
  webxpress_invoice_line_code: ['', []],
  diff_amt: ['', []],
  diff_reason: ['', []],
  loading_charges: ['', []],
  unloading_charges: ['', []],
  detention_days: ['', []],
  detention_charges: ['', []],
  freight_amt: ['', []],
  misc_charges_amt: ['', []],
  consignment_no_search: ['',  []],
};
