import {Trips} from '../trips/trips';
import {ConsignmentNotes} from '../consignmentNotes/consignmentNotes';
export class ConsignmentTripTrackings {
    id : number;
    consignment_notes_id : number;
    trips_id : number;
    last_location : string;
    updatd_by : number;
    is_visible_to_customer : number;
    remarks : string;
    trips_status_cd : string;
    last_location_lat : number;
    last_location_long : number;
    trip : Trips;
    consignment_note : ConsignmentNotes;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : ConsignmentTripTrackings[] {
        let consignmentTripTrackings : ConsignmentTripTrackings[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                consignmentTripTrackings.push(new ConsignmentTripTrackings(json));
            }
        }
        return consignmentTripTrackings;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.consignment_notes_id = json.consignment_notes_id;
            this.trips_id = json.trips_id;
            this.last_location = json.last_location;
            this.updatd_by = json.updatd_by;
            this.is_visible_to_customer = json.is_visible_to_customer;
            this.remarks = json.remarks;
            this.trips_status_cd = json.trips_status_cd;
            this.last_location_lat = json.last_location_lat;
            this.last_location_long = json.last_location_long;
            if (json.trip != null) {
                this.trip = new Trips(json.trip);
            }
            if (json.consignment_note != null) {
                this.consignment_note = new ConsignmentNotes(json.consignment_note);
            }
        }
    }
}
export const consignmentTripTrackingsFormControls = {
  id: ['', []],
  consignment_notes_id: ['', []],
  trips_id: ['', []],
  // last_location: ['', [Validators.required]],
  last_location: ['', []],
  updatd_by: ['', []],
  is_visible_to_customer: [false, []],
  remarks: ['', []],
  trips_status_cd: ['', []],
  temp_trips_status_cd: ['', []],
  last_location_lat: ['', []],
  last_location_long: ['', []],
  status_type_cd: ['', []],
  status_at: ['', []],
  status_at_time: ['', []],
  cancel_reason_cd: ['', []],
  tasks_id: ['', []]
};
