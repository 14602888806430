import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
@Injectable()
export class CommonService {
  
  private static isHeader = new BehaviorSubject<boolean>(false);
  
  castLogin$:Observable<boolean> = CommonService.isHeader.asObservable();
  
  private drawer: MatDrawer;
  private fontSize: MatDrawer;
  constructor() { }
  
  public changeHeader(status :boolean){
    CommonService.isHeader.next(status);
  }
  
  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }
  
  toggle(): void {
      this.drawer.toggle();
  }

  close(): void { 
    this.drawer.close();
  }
}
