import { Deserializable } from '../../core/deserializable';
import { safeDate } from '../../core/globalfunctions';
import { Invoices } from '../invoices/invoices';
import { InvoiceLines } from '../invoiceLines/invoiceLines';
import { Trips } from '../trips/trips';
import { Trucks } from '../trucks/trucks';
import { Partners } from '../partners/partners';
import { PartnerContacts } from '../partnerContacts/partnerContacts';
import { PartnerBanks } from '../partnerBanks/partnerBanks';
import { Bookings } from '../bookings/bookings';
import { Customers } from '../customers/customers';

export class Tasks extends Deserializable{
  id: string;
  deleted_at?: Date;
  created_at: Date;
  updated_at: Date;
  territory_cd: string;
  key: string;
  name: string;
  candidate_group: string;
  candidate?: string;
  assignee: string;
  owner?: string;
  due_at: Date;
  follow_up_at?: Date;
  description?: string;
  priority: number;
  revision?: string;
  delegation_state_cd?: string;
  suspension_state_cd?: string;
  delete_reason?: string;
  suspension_reason?: string;
  execution_id?: string;
  process_definition_id?: string;
  process_instance_id?: string;
  task_definition_id?: string;
  parent_task_id?: string;
  is_escalated: boolean;
  work_area_cd: string;
  entity_type: string;
  entity_id: number;
  entity_uuid?: string;
  completed_by?: string;
  completed_at?: Date;
  assigned_at: string;
  status_cd?: string;
  lifecycle_state_cd: string;
  booking_for_start: Date;
  tat: number;
  freight_amt: number;
  assignee_name: string;
  owner_name: string;
  actual_dispatch_dt: Date;
  actual_arrival_dt: Date;

  // entity specific attributes

   email_from_names: string;
   subject: string;
   body: string;
   actual_email_dt: Date;
   brokers_id: number;
    constructor(json? : any) {
      super();
      // this.fill(json);
      // console.log(json);

      this.deserialize(json)
      // console.log(this.deserialize(json));
      //
      if(json.booking_for_start && typeof json.booking_for_start ===  'string') {
        this.booking_for_start = new Date(('1970-01-01 ' + json.booking_for_start).replace(/-/g, "/"));
      }

    }
    isComplete(){
      return this.completed_at || (this.lifecycle_state_cd == 'COMPLETE');
    }
    isOpen(){
      return (this.lifecycle_state_cd == 'CREATED' || this.lifecycle_state_cd == 'PENDING');
    }
    getDisplayId(){
      switch(this.entity_type) {
        case "Trip":{
          return this['trip_no'];
        }
        case "Truck Hire Challan":{
          return this['thc_no'];
        }
        case "Consignment Note":{
          return this['consignment_no'];
        }
        case "Invoice":{
          return this['invoice_ref'];
        }
        case "Booking":{
          return this['booking_no'];
        }
        default: {
          return this.entity_id;
        }
      }
    }
    getDisplayStatus(){
      switch(this.entity_type) {
        case "Trip":{
          return this['trips_status_cd'];
        }
        case "Truck Hire Challan":{
          return this['truck_hire_challan_status_cd'];
        }
        case "Consignment Note":{
          return this['consignment_status_cd'];
        }
        case "Invoice":{
          return this['invoice_status_cd'];
        }
        case "Booking":{
          return this['booking_status_cd'];
        }
        default: {
          return '';
        }
      }
    }
    getTypes(): any {
      return {
        'deleted_at': Date,
        'created_at': Date,
        'updated_at': Date,
        'due_at': Date,
        'follow_up_at': Date,
        'completed_at': Date,
        'assigned_at': Date,
        'booking_for_dt': Date,
        'actual_dispatch_dt': Date,
        'actual_arrival_dt': Date,
        'invoice': Invoices,
        'invoice_lines': InvoiceLines,
        'trip': Trips,
        'truck': Trucks,
        'partners': Partners,
        'broker': Partners,
        'broker_contact': PartnerContacts,
        'partner_contact': PartnerContacts,
        'partner_bank': PartnerBanks,
        'task_comments': TaskComments,
        'task_attachments': TaskAttachments,
        'booking': Bookings,
        'customer': Customers,
        // 'booking_for_start': Date

      };
    }
    static toArray(jsons : any[]) : Tasks[] {
        let tasks : Tasks[] = [];
        if (jsons != null) {
            for (let json of jsons) {
              tasks.push(new Tasks(json));
            }
        }
        return tasks;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            safeDate(this, json,  'deleted_at');
            safeDate(this, json,  'created_at');
            safeDate(this, json,  'updated_at');

            this.territory_cd = json.territory_cd;
            this.key = json.key;
            this.name = json.name;
            this.candidate_group = json.candidate_group;
            this.candidate = json.candidate;
            this.assignee = json.assignee;
            this.owner = json.owner;
            if(json.due_at) {
              this.due_at = json.due_at;
            }
            if(json.follow_up_at) {
              this.follow_up_at = json.follow_up_at;
            }
            this.description = json.description;
            this.priority = json.priority;
            this.revision = json.revision;
            this.delegation_state_cd = json.delegation_state_cd;
            this.suspension_state_cd = json.suspension_state_cd;
            this.delete_reason = json.delete_reason;
            this.suspension_reason = json.suspension_reason;
            this.execution_id = json.execution_id;
            this.process_definition_id = json.process_definition_id;
            this.process_instance_id = json.process_instance_id;
            this.task_definition_id = json.task_definition_id;
            this.parent_task_id = json.parent_task_id;
            this.is_escalated = json.is_escalated;
            this.work_area_cd = json.work_area_cd;
            this.entity_type = json.entity_type;
            this.entity_id = json.entity_id;
            this.entity_uuid = json.entity_uuid;
            this.completed_by = json.completed_by;
            if(json.completed_at) {
              this.completed_at = json.completed_at;
            }
            if(json.assigned_at) {
              this.assigned_at = json.assigned_at;
            }
            this.status_cd = json.status_cd;
            this.lifecycle_state_cd = json.lifecycle_state_cd;
            safeDate(this, json,  'actual_dispatch_dt');
            safeDate(this, json,  'actual_arrival_dt');
            this.freight_amt = json.freight_amt;
            this.tat = json.tat;
            this.brokers_id = json.brokers_id;
        }
    }
    getTHCEventDelay() {
      if (this.due_at < (new Date())) {
        return '(Delayed)';
      }
      return '';
    }
    getTHCEventDue() {
      if (this.name == 'REQUEST ADVANCE' || this.name == 'APPROVE ADVANCE' || this.name == 'APPROVE ADVANCE FIN' || this.name == 'UPDATE ADV DOCS') {
        return 'ATH'
      }
      if (this.name == 'REQUEST BALANCE' || this.name == 'APPROVE BALANCE' || this.name == 'APPROVE BALANCE FIN' || this.name == 'UPDATE BAL DOCS') {
        return 'BTH'
      }
      else {
        return this.name;
      }
    }
    getRowColor() {
      let now = new Date();
      if (this.completed_at) {
        now = this.completed_at;
      }
      let secsAllowed = (this.due_at.getTime() - this.created_at.getTime()) / 1000;
      let secsPassed = (now.getTime() - this.created_at.getTime()) / 1000;
      if (secsPassed > secsAllowed) {
        return 'border-red';
      }
      if (secsPassed > (2 * secsAllowed / 3)) {
        return 'border-orange';
      }
      return 'border-green';
    }
    getPODXFactor(): number {
      let now = new Date();
      let secsAllowed = (this.due_at.getTime() - this.created_at.getTime()) / 1000;
      let secsPassed = (now.getTime() - this.created_at.getTime()) / 1000;
      // console.log(secsPassed);
      // console.log(secsAllowed);
      // transit time * 3 is allowedtime.
      // transit time is 3*transit days + 1 = due date from delivery
      // due date -1 = 3 into transit days
      // due length / transit days  ~ 3x
      // days passed / transit days
      if (!this.actual_arrival_dt || !this.actual_dispatch_dt) {
        // console.log(this.actual_arrival_dt);
        // console.log(this.actual_dispatch_dt);
        // console.log(this);
        // console.log(this.name);
        return 0;
      }
      let transitDaysSecs = (this.actual_arrival_dt.getTime() - this.actual_dispatch_dt.getTime());
      let transitDays = Math.ceil(transitDaysSecs / (1000 * 60 * 60 * 24));
      let dueDays = Math.ceil((this.due_at.getTime() - this.actual_arrival_dt.getTime()) / (1000 * 60 * 60 * 24));
      let daysPassed = Math.ceil((now.getTime() - this.actual_arrival_dt.getTime()) / (1000 * 60 * 60 * 24));
      // let xFactor = Math.round( (3 *( secsPassed/secsAllowed) ) * 10 ) / 10;
      let xFactor = Math.round(((daysPassed / transitDays)) * 10) / 10;
      // console.log('factor by transit ' + xFactor);
      // console.log('factor by due ' + Math.round( ( ( 3*( daysPassed/ dueDays) ) * 10 ) / 10) );
      //console.log('days passed' + daysPassed);
      // console.log('days transit' + transitDays);
      // x = y passed is 3x the
      // console.log(xFactor );
      // console.log(this.created_at);
      // console.log(this.due_at);
      // console.log(Math.
      return xFactor;
      // 3x+1 is create date till due date total allowed
      // create date till today is time elapses
      // x factor is total allowed / total elapsed.

    }
    isPODXFactorRed() {
      if (this.getPODXFactor() >= 3) {
        return true;
      }
    }
    isPODXFactorAmber() {
      if (this.getPODXFactor() >= 2.5) {
        return true;
      }
    }
    isPODXFactorGreen() {
      if (this.getPODXFactor() < 2.5) {
        return true;
      }
    }

    getPODColorClass() {
      if (this.isPODXFactorRed()) {
        return "text-red";
      }
      else if (this.isPODXFactorAmber()) {
        return "text-amber";
      }
      else if (this.isPODXFactorGreen()) {
        return "text-green";
      }
      else {
        return "normal";
      }
    }
    getPODRecClass() {
      if (this.isPODXFactorRed()) {
        return "pod-days-rec-red";
      }
      else if (this.isPODXFactorAmber()) {
        return "pod-days-rec-amber";
      }
      else if (this.isPODXFactorGreen()) {
        return "pod-days-rec-green";
      }
      else {
        return "normal";
      }
    }
}
export let tasksFormControls = {
  id: ['', []],
  assignee_name: ['', []],
  assignee: ['', []],
  escalate_to: ['', []],
  escalate_to_name: ['', []],
  due_at: ['', []],
  comment: ['',  []],
  candidate_group: ['', []],
  work_area_cd: ['', []],
  lifecycle_state_cd: ['', []],
  cancel_reason_cd: ['', []],
  is_escalated: ['', []],
  owner: ['', []],
  delegation_state_cd: ['', []],

}

export class TaskComments extends Deserializable{
  id: string;
  tasks_id: string;
  constructor(json? : any) {
    super();
    this.deserialize(json);
  }

}
export class TaskAttachments extends Deserializable{
  id: string;
  tasks_id: string;
  constructor(json? : any) {
    super();
    this.deserialize(json);
  }

}
