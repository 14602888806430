import {Trips} from '../trips/trips';
import { Validators } from '@angular/forms';
import { safeDate } from '../../core/globalfunctions';

export class TripAddresses {
    id : number;
    trip_address_type_cd : string;
    address_sequence : number;
    street : string;
    map_locality_street: string;
    vicinity: string;
    place_name: string;
    place_id: string;

    city : string;
    state_cd : string;
    pincode : string;
    contact_name : string;
    contact_mobile_no : string;
    contact_phone_no : string;
    trips_id : number;
    company_name : string;
    location_lat : number;
    location_long : number;
    scheduled_arrival_dt : Date;
    scheduled_arrival_time : string;
    scheduled_dispatch_dt : Date;
    scheduled_dispatch_time : string;
    estimated_arrival_dt : Date;
    estimated_arrival_time : string;
    estimated_dispatch_dt : Date;
    estimated_dispatch_time : string;
    actual_arrival_dt : Date;
    actual_arrival_time : string;
    actual_dispatch_dt : Date;
    actual_dispatch_time : string;
    remarks : string;
    // trips : Trips;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : TripAddresses[] {
        let tripAddresses : TripAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                tripAddresses.push(new TripAddresses(json));
            }
        }
        return tripAddresses;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.trip_address_type_cd = json.trip_address_type_cd;
            this.address_sequence = json.address_sequence;
            this.street = json.street;
            this.map_locality_street = json.map_locality_street
            this.vicinity = json.vicinity;
            this.place_name = json.place_name;
            this.place_id = json.place_id;
            this.city = json.city;
            this.state_cd = json.state_cd;
            this.pincode = json.pincode;
            this.contact_name = json.contact_name;
            this.contact_mobile_no = json.contact_mobile_no;
            this.contact_phone_no = json.contact_phone_no;
            this.trips_id = json.trips_id;
            this.company_name = json.company_name;
            this.location_lat = json.location_lat;
            this.location_long = json.location_long;
            safeDate(this, json,  'scheduled_arrival_dt');
            if (json.scheduled_arrival_time != null) {
                this.scheduled_arrival_time = json.scheduled_arrival_time;
            }
            safeDate(this, json,  'scheduled_dispatch_dt');
            if (json.scheduled_dispatch_time != null) {
                this.scheduled_dispatch_time = json.scheduled_dispatch_time;
            }
            safeDate(this, json,  'estimated_arrival_dt');
            if (json.estimated_arrival_time != null) {
                this.estimated_arrival_time = json.estimated_arrival_time;
            }
            safeDate(this, json,  'estimated_dispatch_dt');
            if (json.estimated_dispatch_time != null) {
                this.estimated_dispatch_time = json.estimated_dispatch_time;
            }
            safeDate(this, json,  'actual_arrival_dt');
            if (json.actual_arrival_time != null) {
                this.actual_arrival_time = json.actual_arrival_time;
            }
            safeDate(this, json,  'actual_dispatch_dt');
            if (json.actual_dispatch_time != null) {
                this.actual_dispatch_time = json.actual_dispatch_time;
            }
            this.remarks = json.remarks;
            if (json.trips != null) {
                // this.trips = new Trips(json.trips);
            }
        }
    }
}
export let tripAddressesFormControls = {
  id: ['', []],
  trip_address_type_cd: ['', [Validators.required]],
  address_sequence: ['', [Validators.required]],
  street: ['', [Validators.required]],
  map_locality_street: ['', []],
    vicinity: ['', []],
    place_name: ['', []],
    place_id: ['', []],

  city: ['', [Validators.required]],
  state_cd: ['', [Validators.required]],
  pincode: ['', [Validators.required]],
  contact_name: ['', [Validators.required]],
  contact_mobile_no: ['', [Validators.required,Validators.min(1000000000), Validators.max(9999999999)]],
  contact_phone_no: ['', []],
  trips_id: ['', []],
  company_name: ['', []],
  location_lat: ['', []],
  location_long: ['', []],
  scheduled_arrival_dt: ['', [Validators.required]],
  scheduled_arrival_time: ['', []],
  scheduled_dispatch_dt: ['', []],
  scheduled_dispatch_time: ['', []],
  estimated_arrival_dt: ['', []],
  estimated_arrival_time: ['', []],
  estimated_dispatch_dt: ['', []],
  estimated_dispatch_time: ['', []],
  actual_arrival_dt: ['', []],
  actual_arrival_time: ['', []],
  actual_dispatch_dt: ['', []],
  actual_dispatch_time: ['', []],
  remarks: ['', []],
  }
