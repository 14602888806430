import { Trucks } from "../trucks/trucks";
import { Users } from "../users/users";
import * as appGlobals from '../../core/globalfunctions';

export class Bids {
    id : number;
    bid_details_id : number;
    price : number;
    user_id : number;
    truck_type_cd : string;
    weight_type_cd : string;
    selected : boolean;
    created_date : Date;
    updated_date : Date;
    assign_truck : number;
    truck: Trucks;
    user: Users;
    bidusers: Users;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Bids[] {
        let bids : Bids[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                bids.push(new Bids(json));
            }
        }
        return bids;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.bid_details_id = json.bid_details_id;
            this.price = json.price;
            this.user_id = json.user_id;
            this.truck_type_cd = json.truck_type_cd;
            this.weight_type_cd = json.weight_type_cd;
            this.selected = json.selected;
            appGlobals.safeDate(this, json,  'created_date');
            appGlobals.safeDate(this, json,  'updated_date');

            this.assign_truck = json.assign_truck;
            if (json.truck != null) {
                this.truck = new Trucks(json.truck);
            }
            if (json.user != null) {
                this.user = new Users(json.user);
            }
            if (json.bidusers != null) {
                this.bidusers = new Users(json.bidusers);
            }
        }
    }
}
