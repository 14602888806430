import {Customers} from '../customers/customers';
import {Trips} from '../trips/trips';
import {Drivers} from '../drivers/drivers';
export class DriverRatings {
    id : number;
    rating : number;
    remarks : string;
    drivers_id : number;
    trips_id : number;
    customers_id : number;
    customers : Customers;
    trips : Trips;
    drivers : Drivers;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : DriverRatings[] {
        let driverRatings : DriverRatings[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                driverRatings.push(new DriverRatings(json));
            }
        }
        return driverRatings;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.rating = json.rating;
            this.remarks = json.remarks;
            this.drivers_id = json.drivers_id;
            this.trips_id = json.trips_id;
            this.customers_id = json.customers_id;
            if (json.customers != null) {
                this.customers = new Customers(json.customers);
            }
            if (json.trips != null) {
                this.trips = new Trips(json.trips);
            }
            if (json.drivers != null) {
                this.drivers = new Drivers(json.drivers);
            }
        }
    }
}
