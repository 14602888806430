import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { ChartsModule } from 'ng2-charts';

const routes: Routes = [
  {path:'pdf', component:DownloadPdfComponent}
];

@NgModule({
  declarations: [DownloadPdfComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    ChartsModule
  ],
  exports: [RouterModule]
})
export class PdfModule { }
