import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Users } from '../../manage/users/users';
import { UsersService } from '../../manage/users/services/users.service';
import { StorageService } from '../../core/services/storage.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as appGlobals from '../../core/globalfunctions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [UsersService]
})
export class ForgotPasswordComponent implements OnInit {
  forgotFormGroup: FormGroup;
  errorMessage:string = null;
  sucessMessage:string = null;
  constructor(private commonService: CommonService,
    private _formBuilder: FormBuilder,
    private usersService: UsersService,
    private router:Router) { }
  ngOnInit() {
    this.forgotFormGroup = this._formBuilder.group({
      email: ['',[Validators.required,Validators.minLength(6)]]
    });
  }
  submit(data){
    this.errorMessage = null;
      this.usersService.forgotpassword(data).subscribe(
        response=>{
        if(response.status == 200){
           this.sucessMessage = response.message;
        }else{
          this.errorMessage = JSON.stringify(response.message);
          alert(this.errorMessage);
        }
      },
      error => {
        this.errorMessage = appGlobals.getDisplayErrorMessage(error);
        alert(this.errorMessage);
      },
      () => {
      });
  }
}
