import {Bookings} from '../bookings/bookings';
import {CustomerContactAddresses} from '../customerContactAddresses/customerContactAddresses';
export class BookingAddresses {
    id : number;
    address_type_cd : string;
    address_sequence : number;
    city : string;
    customer_contact_addresses_id : number;
    bookings_id : number;
    customerContactAddresses : CustomerContactAddresses;
    customer_addresses_id: number;
    contract_city: string;
    state: string;
    location_code: string;
    branch_cd: string;
    state_location_mappings_id: number;
    location_label: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : BookingAddresses[] {
        let bookingAddresses : BookingAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                bookingAddresses.push(new BookingAddresses(json));
            }
        }
        return bookingAddresses;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.address_type_cd = json.address_type_cd;
            this.address_sequence = json.address_sequence;
            this.city = json.city;
            this.contract_city = json.contract_city;
            this.customer_contact_addresses_id = json.customer_contact_addresses_id;
            this.customer_addresses_id = json.customer_addresses_id;
            this.bookings_id = json.bookings_id;
            this.location_label = json.location_label;
            if (json.customer_contact_address != null) {
                this.customerContactAddresses = new CustomerContactAddresses(json.customer_contact_address);
                if(this.customerContactAddresses.customer_address) {
                  this.location_label = this.customerContactAddresses.customer_address.location_label;
                }
            }
            this.state = json.state;
            this.location_code = json.location_code;
            this.branch_cd = json.branch_cd;
            this.state_location_mappings_id = json.state_location_mappings_id;


        }
    }
}
