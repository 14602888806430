import {Trucks} from '../trucks/trucks';
import {Drivers} from '../drivers/drivers';
import { safeDate } from '../../core/globalfunctions';
export class TruckDrivers {
    id : number;
    start_dt : Date;
    end_dt : Date;
    active : number;
    status : string;
    trucks_id : number;
    drivers_id : number;
    // trucks : Trucks;
    driver : Drivers;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : TruckDrivers[] {
        let truckDrivers : TruckDrivers[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                truckDrivers.push(new TruckDrivers(json));
            }
        }
        return truckDrivers;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            safeDate(this, json,  'start_dt');
            safeDate(this, json,  'end_dt');

            this.active = json.active;
            this.status = json.status;
            this.trucks_id = json.trucks_id;
            this.drivers_id = json.drivers_id;
            if (json.trucks != null) {
                // this.trucks = new Trucks(json.trucks);
            }
            if (json.driver != null) {
                this.driver = new Drivers(json.driver);
            }
        }
    }
}
export let trukDriversFormControls = {
    id: ['', []],
    start_dt: ['', []],
    end_dt: ['', []],
    active: ['', []],
    status: ['', []],
    trucks_id: ['', []],
    drivers_id: ['', []],
  };
