
import { switchMap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { Component, TemplateRef, Input, HostListener } from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { Entity as EntityClass, entityFormControls as entityFormControls, EntityDataSource } from '../entity';
import { EntityService } from '../services/entity.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { AppSettings } from '../../../core/config';
import { StorageService } from '../../../core/services/storage.service';

@Component({
  selector: 'app-entity-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [EntityService, DialogService, FileTransferService, StorageService]
})
export class EntityListComponent {
  displayedColumns = [
    'id',
    'edit'
  ];
  inputProperties = [
  ];
  entityName = 'entity';

  dataSource = new EntityDataSource();
  entity: EntityClass;
  entityType = EntityClass;
  entityList = new Array<EntityClass>();

  entityFormControlsType = entityFormControls
  entityFormGroup: FormGroup;
  isError: boolean = false;
  errorMessages = new Array<string>();
  modalRef: BsModalRef;
  per_page: number;
  total: number;
  imageUrl: String = AppSettings.IMAGE_ENDPOINT;

  searchForm: FormGroup;
  currentPage = 1;
  searchBarOpen = false;
  selection: SelectionModel<EntityClass>;
  currentTemplate: TemplateRef<any>;
  usePreviewPanel = false;
  innerWidth: any;

  constructor(protected router: Router,
    protected _formBuilder: FormBuilder,
    protected modalService: BsModalService,
    protected _snackBarService: MatSnackBar,
    protected _dialogService: DialogService,
    protected entityService: EntityService,
    protected fileTransferService: FileTransferService,
  ) { }
  ngOnInit() {
    // console.log(this.entityFormControlsType);
    this.entityFormGroup = this._formBuilder.group(this.entityFormControlsType);
    this.searchForm = this._formBuilder.group({
      search: ['', []],
    });
    appGlobals.observeChanges(
      this,
      this.searchForm.controls.search,
      'entityList',
      'getSearchService',
      'setSearchList'
    );
    // this.getResponse();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(event);
    // console.log(window);
    this.innerWidth = window.innerWidth;
    // console.log('window resized ' + this.innerWidth);
  }
  isDesktop() {
    if(!this.innerWidth) {
      this.innerWidth = window.innerWidth;
    }
    // console.log(this.innerWidth);
    if( this.innerWidth >  1000) {
      return true;
    }
    return false;
  }
  openModalGeneric(template: TemplateRef<any>, aData?: any ) {
    if(this.usePreviewPanel && this.isDesktop()) {
      this.currentTemplate =  template;
      this.modalRef = null;
    }
    else {
      // console.log('openging modal ' + this.modalService.getModalsCount());
      // console.log(template.elementRef);
      // console.log(this.modalService.getModalsCount());
      this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
      // console.log(this.modalRef);
      // console.log(this.modalService.getModalsCount());
      StorageService.modelRefs.push(this.modalRef);
      return this.modalRef;
    }
    return this.modalRef;
  }
  closeModalGeneric(){
    if(this.modalRef &&  this.modalRef != null){
      // console.log('closing generic modal ');
      // console.log(this.modalRef);
      // console.log(this.modalService.getModalsCount());
      const hideResult = this.modalRef.hide();
      // console.log(this.modalService.getModalsCount());
    }
    if(StorageService.modelRefs.length > 0) {
      this.modalRef = StorageService.modelRefs.pop();
      this.modalRef.hide();

  }
    else if(this.currentTemplate) {
      this.currentTemplate = null;
    }
  }
  getResponse(pageNo = 1) {
    this.currentPage = pageNo;
    return this.entityService.getList(
      this.mergeWithSearch()
    ).subscribe(
      response => {
        this.setSearchList(response);
        // this.currentPage = pageNo;
        // this.entityList = EntityClass.toArray(response.data);
        // console.log(response);
        // console.log(this.entityList);
        // this.dataSource = new EntityDataSource(this.entityList);
        // this.total = response.total;
        // this.per_page = response.per_page;
      },
      error => {
        this._dialogService.openAlert({ 'title': 'Error', 'message': appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  mergeWithSearch() {
    let mergedSearchResult =  Object.assign(
      {},
      this.getInputPropertiesObject(),
      this.searchForm.value
    );
    mergedSearchResult = appGlobals.removeEmpty(mergedSearchResult);
    return mergedSearchResult;
  }
  getInputPropertiesObject() {
    let result = {
      page: this.currentPage
    };
    this.inputProperties.forEach(element => {
      result[element] = this[element];
    });
    return result;
  }
  submitSearch(aData = {}) {
    return this.getResponse();
  }
  resetSearch() {
    this.searchForm.reset();
    this.getResponse();
  }
  toggleSearchBar() {
    this.searchBarOpen = !this.searchBarOpen;
  }
  getSearchService(searchTerm) {
    return this.entityService.search(this.mergeWithSearch());
  }
  setSearchList = function (aData) {
    // console.log(aData);
    // console.log(this.entityType);
 //  console.log(aData);
    this.entityList = this.entityType.toArray(aData.data);
 //  console.log(this.entityList);
    this.dataSource = new EntityDataSource(this.entityList);
    this.total = aData.total;
    this.per_page = aData.per_page;
  };
  download(event) {
    this.getResponseToExcel(this.currentPage);
  }
  downloadFile(aData: any) {
    const options = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    const fileName = this.entityName + '_' + new Date().toLocaleDateString();
    appGlobals.createAndDownloadBlobFile(aData, options, fileName);
  }
  refresh(event) {
    this.getResponse(this.currentPage);
  }
  getResponseToExcel(pageNo = 1) {
    this.currentPage = pageNo;
    return this.entityService
      .exportList(this.mergeWithSearch())
      .subscribe(
        response => {
          this.downloadFile(response);
        },
        error => {
       //  console.log(error);
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getLength();
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.getAsArray().forEach(row => this.selection.select(row));
  }
  selectRow(row) {
    this.selection.toggle(row);
    // console.log(this.selection);
  }
  getResponseByPage(event) {
    this.getResponse(event.pageIndex + 1);
  }
  getNewEntityFormGroup(){
    this.entityFormGroup = this._formBuilder.group(this.entityFormControlsType);
    this.entityFormGroup.patchValue(this.getInputPropertiesObject());
    return this.entityFormGroup;
  }

  patchExistingEntity(aEntity){
    this.getNewEntityFormGroup();
    // console.log(this.entityFormGroup);
    this.entityFormGroup.patchValue(aEntity);
    return this.entityFormGroup;
  }
  openAddModal(template: TemplateRef<any>) {
    this.entity = new this.entityType();
    this.getNewEntityFormGroup();
    this.modalRef = this.modalService.show(template);
  }
  getEntityDetail(aData){
    this.entity = new this.entityType(aData);
    // console.log(this.entity);
    this.doPostEntityDetailResponse();
  }
  doPostEntityDetailResponse(){
    this.patchExistingEntity(this.entity);
  }
  openEditModal(template: TemplateRef<any>, aRow: EntityClass) {
    // console.log(aRow);
    this.getEntityDetail(aRow);
    // return;
    this.modalRef = this.modalService.show(template);
  }

  setEntity(aData){
    // console.log('set payment called');
    this.patchExistingEntity(aData);
    // this.entity = new Payments(aData);
    // this.paymentsFormGroup.reset();
    // this.paymentsFormGroup.patchValue(this.payment);
    // this.observeWebXpressPayment();

    // // if(!this.payment.payment_lines){
    // //   this.payment.payment_lines =
    // // }
    // if(this.invoicePay) {
    //   this.setInvoiceForPayment(this.invoicePay);
    // }

  }
  submitEntityForm(aData?) {
    if (!this.entityFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(this.entityFormGroup);
      appGlobals.markFormGroupTouched(this.entityFormGroup);
    }
    else {
      const formData = this.entityFormGroup.value;
      if (formData.id) {
        this.entityService.updateEntity(formData).subscribe(
          response => {
            if (response.status === 200) {
              const foundIndex = this.entityList.findIndex(x => x.id === formData.id);
              this.entityList[foundIndex] = formData;
              this.dataSource = new EntityDataSource();
              this.modalRef.hide();
              this._snackBarService.open(response.message, 'X', { duration: appGlobals.SNACKBAR_TIMEOUT });
            }
            else {
              this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
            }
          },
          error => {
            this._dialogService.openAlert({ 'title': 'Error', 'message': appGlobals.getDisplayErrorMessage(error) });
          }
        );
      } else {
        this.entityService.addEntity(formData).subscribe(
          response => {
            if (response.status === 200) {
              this.entityList.unshift(response.data);
              this.dataSource = new EntityDataSource();
              this.modalRef.hide();
              this._snackBarService.open(response.message, 'X', { duration: appGlobals.SNACKBAR_TIMEOUT });
            }
            else {
              this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
            }
          },
          error => {
            this._dialogService.openAlert({ 'title': 'Error', 'message': appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
    }
  }
  deleteEntity(id) {
  }
  editEntity(id) {
    this.router.navigate(['/' + this.entityName + '/edit', id]);
  }
  viewPayments(id) {
    this.router.navigate(['/' + this.entityName + '/view', id]);
  }
}
