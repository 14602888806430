import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class TripsService {

  sendTripConsignmentAsPDF(aData: any) {
    return this.serviceHttp.getResponse('consignmentNotes/sendTripConsignmentAsPDF', 'POST', 0, aData);
  }
  downloadTripConsignmentPreviewAsPDF(aData: any) {
    // return this.serviceHttp.getResponseExcel('generate-pdf/'+ id, 'GET');
    return this.serviceHttp.getResponseExcel('consignmentNotes/downloadTripConsignmentPreviewAsPDF', 'POST', 0, aData);
  }
  downloadTripConsignmentAsPDF(aData: any) {
    // return this.serviceHttp.getResponseExcel('generate-pdf/'+ id, 'GET');
    return this.serviceHttp.getResponseExcel('consignmentNotes/downloadTripConsignmentAsPDF', 'POST', 0, aData);
  }
  downloadTripKNConsignmentAsPDF(aData: any) {
    return this.serviceHttp.getResponseExcel('consignmentNotes/downloadTripKNConsignmentAsPDF', 'POST', 0, aData);
  }
  getListWithTracking(data: any): any {
    return this.serviceHttp.getResponse('trips/withTracking', 'POST', 0, data);
  }
  getMobileProvider(data: any) {
 //  console.log('getting provider');
    return this.serviceHttp.getResponse('getnumbercarrier', 'POST', 0, {number:data});
  }

  tripsQueue(data): any {
    return this.serviceHttp.getResponse('trips/tripsQueue', 'POST', 0, data);
    // throw new Error('Method not implemented.');
  }
  exportList(data): any {
      return this.serviceHttp.getResponseExcel('trips/exportList', 'POST', 0, data);
      // throw new Error('Method not implemented.');
  }
  exportSaleReport(data: any) {
    return this.serviceHttp.getResponseExcel('trips/exportSaleReport', 'POST', 0, data);

  }
  exportSaleReport2(data: any) {
    return this.serviceHttp.getResponseExcel('trips/exportSaleReport2', 'POST', 0, data);

  }
  constructor(private serviceHttp:HttpService) { }
  searchForTracking(data) {
    return this.serviceHttp.getResponse('trips/searchForTracking', 'POST', 0, data);
  }
  getFile(aUrl) {
    return this.serviceHttp.getResponseDirect(aUrl, 'GET');
  }
  getList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/page', 'POST', 0, data);
  }
  search(data ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/search', 'POST', 0, data);
  }
  getTripsWithLocationById(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/withLocations/'+ id, 'GET');
  }
  getTripsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trips/'+ id, 'GET');
  }
  addTrips(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trips', 'POST', 0, data);
  }
  updateTrips(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/' + data.id, 'PUT', 0, data);
  }
  searchTripsList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/search', 'POST', 0, {search:str});
  }
  assignTruck(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/assignTruck', 'POST', 0, data);
  }
  changeDriver(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/changeDriver', 'POST', 0, data);
  }
  attachTruckDocument(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/attachTruckDocument', 'POST', 0, data);
  }

  attachTruckDocumentOnly(data: any): any {
    return this.serviceHttp.getResponse('trips/attachTruckDocumentOnly', 'POST', 0, data);
  }
  refreshLocation(data: any): any {
    return this.serviceHttp.getResponse('trips/refreshLocation', 'POST', 0, data);
  }

}
