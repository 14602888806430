<div class="header-strip clearfix">
	<div class="row">
		<div class="col-sm-6">
			<h4><a class="back-btn" [routerLink]="['/notifications/list']">
							<i class="fa fa-angle-left"></i>
						</a>{{(notifications.id)?('Edit'):('Create')}} Notifications</h4>
		</div>
		<div class="col-sm-6 text-right">
		  <button mat-raised-button color="primary" (click)="submitNotificationsForm()" [disabled]="!notificationsFormGroup.valid">Submit</button>
		</div>
	</div>
</div>
  <div class="formWhiteBox pLR0 pTop10">
    <div class="row justify-content-md-center">
        <div class="col-md-12">
         <div class="whiteFormShodow radiusTopNone">
          <form [formGroup]="notificationsFormGroup">
          <h1 class="mBtm0">Notifications Details</h1>
          <div *ngIf="isError">
			<ul>
				<li *ngFor="let message of errorMessages">{{message}}</li>
			</ul>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"  required   matInput placeholder="Type" value="" formControlName="type">
		<mat-error *ngIf="!notificationsFormGroup.get('type').valid && notificationsFormGroup.get('type').touched">
                  <span *ngIf="notificationsFormGroup.get('type').errors.required"> Type is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="number"  required   matInput placeholder="Notifiable Id" value="" formControlName="notifiable_id">
		<mat-error *ngIf="!notificationsFormGroup.get('notifiable_id').valid && notificationsFormGroup.get('notifiable_id').touched">
                  <span *ngIf="notificationsFormGroup.get('notifiable_id').errors.required"> Notifiable Id is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"  required   matInput placeholder="Notifiable Type" value="" formControlName="notifiable_type">
		<mat-error *ngIf="!notificationsFormGroup.get('notifiable_type').valid && notificationsFormGroup.get('notifiable_type').touched">
                  <span *ngIf="notificationsFormGroup.get('notifiable_type').errors.required"> Notifiable Type is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                    <textarea matInput placeholder="Data" value="" formControlName="data"  required   autoResize="true"></textarea>
		<mat-error *ngIf="!notificationsFormGroup.get('data').valid && notificationsFormGroup.get('data').touched">
                  <span *ngIf="notificationsFormGroup.get('data').errors.required"> Data is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Read At" value="" formControlName="read_at">
		<mat-error *ngIf="!notificationsFormGroup.get('read_at').valid && notificationsFormGroup.get('read_at').touched">
                  <span *ngIf="notificationsFormGroup.get('read_at').errors.required"> Read At is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
</div>
            </form>
        </div>
        </div>
    </div>
  </div>