/// <reference types="gapi" />
import { MapsAPILoader } from '@agm/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  HostListener
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { AppSettings } from '../../../core/config';
import * as appGlobals from '../../../core/globalfunctions';
import { AuthenticatorService } from '../../../core/services/authenticator.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { StorageService } from '../../../core/services/storage.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import {
  BidDetails,
  bidDetailsFormControls
} from '../../bidDetails/bidDetails';
import { BidsService } from '../../bids/services/bids.service';
import { Bookings, bookingsFormControls } from '../../bookings/bookings';
import { BookingsService } from '../../bookings/services/bookings.service';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { DriverRatingsService } from '../../driverRatings/services/driverRatings.service';
import { Drivers } from '../../drivers/drivers';
import { DriversService } from '../../drivers/services/drivers.service';
import { PartnerContacts } from '../../partnerContacts/partnerContacts';
import { PartnerContactsService } from '../../partnerContacts/services/partnerContacts.service';
import { Partners } from '../../partners/partners';
import { PartnersService } from '../../partners/services/partners.service';
import { TruckHireChallansService } from '../../truckHireChallans/services/truckHireChallans.service';
import {
  TruckHireChallans, assignTruckFormControls, attachTruckDocumentsFormControls,
  requestAdvanceFormControls, approveExcessAdvanceFormControls, requestBalanceFormControls,
  approveExcessHireFormControls, balancePaidFormControls, advancePaidFormControls, changeTruckDriverFormControls
} from '../../truckHireChallans/truckHireChallans';
import { TrucksService } from '../../trucks/services/trucks.service';
import { Trucks } from '../../trucks/trucks';
import { UsersService } from '../../users/services/users.service';
import { Users } from '../../users/users';
import { TripsService } from '../services/trips.service';
import { Trips } from '../trips';
import { debounceTime, switchMap, distinctUntilChanged, filter, timestamp } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { work_areas } from '../../../core/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { invoicesFormControls, Invoices } from '../../invoices/invoices';
import { consignmentNotesFormControls, ConsignmentNotes, CNoteDataSource } from '../../consignmentNotes/consignmentNotes';
import { consignmentTripTrackingsFormControls } from '../../consignmentTripTrackings/consignmentTripTrackings';
import { saveAs } from 'file-saver';
import { ConsignmentItems, consignmentItemsFormControls } from '../../consignmentItems/consignmentItems';
import { ConsignmentTrips } from '../../consignmentTrips/consignmentTrips';
import { style, trigger, state, animate, transition } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { PartnerBanks } from '../../partnerBanks/partnerBanks';
import { URL } from 'url';

declare var gapi: any;
@Component({
  selector: 'app-trips-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    TripsService,
    ConsignmentTripTrackingsService,
    DialogService,
    DriverRatingsService,
    DatePipe,
    BidsService,
    BookingsService,
    TruckHireChallansService,
    FileTransferService,
    UsersService,
    TrucksService,
    DriversService,
    PartnersService,
    PartnerContactsService,
    AuthenticatorService,
    StorageService,
    ConsignmentNotesService,
  ],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class TripsListComponent implements OnInit {
  // #region Properties (68)

  // #region Properties (60)
  @Input() bookings_id: number;
  @Input() drivers_id: number;
  @Input() partners_id: number;
  @ViewChild('searchBar') searchBar: ElementRef;
  @ViewChild('location') searchLocation: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('updateStatus', { static: true }) updateStatus: TemplateRef<any>;
  @ViewChild('updateLocation', { static: true }) updateLocation: TemplateRef<any>;

  @ViewChild('assignTruck', { static: true }) assignTruck: TemplateRef<any>;
  @ViewChild('truckDocuments', { static: true }) truckDocuments: TemplateRef<any>;
  @ViewChild('confirmTrip', { static: true }) confirmTrip: TemplateRef<any>;
  @ViewChild('requestAdvance', { static: true }) requestAdvance: TemplateRef<any>;
  @ViewChild('approveAdvance', { static: true }) approveAdvance: TemplateRef<any>;
  @ViewChild('advanceCompDocs', { static: true }) advanceCompDocs: TemplateRef<any>;
  @ViewChild('advanceFinApprove', { static: true }) advanceFinApprove: TemplateRef<any>;
  @ViewChild('advancePaid', { static: true }) advancePaid: TemplateRef<any>;
  @ViewChild('requestBalance', { static: true }) requestBalance: TemplateRef<any>;
  @ViewChild('approveBalance', { static: true }) approveBalance: TemplateRef<any>;
  @ViewChild('balanceCompDocs', { static: true }) balanceCompDocs: TemplateRef<any>;
  @ViewChild('balanceFinApprove', { static: true }) balanceFinApprove: TemplateRef<any>;

  @ViewChild('balancePaid', { static: true }) balancePaid: TemplateRef<any>;
  @ViewChild('updateConsignment', { static: true }) updateConsignment: TemplateRef<any>;
  @ViewChild('updateConsignmentTrip', { static: true }) updateConsignmentTrip: TemplateRef<any>;
  @ViewChild('dispatchLocal', { static: true }) dispatchLocal: TemplateRef<any>;
  @ViewChild('dispatchContentTemplate', { static: true }) dispatchContentTemplate: TemplateRef<any>;
  @ViewChild('cNoteDetails', { static: true }) cNoteDetails: TemplateRef<any>;
  @ViewChild('cNoteList', { static: true }) cNoteList: TemplateRef<any>;
  @ViewChild('addConsignment', { static: true }) addConsignment: TemplateRef<any>;
  @ViewChild('itemDetails2', { static: true }) itemDetails2: TemplateRef<any>;
  @ViewChild('truckDocumentsContent', { static: true }) truckDocumentsContent: TemplateRef<any>;
  @ViewChild('updateStatusContent', { static: true }) updateStatusContent: TemplateRef<any>;
  @ViewChild('successTick', { static: true }) successTick: TemplateRef<any>;


  dialogRE: any;
  dialogCDT: any;

  // openModalTruckDocument(truckDocuments, element)
  currentTemplate: TemplateRef<any>;
  usePreviewPanel = false;
  innerWidth: any;
  approveExcessAdvanceFormGroup: FormGroup;
  approveExcessHireFormGroup: FormGroup;
  assignTruckFormGroup: FormGroup;
  changeTruckDriverFormGroup: FormGroup;
  attachTruckDocumentsFormGroup: FormGroup;
  requestAdvanceFormGroup: FormGroup;
  advancePaidFormGroup: FormGroup;

  requestBalanceFormGroup: FormGroup;
  balancePaidFormGroup: FormGroup;

  consignmentPodFormGroup: FormGroup;
  invoicesFormGroup: FormGroup;
  consignmentNotesFormGroup: FormGroup;
  consignmentNoteDetailsFormGroup: FormGroup;
  consignmentItemsFormGroup: FormGroup;
  cNoteFormGroup: FormGroup;

  bidsFormGroup: FormGroup;
  bookingId: number;
  bookings = new Bookings();

  consignmentTripTrackingsFormGroup: FormGroup;
  currentPage = 1;

  flag = false;
  work_area_cds: any;
  displayedColumns = [
    'tripId',
    'CustomerName',
    'SourceCity',
    // 'destination_city',
    'ConsignmentNo',

    // 'BookingDate',
    'trips_status',
    // 'location',
    'emp',
    'TruckRegistrationNo',
    // 'nextSteps',
    'HireTruck'
    // 'edit'
    // 'add'
  ];
  driverList = new Array<Drivers>();
  driverRatingsFormGroup: FormGroup;
  employeeList = new Array<Users>();
  employeeListAbs = new Array<Users>();
  errorMessage = new Array<string>();
  errorMessages = new Array<string>();

  imageUrl: String = AppSettings.IMAGE_ENDPOINT;
  isErr = false;
  isError = false;
  message: string = null;
  minStartDate = new Date();
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  allModelRefs = new Array<BsModalRef>();
  partner: Partners;
  partnerContact: PartnerContacts;
  partnerBanks = new Array<PartnerBanks>();
  partnerContactList = new Array<PartnerContacts>();
  partnerList = new Array<Partners>();
  partner_type_cd: any;
  per_page: number;

  searchBarOpen = true;
  searchBranchCd: string;
  searchExecutiveId: number;
  searchExecutiveName: string;
  searchStatusCd: [];
  searchTerm: string;
  searchTerritoryCd: string;
  total: number;
  tripId: number;
  trip_no: string;
  trips = new Trips();
  tripsFormGroup: FormGroup;
  tripsList = new Array<Trips>();
  tripsSearchForm: FormGroup;
  truckHireChallan: TruckHireChallans;
  truckList = new Array<Trucks>();
  url: any;
  vendorSlipScan: string;
  tripsChangeExecutiveForm: FormGroup;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<Trips>(true, []);
  dataSource = new TripsDataSource();
  deferGetResponse = false;
  sanitizer: any;
  next_status: any;
  next_plus_status: any;
  last_status: any;
  sendConsignmentFormGroup: FormGroup;
  cNote: ConsignmentNotes;
  consignment_notes_id: number;
  consignmentEwayBillList = [];
  consignmentItemList = new Array<ConsignmentItems>();
  consignmentNotesList: ConsignmentNotes[];
  consignment_trips = new ConsignmentTrips();
  // no_of_consignment_items = 0;
  iIndex = 0;
  visiblityState = 'shown';
  isSingleIncompleteLR = false;
  cNoteListDataSource: CNoteDataSource;

  bookingsFormGroup: FormGroup;
  searchSubscription: any;

  isValid: boolean = false;
  enableClose: boolean = false;
  // #endregion

  // #region Constructors (1)

  // #endregion

  // #region Constructors (1)

  constructor(
    protected _formBuilder: FormBuilder,
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: BsModalService,
    protected _snackBarService: MatSnackBar,
    protected _dialogService: DialogService,
    protected tripsService: TripsService,
    protected thcService: TruckHireChallansService,
    protected bookingsService: BookingsService,
    protected userService: UsersService,
    protected truckService: TrucksService,
    protected partnerService: PartnersService,
    protected partnerContactService: PartnerContactsService,
    protected driverService: DriversService,
    protected fileTransferService: FileTransferService,
    protected consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    protected driverRatingsService: DriverRatingsService,
    protected datepipe: DatePipe,
    protected bidsService: BidsService,
    protected authenticatorService: AuthenticatorService,
    protected _sanitizer: DomSanitizer,
    protected consignmentNotesService: ConsignmentNotesService,
    protected dialog: MatDialog
  ) {
    this.sanitizer = _sanitizer;
  }
  ngOnInit() {
    // this.authenticatorService.loadAuth2();
    // this.listFiles();
    this.route.params.subscribe(params => {
      if (+params.bookingId) {
        this.bookings_id = +params.bookingId;
      }
    });
    this.work_area_cds = work_areas;
    this.consignmentTripTrackingsFormGroup = this._formBuilder.group(consignmentTripTrackingsFormControls);
    this.tripsFormGroup = this._formBuilder.group({
      id: ['', []],
      bookings_id: ['', []],
      truck_hire_challans_id: ['', []],
      trips_status_cd: ['', []],
      source_city: ['', []],
      destination_city: ['', []]
    });
    this.driverRatingsFormGroup = this._formBuilder.group({
      id: ['', []],
      rating: ['', []],
      remarks: ['', []],
      drivers_id: ['', []],
      trips_id: ['', []],
      customers_id: ['', []]
    });
    this.tripsSearchForm = this._formBuilder.group({
      search: ['', []],
      territory_cd: ['', []],
      branch_cd: ['', []],
      executive_id: ['', []],
      trips_status_cd: ['', []],
      truck_hire_challan_status_cd: ['', []],
      work_area_cd: ['', []],
      executive_name: ['', []],
      lifecycle_state_cd: ['', []],
      task_name: ['', []],
      unassigned: [false, []],
      tab_activity_index: ['', []],
      tab_global_activity_index: ['', []],
      tab_task_name: ['', []],
      entity_type: ['', []],
      entity_type_list: ['', []],
      list_type: ['', []],
      source_city: ['', []],
      destination_city: ['', []],
      service_type_cd: ['', []],

    });
    this.tripsChangeExecutiveForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      pricing_executive_id: ['', [Validators.required]],
      dispatch_executive_id: ['', [Validators.required]],
      tracking_executive_id: ['', [Validators.required]],
      arrival_executive_id: ['', [Validators.required]],
      pricing_executive_name: ['', []],
      dispatch_executive_name: ['', []],
      tracking_executive_name: ['', []],
      arrival_executive_name: ['', []],
      ops_executive_id: ['', []],
      ops_executive_name: ['', []],
      finance_executive_id: ['', []],
      finance_executive_name: ['', []],
      trips_status_cd: ['', []],
    });
    this.consignmentPodFormGroup = this._formBuilder.group({
      id: ['', []],
      consignment_no: ['', []],
      consignment_status_cd: ['', [Validators.required]],
      pod_remarks: ['', []],
      pod_scan: ['', []],
      unloading_charges: ['', []],
      loading_charges: ['', []],
      detention_charges: ['', []],
      detention_days: ['', []],
      detention_amt_per_day: ['', []],
      damage_deduction: ['', []],
      has_remarks: ['', [Validators.required]],

      pod_scan_2: ['', []],
      gate_in_scan: ['', []],
      // delivery_date: ['', [Validators.required]],
      // delivery_time: ['', [Validators.required]],
      // recieved_by: ['', [Validators.required]],
      // delivery_condition_cd: ['', [Validators.required]],
      delivery_date: ['', []],
      delivery_time: ['', []],
      recieved_by: ['', []],
      delivery_condition_cd: ['', []],
      cancel_reason_cd: ['', []],
      epod_received: ['No', []],
      hard_pod_received: ['No', []],
      physical_pod_dt: ['', []],
      status_type_cd: ['STATUS', []],
      progress_remarks: ['', []],
      follow_up_at: ['', []],
    });
    this.invoicesFormGroup = this._formBuilder.group(invoicesFormControls);
    // this.cNoteFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    // this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.prepareLRForm()
    this.cNoteFormGroup = this._formBuilder.group({
      // trips_id: [null, []],
      last_location: ['', []],
      last_location_lat: [null, []],
      last_location_long: [null, []],
      // consignment_no: ['', []],
      remarks: ['', []],
      consignment_status_cd: ['', [Validators.required]],
      id: [null, []],
      status_at: ['', []],
      status_at_time: ['', []],
      cancel_reason_cd: ['', []]
    });
    this.sendConsignmentFormGroup = this._formBuilder.group({
      id: ['', []],
      to_email: ['', [Validators.required, Validators.email]],
      to_name: ['', [Validators.required]],
    });
    this.assignTruckFormGroup = this._formBuilder.group(assignTruckFormControls);
    this.changeTruckDriverFormGroup = this._formBuilder.group(changeTruckDriverFormControls)
    this.attachTruckDocumentsFormGroup = this._formBuilder.group(attachTruckDocumentsFormControls);
    this.requestAdvanceFormGroup = this._formBuilder.group(requestAdvanceFormControls);
    this.approveExcessHireFormGroup = this._formBuilder.group(approveExcessHireFormControls);
    this.approveExcessAdvanceFormGroup = this._formBuilder.group(approveExcessAdvanceFormControls);
    this.requestBalanceFormGroup = this._formBuilder.group(requestBalanceFormControls);
    this.balancePaidFormGroup = this._formBuilder.group(balancePaidFormControls);


    // const user = StorageService.getUser();
    // if (!this.bookings_id && !this.partners_id && !this.drivers_id) {
    //   if (
    //     (StorageService.userIsLE() ||
    //     StorageService.userIsTE() ||
    //     StorageService.userIsBE()
    //     || StorageService.userIsCT()
    //     )
    //     && (!this.searchExecutiveId)
    //   ) {

    //     this.setExecutive(user);
    //   } else {
    //     if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
    //       this.setterritoryCd([user.territory_cd]);
    //     }
    //   }
    // }

    this.fillSearchFormFromQueryParams(this.route.snapshot.queryParams);
    this.route.queryParams.subscribe(aData => {
      // console.log(aData);
      if (aData && aData.length > 0) {
        this.fillSearchFormFromQueryParams(aData);
      }
      // multiselect issue
    });
    // this.route.queryParams.
    if (!this.deferGetResponse) {
      this.setInitialFilter();
      this.getResponse();
    }


    this.bidsFormGroup = this._formBuilder.group(bidDetailsFormControls);
    this.observeFieldChanges();
    this.searchBarOpen = false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = window.innerWidth;
 //  console.log('window resized ' + this.innerWidth);
  }
  selectRow(row) {
    this.selection.toggle(row);
    // console.log(this.selection);
  }
  observeChangeDriverFieldChanges(aForm) {
    appGlobals.observeChanges(
      this,
      aForm.controls.registration_no,
      'truckList',
      'getTruckSearchService',
      'setTruckList',
      'registration_no'
    );

    appGlobals.observeChanges(
      this,
      aForm.controls.driver_mobile_no,
      'driverList',
      'getDriverSearchService',
      'setDriverList',
      'mobile_no',
      'user'
    );
    appGlobals.observeChanges(
      this,
      aForm.controls.driver_name,
      'driverList',
      'getDriverSearchService',
      'setDriverList',
      'name',
      'user'
    );
  }
  observeFieldChanges() {
    // console.log('setting truck changes');
    this.searchSubscription = appGlobals.observeChanges(
      this,
      this.tripsSearchForm.controls.search,
      'tripsList',
      'getTripsSearchService',
      'setTripsList'
    );
    appGlobals.observeChanges(
      this,
      this.tripsSearchForm.controls.executive_name,
      'employeeListAbs',
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    this.observeChangeDriverFieldChanges(this.assignTruckFormGroup);
    // appGlobals.observeChanges(
    //   this,
    //   this.assignTruckFormGroup.controls.registration_no,
    //   'truckList',
    //   'getTruckSearchService',
    //   'setTruckList',
    //   'registration_no'
    // );

    // appGlobals.observeChanges(
    //   this,
    //   this.assignTruckFormGroup.controls.driver_mobile_no,
    //   'driverList',
    //   'getDriverSearchService',
    //   'setDriverList',
    //   'mobile_no',
    //   'user'
    // );
    // appGlobals.observeChanges(
    //   this,
    //   this.assignTruckFormGroup.controls.driver_name,
    //   'driverList',
    //   'getDriverSearchService',
    //   'setDriverList',
    //   'name',
    //   'user'
    // );
    // appGlobals.observeChanges(
    //   this,
    //   this.assignTruckFormGroup.controls.partner_company_name,
    //   this.partnerList,
    //   'getPartnerSearchService',
    //   'setPartnerListBroker',
    //   'name', null, null, null, 'getPartnerList'
    // );
    appGlobals.observeChanges(
      this,
      this.assignTruckFormGroup.controls.partner_company_name,
      'partnerList',
      'getPartnerSearchService',
      'setPartnerListBroker',
      'partner_company_name'
    );
    appGlobals.observeChanges(
      this,
      this.attachTruckDocumentsFormGroup.controls.partner_company_name,
      'partnerList',
      'getPartnerSearchService',
      'setPartnerListOwner',
      'partner_company_name');
    appGlobals.observeChanges(
      this,
      this.assignTruckFormGroup.controls.partner_contact_name,
      'partnerContactList',
      'getPartnerContactSearchService',
      'setPartnerContactList',
      'name',
      'user');

    appGlobals.observeChanges(
      this,
      this.assignTruckFormGroup.controls.mobile_no,
      'partnerContactList',
      'getPartnerContactSearchService',
      'setPartnerContactList',
      'mobile_no',
      'user');
    // appGlobals.observeChanges(
    //   this,
    //   this.advancePaidFormGroup.controls.advance_payment_cash_paid_by_name,
    //   this.employeeListAbs,
    //   'getEmployeeSearchService',
    //   'setEmployeeList',
    //   'name'
    // );
    // appGlobals.observeChanges(
    //   this,
    //   this.balancePaidFormGroup.controls.balance_payment_cash_paid_by_name,
    //   this.employeeListAbs,
    //   'getEmployeeSearchService',
    //   'setEmployeeList',
    //   'name'
    // );
    this.getMobileProvider();
  }

  // #endregion
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // console.log('is all selected');
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // console.log('master toggle');
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Trips): string {
    // console.log('check box' + (row ? row.id : 'all') );
    if (!row) {
      return "${this.isAllSelected() ? 'select' : 'deselect'} all";
    }
    return "${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id }";
  }
  selectCboxOnChange(event, element) {
    // console.log(event );
    // console.log(element.id);
    return event ? this.selection.toggle(element) : null;
  }
  selectCboxOnChecked(element) {
    // console.log('on checked ' + element.id);
    return this.selection.isSelected(element);
  }
  rowOnClick(row) {
    // console.log('on row click ' + row.id);

    return this.selection.toggle(row);
  }

  getLocationForMulti() {
 //  console.log('get multi select');
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    // alert(numSelected  + ' ' + numRows);
    // alert(Object.entries(this.selection.selected));
    if (numSelected == 0) {
      this._dialogService.openAlert({ title: 'Error', message: 'Please select atleast one row to get location' });
    }
    else {
      const formDataMultiple = [];
      this.selection.selected.forEach(element => {
        formDataMultiple.push(element.id);
      });
   //  console.log(formDataMultiple);
      this.tripsService.refreshLocation({ trips_id: formDataMultiple }).subscribe(
        response => {
          this.enableClose = false;
          if (response.status === 200) {
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this._dialogService.openAlert({
              title: 'Success',
              message: 'Location request has been sent. Please refresh your screen in few minutes check results'
            });
            this.closeModalGeneric();
            if (this.dialogCDT) {
              this.dialogCDT.close();
            }

          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }

  }

  // #region Accessors (1)

  //// #endregion

  // #region Accessors (1)
  setTruckList = function (aData) {
    this.truckList = Trucks.toArray(aData.data);
  };

  get detentionAmount(): number {
    const total =
      this.requestBalanceFormGroup.controls.detention_days.value *
      this.requestBalanceFormGroup.controls.detention_amt_per_day.value;
    this.requestBalanceFormGroup.patchValue({ detention_charges: total });
    return total;
  }

  // #endregion

  // #region Methods (137)

  // #endregion

  // #region Methods (137)

  cancel() {
    this.isErr = false;
    this.errorMessage = new Array<string>();
    this.closeModalGeneric();
    // this.modalRef1.hide();
  }

  chaneUrl(aData) {
    this.url = aData.target.result;
  }
  getTruckSearchService = function (searchTerm) {
    return this.truckService.searchSilent(searchTerm);
  };
  createTripFolder() {
    const fileMetadata = {
      name: this.trips.trip_no,
      mimeType: 'application/vnd.google-apps.folder'
    };
    gapi.client.drive.files.create(
      {
        resource: fileMetadata,
        fields: 'id'
      },
      function (err, file) {
        if (err) {
          // Handle error
       //  console.log(err);
        } else {
       //  console.log('Folder Id: ', file.id);
          return file.id;
        }
      }
    );
  }

  deleteTrips(id) { }

  download(event) {
    this.getResponseToExcel(this.currentPage);
  }

  downloadFile(aData: any) {
    if (aData.status) {
      if (aData.status == 300 && aData.message) {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(aData.message) });
      }
      else if (aData.data) {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(aData.data) });
      }
      return;
    }
 //  console.log(aData);
 //  console.log(aData.status);
 //  console.log(aData.text());
 //  console.log(aData.message);
    const options = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    const fileName = 'Trip-' + new Date().toLocaleDateString();
    appGlobals.createAndDownloadBlobFile(aData, options, fileName);

  }

  editTrips(id) {
    this.router.navigate(['/trips/edit', id]);
  }

  findFolder(fileName, parentId = '') {
    gapi.client.drive.files
      .list({
        // 'q': 'mimeType = "application/vnd.google-apps.folder" and sharedWithMe = true and title = "LR Documents"',
        q:
          "name = '" +
          fileName +
          "' and '" +
          parentId +
          "' in parents and mimeType = 'application/vnd.google-apps.folder'",
        // mimeType = 'application/vnd.google-apps.folder' and sharedWithMe = true and  name = 'Glasswing Master Folder_RD_001'
        // 'sharedWithMe': true,
        pageSize: 10,
        fields: 'nextPageToken, files(id, name)'
      })
      .then(function (response) {
        // console.log(response);
        // console.log('Files:');
        if (response.result && response.result.files) {
          const files = response.result.files;
          if (files && files.length > 0) {
            // console.log(files[0].name + ' (' + files[0].id + ')');
            return files[0].id;
          } else {
            // console.log('No files found.');
            return this.createTripFolder();
          }
        }
      });
  }

  getActionItems(tripData) { }

  getAllStatuses() {
    return Trips.getStatuses(
      this.trips.business_type_cd,
      this.trips.movement_type_cd
    );
  }
  getTruckHireChallan(tripData: Trips) {

    if (tripData.truck_hire_challans && tripData.truck_hire_challans[0]) {
      return tripData.truck_hire_challans[0];
    }
    return false;
  }
  getDriverName(tripData: Trips) {
    if (
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].user
    ) {
      return tripData.truck_hire_challans[0].truck.drivers[0].user.name;
    } else if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck
    ) {
      return 'Driver not assigned';
    }
    return '';
  }

  getDriverNumber(tripData: Trips) {

    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].user
    ) {
      return tripData.truck_hire_challans[0].truck.drivers[0].user.mobile_no;
    } else if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck
    ) {
      return '';
    }
    return '';
  }
  getDriverMobileProvider(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].user
    ) {
      let provider = tripData.truck_hire_challans[0].truck.drivers[0].user.mobile_provider_cd;
      if (provider) {
        return provider.substring(0, 7);
      }
      return provider;
    } else if (
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck
    ) {
      return '';
    }
    return '';
  }
  getBrokerContactName(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].broker_contact &&
      tripData.truck_hire_challans[0].broker_contact.user
    ) {
      return tripData.truck_hire_challans[0].broker_contact.user.name;
    }
    return '';
  }
  getBrokerContactMobile(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].broker_contact &&
      tripData.truck_hire_challans[0].broker_contact.user
    ) {
      return tripData.truck_hire_challans[0].broker_contact.user.mobile_no;
    }
    return '';
  }
  getBrokerName(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].broker
    ) {
      return tripData.truck_hire_challans[0].broker.name.substring(0, 30);
    }
    return '';
  }
  getDriverConsent(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].user
    ) {
      let provider = '';
      if (tripData.truck_hire_challans[0].truck.drivers[0].user.telenity_enabled) {
        provider = tripData.truck_hire_challans[0].truck.drivers[0].user.telenity_consent;
        if (provider) {
          return provider.substring(0, 7);
        }
        else {
          provider = 'Not Taken';
        }
      }
      return provider;
    } else if (
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck
    ) {
      return '';
    }
    return '';
  }

  getDriverSearchService(searchTerm) {
    // console.log('searching driver  ' + searchTerm);
    return this.driverService.searchSilent(searchTerm);
  }

  getEmployeeSearchService(searchTerm) {
    return this.userService.getEmployeesWithLoading(searchTerm);
  }

  getExecutiveSearchService(searchTerm) {
    return this.userService.getEmployees(searchTerm);
  }

  getFolderToUpload() { }

  getLastTracking(trips_id) {
    return this.consignmentTripTrackingsService
      .getLastUpdate(trips_id)
      .subscribe(
        response => {
          this.consignmentTripTrackingsFormGroup.patchValue(response.data);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
  }
  getNextPlusStatus(tripData: Trips, next_status_cd?: string) {
    if (tripData.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    if(!next_status_cd) {
      next_status_cd = Trips.nextStatus(
        tripData.trips_status_cd,
        tripData.business_type_cd,
        tripData.movement_type_cd
      );
    }

    if (next_status_cd === 'TRIP COMPLETE' || next_status_cd === 'CANCELLED') {
      return false;
    }

    const nextStatus2 = Trips.nextStatus(
      next_status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus2 === 'CANCELLED') {
      return false;
    }
    return nextStatus2;
  }
  hasNextStatusSuffx(status_cd, tripData) {
    const nextStatus = Trips.nextStatus(
      status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus && nextStatus != 'CANCELLED') {
      return '';
    }
    return 'no';
  }
  getNextStatus(tripData: Trips, next_status_cd?: string) {
    if (tripData.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    if(!next_status_cd) {
      next_status_cd = Trips.nextStatus(
        tripData.trips_status_cd,
        tripData.business_type_cd,
        tripData.movement_type_cd
      );
    }

    if (next_status_cd === 'VEHICLE ASSIGNED') {
      return false;
    }
    return next_status_cd.toLowerCase();
  }
  getLastStatus(tripData: Trips, next_status_cd?: string) {
    if (tripData.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    if(!next_status_cd) {
      next_status_cd = Trips.nextStatus(
        tripData.trips_status_cd,
        tripData.business_type_cd,
        tripData.movement_type_cd
      );
    }
    if (next_status_cd === 'TRIP COMPLETE' || next_status_cd === 'CANCELLED') {
      return false;
    }

    const nextStatus2 = Trips.nextStatus(
      next_status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus2 === 'TRIP COMPLETE' || nextStatus2 === 'CANCELLED') {
      return false;
    }

    const nextStatus3 = Trips.nextStatus(
      nextStatus2,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus3 === 'CANCELLED') {
      return false;
    }
    return nextStatus3;
  }
  getNextActionButton(element) {
    if (this.needAssignTruckLink(element)) {
      return this.sanitizer.sanitize('<button mat-icon-button [matMenuTriggerFor]="rootMenu"><span class="Rectangle-13"><span class="Mark-Collected">Mark In Transit</span></span><mat-icon>more_vert</mat-icon></button>');
    }

  }
  goToConsignment(element: Trips) {
    if (element.consignment_trips && element.consignment_trips.length && element.consignment_trips[0] && element.consignment_trips[0].consignment_note) {
      this.router.navigate(['/consignmentNotes/edit', element.consignment_trips[0].consignment_notes_id]);
    }
  }


  getNextActionButtonClickFirst(element) {
    // console.log('clicked next action');
    return this.getNextActionButtonClick(element, element.id);
    if (this.needAssignTruckLink(element)) {
      return this.openModalAssignTruck(this.assignTruck, element);
    }
    if (this.needUploadTruckLink(element)) {
      return this.openModalTruckDocument(this.truckDocuments, element);
    }
    return this.updateToNextStatus(element);
  }
  onClickAction(element) {
    // console.log('clicked' + element.id);
    this.getNextActionButtonClick(element, element.id);
  }
  openModalByAction(element, entityAction, entityType, entityId) {

    // console.log(element.trips_status_cd + ' | ' + entityAction + ' | ' +  entityType + ' | ' +  entityId);
    if (this.needConfirmTripLink(element)) {
      // console.log('opening confirm trip link');
      // console.log(element);
      // return  this.sanitizer.sanitize('openModalConfirmTrip(confirmTrip, element)');
      return this.openModalConfirmTrip(this.confirmTrip, element);
    }
    if (this.needAssignTruckLink(element)) {
      // console.log('opening assign truck trip link');
      // return  this.sanitizer.sanitize('openModalAssignTruck(assignTruck, element)');
      return this.openModalAssignTruck(this.assignTruck, element);
    }
    if (this.needUploadTruckLink(element)) {
      // console.log('opening attach trip link');
      // return  this.sanitizer.sanitize('openModalTruckDocument(truckDocuments, element)');
      return this.openModalTruckDocument(this.truckDocuments, element);
    }
    if (this.needCosingnmentUpdate(element)) {
      // console.log('opening update consignment trip link');
      // return  this.sanitizer.sanitize('goToConsignment(element)');
      return this.goToConsignment(element);
    }
    if (this.needConsentSMS(element)) {
   //  console.log('opening need sms trip link');
      // return  this.sanitizer.sanitize('getTelenityLocation(element)');
      return this.getTelenityLocation(element);
    }
    // return  this.sanitizer.sanitize('updateToNextStatus(element)');
 //  console.log('opening next status trip link');
    return this.updateToNextStatus(element);

  }
  getNextActionButtonClick(element, entityId, entityType = 'Trip') {

 //  console.log('clicked next action ' + element.id + this.getNextActionButtonText(element));
    return this.tripsService.getTripsById(entityId).subscribe(
      response => {
     //  console.log(response);
        this.trips = new Trips(response.data);
        this.openModalByAction(element,
          this.getNextActionButtonText(element), entityType, entityId);
        // this.tripsChangeExecutiveForm.patchValue(this.trips);
        // this.lookupEmployees();
        // this.modalRef = this.openModalGeneric(template);
        switch (entityType) {
          case 'Trip': {

            break;
          }
          default: {

          }
        }
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );

  }
  getFirst4(nextStatusCommand) {
    return nextStatusCommand.split(' ').slice(0, 4).join(' ');
  }

  getNextActionButtonText(element) {

    if (this.needConfirmTripLink(element)) {
      return 'Confirm';
    }
    if (this.needAssignTruckLink(element)) {
      return 'Assign Vehicle';
    }
    if (this.needUploadTruckLink(element)) {
      return 'Upload Docs';
    }
    if (this.needCosingnmentUpdate(element)) {
      return 'Update LR';
    }
    if (this.needConsentSMS(element)) {
      return 'Send SMS';
    }
    const nextStatus = this.getNextStatus(element);
    if (nextStatus) {
      const nextStatusCommand = 'Mark ' + nextStatus.toLowerCase();
      return nextStatusCommand.split(' ').slice(0, 4).join(' ');
      // const astring = this.getNextStatus(element) + '';
      // return 'Mark ' + astring.substring(0,20);
    }

  }
  getNoOfConsignments(tripData: Trips) {
    let result = '';
    if (
      tripData.consignment_trips &&
      tripData.consignment_trips.length === 1 &&
      tripData.consignment_trips[0].consignment_note &&
      !tripData.consignment_trips[0].consignment_note.consignment_no
    ) {
      result = result + ' Needs update';
    }
    return result;
  }

  getPartnerContactSearchService(searchTerm) {
 //  console.log('parttner contact search service for '  + searchTerm);
    return this.partnerContactService.searchNew(searchTerm,
      this.assignTruckFormGroup.controls.brokers_id.value);
  }

  getPartnerSearchService(searchTerm) {
 //  console.log('partner search service for '  + searchTerm);
    if(searchTerm instanceof Partners) {
      searchTerm = searchTerm.name;
    }
    else if(typeof searchTerm === 'object'){
      searchTerm = searchTerm.name;
    }
    return this.partnerService.searchNew(searchTerm);
  }
  // processResponse(response) {
  //   if (this.usersId || this.tripsId || this.trucksId || this.bookingsId) {
  //     this.markers = c.toArray(response.data.data, true);
  //   } else {
  //     this.markers = UserMarkers.toArray(response.data.data, false);
  //   }
  //  console.log(this.markers);
  //   this.findBounds();
  //   data = this.markers;
  //   this.dataSource = new UsersDataSource();
  //   this.total = response.data.total;
  //   this.per_page = response.data.per_page;

  //   if (this.layoutType == 'polyline') {
  //     this.fillWayPoints(true);
  //     this.zoom = 7;
  //     this.lat = this.destination['lat'];
  //     this.lng = this.destination['lng'];
  //   }
  // }

  getResponse(pageNo = 1) {
    this.currentPage = pageNo;
    // this.observeFieldChanges();
    return this.tripsService.getList(this.mergeWithSearch()).subscribe(
      response => {
     //  console.log(response);
        this.tripsList = Trips.toArray(response.data);
        // console.log(this.tripsList);
        //data = this.tripsList;
        this.dataSource = new TripsDataSource(this.tripsList);
        this.total = response.total;
        this.per_page = response.per_page;
        // console.log(this.mergeWithSearch());
        setTimeout(() => {
          this.isValid = false;
        }, 3000);
        this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
        this.doPostResponse(response);
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  doPostResponse(response) {

  }
  getResponseByPage(event) {
    this.getResponse(event.pageIndex + 1);
  }
  downloadSalesReport(anEvent) {
    return this.tripsService.exportSaleReport(this.mergeWithSearch()).subscribe(
      response => {
        this.downloadFile(response);
      },
      error => {
     //  console.log(error);
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadSalesReportToday(anEvent) {

    return this.tripsService.exportSaleReport2({time_period: 'Today'}).subscribe(
      response => {
        this.downloadFile(response);
      },
      error => {
     //  console.log(error);
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadSalesReportYesterday(anEvent) {
    return this.tripsService.exportSaleReport2({time_period: 'Yesterday'}).subscribe(
      response => {
        this.downloadFile(response);
      },
      error => {
     //  console.log(error);
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadSalesReportMTD(anEvent) {
    return this.tripsService.exportSaleReport2({time_period: 'MTD'}).subscribe(
      response => {
        this.downloadFile(response);
      },
      error => {
     //  console.log(error);
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  getResponseToExcel(pageNo = 1) {
    this.currentPage = pageNo;
    // this.observeFieldChanges();
    return this.tripsService.exportList(this.mergeWithSearch()).subscribe(
      response => {
        this.downloadFile(response);
      },
      error => {
     //  console.log(error);
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }

  getTargetAdvancePercentage() {
    return this.trips.target_advance_percentage;
  }

  getTargetHireAmt() {
    return this.trips.target_hire_amt;
  }

  getTripsByBooking(id: number) { }
  mergeWithSearch() {
    let mergedSearchResult = Object.assign(
      {},
      {
        page: +this.currentPage,
        bookings_id: this.bookings_id,
        drivers_id: this.drivers_id,
        partners_id: this.partners_id,

      },
      this.tripsSearchForm.value
    );
    mergedSearchResult = appGlobals.removeEmpty(mergedSearchResult);
    return mergedSearchResult;
  }
  // getTripsSearchService(searchTerm) {
  //   return this.tripsService.search({
  //     bookings_id: this.bookings_id,
  //     search: searchTerm,
  //     territory_cd: this.tripsSearchForm.controls.territory_cd.value,
  //     branch_cd: this.tripsSearchForm.controls.branch_cd.value,
  //     executive_id: this.tripsSearchForm.controls.executive_id.value,
  //     trips_status_cd: this.tripsSearchForm.controls.trips_status_cd.value
  //   });
  // }
  getTripsSearchService(searchTerm) {

    return this.tripsService.search(this.mergeWithSearch());
  }

  getTruckRegistrationNo(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck
    ) {
      return tripData.truck_hire_challans[0].truck.registration_no;
    }
    return 'No Vehicle';
  }

  isExcessAdvance() {
    const totalAdvanceAmt =
      this.assignTruckFormGroup.controls.advance_cash_amt.value +
      this.assignTruckFormGroup.controls.advance_transfer_amt.value +
      this.assignTruckFormGroup.controls.advance_utilities_amt.value;
    if (
      this.trips.target_advance_amt &&
      totalAdvanceAmt > this.trips.target_advance_amt
    ) {
      return false;
    }

    return false;
  }

  isExcessHire() {
    const totalHireAmt = this.assignTruckFormGroup.controls.hire_amt.value;
    if (
      this.trips.target_hire_amt &&
      this.trips.target_hire_amt < totalHireAmt
    ) {
      return false;
    }
    return false;
  }

  isStatusCrossed(statusCd, tripData) {
    return Trips.isStatusCrossed(statusCd, tripData);
  }

  isValidate() {
    let valid = true;
    if (!this.bidsFormGroup.get('bid_start').value) {
      valid = false;
      this.errorMessage.push('Start Bid can not be Empty');
    }
    if (!this.bidsFormGroup.get('bid_end').value) {
      valid = false;
      this.errorMessage.push('End Bid can not be Empty');
    }
    if (
      this.bidsFormGroup.get('bid_start').value &&
      this.bidsFormGroup.get('bid_end').value &&
      this.bidsFormGroup.get('bid_end').value <
      this.bidsFormGroup.get('bid_start').value
    ) {
      valid = false;
      this.errorMessage.push('End Bid can not be less then Start Bid');
    }
    if (!this.bidsFormGroup.get('bid_status_cd').value) {
      valid = false;
      this.errorMessage.push('Status type can not be empty');
    }
    if (!this.bidsFormGroup.get('source_location').value) {
      valid = false;
      this.errorMessage.push('Source Location can not be empty');
    }
    if (!this.bidsFormGroup.get('destination_location').value) {
      valid = false;
      this.errorMessage.push('Destination Location can not be empty');
    }
    if (!this.bidsFormGroup.get('truck_type_cd').value) {
      valid = false;
      this.errorMessage.push('Vehicle type can not be empty');
    }
    if (!this.bidsFormGroup.get('goods_type_cd').value) {
      valid = false;
      this.errorMessage.push('Load type can not be empty');
    }
    // if (!this.bidsFormGroup.get('weight_cd').value) {
    //   valid = false;
    //   this.errorMessage.push('Weight type can not be empty');
    // }

    return valid;
  }

  listFiles() {
    if (!gapi.client || !gapi.client.drive) {
   //  console.log('client not loaded yet');
      return;
    }
    const allLRFolders = '1mvOnzgZOrMndZ6iCK2duYj5H8cZCjwIr'; // LR document
    const westFolder = '1qwVp516XlJsJzLvrj4oSCXQFERv1udty'; // WEST
    gapi.client.drive.files
      .list({
        // 'q': 'mimeType = "application/vnd.google-apps.folder" and sharedWithMe = true and title = "LR Documents"',
        q:
          "'1qwVp516XlJsJzLvrj4oSCXQFERv1udty' in parents and mimeType = 'application/vnd.google-apps.folder'",
        // mimeType = 'application/vnd.google-apps.folder' and sharedWithMe = true and  name = 'Glasswing Master Folder_RD_001'
        // 'sharedWithMe': true,
        pageSize: 10,
        fields: 'nextPageToken, files(id, name)'
      })
      .then(function (response) {
     //  console.log(response);
     //  console.log('Files:');
        if (response.result && response.result.files) {
          const files = response.result.files;
          if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
           //  console.log(file.name + ' (' + file.id + ')');
            }
          } else {
         //  console.log('No files found.');
          }
        }
      });
  }

  logElement(element) {
 //  console.log(element);
  }

  needCosingnmentUpdate(tripData: Trips) {
    if (
      (tripData.trips_status_cd == 'REACHED LOADING POINT') ||
      (tripData.trips_status_cd == 'LOADING STARTED') ||
      tripData.trips_status_cd == 'LOADING COMPLETE'
      || tripData.trips_status_cd == 'PAPER WORK DONE'
      || tripData.trips_status_cd == 'TRIP CONFIRMED'
      || tripData.trips_status_cd == 'ORDER CONFIRMED'

    ) {
      if (tripData.consignment_trips && tripData.consignment_trips.length
        && tripData.consignment_trips[0] && tripData.consignment_trips[0].consignment_note
      ) {
        if (!tripData.consignment_trips[0].consignment_note.consignment_no) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    return false;
  }
  needApproveAdvanceLink(tripData: Trips) {
    if (this.userIsLE()) {
      return false;
    }
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.registration_scan &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].driving_license_scan &&
      (tripData.truck_hire_challans[0].truck_hire_challan_status_cd === 'ADVANCE REQUESTED')
    ) {
      return true;
    } else {
      return false;
    }
  }
  needAdvancePaidLink(tripData: Trips) {
    if (this.userIsLE()) {
      return false;
    }
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.registration_scan &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].driving_license_scan &&
      // ['DRAFT', ].indexOf('three') > -1
      (tripData.truck_hire_challans[0].truck_hire_challan_status_cd === 'ADVANCE REQUESTED'
        || tripData.truck_hire_challans[0].truck_hire_challan_status_cd === 'ADVANCE APPROVED')
    ) {
      return true;
    } else {
      return false;
    }
  }

  needAdvanceRequestLink(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.registration_scan &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0] &&
      tripData.truck_hire_challans[0].truck.drivers[0].driving_license_scan &&
      // ['DRAFT', ].indexOf('three') > -1
      (tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
        'DRAFT' ||
        tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
        'VERIFIED' ||
        tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
        'DOCUMENTS ATTACHED') &&
      (this.isStatusCrossed('LOADING COMPLETE', tripData) ||
        this.isStatusCrossed('CONTAINER PICKED', tripData))
    ) {
      return true;
    } else {
      return false;
    }
  }

  needApproveExcessAdvanceLink(tripData: Trips) {
    if (this.userIsLE()) {
      return false;
    }
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
      'BALANCE REQUESTED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  needApproveExcessHireLink(tripData: Trips) {
    if (this.userIsLE()) {
      return false;
    }
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
      'BALANCE REQUESTED'
    ) {
      return true;
    } else {
      return false;
    }
  }
  needConfirmTripLink(tripData: Trips) {
    // if (!tripData.truck_hire_challans || !tripData.truck_hire_challans[0]) {
    // console.log(tripData.trips_status_cd);
    if (tripData.trips_status_cd === 'NEW'
      || tripData.trips_status_cd === 'ORDER CONFIRMED') {
      return true;
      // }
    }
    return false;
  }
  needAssignTruckLink(tripData: Trips) {
    // if (!tripData.truck_hire_challans || !tripData.truck_hire_challans[0]) {
    if (tripData.trips_status_cd === 'TRIP CONFIRMED'
      // || tripData.trips_status_cd === 'ORDER CONFIRMED'
    ) {
      return true;
      // }
    }
    return false;
  }


  needBalancePaidLink(tripData: Trips) {
    if (this.userIsLE()) {
      return false;
    }
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
      'BALANCE REQUESTED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  needBalanceRequestLink(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck_hire_challan_status_cd ===
      'VERIFIED' &&
      tripData.trips_status_cd === 'TRIP COMPLETE'
    ) {
      return true;
    } else {
      return false;
    }
  }

  needUploadCNoteLink(tripData) {
    if (
      !tripData.consignment_trips ||
      tripData.consignment_trips.length === 0 ||
      !tripData.consignment_trips[0].consignment_note ||
      !tripData.consignment_trips[0].consignment_note.consignment_no
    ) {
      return true;
    } else {
      return false;
    }
  }

  needUploadDriverLink(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      (!tripData.truck_hire_challans[0].partners_id ||
        !tripData.truck_hire_challans[0].partner_contacts_id ||
        !tripData.truck_hire_challans[0].truck.drivers ||
        !tripData.truck_hire_challans[0].truck.drivers[0] ||
        !tripData.truck_hire_challans[0].truck.drivers[0].driving_license_scan)
    ) {
      return true;
    } else {
      return false;
    }
  }

  needUploadTruckLink(tripData: Trips) {
    if (
      (tripData.trips_status_cd === 'REACHED LOADING POINT'
        || tripData.trips_status_cd === 'LOADING STARTED'
        || tripData.trips_status_cd === 'LOADING COMPLETE'
        // || tripData.trips_status_cd === 'PAPER WORK DONE'
      )
      && tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      (!tripData.truck_hire_challans[0].partners_id ||
        !tripData.truck_hire_challans[0].partner_contacts_id ||
        !tripData.truck_hire_challans[0].truck ||
        !tripData.truck_hire_challans[0].truck.registration_scan ||
        !tripData.truck_hire_challans[0].truck.drivers ||
        !tripData.truck_hire_challans[0].truck.drivers[0] ||
        !tripData.truck_hire_challans[0].truck.drivers[0].driving_license_scan)
    ) {
      return true;
    } else {
      return false;
    }
  }

  needUploadePODLink(tripData) {
    if (
      tripData.consignment_trips &&
      tripData.consignment_trips.length > 0 &&
      tripData.consignment_trips[0].consignment_note &&
      !tripData.consignment_trips[0].consignment_note.pod_scan &&
      (tripData.trips_status_cd === 'REACHED DESTINATION' ||
        tripData.trips_status_cd === 'UNLOADING STARTED' ||
        tripData.trips_status_cd === 'UNLOADING COMPLETE' ||
        tripData.trips_status_cd === 'PAPER WORK DONE' ||
        tripData.trips_status_cd === 'POD GENERATED' ||
        tripData.trips_status_cd === 'TRIP COMPLETE')
    ) {
      return true;
    } else {
      return false;
    }
  }


  fillSearchFormFromQueryParams(aData) {
 //  console.log('fillSearchFormFromQueryParams');
 //  console.log(aData);
    this.tripsSearchForm.patchValue(aData);
    // if (aData['search']) {
    //   this.tripsSearchForm.patchValue({ search: [aData['search']] });
    // }
    // if (aData['page']) {
    //   this.currentPage = aData['page'];
    // }
    if (aData['trips_status_cd']) {
      if (aData['trips_status_cd'] !== '' && !Array.isArray(aData['trips_status_cd'])) {
        this.tripsSearchForm.patchValue({ trips_status_cd: [aData['trips_status_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ trips_status_cd: aData['trips_status_cd'] });
      }
    }
    if (aData['territory_cd']) {
      if (aData['territory_cd'] !== '' && !Array.isArray(aData['territory_cd'])) {
        this.tripsSearchForm.patchValue({ territory_cd: [aData['territory_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ territory_cd: aData['territory_cd'] });
      }
    }
    if (aData['branch_cd']) {
      if (aData['branch_cd'] !== '' && !Array.isArray(aData['branch_cd'])) {
        this.tripsSearchForm.patchValue({ branch_cd: [aData['branch_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ branch_cd: aData['branch_cd'] });
      }
    }
    if (aData['service_type_cd']) {
      // if (aData['service_type_cd'] !== '' && !Array.isArray(aData['service_type_cd'])) {
      //   this.tripsSearchForm.patchValue({ service_type_cd: [aData['service_type_cd']] });
      // }
      // else {
      this.tripsSearchForm.patchValue({ service_type_cd: aData['service_type_cd'] });
      // }
    }

    if (aData['truck_hire_challan_status_cd']) {
      if (aData['truck_hire_challan_status_cd'] !== '' && !Array.isArray(aData['truck_hire_challan_status_cd'])) {
        this.tripsSearchForm.patchValue({ truck_hire_challan_status_cd: [aData['truck_hire_challan_status_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ truck_hire_challan_status_cd: aData['truck_hire_challan_status_cd'] });
      }
    }
    if (aData['work_area_cd']) {
      if (aData['work_area_cd'] !== '' && !Array.isArray(aData['work_area_cd'])) {
        this.tripsSearchForm.patchValue({ work_area_cd: [aData['work_area_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ work_area_cd: aData['work_area_cd'] });
      }
    }
    if (aData['lifecycle_state_cd']) {
      if (aData['lifecycle_state_cd'] !== '' && !Array.isArray(aData['lifecycle_state_cd'])) {
        this.tripsSearchForm.patchValue({ lifecycle_state_cd: [aData['lifecycle_state_cd']] });
      }
      else {
        this.tripsSearchForm.patchValue({ lifecycle_state_cd: aData['lifecycle_state_cd'] });
      }
    }
    if (aData['task_name']) {
      if (aData['task_name'] !== '' && !Array.isArray(aData['task_name'])) {
        this.tripsSearchForm.patchValue({ task_name: [aData['task_name']] });
      }
      else {
        this.tripsSearchForm.patchValue({ task_name: aData['task_name'] });
      }
    }
    if (aData['source_city']) {
      this.tripsSearchForm.patchValue({ source_city: aData['source_city'] });
    }
    if (aData['destination_city']) {
      this.tripsSearchForm.patchValue({ source_city: aData['destination_city'] });
    }

    if (aData['unassigned']) {
      if (aData['unassigned'] !== 'false') {
        this.tripsSearchForm.patchValue({ unassigned: false });
      }
    }
    if (aData['page']) {
      this.currentPage = aData['page'];
    }
 //  console.log(this.tripsSearchForm.value);
  }
  getPartnerList() {
    return this.partnerList;
  }
  getPartnerContactList() {
    return this.partnerContactList;
  }
  getTruckList() {
    return this.truckList;
  }
  mapView() {
    this.router.navigate(['/trips/map'], { queryParams: { filter: 'new' }, queryParamsHandling: '' });
  }
  isDesktop() {
    if (!this.innerWidth) {
      this.innerWidth = window.innerWidth;
    }
    // console.log(this.innerWidth);
    if (this.innerWidth > 1000) {
      return true;
    }
    return false;
  }
  isPopup() {

  }
  openModalPopup(template: TemplateRef<any>, aData?: any) {
    this.enableClose = true;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    StorageService.modelRefs.push(this.modalRef);
    return this.modalRef;
  }
  closeModalPopup(aModelRef?: BsModalRef) {
    if (aModelRef) {
      aModelRef.hide();
    }

    // else if(this.modalRef &&  this.modalRef != null){
    //   this.modalRef.hide();
    // }
    // else if (this.allModelRefs.length > 0) {
    //   aModelRef = this.allModelRefs.pop();
    //   aModelRef.hide();
    // }
    this.modalRef = StorageService.modelRefs.pop();
    this.modalRef.hide();
  }
  openModalGeneric(template: TemplateRef<any>, aData?: any, forceModal = false) {
    if (!forceModal && this.usePreviewPanel && this.isDesktop()) {
   //  console.log('assigning to right template');
   //  console.log(template);
      this.currentTemplate = template;
      this.modalRef = null;
    }
    else {
   //  console.log('openging modal ' + this.modalService.getModalsCount());
   //  console.log(template.elementRef);
   //  console.log(this.modalService.getModalsCount());
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
   //  console.log(this.modalRef);
   //  console.log(this.modalService.getModalsCount());
      // this.allModelRefs.push(this.modalRef);
      StorageService.modelRefs.push(this.modalRef);
      return this.modalRef;
    }
    return this.modalRef;
  }
  closeModalGeneric(aModelRef?: BsModalRef) {
    if (aModelRef) {
      aModelRef.hide();
    }
    // else if(this.modalRef &&  this.modalRef != null){
    //  console.log('closing generic modal ');
    //  console.log(this.modalRef);
    //  console.log(this.modalService.getModalsCount());
    //   const hideResult = this.modalRef.hide();
    //  console.log(this.modalService.getModalsCount());
    //   // this.modalRef = null;
    // }
    // else if (this.allModelRefs.length > 0) {
    //  console.log(this.allModelRefs);
    //   aModelRef = this.allModelRefs.pop();
    //   aModelRef.hide();
    // }
    if(StorageService.modelRefs.length > 0) {
        this.modalRef = StorageService.modelRefs.pop();
        this.modalRef.hide();
    }
    else if (this.currentTemplate) {
      // this.currentTemplate = aTemplate;
   //  console.log(this.currentTemplate);
      this.currentTemplate = null;
    }
    else if (!this.modalRef || this.modalRef == null) {
   //  console.log('model ref is null');
    }

 //  console.log('closing generic modal');
  }
  openModalUpdateLocation(updateLocation: TemplateRef<any>,
    tripData,
    status_type_cd = 'LOCATION',
    default_status_cd?: any): any {
    this.trips = new Trips(tripData);
    this.prepareUpdateLocation();
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_type_cd: status_type_cd
    });
    if (
      this.trips && this.trips.bookings &&
      this.trips.bookings.consignment_notes &&
      this.trips.bookings.consignment_notes[0]
    ) {
      this.tripsFormGroup.patchValue({
        consignment_no: tripData.bookings.consignment_notes[0].consignment_no
      });
    }
    this.trip_no = tripData.trip_no;
    // console.log();
    this.modalRef = this.openModalGeneric(updateLocation, {
      class: 'modal-lg'
    });
    return this.modalRef;
  }
  openModalUpdateStatus(
    updateLocation: TemplateRef<any>,
    tripData,
    status_type_cd,
    next_status_cd?: any, forceModal = false
  ) {
    this.trips = new Trips(tripData);
    this.prepareUpdateStatus(status_type_cd, next_status_cd);
    if (
      this.trips && this.trips.bookings &&
      this.trips.bookings.consignment_notes &&
      this.trips.bookings.consignment_notes[0]
    ) {
      this.tripsFormGroup.patchValue({
        consignment_no: tripData.bookings.consignment_notes[0].consignment_no
      });
    }
    this.trip_no = tripData.trip_no;
    // console.log();
    this.modalRef = this.openModalGeneric(updateLocation, {
      class: 'modal-lg'
    }, forceModal);
    // this.getLastTracking(tripData.id);
  }

  openModal1(template: TemplateRef<any>, tripId = null, bookingId = null) {
    if (tripId && bookingId) {
      this.tripId = tripId;
      this.bookingId = bookingId;
      this.bookingsService.getBookingsById(bookingId).subscribe(response => {
        this.bookings = response.data;
        this.bidsFormGroup.patchValue({
          source_location: this.bookings.source_city,
          destination_location: this.bookings.destination_city,
          truck_type_cd: this.bookings.truck_type_cd,
          goods_type_cd: this.bookings.goods_type_cd,
          pick_up_date: this.bookings.booking_for_dt,
          no_of_trucks: 1, // this.bookings.no_of_trucks,
          // this.datepipe.transform(this.bookings.booking_for_dt, 'yyyy-MM-dd'),
          booking_for_slot_cd: this.bookings.booking_for_slot_cd
        });
      });
      // this.modalRef1 = this.openModalGeneric(template, { class: 'modal-lg' });
      this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
    }
  }

  openModalRequestAdvance(template, aThc: TruckHireChallans) {
    this.requestAdvanceFormGroup = this._formBuilder.group(requestAdvanceFormControls);
    this.trips = new Trips(aThc.trip);
 //  console.log(this.trips);
    this.trip_no = this.trips.trip_no;
    this.truckHireChallan = aThc;
    this.requestAdvanceFormGroup.patchValue({ trips_id: this.trips.id });
    this.requestAdvanceFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
      return;
    }
    this.setPartnerDetailsForAdvance('BROKER');
 //  console.log(aThc);
 //  console.log(this.requestAdvanceFormGroup.value);
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });

  }
  openModalApproveAdvanceRequest(template, aThc) {
    if (!(aThc.truck_hire_challan_status_cd == 'ADVANCE REQUESTED')) {
      alert('Please select THC with status Advance Requested for Approving');
    }
    this.trips = new Trips(aThc.trip);
    this.trip_no = this.trips.trip_no;
    this.truckHireChallan = aThc;
    this.requestAdvanceFormGroup = this._formBuilder.group(requestAdvanceFormControls);
    this.requestAdvanceFormGroup.patchValue({ trips_id: this.trips.id });
    this.requestAdvanceFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
      return;
    }
    this.setPartnerDetailsForAdvance('BROKER');
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  openModalAdvanceCompDocsLink(template: TemplateRef<any>, aThc) {
    this.truckHireChallan = aThc;
    if (!(aThc.truck_hire_challan_status_cd == 'ADVANCE APPROVED')) {
      alert('Please select THC with status Advance Approved for Documentation');
    }
    this.trips = new Trips(aThc.trip);
    this.trip_no = this.trips.trip_no;
    this.truckHireChallan = aThc;
    this.requestAdvanceFormGroup = this._formBuilder.group(requestAdvanceFormControls);

    this.requestAdvanceFormGroup.patchValue({ trips_id: this.trips.id });
    this.requestAdvanceFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
      return;
    }
    this.setPartnerDetailsForAdvance('BROKER');
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });


  }
  openModalAdvanceFinApproveLink(template: TemplateRef<any>, aThc) {
    this.truckHireChallan = aThc;
    this.trips = new Trips(aThc.trip);
    this.trip_no = this.trips.trip_no;
    this.partner = this.truckHireChallan.broker;
    this.setPartnerofThc();
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  openModalBalanceCompDocsLink(template: TemplateRef<any>, aThc) {
    this.truckHireChallan = aThc;
    if (!(aThc.truck_hire_challan_status_cd == 'BALANCE APPROVED')) {
      alert('Please select THC with status Balance Approved for Documentation');
    }
    this.trips = new Trips(aThc.trip);
    this.trip_no = this.trips.trip_no;
    this.truckHireChallan = aThc;
    this.requestAdvanceFormGroup = this._formBuilder.group(requestAdvanceFormControls);

    this.requestAdvanceFormGroup.patchValue({ trips_id: this.trips.id });
    this.requestAdvanceFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
      return;
    }
    this.setPartnerDetailsForAdvance('BROKER');
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });


  }
  openModalBalanceFinApproveLink(template: TemplateRef<any>, aThc) {
    this.truckHireChallan = aThc;
    this.trips = new Trips(aThc.trip);
    this.trip_no = this.trips.trip_no;
    this.partner = this.truckHireChallan.broker;
    this.setPartnerofThc();
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }

  openModalRequestBalance(template, aThc: TruckHireChallans) {
 //  console.log(aThc);
    this.trips = aThc.trip;
    this.truckHireChallan = aThc;
    this.requestBalanceFormGroup = this._formBuilder.group(requestBalanceFormControls);;
    this.requestBalanceFormGroup.patchValue({ trips_id: this.trips });
    this.requestBalanceFormGroup.patchValue({
      truck_hire_challans_id: aThc.id
    });
    this.setRequestBalancePartnerTypeCd('BROKER');

    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
    }
    this.setPartnerDetailsForBalance('BROKER');
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  openModalAdvancePaid(template, aThc: TruckHireChallans) {
    this.trips = new Trips(aThc.trip);
    this.advancePaidFormGroup = this._formBuilder.group(advancePaidFormControls);;
    //   trips_id: ['', []],
    // partners_id: ['', []],
    // truck_hire_challans_id: ['', []],
    // advance_payment_transfer_amt: ['', [Validators.required]],
    // advance_payment_cash_amt: [0, []],
    // advance_payment_utilities_amt: [0, []],
    // advance_payment_cash_paid_by: ['', []],
    // advance_payment_cash_paid_by_name: ['', []],
    // advance_payment_ref: ['', [Validators.required]],
    // advance_payment_proof_scan: ['', []],
    this.advancePaidFormGroup.patchValue({ trips_id: this.trips.id });
    this.truckHireChallan = aThc;
    this.advancePaidFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.advancePaidFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    if (!this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
      return;
    }
    this.advancePaidFormGroup.patchValue(this.truckHireChallan);
    if (!this.truckHireChallan.advance_payment_transfer_amt) {
      this.advancePaidFormGroup.patchValue({
        advance_payment_transfer_amt: this.truckHireChallan.advance_transfer_amt
      });
      this.advancePaidFormGroup.patchValue({
        advance_payment_cash_amt: this.truckHireChallan.advance_cash_amt
      });
    }
    this.advancePaidFormGroup.patchValue({
      partners_id: this.truckHireChallan.brokers_id
    });

    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  openModalApproveBalanceRequest(template, aThc: TruckHireChallans) {
 //  console.log(aThc);
    this.trips = aThc.trip;
    this.truckHireChallan = aThc;
    this.requestBalanceFormGroup = this._formBuilder.group(requestBalanceFormControls);;
    this.requestBalanceFormGroup.patchValue({ trips_id: this.trips });
    this.requestBalanceFormGroup.patchValue({
      truck_hire_challans_id: aThc.id
    });
    this.setRequestBalancePartnerTypeCd('BROKER');

    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for advance'
      );
    }
    this.setPartnerDetailsForBalance('BROKER');
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  uploadVendorInvoiceScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.requestBalanceFormGroup.patchValue({
          vendor_invoice_scan: response.data.filename
        });
      });
  }
  // getAdvanceAmount(element: TruckHireChallans) {
  //   const result = element.ath_amt;
  //   return result.toLocaleString('en-IN');
  // }

  setPartnerDetailsForBalance(aType = 'BROKER') {
    /*trips_id: ['', []],
  truck_hire_challans_id: ['', []],
  partner_type_cd: ['', [Validators.required]],
  bank_ac_bank_name: ['', []],
  bank_ac_ifsc: ['', []],
  bank_ac_no: ['', []],
  bank_cheque_scan: ['', []],
  pan_no: ['', [Validators.pattern(appGlobals.pan_pattern)]],
  pan_card_scan: ['', []],

  balance_amt: ['', []],
  detention_amt_per_day: ['', []],
  detention_days: ['', []],
  detention_charges: ['', []],
  loading_charges: ['', []],
  unloading_charges: ['', []],
  other_charges: ['', []],
  other_charges_head: ['', []],
  total_balance_amt: ['', []],
  balance_request_cash_amt: ['', []],
  balance_request_transfer_amt: ['', []]
  partners_id: ['', []],
  payment_to_partner_banks_id: ['', []]

    */
    this.requestBalanceFormGroup.patchValue({
      trips_id: this.trips.id
    });
    this.requestBalanceFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.requestBalanceFormGroup.patchValue({
      partner_type_cd: aType
    });
    this.requestBalanceFormGroup.patchValue({
      balance_amt: this.truckHireChallan.balance_amt
    });
    this.requestBalanceFormGroup.patchValue({
      detention_amt_per_day: this.truckHireChallan.detention_amt_per_day
    });
    this.requestBalanceFormGroup.patchValue({
      detention_days: this.truckHireChallan.detention_days
    });
    this.requestBalanceFormGroup.patchValue({
      detention_charges: this.truckHireChallan.detention_charges
    });
    this.requestBalanceFormGroup.patchValue({
      loading_charges: this.truckHireChallan.loading_charges
    });
    this.requestBalanceFormGroup.patchValue({
      unloading_charges: this.truckHireChallan.unloading_charges
    });
    this.requestBalanceFormGroup.patchValue({
      other_charges: this.truckHireChallan.other_charges
    });
    this.requestBalanceFormGroup.patchValue({
      other_charges_head: this.truckHireChallan.other_charges_head
    });
    this.requestBalanceFormGroup.patchValue({
      total_balance_amt: this.truckHireChallan.total_balance_amt
    });
    this.requestBalanceFormGroup.patchValue({
      balance_request_cash_amt: this.truckHireChallan.balance_request_cash_amt
    });
    this.requestBalanceFormGroup.patchValue({
      balance_request_transfer_amt: this.truckHireChallan.balance_request_transfer_amt
    });
    this.requestBalanceFormGroup.patchValue({
      payment_to_partner_banks_id: this.truckHireChallan.payment_to_partner_banks_id
    });
    this.requestBalanceFormGroup.patchValue({
      vendor_invoice_scan: this.truckHireChallan.vendor_invoice_scan
    });

    this.partner = this.truckHireChallan.broker;
    this.setPartnerofThc(aType);

    this.requestBalanceFormGroup.patchValue({ partners_id: this.partner.id });
    if (this.partner.partner_banks && this.partner.partner_banks[0]) {
      let partnerBank = this.partner.partner_banks[0];
      //TODO: check the account to which balance needs to be paid instead of frst one.
      if (this.truckHireChallan.partner_bank && this.truckHireChallan.partner_bank != null) {
        partnerBank = this.truckHireChallan.partner_bank;
      }
      this.requestBalanceFormGroup.patchValue({
        bank_ac_bank_name: partnerBank.bank_ac_bank_name
      });
      this.requestBalanceFormGroup.patchValue({
        partner_banks_id: partnerBank.id
      });
      this.requestBalanceFormGroup.patchValue({
        payment_to_partner_banks_id: partnerBank.id
      });
      this.requestBalanceFormGroup.patchValue({
        bank_ac_ifsc: partnerBank.bank_ac_ifsc
      });
      this.requestBalanceFormGroup.patchValue({
        bank_ac_no: partnerBank.bank_ac_no
      });
      this.requestBalanceFormGroup.patchValue({
        bank_ac_holder_name: partnerBank.bank_ac_holder_name
      });
      this.requestBalanceFormGroup.patchValue({
        bank_cheque_scan: partnerBank.bank_cheque_scan
      });
      // this.requestAdvanceFormGroup.patchValue({ bank_ac_holder_name: partnerBank.bank_ac_holder_name });
    }
  }

  openModalBalancePaid(template, aThc: TruckHireChallans) {
    this.balancePaidFormGroup = this._formBuilder.group(balancePaidFormControls);
    this.trips = aThc.trip;
    this.truckHireChallan = aThc;
    this.setPartnerofThc();
    this.balancePaidFormGroup.patchValue({ trips_id: this.trips.id });
    this.balancePaidFormGroup.patchValue({
      truck_hire_challans_id: aThc.id
    });
    if (this.truckHireChallan.balance_payment_transfer_amt) {
      this.balancePaidFormGroup.patchValue({
        balance_payment_transfer_amt: aThc.balance_payment_transfer_amt
      });
    }
    else {
      this.balancePaidFormGroup.patchValue({
        balance_payment_transfer_amt: aThc.balance_request_transfer_amt
      });
    }


    if (
      !this.truckHireChallan.brokers_id ||
      !this.truckHireChallan.partners_id
    ) {
      alert(
        'Partners not assigned. Please assign truck and upload document to procceed for balance'
      );
    }
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  openModalApproveExcessHire(template, tripData: Trips) {
    this.trips = new Trips(tripData);
    this.approveExcessHireFormGroup.patchValue({ trips_id: this.trips.id });
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
      this.setPartnerofThc();
      this.approveExcessHireFormGroup.patchValue({
        truck_hire_challans_id: this.truckHireChallan.id
      });

      if (
        !this.truckHireChallan.brokers_id ||
        !this.truckHireChallan.partners_id
      ) {
        alert('Partners not assigned.');
        return;
      } else {
        this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
      }
    } else {
      alert('THC not created yet.');
    }
  }

  openModalAssignTruck(template, tripData: Trips) {
    this.trips = new Trips(tripData);
    this.assignTruckFormGroup.reset();
    this.assignTruckFormGroup.patchValue({ trips_id: tripData.id });
    this.assignTruckFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    this.assignTruckFormGroup.patchValue({
      freight_amt: this.trips.getFreightAmt()
    });
    // if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
    //   this.truckHireChallan = this.trips.truck_hire_challans[0];
    //   this.assignTruckFormGroup.patchValue({
    //     truck_hire_challans_id: this.truckHireChallan.id
    //   });
    //   if (this.truckHireChallan.broker) {
    //     let partner = this.truckHireChallan.broker;
    //     this.assignTruckFormGroup.patchValue({
    //       partner_company_name: partner.name
    //     });
    //     this.assignTruckFormGroup.patchValue({
    //       brokers_id: partner.id
    //     });
    //     this.assignTruckFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    //     this.assignTruckFormGroup.patchValue({ pan_no: partner.pan_no });
    //     this.assignTruckFormGroup.patchValue({
    //       pan_card_scan: partner.pan_card_scan
    //     });
    //     if(this.truckHireChallan.broker_contact &&  this.truckHireChallan.broker_contact.user){
    //       let brokerContactUser =  this.truckHireChallan.broker_contact.user;
    //       this.assignTruckFormGroup.patchValue({
    //         partner_contact_name: brokerContactUser.name
    //       });
    //       this.assignTruckFormGroup.patchValue({
    //         mobile_no: brokerContactUser.mobile_no
    //       });
    //       this.assignTruckFormGroup.patchValue({
    //         broker_contacts_id: this.truckHireChallan.broker_contact.id
    //       });
    //     }
    //   }

    //   this.assignTruckFormGroup.patchValue({
    //     freight_amt: this.trips.getFreightAmt()
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     hire_amt: this.truckHireChallan.hire_amt
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     ath_amt: this.truckHireChallan.advance_transfer_amt
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     hire_amt: this.truckHireChallan.hire_amt
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     hire_excess_reason: this.truckHireChallan.hire_excess_reason
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     advance_excess_reason: this.truckHireChallan.advance_excess_reason
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     vendor_slip_scan: this.truckHireChallan.vendor_slip_scan
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     detention_amt_per_day: this.truckHireChallan.detention_amt_per_day
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     reporting_at: this.truckHireChallan.reporting_at
    //   });
    //   if(this.truckHireChallan.reporting_at)  {
    //     this.assignTruckFormGroup.patchValue({
    //       reporting_at_time: this.truckHireChallan.reporting_at.toLocaleTimeString()
    //     });
    //   }

    //   this.assignTruckFormGroup.patchValue({
    //     estimated_transit_time: this.truckHireChallan.estimated_transit_time
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     eway_bill_no: this.truckHireChallan.eway_bill_no
    //   });
    //   if (this.truckHireChallan.truck) {
    //     this.assignTruckFormGroup.patchValue({
    //       registration_no: this.truckHireChallan.truck.registration_no
    //     });
    //     this.assignTruckFormGroup.patchValue({
    //       trucks_id: this.truckHireChallan.truck.id
    //     });
    //     if (
    //       this.truckHireChallan.truck.drivers &&
    //       this.truckHireChallan.truck.drivers[0]
    //     ) {
    //       const driver = this.truckHireChallan.truck.drivers[0];
    //       this.assignTruckFormGroup.patchValue({
    //         driver_name: driver.user.name
    //       });
    //       this.assignTruckFormGroup.patchValue({
    //         drivers_id: driver.id
    //       });
    //       this.assignTruckFormGroup.patchValue({
    //         driver_mobile_no: driver.user.mobile_no
    //       });
    //       // this.assignTruckFormGroup.patchValue({ 'partner_contact_mobile_no': partner.partner_contacts[0].user.mobile_no });
    //     }
    //   }
    // }
    // add trip id to group.
    // check phone number exists??
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  createBooking() {
    this.router.navigate(['/bookings/add']);
  }
  userCanCreateOrder() {
    return StorageService.userCanCreateOrder();
  }
  openModalChangeDriver(template, tripData: Trips, action = true) {

    // this.openModalAssignTruckNew(template, tripData, true);
    this.trips = new Trips(tripData);
    this.truckHireChallan = new TruckHireChallans();
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
      this.changeTruckDriverFormGroup.reset();
      this.observeChangeDriverFieldChanges(this.changeTruckDriverFormGroup);

      this.observeAssignTruckLookupChanges(this.changeTruckDriverFormGroup);
      this.changeTruckDriverFormGroup.patchValue({ truck_hire_challans_id: this.truckHireChallan.id });
      if (this.truckHireChallan.truck) {
        this.assignTruckFormGroup.patchValue({
          registration_no: this.truckHireChallan.truck.registration_no
        });
        this.assignTruckFormGroup.patchValue({
          trucks_id: this.truckHireChallan.truck.id
        });
        if (
          this.truckHireChallan.truck.drivers &&
          this.truckHireChallan.truck.drivers[0]
        ) {
          const driver = this.truckHireChallan.truck.drivers[0];
          this.assignTruckFormGroup.patchValue({
            driver_name: driver.user.name
          });
          this.assignTruckFormGroup.patchValue({
            drivers_id: driver.id
          });
          this.assignTruckFormGroup.patchValue({
            driver_mobile_no: driver.user.mobile_no
          });
        }
      }
    }
    else {
      alert('Please assign vehicle first.');
    }
    // add trip id to group.
    // check phone number exists??
    if (action) {
      this.enableClose = true;
      this.dialogCDT = this.dialog.open(template);
      if (this.dialogCDT) {
        this.dialogCDT.afterClosed().subscribe(result => {
       //  console.log(`Dialog result: ${result}`);
          if (result == true) {
            this.submitTripConfirm();
          }
        });
      }
      else {
        this.enableClose = true;
        this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
      }
    }
  }
  observeAssignTruckLookupChanges(aForm) {
    aForm.get('driver_mobile_no').valueChanges.subscribe(val => {
   //  console.log('driver_mobile_no changed ' + val + ' isdirty ' + aForm.get('driver_mobile_no').dirty);
      if (aForm.get('driver_mobile_no').dirty) {
        aForm.get('drivers_id').reset();
        if (!aForm.get('driver_name').dirty) {
          aForm.get('driver_name').reset();
        }
      }
    });
    aForm.get('driver_name').valueChanges.subscribe(val => {
   //  console.log('driver_name changed ' + val + ' isdirty ' + aForm.get('driver_name').dirty);
      if (aForm.get('driver_name').dirty) {
        aForm.get('drivers_id').reset();
        if (!aForm.get('driver_mobile_no').dirty) {
          aForm.get('driver_mobile_no').reset();
        }
      }
    });
    aForm.get('registration_no').valueChanges.subscribe(val => {
   //  console.log('registration_no changed ' + val + ' isdirty ' + aForm.get('registration_no').dirty);
      if (aForm.get('registration_no').dirty) {
        aForm.get('trucks_id').reset();
        // if(!this.assignTruckFormGroup.get('driver_mobile_no').dirty){
        //   this.assignTruckFormGroup.get('driver_mobile_no').reset();
        // }
      }
    });

  }
  selectDriverByName(aData: Drivers, aForm?) {
    aForm.patchValue({ drivers_id: aData.id });
    aForm.patchValue({
      driver_mobile_no: aData.user.mobile_no
    },
      { emitEvent: false });
    // this.assignTruckFormGroup.patchValue(
    //   { mobile_provider_cd: aData.user.mobile_provider_cd },
    //   { emitEvent: false }
    // );
    aForm.patchValue(
      { telenity_enabled: aData.user.telenity_enabled },
      { emitEvent: false }
    );

    aForm.patchValue({ driver_name: aData.user.name },
      { emitEvent: false });
    // this.getMobileProvider(aData.user.mobile_no);
  }

  selectDriverByNo(aData: Drivers, aForm?) {
    aForm.patchValue({ drivers_id: aData.id });
    aForm.patchValue({
      driver_mobile_no: aData.user.mobile_no
    },
      { emitEvent: false });
    // aForm.patchValue(
    //   { mobile_provider_cd: aData.user.mobile_provider_cd },
    //   { emitEvent: false }
    // );
    aForm.patchValue(
      { telenity_enabled: aData.user.telenity_enabled },
      { emitEvent: false }
    );
    aForm.patchValue({ driver_name: aData.user.name },
      { emitEvent: false });
    // this.getMobileProvider(aData.user.mobile_no);
  }
  selectTruck(option: Trucks, aForm?) {
    aForm.patchValue(
      { trucks_id: option.id },
      { emitEvent: false }
    );
    aForm.patchValue(
      {
        registration_no: option.registration_no
      },
      { emitEvent: false }
    );
    if (option.drivers && option.drivers.length > 0) {
      this.driverList = Drivers.toArray(option.drivers);
      const aDriver = this.driverList[0];
      aForm.patchValue(
        { drivers_id: aDriver.id },
        { emitEvent: false }
      );

      aForm.patchValue(
        { mobile_provider_cd: aDriver.user.mobile_provider_cd },
        { emitEvent: false }
      );

      aForm.patchValue(
        { telenity_enabled: aDriver.user.telenity_enabled },
        { emitEvent: false }
      );
      aForm.patchValue(
        {
          driver_mobile_no: aDriver.user.mobile_no
        },
        // { emitEvent: false }
      );
      aForm.patchValue(
        { driver_name: aDriver.user.name },
        { emitEvent: false }
      );
    }
  }
  getPartnerCompanyNameWithCode(aPartner){
    return aPartner.partner_company_name;
  }
  setPartnerInThcForm(aForm) {
    if (this.partner) {
      // const partner_code_company = this.getPartnerCompanyNameWithCode(this.partner);
      // alert('setting partner again');
      aForm.patchValue({
        partner_company_name: this.partner.partner_company_name
      }, { emitEvent: false });
      aForm.patchValue({
        brokers_id: this.partner.id
      }, { emitEvent: false });
      aForm.patchValue({ pan_no: this.partner.pan_no }, { emitEvent: false });
      aForm.patchValue({
        pan_card_scan: this.partner.pan_card_scan
      }, { emitEvent: false });
    }
    if (this.partnerContact && this.partnerContact.id) {
      let contactUser = this.partnerContact.user;
      aForm.patchValue({
        partner_contact_name: contactUser.name
      }, { emitEvent: false });
      aForm.patchValue({
        mobile_no: contactUser.mobile_no
      }, { emitEvent: false });
      aForm.patchValue({
        broker_contacts_id: this.partnerContact.id
      }, { emitEvent: false });
    }
    if (this.partnerBanks && this.partnerBanks[0]) {
      aForm.patchValue({
        partner_banks_id: this.partnerBanks[0].id,
        bank_ac_bank_name: this.partnerBanks[0].bank_ac_bank_name,
        bank_ac_no: this.partnerBanks[0].bank_ac_no,
        bank_ac_ifsc: this.partnerBanks[0].bank_ac_ifsc,
        bank_ac_holder_name: this.partnerBanks[0].bank_ac_holder_name,
        bank_cheque_scan: this.partnerBanks[0].bank_cheque_scan,
        payment_to_partner_banks_id: this.partnerBanks[0].id,
      }, { emitEvent: false });
    }
    return aForm;
  }

  openModalAssignTruckNew(template, tripData: Trips, action) {
 //  console.log('open assign truck new');
    this.trips = new Trips(tripData);
    this.assignTruckFormGroup.reset();
    this.observeAssignTruckLookupChanges(this.assignTruckFormGroup);
    this.assignTruckFormGroup.patchValue({ trips_id: tripData.id });
    this.assignTruckFormGroup.patchValue({ partner_type_cd: 'BROKER' });
    this.assignTruckFormGroup.patchValue({
      freight_amt: this.trips.getFreightAmt()
    });
    this.assignTruckFormGroup.patchValue({
      estimated_transit_time: this.trips.estimated_transit_time
    });
    this.partner = new Partners();
    this.partnerContact = new PartnerContacts();
    this.partnerBanks = [];
    this.truckHireChallan = new TruckHireChallans();
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
      this.setPartnerofThc();
      this.assignTruckFormGroup.patchValue({
        truck_hire_challans_id: this.truckHireChallan.id
      });
      this.assignTruckFormGroup.patchValue({
        hire_amt: this.truckHireChallan.hire_amt
      });
      this.assignTruckFormGroup.patchValue({
        ath_amt: this.truckHireChallan.advance_transfer_amt
      });
      this.assignTruckFormGroup.patchValue({
        hire_amt: this.truckHireChallan.hire_amt
      });
      this.assignTruckFormGroup.patchValue({
        hire_excess_reason: this.truckHireChallan.hire_excess_reason
      });
      this.assignTruckFormGroup.patchValue({
        advance_excess_reason: this.truckHireChallan.advance_excess_reason
      });
      this.assignTruckFormGroup.patchValue({
        vendor_slip_scan: this.truckHireChallan.vendor_slip_scan
      });
      this.assignTruckFormGroup.patchValue({
        detention_amt_per_day: this.truckHireChallan.detention_amt_per_day
      });
      this.assignTruckFormGroup.patchValue({
        reporting_at: this.truckHireChallan.reporting_at
      });
      if (this.truckHireChallan.reporting_at) {
        this.assignTruckFormGroup.patchValue({
          reporting_at_time: this.truckHireChallan.reporting_at.toLocaleTimeString()
        });
      }

      this.assignTruckFormGroup.patchValue({
        estimated_transit_time: this.truckHireChallan.estimated_transit_time
      });
      this.assignTruckFormGroup.patchValue({
        tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
      });
      this.assignTruckFormGroup.patchValue({
        eway_bill_no: this.truckHireChallan.eway_bill_no
      });
    }
    this.assignTruckFormGroup = this.setPartnerInThcForm(this.assignTruckFormGroup);
    // if(this.partner){
    //   this.assignTruckFormGroup.patchValue({
    //     partner_company_name: this.partner.name
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     brokers_id: this.partner.id
    //   });
    //   this.assignTruckFormGroup.patchValue({ pan_no: this.partner.pan_no });
    //   this.assignTruckFormGroup.patchValue({
    //     pan_card_scan: this.partner.pan_card_scan
    //   });
    // }
    // if(this.partnerContact && this.partnerContact.id){
    //   let contactUser =  this.partnerContact.user;
    //   this.assignTruckFormGroup.patchValue({
    //     partner_contact_name: contactUser.name
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     mobile_no: contactUser.mobile_no
    //   });
    //   this.assignTruckFormGroup.patchValue({
    //     broker_contacts_id: this.partnerContact.id
    //   });
    // }
    // if(this.partnerBanks && this.partnerBanks[0]) {
    //   this.assignTruckFormGroup.patchValue({
    //     partner_banks_id: this.partnerBanks[0].id,
    //     bank_ac_bank_name: this.partnerBanks[0].bank_ac_bank_name,
    //     bank_ac_no: this.partnerBanks[0].bank_ac_no,
    //     bank_ac_ifsc: this.partnerBanks[0].bank_ac_ifsc,
    //     bank_ac_holder_name: this.partnerBanks[0].bank_ac_holder_name,
    //     bank_cheque_scan: this.partnerBanks[0].bank_cheque_scan,
    //   });
    // }
    this.assignTruckFormGroup.patchValue({
      freight_amt: this.trips.getFreightAmt()
    });

    if (this.truckHireChallan.truck) {
      this.assignTruckFormGroup.patchValue({
        registration_no: this.truckHireChallan.truck.registration_no
      });
      this.assignTruckFormGroup.patchValue({
        trucks_id: this.truckHireChallan.truck.id
      });
      if (
        this.truckHireChallan.truck.drivers &&
        this.truckHireChallan.truck.drivers[0]
      ) {
        const driver = this.truckHireChallan.truck.drivers[0];
        this.assignTruckFormGroup.patchValue({
          driver_name: driver.user.name
        });
        this.assignTruckFormGroup.patchValue({
          drivers_id: driver.id
        });
        this.assignTruckFormGroup.patchValue({
          driver_mobile_no: driver.user.mobile_no
        });
        // this.assignTruckFormGroup.patchValue({ 'partner_contact_mobile_no': partner.partner_contacts[0].user.mobile_no });
      }
    }

    // add trip id to group.
    // check phone number exists??
    if (action) {
      this.enableClose = true;
      this.dialogCDT = this.dialog.open(template);
      if (this.dialogCDT) {
        this.dialogCDT.afterClosed().subscribe(result => {
       //  console.log('Dialog result:' + result);
       //  console.log(result);
          if (result == true) {
            this.submitTripConfirm();
          }
        });
      }
    } else {
      this.enableClose = true;
      this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
    }
  }



  openModalForBid1(bidTemplate: TemplateRef<any>, tripData = null) {
    this.trips = new Trips(tripData);
    this.modalRef = this.openModalGeneric(bidTemplate);
  }

  openModalGallery(updateLocation: TemplateRef<any>, tripData) {
    this.trips = tripData;
    this.modalRef = this.openModalGeneric(updateLocation, {
      class: 'modal-lg'
    });
  }

  openModalRating(ratingTemplate: TemplateRef<any>, tripData) {
    this.trips = new Trips(tripData);
    this.driverRatingsFormGroup.reset();
    this.driverRatingsFormGroup.patchValue({ trips_id: this.trips.id });
    this.driverRatingsFormGroup.patchValue({
      customers_id: this.trips.bookings.customers.id
    });
    if (
      this.trips &&
      this.trips.bookings.consignment_notes &&
      this.trips.bookings.consignment_notes[0]
    ) {
      this.driverRatingsFormGroup.patchValue({
        consignment_no: this.trips.bookings.consignment_notes[0].consignment_no
      });
    }
    if (this.trips.rating) {
      this.driverRatingsFormGroup.patchValue(this.trips.rating);
    }
    this.trip_no = this.trips.trip_no;
    this.modalRef = this.openModalGeneric(ratingTemplate);
  }



  openModalTruckDocument(template, tripData: Trips) {
    this.trips = new Trips(tripData);
 //  console.log(this.trips);
    // console.log(tripData);
    this.attachTruckDocumentsFormGroup.reset();
    this.attachTruckDocumentsFormGroup.patchValue({ trips_id: tripData.id });
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: 'FLEET OWNER'
    });
    if (
      !tripData.truck_hire_challans ||
      !tripData.truck_hire_challans[0] ||
      !tripData.truck_hire_challans[0].truck ||
      !tripData.truck_hire_challans[0].truck.drivers ||
      !tripData.truck_hire_challans[0].truck.drivers[0]
    ) {
      alert('Please assign truck / vendor slip first');
      return;
    }
    this.truckHireChallan = tripData.truck_hire_challans[0];
 //  console.log(this.truckHireChallan);
    this.attachTruckDocumentsFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.attachTruckDocumentsFormGroup.patchValue({
      tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
    });

    if (tripData.truck_hire_challans[0].partners) {
      const partner = tripData.truck_hire_challans[0].partners;
      this.attachTruckDocumentsFormGroup.patchValue({
        partners_id: partner.id
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        partner_company_name: partner.partner_company_name
      });
      this.attachTruckDocumentsFormGroup.patchValue({ pan_no: partner.pan_no });
      this.attachTruckDocumentsFormGroup.patchValue({
        pan_card_scan: partner.pan_card_scan
      });
      if (partner.partner_contacts && partner.partner_contacts[0]
        && partner.partner_contacts[0].user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: partner.partner_contacts[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: partner.partner_contacts[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: partner.partner_contacts[0].user.mobile_no
        });
      }
      else {
        // alert();
      }
    }
    if (this.truckHireChallan.truck) {
      const truck = this.truckHireChallan.truck;
      this.attachTruckDocumentsFormGroup.patchValue({
        trucks_id: truck.id
      });
      if (truck.owner_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partners_id: truck.owner_id
        });
      }
      if (truck.owner_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_company_name: truck.owner_name
        });
      }
      if (truck.owner_contacts_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: truck.owner_contacts_id
        });
      }
      if (truck.owner_contacts_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: truck.owner_contacts_name
        });
      }
      if (truck.owner_contact && truck.owner_contact.user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: truck.owner_contact.user.mobile_no
        });
      }

      if (truck.owner_partner) {
        this.attachTruckDocumentsFormGroup.patchValue({ pan_no: truck.owner_partner.pan_no });
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: truck.owner_partner.pan_card_scan
        });
      }
      if (this.truckHireChallan.truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          drivers_id: truck.drivers[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_name: truck.drivers[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_mobile_no: truck.drivers[0].user.mobile_no
        });
      }
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({ trucks_id: truck.id });
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        fitness_scan: truck.fitness_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        insurance_scan: truck.insurance_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        permit_scan: truck.permit_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        pollution_scan: truck.pollution_scan
      });
      if (truck.drivers[0] && truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: truck.drivers[0].driving_license_scan
        });

        this.attachTruckDocumentsFormGroup.patchValue(
          { mobile_provider_cd: truck.drivers[0].user.mobile_provider_cd },
          { emitEvent: false }
        );
        this.attachTruckDocumentsFormGroup.patchValue(
          { telenity_enabled: truck.drivers[0].user.telenity_enabled },
          { emitEvent: false }
        );
      }
    }

 //  console.log(this.attachTruckDocumentsFormGroup.value);

    // add trip id to group.
    // check phone number exists??
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  prepareAttachDocuments(thc: TruckHireChallans) {
    this.attachTruckDocumentsFormGroup.reset();
    this.attachTruckDocumentsFormGroup.patchValue({ trips_id: thc.trips_id });
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: 'FLEET OWNER'
    });
    this.truckHireChallan = thc;
 //  console.log(this.truckHireChallan);
    this.attachTruckDocumentsFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.attachTruckDocumentsFormGroup.patchValue({
      tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
    });

    if (this.truckHireChallan.partners) {
      const partner = this.truckHireChallan.partners;
      this.attachTruckDocumentsFormGroup.patchValue({
        partners_id: partner.id
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        partner_company_name: partner.partner_company_name
      });
      this.attachTruckDocumentsFormGroup.patchValue({ pan_no: partner.pan_no });
      this.attachTruckDocumentsFormGroup.patchValue({
        pan_card_scan: partner.pan_card_scan
      });
      if (partner.partner_contacts && partner.partner_contacts[0] && partner.partner_contacts[0].user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: partner.partner_contacts[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: partner.partner_contacts[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: partner.partner_contacts[0].user.mobile_no
        });
      }
    }
    if (this.truckHireChallan.truck) {
      const truck = this.truckHireChallan.truck;
      this.attachTruckDocumentsFormGroup.patchValue({
        trucks_id: truck.id
      });
      if (truck.owner_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partners_id: truck.owner_id
        });
      }
      if (truck.owner_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_company_name: truck.owner_name
        });
      }
      if (truck.owner_contacts_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: truck.owner_contacts_id
        });
      }
      if (truck.owner_contacts_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: truck.owner_contacts_name
        });
      }
      if (truck.owner_contact && truck.owner_contact.user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: truck.owner_contact.user.mobile_no
        });
      }

      if (truck.owner_partner) {
        this.attachTruckDocumentsFormGroup.patchValue({ pan_no: truck.owner_partner.pan_no });
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: truck.owner_partner.pan_card_scan
        });
      }
   //  console.log(this.truckHireChallan.truck);
      if (this.truckHireChallan.truck.drivers[0]) {
     //  console.log('setting driver details');
        this.attachTruckDocumentsFormGroup.patchValue({
          drivers_id: truck.drivers[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_name: truck.drivers[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_mobile_no: truck.drivers[0].user.mobile_no
        });
      }
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({ trucks_id: truck.id });
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        fitness_scan: truck.fitness_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        insurance_scan: truck.insurance_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        permit_scan: truck.permit_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        pollution_scan: truck.pollution_scan
      });
      if (truck.drivers[0] && truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: truck.drivers[0].driving_license_scan
        });

        this.attachTruckDocumentsFormGroup.patchValue(
          { mobile_provider_cd: truck.drivers[0].user.mobile_provider_cd },
          { emitEvent: false }
        );
        this.attachTruckDocumentsFormGroup.patchValue(
          { telenity_enabled: truck.drivers[0].user.telenity_enabled },
          { emitEvent: false }
        );
      }
    }

 //  console.log(this.attachTruckDocumentsFormGroup.value);
  }
  openModalTruckDocumentThc(template, thc: TruckHireChallans) {
    this.prepareAttachDocuments(thc);
    // add trip id to group.
    // check phone number exists??
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }
  prepareUpdateStatus(status_type_cd = 'STATUS', next_status_cd? : string) {

    if(!next_status_cd) {
      next_status_cd = Trips.nextStatus(
        this.trips.trips_status_cd,
        this.trips.business_type_cd,
        this.trips.movement_type_cd
      );
    }

    this.next_status = this.getNextStatus(this.trips, next_status_cd);
    // this.openModalUpdateStatus(this.updateStatus, tripData, 'STATUS', next_status_cd);

    this.next_plus_status = this.getNextPlusStatus(this.trips, next_status_cd);
    this.last_status = this.getLastStatus(this.trips, next_status_cd);
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
    }
 //  console.log('next status ' + next_status_cd);
 //  console.log(this.trips);
    this.consignmentTripTrackingsFormGroup = this._formBuilder.group(consignmentTripTrackingsFormControls);
    this.consignmentTripTrackingsFormGroup.patchValue({
      trips_id: this.trips.id
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      tasks_id: this.trips.tasks_id
    });
    if (next_status_cd) {
      this.consignmentTripTrackingsFormGroup.patchValue({
        trips_status_cd: next_status_cd
      });
    } else {
      this.consignmentTripTrackingsFormGroup.patchValue({
        trips_status_cd: this.trips.trips_status_cd
      });
    }
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location: this.trips.location
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_lat: this.trips.location_lat
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_long: this.trips.location_long
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_type_cd: status_type_cd
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      is_visible_to_customer: false
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at: new Date()
    });
    // const currentTime = (new Date()).getHours() + ':' + (new Date()).getMinutes();
    // const currentTime = (new Date()).toTimeString();
    // console.log('current time is ' + currentTime);
    let currentHour = (new Date()).getHours() + '';
    if (currentHour.length === 1) {
      currentHour = '0' + currentHour;
    }
    let currentMinutes = (new Date()).getMinutes() + '';
    if (currentMinutes.length === 1) {
      currentMinutes = '0' + currentMinutes;
    }
    const currentTime = currentHour + ':' + currentMinutes;
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at_time: currentTime
    });

    this.trip_no = this.trips.trip_no;
 //  console.log(this.consignmentTripTrackingsFormGroup.value);
  }
  prepareUpdateLocation(status_type_cd = 'STATUS') {
 //  console.log(this.trips);
    this.consignmentTripTrackingsFormGroup = this._formBuilder.group(consignmentTripTrackingsFormControls);
    this.consignmentTripTrackingsFormGroup.patchValue({
      trips_id: this.trips.id
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      tasks_id: this.trips.tasks_id
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      trips_status_cd: this.trips.trips_status_cd
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location: this.trips.location
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_lat: this.trips.location_lat
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_long: this.trips.location_long
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_type_cd: status_type_cd
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      is_visible_to_customer: false
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at: new Date()
    });
    // const currentTime = (new Date()).getHours() + ':' + (new Date()).getMinutes();
    // const currentTime = (new Date()).toTimeString();
    // console.log('current time is ' + currentTime);
    let currentHour = (new Date()).getHours() + '';
    if (currentHour.length === 1) {
      currentHour = '0' + currentHour;
    }
    let currentMinutes = (new Date()).getMinutes() + '';
    if (currentMinutes.length === 1) {
      currentMinutes = '0' + currentMinutes;
    }
    const currentTime = currentHour + ':' + currentMinutes;
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at_time: currentTime
    });

    this.trip_no = this.trips.trip_no;
 //  console.log(this.consignmentTripTrackingsFormGroup.value);
  }
  openModalTruckDocumentOnly(template, tripData: Trips) {
    this.trips = new Trips(tripData);
 //  console.log(this.trips);
 //  console.log(tripData);
    this.attachTruckDocumentsFormGroup.reset();
    this.attachTruckDocumentsFormGroup.patchValue({ trips_id: tripData.id });
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: 'FLEET OWNER'
    });
    if (
      !tripData.truck_hire_challans ||
      !tripData.truck_hire_challans[0] ||
      !tripData.truck_hire_challans[0].truck ||
      !tripData.truck_hire_challans[0].truck.drivers ||
      !tripData.truck_hire_challans[0].truck.drivers[0]
    ) {
      alert('Please assign truck / vendor slip first');
      return;
    }
    this.truckHireChallan = tripData.truck_hire_challans[0];
 //  console.log(this.truckHireChallan);
    this.attachTruckDocumentsFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.attachTruckDocumentsFormGroup.patchValue({
      tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
    });

    if (tripData.truck_hire_challans[0].partners) {
      const partner = tripData.truck_hire_challans[0].partners;
      this.attachTruckDocumentsFormGroup.patchValue({
        partners_id: partner.id
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        partner_company_name: partner.partner_company_name
      });
      this.attachTruckDocumentsFormGroup.patchValue({ pan_no: partner.pan_no });
      this.attachTruckDocumentsFormGroup.patchValue({
        pan_card_scan: partner.pan_card_scan
      });
      if (partner.partner_contacts && partner.partner_contacts[0] && partner.partner_contacts[0].user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: partner.partner_contacts[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: partner.partner_contacts[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: partner.partner_contacts[0].user.mobile_no
        });
      }
    }
    if (this.truckHireChallan.truck) {
      const truck = this.truckHireChallan.truck;
      this.attachTruckDocumentsFormGroup.patchValue({
        trucks_id: truck.id
      });
      if (truck.owner_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partners_id: truck.owner_id
        });
      }
      if (truck.owner_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_company_name: truck.owner_name
        });
      }
      if (truck.owner_contacts_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: truck.owner_contacts_id
        });
      }
      if (truck.owner_contacts_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: truck.owner_contacts_name
        });
      }
      if (truck.owner_contact && truck.owner_contact.user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: truck.owner_contact.user.mobile_no
        });
      }

      if (truck.owner_partner) {
        this.attachTruckDocumentsFormGroup.patchValue({ pan_no: truck.owner_partner.pan_no });
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: truck.owner_partner.pan_card_scan
        });
      }
      if (this.truckHireChallan.truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          drivers_id: truck.drivers[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_name: truck.drivers[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_mobile_no: truck.drivers[0].user.mobile_no
        });
      }
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({ trucks_id: truck.id });
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        fitness_scan: truck.fitness_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        insurance_scan: truck.insurance_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        permit_scan: truck.permit_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        pollution_scan: truck.pollution_scan
      });
      if (truck.drivers[0] && truck.drivers[0].driving_license_scan) {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: truck.drivers[0].driving_license_scan
        });
        this.attachTruckDocumentsFormGroup.patchValue(
          { mobile_provider_cd: truck.drivers[0].user.mobile_provider_cd },
          { emitEvent: false }
        );
        this.attachTruckDocumentsFormGroup.patchValue(
          { telenity_enabled: truck.drivers[0].user.telenity_enabled },
          { emitEvent: false }
        );
      }
    }

 //  console.log(this.attachTruckDocumentsFormGroup.value);

    // add trip id to group.
    // check phone number exists??
    this.modalRef = this.openModalGeneric(template, { class: 'modal-lg' });
  }

  // downloadFile2(blob: any, type: string, filename: string, filetype: string) {
  //   let binaryData = [];
  //   binaryData.push(blob);
  //   const url = window.URL.createObjectURL(new Blob(binaryData, { type: filetype })); // <-- work with blob directly
  //    // create hidden dom element (so it works in all browsers)
  //    const a = document.createElement('a');
  //    a.setAttribute('style', 'display:none;');
  //    document.body.appendChild(a);
  //    // create file, attach to hidden element and open hidden element
  //    a.href = url;
  //    a.download = filename;
  //    a.click();
  // }
  refresh(event) {
    this.getResponse(this.currentPage);
  }

  resetDriver() {
    this.assignTruckFormGroup.controls.drivers_id.reset();
    //  this.assignTruckFormGroup.controls.trucks_id.reset();
  }

  resetSearch() {
    this.searchTerm = null;
    this.searchExecutiveId = null;
    this.searchExecutiveName = null;
    this.searchTerritoryCd = null;

    this.tripsSearchForm.reset();
    // this.setInitialFilter();
    this.getResponse();
  }
  setInitialFilter() {
    if (!this.bookings_id && !this.partners_id && !this.drivers_id) {
   //  console.log('setInitialFilter');
      const user = StorageService.getUser();
      if (
        !StorageService.userIsManagement()
        && !StorageService.userIsROH()
        // StorageService.userIsLE() ||
        // StorageService.userIsTE() ||
        // StorageService.userIsBE()
        // || StorageService.userIsCT()
      ) {
        this.setExecutive(user);
        if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
          this.setterritoryCd([user.territory_cd]);
        }
        if (user.work_area_cd) {
          this.setWorkAreaCd([user.work_area_cd]);
          // this.tripsSearchForm.patchValue({work_area_cd: user.work_area_cd});
        }
      } else {
        if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
          this.setterritoryCd([user.territory_cd]);
        }
        if (user.work_area_cd) {
          this.setWorkAreaCd([user.work_area_cd]);
          // this.tripsSearchForm.patchValue({work_area_cd: user.work_area_cd});
        }
      }

    }
 //  console.log(this.tripsSearchForm.value);
  }

  resetTruck() {
    this.assignTruckFormGroup.controls.trucks_id.reset();
    //  this.assignTruckFormGroup.controls.trucks_id.reset();
  }

  saveFileWithinFolder(file, folderId, fileName) {
    const fileMetadata = {
      name: fileName,
      parents: [folderId]
    };
    const reader = new FileReader();
    const media = {
      // mimeType: 'image/jpeg',
      body: reader.readAsBinaryString(file)
    };
 //  console.log('creating file');
    gapi.client.drive.files
      .create({
        resource: fileMetadata
      })
      .then(function (aResponse) {
        switch (aResponse.status) {
          case 200:
            const file2 = aResponse.result;
         //  console.log('Created Folder Id: ', file2.id);
            break;
          default:
         //  console.log('Error creating the folder, ' + aResponse);
            break;
        }
      });
  }

  saveToTripFolder(fileToSave, fileNameAs, cSaveFileWithinFolder) {
    const parentId = '1qwVp516XlJsJzLvrj4oSCXQFERv1udty'; // WEST
    const tripName = this.trips.trip_no;
    gapi.client.drive.files
      .list({
        // 'q': 'mimeType = "application/vnd.google-apps.folder" and sharedWithMe = true and title = "LR Documents"',
        q:
          "name = '" +
          tripName +
          "' and '" +
          parentId +
          "' in parents and mimeType = 'application/vnd.google-apps.folder' ",
        // mimeType = 'application/vnd.google-apps.folder' and sharedWithMe = true and  name = 'Glasswing Master Folder_RD_001'
        // 'sharedWithMe': true,
        pageSize: 10,
        fields: 'nextPageToken, files(id, name)'
      })
      .then(function (response) {
     //  console.log(response);
     //  console.log('Files:');
        if (response.result && response.result.files) {
          const files = response.result.files;
          if (files && files.length > 0) {
         //  console.log(files[0].name + ' (' + files[0].id + ')');
            return cSaveFileWithinFolder(fileToSave, files[0].id, fileNameAs);
            // return files[0].id;
          } else {
         //  console.log('No files found. creating new');
            const fileMetadata = {
              name: tripName,
              mimeType: 'application/vnd.google-apps.folder',
              parents: [parentId]
            };
         //  console.log('creating new ');
            gapi.client.drive.files
              .create({
                resource: fileMetadata
              })
              .then(function (aResponse) {
                switch (aResponse.status) {
                  case 200:
                    const file = aResponse.result;
                 //  console.log('Created Folder Id: ', file.id);
                    return cSaveFileWithinFolder(
                      fileToSave,
                      file.id,
                      fileNameAs
                    );
                    break;
                  default:
                 //  console.log('Error creating the folder, ' + aResponse);
                    break;
                }
              });
          }
        }
      });
  }

  selectBrokerContactName(aData, anEvent?) {
 //  console.log(aData);
 //  console.log(anEvent);

    this.partnerContact = aData;
    const partnerId = this.assignTruckFormGroup.get('brokers_id').value;
    const partnerName = this.assignTruckFormGroup.get('partner_company_name')
      .value;
    if (partnerId) {
      if (partnerId !== aData.partners_id) {
        this.showErrorDialog(
          'Contact is not of partner choosen ' + partnerName
        );
        return;
      }
    }
    this.assignTruckFormGroup.patchValue({ partner_contact_name: aData.user.name }, { emitEvent: false });
    this.assignTruckFormGroup.patchValue({ broker_contacts_id: aData.id });
    const partnerContactNo = this.assignTruckFormGroup.get('mobile_no').value;
    if (partnerContactNo !== aData.user.mobile_no) {
      this.assignTruckFormGroup.patchValue({ mobile_no: aData.user.mobile_no }, { emitEvent: false });
    }
    // alert('setting broker contact ' + aData.user.name + aData.user.mobile_no);
    // console.log(this.assignTruckFormGroup.value);
  }

  selectBrokerContactNo(aData) {
    // console.log('setting broker contact mobile' + aData.user.mobile_no);
    this.partnerContact = aData;
    this.assignTruckFormGroup.patchValue({ mobile_no: aData.user.mobile_no }, { emitEvent: false });
    const partnerContactName = this.assignTruckFormGroup.get('partner_contact_name').value;
    if (partnerContactName !== aData.user.name) {
      this.assignTruckFormGroup.patchValue({ partner_contact_name: aData.user.name }, { emitEvent: false });
      this.assignTruckFormGroup.patchValue({ broker_contacts_id: aData.id });
    }
  }
  displayPartnerName(aPartner){
 //  console.log(aPartner);
    if(aPartner != null && (aPartner instanceof Partners || (typeof aPartner === 'object' && aPartner.partner_company_name))){
      return aPartner.partner_company_name;
    }
    else if(aPartner != null) {
      return aPartner;
    }


  }
  selectBrokerName(aData: Partners) {
 //  console.log('setting broker ' + aData.name);
 //  console.log(this.assignTruckFormGroup.get('partner_company_name').value);
    this.partner = aData;
    this.partnerContact = new PartnerContacts();
    this.partnerBanks = [];
    this.truckHireChallan = new TruckHireChallans();
    // this.assignTruckFormGroup.patchValue({ partner_company_name: (aData.webxpress_partner_code + ' ' + aData.name) }, { emitEvent: false });
    this.assignTruckFormGroup.patchValue({ brokers_id: aData.id });
    // this.assignTruckFormGroup.patchValue({ pan_no: aData.pan_no });
    // this.assignTruckFormGroup.patchValue({
    //   pan_card_scan: aData.pan_card_scan
    // });
    // if (aData.tds_declaration_scan) {
    //   this.assignTruckFormGroup.patchValue({
    //     tds_declaration_scan: aData.tds_declaration_scan
    //   });
    // }
    // // this.assignTruckFormGroup.patchValue({
    // //   bank_cheque_scan: aData.partner_banks[0].bank_cheque_scan;
    // // });

    if (aData.partner_contacts) {
      this.partnerContactList = aData.partner_contacts;
      if (this.partnerContactList[0]) {
        this.partnerContact = this.partnerContactList[0];
        this.selectBrokerContactName(this.partnerContactList[0]);
        // this.selectBrokerContactNo(this.partnerContactList[0]);
      }
    }
    this.partnerBanks = aData.partner_banks;

    if (aData.partner_vehicles) {
      // TODO: this should be seperate query to get detention by truck type
      if (aData.partner_vehicles[0]) {
        if (aData.partner_vehicles[0].detention_charges) {
          this.assignTruckFormGroup.patchValue({
            detention_amt_per_day: aData.partner_vehicles[0].detention_charges
          });
        }

      }
    }
    this.assignTruckFormGroup = this.setPartnerInThcForm(this.assignTruckFormGroup);

    // console.log(this.assignTruckFormGroup.value);
  }
  selectOwnerName(aData: Partners) {
    this.attachTruckDocumentsFormGroup.patchValue({ partner_company_name: aData.partner_company_name }, { emitEvent: false });
    this.attachTruckDocumentsFormGroup.patchValue({ partners_id: aData.id });
    this.attachTruckDocumentsFormGroup.patchValue({ pan_no: aData.pan_no });
    this.attachTruckDocumentsFormGroup.patchValue({
      pan_card_scan: aData.pan_card_scan
    });
    if (aData.tds_declaration_scan) {
      this.attachTruckDocumentsFormGroup.patchValue({
        tds_declaration_scan: aData.tds_declaration_scan
      });
    }

  }
  setbookingStatusCd(event) {
    this.bookingsFormGroup.patchValue({
      booking_status_cd: event
    });
    if (this.bookingsFormGroup.controls['booking_status_cd'].value == 'CANCELLED') {
      this.bookingsFormGroup.controls['booking_status_cd'].setValidators([Validators.required]);
      this.bookingsFormGroup.controls['booking_status_cd'].updateValueAndValidity();
    }
    else {
      this.bookingsFormGroup.controls['booking_status_cd'].clearValidators();
      this.bookingsFormGroup.controls['booking_status_cd'].updateValueAndValidity();
    }
  }
  getTruckTypeValue(key) {
    const value = appGlobals.getTruckTypeValue(key);
    if (value && value != null) {
      return value.substring(0, 20);
    }
    return '';
  }
  tConvertToAMPM(time) {
    return appGlobals.tConvertToAMPM(time);
  }
  getStringBeforeComma(str) {
    return appGlobals.getStringBeforeComma(str);
  }
  timeSince(timeStamp, timeStamp2 = null) {
    return appGlobals.timeSince(timeStamp, timeStamp2, true);

  }
  isEarlier(timeStamp, timeStamp2 = null) {
    return appGlobals.isEarlier(timeStamp, timeStamp2);

  }
  isEarlierThanToday(timeStamp){
    const today = new Date();
    today.setHours(0,0,0,0);
    // console.log(timeStamp);
    // console.log(today);
    // console.log(appGlobals.isEarlier(timeStamp, today));

    return appGlobals.isEarlier(timeStamp, today);
  }
  isLate(aTrip: Trips){
    let result = false;
    if(aTrip.trips_status_cd != 'CANCELLED' && aTrip.trips_status_cd != 'TRIP COMPLETE'){
      for (let trip_address of aTrip.trip_addresses) {
        let checkDate = trip_address.actual_dispatch_dt;
        if(checkDate && checkDate instanceof Date )
        {
          if(trip_address.trip_address_type_cd == 'PICKUP') {
            continue;
          }
        }
        if(!checkDate  ||  !(checkDate instanceof Date)){
          checkDate = trip_address.estimated_dispatch_dt;
        }
        if(!checkDate  || !(checkDate instanceof Date)){
          checkDate = trip_address.scheduled_dispatch_dt;
        }
        if(!checkDate  || !(checkDate instanceof Date)){
          continue;
        }
        if(checkDate && (aTrip.trips_status_cd != 'CANCELLED' && aTrip.trips_status_cd != 'TRIP COMPLETE')) {
          result = this.isEarlierThanToday(checkDate);
          if(result && (aTrip.trip_no === "TF23199001") ){
            // console.log('Start late ' + aTrip.trip_no + ' ' + trip_address.trip_address_type_cd);
            // console.log(checkDate.toLocaleString());
            // console.log(typeof checkDate);
            // console.log(checkDate instanceof Date);
            // console.log('is late  ' + aTrip.trip_no + ' ' + trip_address.trip_address_type_cd);
            // console.log(result);
            // console.log(trip_address.actual_dispatch_dt ? trip_address.actual_dispatch_dt.toLocaleString() : 'Some date 1');
            // console.log(trip_address.estimated_dispatch_dt ? trip_address.estimated_dispatch_dt.toLocaleString() : 'Some date 2');
            // console.log(trip_address.scheduled_dispatch_dt ? trip_address.scheduled_dispatch_dt.toLocaleString() : 'Some date 3');

            // console.log('Done');
            // if((checkDate instanceof Date)){
            //   // console.log('checkdate is ' + checkDate.toLocaleDateString() + ' '+ checkDate.toLocaleDateString() );
            // }

          }
          if(result) {
            return result;
          }

        }
      }
    }
    return result;
  }
  getToday(){
    const today = new Date();
    today.setHours(0,0,0,0);
    return today;
  }
  isDueToday(aTrip: Trips){
    let result = false;
    if(aTrip.trips_status_cd != 'CANCELLED' && aTrip.trips_status_cd != 'TRIP COMPLETE'){
      for (let trip_address of aTrip.trip_addresses) {
        let checkDate = trip_address.actual_dispatch_dt;
        if( (checkDate && checkDate instanceof Date)  )
        {
          if(trip_address.trip_address_type_cd == 'PICKUP') {
            continue;
          }
        }

        if(!checkDate  ||  !(checkDate instanceof Date)){
          checkDate = trip_address.estimated_dispatch_dt;
          if(!checkDate  || !(checkDate instanceof Date)){
            checkDate = trip_address.estimated_arrival_dt;
          }
        }
        if(!checkDate  || !(checkDate instanceof Date)){
          checkDate = trip_address.scheduled_dispatch_dt;
          if(!checkDate  || !(checkDate instanceof Date)){
            checkDate = trip_address.scheduled_arrival_dt;
          }
        }
        if(!checkDate  || !(checkDate instanceof Date)){
          continue;
        }

        if(checkDate && (checkDate instanceof Date) && (aTrip.trips_status_cd != 'CANCELLED' && aTrip.trips_status_cd != 'TRIP COMPLETE')) {
          result = appGlobals.isToday(checkDate);
          if(result && (aTrip.trip_no === "TF23199001") ){
            // console.log('Start due today ' + aTrip.trip_no + ' ' + trip_address.trip_address_type_cd);
            // console.log(checkDate);
            // console.log(typeof checkDate);
            // console.log(checkDate instanceof Date);
            // console.log('is dur today  ' + aTrip.trip_no + ' ' + trip_address.trip_address_type_cd);
            // console.log(result);
            // console.log(trip_address.actual_dispatch_dt ? trip_address.actual_dispatch_dt.toLocaleDateString() : 'Some date 1');
            // console.log(trip_address.estimated_dispatch_dt ? trip_address.estimated_dispatch_dt.toLocaleDateString() : 'Some date 2');
            // console.log(trip_address.scheduled_dispatch_dt ? trip_address.scheduled_dispatch_dt.toLocaleDateString() : 'Some date 3');
            // console.log('Done');
            // if((checkDate instanceof Date)){
            //   // console.log('checkdate is ' + checkDate.toLocaleDateString() + ' '+ checkDate.toLocaleDateString() );
            // }

          }
          if(result) {
            return result;
          }
        }
      }
    }
    return result;
  }
  getRowColor(aData1: Trips) {
    // console.log(aData);
    if(this.isLate(aData1)) {
      return 'border-red';
    }
    else if(this.isDueToday(aData1)){

      return 'border-orange';
    }
    return 'border-green';
  }
  getAllTripTasks() {
    // return Object.values(Trips.statusesAllIndex);
    let arr1 = Trips.commandsAllIndex;
    arr1 = arr1.concat(TruckHireChallans.commandsAllIndex);
    arr1 = arr1.concat(ConsignmentNotes.commandsAllIndex);
    arr1 = arr1.concat(Invoices.commandsAllIndex);
    arr1 = arr1.concat(Bookings.commandsAllIndex);

    return arr1;
  }
  getAllTripStatuses() {
    return Object.keys(Trips.statusesAllIndex);
  }
  getFirstWordMax15(str) {
    return appGlobals.getFirstWordMax15(str);
  }

  getMobileProvider() {
    this.assignTruckFormGroup.controls.driver_mobile_no.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => {
     //  console.log('mobile no is valid ' + this.assignTruckFormGroup.controls.driver_mobile_no.valid);
        return (this.assignTruckFormGroup.controls.driver_mobile_no.valid && this.assignTruckFormGroup.controls.driver_mobile_no.dirty);
      }),
      switchMap(term => this.tripsService.getMobileProvider(term))).subscribe(
        response => {
          // console.log(response);
          if (response.status === 200) {
            this.assignTruckFormGroup.patchValue(
              { mobile_provider_cd: response.Mobile_Operator },
              { emitEvent: false }
            );
            this.assignTruckFormGroup.patchValue(
              { delivery_condition_cd: 1 },
              { emitEvent: false }
            );

            // console.log(response);

          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    // this.tripsService.getMobileProvider(mobileNo).subscribe(
    //   response => {
    //     // console.log(response);
    //     if (response.status === 200) {
    //       this.assignTruckFormGroup.patchValue(
    //         { mobile_provider_cd: response.Mobile_Operator },
    //         { emitEvent: false }
    //       );
    //       // console.log(response);

    //     } else {
    //       this._dialogService.openAlert({
    //         title: 'Error',
    //         message: response.message
    //       });
    //     }
    //   },
    //   error => {
    //     this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
    //   }
    // );
  }
  selectLocation(event) {
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location: appGlobals.selectCityRegion(event)
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_lat: event.geometry.location.lat()
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_long: event.geometry.location.lng()
    });
  }



  setAdvancePaidBy(option) {
    this.advancePaidFormGroup.patchValue({
      advance_payment_cash_paid_by: option.id
    });
    this.advancePaidFormGroup.patchValue({
      advance_payment_cash_paid_by_name: option.name
    });
  }

  setBalancePaidBy(option) {
    this.balancePaidFormGroup.patchValue({
      balance_payment_cash_paid_by: option.id
    });
    this.balancePaidFormGroup.patchValue({
      balance_payment_cash_paid_by_name: option.name
    });
  }

  setDriverList(aData) {
    this.driverList = Drivers.toArray(aData.data);
  }

  setEmployeeList(aData) {
    this.employeeListAbs = (<any>aData).data.data;
  }

  setExecutive(option) {
    this.tripsSearchForm.patchValue({ executive_id: option.id });
    this.tripsSearchForm.patchValue({ executive_name: option.name });
    this.searchExecutiveId = option.id;
    this.searchExecutiveName = option.name;
  }

  setExecutiveList(aData) {
    this.employeeListAbs = Users.toArray(aData.data.data);
  }

  setPartnerContactList(aData) {
    this.partnerContactList = PartnerContacts.toArray(aData.data);
  }
  setPartnerofThc(aPartnerType = 'BROKER') {
    this.partner = this.truckHireChallan.broker;
    this.partnerContact = this.truckHireChallan.broker_contact;
    if (aPartnerType == 'FLEET OWNER') {
      this.partner = this.truckHireChallan.partners;
      this.partnerContact = this.truckHireChallan.partner_contact;
    }
    if (!this.partnerContact || !this.partnerContact.user) {
      //TODO:  check first  contact
      if (this.partner.partner_contacts && this.partner.partner_contacts[0] && this.partner.partner_contacts[0].user) {
        this.partnerContact = this.partner.partner_contacts[0];
      }
      else {
        alert('Partner ' + this.partner.name + ' does not have valid contact. Please update the partner with proper contact.');
        alert('Do not use same mobile number for  multiple accounts');
        return false;
      }
    }
    this.partnerBanks = this.partner.partner_banks;


    // if (this.partner.partner_contacts && this.partner.partner_contacts[0]) {
    //   if(!this.partner.partner_contacts[0].user){
    //     alert('Do not use same mobile number for  multiple accounts');
    //     return false;
    //   }
    // }
    // else {
    //   alert('Partner ' + this.partner.name + ' does not have valid contact. Please update the partner with proper contact.');
    //   alert('Do not use same mobile number for  multiple accounts');
    //   return false;
    // }
    return true;
  }
  setPartnerDetailsForAdvance(aType = 'BROKER') {
    // patch values
    // trips_id: ['', []],
    // partner_type_cd: [null, [Validators.required]],
    // partners_id: ['', []],
    // partner_contacts_id: ['', []],
    // truck_hire_challans_id: ['', []],
    // bank_ac_bank_name: ['', []],
    // bank_ac_ifsc: ['', []],
    // bank_ac_no: ['', []],
    // bank_ac_holder_name: ['', []],
    // hire_amt: ['', []],
    // advance_cash_amt: [0, []],
    // advance_transfer_amt: ['', []],
    // advance_utilities_amt: ['', []],
    // balance_amt: ['', []],
    // advance_percentage: ['', []],
    // advance_excess_reason: ['', []],
    // advance_excess_approved_by: ['', []],
    // advance_excess_approved_by_name: ['', []],
    // advance_excess_approval_remarks: ['', []],
    // pan_no: ['', [Validators.pattern(appGlobals.pan_pattern)]],
    // pan_card_scan: ['', []],
    // bank_cheque_scan: ['', []],
    // detention_amt_per_day: ['', []],
    // detention_days: ['', []],
    // tds_declaration_scan: ['', []]
    this.requestAdvanceFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.requestAdvanceFormGroup.patchValue({
      hire_amt: this.truckHireChallan.hire_amt
    });
    if (this.truckHireChallan.advance_cash_amt) {
      this.requestAdvanceFormGroup.patchValue({
        advance_cash_amt: this.truckHireChallan.advance_cash_amt
      });
    }
    this.requestAdvanceFormGroup.patchValue({
      advance_transfer_amt: this.truckHireChallan.advance_transfer_amt
    });
    //console.log(aType);
    // console.log(this.truckHireChallan);
    this.setPartnerofThc(aType);
    // this.requestAdvanceFormGroup.patchValue({
    //   partner_contacts_id: this.partner.partner_contacts[0].id
    // });
    this.requestAdvanceFormGroup.patchValue({
      partner_contacts_id: this.truckHireChallan.partner_contacts_id
        ? this.truckHireChallan.partner_contacts_id
        : this.partnerContact.id
    });

    this.requestAdvanceFormGroup.patchValue({ pan_no: this.partner.pan_no });
    this.requestAdvanceFormGroup.patchValue({
      pan_card_scan: this.partner.pan_card_scan
    });
    this.requestAdvanceFormGroup.patchValue({
      tds_declaration_scan: this.partner.tds_declaration_scan
    });

    if (this.partner.partner_banks && this.partner.partner_banks[0]) {
      const partnerBank = this.partner.partner_banks[0];
      this.requestAdvanceFormGroup.patchValue({
        bank_ac_bank_name: partnerBank.bank_ac_bank_name
      });
      this.requestAdvanceFormGroup.patchValue({
        bank_ac_ifsc: partnerBank.bank_ac_ifsc
      });
      this.requestAdvanceFormGroup.patchValue({
        bank_ac_no: partnerBank.bank_ac_no
      });
      this.requestAdvanceFormGroup.patchValue({
        bank_ac_holder_name: partnerBank.bank_ac_holder_name
      });
      this.requestAdvanceFormGroup.patchValue({
        bank_cheque_scan: partnerBank.bank_cheque_scan
      });

      // this.requestAdvanceFormGroup.patchValue({ bank_ac_holder_name: partnerBank.bank_ac_holder_name });
    }
  }

  setPartnerListOwner(aData) {
 //  console.log('setting orner partner list ');
    this.attachTruckDocumentsFormGroup.controls.partners_id.reset();
    this.attachTruckDocumentsFormGroup.controls.partner_contacts_id.reset();
    this.attachTruckDocumentsFormGroup.controls.pan_no.reset();
    this.attachTruckDocumentsFormGroup.controls.pan_card_scan.reset();
 //  console.log(aData);
    this.partnerList = Partners.toArray(aData.data);
    // this.attachTruckDocumentsFormGroup.controls.partner_company_name.reset();
 //  console.log('setting owner partner list ');

    // this.attachTruckDocumentsFormGroup.controls.tds_declaration_scan.reset();
  }
  setPartnerListBroker(aData) {
 //  console.log(aData);
    this.partnerList = Partners.toArray(aData.data);
 //  console.log('setting broker list ' + this.partnerList.length + this.assignTruckFormGroup.controls.partner_company_name.value);

    this.assignTruckFormGroup.controls.brokers_id.reset();
    this.assignTruckFormGroup.controls.broker_contacts_id.reset();
    this.assignTruckFormGroup.controls.pan_no.reset();
    this.assignTruckFormGroup.controls.pan_card_scan.reset();
    // this.assignTruckFormGroup.controls.partner_company_name.reset();
    this.assignTruckFormGroup.controls.partner_contact_name.reset();
    this.assignTruckFormGroup.controls.mobile_no.reset();

    // this.assignTruckFormGroup.controls.tds_declaration_scan.reset();


  }
  getTruckType(aTrip: Trips) {

    if (aTrip.truck_hire_challans && aTrip.truck_hire_challans[0] && aTrip.truck_hire_challans[0].truck) {
      return this.getTruckTypeValue(aTrip.truck_hire_challans[0].truck.truck_type_cd);
    }
    else {
      return this.getTruckTypeValue(aTrip.truck_type_cd);
    }

  }
  setReportindDateWithTime(aData) {
 //  console.log(aData);
    const reportingTime = aData['reporting_at_time'];
    let reportingDate = aData['reporting_at'];
    if (aData['reporting_at'] && typeof aData['reporting_at'] === 'string') {
      reportingDate = new Date((aData['reporting_at']).replace(/-/g, "/"));
    }
    else {
      reportingDate = new Date((aData['reporting_at']));
    }
    if (reportingTime && aData['reporting_at']) {
      const timeArr = reportingTime.split(':');
      reportingDate.setHours(timeArr[0]);
      reportingDate.setMinutes(timeArr[1]);
   //  console.log(reportingDate);
      aData['reporting_at'] = reportingDate;
    } else {
   //  console.log('missing time');
   //  console.log(reportingTime);
   //  console.log(aData['reporting_at']);
    }
 //  console.log(aData);
    return aData;
  }

  setRequestAdvancePartnerTypeCd(event) {
    this.partner_type_cd = event;
    this.requestAdvanceFormGroup.patchValue({
      partner_type_cd: event
    });
    this.setPartnerDetailsForAdvance(event);
  }

  setRequestBalancePartnerTypeCd(event) {
    this.partner_type_cd = event;
    this.requestBalanceFormGroup.patchValue({
      partner_type_cd: event
    });
  }

  setStatusCd(event) {
    this.bidsFormGroup.patchValue({ bid_status_cd: event });
  }

  setThcStatusCd(event) {
    this.tripsSearchForm.patchValue({ truck_hire_challan_status_cd: event });
    // this.searchStatusCd = event;
  }

  setTripStatusCd(event) {
    this.tripsSearchForm.patchValue({ trips_status_cd: event });
    this.searchStatusCd = event;
  }

  setTripsList(aData) {
    this.tripsList = Trips.toArray(aData.data);
    // data = this.tripsList;
    // console.log(this.tripsList);
    this.dataSource = new TripsDataSource(this.tripsList);
    this.total = aData.total;
    this.per_page = aData.per_page;
    this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
  }

  setTripsStatusCd(event) {
    this.consignmentTripTrackingsFormGroup.patchValue({
      trips_status_cd: event
    });
  }

  setWeightCd(event) {
    this.bidsFormGroup.patchValue({ weight_cd: event });
  }

  setbookingForSlotCd(event) {
    this.bidsFormGroup.patchValue({
      booking_for_slot_cd: event
    });
  }

  setbranchCd(event) {
    this.tripsSearchForm.patchValue({ branch_cd: event });
    this.searchBranchCd = event;
  }
  setServiceTypeCd(event) {
    this.tripsSearchForm.patchValue({ service_type_cd: event });
    // this.searchBranchCd = event;
  }

  setWorkAreaCd(event) {
    if (!Array.isArray(event)) {
      this.tripsSearchForm.patchValue({ work_area_cd: [event] });
    }
    else {
      this.tripsSearchForm.patchValue({ work_area_cd: event });
    }
  }
  setgoodsTypeCd(event) {
    this.bidsFormGroup.patchValue({
      goods_type_cd: event
    });
  }

  setpartnerTypeCd(event) {
    this.assignTruckFormGroup.patchValue({
      partner_type_cd: event
    });
  }

  setpartnerTypeCd2(event) {
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: event
    });
  }

  setterritoryCd(event) {
    this.tripsSearchForm.patchValue({ territory_cd: event });
    this.searchTerritoryCd = event;
  }

  settruckTypeCd(event) {
    this.bidsFormGroup.patchValue({
      truck_type_cd: event
    });
  }

  showErrorDialog(error) {
    this._dialogService.openAlert({
      title: Error,
      message: appGlobals.getDisplayErrorMessage(error)
    });
  }

  sendRequest() {
 //  console.log("Req Send");

  }

  submitAssignTruckForm() {
    if (!this.assignTruckFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.assignTruckFormGroup
      );
      appGlobals.markFormGroupTouched(this.assignTruckFormGroup);
      this._dialogService.openAlert({
        title: 'Error',
        message: appGlobals.getFormValidationErrorsDisplayMessage(this.assignTruckFormGroup)
      });
    } else {
      let formData = this.assignTruckFormGroup.value;
      formData = this.setReportindDateWithTime(formData);
   //  console.log(formData);
      this.tripsService.assignTruck(formData).subscribe(
        response => {
          this.enableClose = false;
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            if (this.dialogCDT) {
              this.dialogCDT.close();
            }
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitChangeDriverForm() {
    if (!this.changeTruckDriverFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.changeTruckDriverFormGroup
      );
      appGlobals.markFormGroupTouched(this.changeTruckDriverFormGroup);
    } else {
      let formData = this.changeTruckDriverFormGroup.value;
   //  console.log(formData);
      this.tripsService.changeDriver(formData).subscribe(
        response => {
          this.enableClose = false;
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            if (this.dialogCDT) {
              this.dialogCDT.close();
            }
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitRequestAdvance() {
    if (!this.requestAdvanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestAdvanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestAdvanceFormGroup);
    } else {
      const formData = this.requestAdvanceFormGroup.value;

      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.requestAdvance(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  getAdvancePercentageApproveAdvance(element: TruckHireChallans) {
    let advance_transfer_amt = this.requestAdvanceFormGroup.controls.advance_transfer_amt.value;
    let advance_cash_amt = this.requestAdvanceFormGroup.controls.advance_cash_amt.value;
    let advance =
      +advance_transfer_amt ||
      0 + advance_cash_amt ||
      0 + element.advance_utilities_amt ||
      0;
    if (element.hire_amt > 0) {
      return Math.ceil((advance * 100) / element.hire_amt) + '%';
    }
  }
  submitApproveAdvance() {
    if (!this.requestAdvanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestAdvanceFormGroup
      );
   //  console.log(this.errorMessages);
      appGlobals.markFormGroupTouched(this.requestAdvanceFormGroup);
    } else {
      const formData = this.requestAdvanceFormGroup.value;
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.approveAdvance(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }

  }
  getConsignmentNos(aThc: TruckHireChallans) {
    let consignmentNotes = '';
    // console.log(aThc);
    if (aThc.trip && aThc.trip.consignment_notes) {
      aThc.trip.consignment_notes.forEach(element => {
        if (consignmentNotes == '') {
          consignmentNotes = element.consignment_no;
        }
        else {
          consignmentNotes = consignmentNotes + ', ' + element.consignment_no;
        }

      });
    }

    return consignmentNotes;
  }
  submitAdvanceFinApproved() {
    {
      this.thcService.markAdvanceFinApproved({ truck_hire_challans_id: this.truckHireChallan.id }).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitAdvanceCompDocs() {
    if (!this.requestAdvanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestAdvanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestAdvanceFormGroup);
    } else {
      const formData = this.requestAdvanceFormGroup.value;
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.markAdvanceDocsCompleted(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitRequestBalance() {
 //  console.log(this.requestBalanceFormGroup);
    if (!this.requestBalanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestBalanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestBalanceFormGroup);
    } else {
      const formData = this.requestBalanceFormGroup.value;
   //  console.log(formData);
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
      this.thcService.requestBalance(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitBalanceCompDocs() {
    if (!this.requestBalanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestBalanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestBalanceFormGroup);
    } else {
      const formData = this.requestBalanceFormGroup.value;
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.markBalanceDocsCompleted(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.modalRef.hide();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitApproveBalanceFin() {
    if (!this.requestBalanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestBalanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestBalanceFormGroup);
    } else {
      const formData = this.requestBalanceFormGroup.value;
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.markBalanceFinApproved(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.modalRef.hide();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitApproveBalance() {
    if (!this.requestBalanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.requestBalanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.requestBalanceFormGroup);
    } else {
      const formData = this.requestBalanceFormGroup.value;
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
   //  console.log(formData);
      this.thcService.approveBalance(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitAdvancePaid() {
 //  console.log(this.advancePaidFormGroup);
    if (!this.advancePaidFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.advancePaidFormGroup
      );
      appGlobals.markFormGroupTouched(this.advancePaidFormGroup);
    } else {
      const formData = this.advancePaidFormGroup.value;
   //  console.log(formData);
      this.thcService.markAdvancePaid(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitBalancePaid() {
 //  console.log('balance Paid form');
 //  console.log(this.balancePaidFormGroup);
    if (!this.balancePaidFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.balancePaidFormGroup
      );
      appGlobals.markFormGroupTouched(this.balancePaidFormGroup);
    } else {
      const formData = this.balancePaidFormGroup.value;
   //  console.log(formData);
      this.thcService.markBalancePaid(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitApproveExcessAdvance() {
 //  console.log(this.approveExcessAdvanceFormGroup);
    if (!this.approveExcessAdvanceFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.approveExcessAdvanceFormGroup
      );
      appGlobals.markFormGroupTouched(this.approveExcessAdvanceFormGroup);
    } else {
      const formData = this.approveExcessAdvanceFormGroup.value;
   //  console.log(formData);
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
      this.thcService.approveExcessAdvance(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitApproveExcessHire() {
 //  console.log(this.approveExcessHireFormGroup);
    if (!this.approveExcessHireFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.approveExcessHireFormGroup
      );
      appGlobals.markFormGroupTouched(this.approveExcessHireFormGroup);
    } else {
      const formData = this.approveExcessHireFormGroup.value;
   //  console.log(formData);
      if (formData['partner_type_cd'] === 'BROKER') {
        formData['partners_id'] = this.truckHireChallan.brokers_id;
      } else if (formData['partner_type_cd'] === 'FLEET OWNER') {
        formData['partners_id'] = this.truckHireChallan.partners_id;
      }
      this.thcService.approveExcessHire(formData).subscribe(
        response => {
       //  console.log(response);
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitBid(aData) {
    this.isErr = false;
    this.errorMessage = new Array<string>();
    this.message = null;
    if (this.isValidate()) {
      if (this.bidsFormGroup.valid) {
        const bids = new BidDetails(aData);
        //        bids = aData;
        bids.trips_id = this.tripId;
        bids.bookings_id = this.bookingId;
        // bids.bid_start = this.datepipe.transform(aData.bid_start, 'yyyy-MM-dd');
        // bids.bid_end = this.datepipe.transform(aData.bid_end, 'yyyy-MM-dd');
        // bids.pick_up_date = this.datepipe.transform(aData.pick_up_date, 'yyyy-MM-dd');
        return this.bidsService.addBids(bids).subscribe(response => {
          this.message = null;
          // this.modalRef1.hide();
          this.closeModalGeneric();
          this.bidsFormGroup.reset();
          this.getResponse();
        });
      }
    } else {
      this.isErr = true;
    }
  }

  submitDriverRatingsForm() {
    if (!this.driverRatingsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.driverRatingsFormGroup
      );
      appGlobals.markFormGroupTouched(this.driverRatingsFormGroup);
    } else {
      const formData = this.driverRatingsFormGroup.value;
      if (formData.id) {
        this.driverRatingsService.updateDriverRatings(formData).subscribe(
          response => {
            if (response.status === 200) {
              this.closeModalGeneric();
              this._snackBarService.open(response.message, 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });
            } else {
              this._dialogService.openAlert({
                title: 'Error',
                message: response.message
              });
            }
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      } else {
        this.driverRatingsService.addDriverRatings(formData).subscribe(
          response => {
            if (response.status === 200) {
              this.closeModalGeneric();
              this._snackBarService.open(response.message, 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });
            } else {
              this._dialogService.openAlert({
                title: 'Error',
                message: response.message
              });
            }
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
    }
  }


  submitSearch(aData = {}) {
    return this.getResponse();
  }

  submitTripLocation() {
    if (!this.consignmentTripTrackingsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentTripTrackingsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentTripTrackingsFormGroup);
    } else {
      const formData = this.consignmentTripTrackingsFormGroup.value;
   //  console.log(formData);
      this.consignmentTripTrackingsService.updateLocation(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitTripRemark() {
    if (!this.consignmentTripTrackingsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentTripTrackingsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentTripTrackingsFormGroup);
    } else {
      const formData = this.consignmentTripTrackingsFormGroup.value;
   //  console.log(formData);
      this.consignmentTripTrackingsService.updateLocation(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  lookupEmployees() {
    appGlobals.observeChanges(
      this,
      this.tripsChangeExecutiveForm.controls.dispatch_executive_name,
      this.employeeListAbs,
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    appGlobals.observeChanges(
      this,
      this.tripsChangeExecutiveForm.controls.pricing_executive_name,
      this.employeeListAbs,
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    appGlobals.observeChanges(
      this,
      this.tripsChangeExecutiveForm.controls.tracking_executive_name,
      this.employeeListAbs,
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    appGlobals.observeChanges(
      this,
      this.tripsChangeExecutiveForm.controls.arrival_executive_name,
      this.employeeListAbs,
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
  }

  openModalConfirmTrip(template, tripData: Trips) {
    this.trips = new Trips(tripData);
    this.tripsChangeExecutiveForm.patchValue(this.trips);
    this.lookupEmployees();
    this.modalRef = this.openModalGeneric(template);
  }

  openModalChangeExecutive(template: TemplateRef<any>, tripData = null, action) {
    this.trips = new Trips(tripData);
    this.tripsChangeExecutiveForm.patchValue(this.trips);
    this.lookupEmployees();
    if (action) {
      this.enableClose = true;
      this.dialogRE = this.dialog.open(template);
      if (this.dialogRE) {
        this.dialogRE.afterClosed().subscribe(result => {
       //  console.log(`Dialog result: ${result}`);
          if (result == true) {
            this.submitTripConfirm();
          }
        });
      }
    }
    else {
      this.enableClose = false;
      this.modalRef = this.openModalGeneric(template);
    }
  }

  openModalCancelBooking(template: TemplateRef<any>) {

    this.enableClose = true;
    this.dialogRE = this.dialog.open(template);

    this.dialogRE.afterClosed().subscribe(result => {
   //  console.log(`Dialog result: ${result}`);
      if (result == true) {
        // this.submitTripConfirm();
      }
    });
  }
  openModalSelectFormat(template: TemplateRef<any>) {

    this.enableClose = true;
    this.dialogRE = this.dialog.open(template);

    this.dialogRE.afterClosed().subscribe(result => {
   //  console.log(`Dialog result: ${result}`);
      if (result == true) {
        // this.submitTripConfirm();
      }
    });
  }


  submitTripConfirm() {
    this.tripsChangeExecutiveForm.patchValue({ trips_status_cd: 'TRIP CONFIRMED' });
    return this.submitTripChangeExecutives();
  }
  needSendLr(tripData: Trips) {
    if (!this.needCosingnmentUpdate(tripData)) {
      if (tripData.truck_hire_challans
        && tripData.truck_hire_challans[0]) {
        return true;
      }
    }
    return false;
  }
  openModalSendConsignment(template, tripData: Trips) {
    this.trips = new Trips(tripData);
    this.sendConsignmentFormGroup.reset();
    this.sendConsignmentFormGroup.patchValue({ id: this.trips.id });
    this.modalRef = this.openModalGeneric(template);
  }
  submitSendLr(aData) {
    if (!this.sendConsignmentFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.sendConsignmentFormGroup
      );
      appGlobals.markFormGroupTouched(this.sendConsignmentFormGroup);
      return;
    }
    if (aData) {
      // aData.remarks = this.cNote.remarks  + '<\BR>' + aData.remarks;
      this.tripsService.sendTripConsignmentAsPDF(aData).subscribe(
        response => {
          // this.modalRef.hide();
          this.closeModalGeneric();
          // this.initializeList();
          this._snackBarService.open('Mail sent to ' + aData.to_name, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  downloadPdfFile(responseData: any, entityDisplayData: any) {
    const options = {
      type: 'application/pdf'
    };
    var blob = new Blob([responseData], { type: "application/pdf" });
    saveAs(blob, entityDisplayData + "_download.pdf");
  }
  downloadTripConsignmentPreviewAsPDF(aData) {
    this.tripsService.downloadTripConsignmentPreviewAsPDF({ id: aData.id }).subscribe(
      response => {
     //  console.log('got pdf response');
        this.downloadPdfFile(response, aData.trip_no);
        this._snackBarService.open('TripLR ' + aData.trip_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadTripConsignmentAsPDF(aData) {
    this.tripsService.downloadTripConsignmentAsPDF({ id: aData.id }).subscribe(
      response => {
     //  console.log('got pdf response');
        this.downloadPdfFile(response, aData.trip_no);
        this._snackBarService.open('TripLR ' + aData.trip_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadTripKNConsignmentAsPDF(aData) {
    this.tripsService.downloadTripKNConsignmentAsPDF({ id: aData.id }).subscribe(
      response => {
     //  console.log('got pdf response');
        this.downloadPdfFile(response, aData.trip_no);
        this._snackBarService.open('TripLR ' + aData.trip_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  removeRowFromDataSource(element) {

 //  console.log(element.id + element.trip_no);
    //console.log(this.dataSource.data[this.tripsList.indexOf(element)]);
    const itemIndex = this.dataSource.getIndex(element.id);
 //  console.log(itemIndex);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource = new TripsDataSource(this.dataSource.data);
  }
  submitTripChangeExecutives() {
    if (!this.tripsChangeExecutiveForm.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.tripsChangeExecutiveForm
      );
      appGlobals.markFormGroupTouched(this.tripsChangeExecutiveForm);
    } else {
      const formData = this.tripsChangeExecutiveForm.value;
   //  console.log(formData);
      this.tripsService.updateTrips(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();

            this.getResponse(this.currentPage);
            // this.removeRowFromDataSource(this.trips);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            if (this.dialogRE) {
              this.dialogRE.close();
              this.enableClose = false;
            }

          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  getCancelReasons() {
    return Trips.cancelReasons;
  }
  validateTripStatus() {
    if (!this.consignmentTripTrackingsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentTripTrackingsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentTripTrackingsFormGroup);
      return false;
    }
    const formData = this.consignmentTripTrackingsFormGroup.value;
    formData.status_at = new Date((formData.status_at.toDateString() + ' ' + formData.status_at_time).replace(/-/g, "/"));
    const default_status_cd = formData['trips_status_cd'];
    const afterInTransit = Trips.isStatusCrossed('IN TRANSIT', this.trips);
    // console.log(formData);
    if (!afterInTransit && default_status_cd && default_status_cd != null
      && default_status_cd !== 'IN TRANSIT' && default_status_cd !== 'CANCELLED') {
      // const origStatus = this.trips.trips_status_cd;
      // this.trips.trips_status_cd = default_status_cd;
      if (Trips.isStatusCrossed('IN TRANSIT', this.trips, default_status_cd)) {
        alert('Please mark trip IN TRANSIT before marking it ' + default_status_cd);
        // this.trips.trips_status_cd = origStatus;
        return false;
      }
    }
    if (default_status_cd === 'VEHICLE ASSIGNED') {
      if (!this.trips.truck_hire_challans || !this.trips.truck_hire_challans[0]) {
        alert('Please assign truck before marking trip VEHICLE ASSIGNED');
        return false;
      }
    }
    return true;
  }
  submitTripStatus() {
    if (!this.validateTripStatus()) {
      return;
    }
    const formData = this.consignmentTripTrackingsFormGroup.value;
 //  console.log(formData);
    // return;
    this.consignmentTripTrackingsService.updateStatus(formData).subscribe(
      response => {
        if (response.status === 200) {
          this.isValid = true;
          this.closeModalGeneric();
          window.scrollTo(0, 0);
          this._snackBarService.open(response.message, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
          this.getResponse(this.currentPage);

        } else {
          this._dialogService.openAlert({
            title: 'Error',
            message: response.message
          });
        }
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  submitTruckDocumentOnly() {
 //  console.log('add truck document form');
 //  console.log(this.attachTruckDocumentsFormGroup);
    if (!this.attachTruckDocumentsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.attachTruckDocumentsFormGroup
      );
      appGlobals.markFormGroupTouched(this.attachTruckDocumentsFormGroup);
      if (!this.attachTruckDocumentsFormGroup.get('registration_scan').valid
        && this.attachTruckDocumentsFormGroup.get('registration_scan').errors
          .required
      ) {
        alert('RC scan is required');
      }
      if (!this.attachTruckDocumentsFormGroup.get('driving_license_scan').valid
        && this.attachTruckDocumentsFormGroup.get('driving_license_scan').errors
          .required
      ) {
        alert('Driving License copy is required');
      }
    } else {
      const formData = this.attachTruckDocumentsFormGroup.value;
   //  console.log(formData);
      this.tripsService.attachTruckDocumentOnly(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error)
          });
        }
      );
    }
  }
  validateTruckDocument() {
    if (!this.attachTruckDocumentsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.attachTruckDocumentsFormGroup
      );
      appGlobals.markFormGroupTouched(this.attachTruckDocumentsFormGroup);
      if (!this.attachTruckDocumentsFormGroup.get('registration_scan').valid
        && this.attachTruckDocumentsFormGroup.get('registration_scan').errors
          .required
      ) {
        alert('RC scan is required');
        return false;
      }
      if (!this.attachTruckDocumentsFormGroup.get('driving_license_scan').valid
        && this.attachTruckDocumentsFormGroup.get('driving_license_scan').errors
          .required
      ) {
        alert('Driving License copy is required');
        return false;
      }
      return false;
    }
    return true;
  }
  submitTruckDocument() {
    // console.log('submitTruckDocument');
    if (this.validateTruckDocument()) {
      // console.log('after submitTruckDocument');
      const formData = this.attachTruckDocumentsFormGroup.value;
   //  console.log(formData);
      this.tripsService.attachTruckDocument(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error)
          });
        }
      );
    }
  }

  toggleSearchBar() {
    this.searchBarOpen = !this.searchBarOpen;
  }

  undoSetTruckList(term) {
    // console.log('value changed reset function of truck list ' + term);
  }

  updateToNextStatus(tripData: Trips) {
    const next_status_cd = Trips.nextStatus(
      tripData.trips_status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    this.openModalUpdateStatus(this.updateStatus, tripData, 'STATUS', next_status_cd);
  }
  updateToInTransit(tripData: Trips) {
    // const next_status_cd = Trips.nextStatus(
    //   tripData.trips_status_cd,
    //   tripData.business_type_cd,
    //   tripData.movement_type_cd
    // );
    this.openModalUpdateStatus(this.updateStatus, tripData, 'STATUS', 'IN TRANSIT');
  }
  uploadAdvancePaidProofScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.advancePaidFormGroup.patchValue({
          advance_payment_proof_scan: response.data.filename
        });
      });
  }

  uploadAdvancePanScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.requestAdvanceFormGroup.patchValue({
          pan_card_scan: response.data.filename
        });
      });
  }

  uploadBalanceBankChequeScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.requestBalanceFormGroup.patchValue({
          bank_cheque_scan: response.data.filename
        });
      });
  }
  uploadAssignBankChequeScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.assignTruckFormGroup.patchValue({
          bank_cheque_scan: response.data.filename
        });
      });
  }

  uploadBalancePaidProofScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.balancePaidFormGroup.patchValue({
          balance_payment_proof_scan: response.data.filename
        });
      });
  }

  uploadBrokerPanScan(event, entityId) {
 //  console.log(event);
    // if (event.target.files && event.target.files[0]) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]); // read file as data url
    //   reader.onload = (aresponse) => this.chaneUrl(aresponse);
    // }

    // this.listFiles();
    // this.saveToTripFolder(event.srcElement.files[0], 'Broker PAN' + event.srcElement.files[0].name, this.saveFileWithinFolder);

    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'PAN CARD'
      )
      .subscribe(response => {
        this.assignTruckFormGroup.patchValue({
          pan_card_scan: response.data.filename
        });
      });
  }

  uploadBrokerTdsScan(event, entityId) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.assignTruckFormGroup.patchValue({
          tds_declaration_scan: response.data.filename
        });
      });
  }
  uploadConsignmentTruckScan(event, cNoteId, entityId, aFieldName) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        aFieldName
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.get(aFieldName).setValue(response.data.filename);
        // this.attachTruckDocumentsFormGroup.patchValue({
        //   aFieldName: response.data.filename
        // });
      });
  }
  uploadDrivingLicenseScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'driver',
        'DRIVING LICENSE'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: response.data.filename
        });
      });
  }

  uploadFitnessScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'FITNESS'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          fitness_scan: response.data.filename
        });
      });
  }

  uploadInsuranceScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'INSURANCE'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          insurance_scan: response.data.filename
        });
      });
  }

  uploadOwnerPanScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'PAN CARD'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: response.data.filename
        });
      });
  }

  uploadOwnerTdsScan(event, entityId) {
 //  console.log("ID : ", entityId, event);

    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'TDS DECLARATION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          tds_declaration_scan: response.data.filename
        });
      });
  }

  uploadPartnerBankChequeScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.requestAdvanceFormGroup.patchValue({
          bank_cheque_scan: response.data.filename
        });
      });
  }

  uploadPartnerPanScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'PAN CARD'
      )
      .subscribe(response => {
        this.requestAdvanceFormGroup.patchValue({
          pan_card_scan: response.data.filename
        });
      });
  }

  uploadPermitScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'PERMIT'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          permit_scan: response.data.filename
        });
      });
  }

  uploadPollutionScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'POLLUTION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          pollution_scan: response.data.filename
        });
      });
  }

  uploadRegistrationScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'REGISTRATION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          registration_scan: response.data.filename
        });
      });
  }

  uploadVendorSlip(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.vendorSlipScan = response.data.filename;
        this.assignTruckFormGroup.patchValue({
          vendor_slip_scan: response.data.filename
        });
        this.assignTruckFormGroup.patchValue({
          vendor_slip_url: response.data.file_url
        });
      });
  }
  uploadVendorSlipAdvanceRequest(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.vendorSlipScan = response.data.filename;
        this.requestAdvanceFormGroup.patchValue({
          vendor_slip_scan: response.data.filename
        });
      });
  }
  getAdvancePercentage(element: TruckHireChallans) {
    let advance_transfer_amt = this.requestAdvanceFormGroup.controls.advance_transfer_amt.value;
    let advance_cash_amt = this.requestAdvanceFormGroup.controls.advance_cash_amt.value;
    let advance =
      +advance_transfer_amt ||
      0 + advance_cash_amt ||
      0 + element.advance_utilities_amt ||
      0;
    if (element.hire_amt > 0) {
      return Math.ceil((advance * 100) / element.hire_amt) + '%';
    }
  }
  userIsBE(): any {
    return StorageService.userIsBE();
  }
  userIsCT() {
    return StorageService.userIsCT();
  }
  userIsLE() {
    return StorageService.userIsLE();
  }
  userIsTE() {
    return StorageService.userIsTE();
  }
  userIsROH() {
    return StorageService.userIsROH();
  }
  userIsAdmin(): boolean {
    return StorageService.checkRole([10]);
  }
  userIsFinance(): any {
    return StorageService.userIsFinance();
  }
  userIsBilling(): any {
    return StorageService.userIsBilling();
  }
  userIsCoordinator(): any {
    return StorageService.userIsCoordinator();
  }
  userIsOpsHead(): any {
    return StorageService.userIsOpsHead();
  }
  userIsKAM(): any {
    return StorageService.userIsKAM();
  }
  userIsManagement(): any {
    return StorageService.userIsManagement();
  }
  viewTrips(id) {
    this.router.navigate(['/trips/view', id]);
  }
  getDriver(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck &&
      tripData.truck_hire_challans[0].truck.drivers &&
      tripData.truck_hire_challans[0].truck.drivers[0]

    ) {
      return tripData.truck_hire_challans[0].truck.drivers[0];

    }
    return false;
  }
  getTruck(tripData: Trips) {
    if (
      tripData.truck_hire_challans &&
      tripData.truck_hire_challans[0] &&
      tripData.truck_hire_challans[0].truck

    ) {
      return tripData.truck_hire_challans[0].truck;
    }
    return false;
  }
  getConsignment(tripData: Trips) {
    if (
      tripData.consignment_trips &&
      tripData.consignment_trips[0] &&
      tripData.consignment_trips[0].consignment_note

    ) {
      return tripData.consignment_trips[0].consignment_note;
    }
    return false;
  }

  needCheckLocation(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (
      driver &&
      driver.user
    ) {
      if (
        (driver.user.mobile_provider_cd === 'AIRTEL'
          || driver.user.mobile_provider_cd === 'VODAFONE'
          || driver.user.mobile_provider_cd === 'JIO'
          || driver.user.mobile_provider_cd === 'RELIANCE TELECOM'
          || driver.user.mobile_provider_cd === 'IDEA')
      ) {
        if (driver.user.telenity_consent === 'PENDING' || driver.user.telenity_consent === 'ALLOWED') {
          return true;
        }
        return false;
      }

    }
    return false;
  }
  needEditButton(element) {
    if (StorageService.checkRole([10])) {
      return true;
    }
    return false;
  }
  needEndTripIntugine(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (
      driver &&
      driver.user
    ) {
      // if(driver.user.telenity_consent == 'ALLOWED'){
      if (tripData.intugine_trip_status == 1) {
        return true;
      }
      // if(tripData.intugine_trip_id){
      //   return true;
      // }
      // }
    }
    return false;
  }
  needStartTripIntugine(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (
      driver &&
      driver.user
    ) {
      // if(driver.user.telenity_consent == 'ALLOWED' || driver.user.telenity_consent == 'PENDING'){
      // if(!tripData.intugine_trip_id){
      //   return true;
      // }
      if (tripData.intugine_trip_status == 0
        || (!tripData.intugine_trip_status && driver.user.telenity_consent == 'ALLOWED')
        || (!tripData.intugine_trip_id && driver.user.telenity_consent == 'ALLOWED')) {
        return true;
      }
      // }
    }
    return false;

  }
  needCheckConsent(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (
      driver &&
      driver.user
    ) {

      if (
        driver.user.telenity_consent == 'PENDING'
        && (driver.user.mobile_provider_cd === 'AIRTEL'
          || driver.user.mobile_provider_cd === 'VODAFONE'
          || driver.user.mobile_provider_cd === 'JIO'
          || driver.user.mobile_provider_cd === 'RELIANCE TELECOM'
          || driver.user.mobile_provider_cd === 'IDEA')
      ) {
        return true;
      }

    }
    return false;
  }
  needConsentSMS(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (
      driver &&
      driver.user
    ) {
      if (
        driver.user.telenity_consent !== 'PENDING' &&
        driver.user.telenity_consent !== 'ALLOWED'
        && (driver.user.mobile_provider_cd === 'AIRTEL'
          || driver.user.mobile_provider_cd === 'VODAFONE'
          || driver.user.mobile_provider_cd === 'JIO'
          || driver.user.mobile_provider_cd === 'RELIANCE TELECOM'
          || driver.user.mobile_provider_cd === 'IDEA')
      ) {
        if (driver.user.telenity_consent === 'ALLOWED') {
          return false;
        }
        return true;
      }

    }
    return false;
  }
  dispatchChanged(event) {
    this.tripsChangeExecutiveForm.get('dispatch_executive_id').reset();
    this.tripsChangeExecutiveForm.get('dispatch_executive_name').reset();
  }
  pricingChanged(event) {
    this.tripsChangeExecutiveForm.get('pricing_executive_id').reset();
    this.tripsChangeExecutiveForm.get('pricing_executive_name').reset();

  }
  billingChanged(event) {
    this.tripsChangeExecutiveForm.get('billing_executive_id').reset();
    this.tripsChangeExecutiveForm.get('billing_executive_name').reset();

  }
  arrivalChanged(event) {
    this.tripsChangeExecutiveForm.get('arrival_executive_id').reset();
    this.tripsChangeExecutiveForm.get('arrival_executive_name').reset();
  }
  trackingChanged(event) {
    this.tripsChangeExecutiveForm.get('tracking_executive_id').reset();
    this.tripsChangeExecutiveForm.get('tracking_executive_name').reset();
  }
  setPricingEx(option) {
    this.tripsChangeExecutiveForm.patchValue({ pricing_executive_id: option.id });
    this.tripsChangeExecutiveForm.patchValue(
      { pricing_executive_name: option.name },
      { emitEvent: false }
    );
  }
  setDispatchEx(option) {
    this.tripsChangeExecutiveForm.patchValue({ dispatch_executive_id: option.id });
    this.tripsChangeExecutiveForm.patchValue(
      { dispatch_executive_name: option.name },
      { emitEvent: false }
    );
  }
  setTrackingEx(option) {
    this.tripsChangeExecutiveForm.patchValue({ tracking_executive_id: option.id });
    this.tripsChangeExecutiveForm.patchValue(
      { tracking_executive_name: option.name },
      { emitEvent: false }
    );
  }
  setArrivalEx(option) {
    this.tripsChangeExecutiveForm.patchValue({ arrival_executive_id: option.id });
    this.tripsChangeExecutiveForm.patchValue(
      { arrival_executive_name: option.name },
      { emitEvent: false }
    );
  }
  getLocationConsent(tripData: Trips, template?) {
    // getIntugineGetConsent
    const driver = this.getDriver(tripData);
    if (driver && driver.user) {
      const user = driver.user;
   //  console.log(user);
      const aData = { id: user.id, trips_id: tripData.id };
      const callString = '<p>Please call <b>73 0377 7719</b> from <b>DRIVER MOBILE</b> and enter <b>1</b> when prompted.</p>';
      this.userService.getIntugineGetConsent(aData).subscribe(
        response => {
       //  console.log(response);
          if (response.status && response.status == 500 && response.message) {
            this._dialogService.openAlert({
              title: 'Error ',
              message: response.message
            });
          }
          else if (response.status && response.status == 300 && response.message) {
            this._dialogService.openAlert({
              title: 'Error ',
              message: response.message
            });
          }
          else if (response.data && response.data.message) {
            this._dialogService.openAlert({
              title: response.data.message,
              message: '<p>' + response.data.consent + '</p>' + response.data.consent_suggestion
            });
          }
          else if (response.data && response.data.consent_suggestion) {
            //{"number":"9324435040","current_consent":"PENDING","consent":"PENDING","operator":"jio","jioUpdate":true,"consent_suggestion":"Reply “Y” to message received from 5168522"}
            this._dialogService.openAlert({
              title: 'Consent Response',
              message: '<p>' + response.data.consent + '</p>' + response.data.consent_suggestion
            });
          }
          else if (response.data && response.data.consent) {
            //{"number":"9324435040","current_consent":"PENDING","consent":"PENDING","operator":"jio","jioUpdate":true,"consent_suggestion":"Reply “Y” to message received from 5168522"}
            this._dialogService.openAlert({
              title: 'Consent Response',
              message: '<p>' + response.data.consent + '</p>'
            });
          }
          else if (
            response.data &&
            response.data[0]
          ) {

            // this._dialogService.openAlert({
            //   title: 'Consent Response ',
            //   message: '<p>' + response.data[0]  + '</p>' + callString
            // });
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          }
          else if (
            response.data &&
            response.data.Consent &&
            response.data.Consent.status
          ) {
            // this._dialogService.openAlert({
            //   title: 'Consent is ',
            //   message: '<p>' + response.data.Consent.status + '</p>' + callString
            // });
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          } else if (
            response.data &&
            (response.data.errorCode || response.data.requestError)
          ) {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          } else if (
            response.data &&
            response.data.terminalLocationList &&
            response.data.terminalLocationList.terminalLocation &&
            response.data.terminalLocationList.terminalLocation[0]
          ) {
            let latitude =
              response.data.terminalLocationList.terminalLocation[0]
                .currentLocation.latitude;
            let longitude =
              response.data.terminalLocationList.terminalLocation[0]
                .currentLocation.longitude;
            let dateTime = new Date(
              (response.data.terminalLocationList.terminalLocation[0].currentLocation.timestamp).replace(/-/g, "/")
            );
            let latlng = latitude + ', ' + longitude;
            let result =
              '<p>' +
              response.data.terminalLocationList.terminalLocation[0].address +
              ' Status: ' +
              response.data.terminalLocationList.terminalLocation[0]
                .locationRetrievalStatus;
            result = result + '<p><p>';
            result = result + latlng + ' at ' + dateTime; //this.datepipe.transform(dateTime, 'dd/MM/yyyy hh:mm:ss') ;
            result = result + '<p><p>';
            result =
              result +
              '<a target="_blank" href="https://www.google.co.in/maps?q=' +
              latlng +
              '" >Show on map</a>';
            result = result + '<p>';
            this._dialogService.openAlert({
              title: 'Location Result',
              message: result
            });
          }
          else if (typeof response.data === 'string') {
            this._dialogService.openAlert({
              title: 'Location Result',
              message: response.data
            });
          }
          else {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          }
          this.getResponse(this.currentPage);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else {

      this._dialogService.openAlert({ title: 'Error', message: 'Truck or driver not assigned' });

    }
  }
  endTripIntugine(tripData: Trips, template?) {
    // getIntugineGetConsent
    const driver = this.getDriver(tripData);
    if (driver && driver.user) {
      const user = driver.user;
   //  console.log(user);
      const aData = { users_id: user.id, trips_id: tripData.id };
      this.userService.intugineEndTrip(aData).subscribe(
        response => {
       //  console.log(response);
          //{"consentResults":[{"number":"9324435040", "consent":"ALLOWED","operator":"jio","consent_suggestion":null}],
          // "msg":"Trip started",
          // "requestdata":{"msg":"Trip started","tel":["9324435040"],"src":["30.066753000","79.019299700"],"dest":["19.281254700","73.048291200"],"custom_uid":"6115","started_by":"glasswing","truck_number":null,"isDelayed":false,"isAtHalt":false},
          // "tripId":"5eedc6d21990d212f3293fda"}
          //{"status":200,"message":"successfully","data":{"errorCode":409,"msg":"Trip already exists with one of the numbers provided","tripId":"5eedc6d21990d212f3293fda"},
          if (response.data.msg) {
            this._dialogService.openAlert({
              title: 'End trip response ',
              message: response.data.msg
            });
          }
          else if (response.data.errorCode) {
            this._dialogService.openAlert({
              title: 'End trip error ',
              message: response.data.errorCode
            });
          }
          else {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else {

      this._dialogService.openAlert({ title: 'Error', message: 'Truck or driver not assigned' });

    }
  }
  startTripIntugine(tripData: Trips, template?) {
    // getIntugineGetConsent
    const driver = this.getDriver(tripData);
    const truck = this.getTruck(tripData);
    const consignment = this.getConsignment(tripData);

    if (driver && driver.user) {
      const user = driver.user;
   //  console.log(user);
      let aData = { users_id: user.id, trips_id: tripData.id };
      if (truck) {
        aData['truck_number'] = truck.registration_no;
        aData['registration_no'] = truck.registration_no;
      }
      if (consignment) {
        aData['lr_number'] = consignment.consignment_no;
        aData['consignment_no'] = consignment.consignment_no;
      }
      this.userService.startTripIntugine(aData).subscribe(
        response => {
       //  console.log(response);
          //{"consentResults":[{"number":"9324435040", "consent":"ALLOWED","operator":"jio","consent_suggestion":null}],
          // "msg":"Trip started",
          // "requestdata":{"msg":"Trip started","tel":["9324435040"],"src":["30.066753000","79.019299700"],"dest":["19.281254700","73.048291200"],"custom_uid":"6115","started_by":"glasswing","truck_number":null,"isDelayed":false,"isAtHalt":false},
          // "tripId":"5eedc6d21990d212f3293fda"}
          //{"status":200,"message":"successfully","data":{"errorCode":409,"msg":"Trip already exists with one of the numbers provided","tripId":"5eedc6d21990d212f3293fda"},
          if (response.data.msg) {
            this._dialogService.openAlert({
              title: 'Start trip response ',
              message: response.data.msg
            });
          }
          else if (response.data.errorCode) {
            this._dialogService.openAlert({
              title: 'Start trip error ',
              message: response.data.errorCode
            });
          }
          else {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else {

      this._dialogService.openAlert({ title: 'Error', message: 'Truck or driver not assigned' });

    }
  }
  intugineFetchLocation(tripData: Trips, template?) {
    // getIntugineGetConsent
    const driver = this.getDriver(tripData);
    if (driver && driver.user) {
      const user = driver.user;
   //  console.log(user);
      const aData = { users_id: user.id, trips_id: tripData.id };
      this.userService.intugineFetchLocation(aData).subscribe(
        response => {
       //  console.log(response);
          //{"_id":"5eedc6d21990d212f3293fda",
          // "tel":["9324435040"],"src":["30.066753000","79.019299700"],
          // "dest":["19.281254700","73.048291200"],"custom_uid":"6115","started_by":"glasswing","truck_number":null,"isDelayed":false,"isAtHalt":false,"user":"GLASSWING",
          //"running":true,"submit":true,"reached":0,"ping_rate":3600000,"startTime":"2020-06-20T08:20:34.507Z","env":"test","createdAt":"2020-06-20T08:20:34.507Z","base_ETA":"2020-06-22T09:28:49.526Z",
          // "base_google_distance":1724811.2,"base_google_time":176895,"curr_ETA":"2020-06-21T05:11:08.112Z","dest_state":"MAHARASHTRA","destname":"Thane","distance_travelled":1405486.433573281,
          // "speed":9.750480228384069,"srcname":"Pauri Garhwal","time_remained":86467.4,
          // "consent":{"result":{"number":"9324435040","consent":"ALLOWED","operator":"jio","consent_suggestion":null},"code":200},"updatedAt":"2020-06-20T08:20:35.287Z","distance_remained":731608.8,"geofence_properties":{"src":{"radius":5000},"dest":{"radius":5000},"drops":{"radius":5000}},"last_delay_alert":0,
          // "ping":{"loc":[17.441890277777777,78.34498638888888],"address":"K.V.Rangareddy 500032 TELANGANA India","city":"K.V.Rangareddy","mode":"automated","createdAt":"2020-06-20T08:20:35.287Z"}}

          if (response.data && response.data.ping) {
            let latitude =
              response.data.ping.loc[0];
            let longitude =
              response.data.ping.loc[1];
            let dateTime = new Date(
              (response.data.ping.createdAt)
            ).toLocaleString('en-IN');
            let latlng = latitude + ', ' + longitude;
            let result = '';
            result = result +
              '<p>' + response.data.ping.address + '</p>';
            if (!response.data.ping.address) {
              result = result +
                '<p>' + latlng + '</p>';
            }
            // result = result + '<p><p>';
            result = result + ' at ' + dateTime; //this.datepipe.transform(dateTime, 'dd/MM/yyyy hh:mm:ss') ;
            result = result + '<p></p>';
            const parsedUrl = new window.URL(window.location.href);
            const baseUrl = parsedUrl.origin;
            result =
              result + '<a target="_blank" href="'  + baseUrl + '/trips/map/trip/' + tripData.id + '"> Show on map </a>';
              // <a [routerLink]="['/trips/map/trip',element.id]"
              //   target="_blank">{{element.location}}</a>


              // '<a target="_blank" href="https://www.google.co.in/maps?q=' +
              // latlng +
              // '" >Show on map</a>';
            result = result + '<p></p>';
            if(response.data.public_link) {
              result = result +  '<a target="_blank" href="' + response.data.public_link+ '"> Intugine tracker </a>';
              result = result + '<p></p>';
            }
            this._dialogService.openAlert({
              title: 'Location Result',
              message: result
            });
          }
          else {
            if (response.status && response.status == 500 && response.message) {
              this._dialogService.openAlert({
                title: 'Error ',
                message: response.message
              });
            }
            else if (response.status && response.status == 300 && response.message) {
              this._dialogService.openAlert({
                title: 'Error ',
                message: response.message
              });
            }
            else if (response.data) {
              this._dialogService.openAlert({
                title: 'Error ',
                message: JSON.stringify(response.data)
              });
            }
            else {
              this._dialogService.openAlert({
                title: 'Error ',
                message: JSON.stringify(response)
              });
            }

          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  getTelenityLocation(tripData: Trips) {
    const driver = this.getDriver(tripData);
    if (driver && driver.user) {
      const user = driver.user;
   //  console.log(user);
      const aData = { id: user.id };
      const callString = '<p>Please call <b>73 0377 7719</b> from <b>DRIVER MOBILE</b> and enter <b>1</b> when prompted.</p>';
      this.userService.getTelenityLocation(aData).subscribe(
        response => {
       //  console.log(response);
          if (
            response.data &&
            response.data[0]
          ) {

            this._dialogService.openAlert({
              title: 'Consent Response ',
              message: '<p>' + response.data[0] + '</p>' + callString
            });

          }
          else if (
            response.data &&
            response.data.Consent &&
            response.data.Consent.status
          ) {
            this._dialogService.openAlert({
              title: 'Consent is ',
              message: '<p>' + response.data.Consent.status + '</p>' + callString
            });
          } else if (
            response.data &&
            (response.data.errorCode || response.data.requestError)
          ) {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          } else if (
            response.data &&
            response.data.terminalLocationList &&
            response.data.terminalLocationList.terminalLocation &&
            response.data.terminalLocationList.terminalLocation[0]
          ) {
            let latitude =
              response.data.terminalLocationList.terminalLocation[0]
                .currentLocation.latitude;
            let longitude =
              response.data.terminalLocationList.terminalLocation[0]
                .currentLocation.longitude;
            let dateTime = new Date(
              (response.data.terminalLocationList.terminalLocation[0].currentLocation.timestamp).replace(/-/g, "/")
            );
            let latlng = latitude + ', ' + longitude;
            let result =
              '<p>' +
              response.data.terminalLocationList.terminalLocation[0].address +
              ' Status: ' +
              response.data.terminalLocationList.terminalLocation[0]
                .locationRetrievalStatus;
            result = result + '<p><p>';
            result = result + latlng + ' at ' + dateTime; //this.datepipe.transform(dateTime, 'dd/MM/yyyy hh:mm:ss') ;
            result = result + '<p><p>';
            result =
              result +
              '<a target="_blank" href="https://www.google.co.in/maps?q=' +
              latlng +
              '" >Show on map</a>';
            result = result + '<p>';
            this._dialogService.openAlert({
              title: 'Location Result',
              message: result
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error ',
              message: JSON.stringify(response.data)
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else {

      this._dialogService.openAlert({ title: 'Error', message: 'Truck or driver not assigned' });

    }
  }
  openModalConsignmentAddToTrip(template, aTrip: Trips) {
 //  console.log('called open modal consignment add to trip');
    this.openModalConsignment(template, null, aTrip);
  }
  openModalConsignment(template, aData, aTrip?: Trips) {

 //  console.log('called open modal consignment only');
 //  console.log(aTrip);
    this.trips = aTrip;
    this.consignment_notes_id = aData;
    this.openModalGeneric(template);
    return;
    this.cNote = new ConsignmentNotes();
    this.consignmentItemList = [];

    this.consignment_trips = new ConsignmentTrips();
    this.prepareLRForm();
    if (aTrip) {
      this.trips = aTrip;
      this.tripId = aTrip.id;
      if (this.trips.bookings) {
        this.bookings = this.trips.bookings;
      }
      this.consignment_trips = new ConsignmentTrips();
      if (this.tripId) {
        this.consignment_trips.trips_id = this.tripId;
      }
    }
    if (this.consignment_notes_id != null) {
      this.getConsignmentResponse(this.consignment_notes_id);
    }
    else {
      // if(this.bookings){
   //  console.log(this.bookings);
      this.cNote.bookings_id = this.bookings.id;
      this.cNote.customers_id = this.bookings.customers_id;
      // }
      this.cNote.consignment_status_cd = 'INCOMPLETE';
      this.consignmentItemList = [];
      // this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
      // this.consignmentNotesFormGroup.addControl(
      //   'consignment_items',
      //   this._formBuilder.array([])
      // );
      // this.getNewConsignmentItemForm();
      this.consignmentNotesFormGroup.patchValue(this.cNote);
   //  console.log(this.consignmentNotesFormGroup.value);
      this.visiblityState = 'shown';
    }
    // this.setConsignmentResponse(aData);
    // this.setTabsAndContentDispatch();
    // this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    let modalRef = this.openModalGeneric(template, aData);
    return modalRef;
  }
  getConsignmentStatuses() {
    return ConsignmentNotes.consignmentStatuses;
  }
  setConsignmentNotes(anEvent){
    // this.cNote =
  }
  receivedOnComplete(anEvent){
    //  console.log(anEvent);
       if(anEvent) {
         this.getResponse();
         this.closeModalGeneric();
       }
  }
  getConsignmentResponse(id) {
 //  console.log('get  consignment response');
    return this.consignmentNotesService
      .getConsignmentNotesById(id)
      .subscribe(response => {
     //  console.log(response);
        this.setConsignmentResponse(response.data);
      });
  }
  setConsignmentResponse(aData) {
 //  console.log('set consignment response');
 //  console.log(aData);
    this.consignmentItemList = [];
    this.cNote = new ConsignmentNotes(aData);

    if (this.cNote) {
   //  console.log(this.cNote);
      this.consignmentItemList = this.cNote.consignment_items;
      if (this.consignmentItemList && this.consignmentItemList.length > 0) {
        this.visiblityState = 'hidden';
      }
      else {
        this.visiblityState = 'shown';
      }
      this.consignmentEwayBillList = this.cNote.eway_bills;
      if (!this.bookings || !this.bookings.id) {
        this.bookings = this.cNote.bookings;

      }
      if (
        this.cNote.consignment_trips &&
        this.cNote.consignment_trips[0]
      ) {
        if (!this.trips) {
          this.trips = this.cNote.consignment_trips[0].trip;
          // this.tripId = this.trips.id;
          this.next_status = this.getNextStatus(this.trips);
          this.next_plus_status = this.getNextPlusStatus(this.trips);
          this.last_status = this.getLastStatus(this.trips);
          if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
            this.truckHireChallan = this.trips.truck_hire_challans[0];
            // this.setPartnerofThc();
          }
        }

      }

      // this.selectedBookingValue = this.selectedBooking.id + '';
      // console.log(this.consignmentNotesFormGroup.value);
    }
    else {

    }
    this.prepareLRForm();
  }
  doThing() {
 //  console.log('do thing event from child consignment');
    this.closeModalGeneric();
    // this.getConsignmentResponse(this.currentPage);
    // return  this.updateToStatus(this.trips, 'CONFIRM PAPER WORK DONE', 'Trip', this.trips.id);
  }
  addItem() {
    if (this.updateItemInConsignmentForm()) {
      this.visiblityState = 'hidden';
      if (this.consignmentItemList.length > 1) {
        this.closeModalGeneric();
      }
    }
    else {
      // alert();
    }
  }
  updateItemInConsignmentForm() {
    if (!this.consignmentItemsFormGroup.valid) {
      // this.errorMessages = appGlobals.getFormValidationErrors(
      //   this.consignmentItemsFormGroup
      // );
      appGlobals.markFormGroupTouched(this.consignmentItemsFormGroup);
      this._dialogService.openAlert({
          title: 'Error',
          message: appGlobals.getFormValidationErrorsDisplayMessage(this.consignmentItemsFormGroup)
        });
      return false;
    }
 //  console.log(this.consignmentItemList);
    const aConsignmentItem = new ConsignmentItems(this.consignmentItemsFormGroup.value);
    // if((!aConsignmentItem.id) && aConsignmentItem._id){
    if ((!aConsignmentItem.id)) {
      if (!aConsignmentItem._id) {
        aConsignmentItem._id = this.consignmentItemList.length + 1;
      }
      appGlobals.replaceAppendObjectInArray(aConsignmentItem, this.consignmentItemList, '_id')
    }
    else {
      appGlobals.replaceAppendObjectInArray(aConsignmentItem, this.consignmentItemList);
    }

 //  console.log(this.consignmentItemList);
    (<FormArray>this.consignmentNotesFormGroup.get('consignment_items')).push(this.consignmentItemsFormGroup);
    let currenteWay = this.consignmentNotesFormGroup.value.eway_bill_no;
    let currentInvoiceNo = this.consignmentNotesFormGroup.value.invoice_no;
    let currentInvoiceVal = +this.consignmentNotesFormGroup.value.invoice_value;
    let currentPayload = +this.consignmentNotesFormGroup.value.consignment_pay_load;
    let currentNoOfBoxes = +this.consignmentNotesFormGroup.value.no_of_boxes;

    //if(currenteWay && )
    if (!currenteWay) {
      this.consignmentNotesFormGroup.patchValue({
        eway_bill_no: this.consignmentItemsFormGroup.value.eway_bill_no,
        eway_expiry_dt: this.consignmentItemsFormGroup.value.eway_expiry_dt
      });
    }
    if (!currentInvoiceNo) {
      this.consignmentNotesFormGroup.patchValue({
        invoice_no: this.consignmentItemsFormGroup.value.invoice_no,
        invoice_dt: this.consignmentItemsFormGroup.value.invoice_dt,
      });
    }
    this.consignmentNotesFormGroup.patchValue({
      // eway_bill_no: this.consignmentItemsFormGroup.value.eway_bill_no,
      // invoice_no: this.consignmentItemsFormGroup.value.invoice_no,
      // invoice_dt: this.consignmentItemsFormGroup.value.invoice_dt,
      invoice_value: currentInvoiceVal + (+this.consignmentItemsFormGroup.value.invoice_value),
      consignment_pay_load: currentPayload + (+this.consignmentItemsFormGroup.value.actual_wt),
      no_of_boxes: currentNoOfBoxes + (+this.consignmentItemsFormGroup.value.quantity),
    });
    return true;
  }
  prepareLRForm() {
    // console.log('in prepapreLRForm');
    this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.consignmentNotesFormGroup.addControl(
      'consignment_items',
      this._formBuilder.array([])
    );
    this.getNewConsignmentItemForm();
    if (this.cNote) {
      // console.log('prepareLRForm patched consignment notes');
      this.consignmentNotesFormGroup.patchValue(this.cNote);
      // console.log(this.consignmentNotesFormGroup);
      if (!this.bookings) {
        this.bookings = this.cNote.bookings;
      }
      if ((this.cNote && this.cNote.business_type_cd !== 'EXIM')
        || (!this.cNote && this.bookings && this.bookings.business_type_cd !== 'EXIM')) {
        if (this.bookings.total_distance >= 50) {
          this.consignmentNotesFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
          Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
          this.consignmentItemsFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
          Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
          this.consignmentNotesFormGroup.controls['eway_bill_no'].updateValueAndValidity();
          this.consignmentItemsFormGroup.controls['eway_bill_no'].updateValueAndValidity();
        }
      }
    }
    if (this.bookings) {
      this.consignmentNotesFormGroup.patchValue({
        // searchCustomer: this.selectedCustomer.name,
        consignor_location_code: this.bookings
          .source_location_code,
        consignee_location_code: this.bookings
          .destination_location_code
      });
    }

  }
  getNewConsignmentItemForm() {
    this.consignmentItemsFormGroup = this._formBuilder.group(consignmentItemsFormControls);
    // console.log('getNewConsignmentItemForm ' +  this.consignment_notes_id);
    if (this.consignment_notes_id) {
      this.consignmentItemsFormGroup.patchValue({ consignment_notes_id: this.consignment_notes_id });
    }
    // console.log(this.consignmentItemsFormGroup.controls.eway_bill_no);
    if ((this.cNote && this.cNote.business_type_cd !== 'EXIM')
      || (!this.cNote && this.bookings && this.bookings.business_type_cd !== 'EXIM')) {
      if (this.bookings?.total_distance && this.bookings?.total_distance >= 50) {
        // console.log('setting validators on eway bill');
        this.consignmentItemsFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
        Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
        this.consignmentItemsFormGroup.controls['eway_bill_no'].updateValueAndValidity();
      }
    }
    this.observeEwayItemLookup();
    // console.log(this.consignmentItemsFormGroup.controls.eway_bill_no);
    return this.consignmentItemsFormGroup;
  }
  observeEwayItemLookup() {
    // console.log('setting observe on eway ');
    appGlobals.observeChanges(
      this,
      this.consignmentItemsFormGroup.controls.eway_bill_no,
      this.consignmentEwayBillList,
      'getEWaySearchService',
      'setEWay',
      'eway_bill_no'
    );

  }
  getEWaySearchService(searchTerm) {
    // console.log('sending search service ' + searchTerm);
    return this.consignmentNotesService.geteWayBillDetailsFromExternal({ eway_bill_no: searchTerm, consignment_notes_id: this.cNote.id, trips_id: this.tripId });
  }
  setEWay(response) {
 //  console.log('setting eway response');
 //  console.log(response);
    if (response.status === 200) {
      // this.ewayBillNo = response.data.eway_bill_no;
      // this.tempEwayBill = this.ewayBillNo;
      if (!this.consignmentEwayBillList || this.consignmentEwayBillList == null) {
        this.consignmentEwayBillList = [];
      }
      const len = this.consignmentEwayBillList.length;
      let confResult = true;
      if (!response.data || !response.data.eway_bill_raw) {
        // confResult = false;
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Unable to fetch eWay Bill details.'
        });
      }
      else {
     //  console.log(response.data.eway_bill_raw);
        if (!response.data.eway_bill_raw.vehicle_no || response.data.eway_bill_raw.vehicle_no == null) {
          confResult = false;
          alert('Error: Eway does not have Truck Number attached. Please have Part B updated');
        }
        else if (!response.data.eway_bill_raw.trans_doc_no || response.data.eway_bill_raw.trans_doc_no == null) {
          // confResult = false;
          confResult = confirm('Warning: Eway does not have LR number. Please press Ok to Continue or cancel to correct Part B');

        }
        else {
          confResult = confirm('Eway will is attached to LR no '
            + response.data.eway_bill_raw.trans_doc_no + ' and truck '
            + response.data.eway_bill_raw.vehicle_no + '. Please press Ok to Confirm. Press cance to enter another eWay');
        }
      }
      if (confResult && response.data) {
        // this.setConsignmentResponse(response.data);
        if (response.data.eway_bill_raw) {
          let existingEway = false;
          if (this.consignmentEwayBillList.length === 0) {
            // is first eway bill
            this.cNote.fill(response.data);
          }
          //patch current line item
          this.consignmentItemsFormGroup.patchValue({
            eway_bill_no: response.data.eway_bill_no,
            invoice_no: response.data.invoice_no,
            invoice_dt: appGlobals.getSafeDate(response.data, 'invoice_dt'),
            invoice_value: response.data.invoice_value,
            eway_valid_upto_dt: appGlobals.getSafeDate(response.data, 'eway_valid_upto_dt'),
            eway_hsn_cd: response.data.eway_hsn_cd,
          }
          );
          if (response.data.invoice_dt != null) {

          }

          this.consignmentEwayBillList.forEach(element => {
            if (element['eway_bill_no'] === response.data.eway_bill_no) {
              existingEway = true;
            }
          });
          if (!existingEway) {

            if (response.data.consignment_items && response.data.consignment_items[0]) {
              // this.consignmentItemList || []
              const currentConsignmentItems = this.cNote.consignment_items || [];
              // adding items to current consignment note..
              const lineItemsInEway = ConsignmentItems.toArray(response.data.consignment_items);
              const newConsignmentItems = currentConsignmentItems.concat(lineItemsInEway);
              let itemDesc = '';
              let itemDescShort = '';
              let itemQuantity = 0;
              let itemUnit = '';
              lineItemsInEway.forEach(element => {
             //  console.log(element.description + element.quantity + element.unit_cd);
                if (itemDesc == '') {
                  itemDesc = element.description;
                  itemDescShort = element.description;
                }
                else if (itemDesc.trim().toLowerCase() == element.description.trim().toLowerCase()) {

                }
                else {
                  itemDesc = itemDesc + ' ,' + element.description;
                }
                const noOfDifferentItems = itemDesc.split(' ,').length;
                if (noOfDifferentItems > 1) {
                  const additionalItems = noOfDifferentItems - 1;
                  itemDescShort = itemDescShort + ' and ' + additionalItems + ' items';
                }
                if (itemUnit == '') {
                  itemUnit = element.unit_cd;
                }
                else if (itemUnit.trim().toLowerCase() == element.unit_cd.trim().toLowerCase()) {

                }
                else {
                  // itemUnit = itemUnit + ' ,' + element.unit_cd;
                }
                itemQuantity = itemQuantity + element.quantity;
              });
              this.consignmentItemsFormGroup.patchValue({
                quantity: itemQuantity,
                description: itemDesc.substr(0, 50),
                unit_cd: itemUnit,
              }
              );
            }


            this.consignmentEwayBillList[len] = {
              consignment_notes_id: this.cNote.id,
              eway_bill_no: response.data.eway_bill_no,
              eway_valid_upto_dt: appGlobals.getSafeDate(response.data, 'eway_valid_upto_dt'),
              eway_hsn_cd: response.data.eway_hsn_cd,
              invoice_no: response.data.invoice_no,
              invoice_dt: response.data.invoice_dt,
              invoice_value: response.data.invoice_value,
              trans_doc_no: response.data.eway_bill_raw.trans_doc_no,
            };
          }
       //  console.log(this.consignmentEwayBillList);
        }
      }
      else {
        if (this.ifEwayMandatory()) {
          // this.ewayBillNo = '';
          this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
          // this.consignmentNotesFormGroup.controls.eway_bill_no.setValue('');
        }
      }

    }
    else if (response.status === 300 || response.status === 500) {
      // console.log('error in eway search ' + (response.message ? response.message : response['error']));
      this._dialogService.openAlert({
        title: 'Error',
        message: (response.message ? response.message : response['error'])
      });
      if (this.ifEwayMandatory()) {
        // this.tempEwayBill = this.ewayBillNo = '';
        this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
        // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
      }
    }
  }
  ifEwayMandatory() {
    const inv_value = this.consignmentNotesFormGroup.get('invoice_value').value;
    const total_distance = this.bookings.total_distance;
    if (this.cNote.business_type_cd === 'EXIM' ||
      (inv_value && inv_value <= 50000) || total_distance <= 50) {
      return false; //  true;
    }
    return true;
  }
  openModalEditItem(addConsignment: TemplateRef<any>, option = null) {
    // this.consignmentItemsFormGroup.reset();
    this.getNewConsignmentItemForm()
    if (option) {
      this.consignmentItemsFormGroup.patchValue(option);
    } else {
      this.consignmentItemsFormGroup.patchValue({
        eway_bill_no: this.consignmentNotesFormGroup.value.eway_bill_no,
        invoice_no: this.consignmentNotesFormGroup.value.invoice_no,
        invoice_dt: this.consignmentNotesFormGroup.value.invoice_dt,
        invoice_value: this.consignmentNotesFormGroup.value.invoice_value,
        consignment_notes_id: this.consignmentNotesFormGroup.value.id,
        _id: this.consignmentItemList.length + 1
      });

    }
    // console.log(this.consignmentItemsFormGroup.value);
    this.modalRef = this.openModalGeneric(addConsignment);

  }
  uploadConsignmentNote(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'CONSIGNMENT')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          consignment_scan: response.data.filename
        });
        this.cNote.consignment_scan = response.data.filename;
      });
  }
  uploadInvoice(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'INVOICE')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
        this.cNote.invoice_scan = response.data.filename;
      });
  }
  uploadTruck(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'TRUCK')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          truck_scan: response.data.filename
        });
        this.cNote.truck_scan = response.data.filename;
      });
  }
  uploadEway(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'EWAY')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          eway_bill_scan: response.data.filename
        });
        this.cNote.eway_bill_scan = response.data.filename;
      });
  }
  uploadEmptyLetter(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'EMPTY LETTER')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          empty_letter_scan: response.data.filename
        });
        this.cNote.empty_letter_scan = response.data.filename;
      });
  }
  addMoreInvoices() {
 //  console.log('addMoreInvoices');
    this.getNewConsignmentItemForm();
 //  console.log('addMoreInvoices got new item');
    // this.getNewConsignmentItemForm();
    this.consignmentItemsFormGroup.patchValue({
      consignment_notes_id: this.consignmentNotesFormGroup.value.id,
      _id: this.consignmentItemList.length + 1
    });
 //  console.log('opening modal addMoreInvoices');
    this.openModalGeneric(this.addConsignment);
  }
  addMoreItems() {
 //  console.log('addMoreItems');
 //  console.log(this.consignmentNotesFormGroup);
    // console.log(this.consignmentNotesFormGroup);
    this.getNewConsignmentItemForm();
 //  console.log('addMoreItems got new item');
    // todo: add more items to which eway?? so the eway should have a dropdown of existing eways
    this.consignmentItemsFormGroup.patchValue({
      eway_bill_no: this.consignmentNotesFormGroup.value.eway_bill_no,
      invoice_no: this.consignmentNotesFormGroup.value.invoice_no,
      invoice_dt: this.consignmentNotesFormGroup.value.invoice_dt,
      invoice_value: this.consignmentNotesFormGroup.value.invoice_value,
      consignment_notes_id: this.consignmentNotesFormGroup.value.id,
      _id: this.consignmentItemList.length + 1
    });
    this.openModalGeneric(this.addConsignment);
  }
  submitConsignmentBooked() {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: 'BOOKED'
    });
    return this.submitConsignment();
  }
  submitConsignment() {
 //  console.log(this.consignmentNotesFormGroup.value);
    if (this.consignmentItemList.length == 0) {
      this.updateItemInConsignmentForm();
    }
    //return;
    if (!this.isValidConsignmentForm()) {
      return;
    } else {
      this.cNote = new ConsignmentNotes(
        this.consignmentNotesFormGroup.value
      );
      this.cNote.consignment_items = this.consignmentItemList;
      // update consignment notes eway and invoices if multiple
      let arrEway = [];

      this.consignmentItemList.forEach(element => {
        arrEway[element.eway_bill_no] = element;
      });
   //  console.log(this.consignmentItemList);
   //  console.log(arrEway);
      this.cNote.eway_bill_no = '';
      this.cNote.invoice_no = '';
      const keys = Object.keys(arrEway);
      keys.forEach(element => {
        if (this.cNote.eway_bill_no != '') {
          this.cNote.eway_bill_no = this.cNote.eway_bill_no + ',';
        }
        if (this.cNote.invoice_no != '') {
          this.cNote.invoice_no = this.cNote.invoice_no + ',';
        }
        this.cNote.eway_bill_no = this.cNote.eway_bill_no + arrEway[element].eway_bill_no;
        this.cNote.invoice_no = this.cNote.invoice_no + arrEway[element].invoice_no;
        this.cNote.invoice_dt = arrEway[element].invoice_dt;
        this.cNote.eway_valid_upto_dt = arrEway[element].eway_valid_upto_dt;

      });

      this.cNote.eway_bills = this.consignmentEwayBillList;

      this.cNote.bookings = this.bookings;
      if (!this.consignment_trips || !this.consignment_trips.trips_id) {
        this.consignment_trips = new ConsignmentTrips();
        if (this.tripId) {
          this.consignment_trips.trips_id = this.tripId;
        }
      }
      this.cNote.consignment_trips = new Array(
        this.consignment_trips
      );
   //  console.log(this.cNote);
      if (this.cNote.id) {
        this.consignmentNotesService
          .updateConsignmentNotes(this.cNote)
          .subscribe(
            response => {
              if (response.status === 200) {

                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });
             //  console.log(response);
                this.closeModalGeneric();
                // if(this.modalRef) {
                //   this.modalRef.hide();
                // }
                this.getResponse(this.currentPage);
                // console.log(response);
                // this.cNote = new ConsignmentNotes(response.data);

                // this.openModalGeneric();
                // const confResult = confirm('LR ' + this.consignmentNotes.consignment_no + ' created.'
                //   + ' Do you want to mark PAPER WORK COMPLETE');
                // if (confResult) {
                //   // this.markPaperWorkComplete();
                //   this.onComplete.emit();
                // }
                // this._location.back();
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
              if (error.status == 422) {
                Object.keys(error.error.errors).forEach((key) => {
               //  console.log('got error key' + key + error.error.errors[key]);
                  if (this.consignmentNotesFormGroup.controls[key]) {
                    this.consignmentNotesFormGroup.controls[key].setErrors({ 'required': error.error.errors[key] });
                    this.consignmentNotesFormGroup.controls[key].updateValueAndValidity();
                  }

                });

              }
            }
          );
      } else {
        this.consignmentNotesService
          .addConsignmentNotes(this.cNote)
          .subscribe(
            response => {
              if (response.status === 200) {
                // this._snackBarService.open(response.message, 'X', {
                //   duration: appGlobals.SNACKBAR_TIMEOUT
                // });
                // this.consignmentNotes = new ConsignmentNotes(response.data);
                // appGlobals.replaceAppendObjectInArray(this.consignmentNotes, this.consignmentNotesList);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;

                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });
             //  console.log(response);
                this.closeModalGeneric();
                // if(this.modalRef) {
                //   this.modalRef.hide();
                // }

                this.getResponse(this.currentPage);
                // console.log(response);
                // this.cNote = new ConsignmentNotes(response.data);
                // if (!this.consignmentNotesList) {
                //   this.consignmentNotesList = [];
                // }
                // console.log(this.consignmentNotesList);
                // appGlobals.replaceAppendObjectInArray(this.cNote, this.consignmentNotesList);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // this.dispatchContentTemplate = this.cNoteList;
                // this.isSingleIncompleteLR = false;
                // this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
                // this.setTabsAndContentDispatch();

                // this.consignmentNotesList.push(this.consignmentNotes);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // console.log(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({
                title: 'Error',
                message: error,
                flag: 1
              });
            }
          );
      }
    }
  }
  isFilePDF(aUrl) {
    return aUrl.split('.').pop() === 'pdf';
  }
  display_postcard(aUrl) {
    // console.log(this.images[index]);
    window.open(aUrl, '_blank');
  }
  isValidConsignmentForm() {
    let result = true;
 //  console.log('checking  validity');
    if (!this.consignmentNotesFormGroup.valid) {
   //  console.log('form group is  not  valid');
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup
      );

      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup);

      if (!this.consignmentItemsFormGroup.valid) {
        this.errorMessages.concat(appGlobals.getFormValidationErrors(
          this.consignmentNotesFormGroup
        ));
     //  console.log(this.errorMessages);

        this._dialogService.openAlert({
          title: 'Error',
          message: appGlobals.getFormValidationErrorsDisplayMessage(this.consignmentItemsFormGroup)
        });

      } else {
        // item form is valid.. main form is invalid
        this._dialogService.openAlert({
          title: 'Error',
          message: appGlobals.getFormValidationErrorsDisplayMessage(this.consignmentNotesFormGroup)
        });
      }

      // alert('Please complete all details');
      result = false;
    } else {
      // this.consignmentNotes = new ConsignmentNotes(
      //   this.consignmentNotesFormGroup.value
      // );
   //  console.log('form group is valid');
   //  console.log(this.consignmentNotesFormGroup.value);
   //  console.log(this.consignmentNotesFormGroup);
   //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').valid);
   //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').value);
   //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').validator);
      this.cNote.fill(this.consignmentNotesFormGroup.value);

      this.cNote.consignment_items = this.consignmentItemList;
      this.cNote.bookings = this.bookings;
      this.cNote.consignment_trips = new Array(
        this.consignment_trips
      );
   //  console.log(this.cNote.consignment_items);
      if (
        !this.cNote.consignment_items ||
        this.cNote.consignment_items.length === 0
      ) {
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Please add consignment Items'
        });
        result = false;
      }
    }
    return result;
  }

  initalizeBookingsFormGroup() {
    this.bookingsFormGroup = this._formBuilder.group(bookingsFormControls);
  }
  // #endregion
  // #endregion
}

// let data: Trips[];
export class TripsDataSource extends DataSource<any> {
  // #region Methods (2)

  // #region Methods (2)
  data: any;
  constructor(aData?: Trips[]) {
    super();
    this.data = aData;
  }
  connect(): Observable<Trips[]> {
    return of(this.data);
  }
  getIndex(id) {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id == id) {
        return index;
      }

    }
    return -1;
  }
  remove(id) {
    const itemIndex = this.getIndex(id);
 //  console.log('found ' + id + ' at ' + itemIndex);
    this.data = this.data.splice(itemIndex, 1);
 //  console.log('removed');
  }
  disconnect() { }

  // #endregion
  // #endregion
}
