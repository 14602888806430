import {Trips} from '../trips/trips';
import { Users } from '../users/users';
import { safeDate } from '../../core/globalfunctions';
export class TripStatusTrackings {
    id : number;
    trips_id : number;
    trip_no : string;
    trips_status_cd : string;
    remarks : string;
    updated_by : number;
    updated_at : Date;
    updated_by_user: Users;
    is_visible_to_customer : number;
    location : string;
    location_provider : string;
    status_at: Date;
    status_type_cd: string;
    location_lat : number;
    location_long : number;
    source_city: string;
    destination_city: string;
    registration_no: string;
    trip : Trips;
  distance_remaining: number;
  distance_travelled: number;
  time_remaining: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : TripStatusTrackings[] {
        let tripStatusTrackings : TripStatusTrackings[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                tripStatusTrackings.push(new TripStatusTrackings(json));
            }
        }
        return tripStatusTrackings;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.trips_id = json.trips_id;
            this.trips_status_cd = json.trips_status_cd;
            this.remarks = json.remarks;
            this.updated_by = json.updated_by;
            safeDate(this, json,  'updated_at');

            if(json.updated_by_user != null){
              this.updated_by_user = new Users(json.updated_by_user);
            }
            this.is_visible_to_customer = json.is_visible_to_customer;
            if(json.location_lat != null) {
              this.location_lat = +json.location_lat;

            }
            if(json.location_long != null) {
              this.location_long = +json.location_long;

            }

            this.location = json.location;
            this.location_provider = json.location_provider;
            safeDate(this, json,  'status_at');

            // this.status_at = json.status_at;
            this.status_type_cd = json.status_type_cd;
            // this.location_long = <number>json.location_long;
            this.distance_remaining = json.distance_remaining;
            this.distance_travelled = json.distance_travelled;
            this.time_remaining = json.time_remaining;

            if (json.trip != null) {
              this.trip = new Trips(json.trip);
              // this.trips = new Trips(json.trips);
              this.trip_no = json.trip.trip_no;
            }
            else {
              this.trip_no = json.trip_no;
            }
            if (json.trip && json.trip.truck_hire_challan != null) {
              // this.trips = new Trips(json.trips);
              this.registration_no = json.trip.truck_hire_challan.trucks_id;
          }
          else {
            this.trip_no = json.trip_no;
          }

        }
    }
}
