<ng-container *ngIf="!contentOnly then consignmentFull; else cNoteList;" >
</ng-container>

<ng-template #consignmentFull>
<input autocomplete="false" name="hidden" type="text" style="display:none;">
<ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update LR',aCNoteData:consignmentNotes}"></ng-container>
<div class="modal-body ">
  <div class="details_list_item">
    <ng-container *ngTemplateOutlet="tripSummaryCNote">
    </ng-container>
    <div class="fieldset mb-4">
      <div class="row" *ngIf="hasTabs">
        <div class="col" >
          <ng-template *ngTemplateOutlet="detailsTopTemplate">
          </ng-template>
        </div>
      </div>
      <ng-container *ngIf="false; else contentTemplate">
      </ng-container>
    </div>
  </div>
</div>
</ng-template>
<ng-template #tripDetailsTopTabs>
  <!-- <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips, aCNoteData:consignmentNotes}">
  </ng-container> -->
  <div class="fieldset mb-4">
    <div class="row">
      <div class="col">
        <mat-button-toggle-group [value]="selectedDispatchActivity" (change)="onDispatchActivityChange($event)"
          class="update_lr_buttons_group border-0 flex-wrap">
          <mat-button-toggle *ngIf="trips_id " value="UPDATE STATUS" class="border-0 m-r-10 m-b-10">
            <div class="update_lr_buttons">Update Status</div>
          </mat-button-toggle>
          <mat-button-toggle value="MARK PAPER WORK DONE"
            *ngIf="(selectedDispatchActivity!='UPDATE LR') && !isSingleIncompleteLR" class="border-0  m-r-10  m-b-10">
            <div class="update_lr_buttons">Consignment List</div>
          </mat-button-toggle>
          <mat-button-toggle value="UPDATE LR" *ngIf="isSingleIncompleteLR || (selectedDispatchActivity == 'UPDATE LR')"
            class="border-0  m-r-10  m-b-10">
            <div class="update_lr_buttons">Consignment</div>
          </mat-button-toggle>
          <mat-button-toggle *ngIf="truckHireChallan" value="ATTACH DOCUMENTS" class="border-0  m-r-10  m-b-10">
            <div class="update_lr_buttons">Attach Documents</div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cNoteDetailsTopTabs>
  <!-- <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{aCNoteData:consignmentNotes,tripData:trips}">
  </ng-container> -->
  <div class="fieldset mb-4">
    <div class="row">
      <div class="col">
        <mat-button-toggle-group [value]="selectedDispatchActivity" (change)="onDispatchActivityChange($event)"
          class="update_lr_buttons_group border-0 flex-wrap">
          <mat-button-toggle value="MARK PAPER WORK DONE"
            *ngIf="(selectedDispatchActivity!='UPDATE LR') && !isSingleIncompleteLR" class="border-0  m-r-10  m-b-10">
            <div class="update_lr_buttons">Consignment List</div>
          </mat-button-toggle>
          <mat-button-toggle value="UPDATE LR" *ngIf="isSingleIncompleteLR || (selectedDispatchActivity == 'UPDATE LR')"
            class="border-0  m-r-10  m-b-10">
            <div class="update_lr_buttons">Consignment</div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cNoteList>
  <div clas="row">
    <div class="col">
      <h3 class="gwheadline">Consignment List</h3>
      <!-- <div class="row" *ngFor="let consignmentNotes of consignmentNotesList">
        <div class="col">{{consignmentNotes.consignment_no}}</div>
        <div class="col">{{consignmentNotes.consignment_dt}}</div>
        <div class="col">{{consignmentNotes.consignment_status_cd}}</div>
      </div> -->
      <div [ngClass]="{'example-container1': cNoteListDataSource  && (cNoteListDataSource.data).length > 0,'example-container2': cNoteListDataSource  && (cNoteListDataSource.data).length <= 0}">
        <mat-table [dataSource]="cNoteListDataSource " cellpadding="10 " class="table_list">
        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef> LR No </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element">
            <ul>
              <li *ngIf="element?.consignment_no">{{element?.consignment_no  }}</li>
              <li *ngIf="! element?.consignment_no"><a (click)="openUpdateLr(element)">TEMP{{element?.id}}</a></li>
            </ul>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="origin">
          <mat-header-cell *matHeaderCellDef> Loading </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element">
            <ul>
              <li>
                {{getStringBeforeComma(element.consignor_city) | titlecase}}
              </li>
              <li>{{element?.bookings?.booking_for_dt | date: 'dd MMM yy'}}
                {{element?.bookings?.booking_for_start | date: 'h:mm a'}} </li>

            </ul>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="truck_type">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element">
            <ul>
              <li>{{element.consignment_status_cd}}</li>
            </ul>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell mat-header-cell class="td-task-button" *matHeaderCellDef> Task
          </mat-header-cell>
          <mat-cell mat-cell class="td-task-button" *matCellDef="let element; " (click)="$event.stopPropagation()">
            <button mat-raised-button color="primary" class="button-task" (click)="openUpdateLr(element); ">
              Update
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;  " [ngClass]="{hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
        </mat-row>
      </mat-table>
      </div>
    </div>
  </div>
  <div clas="row">
    <div class="col text-right">
      <button mat-raised-button color="secondary" class="button" (click)="addNewConsignment()" style="margin-left: auto">
        Add New Consignment
      </button>
    </div>
  </div>
</ng-template>
<ng-template #cNoteDetailsOld>
  <!-- <div clas="row">
    <div class="col">
      <form [formGroup]="consignmentNotesFormGroup">
        <h3 class="gwheadline">Consignment Details cNoteDetails</h3>
        <div class="row">
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase required type="text" matInput placeholder="LR Number"
                formControlName="consignment_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('consignment_no').valid && consignmentNotesFormGroup.get('consignment_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('consignment_no').errors.required">Consignment No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase type="text" matInput placeholder="Container No" formControlName="container_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('container_no').valid && consignmentNotesFormGroup.get('container_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('container_no').errors.required">Container No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="text" appUppercase matInput placeholder="Seal No" formControlName="seal_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('seal_no').valid && consignmentNotesFormGroup.get('seal_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('seal_no').errors.required">Seal No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="consignment_items" [@visibilityChanged]="visiblityState">
          <ng-template *ngTemplateOutlet="itemDetails2">
          </ng-template>
        </div>
        <div class="table-responsive" *ngIf="consignmentItemList?.length >0">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th>Invoice / Eway</th>
                <th>Invoice Dt / Eway Exp Dt</th>
                <th>Item Description</th>
                <th>Units</th>
                <th>Weight</th>

                <th></th>
              </tr>
              <tr *ngFor="let item of consignmentItemList">
                <td>{{item.invoice_no}} {{ item.eway_bill_no}}</td>
                <td>{{item.invoice_dt | date: 'd MMM'}} </td>

                <td>{{item.description}}</td>
                <td>{{item.quantity}} {{item.unit_cd}}</td>
                <td>{{item.actual_wt}}</td>
                <td>
                  <button mat-mini-fab color="primary" (click)="openModal(addConsignment,item)">
                    <i class="fa fa-pencil"></i>
                  </button>

                </td>
              </tr>
            </tbody>
          </table>
          <div class="mTop15 text-right gap-btn">
            <button class="mLft5" mat-raised-button color="secondary" (click)="addMoreInvoices()">Add More
              Invoices</button>
            <button class="mLft5" mat-raised-button color="primary" (click)="addMoreItems()">Add More Items</button>
          </div>
        </div>
        <div class="row m-t-10">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Attachments</label>
            <div class="row">
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.invoice_scan"><a href="{{imageUrl}}{{consignmentNotes?.invoice_scan}}"
                    target="_blank"><i class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Invoice</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadInvoice($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('invoice_scan').valid && consignmentNotesFormGroup.get('invoice_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('invoice_scan').errors.required">Please attach invoice
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.eway_bill_scan"><a
                    href="{{imageUrl}}{{consignmentNotes?.eway_bill_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload eWay Bill</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEway($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('eway_bill_scan').valid && consignmentNotesFormGroup.get('eway_bill_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('eway_bill_scan').errors.required">Please attach eWay
                    Bill
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.consignment_scan"><a
                    [href]="imageUrl +consignmentNotes?.consignment_scan" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload  btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload LR</span><input type="file" style="display: none;"
                    name="file[]" (change)="uploadConsignmentNote($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('consignment_scan').valid && consignmentNotesFormGroup.get('consignment_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('consignment_scan').errors.required">Please attach
                    consignment
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.truck_scan"><a href="{{imageUrl}}{{consignmentNotes?.truck_scan}}"
                    target="_blank"><i class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Truck/Trailer/Driver Photo</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadTruck($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('truck_scan').valid && consignmentNotesFormGroup.get('truck_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('truck_scan').errors.required">Please attach trailer
                    photo
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach" *ngIf="selectedBooking?.business_type_cd == 'EXIM'">
                <label *ngIf="consignmentNotes?.empty_letter_scan"><a
                    href="{{imageUrl}}{{consignmentNotes?.empty_letter_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Empty Letter</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEmptyLetter($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('empty_letter_scan').valid && consignmentNotesFormGroup.get('empty_letter_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('empty_letter_scan').errors.required">Please attach
                    Empty letter
                    scan</span>
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Insurance</label>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="Insurance By" formControlName="consignment_insured_by">
                    <mat-option value="NOT INSURED">NOT INSURED</mat-option>
                    <mat-option value="CONSIGNOR">CONSIGNOR</mat-option>
                    <mat-option value="CONSIGNEE">CONSIGNEE</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_insured_by').valid && consignmentNotesFormGroup.get('consignment_insured_by').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_insured_by').errors.required">Insured by
                      is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="At Risk of" formControlName="consignment_risk">
                    <mat-option value=""></mat-option>
                    <mat-option value="FLEET OWNER">OWNER</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_risk').valid && consignmentNotesFormGroup.get('consignment_risk').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_risk').errors.required">At Risk Of is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Co"
                    formControlName="insurance_provider">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_provider').valid && consignmentNotesFormGroup.get('insurance_provider').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_provider').errors.required">Insurance
                      provider
                      name is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Policy No."
                    formControlName="insurance_poilicy_no">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_poilicy_no').valid && consignmentNotesFormGroup.get('insurance_poilicy_no').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_poilicy_no').errors.required">Insurance
                      policy
                      number is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input matInput [matDatepicker]="picker2" placeholder="Insurance Date" formControlName="insurance_dt">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_dt').valid && consignmentNotesFormGroup.get('insurance_dt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_dt').errors.required">Insurance date is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Validity"
                    formControlName="insurance_validity">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Freight Details</label>
            <div class="row">

              <div class="col" *ngIf="userIsAdmin()">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Freight Charges" formControlName="freight_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('freight_amt').valid && consignmentNotesFormGroup.get('freight_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('freight_amt').errors.required">Freight is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Loading Charges" formControlName="loading_charges">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('loading_charges').valid && consignmentNotesFormGroup.get('loading_charges').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('loading_charges').errors.required">Loading amount is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Misc Charges" formControlName="misc_charges_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('misc_charges_amt').valid && consignmentNotesFormGroup.get('misc_charges_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('misc_charges_amt').errors.required">Misc charge is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                    <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Remarks"
                    formControlName="remarks">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mTop15 text-right gap-btn">
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value == 'INCOMPLETE'  ">
              <button mat-raised-button color="secondary" (click)="submit()" routerLinkActive="active">Save</button>
              <button mat-raised-button color="primary" (click)="submitBooked()" routerLinkActive="active">Save & Mark Booked</button>
            </ng-container>
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value != 'INCOMPLETE'  ">
              <button mat-raised-button color="primary" (click)="submit()" routerLinkActive="active">Save</button>

            </ng-container>


          </div>
        </div>
      </form>
    </div>
  </div> -->
</ng-template>
<ng-template #modalHeader let-title="title" let-consignmentNotes="aCNoteData">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- close button if popup, hitory link, reassign executives, cancel trip, resend sms, get location, gallery, history, location,  -->
          <mat-menu #rootMenu="matMenu">
            <button *ngIf="consignmentNotes && consignmentNotes.id" mat-menu-item (click)="openModalSendConsignment(sendConsignment, consignmentNotes)">
              <span>Send LR</span>
            </button>
            <button *ngIf="consignmentNotes && consignmentNotes.id" mat-menu-item (click)="downloadConsignmentAsPDF(consignmentNotes)">
              <span>Download Online LR</span>
            </button>
            <button *ngIf="consignmentNotes && consignmentNotes.id" mat-menu-item (click)="downloadKNConsignmentAsPDF(consignmentNotes)">
              <span>Download Online KN LR</span>
            </button>

            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- <ng-template #tripSummary let-trip="tripData" let-consignmentNotes="aCNoteData"> -->
  <ng-template #tripSummary >
  <h2>Trip Details</h2>
  <div class="fieldset mb-4">
    <div class="conatiner_flow">
      <div  *ngIf="hasStatusGrapics" class="row border-b-1 mb-3">
        <div
          class="col col_b_{{getTripStatusColor(trips.trips_status_cd)}}{{hasNextStatusSuffx(trips.trips_status_cd, trips)}} p-r-5 p-l-5">
          <div class="root_flow ">
            <img *ngIf="isDestinationStatus(trips.trips_status_cd)"
              src="assets/img/reached_destination_{{getTripStatusColor(trips.trips_status_cd)}}.png">
            <img *ngIf="!isDestinationStatus(trips.trips_status_cd)"
              src="assets/img/vehicle_assigned_{{getTripStatusColor(trips.trips_status_cd)}}.png">
            <div class="label_sli color_{{getTripStatusColor(trips.trips_status_cd)}}">
              {{trips.trips_status_cd | titlecase}} </div>
          </div>
        </div>
        <div *ngIf="next_status " class="col text-center col_b_{{getTripStatusColor(next_status)}} p-r-5 p-l-5">
          <div class="root_flow ">
            <img *ngIf="isDestinationStatus(next_status)"
              src="assets/img/reached_destination_{{getTripStatusColor(next_status)}}.png">
            <img *ngIf="!isDestinationStatus(next_status)"
              src="assets/img/on_the_way_{{getTripStatusColor(next_status)}}.png">
            <div
              class="label_sli color_{{getTripStatusColor(getNextTripsStatus(trips))}} color-progress-{{getTripStatusColor(next_status)}}">
              {{next_status  | titlecase}} </div>
          </div>
        </div>
        <div *ngIf="next_plus_status && next_plus_status !='' "
          class="col col_b_{{getTripStatusColor(next_plus_status)}}_last text-right p-r-5 p-l-5">
          <div class="root_flow text-center">
            <img *ngIf="isDestinationStatus(next_plus_status)"
              src="assets/img/reached_destination_{{getTripStatusColor(next_plus_status)}}.png">
            <img *ngIf="!isDestinationStatus(next_plus_status)"
              src="assets/img/on_the_way_{{getTripStatusColor(next_plus_status)}}.png">
            <div
              class="label_sli color_{{getTripStatusColor(next_plus_status)}} color-progress-{{getTripStatusColor(next_plus_status)}}">
              {{next_plus_status | titlecase}} </div>
          </div>
        </div>

      </div>
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{selectedBooking?.customers?.name_short || selectedBooking?.customers?.name}}
          {{trips?.trip_no}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Loading Point</div>
          <ul>
            <li>{{getStringBeforeComma(trips?.source_city || selectedBooking?.source_city)}}
              <div><span><a href='http://maps.google.com/maps?q={{trips?.location_lat}},{{trips?.location_long}}'
                    target="_blank">{{trips?.location}}</a></span></div>
            </li>
            <li>{{getReportingAt(trips) | date: 'dd MMM yy'}} {{getReportingAt(trips) | date: 'h:mm a'}} </li>
          </ul>
        </div>
        <div class="col">
          <div class="labelBox_e3"> Vehicle </div>
          <ng-container
            *ngIf="trips && trips.truck_hire_challans && trips.truck_hire_challans[0] && trips.truck_hire_challans[0].truck">
            {{ trips?.truck_hire_challans[0]?.truck?.registration_no }}
          </ng-container>
          <ng-container>
            {{ getTruckTypeValue(trips?.truck_type_cd || selectedBooking?.truck_type_cd)}}
          </ng-container>
        </div>
        <div class="col">
          <div class="labelBox_e3"> TE Details</div>
          {{trips?.pricing_executive_name || selectedBooking?.pricing_executive_name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Origin</div>
          {{getStringBeforeComma(selectedBooking?.source_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Destination</div>
          {{getStringBeforeComma(selectedBooking?.destination_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> ETA</div>
          <ul>
            <li>{{selectedBooking?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{selectedBooking?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- </mat-step> -->
<!-- <ng-template #addEWay>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add eWay</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="consignmentsEwayFormGroup">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="nothingabsurd">
            <input type="number" matInput placeholder="eWay Bill Number" formControlName="eway_bill_no">
          </mat-form-field>
        </div>
      </div>
      <div class="mTop15 text-center gap-btn">
        <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
        <button class="mLft5" mat-raised-button color="primary" (click)="addEWayBill()">Add</button>
      </div>
    </form>
  </div>
</ng-template> -->
<ng-template #truckDocuments>
  <div clas="row">
    <div class="col">

      <form [formGroup]="attachTruckDocumentsFormGroup">
        <h3 class="gwheadline">Vehicle Documents</h3>
        <div class="row">
          <div class="col">
            <span class="aLabel">Driver Details</span>
            <span>{{truckHireChallan.truck.drivers[0].user.name}}
              {{truckHireChallan.truck.drivers[0].user.mobile_no}} </span>
          </div>
        </div>
        <div class="row ">
          <div class="col">
            <!-- <label class="fileTitle required">Driving License*</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('driving_license_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('driving_license_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload DL</span><input type="file" style="display: none;"
                name="file[]" (change)="uploadDrivingLicenseScan($event, truckHireChallan.truck.drivers[0].id)">
            </label>
          </div>
          <div class="col">
            <!-- <label class="fileTitle  required">RC*</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('registration_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('registration_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload RC</span> <input type="file" style="display: none;"
                name="file[]" (change)="uploadRegistrationScan($event, truckHireChallan.truck.id)">
            </label>
          </div>
          <div class="col">
            <!-- <label class="fileTitle">Insurance</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('insurance_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('insurance_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Insurance</span><input type="file"
                style="display: none;" name="file[]" (change)="uploadInsuranceScan($event, truckHireChallan.truck.id)">
            </label>
          </div>
          <div class="col">
            <!-- <label class="fileTitle">Fitness</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('fitness_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('fitness_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Fitness</span><input type="file" style="display: none;"
                name="file[]" (change)="uploadFitnessScan($event, truckHireChallan.truck.id)">
            </label>
          </div>

        </div>
        <div class="row">
          <div class="col">
            <!-- <label class="fileTitle">Permit</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('permit_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('permit_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Permit</span><input type="file" style="display: none;"
                name="file[]" (change)="uploadPermitScan($event, truckHireChallan.truck.id)">
            </label>
          </div>
          <div class="col">
            <!-- <label class="fileTitle">Pollution</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('pollution_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pollution_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Pollution</span> <input type="file"
                style="display: none;" name="file[]" (change)="uploadPollutionScan($event, truckHireChallan.truck.id)">
            </label>
          </div>

          <div class="col">
            <!-- <label class="fileTitle">Owner Pan Card</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('pan_card_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pan_card_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary  fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Owner Pan Card</span><input type="file"
                style="display: none;" name="file[]"
                (change)="uploadOwnerPanScan($event, truckHireChallan.partners.id)">
            </label>
          </div>
          <div class="col">
            <!-- <label class="fileTitle">TDS Decl..</label> -->
            <label *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').value"><a
                href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('tds_declaration_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload TDS.</span><input type="file" style="display: none;"
                name="file[]" (change)="uploadOwnerTdsScan($event, truckHireChallan.partners.id)">
            </label>
            <mat-error
              *ngIf="!attachTruckDocumentsFormGroup.get('tds_declaration_scan').valid && attachTruckDocumentsFormGroup.get('tds_declaration_scan').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').errors.required">TDS is
                required</span>
            </mat-error>
          </div>

        </div>
        <div class="row">
          <div class="col">
            <app-dropdowns-req diabled [required]=true [type]="'partner_type_cd'"
              [selected]="attachTruckDocumentsFormGroup.value.partner_type_cd" (onSelect)="setpartnerTypeCd2($event)"
              (touched)="attachTruckDocumentsFormGroup.get('partner_type_cd').markAsTouched()"
              [placeholder]="'Partner Type'" [valid]="attachTruckDocumentsFormGroup.get('partner_type_cd').valid">
            </app-dropdowns-req>
            <mat-error class="mapError"
              *ngIf="!attachTruckDocumentsFormGroup.get('partner_type_cd').valid && attachTruckDocumentsFormGroup.get('partner_type_cd').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('partner_type_cd').errors.required">Partner Type is
                required.</span>
            </mat-error>
          </div>
          <div class="col">
            <mat-form-field class="notingabsurd">
              <input appUppercase type="text" matInput placeholder="Pan Card No" formControlName="pan_no">
              <mat-error
                *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').valid && attachTruckDocumentsFormGroup.get('pan_no').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is required</span>
                <span *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is invalid</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="notingabsurd">
              <input type="number" min=1000000000 max=9999999999 required matInput placeholder="Owner mobile no"
                formControlName="mobile_no">
              <mat-error
                *ngIf="!attachTruckDocumentsFormGroup.get('mobile_no').valid && attachTruckDocumentsFormGroup.get('mobile_no').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.required">Partner mobile number is
                  required</span>
                <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.min">Mobile number is incorrect(10
                  digits)</span>
                <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.max">Mobile number is incorrect(10
                  digits)</span>

              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="notingabsurd">
              <input appUppercase required type="text" matInput placeholder="Owner Contact Name"
                formControlName="partner_contact_name">
              <mat-error
                *ngIf="!attachTruckDocumentsFormGroup.get('partner_contact_name').valid && attachTruckDocumentsFormGroup.get('partner_contact_name').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('partner_contact_name').errors.required"> Partner
                  contact
                  name is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="notingabsurd">
              <input appUppercase required type="text" matInput [matAutocomplete]="autoabn" placeholder="Owner RC Name"
                formControlName="partner_company_name">
              <mat-autocomplete #autoabn="matAutocomplete" panelWidth="300px">
                <mat-option *ngFor="let option of partnerList" [value]="option.name"
                  (onSelectionChange)="selectOwnerName(option)">
                  <ul>
                    <li>{{option.webxpress_partner_code}} {{ option.name }}</li>
                  </ul>
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="!attachTruckDocumentsFormGroup.get('partner_company_name').valid && attachTruckDocumentsFormGroup.get('partner_company_name').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('partner_company_name').errors.required"> Owner name is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            {{attachTruckDocumentsFormGroup.get('mobile_provider_cd').value}}
          </div>
          <div class="col">
            <mat-checkbox formControlName="telenity_enabled">Track</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTruckDocument()">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #updateStatus>
  <div clas="row">
    <div class="col">

      <form [formGroup]="consignmentTripTrackingsFormGroup">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">
        <h3 class="gwheadline">Update Status</h3>
        <div class="row">
          <div class="col">
            <mat-form-field class="notingabsurd">
              <mat-select required placeholder="Next Trip Status" formControlName="trips_status_cd"
                [(value)]="consignmentTripTrackingsFormGroup.value.trips_status_cd">
                <mat-option *ngFor="let item of getAllStatuses()" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <app-map-req [addressType]="'(regions)'" [placeholder]="'Current Location'"
              [value]="consignmentTripTrackingsFormGroup.value.last_location" (onSelect)="selectLocation($event)">
            </app-map-req>
            <mat-error class="mapError"
              *ngIf="!consignmentTripTrackingsFormGroup.get('last_location').valid && consignmentTripTrackingsFormGroup.get('last_location').touched">
              Location is required.
            </mat-error>
            <input type="hidden" value="" formControlName="last_location_lat">
            <input type="hidden" value="" formControlName="last_location_long">
          </div>
          <div class="col-sm-2">
            <mat-form-field class="notingabsurd">
              <input matInput [matDatepicker]="status_at_dt" placeholder="Date" formControlName="status_at">
              <mat-datepicker-toggle matSuffix [for]="status_at_dt"></mat-datepicker-toggle>
              <mat-datepicker #status_at_dt></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field class="notingabsurd">
              <input mat-timepicker matInput type="time" placeholder="Time" formControlName="status_at_time">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col" *ngIf="consignmentTripTrackingsFormGroup.get('trips_status_cd').value == 'CANCELLED'">
            <mat-form-field class="notingabsurd">
              <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
              </mat-select>
              <mat-error class="mapError"
                *ngIf="!consignmentTripTrackingsFormGroup.get('cancel_reason_cd').valid  && !consignmentTripTrackingsFormGroup.get('cancel_reason_cd').touched">
                Cancel reason is required is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="notingabsurd">
              <input type="text" matInput placeholder="Remarks" formControlName="remarks">
            </mat-form-field>
          </div>
          <div class="col-sm-4">
            <mat-slide-toggle matInput formControlName="is_visible_to_customer">Is visible to Customer
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTripStatus()">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #tripSummaryCNote  let-trip="aTripsData">
  <h2>Consignment</h2>
  <div class="fieldset mb-4">
    <div class="conatiner_flow">
      <div *ngIf="trip && hasStatusGrapics" class="row border-b-1 m-2 mb-3">
        <div
          class="col col_b_{{getTripStatusColor(trip.trips_status_cd)}}{{hasNextStatusSuffx(trip.trips_status_cd, trip)}} p-r-5 p-l-5">
          <div class="root_flow ">
            <img *ngIf="isDestinationStatus(trip.trips_status_cd)"
              src="assets/img/reached_destination_{{getTripStatusColor(trip.trips_status_cd)}}.png">
            <img *ngIf="!isDestinationStatus(trip.trips_status_cd)"
              src="assets/img/vehicle_assigned_{{getTripStatusColor(trip.trips_status_cd)}}.png">
            <div class="label_sli color_{{getTripStatusColor(trip.trips_status_cd)}}">
              {{trip.trips_status_cd | titlecase}} </div>
          </div>
        </div>
        <div *ngIf="next_status " class="col text-center col_b_{{getTripStatusColor(next_status)}} p-r-5 p-l-5">
          <div class="root_flow ">
            <img *ngIf="isDestinationStatus(next_status)"
              src="assets/img/reached_destination_{{getTripStatusColor(next_status)}}.png">
            <img *ngIf="!isDestinationStatus(next_status)"
              src="assets/img/on_the_way_{{getTripStatusColor(next_status)}}.png">
            <div
              class="label_sli color_{{getTripStatusColor(getNextTripsStatus(trips))}} color-progress-{{getTripStatusColor(next_status)}}">
              {{next_status  | titlecase}} </div>
          </div>
        </div>


        <div *ngIf="next_plus_status"
          class="col col_b_{{getTripStatusColor(next_plus_status)}}_last text-right p-r-5 p-l-5">
          <div class="root_flow text-center">
            <img *ngIf="isDestinationStatus(next_plus_status)"
              src="assets/img/reached_destination_{{getTripStatusColor(next_plus_status)}}.png">
            <img *ngIf="!isDestinationStatus(next_plus_status)"
              src="assets/img/on_the_way_{{getTripStatusColor(next_plus_status)}}.png">
            <div
              class="label_sli color_{{getTripStatusColor(next_plus_status)}} color-progress-{{getTripStatusColor(next_plus_status)}}">
              {{next_plus_status | titlecase}} </div>
          </div>
        </div>

      </div>
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{selectedBooking?.customers?.name_short || selectedBooking?.customers?.name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> LR# </div>
          <ng-container>
            {{ consignmentNotes?.consignment_no }}
          </ng-container>
        </div>
        <div class="col">
          <div class="labelBox_e3"> Origin</div>
          {{getStringBeforeComma(consignmentNotes?.consignor_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Destination</div>
          {{getStringBeforeComma(consignmentNotes?.consignee_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> ETA</div>
          <ul>
            <li>{{consignmentNotes?.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{consignmentNotes?.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- <ng-template #itemDetails2 let-aForm="aFormAtIndex"> -->
<ng-template #itemDetails2>
  <form [formGroup]="consignmentItemsFormGroup">
    <div class="fieldset mb-4">
      <h3 class="gwheadline">LR Items {{consignmentNotes?.consignment_no}}</h3>
      <div class="row">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">
        <label class="fields_t_b pt-3 color_blue"> Invoice Details</label>
        <div class="row">
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="hidden" formControlName="consignment_notes_id" [value]="consignmentNotes?.id">
              <input digitOnly type="number" matInput placeholder="eWay Bill" formControlName="eway_bill_no">
              <mat-error *ngIf="!consignmentItemsFormGroup.get('eway_bill_no').valid
                    && consignmentItemsFormGroup.get('eway_bill_no').touched">
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.required">Eway Bill No is
                  required</span>
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.min">Eway Bill No incorrect.
                  Enter 12 digits</span>
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.max">Eway Bill No
                  incorrect.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase type="text" matInput placeholder="Invoice No" formControlName="invoice_no">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_no').valid && consignmentItemsFormGroup.get('invoice_no').touched">
                <span *ngIf="consignmentItemsFormGroup.get('invoice_no').errors.required"> Invoice No is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input matInput [matDatepicker]="pickerinv2" placeholder="Invoice Date" formControlName="invoice_dt">
              <mat-datepicker-toggle matSuffix [for]="pickerinv2"></mat-datepicker-toggle>
              <mat-datepicker #pickerinv2></mat-datepicker>
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_dt').valid && consignmentItemsFormGroup.get('invoice_dt').touched">
                <span> Invoice Date is required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Invoice Value" formControlName="invoice_value">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_value').valid && consignmentItemsFormGroup.get('invoice_value').touched">
                <span *ngIf="consignmentItemsFormGroup.get('invoice_value').errors.required"> Invoice Value is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="fields_t_b pt-3 color_blue"> Item Details</label>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="nothingabsurd">
              <input type="text" required appUppercase matInput placeholder="Item Description"
                formControlName="description">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('description').valid && consignmentItemsFormGroup.get('description').touched">
                <span *ngIf="consignmentItemsFormGroup.get('description').errors.required"> Description is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <mat-select required placeholder="Units of Measure" formControlName="unit_cd">
                <!-- <mat-option value="Tons">Tons</mat-option>
                <mat-option value="Kg">Kg</mat-option> -->
                <mat-option value="Pcs">Pcs / Boxes / Units</mat-option>
                <!-- <mat-option value="UNT">UNT</mat-option>
                <mat-option value="Bags">Bags</mat-option>
                <mat-option value="Bags">Drums</mat-option> -->
              </mat-select>
              <mat-error class="mapError"
                *ngIf="!consignmentItemsFormGroup.get('unit_cd').valid && consignmentItemsFormGroup.get('unit_cd').touched">
                <span>Unit of measure is required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Units" formControlName="quantity">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Actual Weight" formControlName="actual_wt">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('actual_wt').valid && consignmentItemsFormGroup.get('actual_wt').touched">
                <span *ngIf="consignmentItemsFormGroup.get('actual_wt').errors.required"> Actual weight is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Charged Weight" formControlName="charged_wt">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('charged_wt').valid && consignmentItemsFormGroup.get('charged_wt').touched">
                <span *ngIf="consignmentItemsFormGroup.get('charged_wt').errors.required"> Charged weight is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="mTop15 text-right gap-btn">
      <button class="mLft5" mat-raised-button color="primary"
        *ngIf="consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value"
        (click)="addItem()">Save</button>
      <button class="mLft5" mat-raised-button color="primary"
        *ngIf="!(consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value) "
        (click)="addItem()">Add</button>
    </div>
  </form>
</ng-template>
<ng-template #sendConsignment>
  <form [formGroup]="sendConsignmentFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Send LR for Print'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:consignmentNotes,aTripsData:trips}">
        </ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Send LR {{consignmentNotes?.consignment_no}}</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Name" formControlName="to_name">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_name').valid && !sendConsignmentFormGroup.get('to_name').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Email" formControlName="to_email">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_email').valid && !sendConsignmentFormGroup.get('to_email').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="row buttonset">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary"
              (click)="submitSendLr(sendConsignmentFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>
<ng-template #addConsignment>
  <ng-container *ngIf="consignmentItemsFormGroup.get('id').value || consignmentItemsFormGroup.get('_id').value">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Edit Consignment Item'}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!(consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value)">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Add Consignment Item'}"></ng-container>
  </ng-container>
  <div class="modal-body ">
    <div class="details_list_item">
      <ng-container *ngTemplateOutlet="itemDetails2"></ng-container>
      <!-- <div class="mTop15 text-right gap-btn">
          <button class="mLft5" mat-raised-button color="primary" (click)="addItem()">Add</button>
        </div> -->
    </div>
  </div>
</ng-template>
<ng-template #addConsignmentOld>
  <form [formGroup]="consignmentItemsFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Add Consignment Items'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:consignmentNotes,aTripsData:trips}">
        </ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">LR Items {{consignmentNotes?.consignment_no}}</h3>
          <ng-container *ngTemplateOutlet="itemDetails2"></ng-container>
        </div>
        <div class="mTop15 text-right gap-btn">
          <button class="mLft5" mat-raised-button color="primary" (click)="addItem()">Add</button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #updateConsignment>
  <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update Consignment'}"></ng-container>
  <div class="modal-body ">
    <div class="details_list_item">
      <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
      <div class="fieldset mb-4">
        <ng-container *ngTemplateOutlet="cNoteDetails"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cNoteDetails>
  <div clas="row">
    <div class="col">
      <form [formGroup]="consignmentNotesFormGroup">
        <h3 class="gwheadline">Consignment Details cNoteDetails4</h3>
        <!-- <mat-horizontal-stepper [linear]="isLinear" #companyDetails>
              <mat-step [stepControl]="companyDetails"> -->
        <!-- <ng-template matStepLabel>Step 1</ng-template> -->
        <div class="row">
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase required type="text" matInput placeholder="LR Number"
                formControlName="consignment_no">
              <!-- <app-mat-error  [control]="consignmentNotesFormGroup.get('consignment_no')" displayName="LR2 No"></app-mat-error> -->

              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('consignment_no').valid && consignmentNotesFormGroup.get('consignment_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('consignment_no').errors.required">Consignment No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase type="text" matInput placeholder="Container No" formControlName="container_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('container_no').valid && consignmentNotesFormGroup.get('container_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('container_no').errors.required">Container No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="text" appUppercase matInput placeholder="Seal No" formControlName="seal_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('seal_no').valid && consignmentNotesFormGroup.get('seal_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('seal_no').errors.required">Seal No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="consignment_items" [@visibilityChanged]="visiblityState">
          <ng-template *ngTemplateOutlet="itemDetails2">
          </ng-template>
        </div>
        <div class="table-responsive" *ngIf="consignmentItemList?.length >0">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th>Invoice / Eway</th>
                <th>Invoice Dt / Eway Exp Dt</th>
                <th>Item Description</th>
                <th>Units</th>
                <th>Weight</th>
                <!-- <th>Charged Weight</th> -->

                <!-- <th>Volume</th>							 -->
                <th></th>
              </tr>
              <tr *ngFor="let item of consignmentItemList">
                <td>{{item.invoice_no}} {{ item.eway_bill_no}}</td>
                <td>{{item.invoice_dt | date: 'dd MMM yy'}} / {{item.eway_valid_upto_dt || consignmentNotes.eway_valid_upto_dt | date:
                  'dd MMM yy H:mm'}} </td>

                <td>{{item.description}}</td>
                <td>{{item.quantity}} {{item.unit_cd}}</td>
                <td>{{item.actual_wt}}</td>
                <!-- <td>{{item.controls.charged_wt.value}}</td> -->
                <td>
                  <button mat-mini-fab color="primary" (click)="openModalEditItem(addConsignment,item)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <!-- <button mat-mini-fab color="warn">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mTop15 text-right gap-btn">
            <button class="mLft5" mat-raised-button color="secondary" (click)="addMoreInvoices()">Add More
              Invoices</button>
            <button class="mLft5" mat-raised-button color="primary" (click)="addMoreItems()">Add More Items</button>
          </div>
        </div>
        <div class="row m-t-10">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Attachments</label>
            <div class="row">
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.invoice_scan"><a href="{{imageUrl}}{{consignmentNotes?.invoice_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload required btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Invoice</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadInvoice($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('invoice_scan').valid && consignmentNotesFormGroup.get('invoice_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('invoice_scan').errors.required">Please attach invoice
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.eway_bill_scan"><a href="{{imageUrl}}{{consignmentNotes?.eway_bill_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload required btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload eWay Bill</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEway($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('eway_bill_scan').valid && consignmentNotesFormGroup.get('eway_bill_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('eway_bill_scan').errors.required">Please attach eWay
                    Bill
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.consignment_scan"><a [href]="imageUrl +consignmentNotes?.consignment_scan" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload  btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload LR</span><input type="file" style="display: none;"
                    name="file[]" (change)="uploadConsignmentNote($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('consignment_scan').valid && consignmentNotesFormGroup.get('consignment_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('consignment_scan').errors.required">Please attach
                    consignment
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="consignmentNotes?.truck_scan"><a href="{{imageUrl}}{{consignmentNotes?.truck_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Truck/Trailer/Driver Photo</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadTruck($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('truck_scan').valid && consignmentNotesFormGroup.get('truck_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('truck_scan').errors.required">Please attach trailer
                    photo
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach" *ngIf="selectedBooking?.business_type_cd == 'EXIM'">
                <!-- <label class="fileTitle">Empty Letter </label> -->
                <label *ngIf="consignmentNotes?.empty_letter_scan"><a href="{{imageUrl}}{{consignmentNotes?.empty_letter_scan}}"
                    target="_blank"><i class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Empty Letter</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEmptyLetter($event, consignmentNotes?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('empty_letter_scan').valid && consignmentNotesFormGroup.get('empty_letter_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('empty_letter_scan').errors.required">Please attach
                    Empty letter
                    scan</span>
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Insurance</label>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="Insurance By" formControlName="consignment_insured_by">
                    <mat-option value="NOT INSURED">NOT INSURED</mat-option>
                    <mat-option value="CONSIGNOR">CONSIGNOR</mat-option>
                    <mat-option value="CONSIGNEE">CONSIGNEE</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_insured_by').valid && consignmentNotesFormGroup.get('consignment_insured_by').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_insured_by').errors.required">Insured by
                      is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="At Risk of" formControlName="consignment_risk">
                    <mat-option value=""></mat-option>
                    <mat-option value="FLEET OWNER">OWNER</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_risk').valid && consignmentNotesFormGroup.get('consignment_risk').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_risk').errors.required">At Risk Of is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Co"
                    formControlName="insurance_provider">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_provider').valid && consignmentNotesFormGroup.get('insurance_provider').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_provider').errors.required">Insurance
                      provider
                      name is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Policy No."
                    formControlName="insurance_poilicy_no">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_poilicy_no').valid && consignmentNotesFormGroup.get('insurance_poilicy_no').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_poilicy_no').errors.required">Insurance
                      policy
                      number is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input matInput [matDatepicker]="picker2" placeholder="Insurance Date" formControlName="insurance_dt">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_dt').valid && consignmentNotesFormGroup.get('insurance_dt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_dt').errors.required">Insurance date is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Validity"
                    formControlName="insurance_validity">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Freight Details</label>
            <div class="row">

              <div class="col" *ngIf="userIsAdmin()">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Freight Charges" formControlName="freight_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('freight_amt').valid && consignmentNotesFormGroup.get('freight_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('freight_amt').errors.required">Freight is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Loading Charges" formControlName="loading_charges">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('loading_charges').valid && consignmentNotesFormGroup.get('loading_charges').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('loading_charges').errors.required">Loading amount is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Misc Charges" formControlName="misc_charges_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('misc_charges_amt').valid && consignmentNotesFormGroup.get('misc_charges_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('misc_charges_amt').errors.required">Misc charge is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                    <mat-option *ngFor="let item of getConsignmentStatuses()" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Remarks" formControlName="remarks">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mTop15 text-right gap-btn">
            <!-- <button mat-raised-button color="secondary"
              (click)="openModalSendConsignment(sendConsignment, consignmentNotes)" routerLinkActive="active">Send
              LR</button> -->
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value == 'INCOMPLETE'  ">
              <button mat-raised-button color="secondary" (click)="submit()"
                routerLinkActive="active">Save</button>
              <button mat-raised-button color="primary" (click)="submitBooked()"
                routerLinkActive="active">Save & Mark Booked</button>
            </ng-container>
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value != 'INCOMPLETE'  ">
              <button mat-raised-button color="primary" (click)="submit()"
                routerLinkActive="active">Save</button>

            </ng-container>

            <!-- <button mat-raised-button color="primary" (click)="submitLoaded()" routerLinkActive="active">Save &
          Mark Picked Up</button> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
