import { Injectable } from '@angular/core';
import {HttpService} from './http-service.service'
import { Observable } from 'rxjs';
import { ResponseModel } from '../model/response';
@Injectable()
export class FileTransferService {
  constructor(private serviceHttp:HttpService) { }
  uplodaFile(file: File): Observable<ResponseModel> {
    const fd = new FormData();
    fd.append('file', file);
    return this.serviceHttp.getResponseFile('upload', fd);
  }
  uploadContractFile(file: File): Observable<ResponseModel> {
    const fd = new FormData();
    fd.append('file', file);
    return this.serviceHttp.getResponseFile('contractlineupload', fd);
  }
  uploadEntityDocument(file: File, entityId: number = 0, entityType: string = '', documentType: string = ''){
    const fd = new FormData();
    fd.append('file', file);
    fd.append('entity_id', entityId + '');
    fd.append('entity_type', entityType);
    fd.append('document_type', documentType);
    return this.serviceHttp.getResponseFile('upload', fd);
  }
}
