<header class="header" id="header">
  <div class="logo pull-left">
    <a [routerLink]="['user/login']">
      <img src="assets/img/glasswing_logo.svg">
    </a>
  </div>

  <div class="headerSearchBar headerSearchBar1" *ngIf="user?.roles_id == 3 && !advanceSearchFlag">
    <input type="text" class="form-searchbar" [(ngModel)]="inputSearch" (keydown)="getSerachData($event)" placeholder="Search">
    <span class="search-btn"><i class="ti-search"></i> </span>

    <button mat-raised-button (click)="onClickAdvanceSearch()" class="confirm_btn blueButton saveButton" style="margin-left: 20px;">Advance Seach</button>
  </div>

  <div class="headerSearchBar advsearch"  *ngIf="user?.roles_id == 3 && advanceSearchFlag">
    <button mat-raised-button (click)="onClickAdvanceSearch()" class="advSeachBtn"> <span style="font-weight: bold;font-size: 15px;margin-right: 6px;"><</span>  Advance Seach</button>

    <mat-form-field class="mat-form-field" style="margin: 0px 20px;">
      <mat-select placeholder="Origin" [(value)]="selectedSource">
        <!--  (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()" -->
        <mat-option *ngFor="let element of sourceList"  [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mat-form-field" style="margin-right: 20px;">
      <mat-select placeholder="Destination" [(value)]="selectedDestination">
        <!--  (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()" -->
        <mat-option *ngFor="let element of destinationList"  [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field class="mat-form-field">
      <mat-select placeholder="Date Range" [(value)]="selected">
        <mat-option *ngFor="let element of list"  [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <mat-form-field class="mat-form-field">
      <input matInput [matDatepicker]="dtPicker" placeholder="Date range">
      <mat-datepicker-toggle matSuffix [for]="dtPicker"></mat-datepicker-toggle>
      <mat-datepicker #dtPicker></mat-datepicker>
    </mat-form-field> -->
    <mat-form-field class="mat-form-field" appearance="fill">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input
        [formGroup]="campaignOne"
        [rangePicker]="campaignOnePicker"
        [comparisonStart]="campaignOne.value.start"
        [comparisonEnd]="campaignOne.value.end">
        <input matStartDate placeholder="Start date" formControlName="start">
        <input matEndDate placeholder="End date" formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <!-- <div class="advancesearchbox"  *ngIf="user?.roles_id == 3" (click)="getSerachData()"><span class="addSearch">Search</span></div> -->

  <div class="rightUserSec " >
    <div class="notificationSection notificationSection-plus" *ngIf="userCanCreateOrder && (user?.roles_id == 1 || user?.roles_id == 10)">
      <!-- <div class="notificationSection"> -->
      <button mat-button class="blueButton newOrdBtn " (click)="createBooking()">
        <span class="btnBorder nOrdBtn">
          Create Order</span>
      </button>
    </div>
    <!-- <div class="notificationSection">
      <button class="userIcon" mat-icon-button>
    <img src="assets/img/new icons/settings.svg" alt="" style="height: 40px;">
    </button>
  </div> -->
    <div class="notificationSection" >
      <span class="badge style-danger"></span>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="notificationClick()" style="display: flex;">
        <mat-icon class="icon_bell" [matBadge]="notificationLength()" matBadgePosition="above after"
          [matBadgeColor]="badgeColor">
          <!-- <i class="material-icons iconBell">notifications</i> -->
          <img src="assets/img/new icons/notification.svg" alt="" style="height: 40px;margin-top: -9px;">
        </mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="notificationMenuBox">
        <div class="listview">
          <div class="lv-header">Notifications</div>
          <!-- *ngFor="let notification of notificationsList;let i=index " -->
          <div class="lv-body" *ngFor="let notification of notificationsList;let i=index ">
            <a class="listData" *ngIf="i < 3">
              <div *ngIf="notification">
                <div class="textSec">
                  <small class="lv-small"> {{notification.data.message}} </small>
                </div>
              </div>
            </a>
          </div>
          <div class="clearfix"></div>
          <a class="lv-footer" [routerLink]="['notifications/list']">View All Notifications</a>
        </div>
      </mat-menu>
    </div>
    <!-- <button class="flag" mat-icon-button [matMenuTriggerFor]="language">
         <div class="flag-pic">
           <img src="assets/img/eng.svg" alt="user">
         </div>
      </button> -->
    <!-- <mat-menu #language="matMenu">
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="#">
          <img src="assets/img/india.svg" alt="user">
        Hindi</a>
        <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-us"></i> English</a>
      </div>
      </mat-menu>  -->
      <div class="notificationSection">
      <button class="userIcon" mat-icon-button>
    <!-- <span class="material-icons">
    settings
    </span> -->
    <img src="assets/img/new icons/settings.svg" alt="" style="height: 40px;">
    </button>
  </div>
    <div class="notificationSection">
    <button class="userIcon" mat-icon-button [matMenuTriggerFor]="userProfileMenu">
      <!-- <mat-icon class="icon_bell">
        <i class="material-icons userIcon">account_circle</i>

        <i class="material-icons iconBell">notifications</i>
      </mat-icon> -->
      <img src="assets/img/new icons/profile.svg" alt="" style="height: 40px;">
    </button>
  </div>
    <mat-menu #userProfileMenu="matMenu" class="userProfileMenuBox">
      <div class="headerUserSec">
        <ul class="dropdown-user">
          <li role="separator" class="divider">
          </li>
          <li><a (click)="logout()"><i class="fa fa-power-off"></i>
            <ul>
              <li><span>{{user?.name}}</span></li>
              <li><span>{{getCompanyName()}}</span></li>
            </ul>
            </a>
          </li>
          <!-- <li><a (click)="logout()"><i class="fa fa-power-off"></i>
            <div></div>
              Logout {{user?.name}} {{getCompanyName()}}
            </a>
          </li> -->
        </ul>
      </div>
    </mat-menu>
  <!-- </div>
  <div class="rightUserSec headerRightActions"> -->

  </div>
</header>
