import {Partners} from '../partners/partners';
export class PartnerVehicles {
    id : number;
    partners_id : number;
    truck_type_cd : string;
    quantity : number;
    loading_charges: number;
    unloading_charges: number;
    detention_charges: number;
    partners : Partners;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : PartnerVehicles[] {
        let partnerVehicles : PartnerVehicles[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                partnerVehicles.push(new PartnerVehicles(json));
            }
        }
        return partnerVehicles;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.partners_id = json.partners_id;
            this.truck_type_cd = json.truck_type_cd;
            this.quantity = json.quantity;
            if (json.partners != null) {
                this.partners = new Partners(json.partners);
            }
            this.loading_charges = json.loading_charges;
            this.unloading_charges = json.unloading_charges;
            this.detention_charges = json.detention_charges;
        }
    }
}
