<div class=" content_area_list container inne_area_list mobScroll mob-p-default ">
  <div class="row example-header">
    <div class="col-sm-8 text-center">
      <form [formGroup]="tripsSearchForm">
        <div class="row" #searchBar *ngIf="searchBarOpen">
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput #filter placeholder="Search Trips" formControlName="search">
            </mat-form-field>
          </div>

          </div>

      </form>
    </div>
    <div class="col-sm-4 text-right">
      <span *ngIf="!searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_more</mat-icon>
        </button></span>
      <span *ngIf="searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_less</mat-icon>
        </button></span>
      <span *ngIf="bookings_id"><button mat-icon-button [routerLink]="['/trips/map/booking', bookings_id]">
          <mat-icon>map</mat-icon>
        </button></span>
        <span *ngIf="!bookings_id"><button mat-icon-button (click)="mapView()" >
          <mat-icon>map</mat-icon>
        </button></span>

      <!-- <span *ngIf="!bookings_id">
          <a mat-icon-button color="primary" [routerLink]="['/trips/map']" routerLinkActive="active">
            <i class="material-icons">map</i></a>
      </span> -->
      <span><button mat-icon-button [routerLink]="['/trips/add']">
          <mat-icon>add</mat-icon>
        </button></span>
      <!-- <span><button mat-raised-button [routerLink]="
          ['/trips/add']">Add New</button> </span> -->
      <span><button mat-icon-button [routerLink]="['/trips/map']">
          <mat-icon>map</mat-icon>
        </button></span>
        <span><button mat-icon-button color="primary" (click)="download($event)">
          <mat-icon>cloud_download</mat-icon>
        </button></span>
      <span><button mat-icon-button color="primary" (click)="refresh($event)">
          <mat-icon>refresh</mat-icon>
        </button></span>

    </div>
  </div>
  <div class="table_list">
    <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
      <mat-table #table class="table_list" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="tripId">
        <mat-header-cell *matHeaderCellDef class="width100"> Trip Id </mat-header-cell>
        <mat-cell *matCellDef="let element" class="width100"> {{element.trip_no}} / {{element.bookings?.booking_no}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerName">
        <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ul>
            <li>{{element.bookings?.customers?.name}}</li>
            <li>{{element.bookings?.booking_for_dt | date: 'dd MMM yy yy'}} {{element.bookings?.booking_for_start | date: 'h:mm a' }} </li>
            <li *ngIf="element.trip_type_cd == 'Short'" class="text-green">Short Haul</li>
          </ul>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="SourceCity">
        <mat-header-cell *matHeaderCellDef> Origin - Destination </mat-header-cell>

        <mat-cell *matCellDef="let element">
          <ul *ngIf="element.trip_addresses?.length">
            <li *ngFor="let trip_address of element.trip_addresses;
              let first = first; let last = last; let i = index">
              {{trip_address.city}} <span>ETA:
                {{trip_address.estimated_arrival_dt  | date: 'dd MMM yy h:mm A'}}

              </span>
            </li>
          </ul>
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="destination_city">
        <mat-header-cell *matHeaderCellDef> Destination City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.destination_city}} </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="ConsignmentNo">
        <mat-header-cell *matHeaderCellDef> Consignment </mat-header-cell>
        <ng-container *matCellDef="let element">
          <mat-cell *ngIf="element.consignment_trips && element.consignment_trips.length">
            <ul>
              <li *ngFor="let consignment_trip of element.consignment_trips;
            let first = first; let last = last; let i = index">
                <span *ngIf="consignment_trip.consignment_note?.consignment_no">
                  <ul>
                    <li>
                      <!-- <a [routerLink]="['/consignmentNotes/edit', consignment_trip.consignment_note.id]">{{consignment_trip.consignment_note?.consignment_no}}
                      </a> -->
                      <!-- <a [routerLink]="" (click)="openModalConsignment(updateConsignment, consignment_trip.consignment_note.id, element)">{{consignment_trip.consignment_note?.consignment_no}}
                      </a> -->
                      {{consignment_trip.consignment_note?.consignment_no}}
                    </li>
                    <li>{{consignment_trip.consignment_note.consignment_status_cd}}</li>
                  </ul>
                </span>
                <span *ngIf="!consignment_trip.consignment_note?.consignment_no">
                  <ul>
                    <li>
                      <!-- <a [routerLink]="['/consignmentNotes/edit', consignment_trip.consignment_note.id]">{{consignment_trip.consignment_note.consignment_status_cd}}</a> -->
                      <!-- <a [routerLink]="" (click)="openModalConsignment(updateConsignment, consignment_trip.consignment_note.id, element)">{{consignment_trip.consignment_note.consignment_status_cd}}</a> -->
                      {{consignment_trip.consignment_note.consignment_status_cd}}
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </mat-cell>
          <mat-cell *ngIf="!element.consignment_trips || element.consignment_trips.length ==0"> Attach </mat-cell>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="trips_status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ul>
            <li>{{element.trips_status_cd}}</li>
            <li>{{element.updated_at?.toLocaleString('en-IN')}}</li>
          </ul>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef> Location </mat-header-cell>
        <mat-cell *matCellDef="let element;"> {{element.location}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="emp">
          <mat-header-cell *matHeaderCellDef> Traffic / LR </mat-header-cell>
          <mat-cell *matCellDef="let element;"> <ul>
            <li>
                {{element.pricing_executive_name}}
            </li>
            <li>
                {{element.dispatch_executive_name}}
            </li>
            <li>
                {{element.tracking_executive_name}}
            </li>
          </ul> </mat-cell>
        </ng-container>
      <ng-container matColumnDef="TruckRegistrationNo">
        <mat-header-cell *matHeaderCellDef> Truck Reg. no. </mat-header-cell>
        <mat-cell *matCellDef="let element;">
          <ul *ngIf="element.truck_hire_challans && element.truck_hire_challans[0]">
            <li *ngIf="element.truck_hire_challans[0].truck">{{getTruckRegistrationNo(element)}}</li>
            <li *ngIf="element.truck_hire_challans[0].truck">{{getTruckTypeValue(element.truck_hire_challans[0].truck.truck_type_cd)}}</li>

            <li *ngIf="element.truck_hire_challans[0]">{{element.truck_hire_challans[0].truck_hire_challan_status_cd}}</li>
            <li>
                {{element.truck_hire_challans[0].broker?.partner_company_name}}
                <!-- {{element.truck_hire_challans[0].broker_contact?.user.name}}
                {{element.truck_hire_challans[0].broker_contact?.user.mobile_no}} -->
              </li>
              <li *ngIf="!element.truck_hire_challans[0].broker?.pan_no">
                <b>'NO PAN'</b>
              </li>
              <li *ngIf="!element.truck_hire_challans[0].broker?.partner_banks[0]">
                <b>'NO BANK'</b>
              </li>
            <li *ngIf="element.truck_hire_challans[0].truck">{{getDriverName(element)}}</li>
            <li *ngIf="element.truck_hire_challans[0].truck">{{getDriverNumber(element)}}</li>
          </ul>
          <ul *ngIf="!element.truck_hire_challans || !element.truck_hire_challans[0]">
            <li>{{getTruckTypeValue(element.truck_type_cd)}}</li>
            <!-- <li>{{element.truck_type_cd}}</li> -->
          </ul>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="HireTruck">
        <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp;</mat-header-cell>
        <mat-cell *matCellDef="let element; " class="width60-Center">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #rootMenu="matMenu">

            <button mat-menu-item [matMenuTriggerFor]="subMenu">More..</button>
          </mat-menu>
          <mat-menu #subMenu="matMenu">
            <button mat-menu-item [routerLink]="['/trips/map/trip',element.id]">
              <span>View on map</span>
            </button>
            <button mat-menu-item [routerLink]="['/tripStatusTrackings/list/trip',element.id]">Track / History</button>
            <!-- <button mat-menu-item [routerLink]="['/truckHireChallans/add/trip',element.id]">
              <span>Hire Truck</span>
            </button> -->
            <button mat-menu-item [routerLink]="['/triplite/cnotelist/trip',element.id]">
              <span>Add/Update CNote</span>
            </button>
            <button *ngIf="needEditButton(element)" mat-menu-item [routerLink]="['/trips/edit',element.id]" routerLinkActive="active">
                <span>Edit</span>
              </button>
            <!-- <button mat-menu-item (click)="openModalRating(editDriverRatings, element)">
              <span>Rate Trip</span>
            </button> -->
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    </div>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page" (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
<ng-template #editDriverRatings>
  <div class="modal-header">
    <h4 class="modal-title pull-left">DriverRatings {{trip_no}} {{trips.source_city}} {{trips.destination_city}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!--    <div class="alert alert-danger" *ngIf="isError">
          <strong>Alert!</strong>
          <ul>
            <li *ngFor="let message of errorMessages">{{message}}</li>
          </ul>
        </div> -->
    <form [formGroup]="driverRatingsFormGroup">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input type="number" matInput placeholder="Rating" formControlName="rating">
            <mat-error *ngIf="!driverRatingsFormGroup.get('rating').valid && driverRatingsFormGroup.get('rating').touched">
              <span *ngIf="driverRatingsFormGroup.get('rating').errors.required"> Rating is required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Remarks" formControlName="remarks">
            <mat-error *ngIf="!driverRatingsFormGroup.get('remarks').valid && driverRatingsFormGroup.get('remarks').touched">
              <span *ngIf="driverRatingsFormGroup.get('remarks').errors.required"> Remarks is required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input type="number" required matInput placeholder="Drivers Id" formControlName="drivers_id">
            <mat-error *ngIf="!driverRatingsFormGroup.get('drivers_id').valid && driverRatingsFormGroup.get('drivers_id').touched">
              <span *ngIf="driverRatingsFormGroup.get('drivers_id').errors.required"> Drivers Id is required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input type="number" required matInput placeholder="Trips Id" formControlName="trips_id">
            <mat-error *ngIf="!driverRatingsFormGroup.get('trips_id').valid && driverRatingsFormGroup.get('trips_id').touched">
              <span *ngIf="driverRatingsFormGroup.get('trips_id').errors.required"> Trip is required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input type="number" required matInput placeholder="Customers Id" formControlName="customers_id">
            <mat-error *ngIf="!driverRatingsFormGroup.get('customers_id').valid && driverRatingsFormGroup.get('customers_id').touched">
              <span *ngIf="driverRatingsFormGroup.get('customers_id').errors.required"> Customers Id is required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mBtm10">
        <div class="col-sm-6 text-left">
          <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
        </div>
        <div class="col-sm-6 text-right">
          <button mat-raised-button color="primary" (click)="submitDriverRatingsForm()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #bidTemplate1>
  <app-bid-details-edit [trips]="trips" [selfModalRef]="modalRef"></app-bid-details-edit>
</ng-template>
<ng-template #approveExcessAdvance>approveExcessAdvance Not Implemented</ng-template>
<ng-template #approveExcessHire>approveExcessHire Not Implemented</ng-template>
<ng-template #gallery>
  <div class="popupDialog">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Gallery {{trip_no}} {{trips.source_city}}
          {{trips.destination_city}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <app-ngx-plain-gallery-page [trips_id]="trips.id"></app-ngx-plain-gallery-page>
      </div>
    </div>
</ng-template>
