/// <reference types="gapi" />
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import {
  Component,
  NgZone,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticatorService } from '../../../core/services/authenticator.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { BidsService } from '../../bids/services/bids.service';
import { BookingsService } from '../../bookings/services/bookings.service';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { DriverRatingsService } from '../../driverRatings/services/driverRatings.service';
import { DriversService } from '../../drivers/services/drivers.service';
import { PartnerContactsService } from '../../partnerContacts/services/partnerContacts.service';
import { PartnersService } from '../../partners/services/partners.service';
import { TruckHireChallansService } from '../../truckHireChallans/services/truckHireChallans.service';
import { TrucksService } from '../../trucks/services/trucks.service';
import { UsersService } from '../../users/services/users.service';
import { TripsService } from '../../trips/services/trips.service';
import { TripsListComponent, TripsDataSource } from '../../trips/list/trips.list.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Trips } from '../../trips/trips';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import * as appGlobals from '../../../core/globalfunctions';
import { StorageService } from '../../../core/services/storage.service';
import { TasksService } from '../../tasks/services/tasks.service';
import { Tasks, tasksFormControls } from '../../tasks/tasks';
import { Observable, of } from 'rxjs';
import { TruckHireChallans } from '../../truckHireChallans/truckHireChallans';
import { ConsignmentEditComponent } from '../cnoteEdit/edit.component';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { ConsignmentNotes, consignmentNotesFormControls, CNoteDataSource } from '../../consignmentNotes/consignmentNotes';
import { InvoiceLines } from '../../invoiceLines/invoiceLines';
import { Invoices } from '../../invoices/invoices';
import { InvoicesService } from '../../invoices/services/invoices.service';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { consignmentItemsFormControls, ConsignmentItems } from '../../consignmentItems/consignmentItems';
import { ConsignmentTrips } from '../../consignmentTrips/consignmentTrips';
import { style, trigger, state, animate, transition } from '@angular/animations';
import { Bookings, bookingsFormControls } from '../../bookings/bookings';
import { Users } from '../../users/users';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-triplite-internal',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    TripsService,
    ConsignmentTripTrackingsService,
    DialogService,
    DriverRatingsService,
    DatePipe,
    BidsService,
    BookingsService,
    TruckHireChallansService,
    FileTransferService,
    UsersService,
    TrucksService,
    DriversService,
    PartnersService,
    PartnerContactsService,
    AuthenticatorService,
    StorageService,
    TasksService,
    ConsignmentNotesService,
    InvoicesService,
    NotificationsService,
    MatDialog
  ],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class TripliteInternalComponent extends TripsListComponent implements OnInit, OnChanges {

  displayedColumns = [
    'cbox',
    'customer',
    'origin',
    'truck_type',
    // 'destination_city',
    'action',
  ];
  displayedColumnsLr = [
    // 'cbox',
    'customer',
    'origin',
    'truck_type',
    // 'destination_city',
    'action',
  ];
  usePreviewPanel = true;
  selectedActivityIndex = 'ALL TASKS';
  selectedGlobalActivityIndex = 'ALL TASKS';
  selectedDispatchActivity = '';
  selectedDispachIndex = 1;
  swipeCoord?: [number, number];
  swipeTime?: number;
  leftTemplate: TemplateRef<any>;
  leftTopTabTemplate: TemplateRef<any>;
  leftListTemplate: TemplateRef<any>;
  disableStatus: boolean = false;

  @ViewChild('topTraffic', { static: true }) topTraffic: TemplateRef<any>;
  @ViewChild('listTrips', { static: true }) listTrips: TemplateRef<any>;
  @ViewChild('listTripsBE', { static: true }) listTripsBE: TemplateRef<any>;
  @ViewChild('listBookings', { static: true }) listBookings: TemplateRef<any>;
  @ViewChild('listIndents', { static: true }) listIndents: TemplateRef<any>;

  @ViewChild('listTripsApprovePayment', { static: true }) listTripsApprovePayment: TemplateRef<any>;
  @ViewChild('listTripsPODStatus', { static: true }) listTripsPODStatus: TemplateRef<any>;
  @ViewChild('listTripsPODReview', { static: true }) listTripsPODReview: TemplateRef<any>;
  @ViewChild('listTripsAssignVehicle', { static: true }) listTripsAssignVehicle: TemplateRef<any>;
  @ViewChild('listTripsGenerateBill', { static: true }) listTripsGenerateBill: TemplateRef<any>;
  @ViewChild('listTripsReviewBill', { static: true }) listTripsReviewBill: TemplateRef<any>;

  @ViewChild('reviewBillPopup', { static: true }) reviewBillPopup: TemplateRef<any>;

  @ViewChild('listTripsRequestPayment', { static: true }) listTripsRequestPayment: TemplateRef<any>;
  @ViewChild('tripSummaryViewTask', { static: true }) tripSummaryViewTask: TemplateRef<any>;
  @ViewChild('listTripsViewTask', { static: true }) listTripsViewTask: TemplateRef<any>;
  @ViewChild('viewTask', { static: true }) viewTask: TemplateRef<any>;

  @ViewChild('tripSummaryPODStatus', { static: true }) tripSummaryPODStatus: TemplateRef<any>;

  @ViewChild('deliverPopup', { static: true }) deliverPopup: TemplateRef<any>;
  @ViewChild('podReviewPopup', { static: true }) podReviewPopup: TemplateRef<any>;

  @ViewChild('billEdit', { static: true }) billPopup: TemplateRef<any>;
  @ViewChild('createBookingDet', { static: true }) createBookingDet: TemplateRef<any>;
  @ViewChild('submittedPopup', { static: true }) submittedPopup: TemplateRef<any>;
  @ViewChild('paymentRecepvedPopup', { static: true }) paymentPopup: TemplateRef<any>;
  @ViewChild('updateBookingStatus', { static: true }) updateBookingStatus: TemplateRef<any>;
  @ViewChild('successTick', { static: true }) successTick: TemplateRef<any>;
  @ViewChild('cancelTask', { static: true }) cancelTask: TemplateRef<any>;
  @ViewChild('escalateTask', { static: true }) escalateTask: TemplateRef<any>;

  @ViewChild('dispatchTabGroup', { static: true }) dispatchTabGroup: MatTabGroup;
  @ViewChild('mrPopup', { static: true }) mrPopup: TemplateRef<any>;
  @ViewChild('calendar', { static: true }) calendar: MatCalendar<any>;
  isOpen: boolean = false;
  selectedDate: Date;
  selectedTask: string;
  selectedPriority: string;
  R_Task: string[] = ['Assign Truck', 'Start For Loading Pt.', 'Mark Reached Loading Point'];
  R_Priority: string[] = ['High', 'Medium', 'Low'];
  tasksList = new Array<Tasks>();
  tasksDataSource: TasksDataSource;
  taskSelection = new SelectionModel<Tasks>(true, []);
  invoiceList: any;
  tasks: any;
  invoice: Invoices;
  task: Tasks;
  tasksFormGroup: FormGroup;
  tasksCount: any;
  completedTasksCount: number;
  tasksCountHash: any[];
  tasksCountHashByEntity: any[];
  createdTasksCount: number;
  trackingTasks = [
    'START FOR LOADING PT.',
    'CONFIRM REACHED LOADING',
    'CONFIRM REACHED EMPTY YARD',
    'CONFIRM CONTAINER PICKED',
    'TRACK',
    'CONFIRM REACHED DESTINATION',
    'CONFIRM UNLOADING START',
    'CONFIRM UNLOADING END',
    'CONFIRM POD',
    'CONFIRM REACHED EMPTY YARD',
    'DROP CONTAINER',
    'TRIP COMPLETE',
    //'COMPLETE BOOKING'
  ];
  trackingTasksBE = [
    'START FOR LOADING PT.',
    'CONFIRM REACHED LOADING',
    'CONFIRM REACHED EMPTY YARD',
    'CONFIRM CONTAINER PICKED',
  ];
  leTrackingTasks = [
    'CONFIRM LOADING START',
    'CONFIRM LOADING END',
    // 'CONFIRM LOADING END',
    // 'CONFIRM REACHED EMPTY YARD',
    // 'CONFIRM CONTAINER PICKED',
    // 'TRACK',
    // 'CONFIRM REACHED DESTINATION',
    // 'CONFIRM UNLOADING START',
    // 'CONFIRM UNLOADING END',
    // 'CONFIRM POD',
    // 'CONFIRM REACHED EMPTY YARD',
    // 'DROP CONTAINER',
    // 'TRIP COMPLETE'
  ];
  paymentTasks = [
    'APPROVE ADVANCE',
    'APPROVE BALANCE',
    'APPROVE ADV FIN',
    'APPROVE BAL FIN',
    'MARK ADVANCE PAID',
    'MARK BALANCE PAID',
    'UPDATE ADV DOCS',
    'UPDATE BAL DOCS'

  ];
  paymentTasksROH = [
    'APPROVE ADVANCE',
    'APPROVE BALANCE',
  ];
  paymentTasksCO = [
    'UPDATE ADV DOCS',
    'UPDATE BAL DOCS'
  ];
  paymentTasksFIN = [
    'APPROVE ADV FIN',
    'APPROVE BAL FIN',
    'MARK ADVANCE PAID',
    'MARK BALANCE PAID',
  ];

  lRTasks = [
    'COMPLETE LR DOCS',
    'UPLOAD ePOD',
    'MARK RECEIVED POD',
    'REVIEW POD',
    'GENERATE BILL',
  ];
  billingTasks = [
    'REVIEW BILL',
    'SUBMIT BILL',
    'COLLECT',
    'MARK PAYMENT RECEIVED',
    'MARK MR COMPLETED'
  ];
  recountTasks = true;
  showIndividualTasks = true;
  isLogInAs = false;
  employee_id: number;
  user: Users;
  cNoteBill: any;
  enableLineAdd: boolean;
  locationLogData: any;
  previouseRemarks: any;
  ListExpandFlag: boolean;
  displayedListColumns: string[] = ['position', 'name', 'action'];
  vendorPaymentList = [];
  priorityTasksCount: number;
  todaysTasksCount: number;
  pageName: any = '';
  isMultiSelectRows: boolean;
  constructor(
    _formBuilder: FormBuilder,
    mapsAPILoader: MapsAPILoader,
    ngZone: NgZone,
    router: Router,
    route: ActivatedRoute,
    modalService: BsModalService,
    _snackBarService: MatSnackBar,
    _dialogService: DialogService,
    tripsService: TripsService,
    thcService: TruckHireChallansService,
    bookingsService: BookingsService,
    userService: UsersService,
    truckService: TrucksService,
    partnerService: PartnersService,
    partnerContactService: PartnerContactsService,
    driverService: DriversService,
    fileTransferService: FileTransferService,
    consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    driverRatingsService: DriverRatingsService,
    datepipe: DatePipe,
    bidsService: BidsService,
    authenticatorService: AuthenticatorService,
    sanitizer: DomSanitizer,
    consignmentNotesService: ConsignmentNotesService,
    protected tasksService: TasksService,
    protected invoiceService: InvoicesService,
    protected notificationsService: NotificationsService,
    dialog: MatDialog
  ) {

    super(
      _formBuilder, mapsAPILoader, ngZone,
      router, route, modalService,
      _snackBarService,
      _dialogService,
      tripsService,
      thcService,
      bookingsService,
      userService,
      truckService,
      partnerService,
      partnerContactService,
      driverService,
      fileTransferService,
      consignmentTripTrackingsService,
      driverRatingsService,
      datepipe,
      bidsService,
      authenticatorService,
      sanitizer,
      consignmentNotesService,
      dialog
    );

    this.locationLogData = [
      { place: 'Pune', date: '13 jun, 12:55 PM' },
      { place: 'Mumbai', date: '13 jun, 12:55 PM' },
      { place: 'Delhi', date: '13 jun, 12:55 PM' },
      { place: 'Hariyana', date: '13 jun, 12:55 PM' },
      { place: 'Aasam', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Nagpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' },
      { place: 'Kanpur', date: '13 jun, 12:55 PM' }

    ]
    this.previouseRemarks = [
      { recievedDate: 'Will recieve by 15 june', date: '13 jun, 12:55 PM' },
      { recievedDate: 'Will recieve by 13 june', date: '11 jun, 12:55 PM' },
      { recievedDate: 'Will recieve by 11 june', date: '8 jun, 12:55 PM' },
      { recievedDate: 'Will recieve by 18 june', date: '4 jun, 12:55 PM' }
    ]
    // this.route.paramMap.subscribe(params => {
    //   //fetch your new parameters here, on which you are switching the routes and call ngOnInit()
    //   this.ngOnInit();
    //  });
    this.ListExpandFlag = false;
    //TODO: this is resetting the recountTasks
    // this.onDispatchActivityChange({index:this.selectedDispachIndex, tab:{textLabel:'Consignment'}});
  }

  ngOnInit() {
    this.deferGetResponse = true;
    this.disableStatus = false;
    this.tasksFormGroup = this._formBuilder.group(tasksFormControls);
    // this.authenticatorService.selectedMenuName.subscribe((res:any)=>{
    //  console.log("res.name : ",res);
    //   this.pageName = res.name;
    //   if(this.pageName==undefined){
    //     this.pageName = '';
    //   }
    // });

    this.user = StorageService.getUser();
 //  console.log("User : ", this.user);

    if (this.router.url.indexOf('/work_area_cd') > -1) {
   //  console.log('url contains workareacd ');
      this.showIndividualTasks = false;
    }
    this.notificationsService.notificationsListChange.subscribe(value => {
   //  console.log(this.notificationsService.notificationsList);
    }
    );

    super.ngOnInit();
 //  console.log(this.tripsSearchForm.value);
 //  console.log('previsous search subscription');
 //  console.log(this.searchSubscription)
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.searchSubscription = appGlobals.observeChanges(
      this,
      this.tripsSearchForm.controls.search,
      this.tasksList,
      'getTasksSearchService',
      'setTasksList'
    );

    this.route.params.subscribe(params => {
   //  console.log(params);
      this.disableStatus = false;
      this.pageName = params.work_area_cd;
      if (params.bookingId) {
        this.bookings_id = +params.bookingId;
      }
      if (params.work_area_cd) {
     //  console.log('plus params called for workarea cd ' + params.work_area_cd);
        this.showIndividualTasks = false;
        this.tripsSearchForm.patchValue({ work_area_cd: [params.work_area_cd] });
      }
      else if (params.employee) {
        if (params.employee == 'me') {
          this.employee_id = this.user.id;
        }
        else {
          this.employee_id = params.employee;
        }
     //  console.log('plus params called for employee id' + this.employee_id);
        if (this.employee_id != this.user.id) {
          this.isLogInAs = true;
        }
        if (this.showIndividualTasks) {
          let employee_name = this.user.name;
          if (this.employee_id != this.user.id) {
            employee_name = 'User set to ' + this.employee_id;
          }
          else {

          }
          this.setExecutiveIdName(this.employee_id, employee_name);
          this.tripsSearchForm.get('territory_cd').reset();
        }
      }
      if (this.user.territory_cd && this.user.territory_cd !== 'PAN INDIA') {
     //  console.log('snapshop territory_cd ' + this.route.snapshot.queryParams['territory_cd']);
     //  console.log(this.isLogInAs);
        if (!this.route.snapshot.queryParams['territory_cd'] && !this.isLogInAs) {
          this.setterritoryCd([this.user.territory_cd]);
        }

      }
   //  console.log('first set of observe params');
   //  console.log(params);
   //  console.log('calling setActivityIndexByRole2');
      this.setActivityIndexByRole();

   //  console.log('calling setTabsAndContent1');
      this.setTabsAndContent();
    });
 //  console.log(this.selectedActivityIndex);

    // this.taskSelection = new SelectionModel<Tasks>(true, [this.tasksDataSource.data[0]]);
  }

  ngOnChanges() {
    this.pageName = localStorage.getItem('selectedMenu');
 //  console.log(" this.pageName : ", this.pageName);
  }

  toggleModel() {
    this.isOpen = !this.isOpen
  }
  getTasksSearchService(searchTerm) {
    return this.tasksService.search(this.mergeWithSearch());
  }
  setTasksList(aData) {
    this.getResponseTasksCounts();
    this.tasksList = Tasks.toArray(aData.data);
    // data = this.tripsList;
    this.tasksDataSource = new TasksDataSource(this.tasksList);
    this.total = aData.total;
    this.per_page = aData.per_page;
    this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
    this.doPostResponse(aData);
  }
  getIndentsResponse(pageNo = 1) {
    this.currentPage = pageNo;
    // this.observeFieldChanges();
 //  console.log('calling internal comp get response');
    // console.trace();
    // alert("caller is " + this.getResponse.caller.toString());
 //  console.log(this.tripsSearchForm.value);
    // refresh counts anyways.. or should
    if (this.recountTasks) {
      this.getResponseTasksCounts();
    }
    else {
      // toggle recount for future
      this.recountTasks = true;
    }

    return this.tasksService.getIndentsList(this.mergeWithSearch()).subscribe(
      response => {
        // console.log(response);
        this.tasksList = Tasks.toArray(response.data);
        //data = this.tripsList;
     //  console.log(this.tasksList);
        this.tasksDataSource = new TasksDataSource(this.tasksList);
        this.total = response.total;
        this.per_page = response.per_page;
        this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
        this.doPostResponse(response);
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  getResponse(pageNo = 1) {
    this.currentPage = pageNo;
    // this.observeFieldChanges();
 //  console.log('calling internal comp get response');
    // console.trace();
    // alert("caller is " + this.getResponse.caller.toString());
 //  console.log(this.tripsSearchForm.value);
    // refresh counts anyways.. or should
    if (this.recountTasks) {
      this.getResponseTasksCounts();
    }
    else {
      // toggle recount for future
      this.recountTasks = true;
    }

    return this.tasksService.getList(this.mergeWithSearch()).subscribe(
      response => {
        // console.log(response);
        this.tasksList = Tasks.toArray(response.data);
        //data = this.tripsList;
     //  console.log(this.tasksList);
        this.tasksDataSource = new TasksDataSource(this.tasksList);
        this.total = response.total;
        this.per_page = response.per_page;
        this.currentPage = response.current_page;
        this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
        this.doPostResponse(response);
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  doPostResponse(response) {
 //  console.log('doing post response');
 //  console.log(this.modalService.getModalsCount());
    if (this.usePreviewPanel && this.isDesktop()) {

      this.rowOnClick(this.tasksDataSource.data[0]);
    }
 //  console.log(this.modalService.getModalsCount());

  }
  isAllSelected() {
    // console.log('is all selected');
    const numSelected = this.taskSelection.selected.length;
    const numRows = this.tasksDataSource.data.length;
    return numSelected === numRows;
  }
  isRowActive(row){
    return ((this.task && this.task.id && row.id == this.task.id) || this.selection.isSelected(row))
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
 //  console.log('master toggle');
    this.isAllSelected() ?
      this.taskSelection.clear() :
      this.tasksDataSource.data.forEach(row => this.taskSelection.select(row));
  }
  getTruckType(element) {
    let truckType = this.getTruckTypeValue(element.truck_type_cd);
    if (truckType) {
      return truckType.substring(0, 20);
    }
    return '';
  }
  getNextStatus(element) {
    if (element.trips_status_cd === 'TRIP COMPLETE') {
      return '';
    }
    const nextStatus = Trips.nextStatus(
      element.trips_status_cd,
      element.business_type_cd,
      element.movement_type_cd
    );

    if (nextStatus) {
      return nextStatus.toLowerCase();
    }
    return '';
  }
  /*
    Change the task button text based on Task name.
  */
  getNextActionButtonText(element) {
    if (element.name == 'MARK RECEIVED POD') {
      return 'UPDATE POD STATUS';
    }
    if (element.name == 'CONFIRM REACHED LOADING') {
      return 'REACHED LOADING';
    }
    if (element.name == 'TRACK') {
      return 'UPDATE LOCATION';
    }
    if (element.name == 'CONFIRM POD') {
      return 'MARK POD GENERATED';
    }
    if (element.name == 'CONFIRM UNLOADING START') {
      return 'START UNLOADING';
    }
    if (element.name == 'CONFIRM UNLOADING END') {
      return 'COMPLETE UNLOADING';
    }
    if (element.name == 'CONFIRM LOADING START') {
      return 'START LOADING';
    }
    if (element.name == 'CONFIRM LOADING END') {
      return 'END LOADING';
    }
    if (element.name == 'ON THE WAY TO LOADING POINT') {
      return 'START FOR LOADING PT.';
    }
    if (element.name == 'COMPLETE LR DOCS') {
      // return 'DISPATCH';
    }
    if (element.name == 'REQUEST ADVANCE' || element.name == 'REQUEST BALANCE') {
      return "Request";
      // return element.name; // 'REQUEST';
    }
    if (element.name == 'MARK ADVANCE PAID' || element.name == 'MARK BALANCE PAID') {
      return "Mark Paid";
      // return element.name; // 'REQUEST';
    }
    if (element.name == 'APPROVE ADVANCE'
      || element.name == 'APPROVE BALANCE'
      || element.name == 'APPROVE ADVANCE FIN'
      || element.name == 'APPROVE BALANCE FIN'
    ) {
      return "Approve";
      // return element.name; // 'REQUEST';
    }

    return element.name;
  }
  setInitialFilter() {

 //  console.log('child setInitialFilter');
    if (!this.bookings_id && !this.partners_id && !this.drivers_id) {
   //  console.log('setInitialFilter');
      const user = StorageService.getUser();
      if (
        !StorageService.userIsManagement()
        // && !StorageService.userIsROH()
        // StorageService.userIsLE() ||
        // StorageService.userIsTE() ||
        // StorageService.userIsBE()
        // || StorageService.userIsCT()
      ) {
        if (this.showIndividualTasks) {
          let employee_name = user.name;
          if (!this.employee_id) {
            this.employee_id = user.id;
          }
          if (this.employee_id != user.id) {
            employee_name = 'User set to ' + this.employee_id;
          }
          this.setExecutiveIdName(this.employee_id, employee_name);
        }
      }
      if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
        if ((this.tripsSearchForm.get('territory_cd').value != '')) {
       //  console.log('setting territory_cd from user');
          this.setterritoryCd([user.territory_cd]);
        }
      }
      // if (user.work_area_cd && !this.showIndividualTasks) {
      //   if(this.tripsSearchForm.get('work_area_cd').value != '') {
      //  console.log('setting workarea from user');
      //     this.setWorkAreaCd([user.work_area_cd] );
      //   }
      //   // this.tripsSearchForm.patchValue({work_area_cd: user.work_area_cd});
      // }

    }
 //  console.log(this.tripsSearchForm.value);

  }
  ngAfterViewInit() {
    if (this.tasksDataSource && this.tasksDataSource.data) {
   //  console.log('afterViewInit => ', this.tasksDataSource.data ? this.tasksDataSource.data.length : 0);

    }

  }
  setExecutiveIdName(employeeId, employeeName) {
    this.tripsSearchForm.patchValue({ executive_id: employeeId });
    this.tripsSearchForm.patchValue({ executive_name: employeeName });
    this.searchExecutiveId = employeeId;
    this.searchExecutiveName = employeeName;
  }
  currentAction: any;
  rowOnClick(row) {
    if (row && row.id) {
   //  console.log('on row click ' + row.id);
   //  console.log(row);
      row.active = true;

      //this.selection.toggle(row);
      //this.selection.isSelected(row);
      // if(this.selection.isSelected(row)) {
      return this.getNextActionButtonClickFirst(row);
      // }
    }
    else {
      this.currentTemplate = null;
    }
    // return this.selection.toggle(row);
  }
  getNextActionButtonClickFirst(element) {
 //  console.log('clicked next action');
    return this.getNextActionButtonClick(element, element.entity_id, element.entity_type);
  }
  onClickAction(element) {
    this.ListExpandFlag = false;
    this.vendorPaymentList = [];
    this.getNextActionButtonClick(element, element.entity_id, element.entity_type);
  }

  isDestinationStatus(status_cd) {
    if (status_cd == 'REACHED LOADING POINT'
      || status_cd == 'REACHED DESTINATION') {
      return true;
    }
    return false;
  }
  isUnloadingCompleteStatus(status_cd) {
    if (status_cd == 'UNLOADING COMPLETE' || status_cd == 'DESTUFFING COMPLETE') {
      return true;
    }
    return false;
  }
  isLoadingCompleteStatus(status_cd) {
    if (status_cd == 'LOADING COMPLETE' || status_cd == 'STUFFING COMPLETE') {
      return true;
    }
    return false;
  }
  getNextActionButtonClick(element, entityId, entityType = 'Trip') {

    this.task = this.tasks = element;
    // console.log(element);
    if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
   //  console.log('clicked....... if');
      this.modalRef = this.openModalGeneric(this.viewTask);
      // return;
    }
    else if (entityType == 'Trip') {
   //  console.log('clicked....... else');
      return this.tripsService.getTripsWithLocationById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.trips = new Trips(response.data);
          this.next_status = this.getNextStatus(this.trips);
          this.next_plus_status = this.getNextPlusStatus(this.trips);
          this.last_status = this.getLastStatus(this.trips);
       //  console.log(this.trips);
          this.openModalByAction(this.trips,
            element.name, entityType, entityId);

          switch (entityType) {
            case 'Trip': {

              break;
            }
            default: {

            }
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else if (entityType == 'Truck Hire Challan') {
      return this.thcService.getTruckHireChallansById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.truckHireChallan = new TruckHireChallans(response.data);
          this.trips = this.truckHireChallan.trip;
          this.openModalByAction(this.truckHireChallan,
            element.name, entityType, entityId);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else if (entityType == 'Consignment Note') {
      return this.consignmentNotesService.getConsignmentNotesById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.cNote = new ConsignmentNotes(response.data);
          // console.log(this.cNote);
          if ((<any>this.cNote).consignmentTrips && (<any>this.cNote).consignmentTrips[0]
            && (<any>this.cNote).consignmentTrips[0].trip
            && (<any>this.cNote).consignmentTrips[0].trip.truckHireChallan) {
            this.truckHireChallan = (<any>this.cNote).consignmentTrips[0].trip.truckHireChallan;
          }
          this.openModalByAction(this.cNote,
            element.name, entityType, entityId);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else if (entityType == 'Invoice') {
      return this.invoiceService.getEntityById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.invoice = new Invoices(response.data);
       //  console.log(this.invoice);

          this.openModalByAction(this.invoice,
            element.name, entityType, entityId);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    else if (entityType == 'Booking') {
      // if(!entityId) {
      //   return this.openModalByAction(new Bookings(),
      //     element.name, entityType, entityId);
      // }
      return this.bookingsService.getBookingsById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.bookings = new Bookings(response.data);
          this.trips = null;
          this.openModalByAction(this.bookings,
            element.name, entityType, entityId);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }


  }
  getStatusFromAction(tripData: Trips, entityAction?) {
 //  console.log('entity action is ' + entityAction);
    switch (entityAction) {
      case 'CONFIRM': {
        return 'TRIP CONFIRMED';
        break;
      }
      case 'ASSIGN VEHICLE': {
        return 'ASSIGN VEHICLED';
        break;
      }
      case 'START FOR LOADING PT.': {
        return 'ON THE WAY TO LOADING POINT';
        break;
      }
      case 'CONFIRM REACHED LOADING': {
        return 'REACHED LOADING POINT';
        break;
      }
        if (tripData.movement_type_cd == 'EXPORT') {

        }
      case 'CONFIRM REACHED EMPTY YARD': {
        return 'REACHED EMPTY YARD';
        break;
      }
      case 'CONFIRM CONTAINER PICKED': {
        return 'CONTAINER PICKED';
        break;
      }
      case 'CONFIRM LOADING START': {
        return 'LOADING STARTED';
        break;
      }
      case 'CONFIRM LOADING END': {
        return 'LOADING COMPLETE';
        break;
      }
      case 'CONFIRM PAPER WORK DONE': {
        return 'PAPER WORK DONE';
        break;
      }
      case 'SEND CONSENT REQUEST': {
        return 'CONSENT REQUESTED';
        break;
      }
      case 'CONFIRM IN TRANSIT': {
        return 'IN TRANSIT';
        break;
      }
      case 'TRACK': {
        return 'IN TRANSIT';
        break;
      }
      case 'CONFIRM REACHED DESTINATION': {
        return 'REACHED DESTINATION';
        break;
      }
      case 'MARK REACHED DESTINATION': {
        return 'REACHED DESTINATION';
        break;
      }
      case 'CONFIRM UNLOADING START': {
        return 'UNLOADING STARTED';
        break;
      }
      case 'CONFIRM UNLOADING END': {
        return 'UNLOADING COMPLETE';
        break;
      }
      case 'CONFIRM POD': {
        return 'POD GENERATED';
        break;
      }
      case 'CONFIRM REACHED EMPTY YARD': {
        return 'REACHED EMPTY YARD';
        break;
      }
      case 'DROP CONTAINER': {
        return 'CONTAINER DROPPED';
        break;
      }
      case 'COMPLETE TRIP': {
        return 'TRIP COMPLETED';
        break;
      }
      default: {
        return this.getNextStatus(tripData);
      }
    }
  }
  openModalUpdateTripProgress(aTemplate: TemplateRef<any>, tripData: Trips, forceModal = false){
    this.bookingsFormGroup = this._formBuilder.group(bookingsFormControls);
    return this.openModalUpdateStatus(aTemplate, tripData, 'REMARKS', null, forceModal);
  }
  openModalUpdateTripLocation(aTemplate: TemplateRef<any>, tripData: Trips, forceModal = false){
    return this.openModalUpdateStatus(aTemplate, tripData, 'LOCATION', null, forceModal);
  }
  openModalUpdateTripStatus(aTemplate, tripData: Trips, entityAction?, forceModal  = false): any {
      const next_status_cd = this.getStatusFromAction(tripData, entityAction);
      return this.openModalUpdateStatus(aTemplate, tripData, 'STATUS', next_status_cd, forceModal);
  }
  openModalByAction(element, entityAction, entityType, entityId) {

 //  console.log(element.trips_status_cd + ' | ' + entityAction + ' | ' + entityType + ' | ' + entityId);

    if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
      this.modalRef = this.openModalGeneric(this.viewTask);
      return;
    }
    switch (entityAction) {
      case 'CONFIRM': {
        return this.openModalConfirmTrip(this.confirmTrip, element);
        break;
      }
      case 'ASSIGN VEHICLE': {
        return this.openModalAssignTruckNew(this.assignTruck, element, false);
        break;
      }
      case 'ATTACH DOCUMENTS': {
        return this.openModalTruckDocumentThc(this.truckDocuments, element);
        break;
      }
      case 'REQUEST ADVANCE': {
        return this.openModalRequestAdvance(this.requestAdvance, element);
        break;
      }
      case 'APPROVE ADVANCE': {
        return this.openModalApproveAdvanceRequest(this.approveAdvance, element);
        break;
      }
      case 'UPDATE ADV DOCS': {
        return this.openModalAdvanceCompDocsLink(this.advanceCompDocs, element);
        break;
      }
      case 'APPROVE ADV FIN': {
        return this.openModalAdvanceFinApproveLink(this.advanceFinApprove, element);
        break;
      }
      case 'MARK ADVANCE PAID': {
        return this.openModalAdvancePaid(this.advancePaid, element);
        break;
      }
      case 'REQUEST BALANCE': {
        return this.openModalRequestBalance(this.requestBalance, element);
        break;
      }
      case 'APPROVE BALANCE': {
        return this.openModalApproveBalanceRequest(this.approveBalance, element);
        break;
      }
      case 'UPDATE BAL DOCS': {
        return this.openModalBalanceCompDocsLink(this.balanceCompDocs, element);
        break;
      }
      case 'APPROVE BAL FIN': {
        return this.openModalBalanceFinApproveLink(this.balanceFinApprove, element);
        break;
      }
      case 'MARK BALANCE PAID': {
        return this.openModalBalancePaid(this.balancePaid, element);
        break;
      }
      case 'MARK RECEIVED POD': {

      }

      case 'UPLOAD ePOD': {
        return this.openModalUploadEPod(this.deliverPopup, element);
        break;
      }
      case 'REVIEW POD': {
        return this.openModalUploadEPod(this.podReviewPopup, element);
        break;
      }
      case 'GENERATE BILL': {
        return this.openModalGenerateBill(this.billPopup, element);
        break;
      }
      case 'REVIEW BILL': {
        return this.openModalReviewBill(this.reviewBillPopup, element);
        break;
      }
      case 'SUBMIT BILL': {
        return this.openModalSubmitBill(this.submittedPopup, element);
        break;
      }
      case 'CLOSE': {
        return this.openModalUpdateBookingStatus(this.updateBookingStatus, element);
        break;
      }
      // case 'CONFIRM BOOKING': {
      //   return this.openModalUpdateBookingStatus(this.updateBookingStatus, element);
      //   break;
      // }
      case 'CANCEL BOOKING': {
        return this.openModalUpdateBookingStatus(this.updateBookingStatus, element, true);
        break;
      }
      case 'MARK IN PROGRESS': {
        return this.openModalUpdateBookingStatus(this.updateBookingStatus, element);
        break;
      }
      case 'COMPLETE BOOKING': {
        return this.openModalUpdateBookingStatus(this.updateBookingStatus, element);
        break;
      }
      case 'CONFIRM BOOKING': {

      }
      case 'CREATE BOOKING': {
        return this.openModalCreateBooking(this.createBookingDet, element);
        break;
      }
      // case 'CONFIRM LOADING END': {
      //   // return this.openModalTruckDocument(this.truckDocuments, element);
      //   // break;
      // }
      case 'COLLECT': {
        return this.openModalMarkPaymentReceived(this.paymentPopup, element);
        break;
      }
      case 'MARK PAYMENT RECEIVED': {
        return this.openModalMarkPaymentReceived(this.paymentPopup, element);
        break;
      }
      case 'MARK MR COMPLETED': {
        return this.openModalMarkMrComplete(this.paymentPopup, element);
        break;
      }
      case 'COMPLETE LR DOCS': {
     //  console.log(element);
        return this.modalRef = this.openModalConsignment(this.updateConsignment, element.id);
        break;
      }
      // case 'MARK REACHED LOADING POINT': {
      // }
      // case 'CONFIRM LOADING START': {
      // }
      // case 'CONFIRM LOADING END': {
      // }
      // case 'MARK IN TRANSIT': {
      // }
      //  case 'CONFIRM PAPER WORK DONE': {
      case 'DISPATCH': {
     //  console.log(element);
        // return this.modalRef = this.openModalConsignmentTrip(this.updateConsignmentTrip, element);
        return this.modalRef = this.openModalConsignmentTrip(this.dispatchLocal, element);

        //  return this.modalRef = this.openModalGeneric(this.updateConsignment, element);
        break;
      }
      case 'TRACK': {
     //  console.log(element);
        // return this.modalRef = this.openModalConsignmentTrip(this.updateConsignmentTrip, element);
        return this.modalRef = this.openModalUpdateLocation(this.updateLocation, element);
        //  return this.modalRef = this.openModalGeneric(this.updateConsignment, element);
        break;
      }

      default: {
        return this.openModalUpdateTripStatus(this.updateStatus, element, entityAction, false);
      }
    }

  }

  openModalCreateBooking(template: TemplateRef<any>, aData) {
    this.initalizeBookingsFormGroup();
    // this.bookings = new Bookings(aData);
    // this.bookingsFormGroup.patchValue(this.bookings);
 //  console.log('booking initialized');
    this.openModalGeneric(template, aData);
    // this.openModalGeneric(template, aData);
  }
  openModalMarkMrComplete(template: TemplateRef<any>, aData) {
    this.invoice = new Invoices(aData);
    this.invoicesFormGroup.reset();
    this.invoicesFormGroup.patchValue(aData);
    this.invoicesFormGroup.controls[
      'webxpress_payment_code'
    ].setValidators(Validators.required);

    if (this.invoice.invoice_status_cd == 'PAYMENT RECEIVED') {
      this.invoicesFormGroup.patchValue({
        invoice_status_cd: 'MR COMPLETE'
      });
    }
    // console.log(this.invoicesFormGroup);
    this.openModalGeneric(template, aData);
  }
  openModalReviewBill(template: TemplateRef<any>, aData) {
    this.invoice = new Invoices(aData);
    this.invoicesFormGroup.reset();
    this.invoicesFormGroup.patchValue(aData);
    if (this.invoice.invoice_status_cd == 'BILL GENERATED') {
      this.invoicesFormGroup.patchValue({
        invoice_status_cd: 'REVIEWED'
      });
    }

    // console.log(this.invoicesFormGroup);
    this.openModalGeneric(template, aData);
  }
  openModalSubmitBill(template: TemplateRef<any>, aData) {
    this.invoice = new Invoices(aData);
    this.invoicesFormGroup.reset();
    this.invoicesFormGroup.patchValue(this.invoice);
    const paymentDays = this.invoice.customer.credit_period_days;
    const currentDate = new Date();
    const paymentDueDate = new Date();
    paymentDueDate.setDate(currentDate.getDate() + paymentDays);
    // console.log();
    // this.invoicesFormGroup.patchValue({});
    // this.invoicesFormGroup.patchValue({ invoice_dt: currentDate });
    this.invoicesFormGroup.patchValue({ submitted_dt: currentDate });
    this.invoicesFormGroup.patchValue({ payment_due_dt: paymentDueDate });
    if (this.invoice.invoice_status_cd == 'BILL GENERATED' || this.invoice.invoice_status_cd == 'REVIEWED') {
      this.invoicesFormGroup.patchValue({
        invoice_status_cd: 'BILL SUBMITTED'
      });
    }

 //  console.log(this.invoicesFormGroup);
    this.openModalGeneric(template, aData);
  }
  openModalMarkPaymentReceived(template: TemplateRef<any>, aData) {
    this.invoice = new Invoices(aData);
    // this.bookings = this.cNote.bookings;
    this.invoicesFormGroup.reset();
    this.invoicesFormGroup.patchValue(this.invoice);

    this.invoicesFormGroup.patchValue({
      invoice_status_cd: 'PAYMENT RECEIVED'
    });

    this.openModalGeneric(template, aData);

  }

  getAllInvoiceStatuses() {
    return Invoices.allStatuses;
  }
  openModalGenerateBill(template: TemplateRef<any>, aData) {

    this.cNote = new ConsignmentNotes(aData);
    this.cNoteBill = new ConsignmentNotes(aData);
    // if (this.cNote.consignment_trips && this.cNote.consignment_trips[0]) {
    //   this.trips = this.cNote.consignment_trips[0].trip;
    // }
    // this.invoice = new Invoices();
    // this.consignmentNotesList = [];
    // this.setFormForBilling();
    this.openModalGeneric(template, aData);
 //  console.log('billmodalshow');
    // this.observeWebXpressBill();

  }
  doOnEntitySaveComplete(aData?: any) {
 //  console.log('doOnEntitySaveComplete called ');
 //  console.log(aData);
    this._snackBarService.open('Bill saved', 'X', {
      duration: appGlobals.SNACKBAR_TIMEOUT
    });
    this.closeModalGeneric();
    this.getResponse(this.currentPage);

  }
  observeWebXpressBill() {
    appGlobals.observeChanges(
      this,
      this.invoicesFormGroup.controls.webxpress_invoice_code,
      'invoiceList',
      'getInvoiceSearchService',
      'setInvoiceList',
      'webxpress_invoice_code', '', 'invoiceCheck'
    );
    appGlobals.observeChanges(
      this,
      this.invoicesFormGroup.controls.consignment_no_search,
      'consignmentNotesList',
      'getConsignmentSearchService',
      'setConsignmentList'
    );
  }
  getConsignmentSearchService(searchTerm) {
 //  console.log('consignment search service ' + searchTerm);
    return this.consignmentNotesService.lookupConsignmentNo(searchTerm);
  }
  setConsignmentList(aData) {
 //  console.log(aData);
    if (aData.data) {
      this.consignmentNotesList = ConsignmentNotes.toArray(aData.data);
    }
    // this.consignmentNotesList = [];
  }
  setConsignmentForBilling(aData) {
 //  console.log(aData);
    this.cNoteBill = new ConsignmentNotes(aData);
 //  console.log(this.cNoteBill);
    this.invoicesFormGroup.patchValue({ consignment_no_search: aData.consignment_no }, { emitEvent: false });
    this.invoicesFormGroup.patchValue({ invoice_line_amt: aData.total_amt }, { emitEvent: false });
    this.enableLineAdd = true;
  }
  invoiceCheck(aInvoiceNo) {
 //  console.log(aInvoiceNo);
    if (aInvoiceNo && aInvoiceNo != null && aInvoiceNo.length > 14) {
      return true;
    }
    return false;
  }
  getInvoiceSearchService(searchTerm) {
 //  console.log('invoice search service ' + searchTerm);
    return this.invoiceService.lookupEntity({ 'webxpress_invoice_code': searchTerm });
  }
  setInvoiceList(aData) {
 //  console.log(aData);
    if (aData.data) {
      this.invoiceList = [new Invoices(aData.data)];
      // if (this.invoiceList[0]) {
      //   const invoice = this.invoiceList[0];
      //   this.setInvoice(invoice);
      // }
      // else {
      //   this.setFormForBilling(false);
      // }
    }
    else {
      this.setFormForBilling(false);
    }
  }
  setInvoice(invoice) {
 //  console.log(invoice);
    this.invoice = new Invoices(invoice);
    this.invoicesFormGroup.reset();
    this.invoicesFormGroup.patchValue(invoice);
    // add current line for billing
    this.setConsignmentForBilling(this.cNote);
    // this.addConsignmentForBilling(this.cNoteBill);
    // this.invoicesFormGroup.patchValue({ id: invoice['id'] });
    // this.invoicesFormGroup.patchValue({ webxpress_invoice_code: invoice['webxpress_invoice_code'] });
    // this.invoicesFormGroup.patchValue({ invoice_ref: invoice['invoice_ref'] });
    // this.invoicesFormGroup.patchValue({ invoice_status_cd: invoice['invoice_status_cd'] });
    // this.invoicesFormGroup.patchValue({ invoice_total_amt: invoice['invoice_total_amt'] });
    // this.invoicesFormGroup.patchValue({ invoice_dt: invoice['invoice_dt'] });
    // this.invoicesFormGroup.patchValue({ invoice_remarks: invoice['invoice_remarks'] });
    // this.invoicesFormGroup.patchValue({ invoice_scan: invoice['invoice_scan'] });
    // this.invoicesFormGroup.patchValue({
    //   invoice_total_amt: invoiceLine.freight_amt
    // });
  }
  submitStatus(aData) {
    if (!this.consignmentNotesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup);
      return;
    }
    else if (aData.consignment_status_cd === 'IN TRANSIT'
      || aData.consignment_status_cd === 'AT DELIVERY LOCATION') {
      this.cNoteFormGroup.patchValue(this.cNote);
      if (!this.cNoteFormGroup.valid) {
        this.isError = true;
        this.errorMessages = appGlobals.getFormValidationErrors(
          this.cNoteFormGroup
        );
        appGlobals.markFormGroupTouched(this.cNoteFormGroup);
        this._dialogService.openAlert({ title: 'Error', message: 'Please edit the consignment to enter mandatory values.' });
        return;
      }
    }
    if (aData) {
      // this.cNote.fill(data);
      aData.status_at = new Date((aData.status_at.toDateString() + ' ' + aData.status_at_time).replace(/-/g, "/"));

   //  console.log(aData);
      this.consignmentNotesService.updateStatus(aData).subscribe(
        response => {
          this.closeModalGeneric();
          this.getResponse(this.currentPage);
          this._snackBarService.open('Status updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  createInvoiceLineFromConsignment(aData) {
    let aInvoiceLine = new InvoiceLines();
    aInvoiceLine.invoice_line_amt = aData.total_amt;
    aInvoiceLine.freight_amt = aData.freight_amt;
    aInvoiceLine.loading_charges = aData.loading_charges;
    aInvoiceLine.unloading_charges = aData.unloading_charges;
    aInvoiceLine.detention_charges = aData.detention_charges;
    aInvoiceLine.detention_days = aData.detention_days;
    //aInvoiceLine.invoice_total_amt = this.cNote.freight_amt;
    aInvoiceLine.misc_charges_amt = aData.misc_charges_amt;
    aInvoiceLine.consignment_note = aData;
    return aInvoiceLine;
  }
  addConsignmentForBilling(aData) {
 //  console.log(aData);
    if (this.cNoteBill != null) {
      const aInvoiceLine = this.createInvoiceLineFromConsignment(aData);
      aInvoiceLine.invoice_line_amt = this.invoicesFormGroup.get('invoice_line_amt').value;
      if (!this.invoice.invoice_lines) {
        this.invoice.invoice_lines = [];
      }
      aInvoiceLine.invoices_id = this.invoice.id;
      this.invoice.invoice_lines.push(aInvoiceLine);
      this.invoicesFormGroup.get('invoice_line_amt').reset();
      this.invoicesFormGroup.get('consignment_no_search').reset();
      this.enableLineAdd = false;
      this.cNoteBill = null;
    }
    else {
      alert('Please select consignment to add');
    }

 //  console.log(this.invoice);
  }
  setFormForBilling(resetAll = true) {
    let aInvoiceLine = new InvoiceLines();

    if (resetAll) {
      this.invoicesFormGroup.reset();
    }
    if (this.invoice && this.invoice.id) {
      this.invoicesFormGroup.patchValue(this.invoice);
    }
    else {
      const currentDate = new Date();
      this.invoicesFormGroup.patchValue({ invoice_dt: currentDate });
      this.invoicesFormGroup.patchValue({
        invoice_status_cd: 'BILL GENERATED'
      });
    }

    if (this.cNoteBill) {
      this.invoicesFormGroup.patchValue({
        customers_id: this.cNoteBill.customers_id
      });
      this.invoicesFormGroup.patchValue({
        consignment_notes_id: this.cNoteBill.id
      });

      if (this.cNoteBill.invoice_lines && this.cNoteBill.invoice_lines[0]) {
        aInvoiceLine = this.cNoteBill.invoice_lines[0];
        this.invoice = aInvoiceLine.invoice;
        if (!this.invoice.invoice_lines) {
          this.invoice.invoice_lines = [];
        }
        this.invoice.invoice_lines.push(aInvoiceLine);
        this.invoicesFormGroup.patchValue(this.invoice);

     //  console.log(aInvoiceLine);
      } else {
        aInvoiceLine = this.createInvoiceLineFromConsignment(this.cNoteBill);
        aInvoiceLine.consignment_note = this.cNoteBill;
        this.invoice = new Invoices();
        if (!this.invoice.invoice_lines) {
          this.invoice.invoice_lines = [];
        }
        this.invoice.invoice_lines.push(aInvoiceLine);
      }
      // set form from invoice line
      this.invoicesFormGroup.patchValue({
        invoice_line_amt: aInvoiceLine.invoice_line_amt
      });
      this.invoicesFormGroup.patchValue({
        consignment_notes_id: aInvoiceLine.consignment_notes_id
      });
      this.invoicesFormGroup.patchValue({
        freight_amt: aInvoiceLine.freight_amt
      });
      this.invoicesFormGroup.patchValue({
        loading_charges: aInvoiceLine.loading_charges
      });
      this.invoicesFormGroup.patchValue({
        unloading_charges: aInvoiceLine.unloading_charges
      });
      this.invoicesFormGroup.patchValue({
        detention_charges: aInvoiceLine.detention_charges
      });
      this.invoicesFormGroup.patchValue({
        detention_days: aInvoiceLine.detention_days
      });
      this.invoicesFormGroup.patchValue({
        misc_charges_amt: aInvoiceLine.misc_charges_amt
      });
    }
  }
  openModalUpdateBookingStatus(template, aData, isCancel = false) {
    this.initalizeBookingsFormGroup();
    this.bookings = new Bookings(aData);
    this.bookingsFormGroup.patchValue(this.bookings);
    if (isCancel) {
      this.bookingsFormGroup.patchValue({ booking_status_cd: 'CANCELLED' });
    }
    this.openModalGeneric(template, aData);
  }
  openModalUploadEPod(template, aData, statusCd = '') {
    this.consignmentPodFormGroup.reset();
    if (aData) {
      this.trips = new Trips();
      this.cNote = new ConsignmentNotes(aData);
   //  console.log(aData);
      if (this.cNote.consignment_trips
        && this.cNote.consignment_trips[0]
      ) {
        this.trips = this.cNote.consignment_trips[0].trip;

      }
      if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
        this.truckHireChallan = this.trips.truck_hire_challans[0];
      }
      this.consignmentPodFormGroup.patchValue(this.cNote);
   //  console.log(this.consignmentPodFormGroup.value);
      if (statusCd !== '') {
        this.consignmentPodFormGroup.patchValue({
          consignment_status_cd: statusCd
        });
      }
      if (this.cNote.consignment_status_cd == 'ePOD UPLOADED'
        || this.cNote.consignment_status_cd == 'HARD POD RECEIVED') {
        this.consignmentPodFormGroup.patchValue({ epod_received: 'Yes' });
      }
      else {
        this.consignmentPodFormGroup.patchValue({ epod_received: 'No' });
      }
      if (this.cNote.consignment_status_cd == 'HARD POD RECEIVED') {
        this.consignmentPodFormGroup.patchValue({ hard_pod_received: 'Yes' });
      }
      else {
        this.consignmentPodFormGroup.patchValue({ hard_pod_received: 'No' });
      }
      this.makePODMandatory();
      this.openModalGeneric(template, aData);
    }
  }
  getStatuses() {
    return ConsignmentNotes.consignmentStatuses;
  }
  getDeliveryConditions() {
    return ConsignmentNotes.consignmentDeliveryCondition;
  }
  getCancelReasons() {
    return ConsignmentNotes.cancelReasons;
  }
  makePODMandatory() {
    const consignmentStatus = this.consignmentPodFormGroup.get(
      'consignment_status_cd'
    ).value;
    if (
      consignmentStatus === 'ePOD UPLOADED' ||
      consignmentStatus === 'HARD POD RECEIVED'
      || consignmentStatus === 'POD GENERATED'
      || consignmentStatus === 'DELIVERED'
    ) {
      this.consignmentPodFormGroup.controls['pod_scan'].setValidators(
        Validators.required
      );

      this.consignmentPodFormGroup.controls['recieved_by'].setValidators(
        Validators.required
      );

      this.consignmentPodFormGroup.controls[
        'delivery_condition_cd'
      ].setValidators(Validators.required);
      this.consignmentPodFormGroup.controls['delivery_date'].setValidators(
        Validators.required
      );
      this.consignmentPodFormGroup.controls['delivery_time'].setValidators(
        Validators.required
      );

    }
    if (consignmentStatus === 'CANCELLED') {
      this.consignmentPodFormGroup.controls[
        'cancel_reason_cd'
      ].setValidators(Validators.required);
    }
 //  console.log(this.consignmentPodFormGroup);
    this.consignmentPodFormGroup.updateValueAndValidity();
    // delivery_date: ['', [Validators.required]],
    // delivery_time: ['', [Validators.required]],
    // recieved_by: ['', [Validators.required]],
    // delivery_condition_cd: ['', [Validators.required]],
  }
  shouldUpdateRemarks() {
    if (this.checkEPodReceived() == 'No' || this.checkHardPodReceived() == 'No') {
      return true;
    }
    if (!this.consignmentPodFormGroup.get('physical_pod_dt').value) {
      return true;
    }
    return false;
  }
  shouldShowPODDetails() {
    if (this.checkEPodReceived() == 'Yes' || this.checkHardPodReceived() == 'Yes') {
      return true;
    }else{
      return false;
    }
    // if (this.consignmentPodFormGroup.get('epod_received').value) {
    //   return true;
    // }

  }
  checkEPodReceived() {
    return this.consignmentPodFormGroup.get('epod_received').value == 'Yes' ? 'Yes' : 'No';
  }
  markEPodReceived(event) {
 //  console.log(event);
    return this.consignmentPodFormGroup.get('epod_received').value == 'Yes' ? 'Yes' : 'No';
  }
  checkHardPodReceived() {
    return this.consignmentPodFormGroup.get('hard_pod_received').value == 'Yes' ? 'Yes' : 'No';

  }
  markInvoiceStatus(event) {
 //  console.log(event);
  }
  markHardPodReceived(event) {
 //  console.log(event);
    // return this.consignmentPodFormGroup.get('pod_scan').value? true: false;
  }
  submitUpdatePODProgress(aData) {
    alert('needs to be updated');
  }

  getTHCEventDue(aData: Tasks) {
    if (aData.name == 'REQUEST ADVANCE' || aData.name == 'APPROVE ADVANCE' || aData.name == 'APPROVE ADVANCE FIN' || aData.name == 'UPDATE ADV DOCS') {
      return 'ATH'
    }
    if (aData.name == 'REQUEST BALANCE' || aData.name == 'APPROVE BALANCE' || aData.name == 'APPROVE BALANCE FIN' || aData.name == 'UPDATE BAL DOCS') {
      return 'BTH'
    }
    else {
      return aData.name;
    }
  }
  getTHCEventDelay(aData: Tasks) {
    if (aData.due_at < (new Date())) {
      return '(Delayed)';
    }
    return '';
  }
  getThcAdvanceAmt(aData: TruckHireChallans) {
    return aData.advance_transfer_amt + aData.advance_cash_amt + aData.advance_utilities_amt;
    return '';
  }
  getThcAdvancePaidAmt(aData: TruckHireChallans) {
    return aData.advance_payment_transfer_amt + aData.advance_payment_cash_amt + aData.advance_payment_utilities_amt;
    return '';
  }
  getThcBalanceAmt(aData: TruckHireChallans) {
    return aData.balance_amt;
    return '';
  }
  getThcBalancePaidAmt(aData: TruckHireChallans) {
    return aData.balance_payment_transfer_amt + aData.balance_payment_cash_amt + aData.balance_payment_utilities_amt;
    return '';
  }
  isUserAssignee(aTask: Tasks){
    if(StorageService.getUser().uuid == aTask.assignee) {
      return true;
    }
  }
  openModalClaimUnclaimTask(aTemplate: TemplateRef<any>, aData: Tasks){
    this.tasksFormGroup.patchValue(aData);
    // this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
    return this.openModalPopup(aTemplate);
  }
  openModalCancelTask(aTemplate: TemplateRef<any>, aData?: Tasks){
    if(aData) {
      this.isMultiSelectRows = false;
      this.tasks = aData;
      this.tasksFormGroup.patchValue(aData);
      this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
      return this.openModalPopup(aTemplate);
    }
    else {
      if(this.selection.hasValue()){
        const selectedTasks = [];
        this.selection.selected.forEach(element => {
          console.log(element);
          selectedTasks.push(element.id);
        });
        this.isMultiSelectRows = true;
        this.tasksFormGroup.patchValue({id: selectedTasks});
        return this.openModalPopup(aTemplate);
      }
      else {
        alert('Please select tasks to cancel');
      }
    }
  }
  openModalEscalateTask(aTemplate: TemplateRef<any>, aData?: Tasks){
    if(aData) {
      this.tasksFormGroup.patchValue(aData);
      // this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
      this.lookupEmployeesForTask();
      return this.openModalPopup(aTemplate);
    }
    else {
      if(this.taskSelection.hasValue()){
        return this.openModalPopup(aTemplate);
      }
      else {
        alert('Please select tasks to escalate');
      }
    }
  }
  openModalRejectApprovalTask(aTemplate: TemplateRef<any>, aData: Tasks){
    this.tasksFormGroup.patchValue(aData);
    // this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
    this.lookupEmployeesForTask();
    return this.openModalPopup(aTemplate);
  }
  openModalResolveTask(aTemplate: TemplateRef<any>, aData: Tasks){

    this.tasksFormGroup.patchValue(aData);
    // this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
    this.lookupEmployeesForTask();
    return this.openModalPopup(aTemplate);
  }
  openModalReassignTask(aTemplate: TemplateRef<any>, aData?: Tasks){
    this.enableClose = true;
    if(aData) {
      this.tasks = aData;
      this.tasksFormGroup.patchValue(aData);
      // this.tasksFormGroup.patchValue({lifecycle_state_cd: 'CANCELLED'});
      this.lookupEmployeesForTask();
      this.isMultiSelectRows = false;
      this.tasksFormGroup.patchValue(aData);
      return this.openModalPopup(aTemplate);
    }
    else {
      if(this.selection.hasValue()){
        this.lookupEmployeesForTask();
        const selectedTasks = [];
        this.selection.selected.forEach(element => {
          console.log(element);
          selectedTasks.push(element.id);
        });
        this.isMultiSelectRows = true;
        this.tasksFormGroup.patchValue({id: selectedTasks});
        return this.openModalPopup(aTemplate);
      }
      else {
        alert('Please select tasks to reassign');
      }

    }

  }
  submitReturnTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksFormGroup.patchValue({assignee: StorageService.getUser().uuid});
      this.tasksService.updateTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Claimed', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitRejectApprovalTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksFormGroup.patchValue({assignee: StorageService.getUser().uuid});
      this.tasksService.updateTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Rejected', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitClaimTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksFormGroup.patchValue({assignee: StorageService.getUser().uuid});
      this.tasksService.claimTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Claimed', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitUnClaimTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksFormGroup.get('assignee').reset();
      this.tasksService.unClaimTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Unclaimed', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitCancelTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksService.cancelTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Canelled', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  lookupEmployeesForTask(){
    appGlobals.observeChanges(
      this,
      this.tasksFormGroup.controls.assignee_name,
      'employeeListAbs',
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    appGlobals.observeChanges(
      this,
      this.tasksFormGroup.controls.escalate_to_name,
      'employeeListAbs',
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
  }
  setAssignee(option){
    this.tasksFormGroup.patchValue({assignee: option.uuid});
  }
  submitResolveTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksService.resolveTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Resolved', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitEscalateTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksService.escalateTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Escalated', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  submitReassignTask(){
    if(!this.tasksFormGroup.valid){
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.tasksFormGroup);
      appGlobals.markFormGroupTouched(this.tasksFormGroup);
    }
    else{
      this.tasksService.reassignTask(this.tasksFormGroup.value).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Task Reassigned', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        }
      );
    }
  }
  cancelBooking(data) {
    data.booking_status_cd = 'CANCELLED';
    this.bookingsService.updateStatus(data).subscribe(
      response => {
        if (response.status === 200) {
          this._snackBarService.open('Booking updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
          this.closeModalGeneric();
          this.getResponse(this.currentPage);
        }
        else {
          this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
        }
      }
    );
  }
  submitBookingStatus() {

    const formData = this.bookingsFormGroup.value;
    if (formData.booking_status_cd == 'CANCELLED') {
      this.bookingsFormGroup.controls['booking_status_cd'].setValidators([Validators.required]);
      this.bookingsFormGroup.controls['booking_status_cd'].updateValueAndValidity();
    }
    if (!this.bookingsFormGroup.valid) {
      this.isError = true;
      this.errorMessage = appGlobals.getFormValidationErrors(this.bookingsFormGroup);
      appGlobals.markFormGroupTouched(this.bookingsFormGroup);
    }
    else {

      this.bookingsService.updateBookings(formData).subscribe(
        response => {
          if (response.status === 200) {
            this._snackBarService.open('Booking updated', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            // this._snackBarService.open('Booking updated', 'X', {
            // duration: appGlobals.SNACKBAR_TIMEOUT
            // });
          }
          else {
            this._dialogService.openAlert({ 'title': 'Error', 'message': response.message });
          }
        },
        error => {
          this._dialogService.openAlert({ 'title': 'Error', 'message': error });
        }
      );
    }
  }
  submitDeliver(aData) {
    // debugger;
    this.makePODMandatory();
    if (!this.consignmentPodFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentPodFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentPodFormGroup);
    } else if (aData) {
      // this.cNote.consignment_status_cd = data.consignment_status_cd;
      // this.cNote.consignment_no = data.consignment_no;
      // this.cNote.trips_status_cd = data.trips_status_cd;
      // this.cNote.delivery_date = data.delivery_date;
      // this.cNote.delivery_time = data.delivery_time;
      // this.cNote.pod_remarks = data.pod_remarks;
      // this.cNote.pod_scan = data.pod_scan;
      // this.cNote.unloading_charges = data.unloading_charges;
      // this.cNote.damage_deduction = data.damage_deduction;
      // this.cNote.recieved_by = data.recieved_by;
      if (aData.epod_received == 'Yes') {
        aData.consignment_status_cd = 'ePOD UPLOADED';
      }
      if (aData.hard_pod_received == 'Yes') {
        aData.consignment_status_cd = 'HARD POD RECEIVED';
      }
      // console.log(this.cNote);
      // console.log(aData);
      // this.cNote.fill(aData);
      // console.log(this.cNote);
      this.consignmentNotesService.podUpload(aData).subscribe(
        response => {
          this.closeModalGeneric();
          this.getResponse(this.currentPage);
          this._snackBarService.open('POD Uploaded', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitPODReview(aData) {
    // debugger;
    this.makePODMandatory();
    if (!this.consignmentPodFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentPodFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentPodFormGroup);
    } else if (aData) {
      // this.cNote.consignment_status_cd = data.consignment_status_cd;
      // this.cNote.consignment_no = data.consignment_no;
      // this.cNote.trips_status_cd = data.trips_status_cd;
      // this.cNote.delivery_date = data.delivery_date;
      // this.cNote.delivery_time = data.delivery_time;
      // this.cNote.pod_remarks = data.pod_remarks;
      // this.cNote.pod_scan = data.pod_scan;
      // this.cNote.unloading_charges = data.unloading_charges;
      // this.cNote.damage_deduction = data.damage_deduction;
      // this.cNote.recieved_by = data.recieved_by;

      aData.consignment_status_cd = 'POD REVIEWED';

      // console.log(this.cNote);
      // console.log(aData);
      // this.cNote.fill(aData);
      // console.log(this.cNote);
      this.consignmentNotesService.podUpload(aData).subscribe(
        response => {
          this.closeModalGeneric();
          this.getResponse(this.currentPage);
          this._snackBarService.open('POD Reviewed', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }

  submitBillReview(aData) {
    this.submitBillSubmission(aData);
  }
  submitMrComplete(aData) {
    this.submitBillSubmission(aData);
  }
  submitPayment(aData) {
    this.submitBillSubmission(aData);
  }
  submitBillSubmission(aData) {
 //  console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else {

      this.invoiceService.updateEntity(aData).subscribe(response => {
        if (response.status === 200) {
          this.closeModalGeneric();
          this.getResponse(this.currentPage);
          this._snackBarService.open('Bill saved', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        } else {
          this._dialogService.openAlert({
            title: 'Error',
            message: response.message
          });
        }

      },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitBill(aData) {
 //  console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else {
      this.invoice.fill(aData);
      if (this.invoice.id) {
        this.invoiceService.updateEntity(this.invoice).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('Bill saved', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }

        },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
      else {
        this.invoiceService.addEntity(this.invoice).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('Bill created', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }

        },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }

    }

    // console.log(aData);
    // if (!this.invoicesFormGroup.valid) {
    //   this.isError = true;
    //   this.errorMessages = appGlobals.getFormValidationErrors(
    //     this.invoicesFormGroup
    //   );
    //   appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    // } else if (aData) {
    //   let invoice = new Invoices(aData);
    //   let invoiceLine = new InvoiceLines();
    //   if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
    //     invoiceLine = this.cNote.invoice_lines[0];
    //     invoice = invoiceLine.invoice;
    //     invoice.fill(aData);
    //   }
    //   invoiceLine.invoices_id = invoice.id;
    //   invoiceLine.invoice_line_amt = aData.invoice_line_amt;
    //   invoiceLine.freight_amt = aData.freight_amt;
    //   invoiceLine.loading_charges = aData.loading_charges;
    //   invoiceLine.misc_charges_amt = aData.misc_charges_amt;
    //   invoiceLine.unloading_charges = aData.unloading_charges;
    //   invoiceLine.detention_charges = aData.detention_charges;
    //   invoiceLine.detention_days = aData.detention_days;
    //   invoiceLine.consignment_notes_id = aData.consignment_notes_id;
    //   delete invoiceLine.invoice;
    //   invoice.invoice_lines = [invoiceLine];
    //  console.log(invoice);
    //   this.consignmentNotesService.updateInvoice(invoice).subscribe(
    //     response => {
    //       this.closeModalGeneric();
    //       this.getResponse(this.currentPage);
    //       this._snackBarService.open('Bill updated', 'X', {
    //         duration: appGlobals.SNACKBAR_TIMEOUT
    //       });

    //     },
    //     error => {
    //       this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
    //     }
    //   );
    // }
  }
  getInvoiceLineTotal(event) {
 //  console.log(event);
    const total = Number(this.invoicesFormGroup.get('freight_amt').value)
      + Number(this.invoicesFormGroup.get('loading_charges').value)
      + Number(this.invoicesFormGroup.get('unloading_charges').value)
      + Number(this.invoicesFormGroup.get('detention_charges').value)
      + Number(this.invoicesFormGroup.get('misc_charges_amt').value);
 //  console.log(total);
    this.invoicesFormGroup.patchValue({
      invoice_line_amt: total
    });

  }
  fileChange2(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentPodFormGroup.patchValue({
          pod_scan: response.data.filename
        });
        this.cNote.pod_scan = response.data.filename;
      });
  }

  fileChange3(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentPodFormGroup.patchValue({
          pod_scan_2: response.data.filename
        });
        this.cNote.pod_scan_2 = response.data.filename;
      });
  }
  fileChange4(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentPodFormGroup.patchValue({
          gate_in_scan: response.data.filename
        });
        this.cNote.gate_in_scan = response.data.filename;
      });
  }
  uploadInvoiceScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
      });
  }
  uploadSubmissionScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          submission_scan: response.data.filename
        });
      });
  }
  uploadPaymentScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          payment_scan: response.data.filename
        });
      });
  }


  setConsignmentStatusCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: event
    });
  }

  get getRightTemplate() {
    if (this.currentTemplate) {
      return this.currentTemplate;
    }
    return false;
  }
  onActivityChange(event) {
 //  console.log(event);
    this.recountTasks = false;
    this.selectedActivityIndex = event.value;
 //  console.log(this.selectedActivityIndex);
    if (event.value == 'CONFIRM') {
   //  console.log('on activity setting got confirm');
      /// this.tripsSearchForm.patchValue({task_name: ['CONFIRM']});
    }

    if (event.value == 'ASSIGN VEHICLE') {
   //  console.log('on activity setting got assign');
      // this.tripsSearchForm.patchValue({task_name: ['ASSIGN VEHICLE']});
    }
    this.tripsSearchForm.patchValue({ tab_activity_index: this.selectedActivityIndex });
 //  console.log('calling setTabsAndContent2');
    this.setTabsAndContent();
  }

  onDispatchActivityChange(event) {
 //  console.log(event);
    // alert('dispatch changed');
    if (event.index == 0) {
      this.disableStatus = true;
    } else {
      this.disableStatus = false;
    }
    switch (event.index) {
      case 0: {
        this.selectedDispatchActivity = 'UPDATE STATUS';
        break;
      }
      case 1: {
        if (event.tab.textLabel == 'Consignment') {
          this.selectedDispatchActivity = 'UPDATE LR';
        }
        else {
          this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
        }
        break;
      }
      // case 2: {
      //   this.selectedDispatchActivity = 'UPDATE LR';
      //   break;
      // }
      case 2: {
        this.selectedDispatchActivity = 'ATTACH DOCUMENTS';
        break;
      }
    }
    this.recountTasks = false;
    this.setTabsAndContentDispatch();
 //  console.log(this.selectedDispatchActivity);
    // TODO set popup tab
  }
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    // alert('swipe ' + when);
    if (when === 'start') {
        this.swipeCoord = coord;
        this.swipeTime = time;
    } else if (when === 'end') {
        const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
        const duration = time - this.swipeTime;
        // alert(duration + ' ' + Math.abs(direction[0]) + '  ' + Math.abs(direction[1] * 3));


        if (duration < 1000 //
            && Math.abs(direction[0]) > 30 // Long enough
            && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
            const swipe = direction[0] < 0 ? 'next' : 'previous';
            switch (swipe) {
                case 'previous':
                    if (this.selectedDispachIndex > 0) {
                      this.selectedDispachIndex--;
                    }
                    else {
                      this.selectedDispachIndex = 2;
                    }
                    // alert(this.selectedDispachIndex);
                    break;
                case 'next':
                  //this.tabGroup._tabs.length - 1 = 2
                    if (this.selectedDispachIndex < 2) {
                      this.selectedDispachIndex++;
                    }
                    else {
                      this.selectedDispachIndex = 0;
                    }
                    break;
            }
        }
    }
}
  setDataFromTrip(aTrip: Trips) {
 //  console.log('set data from trip');
    this.trips = aTrip;
    this.tripId = aTrip.id;
    this.next_status = this.getNextStatus(this.trips);
    this.next_plus_status = this.getNextPlusStatus(this.trips);
    this.last_status = this.getLastStatus(this.trips);
    this.consignmentNotesList = [];
    this.cNote = null;
    // this.selectedCustomer = null;
    this.bookings = null;
    this.truckHireChallan = null;
    if (this.trips.bookings) {
      this.bookings = this.trips.bookings;
      this.consignment_trips.trips_id = this.trips.id;
    }
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
    }
    let hasIncomplete = false;
    if (
      this.trips.consignment_trips &&
      this.trips.consignment_trips[0]) {
      this.trips.consignment_trips.forEach(aCNoteTrip => {
        if (aCNoteTrip.consignment_note.consignment_status_cd == 'INCOMPLETE') {
          hasIncomplete = true;
        }
        this.consignmentNotesList.push(aCNoteTrip.consignment_note);
      });
      this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
    }
    // console.log('setDataFromTrip got most of trip data and list ' + this.consignmentNotesList.length + this.selectedDispatchActivity);
    // if (hasIncomplete && this.consignmentNotesList.length == 1) {
    //   this.isSingleIncompleteLR = true;
    //   this.consignment_notes_id = this.consignmentNotesList[0].id;
    //  console.log('setDataFromTrip calling prepare update lr if distatch activit mpwwd'  + this.selectedDispatchActivity );
    //   if (this.selectedDispatchActivity == 'MARK PAPER WORK DONE') {
    //     this.prepareUpdateLr();
    //   }
    // }
    // else {

    // }
  }
  addNewConsignment() {
    this.cNote = new ConsignmentNotes();
    this.cNote.bookings_id = this.bookings.id;
    this.cNote.customers_id = this.bookings.customers_id;
    this.cNote.consignment_trips = [new ConsignmentTrips()];
    this.cNote.consignment_trips[0].trips_id = this.trips.id;
    this.cNote.consignment_status_cd = 'INCOMPLETE';
    this.consignmentItemList = [];
    this.consignment_notes_id = null;
    this.selectedDispatchActivity = 'UPDATE LR';
    // this.ngOnInit();
    this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.consignmentNotesFormGroup.addControl(
      'consignment_items',
      this._formBuilder.array([])
    );
    this.getNewConsignmentItemForm();
    this.consignmentNotesFormGroup.patchValue(this.cNote);
    this.visiblityState = 'shown';
    // this.getResponse(this.consignment_notes_id);
    // this.setEwayBillLookup();
    this.setTabsAndContentDispatch();
    // this.ngOnInit();
  }

  openUpdateLr(aData) {
    this.cNote = new ConsignmentNotes();
    this.consignment_notes_id = aData.id;
    this.selectedDispatchActivity = 'UPDATE LR';
    // this.prepareUpdateLr();
    // console.log('get response called');
    // this.getConsignmentResponse(this.consignment_notes_id);
    // // this.setEwayBillLookup();
    this.setTabsAndContentDispatch();
  }
  prepareUpdateConsignmentList() {
 //  console.log('prepareUpdateConsignmentList');
    this.cNote = new ConsignmentNotes();
    this.consignment_notes_id = null;
    // this.tripId = aTripId;
    // set consignment list from trip..
  }
  prepareUpdateConsignment() {
    // set consignment note
 //  console.log('prepareUpdateConsignment');
    // this.consignment_notes_id = aConsignmentId;
    this.prepareUpdateLr();
  }
  prepareUpdateLr() {
 //  console.log('prepapre update lr');
    // this.ngOnInit();
    // this.prepareLRForm();
    this.cNote = new ConsignmentNotes();
    if (this.consignment_notes_id && this.consignment_notes_id != null) {
      this.getConsignmentResponse(this.consignment_notes_id);
    }

  }

  openModalConsignmentTrip(template, aData: Trips) {
 //  console.log('called open modal consignment trip');
    this.setDataFromTrip(aData);
    let hasIncomplete = false;
    this.consignmentNotesList.forEach(aCNoteTrip => {
      if (aCNoteTrip.consignment_status_cd == 'INCOMPLETE') {
        hasIncomplete = true;
      }
    });
    if (hasIncomplete && this.consignmentNotesList.length == 1) {
      this.isSingleIncompleteLR = true;
      this.consignment_notes_id = this.consignmentNotesList[0].id;
      // console.log('openModalConsignmentTrip calling prepare update lr if distatch activit mpwwd' + this.selectedDispatchActivity);
      if (this.selectedDispatchActivity == 'MARK PAPER WORK DONE') {
        // this.prepareUpdateLr();
        this.selectedDispatchActivity = 'UPDATE LR';
      }
    }
    this.setTabsAndContentDispatch();
    // this.prepareUpdateLr();
    let modalRefReally = this.openModalGeneric(template, aData);
    return modalRefReally;
  }





  submitTruckDocumentDispatch() {
 //  console.log('submitTruckDocumentDispatch ' + this.validateTruckDocument());
    if (this.validateTruckDocument()) {
   //  console.log('truck documents are valid');
      const formData = this.attachTruckDocumentsFormGroup.value;
   //  console.log(formData);
      this.tripsService.attachTruckDocument(formData).subscribe(
        response => {
          if (response.status === 200) {
            // this.closeModalGeneric();
            // this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            return this.getNextActionButtonClick(this.tasks, this.tasks.entity_id, this.tasks.entity_type);
            // this.selectedDispatchActivity = 'UPDATE STATUS';
            // this.setTabsAndContentDispatch();
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error)
          });
        }
      );
    }
  }
  submitTripStatusDispatch() {
 //  console.log('submitTripStatusDispatch');
    if (!this.validateTripStatus()) {
      return;
    }
    const formData = this.consignmentTripTrackingsFormGroup.value;
    this.consignmentTripTrackingsService.updateStatus(formData).subscribe(
      response => {
        if (response.status === 200) {
          // this.closeModalGeneric();
          // this.getResponse(this.currentPage);
          window.scrollTo(0, 0);
          this._snackBarService.open(response.message, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });

          return this.getNextActionButtonClick(this.tasks, this.tasks.entity_id, this.tasks.entity_type);
          // this.setTabsAndContentDispatch();
        } else {
          this._dialogService.openAlert({
            title: 'Error',
            message: response.message
          });
        }
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  setTabsAndContentDispatch() {
 //  console.log('set tabs and content called ' + this.selectedDispatchActivity);
    switch (this.selectedDispatchActivity) {
      case 'UPDATE STATUS': {
        this.prepareUpdateStatus();
        // this.dispatchContentTemplate = this.updateStatusContent;
        // this.getResponse();
        break;
      }
      case 'MARK PAPER WORK DONE': {

        this.prepareUpdateConsignmentList();
        // this.dispatchContentTemplate = this.cNoteList; // this.cNoteDetails;
        break;
      }
      case 'UPDATE LR': {

        this.prepareUpdateConsignment();
        // this.dispatchContentTemplate = this.cNoteDetails;

        break;
      }
      case 'ATTACH DOCUMENTS': {
     //  console.log('preparing attach documents ');
     //  console.log(this.truckHireChallan);
        this.prepareAttachDocuments(this.truckHireChallan);
        //this.dispatchContentTemplate = this.truckDocumentsContent;
        break;
      }
    }
  }

  submitConsignmentBookedDispatch() {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: 'BOOKED'
    });
    return this.submitConsignmentDispatch();
  }
  submitConsignmentDispatch() {
 //  console.log(this.consignmentNotesFormGroup.value);
    if (this.consignmentItemList.length == 0) {
      this.updateItemInConsignmentForm();
    }
    //return;
    if (!this.isValidConsignmentForm()) {
      return;
    } else {
      this.cNote = new ConsignmentNotes(
        this.consignmentNotesFormGroup.value
      );
      this.cNote.consignment_items = this.consignmentItemList;
      this.cNote.eway_bills = this.consignmentEwayBillList;
      this.cNote.bookings = this.bookings;
      if (!this.consignment_trips) {
        this.consignment_trips = new ConsignmentTrips();
        if (this.tripId) {
          this.consignment_trips.trips_id = this.tripId;
        }
      }
      this.cNote.consignment_trips = new Array(
        this.consignment_trips
      );
   //  console.log(this.cNote);
      if (this.cNote.id) {
        this.consignmentNotesService
          .updateConsignmentNotes(this.cNote)
          .subscribe(
            response => {
              if (response.status === 200) {
                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });
             //  console.log(response);
                this.cNote = new ConsignmentNotes(response.data);
                if (!this.consignmentNotesList) {
                  this.consignmentNotesList = [];
                }
             //  console.log(this.consignmentNotesList);
                appGlobals.replaceAppendObjectInArray(this.cNote, this.consignmentNotesList);
                this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                this.dispatchContentTemplate = this.cNoteList;
                this.isSingleIncompleteLR = false;
                this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
                this.setTabsAndContentDispatch();
                // this.openModalGeneric();
                // const confResult = confirm('LR ' + this.consignmentNotes.consignment_no + ' created.'
                //   + ' Do you want to mark PAPER WORK COMPLETE');
                // if (confResult) {
                //   // this.markPaperWorkComplete();
                //   this.onComplete.emit();
                // }
                // this._location.back();
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
            }
          );
      } else {
        this.consignmentNotesService
          .addConsignmentNotes(this.cNote)
          .subscribe(
            response => {
              if (response.status === 200) {
                // this._snackBarService.open(response.message, 'X', {
                //   duration: appGlobals.SNACKBAR_TIMEOUT
                // });
                // this.consignmentNotes = new ConsignmentNotes(response.data);
                // appGlobals.replaceAppendObjectInArray(this.consignmentNotes, this.consignmentNotesList);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;


                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });
             //  console.log(response);
                this.cNote = new ConsignmentNotes(response.data);
                if (!this.consignmentNotesList) {
                  this.consignmentNotesList = [];
                }
             //  console.log(this.consignmentNotesList);
                appGlobals.replaceAppendObjectInArray(this.cNote, this.consignmentNotesList);
                this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                this.dispatchContentTemplate = this.cNoteList;
                this.isSingleIncompleteLR = false;
                this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
                this.setTabsAndContentDispatch();

                // this.consignmentNotesList.push(this.consignmentNotes);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // console.log(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({
                title: 'Error',
                message: error,
                flag: 1
              });
            }
          );
      }
    }
  }
  onGlobalActivityChange(event) {
 //  console.log(event);
    this.selectedGlobalActivityIndex = event.value;
    if (this.selectedGlobalActivityIndex) {
      this.selectedActivityIndex = 'ALL TASKS';
    }
 //  console.log(this.selectedGlobalActivityIndex);
 //  console.log(this.tripsSearchForm.value);
 //  console.log('calling setTabsAndContent3');
    this.tripsSearchForm.patchValue({ tab_global_activity_index: this.selectedGlobalActivityIndex });
    this.recountTasks = true;
    this.setTabsAndContent();
  }
  setActivityIndexByRole() {
    let role = this.tripsSearchForm.controls['work_area_cd'].value;
    if (!role) {
      role = this.user.work_area_cd;
    }
 //  console.log('setting activity index by role ' + role + Array.isArray(role));
    if (Array.isArray(role)) {
      role = role[0];
    }
    switch (role) {
      case 'BOOKING EXECUTIVE': {
        this.selectedActivityIndex = 'TRACK-BE';
        break;
      }
      case 'TRAFFIC EXECUTIVE': {
        this.selectedActivityIndex = 'ASSIGN VEHICLE';
        break;
      }
      case 'ROH': {
        this.selectedActivityIndex = 'CONFIRM';
        break;
      }
      case 'LR EXECUTIVE': {
        this.selectedActivityIndex = 'DISPATCH';
        break;
      }
      case 'CT EXECUTIVE': {
        this.selectedActivityIndex = 'TRACK';
        break;
      }
      case 'COORDINATOR': {
        this.selectedActivityIndex = 'GENERATE BILL';
        break;
      }
      case 'FINANCE EXECUTIVE': {
        this.selectedActivityIndex = 'PAID BILL MR';
        break;
      }
      case 'KAM': {
        this.selectedActivityIndex = 'REVIEW BILL';
        break;
      }
      default: {
     //  console.log(' in case default ' + role);
        this.selectedActivityIndex = 'ALL TASKS';
      }
    }
    this.tripsSearchForm.patchValue({ tab_activity_index: this.selectedActivityIndex });
    this.tripsSearchForm.patchValue({ tab_global_activity_index: this.selectedGlobalActivityIndex });

 //  console.log('set activity index by role to ' + this.selectedActivityIndex);
  }
  setTabsAndContent() {
 //  console.log('set tabs and content called ' + this.selectedActivityIndex);
 //  console.log('set tabs and content called selectedGlobalActivityIndex ' + this.selectedGlobalActivityIndex);

    let viewCompletedTasks = false;
    if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
      this.tripsSearchForm.patchValue({ lifecycle_state_cd: ['COMPLETE', 'CANCELLED'] });
      viewCompletedTasks = true;
      this.leftTopTabTemplate = this.topTraffic;
      this.leftListTemplate = this.listTripsViewTask;
    }
    else {
      let states = this.tripsSearchForm.get('lifecycle_state_cd').value;
      if (!states) {
        states = [];
      }
      if (states.includes('COMPLETE')) {
        this.tripsSearchForm.get('lifecycle_state_cd').reset();
      }

    }
    const entityType = this.tripsSearchForm.get('entity_type').value;
    switch (this.selectedActivityIndex) {
      case 'CONFIRM': {
        this.tripsSearchForm.patchValue({ task_name: ['CONFIRM'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTrips;
        }
        this.getResponse();
        break;
      }
      case 'COMPLETE BOOKING': {

        this.tripsSearchForm.patchValue({ task_name: ['COMPLETE BOOKING', 'CLOSE'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Booking'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listBookings;
        }
        this.getResponse();
        break;
      }
      case 'CONFIRM BOOKING': {

        this.tripsSearchForm.patchValue({ task_name: ['CONFIRM BOOKING'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Booking'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listIndents;
        }
        this.getResponse();
        break;
      }
      case 'CREATE BOOKING': {

        this.tripsSearchForm.patchValue({ task_name: ['CREATE BOOKING'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Booking'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listIndents;
        }
        this.getResponse();
        break;
      }
      case 'ASSIGN VEHICLE': {
     //  console.log('set tabs switch case for assign vehicle');
        this.tripsSearchForm.patchValue({ task_name: ['ASSIGN VEHICLE'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsAssignVehicle;
        }
        this.getResponse();
        break;
      }
      case 'DISPATCH': {
        // this.tripsSearchForm.patchValue({task_name: ['DISPATCH', 'CONFIRM REACHED LOADING', 'CONFIRM LOADING START']});
        this.tripsSearchForm.patchValue({ task_name: ['DISPATCH'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip'] });
        // console.log(this.tripsSearchForm.value);
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTrips;
        }
        this.getResponse();
        break;
      }
      case 'MARK RECEIVED POD': {
        this.tripsSearchForm.patchValue({ task_name: ['MARK RECEIVED POD', 'UPLOAD ePOD'] });
        const currentWa = this.tripsSearchForm.get('work_area_cd').value;
        this.tripsSearchForm.patchValue({ work_area_cd: ['ROH', 'TRAFFIC EXECUTIVE'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Consignment Note'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsPODStatus;
        }

        this.getResponse();
        this.tripsSearchForm.patchValue({ work_area_cd: currentWa });
        break;

      }
      case 'REVIEW POD': {
        this.tripsSearchForm.patchValue({ task_name: ['REVIEW POD'] });
        const currentWa = this.tripsSearchForm.get('work_area_cd').value;
        this.tripsSearchForm.patchValue({ work_area_cd: ['ROH', 'TRAFFIC EXECUTIVE'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Consignment Note'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsPODReview;
        }

        this.getResponse();
        this.tripsSearchForm.patchValue({ work_area_cd: currentWa });
        break;

      }
      case 'GENERATE BILL': {
        this.tripsSearchForm.patchValue({ task_name: ['GENERATE BILL'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Consignment Note'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsGenerateBill;
        }
        this.getResponse();
        break;
      }

      case 'REVIEW BILL': {
        this.tripsSearchForm.patchValue({ task_name: ['REVIEW BILL'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Invoice'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsReviewBill;
        }

        this.getResponse();
        break;
      }
      case 'SUBMIT BILL': {
        this.tripsSearchForm.patchValue({ task_name: ['SUBMIT BILL'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Invoice'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsReviewBill;
        }

        this.getResponse();
        break;
      }
      case 'PAID BILL MR': {}
      case 'PAID BILL MR WEBX': {}
      case 'PAID BILL': {
        this.tripsSearchForm.patchValue({ task_name: ['COLLECT', 'MARK PAYMENT RECEIVED', 'MARK MR COMPLETED'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Invoice'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsReviewBill;
        }

        this.getResponse();
        break;
      }

      case 'REQUEST ADVANCE': {
        this.tripsSearchForm.patchValue({ task_name: ['REQUEST ADVANCE', 'REQUEST BALANCE', 'ATTACH DOCUMENTS'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Truck Hire Challan'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsRequestPayment;
        }
     //  console.log(this.tripsSearchForm.value);
        this.getResponse();
        break;
      }
      case 'Escalation': {
        this.tripsSearchForm.patchValue({ task_name: ['Escalation'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsPODStatus;
        }

        this.getResponse();
        break;

      }
      case 'Reconcilication': {
        this.tripsSearchForm.patchValue({ task_name: ['Reconcilication'] });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Invoice'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsPODStatus;
        }

        this.getResponse();
        break;

      }
      case 'APPROVE ADVANCE': {

        this.tripsSearchForm.patchValue({ task_name: this.paymentTasksROH });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Truck Hire Challan'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsApprovePayment;
        }

        this.getResponse();
        break;
      }
      case 'APPROVE ADVANCE CO': {

        this.tripsSearchForm.patchValue({ task_name: this.paymentTasksCO });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Truck Hire Challan'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsApprovePayment;
        }

        this.getResponse();
        break;
      }
      case 'APPROVE ADVANCE FIN': {

        this.tripsSearchForm.patchValue({ task_name: this.paymentTasksFIN });
        this.tripsSearchForm.patchValue({ entity_type: ['Trip', 'Truck Hire Challan'] });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTripsApprovePayment;
        }

        this.getResponse();
        break;
      }
      case 'TRACK': {
        // if()
        const role = this.tripsSearchForm.controls['work_area_cd'].value;
        this.tripsSearchForm.patchValue({ entity_type: ['Trip'] });
        let taskList = this.trackingTasks;
        // if(role == 'BOOKING EXECUTIVE') {
        //   taskList.push('CREATE BOOKING')
        // }
        this.tripsSearchForm.patchValue({
          task_name: taskList
        });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;

          this.leftListTemplate = this.listTrips;
        }
        this.getResponse();
        break;
      }
      case 'TRACK-BE': {
        // if()
        const role = this.tripsSearchForm.controls['work_area_cd'].value;
        this.tripsSearchForm.patchValue({ entity_type: ['Trip'] });
        let taskList = this.trackingTasksBE;
        // if(role == 'BOOKING EXECUTIVE') {
        //   taskList.push('CREATE BOOKING')
        // }
        this.tripsSearchForm.patchValue({
          task_name: taskList
        });
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;

          this.leftListTemplate = this.listTripsBE;
        }
        this.getResponse();
        break;
      }
      case 'UPLOAD ePOD': {
        // break;
      }
      case 'ALL TASKS': {
        this.tripsSearchForm.get('task_name').reset();
        this.tripsSearchForm.get('entity_type').reset();
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTrips;
        }

        this.getResponse();
        break;
      }
      case 'ALL TASKS': {
        this.tripsSearchForm.get('task_name').reset();
        // this.tripsSearchForm.get('entity_type').reset();
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTrips;
        }

        this.getResponse();
        break;
      }
      default: {
     //  console.log('set tab and content default switch' + this.selectedActivityIndex);
        // this.tripsSearchForm.get('entity_type').reset();
        if (!viewCompletedTasks) {
          this.leftTopTabTemplate = this.topTraffic;
          this.leftListTemplate = this.listTrips;
        }

        this.getResponse();
        // return  this.updateToStatus(element, entityAction, entityType, entityId);
      }
        this.tripsSearchForm.patchValue({ entity_type: entityType });
    }
 //  console.log('done set tabs and content called ' + this.selectedActivityIndex);
 //  console.log(this.tripsSearchForm.get('task_name'));

  }

  showAssignTruck() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'TRAFFIC EXECUTIVE') {
      // return this.checkTask('ASSIGN VEHICLE');
      return true;
    }


    // return false;
  }
  showConfirm() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'ROH') {
      // return this.checkTask('CONFIRM');
      return true;
    }

    return false;
  }
  showMarkReceivedPod() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'TRAFFIC EXECUTIVE'
      ||
      this.tripsSearchForm.controls['work_area_cd'].value == 'CT EXECUTIVE'
      || this.tripsSearchForm.controls['work_area_cd'].value == 'LR EXECUTIVE'
      // || this.tripsSearchForm.controls['work_area_cd'].value == 'ROH'
    ) {
      // return this.checkTask('MARK RECEIVED POD');
      return true;
    }

    return false;
  }
  showReviewPod() {
    if (
      // this.tripsSearchForm.controls['work_area_cd'].value == 'TRAFFIC EXECUTIVE'
      // ||
      this.tripsSearchForm.controls['work_area_cd'].value == 'ROH'
    ) {
      // return this.checkTask('MARK RECEIVED POD');
      return true;
    }

    return false;
  }
  showTrack() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'CT EXECUTIVE'
      && this.checkTask(this.trackingTasks)) {
      return true;
    }
    // return this.checkTask(this.trackingTasks);
    return false;
  }
  showTrackBE() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'BOOKING EXECUTIVE'
      // && this.checkTask(this.trackingTasksBE)
      ) {
      return true;
    }
    // return this.checkTask(this.trackingTasksBE);
    return false;
  }
  showCompleteBooking() {
    return false;
  }
  showIndents() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'BOOKING EXECUTIVE'
    ) {
      // return (this.checkTask('CREATE BOOKING'));
      return true;
    }

    return false;
  }
  showCreateBooking() {

    if (this.tripsSearchForm.controls['work_area_cd'].value == 'BOOKING EXECUTIVE'
    ) {
      // return (this.checkTask('CREATE BOOKING')
      // || this.checkTask('MARK  IN PROGRESS')
      // || this.checkTask('COMPLETE BOOKING'));
      return true;
    }

    return false;
  }
  showGenerateBill() {
    if (
      //this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      // ||
      this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR') {
      // return this.checkTask('GENERATE BILL');
      return true;
    }

    return false;
  }
  showReviewBill() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      //  || this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
      // ||  this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'

    ) {
      // return this.checkTask('REVIEW BILL');
      return true;
    }

    return false;
  }
  showSubmitBill() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      || this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
      || this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'

    ) {
      return this.checkTask('SUBMIT BILL');
      return true;
    }

    return false;
  }

  showEscalation() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'KAM') {
      return true;
    }
    return this.checkTask('Escalation');
    return false;
  }

  showReconcilication() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'KAM') {
      return true;
    }
    return this.checkTask('Reconcilication');
    return false;
  }

  showPaymentReceivedBill() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      //|| this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
      // ||  this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'

    ) {
      return this.checkTask(['COLLECT', 'MARK PAYMENT RECEIVED', 'MARK MR COMPLETED']);
      // return true;
    }
    return false;
  }
  showMR() {
    if (
      //this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      // || this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
       this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'

    ) {
      // return this.checkTask(['COLLECT', 'MARK PAYMENT RECEIVED', 'MARK MR COMPLETED']);
      return true;
    }
    return false;
  }
  showMRWebXpress() {
    if (
      //this.tripsSearchForm.controls['work_area_cd'].value == 'KAM'
      this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
      //||  this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'

    ) {
      // return this.checkTask(['COLLECT', 'MARK PAYMENT RECEIVED', 'MARK MR COMPLETED']);
      // return true;
    }

    return false;
  }
  showRequestAdvance() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'TRAFFIC EXECUTIVE') {
      // return (this.checkTask('REQUEST ADVANCE') || this.checkTask('REQUEST BALANCE') || this.checkTask('ATTACH DOCUMENTS'));
      // return (this.checkTask('REQUEST ADVANCE') || this.checkTask('REQUEST BALANCE'));

      return true;
    }
    return false;
  }
  showApproveAdvance() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'ROH'
    ) {
      // return (this.checkTask('APPROVE ADVANCE') || this.checkTask('APPROVE BALANCE'));
      return true;
    }

    return false;
  }
  showApproveAdvanceCo() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'COORDINATOR'
    ) {
      // return (this.checkTask('APPROVE ADVANCE') || this.checkTask('APPROVE BALANCE'));
      return true;
    }

    return false;
  }
  showApproveAdvanceFin() {
    if (
      this.tripsSearchForm.controls['work_area_cd'].value == 'FINANCE EXECUTIVE'
    ) {
      // return (this.checkTask('') || this.checkTask('APPROVE BALANCE'));

      return true;
    }
    return false;
  }
  showDispatch() {
    if (this.tripsSearchForm.controls['work_area_cd'].value == 'LR EXECUTIVE') {
      // return (this.checkTask('DISPATCH') );
      return true;
    }

    return false;
  }

  showAllTasks() {
    // if(this.tripsSearchForm.controls['work_area_cd'].value == 'TRAFFIC EXECUTIVE') {
    //   return true;
    // }
    return this.userIsAdmin();
    // return true;
  }
  getRowColor(aData: any) {
    let now = new Date();
    if (aData.completed_at) {
      now = aData.completed_at;
    }
    let secsAllowed = (aData.due_at.getTime() - aData.created_at.getTime()) / 1000;
    let secsPassed = (now.getTime() - aData.created_at.getTime()) / 1000;
    if (secsPassed > secsAllowed) {
      return 'border-red';
    }
    if (secsPassed > (2 * secsAllowed / 3)) {
      return 'border-orange';
    }
    return 'border-green';
  }
  getTripStatusColor(status_cd) {
    const statusIndex = Trips.statusesIndexForAll.indexOf(status_cd);
    const currIndex = Trips.statusesIndexForAll.indexOf(this.trips.trips_status_cd);
    // if (statusIndex <= currIndex) {
    //   return 'blue';
    // }
    // return 'grey';
    if (statusIndex == currIndex) {
      return 'green';
    } else if (statusIndex < currIndex) {
      return 'blue';
    } else {
      return 'grey';
    }
  }
  getPODStatusColor(aData) {
    if (aData.consignment_status_cd == 'ePOD UPLOADED') {
      return 'green'
    }
    else if (aData.consignment_status_cd == 'HARD POD RECEIVED') {
      return 'green'
    }
    else {
      return 'grey';
    }
    // return aData.consignment_status_cd;
  }
  getBTHProgressColor(aData) {
    if (aData.truck_hire_challans_status_cd == 'ADVANCE REQUESTED'
      || aData.truck_hire_challans_status_cd == 'ADVANCE PAID') {
      return 'blue';
    }
    return 'grey';
  }
  getPODXFactor(aData: Tasks): number {
    let now = new Date();
    let secsAllowed = (aData.due_at.getTime() - aData.created_at.getTime()) / 1000;
    let secsPassed = (now.getTime() - aData.created_at.getTime()) / 1000;
    // console.log(secsPassed);
    // console.log(secsAllowed);
    // transit time * 3 is allowedtime.
    // transit time is 3*transit days + 1 = due date from delivery
    // due date -1 = 3 into transit days
    // due length / transit days  ~ 3x
    // days passed / transit days
    if (!aData.actual_arrival_dt || !aData.actual_dispatch_dt) {
      // console.log(aData.actual_arrival_dt);
      // console.log(aData.actual_dispatch_dt);
      // console.log(aData);
      // console.log(aData.name);
      return 0;
    }
    let transitDaysSecs = (aData.actual_arrival_dt.getTime() - aData.actual_dispatch_dt.getTime());
    let transitDays = Math.ceil(transitDaysSecs / (1000 * 60 * 60 * 24));
    let dueDays = Math.ceil((aData.due_at.getTime() - aData.actual_arrival_dt.getTime()) / (1000 * 60 * 60 * 24));
    let daysPassed = Math.ceil((now.getTime() - aData.actual_arrival_dt.getTime()) / (1000 * 60 * 60 * 24));
    // let xFactor = Math.round( (3 *( secsPassed/secsAllowed) ) * 10 ) / 10;
    let xFactor = Math.round(((daysPassed / transitDays)) * 10) / 10;
    // console.log('factor by transit ' + xFactor);
    // console.log('factor by due ' + Math.round( ( ( 3*( daysPassed/ dueDays) ) * 10 ) / 10) );
    //console.log('days passed' + daysPassed);
    // console.log('days transit' + transitDays);
    // x = y passed is 3x the
    // console.log(xFactor );
    // console.log(aData.created_at);
    // console.log(aData.due_at);
    // console.log(Math.
    return xFactor;
    // 3x+1 is create date till due date total allowed
    // create date till today is time elapses
    // x factor is total allowed / total elapsed.

  }
  isPODXFactorRed(aData) {
    if (this.getPODXFactor(aData) >= 3) {
      return true;
    }
  }
  isPODXFactorAmber(aData) {
    if (this.getPODXFactor(aData) >= 2.5) {
      return true;
    }
  }
  isPODXFactorGreen(aData) {
    if (this.getPODXFactor(aData) < 2.5) {
      return true;
    }
  }

  getPODColorClass(aData) {
    if (this.isPODXFactorRed(aData)) {
      return "text-red";
    }
    else if (this.isPODXFactorAmber(aData)) {
      return "text-amber";
    }
    else if (this.isPODXFactorGreen(aData)) {
      return "text-green";
    }
    else {
      return "normal";
    }
  }
  getPODRecClass(aData) {
    if (this.isPODXFactorRed(aData)) {
      return "pod-days-rec-red";
    }
    else if (this.isPODXFactorAmber(aData)) {
      return "pod-days-rec-amber";
    }
    else if (this.isPODXFactorGreen(aData)) {
      return "pod-days-rec-green";
    }
    else {
      return "normal";
    }
  }
  getGenerateBillRecClass(aData) {
    if (aData.due_at < (new Date())) {
      return "pod-days-rec-red";
    }
    return "normal";
  }
  getGenerateBillColorClass(aData) {
    if (aData.due_at < (new Date())) {
      return "text-red";
    }
    return "normal";
  }
  daysRemainingInBilling(aData) {
    return appGlobals.timeSince(aData.due_at, null, true, false);
  }
  taskDuration(aData) {
    if (aData) {
      return appGlobals.timeSince(aData.due_at, aData.created_at, true, false);
    }

  }
  tripDuration(aData) {
    return appGlobals.timeSince(aData.actual_dispatch_dt, aData.actual_arrival_dt, true, false);
  }
  daysRemainingInPOD(aData) {
    return appGlobals.timeSince(aData.due_at, null, true, false);
  }

  getPODTimeLine(aData) {
    return aData.due_at;
  }
  reassignTaskExecutive(template, element) {
    const entityType = element.entity_type;
    this.task = new Tasks(element);
    this.tasksFormGroup.patchValue(element);
    // this.lookupEmployeesForTask();
 //  console.log(this.task);
    this.modalRef = this.openModalGeneric(template);
    return;
    if (entityType == 'Trip') {
      return this.tripsService.getTripsById(element.entity_id).subscribe(
        response => {
       //  console.log(response);
          this.trips = new Trips(response.data);
       //  console.log(this.task);
       //  console.log(this.trips);
          // this.task = new Tasks(element);
          // this.tasksFormGroup.patchValue(element);
          // this.lookupEmployeesForTask();
          this.modalRef = this.openModalGeneric(template);

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    if (entityType == 'Truck Hire Challan') {
      return this.thcService.getTruckHireChallansById(element.entity_id).subscribe(
        response => {
       //  console.log(response);
          this.truckHireChallan = new TruckHireChallans(response.data);
          this.trips = this.truckHireChallan.trip;
          // this.task = new Tasks(element);
          // this.tasksFormGroup.patchValue(element);
          // this.lookupEmployeesForTask();
          this.modalRef = this.openModalGeneric(template);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
    if (entityType == 'Consignment Note') {
      return this.consignmentNotesService.getConsignmentNotesById(element.entity_id).subscribe(
        response => {
       //  console.log(response);
          this.cNote = new ConsignmentNotes(response.data);
          if (response.data.consignment_trips && response.data.consignment_trips[0]) {
            this.trips = response.data.consignment_trips[0].trip;
          }

          // this.task = new Tasks(element);
          // this.tasksFormGroup.patchValue(element);
          // this.lookupEmployeesForTask();
          this.modalRef = this.openModalGeneric(template);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }

  }
  recalculateTasksCount() {
    if (this.tasksCount && Array.isArray(this.tasksCount)) {
      const orgActiveCount = this.createdTasksCount;
      const orgPriorityCount = this.priorityTasksCount;
      const orgTodaysCount = this.todaysTasksCount;
      this.completedTasksCount = 0;
      this.createdTasksCount = 0;
      this.priorityTasksCount = 0;
      this.todaysTasksCount = 0;

      this.tasksCountHash = [];
      this.tasksCountHash['CREATED'] = [];
      this.tasksCountHash['COMPLETE'] = [];
      this.tasksCountHash['PRIORITY'] = [];
      this.tasksCountHash['TODAY'] = [];
      this.tasksCountHashByEntity = [];
      this.tasksCountHashByEntity['CREATED'] = [];
      this.tasksCountHashByEntity['COMPLETE'] = [];
      this.tasksCountHashByEntity['PRIORITY'] = [];
      this.tasksCountHashByEntity['TODAY'] = [];
      this.tasksCount.forEach(element => {
        if (element.lifecycle_state_cd == 'COMPLETE' || element.lifecycle_state_cd == 'CANCELLED') {
          this.completedTasksCount = this.completedTasksCount + parseInt(element.tasks_count);

          if (!this.tasksCountHash['COMPLETE'][element.name]) {
            this.tasksCountHash['COMPLETE'][element.name] = 0;
          }
          this.tasksCountHash['COMPLETE'][element.name] = this.tasksCountHash['COMPLETE'][element.name] + element.tasks_count;

          if (!this.tasksCountHashByEntity['COMPLETE'][element.entity_type]) {
            this.tasksCountHashByEntity['COMPLETE'][element.entity_type] = 0;
          }
          this.tasksCountHashByEntity['COMPLETE'][element.entity_type] = this.tasksCountHashByEntity['COMPLETE'][element.entity_type] + element.tasks_count;
        }
        else if (element.lifecycle_state_cd == 'CREATED' || element.lifecycle_state_cd == 'PENDING') {
          if (this.selectedGlobalActivityIndex == 'ALL TASKS') {
            this.createdTasksCount = this.createdTasksCount + parseInt(element.tasks_count);
            this.priorityTasksCount = this.priorityTasksCount + parseInt(element.tasks_count_priority);
            this.todaysTasksCount = this.todaysTasksCount + parseInt(element.tasks_count_today);

          }
          if (!this.tasksCountHash['CREATED'][element.name]) {
            this.tasksCountHash['CREATED'][element.name] = 0;
          }
          this.tasksCountHash['CREATED'][element.name] = this.tasksCountHash['CREATED'][element.name] + element.tasks_count;

          if (!this.tasksCountHashByEntity['CREATED'][element.entity_type]) {
            this.tasksCountHashByEntity['CREATED'][element.entity_type] = 0;
          }
          this.tasksCountHashByEntity['CREATED'][element.entity_type] = this.tasksCountHashByEntity['CREATED'][element.entity_type] + element.tasks_count;
        }

      });
      if (this.selectedGlobalActivityIndex != 'ALL TASKS') {
        this.createdTasksCount = orgActiveCount;
        this.priorityTasksCount = orgPriorityCount;
        this.todaysTasksCount = orgTodaysCount;
      }
   //  console.log(this.tasksCountHash);
   //  console.log(this.tasksCountHashByEntity);
    }
  }
  getAllTaskCount() {
    if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
      return this.completedTasksCount;

    }
    if (this.selectedGlobalActivityIndex == 'ALL TASKS') {
      return this.createdTasksCount;
    }
  }
  getTaskCountByEntity(aTaskName) {
    if (this.tasksCountHashByEntity) {
      if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
        if (this.tasksCountHashByEntity['COMPLETE'] && this.tasksCountHashByEntity['COMPLETE'][aTaskName]) {
          return this.tasksCountHashByEntity['COMPLETE'][aTaskName];
        }
      }
      else {
        if (this.tasksCountHashByEntity['CREATED'] && this.tasksCountHashByEntity['CREATED'][aTaskName]) {
          return this.tasksCountHashByEntity['CREATED'][aTaskName];
        }
      }
    }
    return 0;
  }
  getSingleTaskCount(aTaskName) {
    if (this.tasksCountHash) {
      if (this.selectedGlobalActivityIndex == 'TASKS DONE') {
        if (this.tasksCountHash['COMPLETE'] && this.tasksCountHash['COMPLETE'][aTaskName]) {
          return this.tasksCountHash['COMPLETE'][aTaskName];
        }
      }
      else {
        if (this.tasksCountHash['CREATED'] && this.tasksCountHash['CREATED'][aTaskName]) {
          return this.tasksCountHash['CREATED'][aTaskName];
        }
      }
    }
    return 0;
  }
  getTasksCount(aTaskNames) {
    let taskCount = 0;
    if (Array.isArray(aTaskNames)) {
      aTaskNames.forEach(element => {
        taskCount = taskCount + this.getSingleTaskCount(element)
      });
      return taskCount;
    }
    return this.getSingleTaskCount(aTaskNames);
    return 0;
  }
  checkTask(aTaskNames) {
    const taskCount = this.getTasksCount(aTaskNames);
    if (taskCount > 0) {
      return true;
    }
    return false;
  }

  getResponseTasksCounts() {
 //  console.log('calling getResponseTasksCounts');
    // console.log(this.mergeWithSearch());

    return this.tasksService.getCounts(this.mergeWithSearch()).subscribe(
      response => {
     //  console.log(response);
        this.tasksCount = response.data;
        this.recalculateTasksCount();
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }

  openVendorPaymentList(){
 //  console.log("payment List open Clicked........!")
    this.ListExpandFlag = true;
    let paymentFilter = {task_name: this.paymentTasks,
      entity_type: ['Trip', 'Truck Hire Challan'],
      partners_id: this.task.brokers_id}
    ;
    return this.tasksService.getList(paymentFilter).subscribe(
      response => {
     //  console.log(response);
        this.vendorPaymentList = Tasks.toArray(response.data);
        // console.log(response);
        // this.tasksList = Tasks.toArray(response.data);
        //data = this.tripsList;
        // console.log(this.tasksList);
        // this.tasksDataSource = new TasksDataSource(this.tasksList);
        // this.total = response.total;
        // this.per_page = response.per_page;
        // this.currentPage = response.current_page;
        // this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
        // this.doPostResponse(response);
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }

  closeVendorPaymentList(){
 //  console.log("payment List close Clicked........!")
    this.ListExpandFlag = false;
  }
}


export class TasksDataSource extends DataSource<any> {
  data: any;
  constructor(aData?: Tasks[]) {
    super();
    this.data = aData;
  }
  connect(): Observable<Tasks[]> {
    return of(this.data);
  }
  getIndex(id) {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id == id) {
        return index;
      }

    }
    return -1;
  }
  remove(id) {
    const itemIndex = this.getIndex(id);
 //  console.log('found ' + id + ' at ' + itemIndex);
    this.data = this.data.splice(itemIndex, 1);
 //  console.log('removed');
  }
  disconnect() { }
}
