import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TripsService } from '../services/trips.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { Trips } from '../trips';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../core/config';
import { Trucks } from '../../trucks/trucks';
import { Drivers } from '../../drivers/drivers';
import { ConsignmentNotes } from '../../consignmentNotes/consignmentNotes';
import { Partners } from '../../partners/partners';
import { TruckHireChallans } from '../../truckHireChallans/truckHireChallans';
import { TruckHireChallansService } from '../../truckHireChallans/services/truckHireChallans.service';
import { TrucksService } from '../../trucks/services/trucks.service';
import { DriversService } from '../../drivers/services/drivers.service';
import { PartnersService } from '../../partners/services/partners.service';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import * as appGlobals from '../../../core/globalfunctions';
import { NgxImgZoomService } from 'ngx-img-zoom';
@Component({
  selector: 'app-ngx-plain-gallery-page',
  templateUrl: './plain.component.html',
  styleUrls: ['./plain.component.css'],
  providers: [
    TripsService,
    DialogService,
    TruckHireChallansService,
    TrucksService,
    PartnersService,
    DriversService,
    ConsignmentNotesService,
    NgxImgZoomService,
    // UsersService
  ]
})
export class Gallery2PlainComponent implements OnInit {
  @Input() trips_id: number;
  @Input() truck_hire_challans_id: number;
  @Input() trucks_id: number;
  @Input() partners_id: number;
  @Input() brokers_id: number;
  @Input() drivers_id: number;
  @Input() consignment_notes_id: number;

  imageIndex = 1;
  trips: Trips;
  imageUrl: String = AppSettings.IMAGE_ENDPOINT;
  driverImages: any[] = [];
  cnImages: any[] = [];
  bImages: any[] = [];
  oImages: any[] = [];
  images: any[] = [];
  fileURLs: any[] = [];

  canvasWidth = 800;
  canvasHeight = 600;
  imageSrc = '';
  page = 1;
  // array with a single image inside (the first one)
  singleImage: any[] = []; // = [this.images[0]];

  imagesInfiniteAutoAdd: any[]; // = [this.images[0]];
  private count = 0;
  // this variable is used only for example of auto navigation
  isShownAutoNavigate = false;
  fileURL: string;
  truckHireChallan: TruckHireChallans;
  truck: Trucks;
  driver: Drivers;
  consignmentNote: ConsignmentNotes;
  broker: Partners;
  partner: Partners;

  constructor(
    // private galleryService: GalleryService,
    private sanitizer: DomSanitizer,
    private tripsService: TripsService,
    private truckHireChallansService: TruckHireChallansService,
    private trucksService: TrucksService,
    private driversService: DriversService,
    private partnersService: PartnersService,
    private consignmentNotesService: ConsignmentNotesService,

    private _dialogService: DialogService,
    private route: ActivatedRoute,
    private ngxImgZoom: NgxImgZoomService
  ) {}
  ngOnInit() {
    this.route.params.subscribe(params => {
      if (+params.tripsId) {
        this.trips_id = +params.tripsId;
      }
      if (+params.truckHireChallansId) {
        this.truck_hire_challans_id = +params.truckHireChallansId;
      }
      if (+params.trucksId) {
        this.trucks_id = +params.trucksId;
     //  console.log('setting trucksId ' + this.trucks_id);
      }
      if (+params.driversId) {
        this.drivers_id = +params.driversId;
     //  console.log('setting drivers_id ' + this.drivers_id);
      }
      if (+params.partnersId) {
        this.partners_id = +params.partnersId;
     //  console.log('setting partners_id ' + this.partners_id);
      }
      if (+params.brokersId) {
        this.brokers_id = +params.brokersId;
     //  console.log('setting brokers_id ' + this.brokers_id);
      }
      if (+params.consignmentNotesId) {
        this.consignment_notes_id = +params.consignmentNotesId;
     //  console.log('setting consignmentNotesId ' + this.consignment_notes_id);
      }
      // if(params.id){
      //  console.log('user id is supplied ' + params.id);
      //   this.usersId = params.id;
      //   // this.layoutType = 'polyline';
      // }
      this.getResponse();
    });
    this.ngxImgZoom.setZoomBreakPoints([{w: 100, h: 100}, {w: 150, h: 150}, {w: 200, h: 200}, {w: 250, h: 250}, {w: 300, h: 300}]);

  }
  getResponse() {
    if (this.trips_id) {
      return this.tripsService.getTripsById(this.trips_id).subscribe(
        response => {
          // console.log(response.data);
          this.trips = new Trips(response.data);
          this.setTripDependents();
          this.initializeImages('truck');
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    } else if (this.truck_hire_challans_id) {
      return this.truckHireChallansService.getTruckHireChallansById(this.truck_hire_challans_id).subscribe(
        response => {
          // console.log(response.data);
          this.truckHireChallan = new TruckHireChallans(response.data);
          this.setThcDependents();
          //this.initializeImages('thc');
          this.initializeImages('truck');
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    } else if (this.trucks_id) {
      return this.trucksService.getTrucksById(this.trucks_id).subscribe(
        response => {
          // console.log(response.data);
          this.truck = new Trucks(response.data);
          this.setTruckDependents();
          this.initializeImages('truck');
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    } else if (this.drivers_id) {
      return this.driversService.getDriversById(this.drivers_id).subscribe(
        response => {
          // console.log(response.data);
          this.driver = new Drivers(response.data);
          this.setDriverDependents();
          this.initializeImages('driver');
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    } else if (this.consignment_notes_id) {
      return this.consignmentNotesService.getConsignmentNotesById(this.consignment_notes_id).subscribe(
        response => {
          // console.log(response.data);
          this.consignmentNote = new ConsignmentNotes(response.data);
          this.setCnDependents();
          this.initializeImages('consignment');
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }

  }
  initializeImages(aType) {
    const aEvent = <any>[];
    aEvent['value'] = aType;
    this.showImages(aEvent);
    if (this.images[0]) {
      this.singleImage = [this.images[0]];
      this.imagesInfiniteAutoAdd = [this.images[0]];
    }
  }
  setThcDependents() {
    this.truck_hire_challans_id = this.truckHireChallan.id;
    if (this.truckHireChallan.truck) {
      this.truck = this.truckHireChallan.truck;
      this.setTruckDependents();
    }
    if (this.truckHireChallan.broker) {
      this.broker = this.truckHireChallan.broker;
    }
    if (this.truckHireChallan.partners) {
      this.partner = this.truckHireChallan.partners;
    }

  }
  setTruckDependents() {
    this.trucks_id = this.truck.id;
    if (this.truck.drivers && this.truck.drivers[0]) {
      this.driver = this.truck.drivers[0];
      this.setDriverDependents();
    }
  }
  setDriverDependents() {
    this.drivers_id = this.driver.id;
  }
  setCnDependents() {
    this.consignment_notes_id = this.consignmentNote.id;
  }
  setTripDependents() {
    if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
      this.truckHireChallan = this.trips.truck_hire_challans[0];
      this.setThcDependents();
    }
    if (this.trips.consignment_trips[0] && this.trips.consignment_trips[0].consignment_note) {
      // this.consignmentNote = this.trips.consignment_notes[0];
      this.consignmentNote = this.trips.consignment_trips[0].consignment_note;
      this.setCnDependents();
    }
  }
  display_postcard(index) {
    // console.log(this.images[index]);
    window.open(this.images[index].url, '_blank');
  }
  getPDFContent(fileName, index) {
    this.tripsService.getFile(fileName).subscribe(
    response => {
   //  console.log(response);
      const file = new Blob([response], {type: 'application/pdf'});
      this.fileURLs[index] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
   //  console.log(this.fileURLs[index]);
    }
    );
  }
  isFilePDF(aUrl) {
    return aUrl.split('.').pop() === 'pdf';
  }
  getSanitizedUrl(aFileName) {
    // console.log(aFileName);
    // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(aFileName));
    return this.sanitizer.bypassSecurityTrustResourceUrl(aFileName);
  }
  showImages(aEvent: [] = <any>[]) {
    // console.log(aEvent);
    const aType = aEvent['value'];
    if (aType === 'truck' && this.truck ) {
        this.allTruckDocumentImages(this.truck);
    }
    if (aType === 'driver' && this.driver) {
        this.allDriverDocumentImages(this.driver);
    }
    if (aType === 'consignment' && this.consignmentNote) {
        this.allCnDocumentImages(this.consignmentNote);
    }
    if (aType === 'broker' && this.broker) {
        this.allBrokerDocumentImages(this.broker);
    }
    if (aType === 'owner' && this.partner) {
        this.allOwnerDocumentImages(this.partner);

    }
    if (aType === 'thc' && this.truckHireChallan) {
      this.allThcDocumentImages(this.truckHireChallan);

  }
    // this.loadPDFs();
    // console.log(this.images);
  }
  loadPDFs() {
    for (let i = 0; i < this.images.length; i++) {
      this.fileURLs[i] = this.images[i].url;
      if (this.images[i].isPDF) {
        this.getPDFContent(this.images[i].url, i);
      }
    }
  }
  allCnDocumentImages(aData: ConsignmentNotes) {
    // if (!this.cnImages) {
      this.cnImages = [];
    // }
    if (aData.consignment_scan) {
      this.cnImages.push({label: 'Consignment Note', url:
         this.imageUrl + aData.consignment_scan,  isPDF: this.isFilePDF(aData.consignment_scan)});

    }
    if (aData.pod_scan) {
      this.cnImages.push({label: 'POD', url: this.imageUrl + aData.pod_scan, isPDF: this.isFilePDF(aData.pod_scan)});
    }
    if (aData.pod_scan_2) {
      this.cnImages.push({label: 'POD2', url: this.imageUrl + aData.pod_scan_2, isPDF: this.isFilePDF(aData.pod_scan_2)});

    }
    if (aData.invoice_scan) {
      this.cnImages.push({label: 'Invoice', url: this.imageUrl + aData.invoice_scan, isPDF: this.isFilePDF(aData.invoice_scan)});
    }
    if (aData.truck_scan) {
      this.cnImages.push({label: 'Trailer', url: this.imageUrl + aData.truck_scan, isPDF: this.isFilePDF(aData.truck_scan)});
    }
    if (aData.empty_letter_scan) {
      this.cnImages.push({label: 'Empty Letter', url: this.imageUrl + aData.empty_letter_scan,
      isPDF: this.isFilePDF(aData.empty_letter_scan)});
    }
    if (aData.eway_bill_scan) {
      this.cnImages.push({label: 'eWay', url: this.imageUrl + aData.eway_bill_scan, isPDF: this.isFilePDF(aData.eway_bill_scan)});
    }
    this.images = this.cnImages;
 //  console.log(this.images);

  }
  allThcDocumentImages(aData: TruckHireChallans) {
    // if (!this.bImages) {
      this.bImages = [];
    // }
    if (aData.vendor_slip_scan) {
      this.bImages.push({label: 'Vendor Slip', url: this.imageUrl + aData.vendor_slip_scan, isPDF: this.isFilePDF(aData.vendor_slip_scan)});
    }
    this.images = this.bImages;
  }
  allBrokerDocumentImages(aData: Partners) {
    // if (!this.bImages) {
      this.bImages = [];
    // }
    if (aData.pan_card_scan) {
      this.bImages.push({label: 'PAN Card', url: this.imageUrl + aData.pan_card_scan, isPDF: this.isFilePDF(aData.pan_card_scan)});
    }
    if (aData.tds_declaration_scan) {
      this.bImages.push({label: 'TDS', url: this.imageUrl + aData.tds_declaration_scan, isPDF: this.isFilePDF(aData.tds_declaration_scan)});
    }
    this.images = this.bImages;
  }
  allOwnerDocumentImages(aData: Partners) {
    // if (!this.oImages) {
      this.oImages = [];
    // }
    if (aData.pan_card_scan) {
      this.oImages.push({label: 'PAN', url: this.imageUrl + aData.pan_card_scan, isPDF: this.isFilePDF(aData.pan_card_scan)});
    }
    if (aData.tds_declaration_scan) {
      this.oImages.push({label: 'TDS', url: this.imageUrl + aData.tds_declaration_scan, isPDF: this.isFilePDF(aData.tds_declaration_scan)});
    }
    this.images = this.oImages;
  }
  allDriverDocumentImages(driverData: Drivers) {
    // if (!this.images) {
      this.images = new Array<any>();
    // }
    // if (!this.driverImages) {
      this.driverImages = new Array<any>();
    // }
    if (driverData.driving_license_scan) {
      this.driverImages.push({label: 'DL', url: this.imageUrl + driverData.driving_license_scan,
      isPDF: this.isFilePDF(driverData.driving_license_scan)});
    }
    this.images = this.driverImages;
  }
  allTruckDocumentImages(truckData: Trucks) {
    // if (!this.images) {
      this.images = new Array<any>();
    // }
    const aData = truckData;
    if (truckData.registration_scan) {
      this.images.push({label: 'RC', url: this.imageUrl + truckData.registration_scan, isPDF: this.isFilePDF(aData.registration_scan)});
    }
    if (truckData.pollution_scan) {
      this.images.push({label: 'PUC', url: this.imageUrl + truckData.pollution_scan, isPDF: this.isFilePDF(aData.pollution_scan)});
    }
    if (truckData.permit_scan) {
      this.images.push({label: 'Permit', url: this.imageUrl + truckData.permit_scan, isPDF: this.isFilePDF(aData.permit_scan)});
    }
    if (truckData.insurance_scan) {
      this.images.push({label: 'Insurance', url: this.imageUrl + truckData.insurance_scan, isPDF: this.isFilePDF(aData.insurance_scan)});
    }
    if (truckData.fitness_scan) {
      this.images.push({label: 'Fitness', url: this.imageUrl + truckData.fitness_scan, isPDF: this.isFilePDF(aData.fitness_scan)});
    }
  }
}
