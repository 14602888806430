import {Users} from '../users/users';
import { Trucks } from '../trucks-nearby/trucks';
import { TruckDrivers } from '../truckDrivers/truckDrivers';
import { Validators } from '@angular/forms';
import { pan_pattern, safeDate } from '../../core/globalfunctions';
export class Drivers {
  id : number;
  driving_license_scan : string;
  driving_license_no : string;
  driving_license_expiry_dt : Date;
  aadhaar_card_no : string;
  aadhaar_card_scan : string;
  driving_license_issuing_authority : string;
  driving_license_issue_dt : Date;
  pan_no : string;
  pan_card_scan : string;
  street : string;
  map_locality_street: string;
  vicinity: string;
  place_name: string;
  place_id: string;

  city : string;
  state_cd : string;
  pincode : string;
  permanent_address : string;
  name_alias : string;
  emergency_contact_name : string;
  emergency_contact_phone : string;
  highest_education_qualification_cd : string;
  languages_cd : string;
  years_of_experience : number;
  kms_driven : number;
  police_verification_dt : Date;
  police_verfication_place : string;
  police_verification_scan : string;
  police_verification_remarks : string;
  life_insurance_provider : string;
  life_insurance_amt : number;
  life_insurance_issue_dt : Date;
  life_insurance_expiry_dt : Date;
  health_insurance_provider : string;
  health_insurance_amt : number;
  health_insurance_issue_dt : Date;
  health_insurance_expiry_dt : Date;
  accident_history : string;
  behaviour_assessment_remarks : string;
  behaviour_assessment_scan : string;
  general_remarks : string;
  medical_fitness_report_scan : string;
  routes : string;
  is_active : number;
  operational_status_cd : string;
  verified_by : number;
  verified_at : Date;
  base_location : string;
  last_location_long : number;
  last_location_lat : number;
  current_location : string;
  bank_account_no : string;
  bank_name : string;
  bank_ifsc_cd : string;
  users_id : number;
  verification_status: boolean;
  user : Users;
  trucks: Trucks[];
  truck_drivers :TruckDrivers[];
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Drivers[] {
        let drivers : Drivers[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                drivers.push(new Drivers(json));
            }
        }
        return drivers;
    }
    public fill(json: any){
      if (json != null) {
        this.id = json.id;
        this.driving_license_scan = json.driving_license_scan;
        this.driving_license_no = json.driving_license_no;
        safeDate(this, json,  'driving_license_expiry_dt');
        safeDate(this, json,  'driving_license_issue_dt');
        safeDate(this, json,  'police_verification_dt');
        safeDate(this, json,  'life_insurance_issue_dt');
        safeDate(this, json,  'life_insurance_expiry_dt');
        safeDate(this, json,  'health_insurance_issue_dt');
        safeDate(this, json,  'health_insurance_expiry_dt');
        safeDate(this, json,  'verified_at');

        this.aadhaar_card_no = json.aadhaar_card_no;
        this.aadhaar_card_scan = json.aadhaar_card_scan;
        this.driving_license_issuing_authority = json.driving_license_issuing_authority;
        this.pan_no = json.pan_no;
        this.pan_card_scan = json.pan_card_scan;
        this.street = json.street;
        this.map_locality_street = json.map_locality_street;
        this.vicinity = json.vicinity;
        this.place_name = json.place_name;
        this.place_id = json.place_id;

        this.city = json.city;
        this.state_cd = json.state_cd;
        this.pincode = json.pincode;
        this.permanent_address = json.permanent_address;
        this.name_alias = json.name_alias;
        this.emergency_contact_name = json.emergency_contact_name;
        this.emergency_contact_phone = json.emergency_contact_phone;
        this.highest_education_qualification_cd = json.highest_education_qualification_cd;
        this.languages_cd = json.languages_cd;
        this.years_of_experience = json.years_of_experience;
        this.kms_driven = json.kms_driven;
        this.police_verfication_place = json.police_verfication_place;
        this.police_verification_scan = json.police_verification_scan;
        this.police_verification_remarks = json.police_verification_remarks;
        this.life_insurance_provider = json.life_insurance_provider;
        this.life_insurance_amt = json.life_insurance_amt;
        this.health_insurance_provider = json.health_insurance_provider;
        this.health_insurance_amt = json.health_insurance_amt;
        this.accident_history = json.accident_history;
        this.behaviour_assessment_remarks = json.behaviour_assessment_remarks;
        this.behaviour_assessment_scan = json.behaviour_assessment_scan;
        this.general_remarks = json.general_remarks;
        this.medical_fitness_report_scan = json.medical_fitness_report_scan;
        this.routes = json.routes;
        this.is_active = json.is_active;
        this.operational_status_cd = json.operational_status_cd;
        this.verification_status = json.verification_status;
        this.verified_by = json.verified_by;
        this.base_location = json.base_location;
        this.last_location_long = json.last_location_long;
        this.last_location_lat = json.last_location_lat;
        this.current_location = json.current_location;
        this.bank_account_no = json.bank_account_no;
        this.bank_name = json.bank_name;
        this.bank_ifsc_cd = json.bank_ifsc_cd;
        this.users_id = json.users_id;
        if (json.user != null) {
            this.user = new Users(json.user);
        }
        if(json.trucks){
          this.trucks = Trucks.toArray(json.trucks);
        }
    }
    }
}
export let driversFormControls = {
    id: ['', []],
    driving_license_scan: ['', []],
    driving_license_scan_url: ['',[]],
    aadhaar_card_scan_url: ['',[]],
    driving_license_no: ['', []], //TODO: required?Validators.required
    driving_license_expiry_dt: ['', []],
    aadhaar_card_no: ['', []],
    aadhaar_card_scan: ['', []],
    driving_license_issuing_authority: ['', []],
    driving_license_issue_dt: ['', []],
    pan_no: ['', [Validators.pattern(pan_pattern)]],
    pan_card_scan: ['', []],
    pan_card_scan_url: ['', []],
    street: ['', []],
    map_locality_street: ['', []],
    vicinity: ['', []],
    place_name: ['', []],
    place_id: ['', []],

    city: ['', []],
    state_cd: ['', []],
    pincode: ['', []],
    permanent_address: ['', []],
    emergency_contact_name: ['', []],
    emergency_contact_phone: ['', []],
    highest_education_qualification_cd: ['', []],
    languages_cd: ['', []],
    years_of_experience: ['', []],
    kms_driven: ['', []],
    police_verification_dt: ['', []],
    police_verfication_place: ['', []],
    police_verification_scan: ['', []],
    police_verification_scan_url: ['', []],
    police_verification_remarks: ['', []],
    life_insurance_provider: ['', []],
    life_insurance_amt: ['', []],
    life_insurance_issue_dt: ['', []],
    life_insurance_expiry_dt: ['', []],
    health_insurance_provider: ['', []],
    health_insurance_amt: ['', []],
    health_insurance_issue_dt: ['', []],
    health_insurance_expiry_dt: ['', []],
    accident_history: ['', []],
    behaviour_assessment_remarks: ['', []],
    behaviour_assessment_scan: ['', []],
    behaviour_assessment_scan_url: ['', []],
    general_remarks: ['', []],
    medical_fitness_report_scan: ['', []],
    medical_fitness_report_scan_url: ['', []],
    routes: ['', []],
    operational_status_cd: ['', []],
    verified_by: ['', []],
    verified_at: ['', []],
    verification_status: [0, []],
    is_active: [0, []],
    base_location: ['', []],
    last_location_long: ['', []],
    last_location_lat: ['', []],
    current_location: ['', []],
    bank_account_no: ['', []],
    bank_name: ['', []],
    bank_ifsc_cd: ['', []],
    users_id: ['', []],
    trucks_id : ['', []],
    start_dt : ['', []],
    end_dt : ['', []],
    registration_no:['',[]],
    address:['',[]],
    };
