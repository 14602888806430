import {CustomerAddresses} from '../customerAddresses/customerAddresses';
export class CustomerContactAddresses {
    id : number;
    customer_contacts_id : number;
    customer_addresses_id : number;
    customer_address : CustomerAddresses;
    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.customer_contacts_id = json.customer_contacts_id;
            this.customer_addresses_id = json.customer_addresses_id;
            if (json.customer_address != null) {
                this.customer_address = new CustomerAddresses(json.customer_address);
            }
        }
    }
    static toArray(jsons : any[]) : CustomerContactAddresses[] {
        let customerContactAddresses : CustomerContactAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                customerContactAddresses.push(new CustomerContactAddresses(json));
            }
        }
        return customerContactAddresses;
    }
}
export let customerContactAddressesFormControls = {
  id: ['', []],
  customer_addresses_id: ['', []],
  customer_contacts_id: ['', []],
};
