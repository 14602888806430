import { BidCounts } from "../bids/bid-counts";
import { Validators } from "@angular/forms";
import { Bids } from "../bids/bids";
import * as appGlobals from '../../core/globalfunctions';
export class BidDetails {
    id : number;
    bid_no : string;
    bid_name : string;
    no_of_users : number;
    lowest_bid : string;
    winner : number;
    source_location : string;
    source_city_cd : string;
    destination_location : string;
    destination_city_cd : string;
    truck_type_cd : string;
    goods_type_cd : string;
    weight_cd : string;
    bid_start : Date;
    bid_end : Date;
    customers_orders : number;
    bid_status_cd : string;
    created_date : Date;
    bookings_id : number;
    trips_id : number;
    pick_up_date : Date;
    no_of_trucks : number;
    booking_for_slot_cd : string;
    bid_start_time : string;
    bid_end_time : string;
    bids_metrics_cd: string;
    decrement_by: number;
    booking_no: string;
    trip_no: string;
    start_price: number;
    suggested_price: number;
    created_by: number;
    modified_by: number;
    bidcounts: BidCounts[];
    biduserlists: Array<any>;
    biduserlists_count: number;
    bidcounts_count: number;
    winning_bids: Bids[];
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : BidDetails[] {
        let bidDetails : BidDetails[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                bidDetails.push(new BidDetails(json));
            }
        }
        return bidDetails;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.bid_no = json.bid_no;
            this.bid_name = json.bid_name;
            this.no_of_users = json.no_of_users;
            this.lowest_bid = json.lowest_bid;
            this.winner = json.winner;
            this.source_location = json.source_location;
            this.source_city_cd = json.source_city_cd;
            this.destination_location = json.destination_location;
            this.destination_city_cd = json.destination_city_cd;
            this.truck_type_cd = json.truck_type_cd;
            this.goods_type_cd = json.goods_type_cd;
            this.weight_cd = json.weight_cd;
            appGlobals.safeDate(this, json,  'bid_start');
            // if (json.bid_start != null) {
            //     this.bid_start = new Date(json.bid_start.replace(/-/g, "/"));
            // }
            appGlobals.safeDate(this, json,  'bid_end');
            // if (json.bid_end != null) {
            //     this.bid_end = new Date(json.bid_end.replace(/-/g, "/"));
            // }
            this.customers_orders = json.customers_orders;
            this.bid_status_cd = json.bid_status_cd;
            appGlobals.safeDate(this, json,  'created_date');
            this.bookings_id = json.bookings_id;
            this.trips_id = json.trips_id;
            appGlobals.safeDate(this, json,  'pick_up_date');
            // if (json.pick_up_date != null) {
            //     this.pick_up_date = new Date(json.pick_up_date.replace(/-/g, "/"));
            // }
            this.no_of_trucks = json.no_of_trucks;
            this.booking_for_slot_cd = json.booking_for_slot_cd;
            this.bid_start_time = json.bid_start_time;
            this.bid_end_time = json.bid_end_time;
            this.bids_metrics_cd = json.bids_metrics_cd;
            this.decrement_by = json.decrement_by;
            this.booking_no = json.booking_no;
            this.trip_no = json.trip_no;
            this.start_price = json.start_price;
            this.suggested_price = json.suggested_price;
            this.created_by = json.created_by;
            this.modified_by = json.modified_by;
            if (json.bidcounts != null) {
                // this.bidcounts = BidCounts.toArray(json.bidcounts);
                this.bidcounts = json.bidcounts;
            }
            if (json.biduserlists != null) {
                // this.bidcounts = BidCounts.toArray(json.bidcounts);
                this.biduserlists = json.biduserlists;
            }
            this.biduserlists_count = json.biduserlists_count;
            this.bidcounts_count = json.bidcounts_count;
            if (json.winning_bids != null) {
                // this.bidcounts = BidCounts.toArray(json.bidcounts);
                this.winning_bids = Bids.toArray(json.winning_bids);
            }
        }
    }

}
export let bidDetailsFormControls = {
    id: ['', []],
      bid_name: ['', [Validators.required]],
      bid_status_cd: ['Draft', [Validators.required]],
      lowest_bid: ['', []],
      bid_start: ['', [Validators.required]],
      bid_start_time: ['', [Validators.required]],
      bid_end: ['', [Validators.required]],
      bid_end_time: ['', [Validators.required]],
      source_location: ['', []],
      destination_location: ['', []],
      truck_type_cd: ['', [Validators.required]],
      goods_type_cd: ['', []],
      weight_cd: ['', []],
      no_of_trucks: [1, [Validators.required]],
      pick_up_date: ['', [Validators.required]],
      booking_for_slot_cd: ['', [Validators.required]],
      bookings_id: ['', []],
      booking_no: ['', []],
      trips_id: ['', []],
      trip_no: ['', []],
      start_price: ['', [Validators.required]],
      suggested_price: ['', []],

      bids_metrics_cd:  ['TRUCK', [Validators.required]],
      decrement_by:  [100, [Validators.required]],
      bid_no: ['', []],
      no_of_users: [0, []],
      winner: ['', []],
      source_city_cd: ['', []],
      destination_city_cd: ['', []],
      customers_orders: ['', []],
      created_date: ['', []],
      created_by: ['', []],
      modified_by: ['', []],

    };
