import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'
import { ResponseModel } from '../../../core/model/response';
import { MatDialog } from '@angular/material/dialog';
@Injectable()
export class TasksService {

  constructor(private serviceHttp:HttpService) { }
  getCounts(data) : Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/counts', 'POST', 0, data);
    // throw new Error('Method not implemented.');
  }
  getList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/page', 'POST', 0, data);
  }
  getIndentsList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/indents', 'POST', 0, data);
  }
  getListAll(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/listAll', 'POST', 0, data);
  }
  search(data ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/search', 'POST', 0, data);
  }
  getTaskById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('tasks/'+ id, 'GET');
  }
  addTask(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('tasks', 'POST', 0, data);
  }
  updateTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/', 'POST', 0, data);
  }
  reassignTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/reassign', 'POST', 0, data);
  }
  changeStatus(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/changeStatus', 'POST', 0, data);
  }
  escalateTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/escalate', 'POST', 0, data);
  }
  resolveTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/resolve', 'POST', 0, data);
  }
  cancelTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/cancel', 'POST', 0, data);
  }
  completeTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/complete', 'POST', 0, data);
  }
  claimTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/claim', 'POST', 0, data);
  }
  unClaimTask(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('tasks/unclaim', 'POST', 0, data);
  }
}
