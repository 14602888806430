import { Injectable, SkipSelf, Optional, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
@Injectable()
export class DialogService {
  _dialogService;
  constructor(_dialogService: MatDialog) {
    this._dialogService = _dialogService;
   }
  open(component, config) {
    return this._dialogService.open(component, config);
  }
  closeAll(){
    this._dialogService.closeAll();
  }
  openAlert (config) {
    const dialogConfig = this._createConfig(config);
    const dialogRef = this._dialogService.open(AlertDialogComponent, dialogConfig);
    const alertDialogComponent = dialogRef.componentInstance;
    alertDialogComponent.title = config.title;
    alertDialogComponent.message = config.message;
    alertDialogComponent.flag = config.flag;
    if (config.closeButton) {
        alertDialogComponent.closeButton = config.closeButton;
    }
    return dialogRef;
  }
  _createConfig (config) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.viewContainerRef = config.viewContainerRef;
    dialogConfig.disableClose = config.disableClose;
    return dialogConfig;
}
}
