import { CustomerAddresses } from '../customerAddresses/customerAddresses';
import { Bookings } from '../bookings/bookings';
import { CustomerContacts } from '../customerContacts/customerContacts';
import { Validators } from '@angular/forms';
import * as appGlobals from './../../core/globalfunctions';

export class Customers {
    id : number;
    webxpress_customer_code : string;
    name : string;
    pan_no : string;
    turnover : number;
    territory_cd : string;
    account_executive_id : number;
    operations_executive_id : number;
    operations_executive_name: string;
    service_executive_id : number;
    operational_status_cd : string;
    customers_status_cd: string;
    truck_min_guarantee : number;
    truck_type_cd : Array<String>;
    business_type_cd: string;
    booking_type_cd: string;
    service_type_cd : string;
    trip_type_cd = 'Long';
    container_type_cd : string;
    goods_type_cd : string;
    key_market_cd : string;
    customer_tier_cd : string;
    customer_type_cd : string;
    payment_basis_cd : string;
    package_type_cd : string;
    dimension_type_cd : string;
    credit_period_days : number;
    credit_limit : number;
    available_credit_limit : number;
    truck_min_guarantee_monthly : number;
    insured_by_cd : string;
    parent_company : number;
    current_vendors : string;
    logistics_budget : number;
    warehousing_budget : number;
    distribution_budget : number;
    company_type_cd : string;
    credit_rating : number;
    gst_no : string;
    pan_card_scan : string;
    products_list: string;
    verified_by : number;
    verified_at : Date;
    is_active : number;
    billing_executive_id: number;
    billing_executive_name: string;

    booking_branch_cd: string;
    billing_branch_cd: string;
    account_executive_name: string;
    service_executive_name: string;
    is_skip_eway = false;
    name_short: string;
   customer_contacts : CustomerContacts[];
   epod_allowed: number;
   website_address: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Customers[] {
        let customers : Customers[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                customers.push(new Customers(json));
            }
        }
        return customers;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.webxpress_customer_code = json.webxpress_customer_code;
            this.name = json.name;
            this.name_short = json.name_short;
            this.pan_no = json.pan_no;
            this.turnover = json.turnover;
            this.territory_cd = json.territory_cd;
            this.account_executive_id = json.account_executive_id;
            this.operations_executive_name = json.operations_executive_name;
            this.operations_executive_id = json.operations_executive_id;
            this.service_executive_id = json.service_executive_id;
            this.operational_status_cd = json.operational_status_cd;
            this.customers_status_cd = json.customers_status_cd;
            this.truck_min_guarantee = json.truck_min_guarantee;
            if(json.truck_type_cd && Array.isArray(json.truck_type_cd)){
                this.truck_type_cd = json.truck_type_cd;
            }
            else if(json.truck_type_cd){
                this.truck_type_cd = json.truck_type_cd.split(',');
            }
            this.business_type_cd = json.business_type_cd;
            this.booking_type_cd = json.booking_type_cd;
            this.service_type_cd = json.service_type_cd;
            if(json.trip_type_cd) {
              this.trip_type_cd = json.trip_type_cd;
            }

            this.container_type_cd = json.container_type_cd;
            this.goods_type_cd = json.goods_type_cd;
            this.key_market_cd = json.key_market_cd;
            this.customer_tier_cd = json.customer_tier_cd;
            this.customer_type_cd = json.customer_type_cd;
            this.payment_basis_cd = json.payment_basis_cd;
            this.package_type_cd = json.package_type_cd;
            this.dimension_type_cd = json.dimension_type_cd;
            this.credit_period_days = json.credit_period_days;
            this.credit_limit = json.credit_limit;
            this.available_credit_limit = json.available_credit_limit;
            this.truck_min_guarantee_monthly = json.truck_min_guarantee_monthly;
            this.insured_by_cd = json.insured_by_cd;
            this.parent_company = json.parent_company;
            this.current_vendors = json.current_vendors;
            this.logistics_budget = json.logistics_budget;
            this.warehousing_budget = json.warehousing_budget;
            this.distribution_budget = json.distribution_budget;
            this.company_type_cd = json.company_type_cd;
            this.credit_rating = json.credit_rating;
            this.gst_no = json.gst_no;
            this.pan_card_scan = json.pan_card_scan;
            this.products_list = json.products_list;
            this.verified_by = json.verified_by;
            appGlobals.safeDate(this, json,  'verified_at');

            this.is_active = json.is_active;
            if(json.customer_contacts){
               this.customer_contacts = CustomerContacts.toArray(json.customer_contacts);
            }
            this.billing_branch_cd = json.billing_branch_cd;
            this.booking_branch_cd = json.booking_branch_cd;
            this.billing_executive_id = json.billing_executive_id;
            this.billing_executive_name = json.billing_executive_name;
            this.account_executive_name = json.account_executive_name;
            this.service_executive_name = json.service_executive_name;
            this.epod_allowed = json.epod_allowed;
            if(json.is_skip_eway) {
              this.is_skip_eway = json.is_skip_eway;
            }
            this.website_address = json.website_address;
        }
    }
}
export let customersFormControls = {
  id: [''],
  name: ['', [Validators.required, Validators.minLength(3)]],
  name_short: ['', [Validators.required, Validators.minLength(2)]],
  pan_no: ['', [Validators.required, Validators.pattern(appGlobals.pan_pattern)]],
  gst_no: ['', [Validators.pattern(appGlobals.gst_pattern)]],
  territory_cd: ['', [Validators.required]],
  payment_basis_cd: ['', [Validators.required]],
  credit_period_days: ['', [Validators.required]],
  truck_min_guarantee: [1, [Validators.required]],
  business_type_cd: ['DRY', [Validators.required]],
  booking_type_cd: ['SPOT', [Validators.required]],
  service_type_cd: ['FTL', [Validators.required]],
  trip_type_cd: ['Long', [Validators.required]],
  container_type_cd: ['DRY', [Validators.required]],
  key_market_cd: ['NATIONAL', []],
  operational_status_cd: ['New', []],
  customers_status_cd: ['', []],
  customer_tier_cd: ['CLASSIC', []],
  credit_limit: [300000, []],
  available_credit_limit: [300000, []],
  credit_rating: ['', []],
  turnover: ['', []],
  goods_type_cd: ['', []],
  is_active: [false, []],
  webxpress_customer_code: ['', []],
  account_executive_id: ['', [Validators.required]],
  operations_executive_id: ['', [Validators.required]],
  service_executive_id: ['', [Validators.required]],
  truck_type_cd: ['', []],
  customer_type_cd: ['', []],
  package_type_cd: ['', []],
  dimension_type_cd: ['', []],
  truck_min_guarantee_monthly: ['', []],
  insured_by_cd: ['', []],
  parent_company: ['', []],
  current_vendors: ['', []],
  logistics_budget: ['', []],
  warehousing_budget: ['', []],
  distribution_budget: ['', []],
  company_type_cd: ['', []],
  pan_card_scan: ['', []],
  products_list: ['', []],
  verified_by: ['', []],
  verified_at: ['', []],
  users_id:['',[]],
  cust_id:['',[]],
  verification_status: [0, []],
  billing_branch_cd: ['', [Validators.required]],
  booking_branch_cd: ['', [Validators.required]],
  billing_executive_id: ['', []],
  billing_executive_name: ['', []],
  account_executive_name: ['', []],
  service_executive_name: ['', []],
  operations_executive_name: ['', []],
  is_skip_eway: [false, []],
  epod_allowed: ['', [Validators.required]],
  website_address: ['', []]
};
