<div class="main-wrapper">
    <div class="sub-wrapper">
      <div class="header-wrapper">
        <div class="left-side-wrapper">
          <div class="indian-oil-logo">
            <img src="/assets/pdf-images/indianoil.svg" alt="" />
          </div>
          <div class="reports-wrapper">
            <div class="reportname-wrapper">
              <span class="report-heading">Reports</span>
            </div>
            <div class="date-selected">
              <strong>28.02.2019 - 05.03.2019</strong> <br />
              <strong>Mumbai</strong>
            </div>
          </div>
        </div>
        <div class="right-side-wrapper">
          <div class="glasswing-logo">
            <img src="/assets/pdf-images/glasswing.svg" alt="" />
          </div>
          <div class="mail-wrapper">
            <span class="email">
              care@glasswing.com
            </span>
          </div>

          <div class="contact-wrapper">
            <span class="contact-no">
              Ph: 1800 121 1636
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="relationship-heading">
        <span><strong> &nbsp;Our Relationship &nbsp;</strong></span>
      </div>
      <div class="bg-grey">
        <div class="row">
          <div class="col-6 d-flex" style="margin-top: 2.5%;">
            <div class="">
              <img src="/assets/pdf-images/start.svg" alt="" />
            </div>
            <h4>
              Our Relationship has <br />
              <b>grown</b> this week!
            </h4>

            <div>
              <img src="/assets/pdf-images/end.svg" alt="" />
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <div class="percentage">
              <h1>8%</h1>
            </div>
            <div class="upward-img">
              <img src="/assets/pdf-images/trading.svg" alt="" style="width: 75%;" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-end">
          <div class="relationship-heading">
            <span class="heading"
              ><strong> &nbsp;Orders This Week &nbsp;</strong></span
            >
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center align-items-end">
          <div>
            <img src="/assets/pdf-images/start.svg" alt="" />
          </div>
          <h6 class="more-orders">
            You have placed <strong> 4 more orders </strong> compared to last
            week!
          </h6>

          <div>
            <img src="/assets/pdf-images/end.svg" alt="" />
          </div>
        </div>
        <div class="col-6"></div>
      </div>
      <div class="bg-grey">
        <div class="row">
          <div class="col-4 d-flex align-items-center justify-content-center">
            <div class="order-summary">
              <p style="margin-left:25%;">Order Summary</p>

              <div class="charts-area">
                <div class="charts">
                  <canvas
                    id="orderSummaryChart"
                    width="350"
                    height="200"
                    #orderSummaryChart
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 d-flex align-items-center justify-content-center">
            <div class="order-summary">
              <p style="margin-left: 25%;">Order Type</p>

              <div class="charts-area">
                <div class="charts">
                  <canvas
                    id="orderTypeChart"
                    width="350"
                    height="200"
                    #orderTypeChart
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-end">
          <div class="relationship-heading">
            <span class="heading"
              ><strong> &nbsp;Placement Success Rate &nbsp;</strong></span
            >
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center align-items-end">
          <div style="margin-top: -5px;">
            <img src="/assets/pdf-images/start.svg" alt="" />
          </div>
          <h6 class="more-orders">
            Seems like we do better at a <strong> 48 hr</strong> time frame!
          </h6>

          <div>
            <img src="/assets/pdf-images/end.svg" alt="" />
          </div>
        </div>
        <div class="col-6"></div>
      </div>
      <div class="bg-grey">
        <div class="row">
          <div class="col-4 d-flex align-items-center justify-content-center">
            <div class="order-summary" style="text-align: center;">
              <p>OVERALL</p>

              <div class="charts-area">
                <div class="charts">
                  <canvas id="overAllChart" width="350" height="200" #overAllChart></canvas>
                  <div class="donut-inner">
                    <span style="margin-bottom: -7%;"> <b style="font-size: 35px;">135</b><span style="font-size: 20px;">/ 148</span></span>
                    <span style="font-size: 20px; margin-bottom: 4%;line-height: 1.5;">Vehicle</span>
                    <span
                    style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 0px 10px;
                    margin-left: 15px;
                    margin-right: 15px;
                    "
                  >
                    <img
                      src="/assets/pdf-images/trading.svg"
                      alt=""
                      style="height: 13px;"
                    />
                    <strong>28%</strong>
                  </span> 
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 d-flex align-items-center justify-content-center">
            <div class="order-summary">
              <div class="charts-area">
                <div class="row">
                  <div class="charts" style="display: flex;
                  align-items: center;">
                  <div>
                    <canvas
                    id="progressChartOne"
                    width="250"
                    height="125"
                    #progressChartOne
                  ></canvas>
                  </div>
                    <div style="display: flex;align-items: flex-start;">
                      <div>
                        <strong>WITHIN 48 HRS</strong>
                        <p style="font-weight: 500;">37 / 135 Vehicles</p>
                      </div>
                      <span
                      style="
                        background: #ccc;
                        border-radius: 20px;
                        padding: 2px 10px;
                        margin-left: 10px;
                      "
                    >
                      <img
                        src="/assets/pdf-images/trading.svg"
                        alt=""
                        style="height: 13px;"
                      />
                      <strong>&nbsp;28%</strong>
                    </span> 
               
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="charts"  style="display: flex;
                  align-items: center;">
                 <div>
                  <canvas
                  id="progressChartTwo"
                  width="250"
                  height="125"
                  #progressChartTwo
                ></canvas>
                 </div>
                    <div style="display: flex;align-items: flex-start;">
                      <div>
                        <strong>WITHIN 24 HRS</strong>
                        <p style="font-weight: 500;">37 / 135 Vehicles</p>
                      </div>
                      <span
                      style="
                        background: #ccc;
                        border-radius: 20px;
                        padding: 2px 10px;
                        margin-left: 10px;
                      "
                    >
                      <img
                        src="/assets/pdf-images/trading.svg"
                        alt=""
                        style="height: 13px;"
                      />
                      <strong>&nbsp;28%</strong>
                    </span>
               
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div class="relationship-heading">
            <span class="heading"
              ><strong> &nbsp;OTA(Delivery Performance) &nbsp;</strong></span
            >
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <!-- <div style="margin-top: -5px;">
            <img src="/assets/pdf-images/start.svg" alt="" />
          </div> -->
          <h6 class="more-orders"></h6>

          <!-- <div>
            <img src="/assets/pdf-images/end.svg" alt="" />
          </div> -->
        </div>
        <div class="col-6"></div>
      </div>

      <div class="bg-grey">
        <div class="row">
          <div class="col-6">
            <div
              class="d-flex mb-2 align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center justify-content-around">
                <span>DELAYED:14</span>
                <span
                  style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 2px 10px;
                    margin-left: 10px;
                  "
                >
                  <img src="/assets/pdf-images/down-trading.svg" alt="" />
                  <strong>&nbsp;28%</strong>
                </span>
              </div>
              <div class="ontime">
                <span> ON TIME : 148</span>
              </div>
            </div>

            <div class="d-flex">
              <div>
                <div class="charts-area">
                  <div class="charts" style="width: 600px;">
                    <div
                      class="progress"
                      style="height: 35px; background: #dadada;"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 20%; background: #c1c1c1;"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <!-- <span style="color: red; font-weight: bold;">20%</span> -->
                    </div>
                  </div>
                  <span
                    style="color: #C41A20; font-weight: bold; margin-left: 18%;"
                    >20%</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div style="margin-top: -5px;">
                <img src="/assets/pdf-images/start.svg" alt="" />
              </div>
              <h6 class="more-orders">
                wow, over <strong>80%</strong> of other reach on time!
              </h6>

              <div>
                <img src="/assets/pdf-images/end.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex align-items-center justify-content-center">
              <div class="order-summary" style="width: 100%;">
                <div class="d-flex align-items-center mb-2">
                  <span>DETENTIONS</span>
                  <span
                    style="
                      background: #ccc;
                      border-radius: 20px;
                      padding: 2px 10px;
                      margin-left: 10px;
                    "
                  >
                    <img
                      src="/assets/pdf-images/trading.svg"
                      alt=""
                      style="height: 13px;"
                    />
                    <strong>&nbsp;28%</strong>
                  </span>
                </div>

                <div class="charts-area">
                  <span>Loading:</span>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Haridwar Warehouse</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 12 Days | ₹20,800</span>
                    </div>
                  </div>
                </div>
                <div class="charts-area mt-4">
                  <span>Unloading:</span>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Haridwar Warehouse</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 23 Days | ₹20,800</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Mumbai</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 6 Days | ₹20,800</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Delhi</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 4 Days | ₹20,800</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Banglore</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 1 Day | ₹20,800</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> &nbsp;Others</span>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <span> 1 Day | ₹20,800</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div class="relationship-heading">
            <span class="heading"><strong> &nbsp;POD's &nbsp;</strong></span>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center"></div>
        <div class="col-6"></div>
      </div>

      <div class="bg-grey">
        <div class="row">
          <div class="col-6">
            <div
              class="d-flex mb-2 align-items-center justify-content-between"
              style="width: Tvw;"
            >
              <div class="d-flex align-items-center justify-content-between">
                <span>DEFAULTED POD: 2</span>
                <span
                  style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 2px 10px;
                    margin-left: 10px;
                  "
                >
                  <img src="/assets/pdf-images/down-trading.svg" alt="" />
                  <strong>&nbsp;28%</strong>
                </span>
              </div>
              <div class="ontime">
                <span>TOTAL POD : 10</span>
              </div>
            </div>

            <div class="d-flex">
              <div>
                <div class="charts-area mb-4">
                  <div class="charts" style="width: 90vw;">
                    <div
                      class="progress"
                      style="height: 35px; background: #dadada;"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 20%; background: #c1c1c1;"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <!-- <span style="color: red; font-weight: bold;"
                          >20%</span
                        > -->
                      </div>
                    </div>
                  </div>
                  <span
                    style="color: #C41A20; font-weight: bold; margin-left: 18%;"
                    >20%</span
                  >
                </div>
              </div>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-center">
              <div style="margin-top: -5px;">
                <img src="/assets/pdf-images/start.svg" alt="" />
              </div>
              <h6 class="more-orders">
                You have placed <strong> 4 more orders </strong> compared to
                last week!
              </h6>

              <div>
                <img src="/assets/pdf-images/end.svg" alt="" />
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div
              class="d-flex mb-2 align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center justify-content-around">
                <span>DAMAGE: $480</span>
                <span
                  style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 2px 10px;
                    margin-left: 10px;
                  "
                >
                  <img src="/assets/pdf-images/down-trading.svg" alt="" />
                  <strong>&nbsp;28%</strong>
                </span>
              </div>
              <div class="ontime">
                <span> TOTAL AMOUNT: $4800</span>
              </div>
            </div>

            <div class="d-flex">
              <div>
                <div class="charts-area mb-2">
                  <div class="charts" style="width: 600px;">
                    <div
                      class="progress"
                      style="height: 35px; background: #dadada;"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 20%; background: #c1c1c1;"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <!-- <span style="color: red; font-weight: bold;"
                          >20%</span
                        > -->
                      </div>
                    </div>
                  </div>
                  <span
                    style="color: #C41A20; font-weight: bold; margin-left: 18%;"
                    >20%</span
                  >
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center mt-4"
            >
              <div style="margin-top: -5px;">
                <img src="/assets/pdf-images/start.svg" alt="" />
              </div>
              <h6 class="more-orders">
                There has been a <strong> 20% </strong> damage to your
                deliveries.
              </h6>

              <div>
                <img src="/assets/pdf-images/end.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex align-items-center justify-content-center">
              <div class="order-summary" style="width: 100%;">
                <div
                  class="charts"
                  style="
                    height: 150px;
                    width: 300px;
                    background: #fff;
                    border-radius: 10px;
                    border: none;
                    text-align: center;
                    margin: 42px 0px 0px 130px;
                  "
                >
                  <span>
                    Total revenue: $4800
                  </span>
                  <div
                    class="d-flex align-items-center justify-content-center mt-4"
                  >
                    <span>
                      <h2>28%</h2>
                      <div
                        class="d-flex align-items-center justify-content-center mt-2"
                      >
                        <span
                          style="
                            background: #ccc;
                            border-radius: 20px;
                            padding: 2px 10px;
                            margin-left: 10px;
                          "
                        >
                          <img
                            src="/assets/pdf-images/down-trading.svg"
                            alt=""
                            style="height: 13px;"
                          />
                          <strong>&nbsp;28%</strong>
                        </span>
                      </div>
                    </span>

                    <span
                      class="ml-4"
                      style="text-align: left; margin-top: -25px;"
                    >
                      Debit <br />
                      of total revenue
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div class="relationship-heading">
            <span class="heading"
              ><strong> &nbsp;Payments &nbsp;</strong></span
            >
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center"></div>
        <div class="col-6"></div>
      </div>

      <div class="bg-grey">
        <div class="row">
          <div class="col-6">
            <div class="d-flex">
              <div>
                <div class="charts-area mb-4">
                  <div
                    class="charts"
                    style="
                      height: 120px;
                      width: 90vw;
                      border: 2px solid #ccc;
                      background: #d8d8d8;
                    "
                  >
                    <div class="">
                      <div
                        style="
                          height: 30px;
                          width: 150px;
                          border: 2px solid #ccc;
                          background: #fff;
                          margin-left: 55%;
                        "
                      ></div>
                    </div>
                    <div class="">
                      <div
                        style="
                          height: 30px;
                          width: 150px;
                          border: 2px solid #ccc;
                          background: #fff;
                          position: absolute;
                          bottom: 40px;
                          left: 28px;
                          text-align: center;
                        "
                      >
                        <span style="padding: 5px;"> Overdue</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div
              class="d-flex mb-2 align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center justify-content-around">
                <span>BILLS AGAINST ORDERS PLACES</span>
                <span
                  style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 2px 10px;
                    margin-left: 10px;
                  "
                >
                  <img src="/assets/pdf-images/down-trading.svg" alt="" />
                  <strong>&nbsp;28%</strong>
                </span>
              </div>
            </div>

            <div class="d-flex" style="margin-bottom: 3%;">
              <div>
                <div class="charts-area mb-2">
                  <div class="charts" style="width: 600px;">
                    <div
                      class="progress"
                      style="height: 35px; background: #dadada;"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 20%; background: #c1c1c1;"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <!-- <span style="color: red; font-weight: bold;"
                          >20%</span
                        > -->
                      </div>
                    </div>
                  </div>
                  <span
                    style="color: #C41A20; font-weight: bold; margin-left: 18%;"
                    >20%</span
                  >
                </div>
              </div>
            </div>
            <div class="row" style="font-weight: bold;">
              <div class="col-4" style="margin-left: 30px;">
                <span style="color: #bfbfbf;">TOTAL DETENTIONS</span> <br />
                <h2 style="color: #7b7b7b;">$148</h2>
              </div>

              <div class="col-6">
                <span style="color: #bfbfbf;">TOTAL DEDUCTIONS</span> <br />
                <h2 style="color: #7b7b7b;">$148</h2>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="d-flex mb-2 align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center justify-content-around">
                <span> BILL ACCEPTANCE RATE (FIRST TIME)</span>
                <span
                  style="
                    background: #ccc;
                    border-radius: 20px;
                    padding: 2px 10px;
                    margin-left: 10px;
                  "
                >
                  <img src="/assets/pdf-images/down-trading.svg" alt="" />
                  <strong>&nbsp;28%</strong>
                </span>
              </div>
            </div>

            <div class="d-flex" style="margin-bottom: 3%;">
              <div>
                <div class="charts-area mb-2">
                  <div class="charts" style="width: 600px;">
                    <div
                      class="progress"
                      style="height: 35px; background: #dadada;"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 20%; background: #c1c1c1;"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <!-- <span style="color: red; font-weight: bold;"
                          >20%</span
                        > -->
                      </div>
                    </div>
                  </div>
                  <span
                    style="color: #C41A20; font-weight: bold; margin-left: 18%;"
                    >20%</span
                  >
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              style="margin-top: 6%;"
            >
              <div>
                <img src="/assets/pdf-images/start.svg" alt="" />
              </div>
              <h6 class="more-orders">
                You currently have 3 bills due amounting to
                <strong> $12,33,383 </strong>
              </h6>

              <div>
                <img src="/assets/pdf-images/end.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relationship-wrapper">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div class="relationship-heading">
            <span class="heading"
              ><strong> &nbsp;Exceptions &nbsp;</strong></span
            >
          </div>
        </div>
        <div class="col-6"></div>
      </div>
      <div class="bg-grey">
        <!-- hjjgjjghj -->

        <div class="px-5">
          <h5>
            ACCIDENTS
          </h5>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            <br />
            Ut enim ad minim veniam.
          </p>

          <h5>
            SPECIAL CIRCUMSTANCES
          </h5>

          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur</li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore ,

              <br />
              magna aliqua. Ut enim ad minim veniam.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore <br />
              magna aliqua. Ut enim ad minim veniam.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>