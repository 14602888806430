import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
import { EntityService } from '../../entity/services/entity.service';
@Injectable()
export class InvoicesService extends EntityService{
  entityName = 'invoices';
  constructor(protected serviceHttp:HttpService) {
    super(serviceHttp);
  }

  billstatusreport(data
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponseExcel('billstatusreport', 'POST', 0, data);
  }
  // getList(data
  //   ): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('invoices/page', 'POST', 0, data);
  // }
  // exportList(data): any {
  //   return this.serviceHttp.getResponseExcel('invoices/exportList', 'POST', 0, data);
  // }
  // search(str:string  ,invoices_customers_id?: any ): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('invoices/search', 'POST', 0, {search:str     ,customers_id: invoices_customers_id
  //   });
  // }
  // lookupInvoice(data: any ): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('invoices/lookupInvoice', 'POST', 0, data);
  // }
  // getInvoicesById(id): Observable<ResponseModel> {
  //     return this.serviceHttp.getResponse('invoices/'+ id, 'GET');
  // }
  // addInvoices(data): Observable<ResponseModel> {
  //     return this.serviceHttp.getResponse('invoices', 'POST', 0, data);
  // }
  // updateInvoices(data): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('invoices/' + data.id, 'PUT', 0, data);
  // }
}
