import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../../layout/dialogs/services/dialog.service';
import { getCircularReplacer, getDisplayErrorMessage } from '../globalfunctions';
// import { RollbarService } from './rollbar.service';
// import * as Rollbar from 'rollbar';
@Injectable()
export class ErrorsHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    // @Inject(RollbarService) private rollbar: Rollbar
  ) { }
  handleError(error: Error | HttpErrorResponse | any) {
    const _dialogService = this.injector.get(DialogService);
    let errMsg: string;
 //  console.log(error);
 //  console.log(error.constructor.name);
    //JSON.stringify(error, getCircularReplacer());

    //check chunk failed error
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      alert('There is a new update available. Reloading the app. Please wait.');
      // document.location.reload();
      window.location.reload();
    }

    // how about sending only type error
    if(!window.navigator ||  !window.navigator.onLine){
   //  console.log('Window is not ready.');
      return error;
    }
    if(document.readyState !== "complete") {
   //  console.log('Window is not loaded.');
      return error;
    }
    try{
      if(window.navigator && window.navigator.onLine
        && window.navigator.cookieEnabled
        // && this.rollbar.options.enabled
        )
        {
        if (!(error instanceof HttpErrorResponse)
          && !(error.error && error.error == 'idpiframe_initialization_failed') )  {
            // this.rollbar.error(error);
        }
      }
    }
    catch(e){
   //  console.log("error : "+ e);
    }



    //  }
    errMsg = getDisplayErrorMessage(error);
    if (errMsg && errMsg != '') {
      _dialogService.openAlert({ 'title': 'Error', 'message': (errMsg) });
    }
  }
}
