<div class=" content_area_list container mobScroll mob-p-default ">
  <div class="row example-header">
    <div class="col-sm-9 text-center">
      <form [formGroup]="searchForm">
        <div class="row" #searchBar *ngIf="searchBarOpen">
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput #filter placeholder="Search {{entityName | titlecase}}" formControlName="search">
            </mat-form-field>
          </div>
          <div class="col-sm-3 action-buttons  text-center">
            <span style="margin-right: 10px"><button mat-icon-button color="primary" (click)="submitSearch()">
                <mat-icon>search</mat-icon>
              </button></span>
            <span><button mat-icon-button color="secondary" (click)="resetSearch()">
                <mat-icon>clear</mat-icon>
              </button></span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-3 text-right">
      <span *ngIf="!searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_more</mat-icon>
        </button></span>
      <span *ngIf="searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_less</mat-icon>
        </button></span>
      <span>
          <!-- <button mat-icon-button [routerLink]="['/' + entityName +'/add']"> -->
          <button mat-icon-button (click)="openAddModal(billEdit)">
            <mat-icon>add</mat-icon>
          </button>
          </span>
      <span><button mat-icon-button color="primary" (click)="download($event)">
          <mat-icon>cloud_download</mat-icon>
        </button></span>
      <span><button mat-icon-button color="primary" (click)="refresh($event)">
          <mat-icon>refresh</mat-icon>
        </button></span>
        <span><button mat-icon-button [matMenuTriggerFor]="menuTop">
          <mat-icon>more_vert</mat-icon>
        </button></span>
      <mat-menu #menuTop="matMenu">
      </mat-menu>
    </div>
  </div>
  <div class="table_list">
    <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
      <mat-table #table class="table_list" [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="widthm200"> Bill No </mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm200"> {{element.invoice_ref}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="customers_id">
      <mat-header-cell *matHeaderCellDef class="widthm150"> Customer </mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150"> {{element.customer?.name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="consignment_nos">
      <mat-header-cell *matHeaderCellDef class="widthm150"> Consignments </mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150">
        <ul *ngIf="element.invoice_lines?.length">
          <li *ngFor="let invLine of element.invoice_lines;">
            {{invLine.consignment_note?.consignment_no}}
          </li>
        </ul>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoice_total_amt">
      <mat-header-cell *matHeaderCellDef class="width150"> Invoice Amt </mat-header-cell>
      <mat-cell *matCellDef="let element" class="width150"> {{element.invoice_total_amt}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoice_status_cd">
      <mat-header-cell *matHeaderCellDef class="widthm150"> Invoice Status</mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150"> {{element.invoice_status_cd}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoice_dt">
      <mat-header-cell *matHeaderCellDef class="widthm150"> Invoiced On</mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150"> {{element.invoice_dt?.toLocaleDateString()}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="submitted_dt" >
      <mat-header-cell *matHeaderCellDef class="widthm150"> Submitted On</mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150"> {{element.submitted_dt?.toLocaleDateString()}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payment_due_dt">
      <mat-header-cell *matHeaderCellDef class="widthm150"> Payment Dt/ Due Dt</mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm150"><span *ngIf="element.payment_dt">{{element.payment_dt?.toLocaleDateString()}} </span><span *ngIf="!element.payment_dt">{{element.payment_due_dt?.toLocaleDateString()}} </span> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payment_amt">
        <mat-header-cell *matHeaderCellDef class="widthm150"> Payment Amt</mat-header-cell>
        <mat-cell *matCellDef="let element" class="widthm150"> {{element.payment_amt}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="diff_amt">
          <mat-header-cell *matHeaderCellDef class="widthm150"> Difference</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthm150"> {{element.diff_amt}} </mat-cell>
        </ng-container>
    <!-- edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> &nbsp;</mat-header-cell>
      <mat-cell *matCellDef="let element" class="width60-Center">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openEditModal(billEdit, element)">Edit</button>
          <button mat-menu-item *ngIf="needReviewButton(element)" (click)="openModalReviewBill(reviewBillPopup,element)">Review Bill</button>
          <button mat-menu-item *ngIf="needSubmissionButton(element)" (click)="openModalSubmitBill(submittedPopup,element)">Mark
            Bill Submitted</button>
          <button mat-menu-item *ngIf="needPaymentButton(element)" (click)="openModalMarkPaymentReceived(paymentRecepvedPopup,element)">Mark
            Payment Received</button>
          <button mat-menu-item *ngIf="needPaymentButton(element)" (click)="openModalMarkMrComplete(paymentRecepvedPopup,element)">Mark
            MR Complete</button>
        </mat-menu>
      </mat-cell>
      <!-- <mat-cell *matCellDef="let element">
        <a mat-icon-button color="primary" (click)="openEditModal(billPopup, element)"><i
            class="ti-pencil-alt"></i></a>
      </mat-cell> -->
    </ng-container>
    <!-- view Column
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="primary"  [routerLink]="['/invoices/view',element.id]" routerLinkActive="active"><i class="fa fa-eye"></i></a>
	  </mat-cell>
    </ng-container>
	-->
    <!-- Delete Column
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef> Delete</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="warn" (click)="deleteInvoices(element.id)"><i class="ti-trash"></i></a>
	  </mat-cell>
    </ng-container>
    -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page" (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
</div>
<ng-template #modalHeader let-title="title">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- close button if popup, hitory link, reassign executives, cancel trip, resend sms, get location, gallery, history, location,  -->
          <mat-menu #rootMenu="matMenu">
            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #billEdit>
  <app-invoices-edit (onCloseCall)="closeModalGeneric()"  (onEntitySaveComplete)="doOnEntitySaveComplete()" [invoices_id]="entity.id" [customers_id]="customers_id" [consignment_notes_id]="consignment_notes_id">
  </app-invoices-edit>
</ng-template>
<ng-template #submittedPopup>
  <form [formGroup]="entityFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Submit Bill'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="billSummary;context:{aBillData:entity}">
        </ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Mark Bill submitted: {{entity.invoice_ref}}</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="SubmittedDate" placeholder="Submitted Date"
                  formControlName="submitted_dt">
                <mat-datepicker-toggle matSuffix [for]="SubmittedDate"></mat-datepicker-toggle>
                <mat-datepicker #SubmittedDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('submitted_dt').valid && !entityFormGroup.get('submitted_dt').touched">
                  Submitted Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col" >
              <label *ngIf="entityFormGroup.get('submission_scan').value"><a href="{{imageUrl}}{{entityFormGroup.get('submission_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Submission Proof</span><input type="file"
                style="display: none;" name="file[]" (change)="uploadSubmissionScan($event)">
            </label>
            <mat-error class="mapError"
            *ngIf="!entityFormGroup.get('submission_scan').valid  && entityFormGroup.get('submission_scan').touched">
            Submission Proof is required.
          </mat-error>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="PaymentDueDate" placeholder="Payment Due Date"
                  formControlName="payment_due_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDueDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDueDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_due_dt').valid && !entityFormGroup.get('payment_due_dt').touched">
                  Payment Due Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <mat-option *ngFor="let aStatus of getAllEntityStatuses()" value="{{aStatus}}">{{aStatus}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_status_cd').valid  && !entityFormGroup.get('invoice_status_cd').touched">
                  Invoice Status is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill remarks" formControlName="invoice_remarks">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_remarks').valid && !entityFormGroup.get('invoice_remarks').touched">
                  Bill remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary"
              (click)="submitBillSubmission(entityFormGroup.value)">Mark Submitted</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #reviewBillPopup>
  <form [formGroup]="entityFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Review Bill'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="billSummary;context:{aBillData:entity}">
        </ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Bill Review {{entity.invoice_ref}}</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <mat-option *ngFor="let aStatus of getAllEntityStatuses()" value="{{aStatus}}">{{aStatus}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_status_cd').valid  && !entityFormGroup.get('invoice_status_cd').touched">
                  Invoice Status is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col" *ngIf="entityFormGroup.get('invoice_scan').value">
            <label class="btn btn-primary fileUpload btn btn-light btn-light-upload">
              <span><a href="{{imageUrl}}{{entityFormGroup.get('invoice_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a>Bill Scan</span>
            </label>
            </div>
            <div class="col" *ngIf="entityFormGroup.get('submission_scan').value">
            <label class="btn btn-primary fileUpload btn  btn btn-light btn-light-upload">
              <span><a href="{{imageUrl}}{{entityFormGroup.get('submission_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a>Submission Proof</span>
              </label>
            </div>
          </div>
          <div class="table-responsive" *ngIf="entity.invoice_lines?.length >0">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>LR No</th>
                  <th>Customer Ref#</th>
                  <th>LR Freight</th>
                  <th>LR Total</th>
                  <th>Charged Amount</th>
                </tr>
                <tr *ngFor="let item of entity.invoice_lines">
                  <td>
                    <ul>
                      <li>{{item.consignment_note?.consignment_no }} </li>
                      <li>{{item.consignment_note?.consignment_dt | date: 'dd MMM yy'}} </li>
                      <li>{{item.consignment_note?.consignment_status_cd}} </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{item.consignment_note?.invoice_no }} </li>
                      <li>{{item.consignment_note?.invoice_dt | date: 'dd MMM yy'}} </li>
                    </ul>
                    </td>
                    <td>{{item.consignment_note.freight_amt}}</td>
                    <td>{{item.consignment_note.total_amt}}</td>
                    <td>{{item.invoice_line_amt}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill remarks" formControlName="invoice_remarks">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_remarks').valid && !entityFormGroup.get('invoice_remarks').touched">
                  Bill remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitBillReview(entityFormGroup.value)">Validate</button>
          </div>
        </div>
      </div>
  </form>
</ng-template>
<ng-template #paymentRecepvedPopup>
  <form [formGroup]="paymentsFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Mark Payment Received'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <!-- <ng-container *ngIf="payment.id">-->
        <ng-container *ngTemplateOutlet="billSummary;context:{aBillData:entity}">
        </ng-container>
      <!-- </ng-container>  -->
        <div class="fieldset mb-4">
          <!-- <label class="fields_t_b pt-3 color_blue">Payment Information</label> -->
          <h3 class="gwheadline">Payment Information</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input required type="text" matInput appUppercase placeholder="Payment Ref"
                  [matAutocomplete]="autowp" formControlName="payment_ref">
                <mat-autocomplete #autowp="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of paymentsList" [value]="option.payment_ref"
                    (click)="setPayment(option)">
                    {{ option.payment_ref }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('payment_ref').valid && !paymentsFormGroup.get('payment_ref').touched">
                  Payment ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input required type="text" matInput appUppercase placeholder="Bank"
                   formControlName="bank_cd">
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('bank_cd').valid && !paymentsFormGroup.get('bank_cd').touched">
                  Bank is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input required matInput [matDatepicker]="PaymentDate" placeholder="Payment Date" formControlName="payment_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('payment_dt').valid && !paymentsFormGroup.get('payment_dt').touched">
                  Payment Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Payment Amt"
                  formControlName="payment_amt">
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('payment_amt').valid && !paymentsFormGroup.get('payment_amt').touched">
                  Total Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <label *ngIf="paymentsFormGroup.get('payment_scan').value"><a href="{{imageUrl}}{{paymentsFormGroup.get('payment_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Payment Scan</span><input type="file"
                style="display: none;" name="file[]" (change)="uploadPaymentScan($event)">
            </label>
            <mat-error class="mapError"
            *ngIf="!paymentsFormGroup.get('payment_scan').valid  && paymentsFormGroup.get('payment_scan').touched">
            Payment Proof is required.
          </mat-error>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="WebXpress MR Code" formControlName="webxpress_payment_code">
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('webxpress_payment_code').valid && !paymentsFormGroup.get('webxpress_payment_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Remarks" formControlName="payment_remarks">
                <mat-error class="mapError"
                  *ngIf="!paymentsFormGroup.get('payment_remarks').valid && !paymentsFormGroup.get('payment_remarks').touched">
                  Payment remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="fieldset mb-4">
          <!-- <h3 class="gwheadline">Bill Preview {{entity.invoice_ref}}</h3> -->
          <label class="fields_t_b pt-3 color_blue" *ngIf="payment.payment_lines?.length >0">Consignment Notes</label>
          <div class="table-responsive" *ngIf="payment.payment_lines?.length >0">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>LR No</th>
                  <th>Due Amt</th>
                  <!-- <th>LR Total</th> -->
                  <th>Received Amount</th>
                </tr>
                <tr *ngFor="let item of payment.payment_lines">
                  <td>
                    <ul>
                      <li>{{item.invoice_line?.consignment_note?.consignment_no }} </li>

                      <!-- <li>{{item.invoice_line?.invoice?.invoice_ref }} </li> -->
                      <!-- <li>{{item.invoice_line?.invoice?.invoice_total_amt }} </li> -->
                      <!-- <li>{{item.consignment_note?.consignment_dt | date: 'dd MMM yy'}} </li> -->
                      <!-- <li>{{item.consignment_note?.consignment_status_cd}} </li> -->
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <!-- <li>{{item.invoice_line?.consignment_note?.consignment_no }} </li> -->
                      <li>{{item.invoice_line?.invoice_line_amt }} </li>
                    </ul>
                    </td>
                    <td>{{item.payment_line_amt}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          <div class="fieldset mb-4">
          <label class="fields_t_b pt-3  color_blue"> Add Bill to Payment</label>
          <div class="row" formGroupName="payment_line_search">
            <div class="col-md-3 d-flex">
              <mat-form-field class="notingabsurd w-75">
                <input type="text" matInput  placeholder="Add Bill to Payment" [matAutocomplete]="autoir" formControlName="webxpress_invoice_code">
                <mat-autocomplete #autoir="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of invoiceList" [value]="option.id"
                    (click)="setInvoiceForPayment(option)">
                    {{ option.webxpress_invoice_code }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="mapError"
                  *ngIf="!paymentLinesFormGroup.get('webxpress_invoice_code').valid && !paymentLinesFormGroup.get('webxpress_invoice_code').touched">
                  Valid Bill is is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Consignment Notes" formControlName="consignment_no"
                (selectionChange)="setConsignmentNo($event)">
                  <mat-option value="ALL" selected>ALL LRs</mat-option>
                  <ng-container *ngIf="invoicePay?.invoice_lines?.length > 0">
                    <mat-option *ngFor="let item of invoicePay.invoice_lines" [value]="item.id" (click)="setPaymentLine(item)">{{item.consignment_note.consignment_no}}</mat-option>
                  </ng-container>

                </mat-select>
                <mat-error *ngIf="!paymentLinesFormGroup.get('consignment_no').valid && paymentLinesFormGroup.get('consignment_no').touched">
                  <span *ngIf="paymentLinesFormGroup.get('consignment_no').errors.required">Consignment no is required.</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-label>Invoice Amt: <div>{{paymentLinesFormGroup.get('invoice_line_amt').value}}</div></mat-label>
            </div>
            <div class="col-md-2">
              <mat-form-field class="notingabsurd">
                <input  type="number" matInput placeholder="Amount Received"
                  formControlName="payment_line_amt">
                <mat-error class="mapError"
                  *ngIf="!paymentLinesFormGroup.get('payment_line_amt').valid && !paymentLinesFormGroup.get('payment_line_amt').touched">
                  Amount received is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <button mat-raised-button color="primary" class="ml-2" (click)="addInvoiceForpayment(invoicePay)">Add to Payment</button>
            </div>
          </div>
          </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitPayment(paymentsFormGroup.value)">Save Payment</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #paymentRecepvedPopupBak>
  <form [formGroup]="entityFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Mark Payment Received'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngIf="payment.id">
        <ng-container *ngTemplateOutlet="billSummary;context:{aBillData:entity}">
        </ng-container>
      </ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Bill Preview {{entity.invoice_ref}}</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <mat-option *ngFor="let aStatus of getAllEntityStatuses()" value="{{aStatus}}">{{aStatus}}</mat-option>
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option> -->
                  <!-- <mat-option value="BILL GENERATED">BILL GENERATED</mat-option> -->
                  <!-- <mat-option value="BILL SUBMITTED">BILL SUBMITTED</mat-option> -->
                  <!-- <mat-option value="PAYMENT RECEIVED" selected>PAYMENT RECEIVED</mat-option> -->
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_status_cd').valid  && !entityFormGroup.get('invoice_status_cd').touched">
                  Invoice Status is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col"  *ngIf="entityFormGroup.get('invoice_scan').value">
              <!-- <label *ngIf="entityFormGroup.get('invoice_scan').value"><a href="{{imageUrl}}{{entityFormGroup.get('invoice_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label> -->
            <label class="btn btn-primary fileUpload btn btn-light btn-light-upload">
              <span><a href="{{imageUrl}}{{entityFormGroup.get('invoice_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a>Bill Scan</span>
            </label>
            </div>
            <div class="col" *ngIf="entityFormGroup.get('submission_scan').value">
              <!-- <label *ngIf="entityFormGroup.get('submission_scan').value"><a href="{{imageUrl}}{{entityFormGroup.get('submission_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label> -->
            <label class="btn btn-primary fileUpload btn  btn btn-light btn-light-upload">
              <span><a href="{{imageUrl}}{{entityFormGroup.get('submission_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a>Submission Proof</span>
              </label>
            </div>
          </div>
          <div class="table-responsive" *ngIf="entity.invoice_lines?.length >0">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>LR No</th>
                  <th>Customer Ref#</th>
                  <th>LR Freight</th>
                  <th>LR Total</th>
                  <th>Charged Amount</th>
                </tr>
                <tr *ngFor="let item of entity.invoice_lines">
                  <td>
                    <ul>
                      <li>{{item.consignment_note?.consignment_no }} </li>
                      <li>{{item.consignment_note?.consignment_dt | date: 'dd MMM yy'}} </li>
                      <li>{{item.consignment_note?.consignment_status_cd}} </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{item.consignment_note?.invoice_no }} </li>
                      <li>{{item.consignment_note?.invoice_dt | date: 'dd MMM yy'}} </li>
                    </ul>
                    </td>
                    <td>{{item.consignment_note.freight_amt}}</td>
                    <td>{{item.consignment_note.total_amt}}</td>
                    <td>{{item.invoice_line_amt}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <label class="fields_t_b pt-3 color_blue">Payment Information</label>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" required matInput placeholder="Payment Ref" formControlName="payment_ref">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_ref').valid && !entityFormGroup.get('payment_ref').touched">
                  Payment Reference is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input required matInput [matDatepicker]="PaymentDate" placeholder="Payment Date" formControlName="payment_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_dt').valid && !entityFormGroup.get('payment_dt').touched">
                  Submitted Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Payment Amt"
                  formControlName="payment_total_amt">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_total_amt').valid && !entityFormGroup.get('payment_total_amt').touched">
                  Total Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input required type="number" matInput placeholder="Invoice Payment Amt" formControlName="payment_amt">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_amt').valid && !entityFormGroup.get('payment_amt').touched">
                  Invoice Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <!-- TODO
            add payment ammount for this invoice
          payment for each LRs due vs received -->
            <!-- <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Consignment Payment Amt"
                  formControlName="invoice_line_payment_amt">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_line_payment_amt').valid && !entityFormGroup.get('invoice_line_payment_amt').touched">
                  Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div> -->
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Remarks" formControlName="payment_remarks">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('payment_remarks').valid && !entityFormGroup.get('payment_remarks').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Difference reason" formControlName="diff_reason">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('diff_reason').valid && !entityFormGroup.get('diff_reason').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="WebXpress MR Code" formControlName="webxpress_payment_code">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('webxpress_payment_code').valid && !entityFormGroup.get('webxpress_payment_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <label *ngIf="entityFormGroup.get('payment_scan').value"><a href="{{imageUrl}}{{entityFormGroup.get('payment_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Payment Scan</span><input type="file"
                style="display: none;" name="file[]" (change)="uploadPaymentScan($event)">
            </label>
            <mat-error class="mapError"
            *ngIf="!entityFormGroup.get('payment_scan').valid  && entityFormGroup.get('payment_scan').touched">
            Payment Proof is required.
          </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitPayment(entityFormGroup.value)">Save Payment</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #billSummary let-invoice="aBillData">
  <h2>Bill Details</h2>
  <div class="fieldset mb-4">
    <div class="conatiner_flow">
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{invoice.customer?.name_short || invoice?.customer?.name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Bill# </div>
          <ng-container>
            {{ invoice.invoice_ref }}
          </ng-container>
        </div>
        <!-- <div class="col">
              <div class="labelBox_e3">Transit Hours</div> 12 Hours

            </div> -->
        <!-- <div class="col pb-2">
              <div class="labelBox_e3">Company Details</div> TATA SKY PVT LTD
            </div> -->
        <div class="col" style="text-align: center;">
          <div class="labelBox_e3"> Total Amount</div>
          {{invoice.invoice_total_amt}}

        </div>
        <div class="col">
          <div class="labelBox_e3"> Created On</div>
          {{invoice.invoice_dt | date: 'dd MMM yy'}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Billed On</div>
          {{invoice.submitted_dt | date: 'dd MMM yy'}}
        </div>
        <!-- <div class="col">
          <div class="labelBox_e3"> Due On</div>
          {{task.due_at | date: 'dd MMM yy'}}
        </div> -->
        <div class="col">
          <div class="labelBox_e3"> Status</div>
          {{invoice.invoice_status_cd }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
