<header class="header" id="header">
	<div class="logo pull-left">
	  <a href="#">
		<img src="assets/img/glasswing_logo.svg">
	  </a>
	</div>
  </header>


<div class="row justify-content-md-center" *ngIf="isSubmitSuccessful">
	<div class="col-md-6 text-center">
		<div class="whiteFormShodow successPagePopup">
		  <div class="okIcon"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
		  <h2>Registration Completed Successfully.</h2>
		  <p>Thank you for registring. </p>
		  <p>Your information is saved to our database.</p>
		  <button mat-raised-button color="primary" [routerLink]="['/user/login']">Login Now</button>
		</div>
	</div>
</div>
<div class="formWhiteBox" *ngIf="!isSubmitSuccessful">
    <div class="row justify-content-md-center">
        <!-- <div class="col-md-8"> -->
         <div> <!-- class="loginMainDiv"-->
			<h1>Create your account</h1>
		  	<p *ngIf="errorMessage" class="redFont">{{errorMessage}}</p>
			<p *ngIf="successMessage" class="greenFont">{{successMessage}}</p>
			 <!--<a href="#" class="login-with-google">Sign Up with Google</a>-->
			 <!--<div class="or">or</div>-->
			<form class="example-form" [formGroup]="signUpFormGroup" style="    padding: 10px;">
			<div class="row">
				<div class="col-sm-6">
				<mat-form-field class="w-100">
				<input type="text" required matInput placeholder="Name" formControlName="name">
				<mat-error *ngIf="!signUpFormGroup.get('name').valid && signUpFormGroup.get('name').touched">
						 Name is Not Valid (minimum 4 characters are required)
				</mat-error>
				</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6">
					<mat-form-field class="w-100">
					<input type="text" required matInput placeholder="Email" formControlName="email">
					<mat-error *ngIf="!signUpFormGroup.get('email').valid && signUpFormGroup.get('email').touched">
							Email is Not Valid
					</mat-error>
				</mat-form-field>
				</div>
				<div class="col-sm-6">
						<mat-form-field class="w-100">
						<input type="number" minlength=10 maxlength=10 required matInput placeholder="Mobile" formControlName="mobile_no">
						<mat-error *ngIf="!signUpFormGroup.get('mobile_no').valid && signUpFormGroup.get('mobile_no').touched">
						Mobile is Not Valid (minimum 10 digits are required)
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row mBtm15">
				<div class="col-sm-6">
					<mat-form-field class="w-100">
							<input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Password" formControlName="password">
							<img src="assets/img/pass.svg" alt="pass icon" [ngClass]="{'passIcon': hidePassword, 'activePass': !hidePassword}" (click)="hidePassword = !hidePassword">
							<!-- <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon> -->
							<mat-error *ngIf="!signUpFormGroup.get('password').valid && signUpFormGroup.get('password').touched">
							  <span *ngIf="signUpFormGroup.get('password').errors.required">Password is required.</span>
							  <span *ngIf="signUpFormGroup.get('password').errors.minlength">Password must have 6 characters.</span>
							</mat-error>
<!--
				      <input type="password" required matInput placeholder="Password" formControlName="password">
					<mat-error *ngIf="!signUpFormGroup.get('password').valid && signUpFormGroup.get('password').touched">
							Password is Not Valid (minimum 6 characters are required)
					</mat-error> -->
				</mat-form-field>
				</div>
				<div class="col-sm-6">
					<mat-form-field class="w-100">
				      <input type="password" required matInput placeholder="Confirm Password"  formControlName="confirm_password">
					<mat-error *ngIf="!signUpFormGroup.get('confirm_password').valid && signUpFormGroup.get('confirm_password').touched">
							Confirm Password is Not Valid
					</mat-error>
			  	  </mat-form-field>
				</div>
			</div>
			<!-- <div class="row mBtm25">
				<div class="col-sm-2 text-left">
				<label>Role</label></div>
				<div class="col-sm-10 matChipGroup">
					<mat-chip-list formControlName="roles_id">
						<mat-chip  *ngFor="let role of roleList" (click)="selectRole(role.id)">{{role.name}}</mat-chip>
					<mat-error *ngIf="!signUpFormGroup.get('roles_id').valid && signUpFormGroup.get('roles_id').touched" >
							Select an Role
					</mat-error>
				</mat-chip-list>
				</div>
			</div> -->
			<div class="row mBtm25">
				<div class="col-sm-1 text-left">
			    	<label>Role</label>
				</div>
				<div class="col-sm-11">
					<div class="selectRole" *ngFor="let role of roleList">
						<input name="role" type="radio" value="role.id" (click)="selectRole(role.id)" [disabled]="signUpFormGroup.value.termsCheck == true"/>
						<label>{{role.name}}</label>
					</div>
				</div>
            </div>
			<div class="row mBtm10">
				<div class="col-sm-12 text-left">
					<mat-checkbox formControlName="termsCheck">
					  I read and accept <a href="#">Terms & Conditions.</a>
					</mat-checkbox>
				</div>
			</div>
			<div class="row mBtm25">
				<div class="col-sm-12">
				   <!--<button mat-raised-button color="primary" [disabled]="!(signUpFormGroup.valid && signUpFormGroup.value.termsCheck == true)" (click)="submit(signUpFormGroup.value)">Sign Up</button>-->
				   <button mat-raised-button color="primary" [disabled]="!(signUpFormGroup.valid && signUpFormGroup.value.termsCheck == true)" (click)="submit(signUpFormGroup.value)">Sign Up</button>
				</div>
			</div>
            <div class="signup-link">Already have an account <a [routerLink]="['/user/login']" routerLinkActive="active">Sign In here</a></div>
			</form>
        </div>
        <!-- </div> -->
    </div>
</div>
