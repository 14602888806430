import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { BidDetailsService } from '../services/bidDetails.service';
import { BidsService } from '../../bids/services/bids.service';

import { BidDetails, bidDetailsFormControls } from '../bidDetails';
import { Bookings } from '../../bookings/bookings';
import { Trips } from '../../trips/trips';
@Component({
  selector: 'app-bid-details-edit',
  templateUrl: './modal.edit.component.html',
  styleUrls: ['./edit.component.css'],
  providers: [BidDetailsService, DialogService, BidsService]
})
export class BidDetailsEditComponent implements OnInit{
  @Input('groupName')
  groupName: string = 'partnerContactsFormGroup';
  @Input('bookings')
  bookings: Bookings;
  @Input('trips')
  trips: Trips;
  // @ViewChild('childModal')
  // public childModal:ModalDirective;
  @Input('selfModalRef')
  selfModalRef: BsModalRef;
  @Input('bidDetails')
  bidDetails: BidDetails;
  bidDetailsFormGroup: FormGroup;
  isError: boolean = false;
  errorMessages = new Array<string>();
  minStartDate = new Date();
  constructor(
            private router: Router,
            private route: ActivatedRoute,
            private _formBuilder: FormBuilder,
            private modalService: BsModalService,
            private _snackBarService: MatSnackBar,
            private _dialogService: DialogService,
            private bidDetailsService: BidDetailsService,
            private bidsService: BidsService
    ) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
          if(params.id){
            this.getResponse(params.id);
          }
    });
    this.bidDetailsFormGroup = this._formBuilder.group(bidDetailsFormControls);

    if(this.trips && this.trips.bookings){
      this.bookings = this.trips.bookings;
      this.bidDetailsFormGroup.patchValue({
        trips_id: this.trips.id,
        trips_no: this.trips.trip_no,
      });
    }
    if(this.bookings){
      this.bidDetailsFormGroup.patchValue({
        source_location: this.bookings.source_city,
        destination_location: this.bookings.destination_city,
        truck_type_cd: this.bookings.truck_type_cd,
        goods_type_cd: this.bookings.goods_type_cd,
        no_of_trucks: this.bookings.no_of_trucks,
        pick_up_date: this.bookings.booking_for_dt,  // this.datepipe.transform(this.bookings.booking_for_dt, 'yyyy-MM-dd'),
        booking_for_slot_cd: this.bookings.booking_for_slot_cd,
        bookings_id: this.bookings.id,
        booking_no: this.bookings.booking_no,
        bids_metrics_cd: this.bookings.bids_metrics_cd,
      });
    }
    if(this.trips) {
      this.bidDetailsFormGroup.patchValue({
        no_of_trucks: 1,
      });
    }
    if(this.bidDetails){
      this.minStartDate = null;
   //  console.log(this.bidDetails);
      this.bidDetailsFormGroup.patchValue(this.bidDetails);
    }
  }
  setsourceCityCd(event){
    this.bidDetailsFormGroup.patchValue({
      source_city_cd:event
    });
  }
  setdestinationCityCd(event){
    this.bidDetailsFormGroup.patchValue({
      destination_city_cd:event
    });
  }
  settruckTypeCd(event){
    this.bidDetailsFormGroup.patchValue({
      truck_type_cd:event
    });
  }
  setloadTypeCd(event){
    this.bidDetailsFormGroup.patchValue({
      goods_type_cd:event
    });
  }
  setweightCd(event){
    this.bidDetailsFormGroup.patchValue({
      weight_cd:event
    });
  }
  setstatusCd(event){
    this.bidDetailsFormGroup.patchValue({
      bid_status_cd:event
    });
  }
  selectbidsMetricsCd(event){
    this.bidDetailsFormGroup.patchValue({
      bids_metrics_cd:event
    });
  }
  setbookingForSlotCd(event){
    this.bidDetailsFormGroup.patchValue({
      booking_for_slot_cd:event
    });
  }
  getResponse(id) {
    return this.bidDetailsService.getBidDetailsById(id).subscribe(
      response => {
        this.bidDetails  = new BidDetails(response.data);
        if(this.bidDetails){
          this.bidDetailsFormGroup.patchValue(this.bidDetails);
        }
      }
    );
  }
  cancel() {
    this.selfModalRef.hide();
  }
  submitBidDetailsForm(){
    if(!this.bidDetailsFormGroup.valid){
  		this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(this.bidDetailsFormGroup);
   //  console.log(this.errorMessages);
      appGlobals.markFormGroupTouched(this.bidDetailsFormGroup);
    }
    else{
      const formData = this.bidDetailsFormGroup.value;
      if(!this.bidDetails){
        this.bidDetails  = new BidDetails();
      }
      this.bidDetails.fill(formData);
   //  console.log(this.bidDetails.id);

      if(this.bidDetails.id){
        this.bidsService.updateBids(this.bidDetails).subscribe(
	      // this.bidDetailsService.updateBidDetails(this.bidDetails).subscribe(
          response => {
            if(response.status === 200){
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.selfModalRef.hide();
              this.router.navigate(['bids/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
	    }else{
        this.bidsService.addBids(this.bidDetails).subscribe(
	      // this.bidDetailsService.addBidDetails(this.bidDetails).subscribe(
          response  =>  {
            if(response.status === 200){
              this.bidDetails  = new BidDetails(response.data);
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.selfModalRef.hide();
              this.router.navigate(['bids/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
      }
    }
  }
}
