<ng-container *ngIf="false; else getContentTemplate">
</ng-container>
<!-- <ng-container *ngIf="editBill then editEntity; else modalHeader;" >
</ng-container> -->
<ng-template #editEntity>
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Upload Generated Bill'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <!-- <ng-container *ngIf="entity?.id"> -->
        <ng-container *ngTemplateOutlet="billSummary;context:{aBillData:entity,aCNote:cNoteBillIn}"></ng-container>
      <!-- </ng-container> -->
      <form [formGroup]="entityFormGroup">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">

      <div class="fieldset mb-4">
          <h3 class="gwheadline" *ngIf="entity.id">Edit Bill</h3>
          <h3 class="gwheadline" *ngIf="!entity.id">Create Bill</h3>
          <div class="row">
            <!-- <div class="col-sm-3">
            <mat-form-field class="notingabsurd">
              <input type="text" appUppercase required matInput placeholder="Bill Ref No" formControlName="invoice_ref">
              <mat-error class="mapError" *ngIf="!entityFormGroup.get('invoice_ref').valid && !entityFormGroup.get('invoice_ref').touched">
                Bill ref is required.
              </mat-error>
            </mat-form-field>
          </div> -->
          <div class="col" *ngIf="!cNoteBillIn && customers_id">
            <mat-form-field class="notingabsurd">
              <input required type="text" matInput appUppercase placeholder="Customer Name"
                [matAutocomplete]="autocn" formControlName="customers_name">
              <mat-autocomplete #autocn="matAutocomplete" panelWidth="300px">
                <mat-option *ngFor="let option of customersList" [value]="option.name"
                  (click)="setCustomer(option)">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error class="mapError"
                *ngIf="!entityFormGroup.get('customers_id').valid && !entityFormGroup.get('customers_id').touched">
                Please select from list.
              </mat-error>
              <mat-error class="mapError"
                *ngIf="!entityFormGroup.get('customers_name').valid && !entityFormGroup.get('customers_name').touched">
                Customer name selection is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
              <mat-form-field class="notingabsurd">
                <input required type="text" matInput appUppercase placeholder="Bill WebXpress No"
                  [matAutocomplete]="autowi" formControlName="webxpress_invoice_code">
                <mat-autocomplete #autowi="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of invoiceList" [value]="option.webxpress_invoice_code"
                    (click)="setInvoice(option)">
                    {{ option.webxpress_invoice_code }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('webxpress_invoice_code').valid && !entityFormGroup.get('webxpress_invoice_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col "  >
              <mat-form-field class="notingabsurd" style="margin-top: -1px;">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <mat-option *ngFor="let aStatus of getAllEntityStatuses()" value="{{aStatus}}">{{aStatus}}</mat-option>
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option>-->
                  <!-- <mat-option value="BILL GENERATED">BILL GENERATED</mat-option> -->
                  <!-- <mat-option value="BILL SUBMITTED" selected>BILL SUBMITTED</mat-option> -->
                  <!-- <mat-option value="PAYMENT RECEIVED">PAYMENT RECEIVED</mat-option> -->
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_status_cd').valid  && !entityFormGroup.get('invoice_status_cd').touched">
                  Bill Status is required.
                </mat-error>
              </mat-form-field>
            </div>


            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="InvoiceDate" placeholder="Bill Generation Date"
                  formControlName="invoice_dt">
                <mat-datepicker-toggle matSuffix [for]="InvoiceDate"></mat-datepicker-toggle>
                <mat-datepicker #InvoiceDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_dt').valid && !entityFormGroup.get('invoice_dt').touched">
                  Bill Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col btn-attach" >
              <label *ngIf="entityFormGroup.get('invoice_scan').value"><a href="{{imageUrl}}{{entityFormGroup.get('invoice_scan').value}}" target="_blank">
                <mat-icon>attachment</mat-icon>
              </a></label>
            <label class="btn btn-primary  btn-dark dark_gary">
              <span><img src="assets/img/Landing_01.png">Upload Bill</span><input type="file"
                style="display: none;" name="file[]" (change)="uploadInvoiceScan($event)">
            </label>
            <mat-error class="mapError"
            *ngIf="!entityFormGroup.get('invoice_scan').valid  && entityFormGroup.get('invoice_scan').touched">
            Bill is required.
          </mat-error>

            </div>
          </div>

          <label class="fields_t_b pt-3 pb-2 color_blue" *ngIf="entity?.invoice_lines?.length > 0"> Consignment Items</label>
          <!-- <div class="row">
            <div class="col-md-12 section-seperator">
              <label>Consignment Charges</label>
            </div>
          </div> -->
          <div class="table-responsive" *ngIf="entity?.invoice_lines?.length > 0">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>LR No</th>
                  <th>LR Freight / Total </th>
                  <!-- <th>LR Total</th> -->
                  <!-- <th>LR Invoice Amt</th> -->
                </tr>
                <tr *ngFor="let item of entity?.invoice_lines">
                  <td>
                    <ul>
                      <li>{{item.consignment_note?.consignment_no }} </li>
                      <!-- <li>{{item.consignment_note?.consignment_status_cd }} </li> -->
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ item.freight_amt | number: '0.0-0'}} / {{ item.invoice_line_amt | number: '0.0-0' }}</li>
                      <!-- <li>{{item.consignment_note?.total_amt}} </li> -->
                    </ul>
                    </td>
                    <!-- <td>{{item.invoice_line_amt}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <label class="fields_t_b   color_blue"> Add more LRs to Bill</label>
          <!-- <div class="row">
            <div class="col-md-12 section-seperator">
              <label>Add LR to Bill</label>
            </div>
          </div> -->
          <div class="row" formGroupName="invoice_line_search">
            <!-- formArrayName="invoice_lines"> -->
            <div class="col" >
              <mat-form-field class="notingabsurd">
                <input type="text" matInput  placeholder="Add LR to bill" [matAutocomplete]="autolr" formControlName="consignment_no_search">
                <mat-autocomplete #autolr="matAutocomplete" >
                  <mat-option *ngFor="let option of consignmentNotesList"
                    (click)="setConsignmentForBilling(option)">
                    LR {{ option.consignment_no}} | {{getFormattedDate((option.consignment_dt || option.invoice_dt), 'ddd MMM yy yy')  }}
                  </mat-option>
                </mat-autocomplete>
                <mat-hint>Please select LRs which are POD Reviewed</mat-hint>
                <mat-error class="mapError"
                  *ngIf="!invoiceLinesFormGroup.get('consignment_no_search').valid && !invoiceLinesFormGroup.get('consignment_no_search').touched">
                  Valid LR is is required.
                </mat-error>

              </mat-form-field>
            </div>
            <!-- <div class="col-md-3">
              <mat-form-field class="notingabsurd">
                <input readonly type="number" matInput placeholder="Billable Amt" formControlName="invoice_line_amt">
                <mat-error class="mapError"
                  *ngIf="!invoiceLinesFormGroup.get('invoice_line_amt').valid && !invoiceLinesFormGroup.get('invoice_line_amt').touched">
                  LR Amount is required.
                </mat-error>
              </mat-form-field>
            </div> -->
            <div class="col-md-3">
              <button mat-raised-button color="primary" class="ml-2" (click)="addConsignmentForBilling(cNoteBill)">Add to Bill</button>
            </div>
          </div>
          <label class="fields_t_b  color_blue"> Totals</label>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field class="notingabsurd">
                <input required type="number" matInput placeholder="Total Bill Amt"
                  formControlName="invoice_total_amt">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_total_amt').valid && !entityFormGroup.get('invoice_total_amt').touched">
                  Total Amount is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill remarks" formControlName="invoice_remarks">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_remarks').valid && !entityFormGroup.get('invoice_remarks').touched">
                  Bill remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm-3">
              <label></label>
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="LR Freight Charges" formControlName="freight_amt"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('freight_amt').valid && !entityFormGroup.get('freight_amt').touched">
                  Loading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Loading charges" formControlName="loading_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('loading_charges').valid && !entityFormGroup.get('loading_charges').touched">
                  Loading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Un Loading charges" formControlName="unloading_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('unloading_charges').valid && !entityFormGroup.get('unloading_charges').touched">
                  Unloading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention days" formControlName="detention_days">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('detention_days').valid && !entityFormGroup.get('detention_days').touched">
                  No of days of detention is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Detention charges" formControlName="detention_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('detention_charges').valid && !entityFormGroup.get('detention_charges').touched">
                  Detention charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Other charges" formControlName="misc_charges_amt"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('misc_charges_amt').valid && !entityFormGroup.get('misc_charges_amt').touched">
                  Other charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input required type="number" matInput placeholder="Total LR Amt" formControlName="invoice_line_amt">
                <mat-error class="mapError"
                  *ngIf="!entityFormGroup.get('invoice_line_amt').valid && !entityFormGroup.get('invoice_line_amt').touched">
                  Consignment Invoice Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitEntityForm(entityFormGroup.value)">Save Bill</button>
          </div>
        </div>
      </form>
      </div>
    </div>

</ng-template>
<ng-template #modalHeader let-title="title">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- close button if popup, hitory link, reassign executives, cancel trip, resend sms, get location, gallery, history, location,  -->
          <mat-menu #rootMenu="matMenu">
            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editInvoice>
<!-- <div class="header-strip clearfix">
	<div class="row">
		<div class="col-sm-6">
			<h4><a class="back-btn" [routerLink]="['/invoices/list']">
							<i class="fa fa-angle-left"></i>
						</a>{{(invoices.id)?('Edit'):('Create')}} Invoices</h4>
		</div>
		<div class="col-sm-6 text-right">
		  <button mat-raised-button color="primary" (click)="submitInvoicesForm()" [disabled]="!invoicesFormGroup.valid">Submit</button>
		</div>
	</div>
</div>
  <div class="formWhiteBox pLR0 pTop10">
    <div class="row justify-content-md-center">
        <div class="col-md-12">
         <div class="whiteFormShodow radiusTopNone">
          <form [formGroup]="invoicesFormGroup">
          <h1 class="mBtm0">Invoices Details</h1>
          <div *ngIf="isError">
			<ul>
				<li *ngFor="let message of errorMessages">{{message}}</li>
			</ul>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="number"  required   matInput placeholder="Customers Id" value="" formControlName="customers_id">
		<mat-error *ngIf="!invoicesFormGroup.get('customers_id').valid && invoicesFormGroup.get('customers_id').touched">
                  <span *ngIf="invoicesFormGroup.get('customers_id').errors.required"> Customers Id is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Invoice Dt" value="" formControlName="invoice_dt">
		<mat-error *ngIf="!invoicesFormGroup.get('invoice_dt').valid && invoicesFormGroup.get('invoice_dt').touched">
                  <span *ngIf="invoicesFormGroup.get('invoice_dt').errors.required"> Invoice Dt is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Submitted Dt" value="" formControlName="submitted_dt">
		<mat-error *ngIf="!invoicesFormGroup.get('submitted_dt').valid && invoicesFormGroup.get('submitted_dt').touched">
                  <span *ngIf="invoicesFormGroup.get('submitted_dt').errors.required"> Submitted Dt is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Payment Dt" value="" formControlName="payment_dt">
		<mat-error *ngIf="!invoicesFormGroup.get('payment_dt').valid && invoicesFormGroup.get('payment_dt').touched">
                  <span *ngIf="invoicesFormGroup.get('payment_dt').errors.required"> Payment Dt is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="number"   matInput placeholder="Invoice Total Amt" value="" formControlName="invoice_total_amt">
		<mat-error *ngIf="!invoicesFormGroup.get('invoice_total_amt').valid && invoicesFormGroup.get('invoice_total_amt').touched">
                  <span *ngIf="invoicesFormGroup.get('invoice_total_amt').errors.required"> Invoice Total Amt is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
		<div class="col-sm-6">
			<app-dropdowns [type]="'invoice_status_cd'" [selected]="invoicesFormGroup.value.invoice_status_cd" (onSelect)="setinvoiceStatusCd($event)"     (touched)="invoicesFormGroup.get('invoice_status_cd').markAsTouched()" [placeholder]="'Invoice Status Cd'" [valid]="invoicesFormGroup.get('invoice_status_cd').valid"></app-dropdowns>
            <mat-error class="mapError" *ngIf="!invoicesFormGroup.get('invoice_status_cd').valid && invoicesFormGroup.get('invoice_status_cd').touched">
              <span>Invoice Statusis required.</span>
            </mat-error>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<mat-form-field class="w-100">
                <input type="text"   matInput placeholder="Payment Due Dt" value="" formControlName="payment_due_dt">
		<mat-error *ngIf="!invoicesFormGroup.get('payment_due_dt').valid && invoicesFormGroup.get('payment_due_dt').touched">
                  <span *ngIf="invoicesFormGroup.get('payment_due_dt').errors.required"> Payment Due Dt is required.</span>
                </mat-error>
			</mat-form-field>
		</div>
</div>
            </form>
        </div>
        </div>
    </div>
  </div> -->
  </ng-template>
  <ng-template #billSummary let-invoice="aBillData" let-cNote="aCNote">
    <h2 >Bill Details</h2>
    <div class="fieldset mb-4">
      <div class="conatiner_flow">
        <div class="row  ">
          <div class="col">
            <div class="labelBox_e3" >Customer </div>
            <ng-container *ngIf="invoice.id">
              <!-- <span>from invoice</span> -->
              {{invoice?.customer?.name_short || invoice?.customer?.name}}
            </ng-container>
            <ng-container *ngIf="!invoice.id && cNote && cNote.bookings">
              <!-- <span>from cnote</span> -->
              {{cNote.bookings.customers?.name_short || cNote.bookings.customers?.name}}
            </ng-container>
<!-- <span>{{customers_name}}  name direct</span> -->
          </div>
          <div class="col">
            <div class="labelBox_e3"> Bill# </div>
            <ng-container *ngIf="invoice.invoice_ref">
              {{ invoice.invoice_ref }}
            </ng-container>
            <ng-container *ngIf="!invoice.invoice_ref">
              New
            </ng-container>
          </div>
          <!-- <div class="col">
                <div class="labelBox_e3">Transit Hours</div> 12 Hours

              </div> -->
          <!-- <div class="col pb-2">
                <div class="labelBox_e3">Company Details</div> TATA SKY PVT LTD
              </div> -->
          <div class="col" style="text-align: center;">
            <div class="labelBox_e3"> Total Amount</div>
            {{invoice.invoice_total_amt}}

          </div>
          <div class="col">
            <div class="labelBox_e3"> Created On</div>
            {{invoice.invoice_dt | date: 'dd MMM yy'}}
          </div>
          <div class="col">
            <div class="labelBox_e3" > Billed On</div>
            <ng-container *ngIf="invoice.submitted_dt">
              {{invoice.submitted_dt | date: 'dd MMM yy'}}
            </ng-container>
            <ng-container *ngIf="!invoice.submitted_dt">
              Not Submitted
            </ng-container>

          </div>
          <!-- <div class="col">
            <div class="labelBox_e3"> Due On</div>
            {{task.due_at | date: 'dd MMM yy'}}
          </div> -->
          <div class="col">
            <div class="labelBox_e3"> Status</div>
            <ng-container *ngIf="invoice.invoice_status_cd">
              {{invoice.invoice_status_cd }}
            </ng-container>
            <ng-container *ngIf="!invoice.invoice_status_cd">
              New
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </ng-template>
