import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';

@Injectable()
export class PromptUpdateService {

  constructor(updates: SwUpdate) {
    if (!updates.isEnabled) {
   //  console.log('prompt service swUpdate is not enabled');
    } else {
      updates.available.subscribe(event => {
        // console.log('update is available activating it without prompt');
        // if (promptUser(event)) {
           updates.activateUpdate().then(() => {
            alert('There is a new update available. Reloading the app. Please wait.');
            document.location.reload();
            });
        // }
      });
   //  console.log('prompt updates are enabled');
    }
  }
}
