import {PartnerAddresses} from '../partnerAddresses/partnerAddresses';
import {PartnerContacts} from '../partnerContacts/partnerContacts';
export class PartnerContactAddresses {
    id : number;
    partner_addresses_id : number;
    partner_contacts_id : number;
    partner_address : PartnerAddresses;
    partnerContacts : PartnerContacts;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : PartnerContactAddresses[] {
        let partnerContactAddresses : PartnerContactAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                partnerContactAddresses.push(new PartnerContactAddresses(json));
            }
        }
        return partnerContactAddresses;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.partner_addresses_id = json.partner_addresses_id;
            this.partner_contacts_id = json.partner_contacts_id;
            if (json.partner_address != null) {
                this.partner_address = new PartnerAddresses(json.partner_address);
            }
            if (json.partnerContacts != null) {
                this.partnerContacts = new PartnerContacts(json.partnerContacts);
            }
        }
    }
}
export let partnerContactAddressesFormControls = {
    id: ['', []],
    partner_addresses_id: ['', []],
    partner_contacts_id: ['', []],
//    partner_address: this.partnerAddressesFormGroup
  };