import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListComponent } from './list/notifications.list.component';
import { EditComponent } from './edit/edit.component';
import { Routes, RouterModule } from '@angular/router';
import { DropdownsComponent } from '../../layout/dropdowns/dropdowns.component';
// import { DropdownsModule } from '../../layout/dropdowns/dropdowns.module';
import { RoleGuard } from '../../core/guards/role.guard';
import { admin_customer } from '../../core/constants';
import { MapModule } from '../../layout/map/map.module';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SharedModule } from '../../shared/shared.module';
export const notificationsRoutes: Routes = [
	{ path: 'notifications',
		children: [
			{ path: 'list', canActivate:[AuthGuard], component: NotificationsListComponent },
      { path: 'edit/:id', canActivate:[AuthGuard], component: EditComponent },
      { path: 'new-partners', canActivate: [AuthGuard], component: NotificationsListComponent },
      { path: 'add', canActivate:[AuthGuard], component: EditComponent },
      { path: '**', redirectTo: 'list', pathMatch: 'full'}
    ]
	},
];
@NgModule({
  imports: [
    CommonModule,
		RouterModule.forChild(notificationsRoutes),
		SharedModule,
		// DropdownsModule
  ],
  declarations: [
    NotificationsListComponent,
		EditComponent
	],
	exports:[
		NotificationsListComponent,
    EditComponent
	]
})
export class NotificationsModule { }
