
import {switchMap} from 'rxjs/operators';

import {debounceTime} from 'rxjs/operators';
import {Component, TemplateRef, Input, Output, EventEmitter} from '@angular/core';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
// import { EntityClass, invoicesFormControls } from '../invoices';
import { Invoices as EntityClass, invoicesFormControls as entityFormControls } from '../invoices';

import { InvoicesService as EntityService } from '../services/invoices.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { AppSettings } from '../../../core/config';
import { EntityListComponent } from '../../entity/list/entity.list.component';
import { ConsignmentNotes } from '../../consignmentNotes/consignmentNotes';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { InvoiceLines, invoiceLinesFormControls } from '../../invoiceLines/invoiceLines';
import { Payments, paymentsFormControls } from '../../payments/payment';
import { paymentLinesFormControls, PaymentLines } from '../../paymentLines/paymentLines';
import { Customers } from '../../customers/customers';
import { CustomersService } from '../../customers/services/customers.service';
import { PaymentsService } from '../../payments/services/payments.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [EntityService, DialogService, FileTransferService
  ,ConsignmentNotesService, CustomersService, PaymentsService]
})
export class InvoicesListComponent extends EntityListComponent {
@Input('customers_id')
  public customers_id: number;
@Input('consignment_notes_id')
  public consignment_notes_id: number;
@Input('invoices_id')
  public invoices_id: number;
@Output() public onEntitySaveComplete: EventEmitter<any> = new EventEmitter();
@Output() public onEntitySaveError: EventEmitter<any> = new EventEmitter();

displayedColumns = [
'id',
'customers_id',
'consignment_nos',
'invoice_total_amt',
'invoice_status_cd',
'invoice_dt',
'submitted_dt',
'payment_due_dt',
'payment_amt',
'diff_amt',
'edit'
];
inputProperties = [
  'customers_id',
];
entityName = 'invoices';
entityType = EntityClass;
entity = new EntityClass();
entityList = new Array<EntityClass>();

entityFormControlsType = entityFormControls;

cNoteBill: ConsignmentNotes;
enableLineAdd: boolean;
consignmentNotesList= new Array<ConsignmentNotes>(); //: ConsignmentNotes[];
invoiceLinesFormGroup: FormGroup;
invoiceList: EntityClass[];
existingInvoiceLines: InvoiceLines[];

customersList = new Array<Customers>();

invoicePay: EntityClass;
payment: Payments;
paymentsFormGroup: FormGroup;
paymentLinesFormGroup: FormGroup;
paymentsList: Payments[];
  invoiceLinePay: InvoiceLines;



// dataSource = new InvoicesDataSource();
  // invoicesList = new Array<EntityClass>();
  // entityFormGroup: FormGroup;
  // invoicesSearchForm: FormGroup;
  // isError: boolean = false;
  // errorMessages = new Array<string>();
  // modalRef: BsModalRef;
  // invoicesSearchStr: string;
  // per_page: number;
  // total: number;
  // invoices: EntityClass;
  // imageUrl: String = AppSettings.IMAGE_ENDPOINT;

  // searchForm: FormGroup;
  // currentPage = 1;
  // searchBarOpen = false;
  // selection: SelectionModel<EntityClass>;
  constructor(protected router: Router,
    protected _formBuilder: FormBuilder,
    protected modalService: BsModalService,
    protected _snackBarService: MatSnackBar,
    protected _dialogService: DialogService,
    protected entityService: EntityService,
    protected fileTransferService: FileTransferService,
    protected consignmentNotesService: ConsignmentNotesService,
    protected customersService: CustomersService,
    protected paymentsService: PaymentsService,
            ) {
    super(
      router, _formBuilder, modalService, _snackBarService,
      _dialogService, entityService, fileTransferService
    );
  }
  ngOnInit() {
    super.ngOnInit();
    // console.log(EntityClass);
    this.entityFormGroup = this.entityFormGroup;
    this.entityFormGroup.addControl(
      'invoice_lines',
      this._formBuilder.array([])
    );
    this.invoiceLinesFormGroup = this._formBuilder.group(invoiceLinesFormControls);

    this.paymentsFormGroup = this._formBuilder.group(paymentsFormControls);
    this.paymentLinesFormGroup = this._formBuilder.group(paymentLinesFormControls);
    this.getResponse();
    // this.entityFormGroup = this._formBuilder.group(invoicesFormControls);
    // this.searchForm = this._formBuilder.group({
    //   search: ['', []],
    // });
    // this.searchForm.controls.search.valueChanges.pipe(
    //   debounceTime(400),
    //   switchMap(term => this.entityService.search(term, this.customers_id
    //         )),).subscribe(
    //       response => {
    //  console.log(response);
    //       this.invoicesList = EntityClass.toArray(response.data);
    //       data = this.invoicesList;
    //       this.dataSource = new InvoicesDataSource();
    //       this.total = response.total;
    //       this.per_page = response.per_page;
    //     },
    //     error => {
    //       this._dialogService.openAlert({'title' : 'Error', 'message' : appGlobals.getDisplayErrorMessage(error)});
    //     }
    //   );
    //   this.getResponse();
  }

  // getResponse(pageNo =1) {
  //   return this.entityService.getList(
  //     {page: pageNo
  //       ,customers_id: this.customers_id}
  //           ).subscribe(
  //     response => {

  //       this.invoicesList = EntityClass.toArray(response.data);
  //  console.log(response);
  //       data = this.invoicesList;
  //       this.dataSource = new InvoicesDataSource();
  //       this.total = response.total;
  //       this.per_page = response.per_page;
  //   },
  //     error => {
  //       this._dialogService.openAlert({'title' : 'Error', 'message' : appGlobals.getDisplayErrorMessage(error) });
  //     }
  //   );
  // }
  // mergeWithSearch() {
  //   return Object.assign(
  //     {},
  //     {
  //       page: this.currentPage,
  //       customers_id: this.customers_id
  //     },
  //     this.searchForm.value
  //   );
  // }
  // submitSearch(aData = {}) {
  //   return this.getResponse();
  // }
  // resetSearch() {
  //   this.searchForm.reset();
  //   this.getResponse();
  // }
  // toggleSearchBar() {
  //   this.searchBarOpen = !this.searchBarOpen;
  // }
  // getSearchService(searchTerm) {
  //   return this.entityService.search(this.mergeWithSearch());
  // }
  // setSearchList = function(aData) {
  //   this.entityList = EntityClass.toArray(aData.data);
  //   data = this.entityList;
  //   this.dataSource = new InvoicesDataSource();
  //   this.total = aData.total;
  //   this.per_page = aData.per_page;
  //   this.searchTerm = this.searchForm.get('search').value;
  // };
  // download(event) {

  //   this.getResponseToExcel(this.currentPage);
  // }

  // downloadFile(aData: any) {
  //   const options = {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //   };
  //   const fileName = 'PY-' + new Date().toLocaleDateString();
  //   appGlobals.createAndDownloadBlobFile(aData, options, fileName);
  // }
  // refresh(event) {
  //   this.getResponse(this.currentPage);
  // }
  getResponseToExcel(pageNo = 1) {
    this.currentPage = pageNo;
    const aData = { time_period: 'YTD' };

    return this.entityService
      .billstatusreport(aData)
      .subscribe(
        response => {
          // console.log(response);
          this.downloadFile(response);
        },
        error => {
       //  console.log(error);
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
  }
  // /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = data.length;
  //   return numSelected == numRows;
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected()
  //     ? this.selection.clear()
  //     : data.forEach(row => this.selection.select(row));
  // }
  // selectRow(row) {
  //   this.selection.toggle(row);
  //  console.log(this.selection);
  // }
  // getResponseByPage(event) {
  //   this.getResponse(event.pageIndex + 1);
  // }

  uploadInvoice(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'invoice_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
        this.entity['invoice_scan'] = response.data.filename;
      });
  }
  uploadSubmission(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'submission_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          submission_scan: response.data.filename
        });
        this.entity['submission_scan'] = response.data.filename;
      });
  }
  uploadPayment(event, entityId) {
    this.fileTransferService
    .uploadEntityDocument(event.srcElement.files[0], entityId, 'invoices', 'payment_scan')
      .subscribe(response => {
        this.entityFormGroup.patchValue({
          payment_scan: response.data.filename
        });
        this.entity['payment_scan'] = response.data.filename;
      });
  }

  getAllEntityStatuses(){
    return EntityClass.allStatuses;
  }
  needReviewButton(aData){
    return aData.invoice_status_cd == 'BILL GENERATED';
  }
  needSubmissionButton(aData){
    return (aData.invoice_status_cd == 'BILL GENERATED' || aData.invoice_status_cd == 'REVIEWED');
  }
  needPaymentButton(aData){
    return aData.invoice_status_cd == 'BILL SUBMITTED';
  }
  openModalReviewBill(template: TemplateRef<any>, aData) {
    this.entity = new EntityClass(aData);
    this.entityFormGroup.reset();
    this.entityFormGroup.patchValue(aData);
    if(this.entity.invoice_status_cd == 'BILL GENERATED'){
      this.entityFormGroup.patchValue({
        invoice_status_cd: 'REVIEWED'
      });
    }
    this.openModalGeneric(template, aData);
  }
  openModalSubmitBill(template: TemplateRef<any>, aData) {
    this.entity = new EntityClass(aData);
    this.entityFormGroup.reset();
    this.entityFormGroup.patchValue(this.entity);
    const paymentDays = this.entity.customer.credit_period_days;
    const currentDate = new Date();
    const paymentDueDate = new Date();
    paymentDueDate.setDate(currentDate.getDate() + paymentDays);
    this.entityFormGroup.patchValue({ submitted_dt: currentDate });
    this.entityFormGroup.patchValue({ payment_due_dt: paymentDueDate });
    if(this.entity.invoice_status_cd == 'BILL GENERATED' || this.entity.invoice_status_cd == 'REVIEWED'){
      this.entityFormGroup.patchValue({
        invoice_status_cd: 'BILL SUBMITTED'
      });
    }
    // console.log(this.entityFormGroup);
    this.openModalGeneric(template, aData);
  }
  submitBillReview(aData) {
    this.submitBillSubmission(aData);
  }
  submitBillSubmission(aData) {
    // console.log(aData);
    if (!this.entityFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.entityFormGroup
      );
      appGlobals.markFormGroupTouched(this.entityFormGroup);
    } else {

      this.entityService.updateEntity(aData).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('Bill saved', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
    message: response.message
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
      );
    }
  }
  submitBill(aData) {
    // console.log(aData);
    if (!this.entityFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.entityFormGroup
      );
      appGlobals.markFormGroupTouched(this.entityFormGroup);
    } else {
      this.entity.fill(aData);
      if(this.entity.id){
          this.entityService.updateEntity(this.entity).subscribe(response => {
            if (response.status === 200) {
              this.closeModalGeneric();
              this.getResponse(this.currentPage);
              this._snackBarService.open('Bill saved', 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });
            } else {
              this._dialogService.openAlert({
                title: 'Error',
      message: response.message
              });
            }

          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
        );
      }
      else {
        this.entityService.addEntity(this.entity).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('Bill created', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
    message: response.message
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
      );
      }

    }
  }
  openModalGenerateBill(template: TemplateRef<any>, aData) {
    throw 'illegal method openmodalbill generate';
  }
  observeWebXpressBill() {
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.controls.customers_name,
      'customersList',
      'getCustomerSearchService',
      'setCustomerList',

    );
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.controls.webxpress_invoice_code,
      'invoiceList',
      'getInvoiceSearchService',
      'setInvoiceListForPayment',
      'webxpress_invoice_code', '', 'invoiceCheck'
    );
    appGlobals.observeChanges(
      this,
      this.entityFormGroup.get('invoice_line_search').get('consignment_no_search'),
      'consignmentNotesList',
      'getConsignmentSearchService',
      'setConsignmentList', 'consignment_no'
    );
  }
  getCustomerSearchService(searchTerm) {
    // console.log('consignment search service ' + searchTerm);
    let aData  = {search:searchTerm}
    return this.customersService.lookupCustomerList(searchTerm);
  }
  setCustomerList(aData) {
    // console.log(aData);
    if (aData.data) {
      this.customersList = Customers.toArray(aData.data);
    }
  }
  getConsignmentSearchService(searchTerm) {
    // console.log('consignment search service ' + searchTerm);
    let aData  = {search:searchTerm, customers_id: this.customers_id,
    consignment_status_cd: ['ePOD UPLOADED', 'HARD POD RECEIVED', 'POD REVIEWED']};
 //  console.log(aData);
    return this.consignmentNotesService.lookupConsignmentNoData(aData);
  }
  setConsignmentList(aData) {
    // console.log(aData);
    if (aData.data) {
      this.consignmentNotesList = ConsignmentNotes.toArray(aData.data);
    }
  }
  setInvoiceListForPayment(aData) {
 //  console.log(aData);
    if (aData.data) {
      this.invoiceList = this.entityType.toArray(aData.data);
    }
  }
  invoiceCheck(aInvoiceNo) {
    return (aInvoiceNo.length > 2) ;
    // console.log(aInvoiceNo);
    // if (aInvoiceNo && aInvoiceNo != null && aInvoiceNo.length > 14) {
    //   return true;
    // }
    // return false;
  }
  getInvoiceRefSearchService(searchTerm) {
    // console.log('invoice search service ' + searchTerm);
    return this.entityService.lookupEntity({ 'invoice_ref': searchTerm });
  }
  getInvoiceSearchService(searchTerm) {
    // console.log('invoice search service ' + searchTerm);
    return this.entityService.lookupEntity({ 'webxpress_invoice_code': searchTerm });
  }
  setCustomer(customer) {
    // console.log(invoice);
    this.customers_id = customer.id;
    this.entityFormGroup.patchValue({customers_id: customer.id});
    this.entityFormGroup.patchValue({customers_name: customer.name});

  }
  setInvoice(invoice) {
    // console.log(invoice);
    this.entity = new EntityClass(invoice);
    //this.doPostEntityDetailResponse();
    this.patchExistingEntity(this.entity);

  }

  getInvoiceLineSearchForm(){
    this.invoiceLinesFormGroup.reset();
    if (this.cNoteBill) {
      this.patchConsignmentNoteToInvoiceLineForm(this.cNoteBill);
    }
    this.entityFormGroup.setControl(
      'invoice_line_search',
      this.invoiceLinesFormGroup
    );

  }
  setConsignmentForBilling(aData){
    // console.log(aData);
    // console.log(this.consignmentNotesList);
    this.cNoteBill  = new ConsignmentNotes(aData);
    // this.invoiceLinesFormGroup =  this._formBuilder.group(invoiceLinesFormControls);
    if (this.cNoteBill) {
      this.patchConsignmentNoteToInvoiceLineForm(this.cNoteBill);
    }
  }
  addConsignmentForBilling(aData){
    this.cNoteBill  = new ConsignmentNotes(aData);
    if(this.cNoteBill != null) {
      const aInvoiceLine = this.createInvoiceLineFromConsignment(this.cNoteBill);
      // aInvoiceLine.invoice_line_amt = this.entityFormGroup.get('invoice_line_amt').value;
      // if(!this.entity.invoice_lines) {
      //   this.entity.invoice_lines = [];
      // }
      aInvoiceLine.invoices_id = this.entity.id;
      let aLineFormGroup = this._formBuilder.group(invoiceLinesFormControls);
      aLineFormGroup.patchValue(aInvoiceLine);
      let  invoiceLinesFormArray = <FormArray>this.entityFormGroup.controls.invoice_lines;
      invoiceLinesFormArray.push(aLineFormGroup);
   //  console.log(this.entityFormGroup.get('invoice_total_amt').value);
      const invTotal = parseInt(this.entityFormGroup.get('invoice_total_amt').value);
      const   aTotal = invTotal  + (+aInvoiceLine.invoice_line_amt || 0);
      this.entityFormGroup.patchValue({invoice_total_amt: aTotal});
   //  console.log('getting totals');
   //  console.log(aTotal);
   //  console.log(this.entityFormGroup.get('invoice_total_amt').value);
   //  console.log(+aInvoiceLine.invoice_line_amt);
      if(!this.entity.invoice_lines){
        this.entity.invoice_lines  =  [];
      }
      this.entity.invoice_lines.push(aInvoiceLine);
      this.entity.invoice_total_amt = aTotal;
      // this.entity.invoice_total_amt
      this.cNoteBill= null;
      this.getInvoiceLineSearchForm();
      // this.observeWebXpressBill();
    }
    else {
      alert('Please select consignment to add');
    }

    // console.log(this.entity);
  }
  getNewEntityFormGroup(){
    this.entityFormGroup = this._formBuilder.group(entityFormControls);
    const currentDate = new Date();
    this.entityFormGroup.patchValue({ invoice_dt: currentDate });
    this.entityFormGroup.patchValue({
      invoice_status_cd: 'BILL GENERATED'
    });
    this.entityFormGroup.addControl(
      'invoice_lines',
      this._formBuilder.array([])
    );
    this.entityFormGroup.addControl(
      'invoice_line_search',
      this._formBuilder.group([])
    );
    // this.entityFormGroup.add
    this.getInvoiceLineSearchForm();
    this.observeWebXpressBill();
    // console.log(this.entityFormGroup);
    return this.entityFormGroup;
  }
  patchExistingEntity(aEntity){
    this.getNewEntityFormGroup();
    // console.log(this.entityFormGroup);
    // console.log(aEntity);
    this.entityFormGroup.patchValue(aEntity,{emitEvent: false});

    if(!aEntity.invoice_lines){
      aEntity.invoice_lines  =  [];
    }
    this.existingInvoiceLines = aEntity.invoice_lines;
    let invoiceLinesFormArray = [];
    aEntity.invoice_lines.forEach(element => {
      let aLineFormGroup = this._formBuilder.group(invoiceLinesFormControls);
      aLineFormGroup.patchValue(element);
      invoiceLinesFormArray.push(aLineFormGroup);
    });
    this.entityFormGroup.setControl(
      'invoice_lines',
      this._formBuilder.array(invoiceLinesFormArray)
    );
    // console.log(invoiceLinesFormArray);
    // console.log(this.entityFormGroup);

    if(this.cNoteBill){
      if( (this.entity.customers_id != this.cNoteBill.customers_id)){
        alert('LR does not belong to same customer.');
        throw 'LR does not belong to same customer.';
      }
    }
 //  console.log(this.entityFormGroup);

    return this.entityFormGroup;
  }
  patchConsignmentNoteToInvoiceLineForm(aCNote: ConsignmentNotes){
    // this.invoiceLinesFormGroup =  this._formBuilder.group(invoiceLinesFormControls);
    const aInvoiceLine = this.createInvoiceLineFromConsignment(aCNote);
    this.invoiceLinesFormGroup.patchValue(aInvoiceLine);
    // this.invoiceLinesFormGroup.patchValue({consignment_no_search: aCNote.consignment_no}, {emitEvent: false});
    return this.invoiceLinesFormGroup;
  }
  // setFormForBilling(resetAll = true) {
  //   alert('invalid method this.setFormForBilling');
  //   this.getNewEntityFormGroup();
  //   if(this.entity  && this.entity.id){
  //     this.patchExistingEntity(this.entity);
  //   }
  //   else {
  //     const currentDate = new Date();
  //     this.entityFormGroup.patchValue({ invoice_dt: currentDate });
  //     this.entityFormGroup.patchValue({
  //       invoice_status_cd: 'BILL GENERATED'
  //     });
  //   }
  //   if (this.cNoteBill) {
  //     if(!this.entity.customers_id) {
  //       this.entityFormGroup.patchValue({
  //         customers_id: this.cNoteBill.customers_id
  //       });
  //     }
  //     else if( (this.entity.customers_id != this.cNoteBill.customers_id)){
  //       alert('LR does not belong to same customer.');
  //       throw 'LR does not belong to same customer.';
  //     }
  //     this.patchConsignmentNoteToInvoiceLineForm(this.cNoteBill);

  //   //   if (this.cNoteBill.invoice_lines && this.cNoteBill.invoice_lines[0]) {
  //   //     aInvoiceLine = this.cNoteBill.invoice_lines[0];
  //   //     this.entity = aInvoiceLine.invoice;
  //   //     if(!this.entity.invoice_lines) {
  //   //       this.entity.invoice_lines = [];
  //   //     }
  //   //     this.entity.invoice_lines.push(aInvoiceLine);
  //   //     this.entityFormGroup.patchValue(this.entity);

  //  console.log(aInvoiceLine);
  //   //   } else {
  //   //     aInvoiceLine = this.createInvoiceLineFromConsignment(this.cNoteBill);
  //   //     aInvoiceLine.consignment_note = this.cNoteBill;
  //   //     this.entity = new EntityClass();
  //   //     if(!this.entity.invoice_lines) {
  //   //       this.entity.invoice_lines = [];
  //   //     }
  //   //     this.entity.invoice_lines.push(aInvoiceLine);
  //   //   }
  //   //   // set form from invoice line
  //   //   this.entityFormGroup.patchValue({
  //   //       invoice_line_amt: aInvoiceLine.invoice_line_amt
  //   //     });
  //   //   this.entityFormGroup.patchValue({
  //   //     consignment_notes_id: aInvoiceLine.consignment_notes_id
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     freight_amt: aInvoiceLine.freight_amt
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     loading_charges: aInvoiceLine.loading_charges
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     unloading_charges: aInvoiceLine.unloading_charges
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     detention_charges: aInvoiceLine.detention_charges
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     detention_days: aInvoiceLine.detention_days
  //   //   });
  //   //   this.entityFormGroup.patchValue({
  //   //     misc_charges_amt: aInvoiceLine.misc_charges_amt
  //   //   });
  //   }
  // }
  createInvoiceLineFromConsignment(aData: ConsignmentNotes){
    let aInvoiceLine = new InvoiceLines();
    if(this.entity.id){
      aInvoiceLine.invoices_id = this.entity.id;
    }
    aInvoiceLine.invoice_lines_status_cd = 'BILL GENERATED';
    aInvoiceLine.invoice_line_amt = aData.freight_amt;
    aInvoiceLine.freight_amt = aData.freight_amt;
    aInvoiceLine.loading_charges = aData.loading_charges;
    aInvoiceLine.unloading_charges = aData.unloading_charges;
    aInvoiceLine.detention_charges = aData.detention_charges;
    aInvoiceLine.detention_days = aData.detention_days;
    //aInvoiceLine.invoice_total_amt = this.cNote.freight_amt;
    aInvoiceLine.misc_charges_amt = aData.misc_charges_amt;
    aInvoiceLine.consignment_notes_id = aData.id;
    aInvoiceLine.consignment_note = aData;
    return aInvoiceLine;
  }
  setinvoiceStatusCd(event){
    this.entityFormGroup.patchValue({
      invoice_status_cd:event
    });
  }

  uploadPaymentScan(event, entityId = false){
    this.uploadPayment(event, entityId);
  }
  uploadSubmissionScan(event, entityId = false){
    this.uploadSubmission(event, entityId);
  }

  openModalMarkPaymentReceived(template: TemplateRef<any>, aData) {
    this.entity = new EntityClass(aData);
    // this.bookings = this.cNote.bookings;
    this.entityFormGroup.reset();
    this.entityFormGroup.patchValue(this.entity);
    this.entityFormGroup.patchValue({
      invoice_status_cd: 'PAYMENT RECEIVED'
    });

    this.payment = new Payments();
    this.paymentsFormGroup = this._formBuilder.group(paymentsFormControls);
    this.paymentLinesFormGroup = this._formBuilder.group(paymentLinesFormControls);

    this.invoicePay = aData;
    this.paymentsFormGroup.addControl(
      'payment_line_search',
      this._formBuilder.group([])
    );
    // this.entityFormGroup.add
    this.getPaymentLineSearchForm();

    // this.setInvoiceForPayment(this.invoicePay);
    this.paymentsFormGroup.patchValue({customers_id: this.entity.customers_id});
    // this.observeWebXpressPayment();
    appGlobals.observeChanges(
      this,
      this.paymentsFormGroup.controls.payment_ref,
      'paymentsList',
      'getPaymentSearchService',
      'setPaymentList'
    );
 //  console.log(this.paymentsFormGroup);
    this.openModalGeneric(template, aData);
  }

  openModalMarkMrComplete(template: TemplateRef<any>, aData) {
    this.entity = new EntityClass(aData);
    this.entityFormGroup.reset();
    this.entityFormGroup.patchValue(aData);
    this.entityFormGroup.controls[
      'webxpress_payment_code'
    ].setValidators(Validators.required);

    if(this.entity.invoice_status_cd == 'PAYMENT RECEIVED'){
      this.entityFormGroup.patchValue({
        invoice_status_cd: 'MR COMPLETE'
      });
    }
    // console.log(this.entityFormGroup);
    this.openModalGeneric(template, aData);
  }

  submitMrComplete(aData){
    this.submitBillSubmission(aData);
  }
  setConsignmentNo(aEvent){
 //  console.log(aEvent);
  }
  setPaymentLine(aData: InvoiceLines){
    this.invoiceLinePay = aData;
 //  console.log(aData);
  }
  setInvoiceForPayment(aData: EntityClass){
    this.invoicePay  = new EntityClass(aData);
    // this.paymentsFormGroup.patchValue({invoice_ref_search: aData.invoice_ref}, { emitEvent: false });
    // this.paymentsFormGroup.patchValue({invoice_total_amt: aData.invoice_total_amt}, { emitEvent: false });
    // this.enableLineAdd = true;
    // this.addInvoiceForpayment(aData);
    this.patchInvoiceToPaymentLineForm(this.invoicePay);
  }
  addInvoiceForpayment(aData){
 //  console.log(aData);
    const aLineData = this.paymentsFormGroup.get('payment_line_search').value;

    // if(this.invoicePay != null) {
      const apaymentLines = this.createPaymentLinesFromInvoice(aLineData);
      // aInvoiceLine.invoice_line_amt = this.entityFormGroup.get('invoice_line_amt').value;
      if(!this.payment.payment_lines) {
        this.payment.payment_lines = [];
      }
      // aInvoiceLine.invoices_id = this.entity.id;
      this.payment.payment_lines = this.payment.payment_lines.concat(apaymentLines);
      // this.paymentsFormGroup.get('invoice_total_amt').setValue(null);
      // this.paymentsFormGroup.get('invoice_ref_search').setValue(null);
      this.getPaymentLineSearchForm();
      this.enableLineAdd = false;
      this.invoicePay= null;
   // }
    // else {
    //   alert('Please select invoices to add');
    // }
  }
  uploadInvoiceScan(event, entityId = false){
    this.uploadInvoice(event, entityId);
  }
  getPaymentLineSearchForm(){
    this.paymentLinesFormGroup = this._formBuilder.group(paymentLinesFormControls);
    if (this.invoicePay) {
      this.patchInvoiceToPaymentLineForm(this.invoicePay);
    }
    this.paymentsFormGroup.setControl(
      'payment_line_search',
      this.paymentLinesFormGroup
    );
    this.observeWebXpressPayment();

  }
  getAmountReceived(){
    const totalPaymentAmt = this.paymentsFormGroup.get('payment_amt').value || 0;
    let remainingAmt = totalPaymentAmt;

    if(this.payment.payment_lines && this.payment.payment_lines.length> 0){
      this.payment.payment_lines.forEach(aLine => {
        remainingAmt = remainingAmt - aLine.payment_line_amt;
        }
      );
    }
    if(this.invoiceLinePay) {
      if(this.invoiceLinePay.invoice_line_amt > remainingAmt){
        return remainingAmt;
      }
      return this.invoiceLinePay.invoice_line_amt;
    }
    if(this.invoicePay.invoice_total_amt > remainingAmt){
      return remainingAmt;
    }
    return this.invoicePay.invoice_total_amt;
  }
  getPaymentLineReceivedAmt(anInvoice){
    // get amount equal to line amount if money in payment else remaining payment amount
    const totalPaymentAmt = this.paymentsFormGroup.get('payment_amt').value || 0;
    let remainingAmt = totalPaymentAmt;

    if(this.payment.payment_lines && this.payment.payment_lines.length> 0){
      this.payment.payment_lines.forEach(aLine => {
        remainingAmt = remainingAmt - aLine.payment_line_amt;
        }
      );
    }

    if(anInvoice.invoice_total_amt > remainingAmt){
      return remainingAmt;
    }
    return anInvoice.invoice_total_amt;

  }
  patchInvoiceToPaymentLineForm(anInvoice: EntityClass){
    // this.invoiceLinesFormGroup =  this._formBuilder.group(invoiceLinesFormControls);
    // const aLine = this.createPaymentLinesFromInvoice(anInvoice);
    // this.paymentLinesFormGroup.patchValue(aLine);
    this.paymentLinesFormGroup.patchValue({webxpress_invoice_code: anInvoice.webxpress_invoice_code}, {emitEvent: false});
    if(anInvoice.invoice_lines &&  anInvoice.invoice_lines.length==1){
      // this.paymentLinesFormGroup.patchValue({consignment_no: anInvoice.invoice_lines[0].consignmentNote.consignment_no}, {emitEvent: false});
    }
    this.paymentLinesFormGroup.patchValue({invoice_line_amt: anInvoice.invoice_total_amt}, {emitEvent: false});
    this.paymentLinesFormGroup.patchValue({payment_line_amt: this.getPaymentLineReceivedAmt(anInvoice)}, {emitEvent: false});

    //   invoice_lines_id: ['', []],
  // payment_lines_status_cd: ['', []],
  // payment_line_amt: ['', []],
  // payment_line_ref: ['', []],
  // webxpress_payment_line_code: ['', []],
  // invoice_line_pay_type_cd: ['', []],

  // webxpress_invoice_code:  ['',[]],
  // consignment_no: ['', []],
  // invoice_line_amt: ['',[]]

    return this.paymentLinesFormGroup;
  }
  createPaymentLineFromInvoice(aData: EntityClass){
    let aPaymentLines = [];
    aData.invoice_lines.forEach(aInvoiceLine => {
      let aLine = new PaymentLines();
      aLine.invoices_id = aInvoiceLine.invoices_id;
      aLine.invoice_lines_id = aInvoiceLine.id;
      aLine.invoice_line_pay_type_cd = 'LR';
      aLine.invoice = aData;
      aLine.invoice_line = aInvoiceLine;
      aLine.payments_id = this.payment.id;
      // due amount is invoice line total minus paid amount
      aPaymentLines.push(aLine);
    });
    return aPaymentLines;
  }
  createPaymentLinesFromInvoice(aData){
    let newInvoiceLines = [];
 //  console.log(aData);
    if(aData.consignment_no == 'ALL'){
      newInvoiceLines = this.invoicePay.invoice_lines;
    }
    else {
      this.invoicePay.invoice_lines.forEach(anItem =>{
        if(anItem.consignment_note.consignment_no == aData.consignment_no) {
          newInvoiceLines.push(anItem);
        }
      });
    }
    let aPaymentLines = [];
 //  console.log(newInvoiceLines);
    newInvoiceLines.forEach(aInvoiceLine => {
      let aLine = new PaymentLines();
      aLine.invoices_id = aInvoiceLine.invoices_id;
      aLine.invoice_lines_id = aInvoiceLine.id;
      aLine.invoice_line_pay_type_cd = 'LR';
      aLine.invoice = aData;
      aLine.invoice_line = aInvoiceLine;
      aLine.payments_id = this.payment.id;
      // due amount is invoice line total minus paid amount
      aPaymentLines.push(aLine);
    });
    return aPaymentLines;
  }
  submitPayment(aData) {
    if (!this.paymentsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.paymentsFormGroup
      );
      appGlobals.markFormGroupTouched(this.paymentsFormGroup);
    } else {
   //  console.log(aData);
      this.payment.fill(aData);
   //  console.log(this.payment);
      return;
      if(this.payment.id) {
        this.paymentsService.updateEntity(this.payment).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('MR saved', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
    message: response.message
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
      );
      }
      else {
        this.paymentsService.addEntity(this.payment).subscribe(response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            this.getResponse(this.currentPage);
            this._snackBarService.open('MR added', 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
    message: response.message
            });
          }

        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
      );
      }

    }
    // this.submitBillSubmission(aData);
  }

  observeWebXpressPayment() {
    // console.log('observeWebXpressPayment');
    appGlobals.observeChanges(
      this,
      this.paymentLinesFormGroup.controls.webxpress_invoice_code,
      'invoiceList',
      'getInvoiceRefSearchService',
      'setInvoiceListForPayment'
    );
  }

  setPaymentList(aData) {
 //  console.log(aData);
    if (aData.data) {
      this.paymentsList = Payments.toArray(aData.data);
    }
  }
  setPayment(aData){
 //  console.log('set payment called');

    this.payment = new Payments(aData);
    this.paymentsFormGroup.reset();
    this.paymentsFormGroup.patchValue(this.payment);
    this.observeWebXpressPayment();

    // if(!this.payment.payment_lines){
    //   this.payment.payment_lines =
    // }
    if(this.invoicePay) {
      this.setInvoiceForPayment(this.invoicePay);
    }

  }



// openAddModal(template: TemplateRef<any>){
//     this.entityFormGroup.reset();
//            if(this.customers_id){
//         this.entityFormGroup.patchValue({customers_id: this.customers_id});
//       }
//     this.modalRef = this.modalService.show(template);
//   }
  openEditModal(template: TemplateRef<any>,  aRow: EntityClass){
    this.entity =  aRow;
    this.invoices_id = aRow.id;
    this.customers_id = aRow.customers_id;
    this.modalRef = this.modalService.show(template);
  }
  doOnEntitySaveComplete(aData?: any){
 //  console.log('doOnEntitySaveComplete called ');
 //  console.log(aData);
    this._snackBarService.open('Bill saved', 'X', {
      duration: appGlobals.SNACKBAR_TIMEOUT
    });
    this.closeModalGeneric();
    this.getResponse(this.currentPage);

  }
//   submitInvoicesForm(){
//     if(!this.entityFormGroup.valid){
//   		this.isError = true;
//       this.errorMessages = appGlobals.getFormValidationErrors(this.entityFormGroup);
//       appGlobals.markFormGroupTouched(this.entityFormGroup);
//     }
//     else{
//       const formData = this.entityFormGroup.value;
//       if(formData.id){
// 	      this.entityService.updateInvoices(formData).subscribe(
//           response => {
//             if(response.status === 200){
//               const foundIndex = this.invoicesList.findIndex(x => x.id === formData.id);
//               this.invoicesList[foundIndex] = formData;
//               this.dataSource = new InvoicesDataSource();
//               this.modalRef.hide();
//               this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
//             }
//             else{
//               this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
//             }
//           },
//           error => {
//             this._dialogService.openAlert({'title' : 'Error', 'message' : appGlobals.getDisplayErrorMessage(error) });
//           }
//         );
// 	    }else{
// 	      this.entityService.addInvoices(formData).subscribe(
//           response  =>  {
//             if(response.status === 200){
//               this.invoicesList.unshift(response.data);
//               data = this.invoicesList;
//               this.dataSource = new InvoicesDataSource();
//               this.modalRef.hide();
//               this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
//             }
//             else{
//               this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
//             }
//           },
//           error => {
//             this._dialogService.openAlert({'title' : 'Error', 'message' : appGlobals.getDisplayErrorMessage(error) });
//           }
//         );
//       }
//     }
//   }
//   deleteInvoices(id){
//   }
//   editInvoices(id){
//     this.router.navigate(['/invoices/edit', id]);
//   }
//   viewInvoices(id){
//     this.router.navigate(['/invoices/view', id]);
//   }
}
// let data: EntityClass[];
// export class InvoicesDataSource extends DataSource<any> {
//   connect(): Observable<EntityClass[]> {
//     return of(data);
//   }
//   disconnect() { }
// }
