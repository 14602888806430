import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Users } from '../../manage/users/users';
import { UsersService } from '../../manage/users/services/users.service';
import { StorageService } from '../../core/services/storage.service';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as appGlobals from '../../core/globalfunctions';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [UsersService]
})
export class ResetPasswordComponent implements OnInit {
  resetFormGroup: FormGroup;
  errorMessage:string = null;
  constructor(private _titleService: Title,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private _formBuilder: FormBuilder,
    private usersService: UsersService,
    private router:Router) { }
  ngOnInit() {
    this.resetFormGroup = this._formBuilder.group({
      login: ['',[Validators.required,Validators.minLength(6)]],
      newpassword: ['',[Validators.required,Validators.minLength(6)]],
      confirm_password: ['',[Validators.required,Validators.minLength(6)]]
    });
    this.route.params.subscribe((params: Params) => {
            let login = params['email'];
            this.getDecrypted(login);
     });
  }
  getDecrypted(login){
    this.usersService.decryptUsername(login).subscribe(
      response=>{
        if(response.email){
          this.resetFormGroup.patchValue({login:response.email});
        }
      }
    )
  }
  submit(data){
    this.errorMessage = null;
    if(data.newpassword == data.confirm_password){
      this.usersService.resetPassowrd(data).subscribe(
        response=>{
        if(response.status == 200){
          this.router.navigate(['user/login']);
        }else{
          this.errorMessage = JSON.stringify(response.message);
          alert(this.errorMessage);
        }
      },
      error => {
        this.errorMessage = appGlobals.getDisplayErrorMessage(error);
        alert(this.errorMessage);
      },
      () => {
      });
    }else{
      alert('password & confirm_password not match');
    }
  }
}
