import { gst_pattern, safeDate } from './../../core/globalfunctions';
import { Customers } from '../customers/customers';
import { Users } from '../users/users';
import { CustomerContactAddresses } from '../customerContactAddresses/customerContactAddresses';
import { Validators } from '@angular/forms';
export class CustomerContacts {
  id: number;
  designation: string;
  customers_id: number;
  company: string;
  is_active: number;
  operational_status_cd: string;
  reports_to: number;
  verified_by: number;
  verified_at: Date;
  is_descision_maker: number;
  contacts_function_cd: string;
  users_id: number;
  customer: Customers;
  user: Users;
  customer_contact_addresses: CustomerContactAddresses[];
  company_name: String;
  is_external: number;
  is_company: number;
  gstn_no: String;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): CustomerContacts[] {
    let customerContacts: CustomerContacts[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        customerContacts.push(new CustomerContacts(json));
      }
    }
    return customerContacts;
  }
  fill(json?: any) {
    if (json != null) {
      if (json.id) {
        this.id = json.id;
      }
      this.customers_id = json.customers_id;
      this.designation = json.designation;
      this.company = json.company;
      this.is_active = json.is_active;
      this.operational_status_cd = json.operational_status_cd;
      this.reports_to = json.reports_to;
      this.verified_by = json.verified_by;
      safeDate(this, json,  'verified_at');
      this.is_descision_maker = json.is_descision_maker;
      this.contacts_function_cd = json.contacts_function_cd;
      if (json.users_id) {
        this.users_id = json.users_id;
      }
      if (json.customer != null) {
        this.customer = new Customers(json.customer);
      }
      if (json.customer_contact_addresses != null) {
        this.customer_contact_addresses = CustomerContactAddresses.toArray(
          json.customer_contact_addresses
        );
      }
      if (json.user != null) {
        this.user = new Users(json.user);
      } else if (json.name) {
        this.user = new Users(json);
      }
      this.company_name = json.company_name;
      this.is_company = json.is_company;
      this.is_external = json.is_external;
      this.gstn_no = json.gstn_no;
    }
  }
}
export let customerContactsFormControls = {
  id: ['', []],
  designation: ['', []],
  customers_id: ['', []],
  is_active: [true, []],
  operational_status_cd: ['', []],
  reports_to: ['', []],
  verified_by: ['', []],
  verified_at: ['', []],
  is_descision_maker: ['', []],
  contacts_function_cd: ['', []],
  users_id: ['', []],
  company_name: ['', []],
  is_company: ['', []],
  is_external: ['', []],
  gstn_no: ['', [Validators.pattern(gst_pattern)]]
};
