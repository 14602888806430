import { Customers } from '../customers/customers';
import { InvoiceLines } from '../invoiceLines/invoiceLines';
import { safeDate } from '../../core/globalfunctions';
export class Invoices {
  id: number;
  customers_id: number;
  invoice_dt: Date;
  submitted_dt: Date;
  invoice_total_amt: number;
  invoice_status_cd: string;
  invoice_ref: string;
  customer: Customers;
  webxpress_invoice_code: string;
  invoice_scan: string;
  invoice_lines: InvoiceLines[];
  invoice_remarks: string;
  submission_scan: string;
  payment_due_dt: Date;

  payment_ref: string;
  payment_dt: Date;
  payment_amt: number;
  webxpress_payment_code: string;
  payment_remarks: string;
  payment_scan: string;

  loading_charges: number;
  unloading_charges: number;
  detention_charges: number;
  detention_days: number;
  misc_charges_amt: number;
  freight_amt: number;


  diff_amt: number;
  diff_reason: string;

  payment_total_amt: number;
  balance_amt: number;
  created_at: Date;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): Invoices[] {
    let invoices: Invoices[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        invoices.push(new Invoices(json));
      }
    }
    return invoices;
  }
  static statusesAllIndex = {
    'BILL GENERATED': 'REVIEW BILL',
    'REVIEWED': 'SUBMIT BILL',
    'BILL SUBMITTED': 'MARK PAYMENT RECEIVED',
    'PAYMENT RECEIVED': 'MARK MR COMPLETED',
    'MR COMPLETE': 'DONE',
  };
  static commandsAllIndex = [
    'REVIEW BILL',
    'SUBMIT BILL',
    'MARK PAYMENT RECEIVED',
    'MARK MR COMPLETED'
  ];
  static allStatuses = [
    'BILL GENERATED',
    'REVIEWED',
    'BILL SUBMITTED',
    'PAYMENT RECEIVED',
    'MR COMPLETE',
    'CANCELLED',
  ];
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.customers_id = json.customers_id;
      safeDate(this, json,  'invoice_dt');
      safeDate(this, json,  'submitted_dt');
      safeDate(this, json,  'payment_dt');
      safeDate(this, json,  'payment_due_dt');
      safeDate(this, json,  'created_at');

      this.invoice_total_amt = json.invoice_total_amt;
      this.invoice_status_cd = json.invoice_status_cd;

      if (json.customer != null) {
        this.customer = new Customers(json.customer);
      }
      this.invoice_ref = json.invoice_ref;
      this.webxpress_invoice_code = json.webxpress_invoice_code;
      this.payment_ref = json.payment_ref;
      this.diff_amt = json.diff_amt;
      this.diff_reason = json.diff_reason;
      this.payment_amt = json.payment_amt;
      this.invoice_scan  = json.invoice_scan;
      this.payment_scan = json.payment_scan;
      this.submission_scan = json.submission_scan;
      this.payment_remarks = json.payment_remarks;
      this.invoice_remarks = json.invoice_remarks;
      if(json.invoice_lines != null) {
        this.invoice_lines = json.invoice_lines;
      }
      this.webxpress_payment_code = json.webxpress_payment_code;
      this.loading_charges = json.loading_charges;
      this.unloading_charges = json.unloading_charges;
      this.detention_charges = json.detention_charges;
      this.detention_days = json.detention_days;
      this.misc_charges_amt = json.misc_charges_amt;
      this.freight_amt = json.freight_amt;

      this.payment_total_amt = json.payment_total_amt;
      if(json.balance_amt) {
        this.balance_amt = json.balance_amt;
      }
      else {
        this.balance_amt = json.invoice_total_amt;
        if(this.payment_amt) {
          this.balance_amt = this.invoice_total_amt - this.payment_amt;
        }
      }
    }
  }
}
export const invoicesFormControls = {
  id: ['', []],
  customers_id: ['', []],
  customers_name: ['', []],
  invoice_dt: ['', []],
  submitted_dt: ['', []],
  payment_dt: ['', []],
  invoice_total_amt: [0, []],
  invoice_status_cd: ['', []],
  payment_due_dt: ['', []],
  invoice_ref: ['', []],
  webxpress_invoice_code: ['', []],
  payment_ref: ['', []],
  diff_amt: ['', []],
  diff_reason: ['', []],
  consignment_notes_id: ['', []],
  payment_amt: ['', []],
  invoice_scan: ['', []],
  payment_scan: ['', []],
  submission_scan: ['', []],
  webxpress_payment_code: ['', []],
  invoice_remarks: ['', []],
  payment_remarks: ['', []],
  invoice_line_payment_amt: ['', []],
  invoice_line_amt: ['', []],

  loading_charges: ['', []],
  unloading_charges: ['', []],
  detention_days: ['', []],
  detention_charges: ['', []],
  other_charges: ['', []],
  freight_amt: ['', []],
  misc_charges_amt: ['', []],
  payment_total_amt:['', []],
  consignment_no_search: ['', []],
  invoice_lines: ['', []],
};
