<div class="user-panel text-center" (click)="toggleDrawer()">
  <div class="userprofile-pic">
    <div class="borderpic"><img [src]="avatar_url" class="user_img" /></div>
  </div>
  <p *ngIf="checkRole([3])" class="user_name">{{user.name}}</p>
  <p *ngIf="checkRole([3])" class="user_company">{{company_name}}</p>
</div>

<mat-accordion *ngIf="isShow">
  <mat-expansion-panel *ngIf="checkRole([3])" class="singleMenuLink dMenu" routerLink="/dashboard"
    routerLinkActive="active" (click)="toggleDrawer()" >
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="dashboardMenu">&nbsp;</span><span>Dashboard</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="checkRole([3])" class="singleMenuLink dMenu" routerLink="/customer-bookings/quick-order"
  routerLinkActive="active" (click)="toggleDrawer()" >
  <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
    [expandedHeight]="customExpandedHeight">
    <mat-panel-title>
      <span class="ordersMenu">&nbsp;</span><span>Quick Order</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
</mat-expansion-panel>
  <mat-expansion-panel *ngIf="checkRole([3])"
    class="oMenu" hideToggle="true" #example>
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="ordersMenu">&nbsp;</span><span>Orders</span>
        <mat-icon class="arrow_icon">{{!example.expanded  ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }}</mat-icon>
        <!-- <md-icon *ngIf="!example.expanded">keyboard_arrow_right</md-icon>
        <md-icon *ngIf="example.expanded">keyboard_arrow_down</md-icon> -->
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="submenu">
      <li (click)="toggleDrawer()" ><a [routerLink]="['/customer-bookings/add']" routerLinkActive="active" >
          <mat-icon id="icon1">panorama_fish_eye</mat-icon> 
          <mat-icon id="icon2">lens</mat-icon> 
          New Order
        </a></li>
      <li  (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/list']" routerLinkActive="active">
        <mat-icon id="icon1">panorama_fish_eye</mat-icon> 
        <mat-icon id="icon2">lens</mat-icon> 
          Active Order
        </a></li>
      <li (click)="toggleDrawer()" ><a [routerLink]="['/customer-bookings/completed-list']" routerLinkActive="active" >
        <mat-icon id="icon1">panorama_fish_eye</mat-icon> 
        <mat-icon id="icon2">lens</mat-icon> 
          Completed Order
        </a></li>
      <li (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/unprocessed-list']" routerLinkActive="active" >
          <mat-icon id="icon1">panorama_fish_eye</mat-icon> 
          <mat-icon id="icon2">lens</mat-icon> 
           Unprocessed Order
        </a></li>
      <!-- <li><a [routerLink]="['/customer-bookings/spotlisting']" routerLinkActive="active" >
          <mat-icon>lens</mat-icon> Spot Order
        </a></li> -->
      <li (click)="toggleDrawer()"><a routerLink="/customer-bookings/favourites-order" routerLinkActive="active" >
          <mat-icon>star</mat-icon> Favourites Order
        </a></li>
    </ul>
  </mat-expansion-panel>
  <mat-expansion-panel style="display: none" *ngIf="checkRole([3])" class="singleMenuLink tMenu" routerLink="#"
    routerLinkActive="active" (click)="toggleDrawer()" >
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="tripsMenu">&nbsp;</span><span>Trips</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="checkRole([3])" class="singleMenuLink pMenu" [routerLink]="['/customer-bookings/payment']" routerLinkActive="active"
  (click)="toggleDrawer()">
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="paymentsMenu">&nbsp;</span><span class="m-t-7"  >Payments</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="checkRole([3])" class="singleMenuLink rMenu" [routerLink]="['/customer-bookings/reports']"
    routerLinkActive="active" (click)="toggleDrawer()" >
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="reportsMenu">&nbsp;</span><span class="m-t-7">Reports</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="checkRole([3])" class="singleMenuLink rMenu" [routerLink]="['/customer-bookings/truck-detail']"
    routerLinkActive="active" (click)="toggleDrawer()" >
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="truckMenu">&nbsp;</span><span class="m-t-7">Truck Detail</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>
<ul class="navGroups" *ngIf="isShow && checkRole([1, 10])">
  <!-- <li *ngIf="checkRole([3])">
    <a [routerLink]="['/dashboard']" routerLinkActive="active">
 <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Dashboard</span>
    </a>
  </li> -->
  <!-- <li *ngIf="checkRole([3]) && !userIsLE()">
      <a [routerLink]="['/customer-bookings']" routerLinkActive="active">
        <i *ngIf="displayIcon" class="fa fa-sticky-note" aria-hidden="true"></i>
        <span>Orders</span>
      </a>
  </li> -->

  <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
    <a [routerLink]="['/triplite/internal/mytasks', user_id]" routerLinkActive="active" >
 <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Tasks Beta</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/bookings']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-sticky-note" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span>Bookings</span>
    </a>
  </li>
  <!-- <li *ngIf="checkRole([3])">
      <a [routerLink]="['/customer-trips']" routerLinkActive="active" >
   <img src="assets/img/svg images/customer.svg" alt="" height="40">
        <span>Trips</span>
      </a>

  </li> -->

  <li *ngIf="checkRole([10, 1, 2, 4, 5])" (click)="toggleDrawer()">
      <a [routerLink]="['/triplite/list']" routerLinkActive="active" >
   <img src="assets/img/svg images/customer.svg" alt="" height="40">
        <span>Trips</span>
      </a>

    </li>

    <!-- <li *ngIf="checkRole([3])">
    <a [routerLink]="['/reports']" routerLinkActive="active">
 <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Reports</span>
    </a>
  </li> -->
  <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
    <a [routerLink]="['/triplite/cnotelist']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-sticky-note-o" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Consignment Notes</span>
    </a>
  </li>

  <!-- <li *ngIf="checkRole([10, 1]) && !userIsLE()">
    <a [routerLink]="['/driverTrips']" routerLinkActive="active">
 <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Trip Requests</span>
    </a>
  </li> -->
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/truckHireChallans']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-arrow-circle-right" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Truck Hire Challans</span>
    </a>
  </li>

  <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
    <a [routerLink]="['/triplite/tasks']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Manage Tasks</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTask = !IsExpandedTask;" >
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span>Tasks</span>
      <span class="material-icons" style="float: right;" *ngIf="!IsExpandedTask">
        keyboard_arrow_right
        </span>
        <span class="material-icons" style="float: right;" *ngIf="IsExpandedTask">
          keyboard_arrow_down
          </span>
    </a>
    <div *ngIf="IsExpandedTask ">
      <ul class="navGroupsNested" >
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'BOOKING EXECUTIVE']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Booking Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'ROH']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>ROH</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'TRAFFIC EXECUTIVE']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Traffic</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'LR EXECUTIVE']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>LR Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'CT EXECUTIVE']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Ctrl Tower</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'COORDINATOR']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Coordinator</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'FINANCE EXECUTIVE']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Finance</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'KAM']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>KAM</span>
          </a>
        </li>
        <!-- <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/workarea', 'MANAGEMENT']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span>Management</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal']">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>All</span>
          </a>
        </li>
        <!-- <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/internal/group/myorg']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span>My Org</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/triplite/tasks']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Manage</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/stateLocationMappings/list']">
            <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i>
            <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Branch Mapping</span>
          </a>
        </li>
        </ul>
        </div>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTrip = !IsExpandedTrip;" >
 <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span>Track</span>
      <span class="material-icons" style="float: right;" *ngIf="!IsExpandedTrip">
        keyboard_arrow_right
        </span>
        <span class="material-icons" style="float: right;" *ngIf="IsExpandedTrip">
          keyboard_arrow_down
          </span>
    </a>
    <div *ngIf="IsExpandedTrip ">
      <ul class="navGroupsNested" >
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/assignment']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Pending Assignment</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/placement']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Pending Dispatch</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Trip Tracking </span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/POD']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>POD Tracking</span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/Bill']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Pending Bill</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/MR']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Outstandings</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/payments']" routerLinkActive="active">
         <img src="assets/img/svg images/lr.svg" alt="" height="30">
            <span>Pending Payments</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/tripStatusTrackings']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-arrow-circle-right" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/lr.svg" alt="" height="40">
            <span>Trip History</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/thcStatusTrackings']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-arrow-circle-right" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/lr.svg" alt="" height="40">
            <span>THC History</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/bids/list']">
      <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/customer.svg" alt="" height="40">
      <span>Bids</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()">
      <a  (click)="IsExpandedTripRequests = !IsExpandedTripRequests">
     <img src="assets/img/svg images/customer.svg" alt="" height="40">
          <span>Trip Requests</span>
          <span class="material-icons" style="float: right;" *ngIf="!IsExpandedTripRequests">
            keyboard_arrow_right
            </span>
            <span class="material-icons" style="float: right;" *ngIf="IsExpandedTripRequests">
              keyboard_arrow_down
              </span>
        </a>
        <div *ngIf="IsExpandedTripRequests ">
            <ul class="navGroupsNested">
              <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
                  <a [routerLink]="['/driverTrips']" routerLinkActive="active">
                    <!-- <i *ngIf="displayIcon" class="fa fa-suitcase" aria-hidden="true"></i> -->
                    <img src="assets/img/svg images/drivers.svg" alt="" height="30">
                    <span>Drivers</span>
                  </a>
              </li>
              <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
                  <a [routerLink]="['/driverTrips/partners']" routerLinkActive="active">
                    <!-- <i *ngIf="displayIcon" class="fa fa-suitcase" aria-hidden="true"></i> -->
                    <img src="assets/img/svg images/customer.svg" alt="" height="30">
                    <span>Partners</span>
                  </a>
              </li>
              </ul>
              </div>
  </li>

  <li *ngIf="checkRole([10, 1])  && !userIsLE()" >
    <a  (click)="toggleReport($event)">
      <!-- <i *ngIf="displayIcon" class="fa fa-suitcase" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/report.svg" alt="" height="30">
      <span>Reports</span>
      <span class="material-icons" style="float: right;" *ngIf="!IsExpandedReports">
        keyboard_arrow_right
        </span>
        <span class="material-icons" style="float: right;" *ngIf="IsExpandedReports">
          keyboard_arrow_down
          </span>
        </a>
    <div *ngIf="IsExpandedReports ">
      <ul class="navGroupsNested">
        <!-- <li *ngIf="checkRole([10, 1])  && !userIsLE()">
          <a [routerLink]="['/reports/dashboard']" routerLinkActive="active">
       <img src="assets/img/svg images/customer.svg" alt="" height="40">
            <span>Dashboard</span>
          </a>
        </li> -->

        <!-- <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/indentsByConsignment']" [queryParams]="{time_period:'Yesterday'}" routerLinkActive="active">
            <img src="assets/img/svg images/report.svg" alt="" height="30">
            <span>Indents / Dispatch</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])  && !userIsLE()">
          <a [routerLink]="['/reports/salesByCustomer']" [queryParams]="{time_period:'MTD'}" routerLinkActive="active">
       <img src="assets/img/svg images/customer.svg" alt="" height="40">
            <span>Sales</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()">
          <a [routerLink]="['/reports/placementsByTrip']" [queryParams]="{time_period:'Yesterday'}" routerLinkActive="active">
       <img src="assets/img/svg images/customer.svg" alt="" height="40">
            <span>Placement</span>
          </a>
        </li>
        <!-- <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/deliveryByTrip']" [queryParams]="{time_period:'Yesterday'}" routerLinkActive="active">
            <img src="assets/img/svg images/report.svg" alt="" height="30">
            <span>Movement</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/podByCustomer']" routerLinkActive="active">
            <img src="assets/img/svg images/report.svg" alt="" height="30">
            <span>POD / Billing</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/aging']" routerLinkActive="active">
            <img src="assets/img/svg images/report.svg" alt="" height="30">
            <span>Aging</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
            <a [routerLink]="['/reports/usage']" routerLinkActive="active">
              <img src="assets/img/svg images/report.svg" alt="" height="30">
              <span>Usage</span>
            </a>
          </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
      <a [routerLink]="['/invoices/list']" routerLinkActive="active">
        <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
        <img src="assets/img/svg images/booking.svg" alt="" height="40">
        <span>Invoices</span>
      </a>
    </li>
    <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
      <a [routerLink]="['/payments/list']" routerLinkActive="active">
        <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
        <img src="assets/img/svg images/payment.svg" alt="" height="40">
        <span>Receipts</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/thcPayments/thcPayments/list']">
        <img src="assets/img/svg images/booking.svg" alt="" height="30">
        <span> Payments</span>
      </a>
    </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()">
    <a (click)="toggle2()" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-tasks" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span>Manage</span>
      <span class="material-icons" style="float: right;" *ngIf="!IsExpanded2">
        keyboard_arrow_right
        </span>
        <span class="material-icons" style="float: right;" *ngIf="IsExpanded2">
          keyboard_arrow_down
          </span>
    </a>
    <div *ngIf="IsExpanded2 ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/customers/list']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/customer.svg" alt="" height="30">
            <span>Customers</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 3 ]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/contracts']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-credit-card" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/booking.svg" alt="" height="30">
            <span>Contracts</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/partners']" routerLinkActive="active">
            <img src="assets/img/svg images/customer.svg" alt="" height="30">
            <span>Partners</span>
          </a>
        </li>
        <li  *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/partnerBanks/partnerBanks']" routerLinkActive="active">
            <img src="assets/img/svg images/customer.svg" alt="" height="30">
            <span>Partner Banks</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/trucks']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-truck" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/truck.svg" alt="" height="30">
            <span>Trucks</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/drivers']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-id-card-o" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/drivers.svg" alt="" height="30">
            <span> Drivers </span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/users/list']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Users</span>
          </a>
        </li>

      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10])">
    <a (click)="toggle3()" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-tasks" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/profile.svg" alt="" height="40">
      <span>Admin & Test</span>
      <span class="material-icons" style="float: right;" *ngIf="!IsExpanded3">
        keyboard_arrow_right
        </span>
        <span class="material-icons" style="float: right;" *ngIf="IsExpanded3">
          keyboard_arrow_down
          </span>
    </a>
    <div *ngIf="IsExpanded3">
      <ul class="navGroupsNested">
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/userDeviceTokens/list']" routerLinkActive="active">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Device Tokens</span>
          </a>
        </li>
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/userDeviceData/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Device Data</span>
          </a>
        </li>
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/audit/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Audit</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()">
          <a [routerLink]="['/usersGroup/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Bid Users Group</span>
          </a>
        </li>

        <li>
          <a [routerLink]="['/dropdowns/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Dropdowns</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/roles/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Roles</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/pricePredict/']" routerLinkActive="active">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Suggest Price</span>
          </a>
        </li>
        <!-- <li>
                <a [routerLink]="['/userBidHistory/list']">
                  <img src="assets/img/svg images/profile.svg" alt="" height="30">
                  <span>Bid History Temp</span>
                </a>
            </li> -->
        <li>
          <a [routerLink]="['/failedJobs/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Failed Jobs</span>
          </a>
        </li>
        <!-- <li>
                <a [routerLink]="['/truckLocation/list']">
                  <img src="assets/img/svg images/profile.svg" alt="" height="30">
                  <span>Truck Location</span>
                </a>
            </li> -->
        <!-- <li>
                <a [routerLink]="['/invoices/list']">
                  <img src="assets/img/svg images/profile.svg" alt="" height="30">
                  <span>Invoices</span>
                </a>
            </li> -->
        <li>
          <a [routerLink]="['/forecastMasterData/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Forecast Master Data</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/forecastOilPrice/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Forecast Oil Price</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/forecastTruckTypeMapping/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Forecast Truck Type Mapping</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/jobs/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Jobs</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/notifications/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Notifications</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/pushSubscriptions/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>pushSubscriptions</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/quotes/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Quotes</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/states/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>States</span>
          </a>
        </li>
        <!-- <li>
          <a [routerLink]="['/stateLocationMappings/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>State Location Mapping</span>
          </a>
        </li> -->

        <li>
          <a [routerLink]="['/webexpressActivityLog/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Webexpress Activity Log</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/queue/list']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>Queue</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/tenew/edit']">
            <img src="assets/img/svg images/profile.svg" alt="" height="30">
            <span>tenew</span>
          </a>
        </li>
        <!-- <li>
        <a [routerLink]="['/pricing_contract/list']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-credit-card" aria-hidden="true"></i>
          <span>Pricing & Contract</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/partner-contacts-list/list']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-compress" aria-hidden="true"></i>
          <span>Partner Contacts List</span>
        </a>
      </li> -->
        <!-- <ul>
          <li>
            <a [routerLink]="['/nearby_drivers-partners/drivers']" routerLinkActive="active">
              <span>Nearby Drivers</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/nearby_drivers-partners/partners']" routerLinkActive="active">
              <span>Nearby Partners </span>
            </a>
          </li>
        </ul> -->
        <!-- <li>
        <a [routerLink]="['/consignmentNotes']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-sticky-note-o" aria-hidden="true"></i>
          <span>Consignment Notes</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/myconsignmentNotes']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-sticky-note-o" aria-hidden="true"></i>
          <span>My Consignment Notes</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/consignmentNotesPrint/view']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-print" aria-hidden="true"></i>
          <span>Consignment Notes Print</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/users']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
          <span>Users</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/dropdowns']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-list-ul" aria-hidden="true"></i>
          <span>Dropdowns</span>
        </a>
      </li> -->
      </ul>
    </div>
  </li>
</ul>


<div class="text-center p-t-0 bottomImg">
  <img src="assets/img/menu-footer.png" style="width: 90%;" />
  <!-- display: none; -->
</div>
