import { Bookings } from '../bookings/bookings';
import { Customers } from '../customers/customers';
import { Partners } from '../partners/partners';
import { Trips } from '../trips/trips';
import { Trucks } from '../trucks/trucks';
import { PartnerContacts } from '../partnerContacts/partnerContacts';
import { Validators } from '@angular/forms';
import * as appGlobals from '../../core/globalfunctions';
import { PartnerBanks } from '../partnerBanks/partnerBanks';
import { ThcPayments } from '../thcPayments/thcPayments';

export class TruckHireChallans {
  // #region Properties (65)

  advance_cash_amt: number = 0;
  advance_excess_approval_remarks: string;
  advance_excess_approved_by: string;
  advance_excess_approved_by_name: string;
  advance_excess_reason: string;
  advance_payment_dt: Date;
  advance_payment_proof_scan: string;
  advance_payment_ref: string;
  advance_percent: number;
  advance_request_by: number;
  advance_request_dt: Date;
  advance_transfer_amt: number;
  advance_utilities_amt: number;
  arrival_executive_id: number;
  arrival_executive_name: string;
  balance_amt: number;
  balance_payment_dt: Date;
  balance_payment_proof_scan: string;
  balance_payment_ref: string;
  balance_request_by: number;
  balance_request_dt: Date;
  billing_branch_cd: string;
  bookings: Bookings;
  bookings_id: number;
  broker: Partners;
  broker_contacts_id: number;
  brokers_id: number;
  customers: Customers;
  customers_id: number;
  destination_city: string;
  detention_amt_per_day: number;
  dispatch_executive_id: number;
  dispatch_executive_name: string;
  estimated_transit_time: number;
  eway_bill_no: string;
  finance_executive_id: number;
  finance_executive_name: string;
  hire_amt: number;
  hire_excess_approval_remarks: string;
  hire_excess_approved_by: string;
  hire_excess_approved_by_name: string;
  hire_excess_reason: string;
  id: number;
  ops_executive_id: number;
  ops_executive_name: string;
  partner_contacts_id: number;
  partners: Partners;
  partners_id: number;
  payment_to_partner_banks_id;
  pricing_executive_id: number;
  pricing_executive_name: string;
  reporting_at: Date;
  reporting_at_time: string;
  source_city: string;
  tds_declaration_scan: string;
  thc_no: string;
  thc_scan: string;
  tracking_executive_id: number;
  tracking_executive_name: string;
  trip: Trips;
  trips_id: number;
  truck: Trucks;
  truck_hire_challan_status_cd: string;
  trucks_id: number;
  vendor_slip_scan: string;
  webxpress_code: string;
  partner_contact: PartnerContacts;
  broker_contact: PartnerContacts;
  advance_payment_cash_amt: number;
  advance_payment_transfer_amt: number;
  advance_payment_utilities_amt: number;
  balance_payment_transfer_amt: number;
  balance_payment_cash_amt: number;
  balance_payment_utilities_amt: number;
  loading_charges: number;
  unloading_charges: number;
  balance_request_transfer_amt: number;
  balance_request_cash_amt: number;
  partner_bank: PartnerBanks;
  detention_days: number;
  detention_charges: number;
  other_charges: number;
  other_charges_head: number;
  total_balance_amt: number;
  reason_no_sim_tracking: string;
  vendor_invoice_scan: string;

  // advance_amt: number;
  ath_amt: number;
  bth_amt: number;
  paid_amt: number;
  ath_paid_amt: number;

  thc_payments: ThcPayments[];
  vendor_invoice_webxpress_code: string;
  deduction_amt = 0;
  deduction_reason_head: string;
  // #endregion

  // #region Constructors (1)

  constructor(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.trucks_id = json.trucks_id;
      this.partners_id = json.partners_id;
      this.source_city = json.source_city;
      this.destination_city = json.destination_city;
      this.hire_amt = json.hire_amt;
      if(json.advance_cash_amt != null) {
        this.advance_cash_amt = json.advance_cash_amt;
      }
      this.advance_transfer_amt = json.advance_transfer_amt;
      this.advance_utilities_amt = json.advance_utilities_amt;
      this.balance_amt = json.balance_amt;
      this.truck_hire_challan_status_cd = json.truck_hire_challan_status_cd;
      this.brokers_id = json.brokers_id;
      if (json.truck != null) {
        this.truck = new Trucks(json.truck);
      }
      if (json.trip != null) {
        this.trip = new Trips(json.trip);
      }
      if (json.partners != null) {
        this.partners = new Partners(json.partners);
      }
      if (json.broker != null) {
        this.broker = new Partners(json.broker);
      }
      this.customers_id = json.customers_id;
      this.bookings_id = json.bookings_id;
      this.trips_id = json.trips_id;
      this.thc_scan = json.thc_scan;
      this.thc_no = json.thc_no;
      this.billing_branch_cd = json.billing_branch_cd;
      this.pricing_executive_id = json.pricing_executive_id;
      this.dispatch_executive_id = json.dispatch_executive_id;
      this.tracking_executive_id = json.tracking_executive_id;
      this.arrival_executive_id = json.arrival_executive_id;
      this.pricing_executive_name = json.pricing_executive_name;
      this.dispatch_executive_name = json.dispatch_executive_name;
      this.tracking_executive_name = json.tracking_executive_name;
      this.arrival_executive_name = json.arrival_executive_name;
      this.ops_executive_id = json.ops_executive_id;
      this.ops_executive_name = json.ops_executive_name;
      this.finance_executive_id = json.finance_executive_id;
      this.finance_executive_name = json.finance_executive_name;
      this.payment_to_partner_banks_id = json.payment_to_partner_banks_id;
      this.advance_payment_ref = json.advance_payment_ref;
      this.balance_payment_ref = json.balance_payment_ref;
      this.advance_request_by = json.advance_request_by;
      this.balance_request_by = json.balance_request_by;
      appGlobals.safeDate(this, json,  'advance_payment_dt');
      appGlobals.safeDate(this, json,  'balance_payment_dt');
      appGlobals.safeDate(this, json,  'advance_request_dt');
      appGlobals.safeDate(this, json,  'balance_request_dt');

      this.tds_declaration_scan = json.tds_declaration_scan;
      this.advance_excess_approval_remarks =
        json.advance_excess_approval_remarks;
      this.advance_excess_approved_by = json.advance_excess_approved_by;
      this.advance_excess_approved_by_name =
        json.advance_excess_approved_by_name;
      this.advance_excess_reason = json.advance_excess_reason;
      this.hire_excess_approval_remarks = json.hire_excess_approval_remarks;
      this.hire_excess_approved_by = json.hire_excess_approved_by;
      this.hire_excess_approved_by_name = json.hire_excess_approved_by_name;
      this.hire_excess_reason = json.hire_excess_reason;
      this.vendor_slip_scan = json.vendor_slip_scan;
      this.eway_bill_no = json.eway_bill_no;
      this.detention_amt_per_day = json.detention_amt_per_day;
      if (json.reporting_at != null) {
        appGlobals.safeDate(this, json,  'reporting_at');
        if(this.reporting_at) {
          this.reporting_at_time = this.reporting_at.toLocaleTimeString();

        }
        // this.reporting_at_time = this.reporting_at.getHours() + ':' + this.reporting_at.getMinutes() + ':' + this.reporting_at.getSeconds();
      }

      this.estimated_transit_time = json.estimated_transit_time;
      this.advance_payment_proof_scan = json.advance_payment_proof_scan;
      this.balance_payment_proof_scan = json.balance_payment_proof_scan;
      this.webxpress_code = json.webxpress_code;
      this.advance_percent = json.advance_percent;
      this.broker_contacts_id = json.broker_contacts_id;
      this.partner_contacts_id = json.partner_contacts_id;

      if (json.partner_contact) {
        this.partner_contact = new PartnerContacts(json.partner_contact);
      }
      if (json.broker_contact) {
        this.broker_contact = new PartnerContacts(json.broker_contact);
      }
      this.advance_payment_cash_amt = +json.advance_payment_cash_amt;
      this.advance_payment_transfer_amt = +json.advance_payment_transfer_amt;
      this.advance_payment_utilities_amt = +json.advance_payment_utilities_amt;

      this.balance_payment_cash_amt = +json.balance_payment_cash_amt;
      this.balance_payment_transfer_amt = +json.balance_payment_transfer_amt;
      this.balance_payment_utilities_amt = +json.balance_payment_utilities_amt;
      // if(json.balance_request_cash_amt) {
        this.balance_request_cash_amt =  +json.balance_request_cash_amt;
      // }

      this.balance_request_transfer_amt = +json.balance_request_transfer_amt;
      this.loading_charges = +json.loading_charges;
      this.unloading_charges = +json.unloading_charges;
      if(json.partner_bank) {
        this.partner_bank = json.partner_bank;
      }
      this.detention_days = +json.detention_days;
      this.detention_charges = +json.detention_charges;
      this.other_charges = +json.other_charges;
      this.other_charges_head = json.other_charges_head;
      this.total_balance_amt = +json.total_balance_amt;
      this.reason_no_sim_tracking = json.reason_no_sim_tracking;
      this.vendor_invoice_scan = json.vendor_invoice_scan;
      this.vendor_invoice_webxpress_code = json.vendor_invoice_webxpress_code;
      // this.advance_amt = json.advance_amt;
      this.ath_amt = +json.ath_amt;
      this.bth_amt = +json.bth_amt;
      this.ath_paid_amt = +json.ath_paid_amt;
      this.paid_amt = +json.paid_amt;
      if(json.thc_payments) {
        this.thc_payments = ThcPayments.toArray(json.thc_payments);
      }
      if(json.deduction_amt) {
        this.deduction_amt = +json.deduction_amt;
      }

      this.deduction_reason_head = json.deduction_reason_head;
    }
  }

  // #endregion

  // #region Static Methods (1)

  static toArray(jsons: any[]): TruckHireChallans[] {
    let truckHireChallans: TruckHireChallans[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        truckHireChallans.push(new TruckHireChallans(json));
      }
    }
    return truckHireChallans;
  }
  static statusesAllIndex = {
    'QUOTE' : 'CONFIRM',
    'CONFIRMED' : 'ATTACH DOCUMENTS', //
    'DRAFT' : 'ATTACH DOCUMENTS', // SET BY ASSIGN VEHICLE
    'VEHICLE ASSIGNED' : 'ATTACH DOCUMENTS',
    'DOCUMENTS ATTACHED' : 'REQUEST ADVANCE',
    'ADVANCE REQUESTED' : 'APPROVE ADVANCE',
    'ADVANCE APPROVED' : 'UPDATE ADV DOCS',
    'ADV DOCS DONE': 'APPROVE ADV FIN',
    'ADV FIN APPROVED': 'MARK ADVANCE PAID',
    'ADVANCE PAID' : 'REQUEST BALANCE',
    'BALANCE REQUESTED' : 'APPROVE BALANCE',
    'BALANCE APPROVED' : 'UPDATE BAL DOCS',
    'BAL DOCS DONE' : 'APPROVE BAL FIN',
    'BAL FIN APPROVED' : 'MARk BALANCE PAID',
  };
  static commandsAllIndex = [
    'ATTACH DOCUMENTS', //
    'REQUEST ADVANCE',
    'APPROVE ADVANCE',
    'UPDATE ADV DOCS',
    'APPROVE ADV FIN',
    'MARK ADVANCE PAID',
    'REQUEST BALANCE',
    'APPROVE BALANCE',
    'UPDATE BAL DOCS',
    'APPROVE BAL FIN',
    'MARk BALANCE PAID',
  ];
  // #endregion
  getBTHProgressColor() {
    if (this.truck_hire_challan_status_cd == 'ADVANCE REQUESTED'
      || this.truck_hire_challan_status_cd == 'ADVANCE PAID') {
      return 'blue';
    }
    return 'grey';
  }

}
export const truckHireChallanControls = {
    idhidden: [null, []],
    id: [null, []],
    trucks_id: ['', []],
    partners_id: ['', []],
    partner_contacts_id: ['', []],
    source_city: ['', []],
    destination_city: ['', []],
    hire_amt: ['', [Validators.required]],
    advance_cash_amt: [0, []],
    advance_transfer_amt: ['', []],
    advance_utilities_amt: ['', []],
    balance_amt: ['', []],
    truck_hire_challan_status_cd: ['', []],
    registration_no: ['', []],
    searchPartner: ['', []],
    bookings_id: [null, [Validators.required]],
    broker_name: ['', []],
    brokers_id: ['', []],
    broker_contacts_id: ['', []],
    searchTruck: ['', [Validators.required]],
    searchCustomer: ['', [Validators.required]],
    customers_id: [null, []],
    trips_id: [null, [Validators.required]],
    trips_status_cd: ['', []],
    billing_branch_cd: ['', []],
    pricing_executive_id: ['', []],
    dispatch_executive_id: ['', []],
    tracking_executive_id: ['', []],
    arrival_executive_id: ['', []],
    pricing_executive_name: ['', []],
    dispatch_executive_name: ['', []],
    tracking_executive_name: ['', []],
    arrival_executive_name: ['', []],
    ops_executive_id: ['', []],
    ops_executive_name: ['', []],
    finance_executive_id: ['', []],
    finance_executive_name: ['', []],
    source_location_cd: ['', []],
    destination_location_cd: ['', []],
    reporting_at: ['', [Validators.required]],
    reporting_at_time: ['', [Validators.required]],
    webxpress_code: ['', []],
    loading_charges: ['', []],
    unloading_charges: ['', []],
    advance_excess_approval_remarks: ['', []],
    advance_excess_approved_by: ['', []],
    advance_excess_approved_by_name: ['', []],
    advance_excess_reason: ['', []],
    advance_payment_dt: ['', []],
    advance_payment_proof_scan: ['', []],
    advance_payment_ref: ['', []],
    advance_percent: ['', []],
    advance_request_by: ['', []],
    advance_request_dt: ['', []],
    balance_payment_dt: ['', []],
    balance_payment_proof_scan:['', []],
    balance_payment_ref:['', []],
    balance_request_by:['', []],
    balance_request_dt: ['', []],
    detention_amt_per_day:['', []],
    detention_days:['', []],
    detention_charges:['', []],
    estimated_transit_time:['', []],
    hire_excess_approval_remarks:['', []],
    hire_excess_approved_by:['', []],
    hire_excess_approved_by_name:['', []],
    hire_excess_reason:['', []],
    payment_to_partner_banks_id: ['', []],
    tds_declaration_scan:['', []],
    thc_no:['', []],
    thc_scan:['', []],
    advance_payment_cash_amt:['', []],
    advance_payment_transfer_amt:['', []],
    advance_payment_utilities_amt:['', []],
    balance_payment_transfer_amt:['', []],
    balance_payment_cash_amt:['', []],
    balance_payment_utilities_amt:['', []],
    balance_request_transfer_amt:['', []],
    balance_request_cash_amt:['', []],
    partner_banks_id: ['', []],
    reason_no_sim_tracking: ['', []],
    vendor_invoice_scan: ['', []],
    vendor_invoice_webxpress_code: ['', []],
    // advance_amt: ['', []],
    ath_amt: ['', []],
    bth_amt: ['', []],
    ath_paid_amt: ['', []],
    paid_amt: ['', []],
}
export const changeTruckDriverFormControls = {
  truck_hire_challans_id: ['', []],
  trucks_id: ['', []],

  registration_no: ['', [Validators.required]],
  registration_scan: ['', []],
  insurance_scan: ['', []],
  fitness_scan: ['', []],
  permit_scan: ['', []],
  pollution_scan: ['', []],

  drivers_id: ['', []],
  driver_mobile_no: [
    '',
    [
      Validators.required,
      Validators.min(1000000000),
      Validators.max(9999999999)
    ]
  ],
  driver_name: ['', [Validators.required]],
  is_driver_changed: ['', []],
  driving_license_scan: ['', []],

  mobile_provider_cd: ['', []],
  telenity_enabled: [0, []],
  send_telenity_consent: [1, []],

}
export const assignTruckFormControls = {
  truck_hire_challans_id: ['', []],
  trips_id: ['', []],

  trucks_id: ['', []],
  registration_no: ['', [Validators.required]],
  // for any change , new thc would be created and remaining cancelled
  drivers_id: ['', []],
  driver_mobile_no: [
    '',
    [
      Validators.required,
      Validators.min(1000000000),
      Validators.max(9999999999)
    ]
  ],
  driver_name: ['', [Validators.required]],
  mobile_provider_cd: ['', []],
  telenity_enabled: [0, []],
  mobile_no: [
    '',
    [
      Validators.required,
      Validators.min(1000000000),
      Validators.max(9999999999)
    ]
  ],

  // partners_id: ['', []],
  brokers_id: ['', []],
  broker_contacts_id: ['', []],

  partner_contact_name: ['', [Validators.required]],
  partner_company_name: ['', [Validators.required]],
  partner_type_cd: ['BROKER', [Validators.required]],
  hire_amt: ['', [Validators.required]],
  // advance_amt: ['', []],
  balance_amt: ['', []],
  detention_amt_per_day: ['', []],

  freight_amt: ['', []],
  hire_percentage: ['', []],
  hire_excess_reason: ['', []],
  hire_excess_approved_by: ['', []],
  hire_excess_approved_by_name: ['', []],
  hire_excess_approval_remarks: ['', []],

  advance_percentage: ['', []],
  advance_excess_reason: ['', []],
  advance_excess_approved_by: ['', []],
  advance_excess_approved_by_name: ['', []],
  advance_excess_approval_remarks: ['', []],

  reporting_at: [null, [Validators.required]],
  reporting_at_time: ['', [Validators.required]],
  estimated_transit_time: ['', [Validators.required]],

  pan_no: ['', [Validators.pattern(appGlobals.pan_pattern)]],
  pan_card_scan: ['', []],
  vendor_slip_scan: ['', []],
  tds_declaration_scan: ['', []],

  eway_bill_no: ['', []],
  delivery_condition_cd: ['', []],

  partner_banks_id: ['', []],
  bank_ac_bank_name: ['', []],
  bank_ac_ifsc: ['', []],
  bank_ac_no: ['', []],
  bank_ac_holder_name: ['', []],
  bank_cheque_scan: ['', []],

  tasks_id: ['', []],

  ath_amt: ['', []],
  bth_amt: ['', []],
  ath_paid_amt: ['', []],
  paid_amt: ['', []],

};
export const attachTruckDocumentsFormControls = {
  trips_id: ['', []],
  drivers_id: ['', []],
  partners_id: ['', []],
  partner_contacts_id: ['', []],
  // brokers_id: ['', []],
  trucks_id: ['', []],
  truck_hire_challans_id: ['', []],
  registration_scan: ['', [Validators.required]],
  insurance_scan: ['', []],
  fitness_scan: ['', []],
  permit_scan: ['', []],
  pollution_scan: ['', []],
  driving_license_scan: ['', [Validators.required]],
  partner_contact_name: ['', [Validators.required]],
  mobile_no: [
    '',
    [
      Validators.required,
      Validators.min(1000000000),
      Validators.max(9999999999)
    ]
  ],
  partner_company_name: ['', [Validators.required]],
  partner_type_cd: ['FLEET OWNER', [Validators.required]],
  estimated_transit_time: ['', []],
  pan_no: ['', [Validators.pattern(appGlobals.pan_pattern)]],
  pan_card_scan: ['', []],
  tds_declaration_scan: ['', []],
  eway_bill_no: ['', []],
  mobile_provider_cd: ['', []],
  telenity_enabled: [0, []],
  reason_no_sim_tracking: ['', []],
  tasks_id: ['', []],
  driver_name: [{value: '', disabled: true}, []],
  driver_mobile_no: [{value: '', disabled: true}, []],

  consignment_notes_id: ['', []],
  trucks_selfie_scan: ['', []],
  trucks_front_scan: ['', []],
  trucks_back_scan: ['', []],
  trucks_side_scan: ['', []],
  trucks_tyre_scan: ['', []],
  trucks_nib_scan: ['', []],
  trucks_tarpoline_scan: ['', []],
};
export const requestAdvanceFormControls = {
  trips_id: ['', []],
  partner_type_cd: [null, [Validators.required]],
  partners_id: ['', []],
  partner_contacts_id: ['', []],
  truck_hire_challans_id: ['', [Validators.required]],
  bank_ac_bank_name: ['', []],
  bank_ac_ifsc: ['', []],
  bank_ac_no: ['', []],
  bank_ac_holder_name: ['', []],
  bank_cheque_scan: ['', []],
  payment_to_partner_banks_id: ['', [Validators.required]],
  partner_banks_id: ['', []],
  hire_amt: ['', []],
  advance_cash_amt: [0, []],
  advance_transfer_amt: ['', []],
  advance_utilities_amt: ['', []],
  balance_amt: ['', []],
  advance_percentage: ['', []],
  advance_excess_reason: ['', []],
  advance_excess_approved_by: ['', []],
  advance_excess_approved_by_name: ['', []],
  advance_excess_approval_remarks: ['', []],
  pan_no: [
    '',
    []
  ],
  pan_card_scan: ['', []],
  detention_amt_per_day: ['', []],
  detention_days: ['', []],
  tds_declaration_scan: ['', []],
  vendor_slip_scan: ['', []],
  webxpress_code: ['', []],
  reason_no_sim_tracking: ['',  []],
  vendor_invoice_scan: ['', []],
  vendor_invoice_webxpress_code: ['', []],
  tasks_id: ['', []],
  remarks: ['', []],
  task_comments: ['', []],

  ath_amt: ['', []],
  bth_amt: ['', []],
  ath_paid_amt: ['', []],
  paid_amt: ['', []],
  thc_payments_id: ['', []],
  thc_payments: ['', []],

};
export const advancePaidFormControls = {
  trips_id: ['', []],
  partners_id: ['', []],
  truck_hire_challans_id: ['', []],
  advance_payment_transfer_amt: ['', []],
  advance_payment_cash_amt: [0, []],
  advance_payment_utilities_amt: [0, []],
  advance_payment_cash_paid_by: ['', []],
  advance_payment_cash_paid_by_name: ['', []],
  advance_payment_ref: ['', []],
  advance_payment_utilities_ref: ['', []],
  advance_payment_proof_scan: ['', []],

  remarks: ['', []],
  tasks_id: ['', []],
  thc_payments_id: ['', []],
  thc_payments: ['', []],
};
export const approveExcessHireFormControls = {
  trips_id: ['', []],
  partners_id: ['', []],
  truck_hire_challans_id: ['', []],
  hire_amt: ['', [Validators.required]],
  hire_excess_reason: ['', []],
  hire_excess_approval_remarks: ['', []],
  hire_excess_approved_by: ['', []],
  hire_excess_approved_by_name: ['', []]
};
export const approveExcessAdvanceFormControls = {
  trips_id: ['', []],
  partners_id: ['', []],
  truck_hire_challans_id: ['', []],
  total_advance_amt: ['', [Validators.required]],
  advance_excess_reason: ['', []],
  advance_excess_approval_remarks: ['', []],
  advance_excess_approved_by: ['', []],
  advance_excess_approved_by_name: ['', []]
};
export const requestBalanceFormControls = {
  trips_id: ['', []],
  truck_hire_challans_id: ['', []],
  partner_type_cd: ['', [Validators.required]],
  bank_ac_bank_name: ['', []],
  bank_ac_ifsc: ['', []],
  bank_ac_no: ['', []],
  bank_cheque_scan: ['', []],
  pan_no: ['', [Validators.pattern(appGlobals.pan_pattern)]],
  pan_card_scan: ['', []],

  bth_amt: ['', []],
  balance_amt: ['', []],
  detention_amt_per_day: ['', []],
  detention_days: ['', []],
  detention_charges: ['', []],
  loading_charges: ['', []],
  unloading_charges: ['', []],
  other_charges: ['', []],
  other_charges_head: ['', []],
  total_balance_amt: ['', []],
  balance_request_cash_amt: ['', []],
  balance_request_transfer_amt: ['', []],
  partner_banks_id: ['', []],
  partners_id: ['', []],
  payment_to_partner_banks_id: ['', []],
  reason_no_sim_tracking: ['', []],
  vendor_invoice_scan: ['', []],
  vendor_invoice_webxpress_code: ['', []],
  tasks_id: ['', []],
  remarks: ['', []],
  webxpress_code: ['', []],
  deduction_amt: ['', []],
  deduction_reason_head: ['', []],
  thc_payments: ['', []],
};
export const balancePaidFormControls = {
  trips_id: ['', []],
  partners_id: ['', []],
  truck_hire_challans_id: ['', []],
  balance_payment_cash_amt: [0, []],
  balance_payment_utilities_amt: ['', []],
  balance_payment_transfer_amt: ['', []],
  balance_payment_ref: ['', []],
  balance_payment_proof_scan: ['', []],
  balance_payment_cash_paid_by: ['', []],
  balance_payment_cash_paid_by_name: ['', []],

  total_balance_amt: ['', []],
  deduction_amt: ['', []],
  deduction_reason_head: ['', []],
  vendor_invoice_scan: ['', []],
  vendor_invoice_webxpress_code: ['', []],
  tasks_id: ['', []],
  thc_payments: ['', []],
};

