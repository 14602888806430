import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
import { EntityService } from '../../entity/services/entity.service';
@Injectable()
export class PaymentsService extends EntityService {
  entityName = 'payments';
  constructor(protected serviceHttp:HttpService) {
    super(serviceHttp);
  }

}
