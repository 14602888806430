/// <reference types="gapi" />
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import {
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticatorService } from '../../../core/services/authenticator.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { BidsService } from '../../bids/services/bids.service';
import { BookingsService } from '../../bookings/services/bookings.service';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { DriverRatingsService } from '../../driverRatings/services/driverRatings.service';
import { DriversService } from '../../drivers/services/drivers.service';
import { PartnerContactsService } from '../../partnerContacts/services/partnerContacts.service';
import { PartnersService } from '../../partners/services/partners.service';
import { TruckHireChallansService } from '../../truckHireChallans/services/truckHireChallans.service';
import { TrucksService } from '../../trucks/services/trucks.service';
import { UsersService } from '../../users/services/users.service';
import { TripsService } from '../../trips/services/trips.service';
import { TripsListComponent, TripsDataSource } from '../../trips/list/trips.list.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Trips } from '../../trips/trips';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import * as appGlobals from '../../../core/globalfunctions';
import { StorageService } from '../../../core/services/storage.service';
import { TasksService } from '../../tasks/services/tasks.service';
import { Tasks, tasksFormControls } from '../../tasks/tasks';
import { Observable, of } from 'rxjs';
import { TruckHireChallans } from '../../truckHireChallans/truckHireChallans';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { ConsignmentNotes } from '../../consignmentNotes/consignmentNotes';
import { TripliteInternalComponent } from './triplite.internal.component';
import { InvoicesService } from '../../invoices/services/invoices.service';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-triplite-task',
  templateUrl: './list.task.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    TripsService,
    ConsignmentTripTrackingsService,
    DialogService,
    DriverRatingsService,
    DatePipe,
    BidsService,
    BookingsService,
    TruckHireChallansService,
    FileTransferService,
    UsersService,
    TrucksService,
    DriversService,
    PartnersService,
    PartnerContactsService,
    AuthenticatorService,
    StorageService,
    TasksService,
    ConsignmentNotesService,
    InvoicesService,
    NotificationsService,
    MatDialog
  ]
})
export class TripliteTaskComponent extends TripliteInternalComponent implements OnInit {
  displayedColumns = [
    'cbox',
    'customer',
    'origin',
    'truck_type',
    // 'destination_city',
    'action',
  ];
  usePreviewPanel = true;
  selectedActivityIndex = 'CONFIRM';
  leftTemplate: TemplateRef<any>;

  @ViewChild('reassignTask', { static: true }) reassignTask: TemplateRef<any>;
  @ViewChild('escalateTask', { static: true }) escalateTask: TemplateRef<any>;
  @ViewChild('viewTask', { static: true }) viewTask: TemplateRef<any>;
  @ViewChild('listTrips', { static: true }) listTrips: TemplateRef<any>;

  tasksList = new Array<Tasks>();
  task: Tasks;
  tasksDataSource: TasksDataSource;
  taskSelection = new SelectionModel<Tasks>(true, []);
  tasksFormGroup: FormGroup;
  cNote: ConsignmentNotes;
  paymentStatus = "true";
  constructor(
    _formBuilder: FormBuilder,
    mapsAPILoader: MapsAPILoader,
    ngZone: NgZone,
    router: Router,
    route: ActivatedRoute,
    modalService: BsModalService,
    _snackBarService: MatSnackBar,
    _dialogService: DialogService,
    tripsService: TripsService,
    thcService: TruckHireChallansService,
    bookingsService: BookingsService,
    userService: UsersService,
    truckService: TrucksService,
    partnerService: PartnersService,
    partnerContactService: PartnerContactsService,
    driverService: DriversService,
    fileTransferService: FileTransferService,
    consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    driverRatingsService: DriverRatingsService,
    datepipe: DatePipe,
    bidsService: BidsService,
    authenticatorService: AuthenticatorService,
    sanitizer: DomSanitizer,
    consignmentNotesService: ConsignmentNotesService,
    protected tasksService: TasksService,
    protected invoiceService: InvoicesService,
    protected notificationsService: NotificationsService,
    dialog:MatDialog,
  ) {

    super(
      _formBuilder, mapsAPILoader, ngZone,
      router, route, modalService,
      _snackBarService,
      _dialogService,
      tripsService,
      thcService,
      bookingsService,
      userService,
      truckService,
      partnerService,
      partnerContactService,
      driverService,
      fileTransferService,
      consignmentTripTrackingsService,
      driverRatingsService,
      datepipe,
      bidsService,
      authenticatorService,
      sanitizer,
      consignmentNotesService,
      tasksService,
      invoiceService,
      notificationsService,
      dialog
    );

    }

    ngOnInit() {
      this.deferGetResponse = true;
      super.ngOnInit();
      this.authenticatorService.selectedMenuName.subscribe((res:any)=>{
     //  console.log("res.name : ",res);
        this.pageName = res.name;
        if(this.pageName==undefined){
          this.pageName = '';
        }
      });
      this.leftTemplate = this.listTrips;
      this.tasksFormGroup = this._formBuilder.group(tasksFormControls);
      this.tripsSearchForm.patchValue({assignee: false});
      this.getResponse();

      // this.taskSelection = new SelectionModel<Tasks>(true, [this.tasksDataSource.data[0]]);
    }
    getResponse(pageNo = 1) {
      this.currentPage = pageNo;
      // this.observeFieldChanges();

      return this.tasksService.getList(Object.assign(
        {}, this.mergeWithSearch(), {'list_type': 'ALL'}) ).subscribe(
        response => {
       //  console.log(response);
          this.tasksList = Tasks.toArray(response.data);
          //data = this.tripsList;
       //  console.log(this.tasksList);
          this.tasksDataSource = new TasksDataSource(this.tasksList);
          this.total = response.total;
          this.per_page = response.per_page;
          this.currentPage = response.current_page;
       //  console.log(this.mergeWithSearch());
          this.router.navigate([], { relativeTo: this.route, queryParams: this.mergeWithSearch(), queryParamsHandling: '' });
          this.doPostResponse(response);
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );

    }
    setAssignee(option){
      this.tasksFormGroup.patchValue({assignee: option.uuid});
    }

    setEscalateTo(option){
      this.tasksFormGroup.patchValue({escalate_to: option.uuid});
    }

    reassignTaskExecutive(template, element){
      const entityType = element.entity_type;
      this.task = new Tasks(element);
      this.tasksFormGroup.patchValue(element);
      this.lookupEmployeesForTask();
   //  console.log(this.task);
      if(element.isComplete()){
        this.modalRef = this.openModalGeneric(template);
        return;

      }
      if(entityType == 'Trip') {
        return this.tripsService.getTripsById(element.entity_id).subscribe(
          response => {
         //  console.log(response);
            this.trips = new Trips(response.data);
         //  console.log(this.task);
         //  console.log(this.trips);
            // this.task = new Tasks(element);
            // this.tasksFormGroup.patchValue(element);
            // this.lookupEmployeesForTask();
            this.modalRef = this.openModalGeneric(template);

          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
      if(entityType == 'Truck Hire Challan') {
        return this.thcService.getTruckHireChallansById(element.entity_id).subscribe(
          response => {
         //  console.log(response);
            this.truckHireChallan = new TruckHireChallans(response.data);
            this.trips = this.truckHireChallan.trip;
            // this.task = new Tasks(element);
            // this.tasksFormGroup.patchValue(element);
            // this.lookupEmployeesForTask();
            this.modalRef = this.openModalGeneric(template);
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
      if(entityType == 'Consignment Note') {
        return this.consignmentNotesService.getConsignmentNotesById(element.entity_id).subscribe(
          response => {
         //  console.log(response);
            this.cNote = new ConsignmentNotes(response.data);
            if(response.data.consignment_trips && response.data.consignment_trips[0]){
              this.trips = response.data.consignment_trips[0].trip;
            }

            // this.task = new Tasks(element);
            // this.tasksFormGroup.patchValue(element);
            // this.lookupEmployeesForTask();
            this.modalRef = this.openModalGeneric(template);
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }

    }
    lookupEmployeesForTask(){
      appGlobals.observeChanges(
        this,
        this.tasksFormGroup.controls.assignee_name,
        'employeeListAbs',
        'getExecutiveSearchService',
        'setExecutiveList',
        'name'
      );
      appGlobals.observeChanges(
        this,
        this.tasksFormGroup.controls.escalate_to_name,
        'employeeListAbs',
        'getExecutiveSearchService',
        'setExecutiveList',
        'name'
      );
    }
    rowClickToggle(element){
      if(element.lifecycle_state_cd != 'COMPLETE') {
        this.reassignTaskExecutive(this.reassignTask , element);
      }
      else {
        this.reassignTaskExecutive(this.viewTask , element);
      }
    }
    getRowColor(aData: Tasks) {
      let now = new Date();
      if(aData.completed_at) {
        now = aData.completed_at;
      }
      let secsAllowed = (aData.due_at.getTime() - aData.created_at.getTime()) / 1000;
      let secsPassed = ( now.getTime() - aData.created_at.getTime())/1000;
      if(secsPassed > secsAllowed) {
        return 'border-red';
      }
      if(secsPassed > (2*secsAllowed/3) ){
        return 'border-orange';
      }
      return 'border-green';
    }
    submitTaskAssignExecutive(){
      if (!this.tasksFormGroup.valid) {
        this.isError = true;
        this.errorMessages = appGlobals.getFormValidationErrors(
          this.tasksFormGroup
        );
        appGlobals.markFormGroupTouched(this.tasksFormGroup);
      } else {
        let formData = this.tasksFormGroup.value;
     //  console.log(formData);
        this.tasksService.updateTask(formData).subscribe(
          response => {
            if (response.status === 200) {
              this.closeModalGeneric();
              this.getResponse(this.currentPage);
              this._snackBarService.open(response.message, 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });
            } else {
              this._dialogService.openAlert({
                title: 'Error',
                message: response.message
              });
            }
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
    }
    submitTaskEscalate(){
      if (!this.tasksFormGroup.valid) {
        this.isError = true;
        this.errorMessages = appGlobals.getFormValidationErrors(
          this.tasksFormGroup
        );
        appGlobals.markFormGroupTouched(this.tasksFormGroup);
      } else {
        let formData = this.tasksFormGroup.value;
     //  console.log(formData);
        this.tasksService.updateTask(formData).subscribe(
          response => {
            if (response.status === 200) {
              this.closeModalGeneric();
              this.getResponse(this.currentPage);
              this._snackBarService.open(response.message, 'X', {
                duration: appGlobals.SNACKBAR_TIMEOUT
              });
            } else {
              this._dialogService.openAlert({
                title: 'Error',
                message: response.message
              });
            }
          },
          error => {
            this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
          }
        );
      }
    }
    doPostResponse(response){
   //  console.log('doing post response');
   //  console.log(this.modalService.getModalsCount());
      if(this.usePreviewPanel && this.isDesktop()){
        this.reassignTaskExecutive(this.reassignTask, this.tasksDataSource.data[0]);
        //  this.rowOnClick(this.tasksDataSource.data[0]);
      }
   //  console.log(this.modalService.getModalsCount());

    }
    isAllSelected() {
      // console.log('is all selected');
      const numSelected = this.taskSelection.selected.length;
      const numRows = this.tasksDataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
   //  console.log('master toggle');
      this.isAllSelected() ?
          this.taskSelection.clear() :
          this.tasksDataSource.data.forEach(row => this.taskSelection.select(row));
    }
    getTruckType(element){
      let truckType = this.getTruckTypeValue(element.truck_type_cd);
      if(truckType) {
        return truckType.substring(0,20);
      }
      return '';
    }
    getNextStatus(element) {
      if (element.trips_status_cd === 'TRIP COMPLETE') {
        return '';
      }
      const nextStatus = Trips.nextStatus(
        element.trips_status_cd,
        element.business_type_cd,
        element.movement_type_cd
      );

      if(nextStatus) {
        return nextStatus.toLowerCase();
      }
      return '';
    }
    getNextActionButtonText(element) {
      return element.name;
    }
    setInitialFilter() {
      const user = StorageService.getUser();
      if (
        StorageService.userIsLE() ||
        StorageService.userIsTE() ||
        StorageService.userIsBE() ||
        StorageService.userIsCT()
        ) {
        this.setExecutive(user);
      } else {
        if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
          this.setterritoryCd([user.territory_cd]);
        }
      }
      if(StorageService.userIsROH()){
        this.setTripStatusCd(['NEW', 'ORDER CONFIRMED']);
      }
      if(StorageService.userIsLE()) {

        this.setTripStatusCd(['VEHICLE ASSIGNED',
        'ON THE WAY TO LOADING POINT', 'REACHED LOADING POINT',
        'LOADING STARTED', 'LOADING COMPLETE', 'PAPER WORK DONE']);
      }

    }
    ngAfterViewInit() {
      if(this.tasksDataSource && this.tasksDataSource.data) {
     //  console.log('afterViewInit => ', this.tasksDataSource.data ? this.tasksDataSource.data.length : 0 );

      }

    }
    currentAction: any;
    rowOnClick(row) {
      if(row && row.id) {
     //  console.log('on row click ' + row.id);
     //  console.log(row);
        // this.selection.toggle(row);
        // if(this.selection.isSelected(row)) {
          return this.getNextActionButtonClickFirst(row);
        // }
      }
      // return this.selection.toggle(row);
    }
    getNextActionButtonClickFirst(element) {
   //  console.log('clicked next action');
      return this.getNextActionButtonClick(element, element.entity_id, element.entity_type);
    }
    onClickAction(element){
      this.getNextActionButtonClick(element, element.entity_id, element.entity_type);
    }
    getNextActionButtonClick(element, entityId, entityType = 'Trip' ) {

   //  console.log('clicked next action ' + element.id + this.getNextActionButtonText(element));
      return this.tripsService.getTripsById(entityId).subscribe(
        response => {
       //  console.log(response);
          this.trips = new Trips(response.data);
          this.openModalByAction(this.trips,
            this.getNextActionButtonText(element), entityType, entityId);
          // this.tripsChangeExecutiveForm.patchValue(this.trips);
          // this.lookupEmployees();
          // this.modalRef = this.openModalGeneric(template);
          switch(entityType){
            case 'Trip': {

              break;
            }
            default: {

            }
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );

    }
    getStatusFromAction(entityAction){
      switch(entityAction){
        case 'CONFIRM': {
          return 'TRIP CONFIRMED';
          break;
        }
        case 'ASSIGN VEHICLE': {
          return 'ASSIGN VEHICLED';
          break;
        }
        case 'START LOADING ': {
          return 'ON THE WAY TO LOADING POINT';
          break;
        }
        case 'CONFIRM REACHED EMPTY YARD': {
          return 'REACHED EMPTY YARD';
          break;
        }
        case 'CONFIRM CONTAINER PICKED': {
          return 'CONTAINER PICKED';
          break;
        }
        case 'CONFIRM LOADING START': {
          return 'LOADING STARTED';
          break;
        }
        case 'CONFIRM LOADING END': {
          return 'LOADING COMPLETE';
          break;
        }
        case 'CONFIRM PAPER WORK DONE': {
          return 'PAPER WORK DONE';
          break;
        }
        case 'SEND CONSENT REQUEST': {
          return 'CONSENT REQUESTED';
          break;
        }
        case 'CONFIRM IN TRANSIT': {
          return 'IN TRANSIT';
          break;
        }
        case 'TRACK': {
          return 'IN TRANSIT';
          break;
        }
        case 'CONFIRM REACHED DESTINATION': {
          return 'REACHED DESTINATION';
          break;
        }
        case 'CONFIRM UNLOADING START': {
          return 'UNLOADING STARTED';
          break;
        }
        case 'CONFIRM UNLOADING END': {
          return 'UNLOADING COMPLETE';
          break;
        }
        case 'CONFIRM POD': {
          return 'POD GENERATED';
          break;
        }
        case 'CONFIRM REACHED EMPTY YARD': {
          return 'REACHED EMPTY YARD';
          break;
        }
        case 'DROP CONTAINER': {
          return 'CONTAINER DROPPED';
          break;
        }
        case 'COMPLETE TRIP': {
          return 'TRIP COMPLETED';
          break;
        }
      }
    }
    updateToStatus(tripData: Trips, entityAction, entityType, entityId) {
      const next_status_cd = this.getStatusFromAction(entityAction);
      this.openModalUpdateStatus(this.updateStatus, tripData, 'STATUS', next_status_cd);
    }
    openModalByAction(element, entityAction, entityType, entityId){

   //  console.log(element.trips_status_cd + ' | ' + entityAction + ' | ' +  entityType + ' | ' +  entityId);
      switch(entityAction){
        case 'CONFIRM': {
          return this.openModalConfirmTrip(this.confirmTrip, element);
          break;
        }
        case 'ASSIGN VEHICLE': {
          return this.openModalAssignTruck(this.assignTruck, element);
          break;
        }
        case 'CONFIRM LOADING END': {
          return this.openModalTruckDocument(this.truckDocuments, element);
          break;
        }
        case 'CONFIRM PAPER WORK DONE': {
          return this.goToConsignment(element);
          break;
        }
        default: {
          return  this.updateToStatus(element, entityAction, entityType, entityId);
        }
      }
      // 'NEW' => 'CONFIRM',
      //   'ORDER CONFIRMED' => 'CONFIRM',
      //   'TRIP CONFIRMED' => 'ASSIGN VEHICLE',
      //   'VEHICLE ASSIGNED' => 'START FOR LOADING PT.',
      //   'REACHED EMPTY YARD' => 'CONFIRM REACHED EMPTY YARD',
      //   'CONTAINER PICKED' => 'CONFIRM CONTAINER PICKED',
      //   'ON THE WAY TO LOADING POINT' => 'CONFIRM REACHED LOADING',
      //   'REACHED LOADING POINT' => 'CONFIRM LOADING START',
      //   'LOADING STARTED' => 'CONFIRM LOADING END',
      //   'LOADING COMPLETE' => 'CONFIRM PAPER WORK DONE',
      //   'CONSENT REQUESTED' => 'SEND CONSENT REQUEST',
      //   'PAPER WORK DONE' => 'CONFIRM IN TRANSIT',
      //   'IN TRANSIT' => 'TRACK',
      //   'REACHED DESTINATION' => 'CONFIRM UNLOADING START',
      //   'DESTUFFING STARTED' => 'CONFIRM DESTUFFING STARTED',
      //   'DESTUFFING COMPLETE' => 'CONFIRM DESTUFFING COMPLETE',

      //   'UNLOADING STARTED' => 'CONFIRM UNLOADING END',
      //   'UNLOADING COMPLETE' => 'CONFIRM POD',
      //   'REACHED EMPTY YARD DROP' => 'CONFIRM REACHED EMPTY YARD',
      //   'CONTAINER DROPPED' => 'DROP CONTAINER',
      //   'POD GENERATED' => 'COMPLETE TRIP',
      //   'TRIP COMPLETE' => 'DONE',
      // if(entityAction == 'CONFIRM') {
      //  console.log('opening confirm trip link');
      //  console.log(element);
      //   // return  this.sanitizer.sanitize('openModalConfirmTrip(confirmTrip, element)');
      //   return this.openModalConfirmTrip(this.confirmTrip, element);
      // }
      // if(entityAction == 'ASSIGN VEHICLE') {
      //  console.log('opening assign truck trip link');
      //   // return  this.sanitizer.sanitize('openModalAssignTruck(assignTruck, element)');
      //   return this.openModalAssignTruck(this.assignTruck, element);
      // }
      // if(this.needUploadTruckLink(element)) {
      //  console.log('opening attach trip link');
      //   // return  this.sanitizer.sanitize('openModalTruckDocument(truckDocuments, element)');
      //   return this.openModalTruckDocument(this.truckDocuments, element);
      // }
      // if(this.needCosingnmentUpdate(element)) {
      //  console.log('opening update consignment trip link');
      //   // return  this.sanitizer.sanitize('goToConsignment(element)');
      //   return this.goToConsignment(element);
      // }
      // if(this.needConsentSMS(element)){
      //  console.log('opening need sms trip link');
      //   // return  this.sanitizer.sanitize('getTelenityLocation(element)');
      //   return this.getTelenityLocation(element);
      // }
      // // return  this.sanitizer.sanitize('updateToNextStatus(element)');
      //  console.log('opening next status trip link');
      // return  this.updateToNextStatus(element);

    }
    get getTemplate() {
      if(this.currentTemplate) {
        return this.currentTemplate;
      }
      return false;
      // return this.updateStatus;
      // if (this.web) {
      //   return this.webTemplate;
      // }
      // else {
      //   return this.mobileTemplate;
      // }

    }
    onActivityChange(event){
   //  console.log(event);

      this.selectedActivityIndex = event.value;
   //  console.log("Name : ",this.selectedActivityIndex);
    }
  }

  export class TasksDataSource extends DataSource<any> {
    data: any;
    constructor(aData?: Tasks[]) {
      super();
      this.data = aData;
    }
    connect(): Observable<Tasks[]> {
      return of(this.data);
    }
    getIndex(id) {
      for (let index = 0; index < this.data.length; index++) {
        if(this.data[index].id == id){
          return index;
        }

      }
      return -1;
    }
    remove(id) {
      const itemIndex = this.getIndex(id);
   //  console.log('found ' + id + ' at ' + itemIndex) ;
      this.data = this.data.splice(itemIndex, 1);
   //  console.log('removed');
    }
    disconnect() { }
  }
