import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { DropdownsModule } from '../../layout/dropdowns/dropdowns.module';
import { RoleGuard } from '../../core/guards/role.guard';
import {
  admin_driver_fleetOwner_partner,
  employee
} from '../../core/constants';
import { AuthGuard } from '../../core/guards/auth.guard';
import { TripliteListComponent } from './list/triplite.list.component';
import { MapModule } from '../../layout/map/map.module';
// import { GalleryPlainComponent } from './gallery/plain.component';
// import { AuthGuard } from '../../core/guards/auth.guard';
// import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { Gallery2PlainComponent } from './gallery2/plain.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TripliteCnoteListComponent } from './cnoteList/list.component';
import { TripStatusTrackingsLiteListComponent } from './tripStatus/tripStatusTrackingsLite.list.component';
import { TripliteInternalComponent } from './internal/triplite.internal.component';
import { TripliteTaskComponent } from './internal/triplite.task.component';
import { ConsignmentEditComponent } from './cnoteEdit/edit.component';
// import { InvoicesEditComponent } from '../invoices/edit/invoices.edit.component';
import { InvoicesModule } from '../invoices/invoices.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatErrorModule } from '../../layout/mat-error/mat-error.module';
// import { ConsignmentNotesModule } from '../consignmentNotes/consignmentNotes.module';
import { NgxImgZoomModule } from 'ngx-img-zoom';
export const tripliteRoutes: Routes = [
  {
    path: 'triplite',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: 'list',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteListComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      {
        path: 'internal',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteInternalComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      {
        path: 'internal/workarea/:work_area_cd',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteInternalComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      {
        path: 'internal/mytasks/:employee',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteInternalComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      {
        path: 'tasks',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteTaskComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      {
        path: 'list/booking/:bookingId',
        canActivate: [RoleGuard],
        component: TripliteListComponent,
        data: { roles: employee }
      },
      {
        path: 'cnotelist',
        canActivate: [AuthGuard, RoleGuard],
        component: TripliteCnoteListComponent,
        data: { roles: admin_driver_fleetOwner_partner }
      },
      { path: 'cnotelist/booking/:bookingId',canActivate:[RoleGuard], component: TripliteCnoteListComponent, data:{ roles: employee}  },
			{ path: 'cnotelist/customer/:customerId',canActivate:[RoleGuard], component: TripliteCnoteListComponent, data:{ roles: employee}  },
      { path: 'cnotelist/trip/:tripId',canActivate:[RoleGuard], component: TripliteCnoteListComponent, data:{ roles: employee}  },
      { path: '**', redirectTo: 'list', pathMatch: 'full' }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(tripliteRoutes),
    SharedModule,
    DropdownsModule,
    MatErrorModule,
    MapModule,
    PdfViewerModule,
    // ConsignmentNotesModule,
    InvoicesModule,
    MatTableExporterModule,
    NgxImgZoomModule,
  ],
  declarations: [
    TripliteListComponent,
    Gallery2PlainComponent,
    TripliteCnoteListComponent,
    TripStatusTrackingsLiteListComponent,
    TripliteInternalComponent,
    TripliteTaskComponent,
    ConsignmentEditComponent,
    // InvoicesEditComponent,
    // PdfViewerComponent,
  ],
  exports: [
    TripliteListComponent,
    TripliteCnoteListComponent,
    TripStatusTrackingsLiteListComponent,
    Gallery2PlainComponent,
    TripliteInternalComponent,
    TripliteTaskComponent,
    ConsignmentEditComponent,

    // PdfViewerComponent,
  ]
})
export class TripliteModule {}
