import { Validators } from "@angular/forms";
import { ifsc_pattern } from '../../core/globalfunctions';
import * as appGlobals from '../../core/globalfunctions';
import { Partners } from '../partners/partners';

export class PartnerBanks {
    id : number;
    bank_ac_holder_name : string;
    bank_ac_no : string;
    bank_ac_bank_name : string;
    bank_ac_ifsc : string;
    partners_id : number;
    bank_cheque_scan: string;

    is_verified: number;
    verified_by: number;
    verified_at: Date;
    is_active: number;

    bank_account_holder_name: string; // as per bank
    bank_checked: number;
    bank_checked_dt: Date;
    bank_response_message: string;
    name_at_bank: string;
    quicko_checked: number;
    quicko_checked_dt: Date;
    quicko_response_message: string;
    partner: Partners;
    partner_company_name: string;

    static commandsAllIndex = [
    ];
    static allStatuses = [
    ];

    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : PartnerBanks[] {
        let partnerBanks : PartnerBanks[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                partnerBanks.push(new PartnerBanks(json));
            }
        }
        return partnerBanks;
    }
    public fill(json: any){
      if (json != null) {
        this.id = json.id;
        this.bank_ac_holder_name = json.bank_ac_holder_name;
        this.bank_ac_no = json.bank_ac_no;
        this.bank_ac_bank_name = json.bank_ac_bank_name;
        this.bank_ac_ifsc = json.bank_ac_ifsc;
        this.partners_id = json.partners_id;
        this.bank_cheque_scan = json.bank_cheque_scan;

        this.is_verified = json.is_verified;
        this.verified_by = json.verified_by;
        appGlobals.safeDate(this, json, 'verified_at');
        this.is_active = json.is_active;

        this.bank_account_holder_name = json.bank_account_holder_name;
        this.bank_checked = json.bank_checked;
        appGlobals.safeDate(this, json, 'bank_checked_dt');
        this.bank_response_message = json.bank_response_message;
        this.name_at_bank = json.name_at_bank;
        this.quicko_checked = json.quicko_checked;
        appGlobals.safeDate(this, json, 'quicko_checked_dt');
        this.quicko_response_message = json.quicko_response_message;
        if(json.partner){
          this.partner = new Partners(json.partner);
          this.partner_company_name = this.partner.partner_company_name;
        }

      }
    }
}
export let partnerBanksFormControls = {
    id: ['', []],
    bank_ac_holder_name: ['', []],
    partner_company_name: ['', []],
    bank_ac_no: ['', [Validators.minLength(9),Validators.maxLength(18)]],
    bank_ac_bank_name: ['', [Validators.minLength(3)]],
    bank_ac_ifsc: ['', [Validators.pattern(ifsc_pattern)]],
    partners_id: ['', []],
    bank_cheque_scan: ['', []],
    name_at_bank: ['', []],
    bank_response_message: ['', []],
    quicko_response_message: ['', []]
  };
