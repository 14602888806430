import { TruckHireChallans } from '../truckHireChallans/truckHireChallans';
import { Bookings } from '../bookings/bookings';
import { Customers } from '../customers/customers';
import { ConsignmentNotes } from '../consignmentNotes/consignmentNotes';
import { ConsignmentTrips } from '../consignmentTrips/consignmentTrips';
import { TripAddresses } from '../tripAddresses/tripAddresses';
import { Validators } from '@angular/forms';
import { DriverRatings } from '../driverRatings/driverRatings';
import { Drivers } from '../drivers/drivers';
import { Trucks } from '../trucks/trucks';
import * as appGlobals from '../../core/globalfunctions';
import { TripStatusTrackings } from '../tripStatusTrackings/tripStatusTrackings';
import { Triplite } from './triplite';
export class Trips extends Triplite {
  static  statusesIndex = [
    'NEW',
    // 'ORDER CONFIRMED',
    'TRIP CONFIRMED',
    'VEHICLE ASSIGNED',
    'ON THE WAY TO LOADING POINT',
    'REACHED LOADING POINT',
    'LOADING STARTED',
    'LOADING COMPLETE',
    'PAPER WORK DONE',
    'IN TRANSIT',
    'REACHED DESTINATION',
    'UNLOADING STARTED',
    'UNLOADING COMPLETE',
    'POD GENERATED',
    'TRIP COMPLETE',
    'CANCELLED',
];
static statusesImportIndex = [
  'NEW',
  // 'ORDER CONFIRMED',
  'TRIP CONFIRMED',
  'VEHICLE ASSIGNED',
  'ON THE WAY TO LOADING POINT',
  'REACHED LOADING POINT',
  'LOADING STARTED',
  'LOADING COMPLETE',
  'PAPER WORK DONE',
  'IN TRANSIT',
  'REACHED DESTINATION',
  'DESTUFFING STARTED',
  'DESTUFFING COMPLETE',
  'POD GENERATED',
  'REACHED EMPTY YARD',
  'CONTAINER DROPPED',
  'TRIP COMPLETE',
  'CANCELLED',
];
static statusesExportIndex = [
  'NEW',
  // 'ORDER CONFIRMED',
  'TRIP CONFIRMED',
  'VEHICLE ASSIGNED',
  'REACHED EMPTY YARD',
  'CONTAINER PICKED',
  'ON THE WAY TO LOADING POINT',
  'REACHED LOADING POINT',
  'LOADING STARTED',
  'LOADING COMPLETE',
  'PAPER WORK DONE',
  'IN TRANSIT',
  'REACHED DESTINATION',
  'UNLOADING STARTED',
  'UNLOADING COMPLETE',
  'POD GENERATED',

  'TRIP COMPLETE',
  'CANCELLED',
];
static statusesIndexForAll = [
  'NEW',
  // 'ORDER CONFIRMED',
  'TRIP CONFIRMED',
  'VEHICLE ASSIGNED',
  'REACHED EMPTY YARD',
  'CONTAINER PICKED',
  'ON THE WAY TO LOADING POINT',
  'REACHED LOADING POINT',
  'LOADING STARTED',
  'LOADING COMPLETE',
  'PAPER WORK DONE',
  'IN TRANSIT',
  'REACHED DESTINATION',
  'UNLOADING STARTED',
  'UNLOADING COMPLETE',
  'POD GENERATED',
  'REACHED EMPTY YARD DROP',
  'CONTAINER DROPPED',
  'TRIP COMPLETE',
  'CANCELLED',
];
static statusesAllIndex = {
  'NEW' : 'CONFIRM',
  'ORDER CONFIRMED' : 'CONFIRM',
  'TRIP CONFIRMED' : 'ASSIGN VEHICLE',
  'VEHICLE ASSIGNED' : 'ON THE WAY TO LOADING POINT',
  'REACHED EMPTY YARD' : 'CONFIRM REACHED EMPTY YARD',
  'CONTAINER PICKED' : 'CONFIRM CONTAINER PICKED',
  'ON THE WAY TO LOADING POINT' : 'CONFIRM REACHED LOADING',
  'REACHED LOADING POINT' : 'CONFIRM LOADING START',
  'LOADING STARTED' : 'CONFIRM LOADING END',
  'LOADING COMPLETE' : 'CONFIRM PAPER WORK DONE',
  'CONSENT REQUESTED' : 'SEND CONSENT REQUEST',
  'PAPER WORK DONE' : 'CONFIRM IN TRANSIT',
  'IN TRANSIT' : 'TRACK',
  'REACHED DESTINATION' : 'CONFIRM UNLOADING START',
  'DESTUFFING STARTED' : 'CONFIRM DESTUFFING STARTED',
  'DESTUFFING COMPLETE' : 'CONFIRM DESTUFFING COMPLETE',

  'UNLOADING STARTED' : 'CONFIRM UNLOADING END',
  'UNLOADING COMPLETE' : 'CONFIRM POD',
  'REACHED EMPTY YARD DROP' : 'CONFIRM REACHED EMPTY YARD',
  'CONTAINER DROPPED' : 'DROP CONTAINER',
  'POD GENERATED' : 'COMPLETE TRIP',
  'TRIP COMPLETE' : 'DONE',
};
static commandsAllIndex = [
  'CONFIRM',
  'ASSIGN VEHICLE',
  'ON THE WAY TO LOADING POINT',
  'CONFIRM REACHED EMPTY YARD',
  'CONFIRM CONTAINER PICKED',
  'CONFIRM REACHED LOADING',
  'CONFIRM LOADING START',
  'CONFIRM LOADING END',
  'CONFIRM PAPER WORK DONE',
  'SEND CONSENT REQUEST',
   'CONFIRM IN TRANSIT',
  'TRACK',
  'CONFIRM UNLOADING START',
  'CONFIRM DESTUFFING STARTED',
  'CONFIRM DESTUFFING COMPLETE',

  'CONFIRM UNLOADING END',
  'CONFIRM POD',
  // 'CONFIRM REACHED EMPTY YARD',
  'DROP CONTAINER',
  'COMPLETE TRIP',
  // 'TRIP COMPLETE' : 'DONE',
];
static statusesToTrack = [
  'IN TRANSIT',
  'REACHED DESTINATION',
  'UNLOADING STARTED',
  'UNLOADING COMPLETE',
  'DESTUFFING STARTED',
  'DESTUFFING COMPLETE',
  'POD GENERATED',
  'REACHED EMPTY YARD',
  'CONTAINER DROPPED',

];
static cancelReasons = [
  'CUSTOMER CANCELLED',
  'VEHICLE UNAVAILABLE',
  'TEST TRIP',
];
  // id: number;
  // trip_no: string;
  // bookings_id: number;
  // truck_hire_challans_id: number;
  // trips_status_cd: string;
  // source_city: string;
  // destination_city: string;
  // actual_transit_time: number;
  // actual_arrival_dt: Date;
  // estimated_transit_time: number;
  // special_instructions: string;
  // service_type_cd: string;
  // container_type_cd: string;
  // truck_type_cd: string;
  // goods_type_cd: string;
  // dimension_type_cd: string;
  estimated_wt: number;
  estimated_vol_length: number;
  estimated_vol_breadth: number;
  estimated_vol_height: number;
  // target_hire_amt: number;
  target_advance_percentage: number;
  // target_advance_amt: number;
  // freight_amt: number;
  // remarks: string;
  bill_to_party_id: number;
  bill_to_party_city: string;
  // billing_branch_cd: string;
  // pricing_executive_id: number;
  // dispatch_executive_id: number;
  // tracking_executive_id: number;
  // arrival_executive_id: number;
  // pricing_executive_name: string;
  // dispatch_executive_name: string;
  // tracking_executive_name: string;
  // arrival_executive_name: string;
  // ops_executive_id: number;
  // ops_executive_name: string;
  // finance_executive_id: number;
  // finance_executive_name: string;

  // customers_id: number;
  truck_hire_challans: TruckHireChallans[];
  bookings: Bookings;
  // customers: Customers;
  consignment_notes: ConsignmentNotes[];
  consignment_trips: ConsignmentTrips[];
  trip_addresses: TripAddresses[];
  // location: string;
  // location_lat: number;
  // location_long:number;
  rating: DriverRatings;
  updated_at: Date;
  // movement_type_cd: string;
  // business_type_cd; string;
  // booking_type_cd: string;
  trip_scan: string;
  exim_scan_1: string;
  exim_scan_2: string;
  exim_scan_3: string;
  // pickup_icd_city: string;
  // drop_icd_city: string;
  container_no: string;
  seal_no: string;
  trucks: Trucks[];
  trip_last_location: TripStatusTrackings;
  trip_last_status: TripStatusTrackings;
  trip_last_remark: TripStatusTrackings;
  trip_locations: TripStatusTrackings[];
  tasks_id: string;
  // is_skip_eway = false;
  constructor(json?: any) {
    super(json);
    this.fill(json);
  }

  static toArray(jsons: any[]): Trips[] {
    let trips: Trips[] = [];
    if (jsons != null) {
      for (const json of jsons) {
        trips.push(new Trips(json));
      }
    }
    return trips;
  }
  static getStatuses(business_type_cd = '', movement_type_cd = ''){
    let allTripStatuses = Trips.statusesIndex;
    if (business_type_cd === 'EXIM') {
      if (movement_type_cd === 'IMPORT') {
        allTripStatuses = Trips.statusesImportIndex;
      } else if (movement_type_cd === 'EXPORT') {
        allTripStatuses = Trips.statusesExportIndex;
      }
    }
    return allTripStatuses;
  }
  static nextStatus(status_cd: string, business_type_cd = '', movement_type_cd = '') {
    const allTripStatuses = Trips.getStatuses(business_type_cd, movement_type_cd);
    const statusIndex = allTripStatuses.indexOf(status_cd);
    let result = '';
    if (statusIndex !== -1 && statusIndex < (allTripStatuses.length - 1)) {
       result = allTripStatuses[statusIndex + 1];
    }
    // console.log('next status is ' + result);
    return result;
  }
  static isStatusCrossed(status_cd: string, aTrip, current_status_cd = null){

    const allTripStatuses = Trips.getStatuses(aTrip.business_type_cd, aTrip.movement_type_cd);
    const statusIndex = allTripStatuses.indexOf(status_cd);
    if(current_status_cd == null) {
      current_status_cd = aTrip.trips_status_cd;
    }
    const currentIndex = allTripStatuses.indexOf(current_status_cd);

    if (currentIndex !== -1 && statusIndex !== -1 && statusIndex <= currentIndex) {
       return true;
    }
    return false;
  }

  fill(json?: any) {
    if (json != null) {
      super.fill(json);
      // this.id = json.id;
      // this.trip_no = json.trip_no;
      // this.bookings_id = json.bookings_id;
      // this.truck_hire_challans_id = json.truck_hire_challans_id;
      // this.trips_status_cd = json.trips_status_cd;
      // this.source_city = json.source_city;
      // this.destination_city = json.destination_city;
      // this.actual_transit_time = json.actual_transit_time;
      // this.estimated_transit_time = json.estimated_transit_time;
      // this.special_instructions = json.special_instructions;
      // this.service_type_cd = json.service_type_cd;
      // this.container_type_cd = json.container_type_cd;
      // this.truck_type_cd = json.truck_type_cd;
      // this.goods_type_cd = json.goods_type_cd;
      // this.dimension_type_cd = json.dimension_type_cd;
      this.estimated_wt = json.estimated_wt;
      this.estimated_vol_length = json.estimated_vol_length;
      this.estimated_vol_breadth = json.estimated_vol_breadth;
      this.estimated_vol_height = json.estimated_vol_height;
      // this.remarks = json.remarks;
      // this.customers_id = json.customers_id;
      if (json.truck_hire_challans != null) {
        this.truck_hire_challans = TruckHireChallans.toArray(json.truck_hire_challans);
      }
      else if (json.truck_hire_challan != null) {
        if (Array.isArray(json.truck_hire_challan)) {
          this.truck_hire_challans = TruckHireChallans.toArray(json.truck_hire_challan);
        } else {
          this.truck_hire_challans = new Array(new TruckHireChallans(json.truck_hire_challan));
        }
      }
      if (json.bookings != null) {
        this.bookings = new Bookings(json.bookings);
      }
      if (json.trip_addresses != null) {
        this.trip_addresses = TripAddresses.toArray(json.trip_addresses);
      }
      if (json.consignment_notes != null) {
        this.consignment_notes = ConsignmentNotes.toArray(json.consignment_notes);
      }
      if (json.consignment_trips != null) {
        this.consignment_trips = ConsignmentTrips.toArray(json.consignment_trips);
      }
      this.bill_to_party_id = json.bill_to_party_id;
      this.bill_to_party_city = json.bill_to_party_city;
      // this.billing_branch_cd = json.billing_branch_cd;
      // this.pricing_executive_id = json.pricing_executive_id;
      // this.dispatch_executive_id = json.dispatch_executive_id;
      // this.tracking_executive_id = json.tracking_executive_id;
      // this.arrival_executive_id = json.arrival_executive_id;
      // this.pricing_executive_name = json.pricing_executive_name;
      // this.dispatch_executive_name = json.dispatch_executive_name;
      // this.tracking_executive_name = json.tracking_executive_name;
      // this.arrival_executive_name = json.arrival_executive_name;
      // this.ops_executive_id = json.ops_executive_id;
      // this.ops_executive_name = json.ops_executive_name;
      // this.finance_executive_id = json.finance_executive_id;
      // this.finance_executive_name = json.finance_executive_name;
      // this.target_hire_amt = json.target_hire_amt;
      // this.target_advance_percentage = json.target_advance_percentage;
      // this.target_advance_amt = json.target_advance_amt;
      // this.freight_amt = json.freight_amt;
      // this.location = json.location;
      // if(json.location_lat != null) {
      //   this.location_lat = +json.location_lat;

      // }
      // if(json.location_long != null) {
      //   this.location_long = +json.location_long;

      // }
      if (json.rating) {
        this.rating = new DriverRatings(json.rating);
      }
      appGlobals.safeDate(this, json,  'updated_at');

      this.trip_scan = json.trip_scan;
      this.exim_scan_1 = json.exim_scan_1;
      this.exim_scan_2 = json.exim_scan_2;
      this.exim_scan_3 = json.exim_scan_3;
      this.container_no = json.container_no;
      this.seal_no  = json.seal_no;
      if(json.trucks != null) {
        this.trucks = Trucks.toArray(json.trucks);
      }
      if(json.trip_last_location != null) {
        this.trip_last_location = new TripStatusTrackings(json.trip_last_location);
      }
      if(json.trip_last_status != null) {
        this.trip_last_status = new TripStatusTrackings(json.trip_last_status);
      }
      if(json.trip_last_remark != null) {
        this.trip_last_remark = new TripStatusTrackings(json.trip_last_remark);
      }
      if(json.trip_locations != null) {
        this.trip_locations = TripStatusTrackings.toArray(json.trip_locations);
      }
      if(json.is_skip_eway) {
        this.is_skip_eway = json.is_skip_eway;
      }
      this.tasks_id = json.tasks_id;
    }
  }
  getFreightAmt(){
    let freightAmt = 0;
    if(this.consignment_notes) {
      this.consignment_notes.forEach(element => {
        if(element.freight_amt) {
          freightAmt = element.freight_amt;
        }
      });
    }
    return freightAmt;
  }
  getEstimatedTransitDays() {
    if (this.estimated_transit_time && this.estimated_transit_time != null) {
      const transitDays = Math.ceil(
        this.estimated_transit_time / 24
      );
      return transitDays;
    }
    else {
      if(this.total_distance) {
        const transitDays = Math.ceil(
          this.total_distance / 350
        );
        return transitDays;
      }

    }
  }
  getNextPlusStatus() {
    if (this.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    const nextStatus = Trips.nextStatus(
      this.trips_status_cd,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus === 'TRIP COMPLETE' || nextStatus === 'CANCELLED') {
      return false;
    }

    const nextStatus2 = Trips.nextStatus(
      nextStatus,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus2 === 'CANCELLED') {
      return false;
    }
    return nextStatus2;
  }
  hasNextStatusSuffx(status_cd) {
    const nextStatus = Trips.nextStatus(
      status_cd,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus && nextStatus != 'CANCELLED') {
      return '';
    }
    return 'no';
  }
  getNextStatus() {
    if (this.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    const nextStatus = Trips.nextStatus(
      this.trips_status_cd,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus === 'VEHICLE ASSIGNED') {
      return false;
    }
    return nextStatus.toLowerCase();
  }
  getTripStatusColor(status_cd) {
    const statusIndex = Trips.statusesIndexForAll.indexOf(status_cd);
    const currIndex = Trips.statusesIndexForAll.indexOf(this.trips_status_cd);
    // if (statusIndex <= currIndex) {
    //   return 'blue';
    // }
    // return 'grey';
    if (statusIndex == currIndex) {
      return 'green';
    } else if (statusIndex < currIndex) {
      return 'blue';
    } else {
      return 'grey';
    }
  }
  getLastStatus() {
    if (this.trips_status_cd === 'TRIP COMPLETE') {
      return false;
    }
    const nextStatus = Trips.nextStatus(
      this.trips_status_cd,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus === 'TRIP COMPLETE' || nextStatus === 'CANCELLED') {
      return false;
    }

    const nextStatus2 = Trips.nextStatus(
      nextStatus,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus2 === 'TRIP COMPLETE' || nextStatus2 === 'CANCELLED') {
      return false;
    }

    const nextStatus3 = Trips.nextStatus(
      nextStatus2,
      this.business_type_cd,
      this.movement_type_cd
    );
    if (nextStatus3 === 'CANCELLED') {
      return false;
    }
    return nextStatus3;
  }
  static isDestinationStatus(status_cd) {
    if (status_cd == 'REACHED LOADING POINT'
      || status_cd == 'REACHED DESTINATION') {
      return true;
    }
    return false;
  }

}
export let tripsFormControls = {
  id: ['', []],
  truck_hire_challans_id: ['', []],
  trips_status_cd: ['NEW', []],
  source_city: ['', [Validators.required]],
  destination_city: ['', [Validators.required]],
  service_type_cd: ['', [Validators.required]],
  container_type_cd: ['', [Validators.required]],
  truck_type_cd: ['', [Validators.required]],
  goods_type_cd: ['', []],
  dimension_type_cd: ['', []],
  estimated_wt: ['', []],
  estimated_vol_length: ['', []],
  estimated_vol_breadth: ['', []],
  estimated_vol_height: ['', []],
  target_hire_amt: ['', []],
  target_advance_percentage: ['', []],
  target_advance_amt: ['', []],
  freight_amt: ['', []],
  bookings_id: ['', []],
  customer_id: [null, []],
  customer_name: ['', []],
  actual_dispatch_dt: ['', []],
  estimated_arrival_dt: ['', []],
  actual_arrival_dt: ['', []],
  estimated_transit_time: ['', []],
  actual_transit_time: ['', []],
  special_instructions: ['', []],
  bill_to_party_id: ['', []],
  bill_to_party_city: ['', []],
  billing_branch_cd: ['', []],
  pricing_executive_id: ['', []],
  dispatch_executive_id: ['', []],
  tracking_executive_id: ['', []],
  arrival_executive_id: ['', []],
  pricing_executive_name: ['', []],
  dispatch_executive_name: ['', []],
  tracking_executive_name: ['', []],
  arrival_executive_name: ['', []],
  ops_executive_id: ['', []],
  ops_executive_name: ['', []],
  finance_executive_id: ['', []],
  finance_executive_name: ['', []],
  business_type_cd: ['', []],
  booking_type_cd: ['', []],
  movement_type_cd: ['', []],
  trip_scan: ['', []],
  exim_scan_1: ['', []],
  exim_scan_2: ['', []],
  exim_scan_3: ['', []],
  pickup_icd_city: ['', []],
  drop_icd_city: ['', []],
  container_no: ['', []],
  seal_no: ['', []],
  location_long: ['', []],
  location_lat: ['', []],
  location: ['', []],
  location_provider: ['', []],
  is_skip_eway: [false, []],
};

