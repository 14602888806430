import { Customers } from '../customers/customers';
import { PaymentLines } from '../paymentLines/paymentLines';
import { safeDate } from '../../core/globalfunctions';
export class Payments {
  id: number;
  customers_id: number;
  bank_cd: string;
  payment_ref: string;
  payment_dt: Date;
  payment_amt: number;
  webxpress_payment_code: string;
  payment_remarks: string;
  payment_scan: string;
  payment_status_cd: string;
  customer: Customers;
  payment_lines:  PaymentLines[];
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): Payments[] {
    let payments: Payments[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        payments.push(new Payments(json));
      }
    }
    return payments;
  }
  static commandsAllIndex = [
    // 'REVIEW BILL',
    // 'SUBMIT BILL',
    // 'MARK PAYMENT RECEIVED',
    // 'MARK MR COMPLETED'
  ];
  static allStatuses = [
    // 'BILL GENERATED',
    // 'REVIEWED',
    // 'BILL SUBMITTED',
    // 'PAYMENT RECEIVED',
    // 'MR COMPLETE',
  ];
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.bank_cd = json.bank_cd;
      this.customers_id = json.customers_id;
      safeDate(this, json,  'payment_dt');
      if (json.customer != null) {
        this.customer = new Customers(json.customer);
      }
      this.payment_ref = json.payment_ref;
      this.payment_amt = json.payment_amt;
      this.payment_scan = json.payment_scan;
      this.payment_remarks = json.payment_remarks;
      this.webxpress_payment_code = json.webxpress_payment_code;
      this.payment_status_cd = json.payment_status_cd;
      if(json.payment_lines != null) {
        this.payment_lines = PaymentLines.toArray(json.payment_lines);
      }
    }
  }
}
export const paymentsFormControls = {
  id: ['', []],
  customers_id: ['', []],
  payment_dt: ['', []],
  payment_ref: ['', []],
  payment_amt: ['', []],
  payment_scan: ['', []],
  payment_remarks: ['', []],
  payment_status_cd: ['', []],
  bank_cd: ['', []],
  webxpress_payment_code: ['', []],
  invoice_ref_search: ['', []],
  invoice_total_amt: ['', []],
  payment_total_amt: ['', []],
  submitted_dt: ['', []]
};
