import { ConsignmentNotes } from "../consignmentNotes/consignmentNotes";
import { Trips } from "../trips/trips";
import { BookingAddresses } from "../bookingAddresses/bookingAddresses";
import { Customers } from '../customers/customers';
import { Validators } from '@angular/forms';
import { Time } from '@angular/common';
import { safeDate } from '../../core/globalfunctions';
export class Bookings {
  id: number;
  customers_id: number;
  no_of_trucks: number;
  truck_type_cd: string;
  payment_basis_cd: string;
  // business type Dry, Reefer, EXIM,
  business_type_cd: string;
  // order type spot, contract, project
  booking_type_cd: string;
  //ftl, ftl express,
  service_type_cd: string;
  trip_type_cd = 'Long';
  container_type_cd: string;
  goods_type_cd: string;
  package_type_cd: string;
  dimension_type_cd: string;
  no_of_boxes: number;
  booking_for_dt: Date;

  booking_for_start: Date;
  booking_for_end: Date;
  booking_for_slot_cd: string;
  total_distance: number;
  total_fare: number;
  operational_status_cd: string;
  booking_status_cd: string;
  source_city: string;
  contract_source_city: string;
  contract_destination_city: string;

  destination_city: string;
  destination_city_filter: string;
  location_lat: number;
  location_long: number;
  destination_location_lat: number;
  destination_location_long: number;
  customer_contact_users_id: number;
  remarks: string;

  purchase_order_no: string;
  purchase_order_dt: Date;
  booking_no: string;
  source_location_code: string;
  destination_location_code: string;
  customers: Customers;
  booking_addresses: BookingAddresses[];
  bids_metrics_cd: string;
  bill_to_party_id: number;
  bill_to_party_city: string;
  billing_branch_cd: string;
  pricing_executive_id: number;
  dispatch_executive_id: number;
  tracking_executive_id: number;
  arrival_executive_id: number;
  pricing_executive_name: string;
  dispatch_executive_name: string;
  tracking_executive_name: string;
  arrival_executive_name: string;
  ops_executive_id: number;
  ops_executive_name: string;
  finance_executive_id: number;
  finance_executive_name: string;
  product: string;
  unit_cd: string;
  quantity: number;
  unit_estimated_wt: number;
  unit_estimated_vol_length: number;
  unit_estimated_vol_breadth: number;
  unit_estimated_vol_height: number;
  rate_amt: number;
  estimated_no_of_trucks: number;
  total_estimated_wt: number;
  total_estimated_vol_length: number;
  total_estimated_vol_breadth: number;
  total_estimated_vol_height: number;
  shipping_instructions: string;
  status_reason: string;
  cancel_reason_cd: string;
  consignment_notes: ConsignmentNotes[];
  trips: Trips[];
  pickup_icd_city: string;
  drop_icd_city: string;
  movement_type_cd: string;
  booking_scan: string;
  exim_scan_1: string;
  exim_scan_2: string;
  exim_scan_3: string;
  container_no: string;
  seal_no: string;
  booking_branch_cd: string;
  arrival_branch_cd: string;
  billing_executive_id: string;
  billing_executive_name: string;
  territory_cd: string;
  reefer_temp_min: number;
  reefer_temp_max: number;
  reefer_humidity: string;
  is_reuse: number;

  do_scan: string;
  tasks_id: string;

  static statusesAllIndex = {
    // 'NEW INDENT' => $this->newEmailIntand,
    'New': 'CONFIRM BOOKING', // 'DONE', // $this->quoteBooking,
    'Pending Quote': 'DONE', // $this->quoteBooking,
    'Confirmed': 'MARK IN PROGRESS',
    'In Progress': 'COMPLETE BOOKING', // $this->closeBooking,
    'COMPLETE': 'DONE', //
  };
  static commandsAllIndex = [
    // 'NEW INDENT' => $this->newEmailIntand,
    'CONFIRM BOOKING', // 'DONE', // $this->quoteBooking,
    // 'DONE', // $this->quoteBooking,
    'MARK IN PROGRESS',
    'COMPLETE BOOKING', // $this->closeBooking,
    // 'COMPLETE': 'DONE', //
  ];

  constructor(json?: any) {
    // console.log('booking initiated');
    // console.log(json);
    this.fill(json);
  }
  fill(json?: any) {
    if (json != null) {
      // console.log('booking filled');
      // console.log(json);
      this.id = json.id;
      this.customers_id = json.customers_id;
      this.business_type_cd = json.business_type_cd;
      this.booking_type_cd = json.booking_type_cd;
      this.service_type_cd = json.service_type_cd;
      if(json.trip_type_cd) {
        this.trip_type_cd = json.trip_type_cd;
      }
      this.no_of_trucks = json.no_of_trucks;
      this.truck_type_cd = json.truck_type_cd;
      this.payment_basis_cd = json.payment_basis_cd;
      this.container_type_cd = json.container_type_cd;
      this.goods_type_cd = json.goods_type_cd;
      this.package_type_cd = json.package_type_cd;
      this.dimension_type_cd = json.dimension_type_cd;
      this.no_of_boxes = json.no_of_boxes;
      safeDate(this, json,  'booking_for_dt');

      this.booking_for_slot_cd = json.booking_for_slot_cd;
      this.total_distance = json.total_distance;
      this.total_fare = json.total_fare;
      this.operational_status_cd = json.operational_status_cd;
      this.booking_status_cd = json.booking_status_cd;
      this.source_city = json.source_city;
      this.destination_city = json.destination_city;
      this.location_lat = json.location_lat;
      this.location_long = json.location_long;
      this.destination_location_lat = json.destination_location_lat;
      this.destination_location_long = json.destination_location_long;
      this.customer_contact_users_id = json.customer_contact_users_id;
      this.remarks = json.remarks;
      this.purchase_order_no = json.purchase_order_no;
      safeDate(this, json,  'purchase_order_dt');

      this.booking_no = json.booking_no;
      this.source_location_code = json.source_location_code;
      this.destination_location_code = json.destination_location_code;
      if (json.customers != null) {
        this.customers = new Customers(json.customers);
      }
      if (json.booking_addresses) {
        this.booking_addresses = BookingAddresses.toArray(json.booking_addresses);
      }
      this.bids_metrics_cd = json.bids_metrics_cd;
      this.bill_to_party_id = json.bill_to_party_id;
      this.bill_to_party_city = json.bill_to_party_city;
      this.billing_branch_cd = json.billing_branch_cd;
      this.pricing_executive_id = json.pricing_executive_id;
      this.dispatch_executive_id = json.dispatch_executive_id;
      this.tracking_executive_id = json.tracking_executive_id;
      this.arrival_executive_id = json.arrival_executive_id;
      this.pricing_executive_name = json.pricing_executive_name;
      this.dispatch_executive_name = json.dispatch_executive_name;
      this.tracking_executive_name = json.tracking_executive_name;
      this.arrival_executive_name = json.arrival_executive_name;
      this.ops_executive_id = json.ops_executive_id;
      this.ops_executive_name = json.ops_executive_name;
      this.finance_executive_id = json.finance_executive_id;
      this.finance_executive_name = json.finance_executive_name;
      this.product = json.product;
      this.unit_cd = json.unit_cd;
      this.quantity = json.quantity;
      this.rate_amt = json.rate_amt;
      this.estimated_no_of_trucks = json.estimated_no_of_trucks;
      this.unit_estimated_wt = json.unit_estimated_wt;
      this.unit_estimated_vol_length = json.unit_estimated_vol_length;
      this.unit_estimated_vol_breadth = json.unit_estimated_vol_breadth;
      this.unit_estimated_vol_height = json.unit_estimated_vol_height;
      this.total_estimated_wt = json.total_estimated_wt;
      this.total_estimated_vol_length = json.total_estimated_vol_length;
      this.total_estimated_vol_breadth = json.total_estimated_vol_breadth;
      this.total_estimated_vol_height = json.total_estimated_vol_height;
      this.shipping_instructions = json.shipping_instructions;
      this.status_reason = json.status_reason;
      this.cancel_reason_cd = json.cancel_reason_cd;
      if (json.consignment_notes) {
        this.consignment_notes = ConsignmentNotes.toArray(json.consignment_notes);
      }
      if (json.trips) {
        this.trips = Trips.toArray(json.trips);
      }
      this.pickup_icd_city = json.pickup_icd_city;
      this.drop_icd_city = json.drop_icd_city;
      this.movement_type_cd = json.movement_type_cd;
      this.booking_scan = json.booking_scan;
      this.exim_scan_1 = json.exim_scan_1;
      this.exim_scan_2 = json.exim_scan_2;
      this.exim_scan_3 = json.exim_scan_3;
      this.container_no = json.container_no;
      this.seal_no = json.seal_no;
      this.booking_branch_cd = json.booking_branch_cd;
      this.arrival_branch_cd = json.arrival_branch_cd;
      this.billing_executive_id = json.billing_executive_id;
      this.billing_executive_name = json.billing_executive_name;
      this.territory_cd = json.territory_cd;
      this.reefer_temp_min = json.reefer_temp_min;
      this.reefer_temp_max = json.reefer_temp_max;
      this.reefer_humidity = json.reefer_humidity;
      this.is_reuse = json.is_reuse;
      // console.log(json.booking_for_start);
      if (json.booking_for_start != null) {
        if (typeof json.booking_for_start == 'string') {
          this.booking_for_start = new Date(('1970-01-01 ' + json.booking_for_start).replace(/-/g, "/"));
        }
        else {
          this.booking_for_start = json.booking_for_start;
        }
      }
      if (json.booking_for_end != null) {
        if (typeof json.booking_for_end == 'string') {
          this.booking_for_end = new Date(('1970-01-01 ' + json.booking_for_end).replace(/-/g, "/"));
        }
        else {
          this.booking_for_end = json.booking_for_end;
        }
      }
      this.contract_destination_city = json.contract_destination_city;
      this.contract_source_city = json.contract_source_city;
      this.do_scan = json.do_scan;
      this.tasks_id = json.tasks_id;
    }

  }
  static toArray(jsons: any[]): Bookings[] {
    let bookings: Bookings[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        bookings.push(new Bookings(json));
      }
    }
    return bookings;
  }
  getDestinationAddress(){
    if(this.booking_addresses) {
      this.booking_addresses.forEach(element => {
        if(element.address_type_cd == 'DROP') {
          return element;
        }
      });
    }
    // return false;
  }
  getSourceAddress(){
    if(this.booking_addresses) {
      this.booking_addresses.forEach(element => {
        if(element.address_type_cd == 'PICKUP') {
          return element;
        }
      });
    }
    // return false;
  }
}
export let bookingsFormControls = {
  id: ['', []],
  no_of_trucks: [1, [Validators.required]],
  truck_type_cd: ['', [Validators.required]],
  business_type_cd: ['', [Validators.required]],
  service_type_cd: ['', [Validators.required]],
  trip_type_cd: ['Long', [Validators.required]],
  container_type_cd: ['', [Validators.required]],
  goods_type_cd: ['', [Validators.required]],
  package_type_cd: ['', [Validators.required]],
  dimension_type_cd: ['', [Validators.required]],
  payment_basis_cd: ['', [Validators.required]],
  no_of_boxes: [1, []],
  booking_for_dt: [new Date(), [Validators.required]],
  booking_for_slot_cd: ['', [Validators.required]],
  total_distance: [0, []],
  total_fare: ['', []],
  operational_status_cd: ['', []],
  booking_status_cd: ['Confirmed', []],
  purchase_order_no: ['', [Validators.required]],
  purchase_order_dt: ['', [Validators.required]],
  booking_type_cd: ['ORDER', []],
  source_city: ['', [Validators.required]],
  destination_city: ['', [Validators.required]],
  destination_city_filter: ['', []],

  customers_id: [null, []],
  name: ['', []],
  location_lat: ['', []],
  location_long: ['', []],
  source_location_code: ['', []],
  destination_location_code: ['', []],
  destination_location_lat: ['', []],
  destination_location_long: ['', []],
  customer_contact_users_id: ['', []],
  remarks: ['', []],
  booking_no: ['', []],
  bids_metrics_cd: ['TRUCK', [Validators.required]],
  bill_to_party_id: ['', []],
  bill_to_party_city: ['', [Validators.required]],
  billing_branch_cd: ['', [Validators.required]],
  pricing_executive_id: ['', [Validators.required]],
  dispatch_executive_id: ['', [Validators.required]],
  tracking_executive_id: ['', [Validators.required]],
  arrival_executive_id: ['', [Validators.required]],
  pricing_executive_name: ['', []],
  dispatch_executive_name: ['', []],
  tracking_executive_name: ['', []],
  arrival_executive_name: ['', []],
  ops_executive_id: ['', []], // to do finance and kam
  ops_executive_name: ['', []],
  finance_executive_id: ['', []],
  finance_executive_name: ['', []],
  product: ['', []],
  unit_cd: ['', []],
  quantity: ['', []],
  rate_amt: ['', []],
  estimated_no_of_trucks: ['', []],
  unit_estimated_wt: ['', []],
  unit_estimated_vol_length: ['', []],
  unit_estimated_vol_breadth: ['', []],
  unit_estimated_vol_height: ['', []],
  total_estimated_wt: ['', []],
  total_estimated_vol_length: ['', []],
  total_estimated_vol_breadth: ['', []],
  total_estimated_vol_height: ['', []],
  shipping_instructions: ['', []],
  status_reason: ['', []],
  cancel_reason_cd: ['', []],
  pickup_icd_city: ['', []],
  drop_icd_city: ['', []],
  movement_type_cd: ['', []],
  booking_scan: ['', []],
  exim_scan_1: ['', []],
  exim_scan_2: ['', []],
  exim_scan_3: ['', []],
  container_no: ['', []],
  seal_no: ['', []],
  booking_branch_cd: ['', [Validators.required]],
  arrival_branch_cd: ['', [Validators.required]],
  billing_executive_id: ['', [Validators.required]],
  billing_executive_name: ['', []],
  territory_cd: ['', [Validators.required]],
  reefer_temp_min: ['', []],
  reefer_temp_max: ['', []],
  reefer_humidity: ['', []],
  is_reuse: ['', []],
  contract_source_city: ['', []],
  contract_destination_city: ['', []],
  do_scan: ['', []],
  tasks_id: ['', []],
};

