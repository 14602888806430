import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, APP_INITIALIZER, DEFAULT_CURRENCY_CODE, enableProdMode, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { HammerModule, BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { AgmDirectionModule } from 'agm-direction';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { CommonService } from './core/services/common.service';
import { ConfigService } from './core/services/config.service';
import { DropdownsService } from './core/services/dropdowns.service';
// import { EchoService } from './core/services/echo.service';
import { ErrorsHandlerService } from './core/services/errors.handler.service';
import { HttpService } from './core/services/http-service.service';
import { StorageService } from './core/services/storage.service';
import { AlertDialogComponent } from './layout/dialogs/alert-dialog/alert-dialog.component';
import { ImageDialogComponent } from './layout/dialogs/image-dialog/image-dialog.component';
import { DialogService } from './layout/dialogs/services/dialog.service';
import { DropdownsModule } from './layout/dropdowns/dropdowns.module';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';

import { LeftMenuComponent } from './layout/left-menu/left-menu.component';
import { MapModule } from './layout/map/map.module';
import { SubMenusComponent } from './layout/sub-menus/sub-menus.component';
// import { ConsignmentNotesModule } from './manage/consignmentNotes/consignmentNotes.module';
import { NotificationsModule } from './manage/notifications/notifications.module';
// import { TripsModule } from './manage/trips/trips.module';
import { SharedModule } from './shared/shared.module';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { LoginComponent } from './user/login/login.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { SignupComponent } from './user/signup/signup.component';
// import { GwhomeModule } from './gwhome/gwhome.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TripliteModule } from './manage/triplite/triplite.module';
import { AppPreloadingStrategy } from './core/appPreloadingStrategy';
// import { CancelBookingsComponent } from './manage/cancel-bookings/cancel-bookings.component';
// import { SupportComponent } from './manage/support/support.component';

// import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
// import { RouterModule, Routes, ActivatedRoute, PreloadAllModules } from '@angular/router';
// import { CdkTableModule } from '@angular/cdk/table'; // datasource in our app move to common
// import { CustomersModule } from './manage/customers/customers.module';
// import { ContractsModule } from './manage/contracts/contracts.module';
// import { TrucksModule } from './manage/trucks/trucks.module';
// import { DriversModule } from './manage/drivers/drivers.module';
// import { PartnersModule } from './manage/partners/partners.module';

// import { BookingsModule } from './manage/bookings/bookings.module';
// import { TruckHireChallansModule } from './manage/truckHireChallans/truckHireChallans.module';

// import { UsersModule } from './manage/users/users.module';
// import { bidsModule } from './manage/bids/bids.module';
// import { RolesModule } from './manage/roles/roles.module';
// import { NearbyDriversPartnersModule } from './manage/nearby_drivers-partners/nearby_drivers-partners.module';
// import { ConsignmentTripTrackingsModule } from './manage/consignmentTripTrackings/consignmentTripTrackings.module';
// import { ConsignmentNotesPrintModule } from './manage/consignmentNotesPrint/consignmentNotesPrint.module';
// import { QuotesModule } from './manage/quotes/quotes.module';
// import { pricePredictModule } from './manage/pricePredict/pricePredict.module';
import { registerLocaleData } from '@angular/common';

import localeIn from '@angular/common/locales/en-IN';
import { HttpAuthInterceptor } from './core/services/http-auth-interceptor';
// enableProdMode();
registerLocaleData(localeIn);

import { MsalModule } from '@azure/msal-angular';
import { OAuthSettings } from './core/msaloauth';
import { EntityModule } from './manage/entity/entity.module';
import { PdfModule } from './pdf/pdf.module';
import { ProfileComponent } from './user/profile/profile.component';
import { MatErrorModule } from './layout/mat-error/mat-error.module';
import { rollbarFactory, RollbarService } from './core/services/rollbar.service';
// import { ChartsModule } from 'ng2-charts';

import { AuthenticatorService } from './core/services/authenticator.service';
import * as Hammer from 'hammerjs';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}


@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    //PaymentdetailsComponent,
    // HeaderComponent,
    // FooterComponent,
    // AlertDialogComponent,
    // ImageDialogComponent,
    // LeftMenuComponent,
    // SubMenusComponent,
    // LoginComponent,
    // SignupComponent,
    // ForgotPasswordComponent,
    // ResetPasswordComponent,
    // CancelBookingsComponent,
    // SupportComponent,
   // PaymentsComponent,
   // PaymentdetailsComponent,
  ],
  imports: [

    RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: AppPreloadingStrategy }),
    // RouterModule.forRoot(appRoutes, { enableTracing: true }),
    // BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    HammerModule,
    // ChartsModule,
    SharedModule,
    MapModule,
    BrowserAnimationsModule,
    // EntityModule,
    // NoopAnimationsM odule,
    // CdkTableModule,
    DropdownsModule,
    MatErrorModule,
    // pricePredictModule,
    // QuotesModule,
    // BookingsModule,
    // TripsModule,

    TripliteModule,

    // ConsignmentNotesModule,
    // ContractsModule,
    // CustomersModule,
    // DriversModule,
    // PartnersModule,
    // RolesModule,

    // TruckHireChallansModule,
    // TrucksModule,
    // UsersModule,
    // bidsModule,
    // NearbyDriversPartnersModule,
    // ConsignmentTripTrackingsModule,
    // ConsignmentNotesPrintModule,
    NotificationsModule,
    // TruckLocationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDem6tqqXGpyvFNmgIdVIsmg3VlPZxAwe8',
      libraries: ['places']
    }),
    AgmDirectionModule,
    AgmMarkerClustererModule,
    // GwhomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // ImageViewerModule,
    // MsalModule.forRoot({
    //   clientID: OAuthSettings.appId
    // }),
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        authority: 'https://login.microsoftonline.com/' + OAuthSettings.tenentId + '/',
        // redirectUri: 'Enter_the_Redirect_Uri_Here',
        // postLogoutRedirectUri: '',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        'user.read',
        'user.read.all',
        'openid',
        'profile',
      ],
      unprotectedResources: [],
      protectedResourceMap: [
        ['https://graph.microsoft.com/me', ['user.read.all']]
      ],
      extraQueryParameters: {}
    }),
    PdfModule
  ],
  providers: [Title,
    CommonService,
    HttpService,
    DropdownsService,
    StorageService,
    ErrorsHandlerService,
    ConfigService,
    DialogService,
    AuthGuard, RoleGuard, DatePipe,
    AuthenticatorService,
    // EchoService,
    AppPreloadingStrategy,
    {provide: APP_INITIALIZER, useFactory: initialize_config, deps: [ConfigService], multi: true },
    {provide: ErrorHandler, useClass: ErrorsHandlerService, },
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: 'en-in'},
    { provide: MAT_DATE_LOCALE, useValue: 'en-in'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'INR'},
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertDialogComponent, ImageDialogComponent]
})
export class AppModule {
  // constructor(applicationRef: ApplicationRef) {
  //   // const originalTick = applicationRef.tick;
  //   // applicationRef.tick = function() {
  //   //   const windowPerfomance = window.performance;
  //   //   const  before = windowPerfomance.now();
  //   //   window.console.log('CHANGE DETECTION TIME start');
  //   //   const retValue = originalTick.apply(this, arguments);
  //   //   const after = windowPerfomance.now();
  //   //   const runTime = after - before;

  //   //   window.console.log('CHANGE DETECTION TIME' , runTime);
  //   //   return retValue;
  //   // };
  // }
}
export function initialize_config(configService: ConfigService) {
  console.log('app module init config');
  return () => configService.initializeConfig();
}
