import {ConsignmentNotes} from '../consignmentNotes/consignmentNotes';
import { Users } from '../users/users';
import { safeDate } from '../../core/globalfunctions';
export class ConsignmentStatusTrackings {
    id : number;
    consignment_notes_id : number;
    consignment_status_cd : string;
    remarks : string;
    updated_by : number;
    updated_at : Date;
    updated_by_user: Users;
    is_visible_to_customer : number;
    location_lat : number;
    location_long : number;
    location: string;
    status_at: Date;
    // consignmentNotes : ConsignmentNotes;
    consignment_no: string;
    status_type_cd: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : ConsignmentStatusTrackings[] {
        let consignmentStatusTrackings : ConsignmentStatusTrackings[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                consignmentStatusTrackings.push(new ConsignmentStatusTrackings(json));
            }
        }
        return consignmentStatusTrackings;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.consignment_notes_id = json.consignment_notes_id;
            this.consignment_status_cd = json.consignment_status_cd;
            this.remarks = json.remarks;
            this.updated_by = json.updated_by;
            safeDate(this, json,  'updated_at');
            safeDate(this, json,  'status_at');

            if(json.updated_by_user != null){
              this.updated_by_user = new Users(json.updated_by_user);
            }
            this.is_visible_to_customer = json.is_visible_to_customer;
            this.location_lat = json.location_lat;
            this.location_long = json.location_long;
            if (json.consignmentNotes != null) {
                // this.consignmentNotes = new ConsignmentNotes(json.consignmentNotes);
                this.consignment_no = json.consignmentNotes.consignment_no;
            }
            else{
              this.consignment_no = json.consignment_no;
            }
            this.location = json.location;
            this.status_type_cd = json.status_type_cd;
        }
    }
}
