import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class PartnerContactsService {

  constructor(private serviceHttp:HttpService) { }
  searchNew(searchTerm: any, aPartnersId?: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partnerContacts/search',
    'POST', 0, {search: searchTerm, partners_id: aPartnersId }, 0, 1);
  }
  getList(pageNo = 1
    ,partnerContacts_partners_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partnerContacts/page', 'POST', 0, {page: pageNo
        ,partners_id: partnerContacts_partners_id
      });
  }
  search(str:string  ,partnerContacts_partners_id?: any ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partnerContacts/search', 'POST', 0, {search:str,partners_id: partnerContacts_partners_id
    });
  }
  getPartnerContactsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('partnerContacts/'+ id, 'GET');
  }
  addPartnerContacts(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('partnerContacts', 'POST', 0, data);
  }
  updatePartnerContacts(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partnerContacts/' + data.id, 'PUT', 0, data);
  }
}
