import {TruckHireChallans} from '../truckHireChallans/truckHireChallans';
import { safeDate } from '../../core/globalfunctions';
import { PartnerBanks } from '../partnerBanks/partnerBanks';
import { Validators } from '@angular/forms';
export class ThcPayments {
    id : number;

    thc_payment_type_cd : string;
    thc_payment_status_cd : string;
    thc_payment_mode_cd : string;
    thc_payment_ref : string;
    thc_payment_dt : Date;
    thc_payment_amt : number;

    thc_payment_vendor_ref_no : string;
    thc_payment_vendor_ref_dt : Date;

    truck_hire_challans_id : number;
    thc_payment_banks_id : number;
    thc_payment_partners_id: number;
    thc_payment_partner_contacts_id: number;
    thc_payment_upload_ref: string;
    created_by: number;
    updated_by: number;
    updated_at: Date;

    thc_payment_batch_ref: string;
    thc_payment_batch_id: number;
    thc_payment_proof_scan: string;

    truckHireChallans : TruckHireChallans;
    truck_hire_challan : TruckHireChallans;
    partner_bank: PartnerBanks;
    bank_unique_id: string;
    bank_request_id: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : ThcPayments[] {
        let thcPayments : ThcPayments[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                thcPayments.push(new ThcPayments(json));
            }
        }
        return thcPayments;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.thc_payment_type_cd = json.thc_payment_type_cd;
            this.thc_payment_mode_cd = json.thc_payment_mode_cd;
            this.thc_payment_status_cd = json.thc_payment_status_cd;
            this.thc_payment_ref = json.thc_payment_ref;
            safeDate(this, json,  'thc_payment_dt');
            safeDate(this, json,  'thc_payment_vendor_ref_dt');

            this.thc_payment_amt = json.thc_payment_amt;
            this.thc_payment_vendor_ref_no = json.thc_payment_vendor_ref_no;

            this.thc_payment_banks_id  = json.thc_payment_banks_id;

            this.truck_hire_challans_id = json.truck_hire_challans_id;
            if (json.truckHireChallans != null) {
                this.truckHireChallans = new TruckHireChallans(json.truckHireChallans);
            }
            if (json.truck_hire_challan != null) {
              this.truck_hire_challan = new TruckHireChallans(json.truck_hire_challan);
            }
            if (json.partner_bank != null) {
              this.partner_bank = new PartnerBanks(json.partner_bank);
            }
            this.thc_payment_upload_ref = json.thc_payment_upload_ref;
            this.created_by = json.created_by;
            this.updated_by = json.updated_by;
            safeDate(this, json,  'updated_at');
            this.bank_request_id = json.bank_request_id;
            this.bank_unique_id = json.bank_unique_id;

        }
    }

}
export const thcPaymentsFormControl = {
    id: [, []],
    thc_payment_type_cd : ['', []],
    thc_payment_status_cd : ['', []],
    thc_payment_ref : ['', []],
    thc_payment_dt : ['', []],
    thc_payment_amt : ['', []],

    thc_payment_vendor_ref_no : ['', []],
    thc_payment_vendor_ref_dt : ['', []],

    truck_hire_challans_id : ['', []],
    thc_payment_mode_cd : ['', []],
    thc_payment_banks_id : ['', []],
    thc_payment_partners_id: ['', []],
    thc_payment_partner_contacts_id: ['', []],
    thc_payment_upload_ref: ['', []],

    thc_payment_batch_ref: ['', []],
    thc_payment_batch_id: ['', []],
    thc_payment_proof_scan : ['', []],
}
