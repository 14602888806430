import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { NotificationsService } from '../services/notifications.service';
import { Notifications } from '../notifications';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  providers: [NotificationsService, DialogService]
})
export class EditComponent implements OnInit{
  notifications = new Notifications();
  notificationsFormGroup: FormGroup;
  isError: boolean = false;
  errorMessages = new Array<string>();
  constructor(
            private router: Router,
            private route: ActivatedRoute,
            private _formBuilder: FormBuilder,
            private modalService: BsModalService,
            private _snackBarService: MatSnackBar,
            private _dialogService: DialogService,
            private notificationsService: NotificationsService
    ) { } 
  ngOnInit() {
    this.route.params.subscribe(params => {
          if(params.id){
            this.getResponse(params.id);
          }
    });
    this.notificationsFormGroup = this._formBuilder.group({
      id: ['', []],
      type: ['', []],
      notifiable_id: ['', []],
      notifiable_type: ['', []],
      data: ['', []],
      read_at: ['', []],
      });
  }
  getResponse(id) {
    return this.notificationsService.getNotificationsById(id).subscribe(
      response => {
        this.notifications  = new Notifications(response.data);
        if(this.notifications){
          this.notificationsFormGroup.patchValue(this.notifications);
        }
      } 
    );
  }
  submitNotificationsForm(){
    if(!this.notificationsFormGroup.valid){
  		this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(this.notificationsFormGroup);
      appGlobals.markFormGroupTouched(this.notificationsFormGroup);
    }
    else{
      const formData = this.notificationsFormGroup.value;
      if(!this.notifications){
        this.notifications  = new Notifications();
      }
      this.notifications.fill(formData);
      if(this.notifications.id){
	      this.notificationsService.updateNotifications(this.notifications).subscribe(
          response => {
            if(response.status === 200){
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.router.navigate(['notifications/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
	    }else{
	      this.notificationsService.addNotifications(this.notifications).subscribe(
          response  =>  {
            if(response.status === 200){
              this.notifications  = new Notifications(response.data);
              this._snackBarService.open(response.message, 'X' , { duration: appGlobals.SNACKBAR_TIMEOUT });
              this.router.navigate(['notifications/list']);
            }
            else{
              this._dialogService.openAlert({'title' : 'Error', 'message' : response.message});
            }
          },
          error => {
            this._dialogService.openAlert({'title' : 'Error', 'message' : error});
          }
        );
      }
    }
  }
}
