import { ConsignmentNotes } from '../consignmentNotes/consignmentNotes';
import { safeDate } from '../../core/globalfunctions';
import { InvoiceLines } from '../invoiceLines/invoiceLines';
import { Payments } from '../payments/payment';
import { Invoices } from '../invoices/invoices';
export class PaymentLines {
  id: number;
  payments_id: number;

  invoice_line_pay_type_cd: string;
  invoice_lines_id: number;
  invoices_id: number;
  invoice_line: InvoiceLines;
  invoice: Invoices;

  webxpress_payment_line_code: string;
  payment_line_ref: string;

  payment_lines_status_cd: string;
  payment_line_amt: number;

  payment: Payments;

  webxpress_invoice_code:  string;
  consignment_no: string;
  invoice_line_amt: number;
  // payment_line_amt: number;

  consignment_notes_id: number;
  consignment_note: ConsignmentNotes;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): PaymentLines[] {
    let paymentLines: PaymentLines[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        paymentLines.push(new PaymentLines(json));
      }
    }
    return paymentLines;
  }
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.invoice_lines_id = json.invoice_lines_id;
      this.invoices_id = json.invoices_id;
      this.payments_id = json.payments_id;
      if (json.payment != null) {
        this.payment = new Payments(json.payment);
      }
      if(json.invoice != null) {
        this.invoice = new Invoices(json.invoice);
      }
      if (json.invoice_line != null) {
        this.invoice_line = new InvoiceLines(json.invoice_line);
      }
      if (json.consignment_note != null) {
        this.consignment_note = new ConsignmentNotes(json.consignment_note);
      }
      this.payment_lines_status_cd = json.payment_lines_status_cd;
      this.payment_line_amt = json.payment_line_amt;
      this.payment_line_ref = json.payment_line_ref;
      this.webxpress_payment_line_code = json.webxpress_payment_line_code;
      this.invoice_line_pay_type_cd = json.invoice_line_pay_type_cd;
      this.consignment_notes_id = json.consignment_notes_id;

    }
  }
}
export const paymentLinesFormControls = {
  id: ['', []],
  payments_id: ['', []],
  invoice_lines_id: ['', []],
  payment_lines_status_cd: ['', []],
  payment_line_amt: ['', []],
  payment_line_ref: ['', []],
  webxpress_payment_line_code: ['', []],
  invoice_line_pay_type_cd: ['', []],

  webxpress_invoice_code:  ['',[]],
  consignment_no: ['', []],
  invoice_line_amt: ['',[]]

  // payment_line_amt: number;

};
