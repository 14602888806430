import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class CustomersService {
  constructor(private serviceHttp:HttpService) { }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel('customers/exportList', 'POST', 0, data);
  }
  getContractCities(data: { 'customers_id': number; }): any {
    return this.serviceHttp.getResponse('customers/getContractCities', 'POST', 0, data);
  }

  getList(pageNo = 1
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/list', 'POST', 0, {page: pageNo
      });
  }
  getNewCustomersList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/listNewCustomers/' + pageNo, 'GET');
  }
  search(str:string ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/search', 'POST', 0, {search:str });
  }
  getCustomersById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('customers/'+ id, 'GET');
  }
  markCustomer(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/markVerify/'+ id, 'GET');
  }
  addCustomers(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('customers', 'POST', 0, data,1);
  }
  updateCustomers(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/' + data.id, 'PUT', 0, data, 1);
  }
  getEmployees(str: string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('users/employees', 'GET', 0, {search:str}, 0, 1);
  }
  getCustomerById(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/'+id, 'GET');
  }
  addCustomer(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers', 'POST', 0, data, 1);
  }
  updateCustomer(data, id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/'+id, 'PUT', 0, data, 1);
  }
  updateCustomerAddresses(data, id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customerAddresses/update/', 'POST', 0, data);
  }
  updateCustomerContacts(data, id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customerContacts/update/', 'POST', 0, data);
  }
  searchCustomerList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/search', 'POST', 0, {search:str});
  }
  lookupCustomerList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/search', 'POST', 0, {search:str});
  }
  lookupCustomerWithBooking(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/searchWithBookings', 'POST', 0, {search:str});
  }
  getCustomerByIdWithBookings(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/getByIdWithBookings/'+id, 'GET');
  }
  getByIdWithContactAddresses(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/getByIdWithContactAddresses/'+id, 'GET');
  }
  lookupCustomerWithContactAddresses(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/searchWithContactAddresses', 'POST', 0, {search:str});
  }
  lookupCustomerWithPhone(customers_id:number,mobnumber:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/lookup', 'POST', 0, {customers_id:customers_id,mobile_no:mobnumber});
  }
  lookupCustomerWithNamePhone(customers_id:number,  mobnumber?:number, name?:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customers/searchByContact', 'POST', 0, {customers_id:customers_id,mobile_no:mobnumber,contact_name:name});
  }
}
