import { safeDate } from '../../core/globalfunctions';

export class Trucks {
    id : number;
    registration_no : string;
    truck_status_cd : string;
    truck_type_cd : string;
    registration_expiry_dt : Date;
    container_type_cd : string;
    registered_address : string;
    registration_scan : string;
    owner_name : string;
    insurance_policy_no : string;
    insurance_expiry_dt : Date;
    insurance_provider : string;
    insurance_scan : string;
    fitness_expiry_dt : Date;
    fitness_scan : string;
    permit_scan : string;
    pollution_scan: string;
    status : number;
    kms_driven : number;
    model_chasis_engine : string;
    verified_by : string;
    verified_at : Date;
    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.registration_no = json.registration_no;
            this.truck_status_cd = json.truck_status_cd;
            this.truck_type_cd = json.truck_type_cd;
            if (json.registration_expiry_dt != null) {
                safeDate(this, json,  'registration_expiry_dt');
            }
            this.container_type_cd = json.container_type_cd;
            this.registered_address = json.registered_address;
            this.registration_scan = json.registration_scan;
            this.owner_name = json.owner_name;
            this.insurance_policy_no = json.insurance_policy_no;
            if (json.insurance_expiry_dt != null) {
                safeDate(this, json,  'insurance_expiry_dt');
            }
            this.insurance_provider = json.insurance_provider;
            this.insurance_scan = json.insurance_scan;
            if (json.fitness_expiry_dt != null) {
                safeDate(this, json,  'fitness_expiry_dt');
            }
            this.fitness_scan = json.fitness_scan;
            this.permit_scan = json.permit_scan;
            this.pollution_scan = json.pollution_scan;
            this.status = json.status;
            this.kms_driven = json.kms_driven;
            this.model_chasis_engine = json.model_chasis_engine;
            this.verified_by = json.verified_by;
            if (json.verified_at != null) {
              safeDate(this, json,  'this.verified_at');
            }
        }
    }
    static toArray(jsons : any[]) : Trucks[] {
        let trucks : Trucks[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                trucks.push(new Trucks(json));
            }
        }
        return trucks;
    }
}
