/// <reference types="gapi" />
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import {
  Component,
  NgZone,
  OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticatorService } from '../../../core/services/authenticator.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { BidsService } from '../../bids/services/bids.service';
import { BookingsService } from '../../bookings/services/bookings.service';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { DriverRatingsService } from '../../driverRatings/services/driverRatings.service';
import { DriversService } from '../../drivers/services/drivers.service';
import { PartnerContactsService } from '../../partnerContacts/services/partnerContacts.service';
import { PartnersService } from '../../partners/services/partners.service';
import { TruckHireChallansService } from '../../truckHireChallans/services/truckHireChallans.service';
import { TrucksService } from '../../trucks/services/trucks.service';
import { UsersService } from '../../users/services/users.service';
import { TripsService } from '../../trips/services/trips.service';
import { TripsListComponent } from '../../trips/list/trips.list.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { style, trigger, state, animate, transition } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { Trips } from '../../trips/trips';

@Component({
  selector: 'app-triplite-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    TripsService,
    ConsignmentTripTrackingsService,
    DialogService,
    DriverRatingsService,
    DatePipe,
    BidsService,
    BookingsService,
    TruckHireChallansService,
    FileTransferService,
    UsersService,
    TrucksService,
    DriversService,
    PartnersService,
    PartnerContactsService,
    AuthenticatorService,
    ConsignmentNotesService,
    MatDialog,
  ],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ]),
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class TripliteListComponent extends TripsListComponent implements OnInit {
  displayedColumns = [
    'select',
    'tripId',
    'CustomerName',
    'SourceCity',
    // 'destination_city',
    'ConsignmentNo',

    // 'BookingDate',
    'trips_status',
    // 'location',
    'emp',
    'TruckRegistrationNo',
    // 'nextSteps',
    'HireTruck'
    // 'edit'
    // 'add'
  ];
  expandedElement: Trips;
  constructor(
    _formBuilder: FormBuilder,
    mapsAPILoader: MapsAPILoader,
    ngZone: NgZone,
    router: Router,
    route: ActivatedRoute,
    modalService: BsModalService,
    _snackBarService: MatSnackBar,
    _dialogService: DialogService,
    tripsService: TripsService,
    thcService: TruckHireChallansService,
    bookingsService: BookingsService,
    userService: UsersService,
    truckService: TrucksService,
    partnerService: PartnersService,
    partnerContactService: PartnerContactsService,
    driverService: DriversService,
    fileTransferService: FileTransferService,
    consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    driverRatingsService: DriverRatingsService,
    datepipe: DatePipe,
    bidsService: BidsService,
    authService: AuthenticatorService,
    sanitizer: DomSanitizer,
    consignmentNotesService: ConsignmentNotesService,
    dialog:MatDialog,
  ) {

    super(
      _formBuilder, mapsAPILoader, ngZone,
      router, route, modalService,
      _snackBarService,
      _dialogService,
      tripsService,
      thcService,
      bookingsService,
      userService,
      truckService,
      partnerService,
      partnerContactService,
      driverService,
      fileTransferService,
      consignmentTripTrackingsService,
      driverRatingsService,
      datepipe,
      bidsService,
      authService,
      sanitizer,
      consignmentNotesService,
      dialog
    );
    }

  }
