import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesListComponent } from './list/invoices.list.component';
import { InvoicesEditComponent } from './edit/invoices.edit.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { DropdownsComponent } from '../../layout/dropdowns/dropdowns.component';
import { DropdownsModule } from '../../layout/dropdowns/dropdowns.module';
export const invoicesRoutes: Routes = [
	{ path: 'invoices',
		children: [
			{ path: '', redirectTo: 'list', pathMatch: 'full' },
			{ path: 'list', component: InvoicesListComponent },
			{ path: 'edit/:id', component: InvoicesEditComponent },
			{ path: 'add', component: InvoicesEditComponent },
		]
	},
];
@NgModule({
  imports: [
    CommonModule,
		RouterModule.forChild(invoicesRoutes),
		SharedModule,
		DropdownsModule
  ],
  declarations: [
    InvoicesListComponent,
		InvoicesEditComponent
	],
	exports:[
		// InvoicesListComponent,
    InvoicesEditComponent,
    RouterModule
	]
})
export class InvoicesModule { }
