import {Trucks} from '../trucks/trucks';
export class Gpss {
    id : number;
    provider : string;
    provider_uid : string;
    provider_details : string;
    trucks_id : number;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Gpss[] {
        let gpss : Gpss[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                gpss.push(new Gpss(json));
            }
        }
        return gpss;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.provider = json.provider;
            this.provider_uid = json.provider_uid;
            this.provider_details = json.provider_details;
            this.trucks_id = json.trucks_id;
        }
    }
}
export let gpssFormControls = {
  id: ['', []],
  provider: ['', []],
  provider_uid: ['', []],
  provider_details: ['', []],
  trucks_id: ['', []],
  };
