<div class="ierp">
<div class="top-section">
  <div class="container">
    <div class="row align-items-center p-l-20 p-r-10 mob-p-default" id="topStatusBtn">
      <div class="col-md-8 p-0 m-t-10   pb-3 mobScroll">
        <mat-button-toggle-group class="AssignTruckToggle" [value]="selectedGlobalActivityIndex"
        (change)="onGlobalActivityChange($event)">
          <mat-button-toggle value="ALL TASKS">
            <div class="AssignTruck"> All Tasks ({{total}})</div>
          </mat-button-toggle>
          <!-- <mat-button-toggle value="3">
                      <div class="AssignTruck"> Priority Tasks (5)</div>
                  </mat-button-toggle>
                  <mat-button-toggle value="3">
                      <div class="AssignTruck"> Tasks Today (45)</div>
                  </mat-button-toggle>
                  <mat-button-toggle value="4">
                      <div class="AssignTruck">Tasks Done (3)</div>
                  </mat-button-toggle> -->
            <mat-button-toggle value="TASKS DONE">
              <div class="AssignTruck">Tasks Done</div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="col-md-4 text-right">
        <!-- <div class=""> -->
        <button md-icon-button md-fab-image class="tranf_t dividerB" type="button" (click)="download($event)">
          <!-- <span class="color_blue">Click to<br>
            Download</span> -->
          <img md-fab-image src="assets/img/download.svg" style="height: 22px;" >
        </button>
        <button md-icon-button md-fab-image class="tranf_t" type="button" (click)="toggleSearchBar()">
          <img md-fab-image src="assets/img/box_filter.png">
        </button>
        <button mat-icon-button color="primary" (click)="refresh($event)">
          <!-- <mat-icon style="font-size: 36px;">refresh</mat-icon> -->
          <img md-fab-image src="assets/img/refresh.svg" style="height: 22px;">
        </button>
        <!-- <button md-icon-button md-fab-image class="tranf_t" type="button" (click)="toggleSearchBar()">
                          <img md-fab-image src="assets/img/noun_Search_1166289.png">
                      </button> -->
        <!-- <div class="ml-auto topbar-more-vert"> -->
        <button class="ml-auto topbar-more-vert" mat-icon-button [matMenuTriggerFor]="rootMenuT">
          <mat-icon>more_vert</mat-icon>
        </button>
        <!-- close button if popup, hitory link, reassign executives, cancel trip, resend sms, get location, gallery, history, location,  -->
        <mat-menu #rootMenuT="matMenu">
          <button mat-menu-item (click)="closeModalGeneric()">
            <span>Close</span>
          </button>
        </mat-menu>
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="row pb-4 p-r-20 mob-p-default" id="B-slider">
    <div class="col-md-10">
      <mat-progress-bar appProgressBarColor="#12AFEC" class="example-margin ml-2" mode="mode" value="3" bufferValue="5">
      </mat-progress-bar>
    </div>
  </div>
  <div class="container">
    <div class="row  pb-4 p-r-20 mob-p-default" *ngIf="searchBarOpen">
      <div class="col-sm-8 text-center">
        <form [formGroup]="tripsSearchForm">
          <div class="row" #searchBar *ngIf="searchBarOpen">
            <div class="col-sm-3">
              <mat-form-field floatPlaceholder="never" class="w-100">
                <input matInput #filter placeholder="Search Trips" formControlName="search">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <app-dropdowns [multiple]=true [placeholder]="'Trip Status'" [type]="'trips_status_cd'"
                [selected]="tripsSearchForm.value.trips_status_cd"
                [valid]="tripsSearchForm.get('trips_status_cd').valid" (onSelect)="setTripStatusCd($event)"
                (touched)="tripsSearchForm.get('trips_status_cd').markAsTouched()"></app-dropdowns>
            </div>
            <div class="col-sm-3">
              <app-dropdowns [multiple]=true [placeholder]="'THC Status'" [type]="'truck_hire_challan_status_cd'"
                [selected]="tripsSearchForm.value.truck_hire_challan_status_cd"
                [valid]="tripsSearchForm.get('truck_hire_challan_status_cd').valid" (onSelect)="setThcStatusCd($event)"
                (touched)="tripsSearchForm.get('truck_hire_challan_status_cd').markAsTouched()"></app-dropdowns>
            </div>
            <div class="col-sm-3">
              <app-dropdowns [multiple]=true [placeholder]="'Territory'" [type]="'territory_cd'"
                [selected]="tripsSearchForm.value.territory_cd" [valid]="tripsSearchForm.get('territory_cd').valid"
                (onSelect)="setterritoryCd($event)" (touched)="tripsSearchForm.get('territory_cd').markAsTouched()">
              </app-dropdowns>
              <mat-error class="mapError"
                *ngIf="!tripsSearchForm.get('territory_cd').valid && tripsSearchForm.get('territory_cd').touched">
                Territory selection is required.
              </mat-error>
            </div>

          </div>
          <div class="row" *ngIf="searchBarOpen">
            <div class="col-sm-3">
              <app-dropdowns [multiple]=true [required]=true [placeholder]="'Branch'" [type]="'branch_cd'"
                [selected]="tripsSearchForm.value.branch_cd" [valid]="tripsSearchForm.get('branch_cd').valid"
                (onSelect)="setbranchCd($event)" (touched)="tripsSearchForm.get('branch_cd').markAsTouched()">
              </app-dropdowns>
              <mat-error class="mapError"
                *ngIf="!tripsSearchForm.get('branch_cd').valid && tripsSearchForm.get('branch_cd').touched">
                Territory selection is required.
              </mat-error>
            </div>
            <div class="col-sm-3">
              <mat-form-field>
                <mat-select multiple="true" placeholder="Work Area" formControlName="work_area_cd"
                  >
                  <mat-option *ngFor="let item of work_area_cds | keyvalue" [value]="item.key">{{item.value}}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!tripsSearchForm.get('work_area_cd').valid && tripsSearchForm.get('work_area_cd').touched">
                  <span *ngIf="tripsSearchForm.get('work_area_cd').errors.required">Work area Required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field>
                <mat-select multiple="true" placeholder="Task Status" formControlName="lifecycle_state_cd"
                  >
                  <mat-option value="CREATED">CREATED</mat-option>
                  <mat-option value="PENDING">PENDING</mat-option>
                  <mat-option value="COMPLETE">COMPLETE</mat-option>
                  <mat-option value="SUSPENDED">SUSPENDED</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!tripsSearchForm.get('work_area_cd').valid && tripsSearchForm.get('work_area_cd').touched">
                  <span *ngIf="tripsSearchForm.get('work_area_cd').errors.required">Work area Required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field>
                <mat-select multiple="true" placeholder="Task Name" formControlName="task_name"
                  >

                  <mat-option *ngFor="let item of getAllTripTasks()" [value]="item">{{item}}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!tripsSearchForm.get('task_name').valid && tripsSearchForm.get('task_name').touched">
                  <span *ngIf="tripsSearchForm.get('task_name').errors.required">Task name Required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input type="text" placeholder="Executive" matInput [matAutocomplete]="autop"
                  formControlName="executive_name">
                <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setExecutive(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <!-- <mat-form-field> -->
                <mat-checkbox placeholder="Unassigned" formControlName="unassigned"
                  >Unassigned Only
                </mat-checkbox>
                <!-- <mat-error
                  *ngIf="!tripsSearchForm.get('unassigned').valid && tripsSearchForm.get('unassigned').touched">
                  <span *ngIf="tripsSearchForm.get('unassigned').errors.required">Unassigned Required</span>
                </mat-error>
              </mat-form-field> -->
            </div>
            <div class="col-sm-3 action-buttons  text-center">
              <span style="margin-right: 10px"><button mat-icon-button color="primary"
                  (click)="submitSearch(tripsSearchForm.value)">
                  <mat-icon>search</mat-icon>
                </button></span>
              <span><button mat-icon-button color="secondary" (click)="resetSearch()">
                  <mat-icon>clear</mat-icon>
                </button></span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <div> -->
<div class="container ">
  <div class="row">
    <div class="col-md-12 col-lg-5">
      <div id="TopB-T" class="row TopB-T inne_area_list_l mobScroll">
        <mat-button-toggle-group class="AssignTruckToggle radius20" [value]="selectedActivityIndex"
          (change)="onActivityChange($event)">
          <mat-button-toggle value="CONFIRM">
            <div class="AssignTruck">All Tasks</div>
          </mat-button-toggle>
          <!-- <mat-button-toggle value="POD">
            <div class="AssignTruck">Collect POD</div>
          </mat-button-toggle> -->
          <!-- <mat-button-toggle value="Pay">
            <div class="AssignTruck">Pay</div>
          </mat-button-toggle> -->
          <!-- <mat-button-toggle value="Bill">
            <div class="AssignTruck">Bill</div>
          </mat-button-toggle> -->
          <!-- <mat-button-toggle value="Arrival">
            <div class="AssignTruck">Arrival</div>
          </mat-button-toggle>
          <mat-button-toggle value="ATH">
            <div class="AssignTruck">ATH</div>
          </mat-button-toggle>
          <mat-button-toggle value="BTH">
            <div class="AssignTruck">BTH</div>
          </mat-button-toggle>
          <mat-button-toggle value="Bill">
            <div class="AssignTruck">Bill</div>
          </mat-button-toggle> -->
        </mat-button-toggle-group>
      </div>
      <div class="row inne_area_list_l mobScroll mob-p-default">
        <ng-container *ngTemplateOutlet="leftTemplate">
        </ng-container>
      </div>
    </div>
    <div class="col-md-12 p-l-0 col-lg-7">
      <ng-container *ngIf="false; else getTemplate">
      </ng-container>
    </div>
  </div>
</div>
</div>
<ng-template #viewTask>
  <form [formGroup]="tasksFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:task.name}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryViewTask;context:{tripData:trips, taskData:task}"></ng-container>
        <!-- <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container> -->
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Details</h3>
          <div class="row">
            <div class="col">
              <mat-label>Assignee: {{task.assignee_name}}</mat-label>
            </div>
            <div class="col">
              <mat-label>Owner: {{task.assignee_name}}</mat-label>
            </div>
            <div class="col">
              <mat-label>Created: {{task.created_at | date: 'dd MMM yy'}}</mat-label>
            </div>
            <div class="col">
              <mat-label>Due {{task.due_at  | date: 'dd MMM yy'}}</mat-label>
            </div>
          <div class="col" *ngIf="task.completed_at">
            <mat-label>Completed: {{task.completed_at | date: 'dd MMM yy'}}</mat-label>
          </div>
          <!-- <div class="col">
            <mat-label></mat-label>
          </div> -->
        </div>
      </div>
        <!-- <div class="row">
          <div class="col text-right">
            <button mat-raised-button color="primary" (click)="submitTaskAssignExecutive()">Submit</button>
          </div>
        </div> -->
      </div>
    </div>
  </form>
</ng-template>
<ng-template #tripSummary let-trip="tripData" let-task="taskData">
  <!-- <h2>Trip Details</h2> -->
  <div class="fieldset mb-4">
    <h3 class="gwheadline "> Trip Details</h3>
    <div class="conatiner_flow">
      <div class="row  ">
        <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3">Customer </div>
          {{task.name_short || task.customers_name}}
        </div>
        <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3"> Vehicle </div>
          <ng-container
            *ngIf="task?.registration_no">
            {{ task?.registration_no }}
          </ng-container>
          <ng-container
            >
            {{ getTruckTypeValue(task.truck_type_cd)}}
          </ng-container>
        </div>
        <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3"> Origin</div>
          {{getStringBeforeComma(task?.source_city)}}
        </div>
        <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3"> Destination</div>
          {{getStringBeforeComma(task?.destination_city)}}
        </div>
        <!-- <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3"> Booking</div>
          <ul>
            <li class="nowrap">{{trip.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{trip.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div> -->
        <div class="col-xs-4 col-sm-3"  >
          <div class="labelBox_e3">Task Start</div>
          <ul>
            <li class="nowrap">{{task?.created_at | date: 'dd MMM yy h:mm a'}} </li>

            </ul>
        </div>
        <div class="col-xs-4 col-sm-3"  >
          <div class="labelBox_e3">Task Due</div>
          <ul>
            <li class="nowrap">{{task?.due_at | date: 'dd MMM yy h:mm a'}}  </li>

            </ul>
        </div>
        <div class="col-xs-4 col-sm-3" *ngIf="task && task.completed_at">
          <div class="labelBox_e3">Task Completed</div>
          <ul>
            <li class="nowrap">{{task?.completed_at | date: 'dd MMM yy h:mm a'}} </li>
          </ul>
        </div>
        <div class="col-xs-4 col-sm-3" >
          <div class="labelBox_e3" >Task Duration</div>
          <ul>
            <li class="nowrap">{{taskDuration(task)}}</li>
            </ul>
        </div>
        <div class="col-xs-4 col-sm-3">
          <div class="labelBox_e3"> Current Status</div>
          <ul>
            <li class="nowrap" *ngIf="task.entity_type == 'Trip'">  {{task?.trips_status_cd}} </li>
            <li class="nowrap" *ngIf="task.entity_type == 'Truck Hire Challan'">  {{task?.truck_hire_challan_status_cd}} </li>

          </ul>
        </div>
        <!-- <div class="col">
          <div class="labelBox_e3"> Trip Start</div>
          <ul>
            <li *ngIf="trip.actual_dispatch_dt" class="nowrap">  {{trip?.actual_dispatch_dt | date: 'dd MMM yy'}} </li>
            <li *ngIf="!trip.actual_dispatch_dt" class="nowrap">  Not Started </li>
          </ul>
        </div>
        <div class="col">
          <div class="labelBox_e3"> Trip End</div>
          <ul>
            <li *ngIf="trip.actual_arrival_dt" class="nowrap">  {{trip?.actual_arrival_dt | date: 'dd MMM yy'}} </li>
            <li *ngIf="!trip.actual_arrival_dt" class="nowrap">  Not Completed </li>

          </ul>
        </div>
        <div class="col"  *ngIf="trip && trip.actual_arrival_dt">
          <div class="labelBox_e3"> Trip Duration</div>
          <ul>
            <li *ngIf="trip.actual_arrival_dt" class="nowrap">  {{tripDuration(trip)}} </li>

          </ul>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>
<ng-template #listTrips>
  <div [ngClass]="{'example-container1': tasksDataSource  && (tasksDataSource.data).length > 0,'example-container2': tasksDataSource  && (tasksDataSource.data).length <= 0}">
    <mat-table [dataSource]="tasksDataSource " cellpadding="10 " class="table_list">
    <ng-container matColumnDef="cbox">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
          <!-- [aria-label]="Select All" -->
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row" class="{{getRowColor(row)}}">
        <mat-checkbox (click)="$event.stopPropagation();" (change)="selectCboxOnChange($event, row)"
          [checked]="selection.isSelected(row)">
          <!-- [aria-label]="row.trip_no" -->
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="customer">
      <!-- <mat-header-cell *matHeaderCellDef style="min-width: 150px"> Task </mat-header-cell> -->
      <mat-header-cell *matHeaderCellDef > Task </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
        <ul>
          <li>{{element.name | titlecase}}</li>
          <li *ngIf="element.assignee_name">{{element.assignee_name | titlecase}}</li>
          <li *ngIf="!element.assignee_name">{{element.work_area_cd | titlecase}}</li>
          <!-- <li *ngIf="element.actual_dispatch_dt"> Disp: {{element.actual_dispatch_dt | date: 'dd MMM yy'}} </li> -->
          <!-- <li *ngIf="element.actual_arrival_dt"> Arv: {{element.actual_arrival_dt | date: 'dd MMM yy'}} </li> -->
          <!-- <li *ngIf="element.actual_arrival_dt"> Dur: {{tripDuration(element)}} </li> -->


        </ul>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="origin">
      <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
        <ul>
          <li>{{element.due_at | date: 'dd MMM yy h:mm a'}}
            <!-- {{element.created_at | date: 'h:mm a'}} -->
          </li>
          <li> SLA: {{taskDuration(element)}} </li>
          <!-- <li>{{element.due_at | date: 'dd MMM yy'}} {{element.due_at | date: 'h:mm a'}}</li> -->
          <!-- <li>Since {{timeSince(element.created_at)}}</li> -->
          <ng-container *ngIf="!element.isComplete()">
          <li *ngIf="!isEarlier(element.due_at)" class="text-green">{{timeSince(element.due_at)}}</li>
          <li *ngIf="isEarlier(element.due_at)" class="text-red bounce-text">{{timeSince(element.due_at)}}</li>
          <!-- <li *ngIf="element.due_at > new Date()">Past Due: {{timeSince(element.due_at)}}</li> -->
          <!-- <li *ngIf="element.due_at < new Date()">Remaining: {{timeSince(element.due_at)}}</li> -->
        </ng-container>
        </ul>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="truck_type">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
        <ul>
          <li>{{element.lifecycle_state_cd }} </li>
          <li *ngIf="element.isComplete() && isEarlier(element.due_at, element.completed_at)"  class="text-red">{{element.completed_at | date: 'dd MMM yy h:mm a'}}
          </li>
          <li *ngIf="element.isComplete() && !isEarlier(element.due_at, element.completed_at)"  class="text-green">{{element.completed_at | date: 'dd MMM yy h:mm a'}}
          </li>
          <li *ngIf="element.isOpen() " >{{element.created_at | date: 'dd MMM yy h:mm a'}}
          </li>
        </ul>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell mat-header-cell class="td-task-button" *matHeaderCellDef> Task
        <button class="ml-auto topbar-more-vert" mat-icon-button [matMenuTriggerFor]="rootMenuTl">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #rootMenuTl="matMenu">
          <button mat-menu-item (click)="closeModalGeneric()">
            <span>Close</span>
          </button>
        </mat-menu>
      </mat-header-cell>
      <mat-cell mat-cell class="td-task-button" *matCellDef="let element; "
      (click)="$event.stopPropagation()" class="{{getRowColor(element)}}">
        <button *ngIf="!element.isComplete()" mat-raised-button color="primary" class="button-task"
          (click)="reassignTaskExecutive(reassignTask, element);">
          Reassign
        </button>
        <button *ngIf="element.isComplete()" mat-raised-button color="primary" class="button-task"
          (click)="reassignTaskExecutive(viewTask, element);">
          View
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;  "
      [ngClass]="{hovered: row.hovered, mat_row_highlighted: selection.isSelected(row)}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
  </mat-table>
  </div>
  <mat-paginator *ngIf="tasksDataSource  && tasksDataSource.data" [showFirstLastButtons]="true" [length]="total"
    [pageSize]="per_page" (page)="getResponseByPage($event)">
  </mat-paginator>
</ng-template>
<ng-template #modalHeader let-title="title">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- close button if popup, hitory link, reassign executives, cancel trip, resend sms, get location, gallery, history, location,  -->
          <mat-menu #rootMenu="matMenu">
            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #reassignTask>
  <form [formGroup]="tasksFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Re Assign Execs ' + task.name}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips, taskData:task}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Assign to</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" placeholder="Executive" matInput [matAutocomplete]="autod"
                  formControlName="assignee_name">
                <mat-autocomplete #autod="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setAssignee(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tasksFormGroup.get('assignee').valid && tasksFormGroup.get('assignee').touched">
                  <span *ngIf="tasksFormGroup.get('assignee').errors.required"> Assignee is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col">
              <mat-label>Created: {{task.created_at | date: 'dd MMM yy'}}</mat-label>
            </div> -->
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput [matDatepicker]="due_at" placeholder="Due Date" formControlName="due_at">
              <mat-datepicker-toggle matSuffix [for]="due_at"></mat-datepicker-toggle>
              <mat-datepicker #due_at></mat-datepicker>
              <mat-error
                  *ngIf="!tasksFormGroup.get('due_at').valid && tasksFormGroup.get('due_at').touched">
                  <span *ngIf="tasksFormGroup.get('due_at').errors.required"> Due dt is required</span>
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col" *ngIf="task.completed_at">
            <mat-label>Completed: {{task.completed_at | date: 'dd MMM yy'}}</mat-label>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput text placeholder="Comments" formControlName="comment">
              <mat-error
                  *ngIf="!tasksFormGroup.get('comment').valid && tasksFormGroup.get('comment').touched">
                  <span *ngIf="tasksFormGroup.get('comment').errors.required"> comment is required</span>
                </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
        <div class="row">
          <div class="col text-right">
            <button mat-raised-button color="primary" (click)="submitTaskAssignExecutive()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #escalateTask>
  <form [formGroup]="tasksFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Re Assign Execs'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Assigned Executives</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" placeholder="Executive" matInput [matAutocomplete]="autod"
                  formControlName="escalate_to_name">
                <mat-autocomplete #autod="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setEscalateTo(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tasksFormGroup.get('escalate_to').valid && tasksFormGroup.get('escalate_to').touched">
                  <span *ngIf="tasksFormGroup.get('escalate_to').errors.required"> Escalate to is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input matInput text placeholder="Comments" formControlName="comment">
                <mat-error
                    *ngIf="!tasksFormGroup.get('comment').valid && tasksFormGroup.get('comment').touched">
                    <span *ngIf="tasksFormGroup.get('comment').errors.required"> comment is required</span>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            <button mat-raised-button color="primary" (click)="submitTaskEscalate()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
