import { switchMap } from 'rxjs/operators';

import { debounceTime } from 'rxjs/operators';
import { Component, TemplateRef, Input, HostListener, ViewChild } from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { ConsignmentNotes, consignmentNotesFormControls } from '../consignmentNotes';
import { ConsignmentNotesService } from '../services/consignmentNotes.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsignmentTripTrackings } from '../../consignmentTripTrackings/consignmentTripTrackings';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { Trips } from '../../trips/trips';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import * as appGlobals from '../../../core/globalfunctions';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../core/config';
import { invoicesFormControls, Invoices } from '../../invoices/invoices';
import { InvoiceLines } from '../../invoiceLines/invoiceLines';
// import { updateBinding } from '@angular/core/src/render3/instructions';
import { UsersService } from '../../users/services/users.service';
import { Users } from '../../users/users';
import { StorageService } from '../../../core/services/storage.service';
import { InvoicesService } from '../../invoices/services/invoices.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-consignmentnotes-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    ConsignmentNotesService,
    ConsignmentTripTrackingsService,
    FileTransferService,
    UsersService,
    StorageService,
    InvoicesService,
  ]
})
export class CNoteListComponent {
  isError: boolean;
  errorMessages: any;
  @Input('bookings_id')
  public bookings_id: number;
  @Input('customers_id')
  public customers_id: number;

  @ViewChild('previewAsHtml', { static: true }) previewAsHtml: TemplateRef<any>;
  @ViewChild('updateConsignment', { static: true }) updateConsignment: TemplateRef<any>;

  displayedColumns = [
    // 'select',
    'position',
    'name',
    'number',
    'source',
    'destination',
    'Status',
    'Truck',
    'edit'
    // 'deliver'
  ];
  dataSource = new ConsignmentNotesDataSource();
  consignmentNotesList = new Array<ConsignmentNotes>();
  per_page: number;
  total: number;
  searchCNote: string;
  cNote: ConsignmentNotes;
  consignmentNotesFormGroup: FormGroup;
  consignmentNotesFormGroup2: FormGroup;
  consignmentNotesSkipWayForm: FormGroup;
  sendConsignmentFormGroup: FormGroup;
  modalRef: BsModalRef;
  tripData: Trips;
  consignmentScanUrl: String;
  searchForm: FormGroup;
  imageUrl: String = AppSettings.IMAGE_ENDPOINT;
  invoicesFormGroup: FormGroup;
  currentPage = 1;
  searchBarOpen = false;
  employeeListAbs: Users[] = [];
  cNoteFormGroup: FormGroup;
  selection: SelectionModel<ConsignmentNotes>;
  invoiceList: Invoices[] = [];
  trips: Trips;
  currentTemplate: TemplateRef<any>;
  usePreviewPanel = false;
  innerWidth: any;
  htmlPreview: any;

  constructor(
    protected fileTransferService: FileTransferService,
    protected consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    protected modalService: BsModalService,
    protected _formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected consignmentNotesService: ConsignmentNotesService,
    protected _snackBarService: MatSnackBar,
    protected _dialogService: DialogService,
    protected userService: UsersService,
    protected invoiceService: InvoicesService,
  ) {}
  ngOnInit() {
    this.route.params.subscribe(params => {
      if (+params.bookingId) {
        this.bookings_id = +params.bookingId;
      }
      if (+params.customerId) {
        this.customers_id = +params.customerrId;
      }
    });
    this.cNoteFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.sendConsignmentFormGroup = this._formBuilder.group({
      id: ['', []],
      to_email: ['', [Validators.required, Validators.email]],
      to_name: ['', [Validators.required]],
    });
    this.consignmentNotesFormGroup = this._formBuilder.group({
      // trips_id: [null, []],
      last_location: ['', []],
      last_location_lat: [null, []],
      last_location_long: [null, []],
      // consignment_no: ['', []],
      remarks: ['', []],
      consignment_status_cd: ['', [Validators.required]],
      id: [null, []],
      status_at: ['', []],
      status_at_time: ['', []],
      cancel_reason_cd: ['', []]
    });
    this.consignmentNotesSkipWayForm = this._formBuilder.group({
      consignment_notes_id: ['', [Validators.required]],
      is_skip_eway: [false, []],
      remarks: ['', [Validators.required]],
    });
    this.consignmentNotesFormGroup2 = this._formBuilder.group({
      id: ['', []],
      consignment_no: ['', []],
      consignment_status_cd: ['', [Validators.required]],
      pod_remarks: ['', []],
      pod_scan: ['', []],
      unloading_charges: ['', []],
      loading_charges: ['', []],
      detention_charges: ['', []],
      detention_days: ['', []],
      detention_amt_per_day: ['', []],
      damage_deduction: ['', []],

      pod_scan_2: ['', []],
      gate_in_scan: ['', []],
      // delivery_date: ['', [Validators.required]],
      // delivery_time: ['', [Validators.required]],
      // recieved_by: ['', [Validators.required]],
      // delivery_condition_cd: ['', [Validators.required]],
      delivery_date: ['', []],
      delivery_time: ['', []],
      recieved_by: ['', []],
      delivery_condition_cd: ['', []],
      cancel_reason_cd: ['', []]
    });
    this.searchForm = this._formBuilder.group({
      search: ['', []],
      territory_cd: ['', []],
      branch_cd: ['', []],
      executive_id: ['', []],
      consignment_status_cd: ['', []],
      executive_name: ['', []],
      consignment_dt_from: ['', []],
      consignment_dt_to: ['', []],
    });

    this.invoicesFormGroup = this._formBuilder.group(invoicesFormControls);
    appGlobals.observeChanges(
      this,
      this.searchForm.controls.search,
      'consignmentNotesList',
      'getSearchService',
      'setSearchList',
      ''
    );
    appGlobals.observeChanges(
      this,
      this.searchForm.controls.executive_name,
      'employeeListAbs',
      'getExecutiveSearchService',
      'setExecutiveList',
      'name'
    );
    const user = StorageService.getUser();
    if (
      StorageService.userIsLE() ||
      StorageService.userIsTE()
      // || StorageService.userIsCT()
    ) {
      this.setExecutive(user);
    } else {
      if (user.territory_cd && user.territory_cd !== 'PAN INDIA') {
        this.setterritoryCd([user.territory_cd]);
      }
    }

    this.getResponse();
  }

  invoiceCheck(aInvoiceNo){
    // console.log(aInvoiceNo);
    if(aInvoiceNo && aInvoiceNo != null && aInvoiceNo.length > 14){
      return true;
    }
    return false;
  }
  getInvoiceSearchService(searchTerm) {
    // console.log('invoice search service ' + searchTerm);
    return this.invoiceService.lookupEntity({'webxpress_invoice_code': searchTerm});
  }
  setInvoiceList(aData) {
    // console.log(aData);
    if(aData.data) {
      this.invoiceList = [new Invoices(aData.data)];
      if(this.invoiceList[0]) {
        const invoice = this.invoiceList[0];
        this.setInvoice(invoice);
      }
      else{
        this.setFormForBilling(false);
      }
    }
    else {
      this.setFormForBilling(false);
    }
  }
  getExecutiveSearchService(searchTerm) {
    return this.userService.getEmployees(searchTerm);
  }
  setExecutiveList(aData) {
    this.employeeListAbs = Users.toArray(aData.data.data);
  }
  getSearchService(searchTerm) {
    return this.consignmentNotesService.search(this.mergeWithSearch());
  }
  setSearchList = function(aData) {
    this.consignmentNotesList = ConsignmentNotes.toArray(aData.data);
    data = this.consignmentNotesList;
    this.dataSource = new ConsignmentNotesDataSource();
    this.total = aData.total;
    this.per_page = aData.per_page;
    this.searchTerm = this.searchForm.get('search').value;
  };
  download(event) {
    this.getResponseToExcel(this.currentPage);
  }
  downloadFile(aData: any) {
    const options = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    const fileName = 'LR-' + new Date().toLocaleDateString();
    appGlobals.createAndDownloadBlobFile(aData, options, fileName);
  }
  downloadPdfFile(responseData: any, entityDisplayData: any) {
    const options = {
      type: 'application/pdf'
    };
    // console.log('downloading as pdf');
    // use file saver
    var blob = new Blob([responseData] , { type: "application/pdf"});
    //change download.pdf to the name of whatever you want your file to be
    saveAs(blob, entityDisplayData + "_download.pdf");
    // const fileName = entityDisplayData +'_' + new Date().toLocaleDateString();
    // appGlobals.createAndDownloadBlobFile(responseData, options, fileName);
  }

  refresh(event) {
    this.getResponse(this.currentPage);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : data.forEach(row => this.selection.select(row));
  }
  selectRow(row) {
    this.selection.toggle(row);
    // console.log(this.selection);
  }
  openModalConsignment(template, aData){
    //this.entity_id = aData.entity_id;
    this.cNote = new ConsignmentNotes(aData);
    // console.log(this.cNote);
    if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
      this.trips = this.cNote.consignment_trips[0].trip;
    }
    let modalRefReally = this.modalRef = this.openModalGeneric(template, aData);
    return modalRefReally;
  }
  openModalAddBillToMultiple(template) {
    if (this.selection && !this.selection.isEmpty()) {
      // console.log(this.selection);
      // let formDataMultiple = [];
      // this.selection.selected.forEach(element => {
      //   if (!(element.truck_hire_challan_status_cd == 'ADVANCE REQUESTED')) {
      //     this.selection.toggle(element);
      //   }
      // });
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    } else {
      alert('Please select Consignment Notes to add bill');
    }
  }
  submitAddBillToMultiple() {
    alert('adding bills');
  }
  getResponseToExcel(pageNo = 1) {
    this.currentPage = pageNo;
    // this.observeFieldChanges();
    return this.consignmentNotesService
      .exportList(this.mergeWithSearch())
      .subscribe(
        response => {
          this.downloadFile(response);
        },
        error => {
          // console.log(error);
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
  }
  mergeWithSearch() {
    // console.log(this.searchForm.value);
    let mergedSearchResult = Object.assign(
      {},
      {
        page: this.currentPage,
        bookings_id: this.bookings_id,
        customers_id: this.customers_id
      },
      this.searchForm.value
    );
    mergedSearchResult = appGlobals.removeEmpty(mergedSearchResult);
    return mergedSearchResult;
  }
  submitSearch(aData = {}) {
    return this.getResponse();
  }
  resetSearch() {
    this.searchForm.reset();
    this.getResponse();
  }
  toggleSearchBar() {
    this.searchBarOpen = !this.searchBarOpen;
  }

  setterritoryCd(event) {
    this.searchForm.patchValue({ territory_cd: event });
  }
  setbranchCd(event) {
    this.searchForm.patchValue({ branch_cd: event });
  }
  setSearchStatusCd(event) {
    this.searchForm.patchValue({ consignment_status_cd: event });
  }

  setExecutive(option) {
    this.searchForm.patchValue({ executive_id: option.id });
    this.searchForm.patchValue({ executive_name: option.name });
  }
  getResponseByPage(event) {
    // console.log(event);
    this.getResponse(event.pageIndex + 1);
  }
  initializeList() {
    this.getResponse();
  }
  hasTrucks(element) {
    // console.log(element);
    if (
      element.consignment_trips &&
      element.consignment_trips.length &&
      element.consignment_trips[0].trip &&
      element.consignment_trips[0].trip.trucks &&
      element.consignment_trips[0].trip.trucks.length
    ) {
      return true;
    }
    return false;
  }
  getResponse(pageNo = 1) {
    this.currentPage = pageNo;
    return this.consignmentNotesService
      .getList(this.mergeWithSearch())
      .subscribe(response => {
        // console.log(response);
        this.consignmentNotesList = ConsignmentNotes.toArray(response.data);
        // console.log(this.consignmentNotesList);
        data = this.consignmentNotesList;
        this.dataSource = new ConsignmentNotesDataSource();
        this.total = response.total;
        this.per_page = response.per_page;
        this.currentPage = response.current_page; // pageNo;
      });
  }

  deleteConsignmentNotes(id) {}
  editConsignmentNotes(id) {
    this.router.navigate(['/consignmentNotes/edit', id]);
  }
  viewConsignmentNotes(id) {
    this.router.navigate(['/consignmentNotes/view', id]);
  }
  observeWebXpressBill() {
    appGlobals.observeChanges(
      this,
      this.invoicesFormGroup.controls.webxpress_invoice_code,
      'invoiceList',
      'getInvoiceSearchService',
      'setInvoiceList',
      'webxpress_invoice_code', '', 'invoiceCheck'
    );
  }
  setInvoice(invoice){
    // console.log(invoice);
    this.invoicesFormGroup.patchValue({id: invoice['id']});
    this.invoicesFormGroup.patchValue({webxpress_invoice_code: invoice['webxpress_invoice_code']});
    this.invoicesFormGroup.patchValue({invoice_ref: invoice['invoice_ref']});
    this.invoicesFormGroup.patchValue({invoice_status_cd: invoice['invoice_status_cd']});
    this.invoicesFormGroup.patchValue({invoice_total_amt: invoice['invoice_total_amt']});
    this.invoicesFormGroup.patchValue({invoice_dt: invoice['invoice_dt']});
    this.invoicesFormGroup.patchValue({invoice_remarks: invoice['invoice_remarks']});
    this.invoicesFormGroup.patchValue({invoice_scan: invoice['invoice_scan']});
    // this.invoicesFormGroup.patchValue({
    //   invoice_total_amt: invoiceLine.freight_amt
    // });
  }
  setFormForBilling(resetAll = true) {
    if(resetAll){
      this.invoicesFormGroup.reset();
    }

    // console.log('added webxpress observer');
    if (this.cNote) {
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {

        const invoiceLine = this.cNote.invoice_lines[0];
        // console.log(invoiceLine);
        this.invoicesFormGroup.patchValue(invoiceLine.invoice);
        this.invoicesFormGroup.patchValue({
          invoice_line_amt: invoiceLine.invoice_line_amt
        });
        this.invoicesFormGroup.patchValue({
          consignment_notes_id: invoiceLine.consignment_notes_id
        });
        this.invoicesFormGroup.patchValue({
          freight_amt: invoiceLine.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          loading_charges: invoiceLine.loading_charges
        });
        this.invoicesFormGroup.patchValue({
          unloading_charges: invoiceLine.unloading_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_charges: invoiceLine.detention_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_days: invoiceLine.detention_days
        });
        this.invoicesFormGroup.patchValue({
          invoice_total_amt: invoiceLine.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          misc_charges_amt: invoiceLine.misc_charges_amt
        });
      } else {
        this.invoicesFormGroup.patchValue({
          invoice_line_amt: this.cNote.total_amt
        });
        this.invoicesFormGroup.patchValue({
          freight_amt: this.cNote.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          loading_charges: this.cNote.loading_charges
        });
        this.invoicesFormGroup.patchValue({
          unloading_charges: this.cNote.unloading_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_charges: this.cNote.detention_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_days: this.cNote.detention_days
        });
        this.invoicesFormGroup.patchValue({
          invoice_total_amt: this.cNote.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          misc_charges_amt: this.cNote.misc_charges_amt
        });

        this.invoicesFormGroup.patchValue({
          customers_id: this.cNote.bookings.customers_id
        });
        this.invoicesFormGroup.patchValue({
          invoice_status_cd: 'BILL GENERATED'
        });
        const currentDate = new Date();
        this.invoicesFormGroup.patchValue({ invoice_dt: currentDate });
        this.invoicesFormGroup.patchValue({
          consignment_notes_id: this.cNote.id
        });
        this.invoicesFormGroup.patchValue({
          invoice_status_cd: 'BILL GENERATED'
        });
      }
    }
  }
  updateSkipEWayStatus(template: TemplateRef<any>, cNoteData) {
    this.cNote = new ConsignmentNotes(cNoteData);
    if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
      this.trips = this.cNote.consignment_trips[0].trip;
    }
    this.consignmentNotesSkipWayForm.patchValue({
      consignment_notes_id: this.cNote.id
    });
    this.consignmentNotesSkipWayForm.patchValue({
      is_skip_eway: this.cNote.is_skip_eway
    });
    this.modalRef = this.modalService.show(template);
  }
  needSkipEWayButton(cNoteData: ConsignmentNotes){
    const user = StorageService.getUser();
    if(user.roles_id === 10 ||  user.id == 1 || StorageService.userIsKAM()) {
      if(cNoteData.consignment_status_cd == 'INCOMPLETE'
      || cNoteData.consignment_status_cd == 'BOOKED'
      || cNoteData.consignment_status_cd == 'PICKED UP' ) {
        return true;
      }
      return false;
    }
    return false;
  }

  getTruckTypeValue(key) {
    const value = appGlobals.getDropDownValue('truck_type_cd', key);
    if(value && value != null) {
      return value.substring(0,20);
    }
    return '';
  }
  setConsignmentNotes(anEvent){
    // this.cNote =
  }
  receivedOnComplete(anEvent){
    //  console.log(anEvent);
       if(anEvent) {
         this.getResponse();
         this.closeModalGeneric();
       }
  }
  needSendLr(aData: ConsignmentNotes){
    return this.needDownloadAsPDF(aData);
  }
  needCompleteButton(aData: ConsignmentNotes){
    if(!aData.consignment_no || !aData.consignment_scan){
      return true;
    }
    return false;
  }
  needDownloadAsPDF(aData) {
    if(aData.consignment_no) {
      if(aData.consignment_trips && aData.consignment_trips[0]){
        let atrip = aData.consignment_trips[0].trip;
        if(atrip.trips_status_cd != 'NEW'
        && atrip.trips_status_cd != 'ORDER CONFIRMED'
        && atrip.trips_status_cd != 'TRIP CONFIRMED'
        && atrip.trips_status_cd != 'CANCELLED') {
          return true;
        }
      }

      return false;
    }
    return false;
  }
  doThing(){
 //  console.log('dothing in consignment note list ');

  }
  downloadConsignmentPreviewAsHtml(aData){
    this.consignmentNotesService.previewConsignmentAsHtml(aData.id).subscribe(
      response => {
        // console.log('got html response');
        // console.log(response);
        this.htmlPreview = response.data;
        this.modalRef = this.modalService.show(this.previewAsHtml);
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadConsignmentPreviewAsPDF(aData){
    this.consignmentNotesService.previewConsignmentAsPDF(aData.id).subscribe(
      response => {
        // console.log('got pdf response');
        this.downloadPdfFile(response, aData.consignment_no);
        this._snackBarService.open('LR ' + aData.consignment_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadConsignmentAsPDF(aData){
    this.consignmentNotesService.downloadConsignmentAsPDF(aData.id).subscribe(
      response => {
        // console.log('got pdf response');
        this.downloadPdfFile(response, aData.consignment_no);
        this._snackBarService.open('LR ' + aData.consignment_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadKNConsignmentAsPDF(aData){
    this.consignmentNotesService.downloadKNConsignmentAsPDF(aData.id).subscribe(
      response => {
        // console.log('got pdf response');
        this.downloadPdfFile(response, aData.consignment_no);
        this._snackBarService.open('LR ' + aData.consignment_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  showErrorDialog(error) {
    this._dialogService.openAlert({
      title: Error,
      message: appGlobals.getDisplayErrorMessage(error)
    });
  }
  submitMrComplete(aData) {
    // console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else if (aData) {
      let invoice = new Invoices(aData);
      let invoiceLine = new InvoiceLines();
      // console.log(aData);
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
        invoiceLine = this.cNote.invoice_lines[0];
        invoice = invoiceLine.invoice;
        invoice.fill(aData);
      }
      invoiceLine.invoices_id = invoice.id;
      invoiceLine.invoice_line_amt = aData.invoice_line_amt;
      invoiceLine.consignment_notes_id = aData.consignment_notes_id;
      delete invoiceLine.invoice;
      invoice.invoice_lines = [invoiceLine];
      // console.log(invoice);
      this.consignmentNotesService.updateInvoicePaid(invoice).subscribe(
        response => {
          this.closeModalGeneric();
          this.getResponse(this.currentPage);

          this._snackBarService.open('Bill updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitSendLr(aData) {
    if (!this.sendConsignmentFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.sendConsignmentFormGroup
      );
      appGlobals.markFormGroupTouched(this.sendConsignmentFormGroup);
      return;
    }
    if (aData) {
      // aData.remarks = this.cNote.remarks  + '<\BR>' + aData.remarks;
      this.consignmentNotesService.sendConsignmentAsPDF(aData).subscribe(
        response => {
          this.modalRef.hide();
          // this.initializeList();
          this._snackBarService.open('Mail sent to ' + aData.to_name, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitSkipeWay(aData){

    if (!this.consignmentNotesSkipWayForm.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesSkipWayForm
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesSkipWayForm);
      return;
    }
    if (aData) {
      // aData.remarks = this.cNote.remarks  + '<\BR>' + aData.remarks;
      this.consignmentNotesService.updateSkipEWay(aData).subscribe(
        response => {
          this.modalRef.hide();
          // this.initializeList();
          this._snackBarService.open('Status updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  openModalBill(updateLocation: TemplateRef<any>, cNoteData) {
      this.cNote = new ConsignmentNotes(cNoteData);

      // if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
      //   this.trips = this.cNote.consignment_trips[0].trip;
      // }
      // this.setFormForBilling();
      this.modalRef = this.modalService.show(updateLocation);
      // console.log('billmodalshow');
      // this.observeWebXpressBill();

  }
  doOnEntitySaveComplete(aData?: any){
    // console.log('doOnEntitySaveComplete called ');
    // console.log(aData);
    this._snackBarService.open('Bill saved', 'X', {
      duration: appGlobals.SNACKBAR_TIMEOUT
    });
    this.closeModalGeneric();
    this.getResponse(this.currentPage);

  }
  getInvoiceLineTotal(event){
    // console.log(event);
    const total =  Number(this.invoicesFormGroup.get('freight_amt').value)
    + Number(this.invoicesFormGroup.get('loading_charges').value)
    + Number(this.invoicesFormGroup.get('unloading_charges').value)
    + Number(this.invoicesFormGroup.get('detention_charges').value)
    + Number(this.invoicesFormGroup.get('misc_charges_amt').value);
    // console.log(total);
    this.invoicesFormGroup.patchValue({
      invoice_line_amt: total
    });

  }
  openModalSubmitted(updateLocation: TemplateRef<any>, cNoteData) {
    this.cNote = new ConsignmentNotes(cNoteData);
    this.invoicesFormGroup.reset();
    if (cNoteData) {
      this.cNote = new ConsignmentNotes(cNoteData);
      if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
        this.trips = this.cNote.consignment_trips[0].trip;
      }
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
        const invoiceLine = this.cNote.invoice_lines[0];
        this.invoicesFormGroup.patchValue(invoiceLine.invoice);
        this.invoicesFormGroup.patchValue({
          invoice_line_amt: invoiceLine.invoice_line_amt
        });
        this.invoicesFormGroup.patchValue({
          consignment_notes_id: invoiceLine.consignment_notes_id
        });
        this.invoicesFormGroup.patchValue({
          freight_amt: invoiceLine.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          loading_charges: invoiceLine.loading_charges
        });
        this.invoicesFormGroup.patchValue({
          unloading_charges: invoiceLine.unloading_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_charges: invoiceLine.detention_charges
        });
        this.invoicesFormGroup.patchValue({
          detention_days: invoiceLine.detention_days
        });
        this.invoicesFormGroup.patchValue({
          invoice_total_amt: invoiceLine.freight_amt
        });
        this.invoicesFormGroup.patchValue({
          misc_charges_amt: invoiceLine.misc_charges_amt
        });
        this.invoicesFormGroup.patchValue({
          invoice_status_cd: 'BILL SUBMITTED'
        });
        const paymentDays = this.cNote.bookings.customers.credit_period_days;
        const currentDate = new Date();
        const paymentDueDate = new Date();
        paymentDueDate.setDate(currentDate.getDate() + paymentDays);
        // console.log();
        // this.invoicesFormGroup.patchValue({});
        // this.invoicesFormGroup.patchValue({ invoice_dt: currentDate });
        this.invoicesFormGroup.patchValue({ submitted_dt: currentDate });
        this.invoicesFormGroup.patchValue({ payment_due_dt: paymentDueDate });
        this.invoicesFormGroup.patchValue({
          consignment_notes_id: this.cNote.id
        });
        // console.log(this.invoicesFormGroup);
        this.modalRef = this.modalService.show(updateLocation);
      } else {
        alert('Please add bill first.');
        return;
      }

    }
  }
  openModalPayment(updateLocation: TemplateRef<any>, cNoteData) {
    this.cNote = new ConsignmentNotes(cNoteData);
    if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
      this.trips = this.cNote.consignment_trips[0].trip;
    }
    this.invoicesFormGroup.reset();
    if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
      const invoiceLine = this.cNote.invoice_lines[0];
      // console.log(invoiceLine);
      this.invoicesFormGroup.patchValue(invoiceLine.invoice);
      this.invoicesFormGroup.patchValue({
        invoice_line_amt: invoiceLine.invoice_line_amt
      });
      this.invoicesFormGroup.patchValue({
        consignment_notes_id: invoiceLine.consignment_notes_id
      });
      this.invoicesFormGroup.patchValue({
        invoice_status_cd: 'PAYMENT RECEIVED'
      });

      this.modalRef = this.modalService.show(updateLocation);
    } else {
      alert('Please enter bill information first. ');
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = window.innerWidth;
    // console.log('window resized ' + this.innerWidth);
  }
  isDesktop() {
    if(!this.innerWidth) {
      this.innerWidth = window.innerWidth;
    }
    // console.log(this.innerWidth);
    if( this.innerWidth >  1000) {
      return true;
    }
    return false;
  }

  openModalGeneric(template: TemplateRef<any>, aData?: any ) {


    // console.log(this.usePreviewPanel);
    if(this.usePreviewPanel && this.isDesktop()) {
      this.currentTemplate =  template;
      this.modalRef = null;
    }
    else {
      this.modalRef = this.modalService.show(template, aData);
      StorageService.modelRefs.push(this.modalRef);
      return this.modalRef;
    }
  }
  closeModalGeneric(aModelRef?: BsModalRef) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if(StorageService.modelRefs.length > 0) {
        this.modalRef = StorageService.modelRefs.pop();
        this.modalRef.hide();

    }
 //  console.log('closing generic modal');
  }
  openModalSendConsignment(updateLocation: TemplateRef<any>, cNoteData){
    this.sendConsignmentFormGroup.reset();
    if (cNoteData) {
      this.cNote = new ConsignmentNotes(cNoteData);
      this.sendConsignmentFormGroup.patchValue(this.cNote);
      this.openModalGeneric(updateLocation, cNoteData );
      // this.modalRef = this.modalService.show(updateLocation);
    }
  }
  openModalPOD(updateLocation: TemplateRef<any>, cNoteData, statusCd = '') {

    this.consignmentNotesFormGroup2.reset();
    if (cNoteData) {
      this.cNote = new ConsignmentNotes(cNoteData);
      if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
        this.trips = this.cNote.consignment_trips[0].trip;
      }
      this.consignmentNotesFormGroup2.patchValue(this.cNote);

      if (statusCd !== '') {
        this.consignmentNotesFormGroup2.patchValue({
          consignment_status_cd: statusCd
        });
      }

      this.makePODMandatory();
      this.modalRef = this.modalService.show(updateLocation);
    }
  }
  submitTripLocation(data) {
    let tripTracking = new ConsignmentTripTrackings(data);
    if (tripTracking) {
      this.consignmentTripTrackingsService
        .updateConsignmentTripTrackings(tripTracking)
        .subscribe(response => {
          this.modalRef.hide();
          this.consignmentNotesFormGroup.reset();
        });
    }
  }
  getNextStatus(element: ConsignmentNotes) {
    return ConsignmentNotes.nextStatus(
      element.consignment_status_cd,
      element.business_type_cd,
      element.movement_type_cd
    );
  }
  needNextStatusButton(element) {
    if(element.consignment_status_cd === 'INCOMPLETE'
    || element.consignment_status_cd === 'BOOKED'
    || element.consignment_status_cd === 'PICKED UP') {
      return true;;
    }
    if(element.consignment_status_cd === 'IN TRANSIT'
    || element.consignment_status_cd === 'AT DELIVERY LOCATION'
    || element.consignment_status_cd === 'DELIVERED'
    // || element.consignment_status_cd === 'ePOD UPLOADED'
    ) {
      return true;;
    }

    return false;
  }
  updateToNextStatus(
    updateLocation: TemplateRef<any>,
    element: ConsignmentNotes,
    nextStatus = ''
  ) {
    this.cNote = new ConsignmentNotes(element);
    if(this.cNote.consignment_trips && this.cNote.consignment_trips[0]){
      this.trips = this.cNote.consignment_trips[0].trip;
    }
    if (nextStatus === '') {
      nextStatus = ConsignmentNotes.nextStatus(
        element.consignment_status_cd,
        element.business_type_cd,
        element.movement_type_cd
      );
    }

    this.consignmentNotesFormGroup.reset();
    // this.consignmentNotesFormGroup.patchValue(this.cNote);
    this.consignmentNotesFormGroup.patchValue({id: this.cNote.id});
    this.consignmentNotesFormGroup.patchValue({consignment_notes_id: this.cNote.id});

    this.consignmentNotesFormGroup.patchValue({consignment_status_cd: nextStatus});
    this.consignmentNotesFormGroup.patchValue({
      status_at: new Date()
    });
    let currentHour = (new Date()).getHours() + '';
    if(currentHour.length === 1) {
      currentHour = '0' + currentHour;
    }
    let currentMinutes = (new Date()).getMinutes() + '';
    if(currentMinutes.length === 1) {
      currentMinutes = '0' + currentMinutes;
    }
    const currentTime = currentHour + ':' + currentMinutes;
    this.consignmentNotesFormGroup.patchValue({
      status_at_time: currentTime
    });
    this.modalRef = this.modalService.show(updateLocation);
  }
  submitStatus(aData) {
    if (!this.consignmentNotesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup);
      return;
    }
    else if(aData.consignment_status_cd === 'IN TRANSIT'
    || aData.consignment_status_cd === 'AT DELIVERY LOCATION'){
      this.cNoteFormGroup.patchValue(this.cNote);
      if (!this.cNoteFormGroup.valid) {
        this.isError = true;
        this.errorMessages = appGlobals.getFormValidationErrors(
          this.cNoteFormGroup
        );
        appGlobals.markFormGroupTouched(this.cNoteFormGroup);
        this._dialogService.openAlert({ title: 'Error', message: 'Please edit the consignment to enter mandatory values.' });
        return;
      }
    }
    if (aData) {
      // this.cNote.fill(data);
      aData.status_at = new Date((aData.status_at.toDateString() + ' ' + aData.status_at_time).replace(/-/g, "/"));

      // console.log(aData);
      this.consignmentNotesService.updateStatus(aData).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('Status updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  needEPODButton(element: ConsignmentNotes) {
    if(!element.epod_dt || !element.pod_scan) {
      return true;
    }
    return false;
  }
  needPhysicalPODButton(element: ConsignmentNotes){
    if(!this.needEPODButton(element) &&  !element.physical_pod_dt) {
      return true;
    }
    return false;
  }
  needBillButton(element) {
    if(
      // element.consignment_status_cd === 'DELIVERED' ||
      element.consignment_status_cd === 'POD GENERATED'
    || element.consignment_status_cd === 'POD REVIEWED'
    || element.consignment_status_cd === 'HARD POD RECEIVED'
    // || element.consignment_status_cd === 'BILLED'
    // || element.consignment_status_cd === 'BILL GENERATED'
    ) {
      return true;
    }
    return false;
  }
  needEditButton(element) {
    if(StorageService.checkRole([10])){
      return true;
    }
    if(
      element.consignment_status_cd === 'INCOMPLETE'
    ||  element.consignment_status_cd === 'PICKED  UP'
    ||  element.consignment_status_cd === 'BOOKED'
    ) {
      return true;
    }
    return false;
  }
  needBillSubmissionButton(element) {
    if(element.consignment_status_cd === 'BILL GENERATED'
    // || element.consignment_status_cd === 'BILLED'
    // || element.consignment_status_cd === 'PAYMENT RECEIVED'
    ) {
      return true;
    }
    return false;
  }
  needPaymentButton(element) {
    if(element.consignment_status_cd === 'BILLED') {
      return true;
    }
    return false;
  }
  setConsignmentStatusCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: event
    });
  }
  getStringBeforeComma(str) {
    return appGlobals.getStringBeforeComma(str);
  }
  selectLocation(event) {
    this.consignmentNotesFormGroup.patchValue({
      last_location: appGlobals.selectCityRegion(event)
    });
    this.consignmentNotesFormGroup.patchValue({
      last_location_lat: event.geometry.location.lat()
    });
    this.consignmentNotesFormGroup.patchValue({
      last_location_long: event.geometry.location.lng()
    });
  }
  setTripsStatusCd(event) {
    this.consignmentNotesFormGroup.patchValue({ trips_status_cd: event });
  }
  updatePOD(event, cnote: ConsignmentNotes) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        cnote.pod_scan = response.data.filename;
        this.consignmentNotesService
          .updateConsignmentNotes(cnote)
          .subscribe(response => {});
      });
  }
  fileChange2(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentNotesFormGroup2.patchValue({
          pod_scan: response.data.filename
        });
        this.cNote.pod_scan = response.data.filename;
      });
  }

  fileChange3(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentNotesFormGroup2.patchValue({
          pod_scan_2: response.data.filename
        });
        this.cNote.pod_scan_2 = response.data.filename;
      });
  }
  fileChange4(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.consignmentNotesFormGroup2.patchValue({
          gate_in_scan: response.data.filename
        });
        this.cNote.gate_in_scan = response.data.filename;
      });
  }

  downloadPODStatusReport() {
    const aData = { time_period: 'YTD' };

    return this.consignmentNotesService
      .podstatusreport(aData)
      .subscribe(
        response => {
          // console.log(response);
          // if(response.status) {

          //   this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(response.message) });
          // }
          // else {
            this.downloadFile(response);
            // this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(response.message) });
          // }
        },
        error => {
          // console.log(error);
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );

  }
  uploadInvoiceScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
      });
  }
  uploadSubmissionScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          submission_scan: response.data.filename
        });
      });
  }
  uploadPaymentScan(event) {
    this.fileTransferService
      .uplodaFile(event.srcElement.files[0])
      .subscribe(response => {
        this.invoicesFormGroup.patchValue({
          payment_scan: response.data.filename
        });
      });
  }
  submitBillSubmission(aData) {
    // console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else if (aData) {
      // console.log(aData);
      let invoice = new Invoices(aData);
      let invoiceLine = new InvoiceLines();
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
        invoiceLine = this.cNote.invoice_lines[0];
        invoice = invoiceLine.invoice;
        invoice.fill(aData);

      }
      invoiceLine.invoices_id = invoice.id;
      invoiceLine.invoice_line_amt = aData.invoice_line_amt;
      invoiceLine.consignment_notes_id = aData.consignment_notes_id;
      //invoiceLine.submitted_dt = aData.consignment_notes_id;
      delete invoiceLine.invoice;
      invoice.invoice_lines = [invoiceLine];
      // console.log(invoice);
      // console.log(invoice);
      this.consignmentNotesService.markSubmitted(invoice).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('Bill updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitPayment(aData) {
    // console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else if (aData) {
      let invoice = new Invoices(aData);
      let invoiceLine = new InvoiceLines();
      // console.log(aData);
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
        invoiceLine = this.cNote.invoice_lines[0];
        invoice = invoiceLine.invoice;
        invoice.fill(aData);
      }
      invoiceLine.invoices_id = invoice.id;
      invoiceLine.invoice_line_amt = aData.invoice_line_amt;
      invoiceLine.consignment_notes_id = aData.consignment_notes_id;
      delete invoiceLine.invoice;
      invoice.invoice_lines = [invoiceLine];
      // console.log(invoice);
      this.consignmentNotesService.updateInvoicePaid(invoice).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('Bill updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitBill(aData) {
    // console.log(aData);
    if (!this.invoicesFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.invoicesFormGroup
      );
      appGlobals.markFormGroupTouched(this.invoicesFormGroup);
    } else if (aData) {
      let invoice = new Invoices(aData);
      let invoiceLine = new InvoiceLines();
      if (this.cNote.invoice_lines && this.cNote.invoice_lines[0]) {
        invoiceLine = this.cNote.invoice_lines[0];
        invoice = invoiceLine.invoice;
        invoice.fill(aData);
      }
      invoiceLine.invoices_id = invoice.id;
      invoiceLine.invoice_line_amt = aData.invoice_line_amt;
      invoiceLine.freight_amt = aData.freight_amt;
      invoiceLine.loading_charges = aData.loading_charges;
      invoiceLine.misc_charges_amt = aData.misc_charges_amt;
      invoiceLine.unloading_charges = aData.unloading_charges;
      invoiceLine.detention_charges = aData.detention_charges;
      invoiceLine.detention_days = aData.detention_days;
      invoiceLine.consignment_notes_id = aData.consignment_notes_id;
      delete invoiceLine.invoice;
      invoice.invoice_lines = [invoiceLine];
      // console.log(invoice);
      this.consignmentNotesService.updateInvoice(invoice).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('Bill updated', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitPODReview(data) {
    this.makePODMandatory();
    if (!this.consignmentNotesFormGroup2.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup2
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup2);
    } else if (data) {
      // this.cNote.consignment_status_cd = data.consignment_status_cd;
      // this.cNote.consignment_no = data.consignment_no;
      // this.cNote.trips_status_cd = data.trips_status_cd;
      // this.cNote.delivery_date = data.delivery_date;
      // this.cNote.delivery_time = data.delivery_time;
      // this.cNote.pod_remarks = data.pod_remarks;
      // this.cNote.pod_scan = data.pod_scan;
      // this.cNote.unloading_charges = data.unloading_charges;
      // this.cNote.damage_deduction = data.damage_deduction;
      // this.cNote.recieved_by = data.recieved_by;
      this.cNote.fill(data);
      this.cNote.consignment_status_cd = 'POD REVIEWED';
      this.consignmentNotesService.podUpload(this.cNote).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('POD uploaded', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitDeliver(data) {
    this.makePODMandatory();
    if (!this.consignmentNotesFormGroup2.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup2
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup2);
    } else if (data) {
      // this.cNote.consignment_status_cd = data.consignment_status_cd;
      // this.cNote.consignment_no = data.consignment_no;
      // this.cNote.trips_status_cd = data.trips_status_cd;
      // this.cNote.delivery_date = data.delivery_date;
      // this.cNote.delivery_time = data.delivery_time;
      // this.cNote.pod_remarks = data.pod_remarks;
      // this.cNote.pod_scan = data.pod_scan;
      // this.cNote.unloading_charges = data.unloading_charges;
      // this.cNote.damage_deduction = data.damage_deduction;
      // this.cNote.recieved_by = data.recieved_by;
      this.cNote.fill(data);
      this.consignmentNotesService.podUpload(this.cNote).subscribe(
        response => {
          this.modalRef.hide();
          this.initializeList();
          this._snackBarService.open('POD uploaded', 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  needDispatchButton(itemData: ConsignmentNotes) {
    if (
      !itemData.consignment_status_cd ||
      itemData.consignment_status_cd === 'INCOMPLETE' ||
      itemData.consignment_status_cd === 'BOOKED'
    ) {
      return true;
    }
    return false;
  }
  needDeliverButton(itemData: ConsignmentNotes) {
    if (
      itemData.consignment_status_cd &&
      (
        // itemData.consignment_status_cd === 'DELIVERED'
        itemData.consignment_status_cd === 'POD GENERATED'

        )
    ) {
      return true;
    }
    return false;
  }
  needHardPODButton(itemData: ConsignmentNotes) {
    if (itemData.epod_dt && !itemData.physical_pod_dt){
      return true;
    }
    return false;
  }
  needReviewPODButton(itemData: ConsignmentNotes) {
    if (itemData.epod_dt &&
      (
      itemData.consignment_status_cd == 'ePOD UPLOADED'
      || itemData.consignment_status_cd == 'HARD POD RECEIVED'
      )
      ){
      return true;
    }
    return false;
  }
  getStatuses() {
    return ConsignmentNotes.consignmentStatuses;
  }
  makeStatusMandatory() {}
  makePODMandatory() {
    const consignmentStatus = this.consignmentNotesFormGroup2.get(
      'consignment_status_cd'
    ).value;
    if (
      consignmentStatus === 'ePOD UPLOADED' ||
      consignmentStatus === 'HARD POD RECEIVED'
      || consignmentStatus === 'POD GENERATED'
      || consignmentStatus === 'DELIVERED'
    ) {
      this.consignmentNotesFormGroup2.controls['pod_scan'].setValidators(
        Validators.required
      );
      // this.consignmentNotesFormGroup2.controls['recieved_by'].setValidators(
      //   Validators.required
      // );

      this.consignmentNotesFormGroup2.controls[
        'delivery_condition_cd'
      ].setValidators(Validators.required);
      this.consignmentNotesFormGroup2.controls['delivery_date'].setValidators(
        Validators.required
      );
      this.consignmentNotesFormGroup2.controls['delivery_time'].setValidators(
        Validators.required
      );

    }
    if (consignmentStatus === 'CANCELLED') {
      this.consignmentNotesFormGroup2.controls[
        'cancel_reason_cd'
      ].setValidators(Validators.required);
    }
    this.consignmentNotesFormGroup2.updateValueAndValidity();
    // delivery_date: ['', [Validators.required]],
    // delivery_time: ['', [Validators.required]],
    // recieved_by: ['', [Validators.required]],
    // delivery_condition_cd: ['', [Validators.required]],
  }
  getDeliveryConditions() {
    return ConsignmentNotes.consignmentDeliveryCondition;
  }
  getCancelReasons() {
    return ConsignmentNotes.cancelReasons;
  }
}
let data: ConsignmentNotes[];
export class ConsignmentNotesDataSource extends DataSource<any> {
  connect(): Observable<ConsignmentNotes[]> {
    return of(data);
  }
  disconnect() {}
}
