import { safeDate } from '../../core/globalfunctions';
import { Validators } from '@angular/forms';

export class ConsignmentItems {
  id: number;
  _id: number;
  consignment_notes_id: number;
  quantity: number;
  unit_cd: string;
  description: string;
  actual_wt: number;
  charged_wt: number;
  invoice_no: string;
  invoice_value: number;
  invoice_dt: Date;
  eway_bill_no: string;
  eway_expiry_dt: Date;
  eway_valid_upto_dt: Date;
  eway_hsn_cd: string;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): ConsignmentItems[] {
    let consignmentItems: ConsignmentItems[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        consignmentItems.push(new ConsignmentItems(json));
      }
    }
    return consignmentItems;
  }
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this._id = json._id;
      this.consignment_notes_id = json.consignment_notes_id;
      this.quantity = json.quantity;
      this.unit_cd = json.unit_cd;
      this.description = json.description;
      this.actual_wt = json.actual_wt;
      this.charged_wt = json.charged_wt;
      this.invoice_no = json.invoice_no;
      this.invoice_value = json.invoice_value;

      safeDate(this, json,  'invoice_dt');


      this.eway_bill_no = json.eway_bill_no;
      safeDate(this, json,  'eway_valid_upto_dt');
      this.eway_hsn_cd = json.eway_hsn_cd;
    }
  }

}
export const consignmentItemsFormControls = {
  id: ['', []],
  consignment_notes_id: ['', []],
  eway_bill_no: ['', [Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]],
  eway_hsn_cd: ['', []],
  eway_valid_upto_dt: ['', []],
  invoice_no: ['', [Validators.required]],
  invoice_dt: ['', [Validators.required]],
  invoice_value: ['', [Validators.required]],
  quantity: ['', [Validators.required]],
  unit_cd: ['', [Validators.required]],
  description: ['', [Validators.required]],
  actual_wt: ['', []],
  charged_wt: ['', []],
  _id: ['', []],

}
