
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../users/services/users.service';
import { CNoteListComponent } from '../../consignmentNotes/list/list.component';
import { InvoicesService } from '../../invoices/services/invoices.service';
@Component({
  selector: 'app-triplitecnotelist-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [
    ConsignmentNotesService,
    ConsignmentTripTrackingsService,
    FileTransferService,
    UsersService,
    InvoicesService
  ]
})


export class TripliteCnoteListComponent extends CNoteListComponent {

  constructor(
    protected fileTransferService: FileTransferService,
    protected consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    protected modalService: BsModalService,
    protected _formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected consignmentNotesService: ConsignmentNotesService,
    protected _snackBarService: MatSnackBar,
    protected _dialogService: DialogService,
    protected userService: UsersService,
    protected invoiceService:  InvoicesService
  ) {
    super(
      fileTransferService,
      consignmentTripTrackingsService,
      modalService,
      _formBuilder,
      router,
      route,
      consignmentNotesService,
      _snackBarService,
      _dialogService,
      userService,
      invoiceService,
    );
  }

}

