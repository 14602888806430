import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Chart } from "chart.js";

@Component({
  selector: "app-download-pdf",
  templateUrl: "./download-pdf.component.html",
  styleUrls: ["./download-pdf.component.css"],
})
export class DownloadPdfComponent implements OnInit {
  @ViewChild("orderSummaryChart", { static: true }) orderSummaryChart;
  @ViewChild("orderTypeChart", { static: true }) orderTypeChart;
  @ViewChild("overAllChart", { static: true }) overAllChart;
  @ViewChild("progressChartOne", { static: true }) progressChartOne;
  @ViewChild("progressChartTwo", { static: true }) progressChartTwo;

  orderSummaryChartSummary: any;
  orderTypeChartSummary: any;
  overAllChartSummary: any;
  progressChartOneSummary: any;
  progressChartTwoSummary: any;
  constructor() {}

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.chartOne();
    this.chartTwo();
    this.chartThree();
    this.chartFour();
    this.chartFive();
  }

  chartOne() {
    let canvas = this.orderSummaryChart.nativeElement;
    let ctx = canvas.getContext("2d");
    this.orderSummaryChartSummary = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Completed", "Cancelled", "Confirmed"],
        datasets: [
          {
            label: "Orders",
            data: [12, 2, 30],
            backgroundColor: ["#fff", "#C3C3C3", "#ADADAD"],
            hoverBackgroundColor: ["#fff", "#C3C3C3", "#ADADAD"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
        ],
      },
      options: {
        responsive: true,
        cutoutPercentage: 70,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
          },
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
        elements: { arc: { borderWidth: 0 } }
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            var fontSize = 3.5;
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";

            let text = "128",
              textX =
                Math.round((width - ctx.measureText(text).width) / 2) - 50,
              textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ]
    });
  }

  chartTwo() {
    let canvas = this.orderTypeChart.nativeElement;
    let ctx = canvas.getContext("2d");
    this.orderTypeChartSummary = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Accepted", "Decline", "Rejected"],
        datasets: [
          {
            label: "chrt1",
            data: [12, 20],
            backgroundColor: ["#fff", "#C3C3C3"],
            hoverBackgroundColor: ["#fff", "#C3C3C3"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
          {
            label: "chart2",
            data: [12, 15, 15, 40],
            backgroundColor: ["#B8E986", "#CF858A", "#B8E986", "#F8E71C"],
            hoverBackgroundColor: ["#B8E986", "#CF858A", "#B8E986", "#F8E71C"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
        ],
      },

      options: {
        responsive: true,
        cutoutPercentage: 65,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
          },
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
      }
    });
  }

  chartThree() {
    let canvas = this.overAllChart.nativeElement;
    let ctx = canvas.getContext("2d");
    this.overAllChartSummary = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["pune", "mumbai"],
        datasets: [
          {
            label: "overAll",
            data: [20, 80],
            backgroundColor: ["#D8D8D8", "#ABABAB"],
            hoverBackgroundColor: ["#D8D8D8", "#ABABAB"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
        ],
      },
      options: {
        responsive: true,
        cutoutPercentage: 90,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
        tooltips: {
          enabled: false,
        },
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            let fontSize = 1.1;
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            let text = "80%",
              textX =
                Math.round((width - ctx.measureText(text).width) / 2) + 115,
              textY = height / 2;

            ctx.fillStyle = "#C41A20";
            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ],
    });
  }

  chartFour() {
    let canvas = this.progressChartOne.nativeElement;
    let ctx = canvas.getContext("2d");
    this.progressChartOneSummary = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["pune", "mumbai"],
        datasets: [
          {
            label: "overAll",
            data: [20, 80],
            backgroundColor: ["#D8D8D8", "#ABABAB"],
            hoverBackgroundColor: ["#D8D8D8", "#ABABAB"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
        ],
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            let fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            let text = "90%",
              textX = Math.round((width - ctx.measureText(text).width) / 2) + 5,
              textY = height / 2;

            ctx.fillStyle = "#C41A20";
            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ],
      options: {
        responsive: true,
        cutoutPercentage: 85,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

  chartFive() {
    let canvas = this.progressChartTwo.nativeElement;
    let ctx = canvas.getContext("2d");
    this.progressChartTwoSummary = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["pune", "mumbai"],
        datasets: [
          {
            label: "overAll",
            data: [20, 80],
            backgroundColor: ["#D8D8D8", "#ABABAB"],
            hoverBackgroundColor: ["#D8D8D8", "#ABABAB"],
            borderWidth: 0,
            borderColor: "#eae7e1",
          },
        ],
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            let fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            let text = "95%",
              textX = Math.round((width - ctx.measureText(text).width) / 2) + 5,
              textY = height / 2;
            ctx.fillStyle = "#C41A20";
            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ],
      options: {
        responsive: true,
        cutoutPercentage: 85,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

}
