import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
// import { MatSelectFilterModule } from 'mat-select-filter';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { NoDblClickDirective } from '../layout/directives/no-dbl-click.directive';
import { UppercaseDirective } from './../layout/directives/uppercase.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DigitOnlyDirective } from '../layout/directives/digit.only.directive';
import { DigitCharDirective } from '../layout/directives/digi.char.directive';
import { BackButtonDirective } from '../layout/directives/back.button.directive';
import { TableExporterDirective } from '../layout/directives/table.exporter.directive';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
// import { DropdownsModule } from '../layout/dropdowns/dropdowns.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { AlertDialogComponent } from '../layout/dialogs/alert-dialog/alert-dialog.component';
import { ImageDialogComponent } from '../layout/dialogs/image-dialog/image-dialog.component';
import { LeftMenuComponent } from '../layout/left-menu/left-menu.component';
import { SubMenusComponent } from '../layout/sub-menus/sub-menus.component';
import { LoginComponent } from '../user/login/login.component';
import { SignupComponent } from '../user/signup/signup.component';
import { ForgotPasswordComponent } from '../user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../user/reset-password/reset-password.component';
import { ImgSecurePipe } from '../layout/directives/img.secure.pipe';
import { DropdownsModule } from '../layout/dropdowns/dropdowns.module';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { SharedCustomerModule } from './shared.customer.module';
// import { ResponsiveColsDirective } from '../layout/directives/responsive.cols.directive';
import {OverlayModule, OverlayContainer, FullscreenOverlayContainer} from '@angular/cdk/overlay';
import { PreventDoubleClickDirective } from '../layout/directives/prevent-dbl-click.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoDblClickDisableDirective } from '../layout/directives/no-dbl-click-disable.directive';
@NgModule({
  imports: [
    // SharedCustomerModule,
    CommonModule,
    // BrowserModule,
    RouterModule,
    DropdownsModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatBadgeModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    // MatSelectFilterModule,
    MatStepperModule,
    MatSidenavModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatListModule,
    MatSortModule,
    OverlayModule,
    CdkTableModule,
    DragDropModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatTooltipModule,

  ],
  declarations: [
    UppercaseDirective, NoDblClickDirective, DigitOnlyDirective,
    DigitCharDirective, BackButtonDirective, TableExporterDirective,
    PreventDoubleClickDirective,
    NoDblClickDisableDirective,
    HeaderComponent,
    FooterComponent,
    AlertDialogComponent,
    ImageDialogComponent,
    LeftMenuComponent,
    SubMenusComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ImgSecurePipe,
  ],
  exports: [
    // SharedCustomerModule,
    CommonModule,
    // BrowserModule,
    RouterModule,
    DropdownsModule,
    MatBadgeModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    // MatSelectFilterModule,
    MatDatepickerModule,
    MatDialogModule,
    MatStepperModule,
    MatSidenavModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatSnackBarModule,
    UppercaseDirective,
    NoDblClickDirective,
    NoDblClickDisableDirective,
    DigitOnlyDirective,
    DigitCharDirective,
    BackButtonDirective,
    TableExporterDirective,
    LoadingBarHttpClientModule,
    CdkTableModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DragDropModule,
    MatProgressBarModule,
    HeaderComponent,
    FooterComponent,
    AlertDialogComponent,
    ImageDialogComponent,
    LeftMenuComponent,
    SubMenusComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ImgSecurePipe,
    MatFormFieldModule,
    OverlayModule
  ],
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
})
export class SharedModule { }
