import { safeDate } from '../../core/globalfunctions';

export class Triplite {
    id: number;
    trip_no: string;
    bookings_id: number;
    truck_hire_challans_id?: number;
    trips_status_cd: string;
    source_city: string;
    destination_city: string;
    actual_dispatch_dt?: Date;
    actual_transit_time?: number;
    estimated_transit_time: number;
    actual_arrival_dt?: Date;
    estimated_arrival_dt?: Date;
    special_instructions?: string;
    // deleted_at?: Date;
    // deleted_by: number;
    // created_by: number;
    // updated_by: number;
    // created_at: string;
    updated_at: Date;
    service_type_cd: string;
    container_type_cd: string;
    truck_type_cd: string;
    goods_type_cd: string;
    dimension_type_cd: string;
    // estimated_wt?: number;
    // estimated_vol_length?: number;
    // estimated_vol_breadth?: number;
    // estimated_vol_height?: number;
    remarks?: string;
    // bill_to_party_id: number;
    // bill_to_party_city?: string;
    // billing_location_cd?: string;
    pricing_executive_id: number;
    dispatch_executive_id: number;
    tracking_executive_id: number;
    arrival_executive_id: number;
    pricing_executive_name: string;
    dispatch_executive_name: string;
    tracking_executive_name: string;
    arrival_executive_name: string;
    finance_executive_name?: string;
    finance_executive_id?: number;
    ops_executive_name?: string;
    ops_executive_id?: number;
    location: string;
    location_lat: number;
    location_long: number;
    location_provider: string;
    // freight_amt: number;
    target_hire_amt: number;
    target_advance_percentage: number;
    target_advance_amt: number;
    movement_type_cd?: string;
    business_type_cd: string;
    booking_type_cd: string;
    pickup_icd_city?: string;
    drop_icd_city?: string;
    // trip_scan?: string;
    // exim_scan_1?: string;
    // exim_scan_2?: string;
    // exim_scan_3?: string;
    // container_no?: string;
    // seal_no?: string;
    cancel_reason_cd?: string;
    booking_branch_cd: string;
    delivery_branch_cd?: string;
    billing_branch_cd: string;
    billing_executive_id: number;
    billing_executive_name: string;
    arrival_branch_cd: string;
    territory_cd: string;
    tat?: any;
    destination_slot_dt?: Date;
    vessel_cutoff_dt?: Date;
    total_distance?: number;
    is_skip_eway: boolean;
    booking_no: string;
    booking_for_dt: string;
    booking_for_start: string;
    customers_name: string;
    truck_hire_challan_status_cd: string;
    thc_no: string;
    hire_amt: number;
    registration_no: string;
    act_truck_type_cd: string;
    drivers_id?: number;
    drivers_name?: string;
    mobile_no?: string;
    mobile_provider_cd?: string;
    telenity_consent?: string;
    telenity_enabled?: boolean;
    intugine_trip_id: string;
    intugine_trip_status: number;
    customers_id: number;
    distance_travelled: number;
    distance_remaining: number;
    time_remaining: string;
    freight_amt: number;
    trip_type_cd: string;
    constructor(json?: any) {
        this.fill(json);
      }

    static toArray(jsons: any[]): Triplite[] {
      let trips: Triplite[] = [];
      if (jsons != null) {
        for (const json of jsons) {
          trips.push(new Triplite(json));
        }
      }
      return trips;
    }
    fill(json) {
        if (json != null) {
            this.id = json.id;
            this.trip_no = json.trip_no;
            this.bookings_id = json.bookings_id;
            this.truck_hire_challans_id = json.truck_hire_challans_id;
            this.trips_status_cd = json.trips_status_cd;
            this.source_city = json.source_city;
            this.destination_city = json.destination_city;
            this.actual_transit_time = json.actual_transit_time;
            this.estimated_transit_time = json.estimated_transit_time;
            this.special_instructions = json.special_instructions;
            this.service_type_cd = json.service_type_cd;
            this.container_type_cd = json.container_type_cd;
            this.truck_type_cd = json.truck_type_cd;
            this.goods_type_cd = json.goods_type_cd;
            this.dimension_type_cd = json.dimension_type_cd;
            // this.estimated_wt = json.estimated_wt;
            // this.estimated_vol_length = json.estimated_vol_length;
            // this.estimated_vol_breadth = json.estimated_vol_breadth;
            // this.estimated_vol_height = json.estimated_vol_height;
            this.remarks = json.remarks;
            this.customers_id = json.customers_id;
            if (json.truck_hire_challans != null) {
            //  this.truck_hire_challans = TruckHireChallans.toArray(json.truck_hire_challans);
            }
            else if (json.truck_hire_challan != null) {
              if (Array.isArray(json.truck_hire_challan)) {
              //  this.truck_hire_challans = TruckHireChallans.toArray(json.truck_hire_challan);
              } else {
               // this.truck_hire_challans = new Array(new TruckHireChallans(json.truck_hire_challan));
              }
            }
            if (json.bookings != null) {
            //   this.bookings = new Bookings(json.bookings);
            }
            if (json.trip_addresses != null) {
            //   this.trip_addresses = TripAddresses.toArray(json.trip_addresses);
            }
            if (json.consignment_notes != null) {
            //   this.consignment_notes = ConsignmentNotes.toArray(json.consignment_notes);
            }
            if (json.consignment_trips != null) {
            //   this.consignment_trips = ConsignmentTrips.toArray(json.consignment_trips);
            }
            // this.bill_to_party_id = json.bill_to_party_id;
            // this.bill_to_party_city = json.bill_to_party_city;
            this.billing_branch_cd = json.billing_branch_cd;
            this.pricing_executive_id = json.pricing_executive_id;
            this.dispatch_executive_id = json.dispatch_executive_id;
            this.tracking_executive_id = json.tracking_executive_id;
            this.arrival_executive_id = json.arrival_executive_id;
            this.pricing_executive_name = json.pricing_executive_name;
            this.dispatch_executive_name = json.dispatch_executive_name;
            this.tracking_executive_name = json.tracking_executive_name;
            this.arrival_executive_name = json.arrival_executive_name;
            this.ops_executive_id = json.ops_executive_id;
            this.ops_executive_name = json.ops_executive_name;
            this.finance_executive_id = json.finance_executive_id;
            this.finance_executive_name = json.finance_executive_name;
            this.target_hire_amt = json.target_hire_amt;
            this.target_advance_percentage = json.target_advance_percentage;
            this.target_advance_amt = json.target_advance_amt;
            // this.freight_amt = json.freight_amt;
            this.location = json.location;
            if(json.location_lat != null) {
              this.location_lat = +json.location_lat;

            }
            if(json.location_long != null) {
              this.location_long = +json.location_long;

            }
            if (json.rating) {
            //   this.rating = new DriverRatings(json.rating);
            }
            if (json.updated_at) {
              safeDate(this, json,  'updated_at');
            }
            safeDate(this, json,  'actual_arrival_dt');
            safeDate(this, json,  'actual_dispatch_dt');

            this.business_type_cd = json.business_type_cd;
            this.booking_type_cd = json.booking_type_cd;
            this.movement_type_cd = json.movement_type_cd;
            // this.trip_scan = json.trip_scan;
            // this.exim_scan_1 = json.exim_scan_1;
            // this.exim_scan_2 = json.exim_scan_2;
            // this.exim_scan_3 = json.exim_scan_3;
            this.pickup_icd_city = json.pickup_icd_city;
            this.drop_icd_city = json.drop_icd_city;
            // this.container_no = json.container_no;
            // this.seal_no  = json.seal_no;
            if(json.trucks != null) {
            //   this.trucks = Trucks.toArray(json.trucks);
            }
            if(json.trip_last_location != null) {
            //   this.trip_last_location = new TripStatusTrackings(json.trip_last_location);
            }
            if(json.trip_last_status != null) {
            //   this.trip_last_status = new TripStatusTrackings(json.trip_last_status);
            }
            if(json.is_skip_eway) {
              this.is_skip_eway = json.is_skip_eway;
            }
            this.intugine_trip_id = json.intugine_trip_id;
            this.intugine_trip_status = json.intugine_trip_status;
            this.distance_remaining = json.distance_remaining;
            this.distance_travelled = json.distance_travelled;
            this.time_remaining = json.time_remaining;
            this.freight_amt = json.freight_amt;
            this.tat = json.tat;
            this.total_distance = json.total_distance;
            this.trip_type_cd = json.trip_type_cd;
          }
    }


}
