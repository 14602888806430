<div class=" content_area_list container inne_area_list mobScroll mob-p-default ">
  <div class="row example-header">
    <div class="col-sm-8 text-center">
      <form [formGroup]="tripsSearchForm">
        <div class="row" #searchBar *ngIf="searchBarOpen">
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput #filter placeholder="Search Trips" formControlName="search">
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [placeholder]="'Trip Status'" [type]="'trips_status_cd'"
              [selected]="tripsSearchForm.value.trips_status_cd" [valid]="tripsSearchForm.get('trips_status_cd').valid"
              (onSelect)="setTripStatusCd($event)" (touched)="tripsSearchForm.get('trips_status_cd').markAsTouched()">
            </app-dropdowns>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [placeholder]="'THC Status'" [type]="'truck_hire_challan_status_cd'"
              [selected]="tripsSearchForm.value.truck_hire_challan_status_cd"
              [valid]="tripsSearchForm.get('truck_hire_challan_status_cd').valid" (onSelect)="setThcStatusCd($event)"
              (touched)="tripsSearchForm.get('truck_hire_challan_status_cd').markAsTouched()"></app-dropdowns>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [placeholder]="'Territory'" [type]="'territory_cd'"
              [selected]="tripsSearchForm.value.territory_cd" [valid]="tripsSearchForm.get('territory_cd').valid"
              (onSelect)="setterritoryCd($event)" (touched)="tripsSearchForm.get('territory_cd').markAsTouched()">
            </app-dropdowns>
            <mat-error class="mapError"
              *ngIf="!tripsSearchForm.get('territory_cd').valid && tripsSearchForm.get('territory_cd').touched">
              Territory selection is required.
            </mat-error>
          </div>
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput placeholder="Source City" formControlName="source_city">
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput placeholder="Destination City" formControlName="destination_city">
            </mat-form-field>
          </div>
          <!-- </div> -->
          <!-- <div class="row" *ngIf="searchBarOpen"> -->
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [required]=true [placeholder]="'Branch'" [type]="'branch_cd'"
              [selected]="tripsSearchForm.value.branch_cd" [valid]="tripsSearchForm.get('branch_cd').valid"
              (onSelect)="setbranchCd($event)" (touched)="tripsSearchForm.get('branch_cd').markAsTouched()">
            </app-dropdowns>
            <mat-error class="mapError"
              *ngIf="!tripsSearchForm.get('branch_cd').valid && tripsSearchForm.get('branch_cd').touched">
              Territory selection is required.
            </mat-error>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="w-100">
              <input type="text" placeholder="Executive" matInput [matAutocomplete]="autop"
                formControlName="executive_name">
              <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
                <mat-option *ngFor="let option of employeeListAbs" [value]="option.name" (click)="setExecutive(option)">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [required]=true [placeholder]="'Service Type'" [type]="'service_type_cd'"
              [selected]="tripsSearchForm.value.service_type_cd" [valid]="tripsSearchForm.get('service_type_cd').valid"
              (onSelect)="setServiceTypeCd($event)" (touched)="tripsSearchForm.get('service_type_cd').markAsTouched()">
            </app-dropdowns>
            <mat-error class="mapError"
              *ngIf="!tripsSearchForm.get('service_type_cd').valid && tripsSearchForm.get('service_type_cd').touched">
              Service Type is required.
            </mat-error>
          </div>
          <div class="col-sm-3 action-buttons  text-center">
            <span style="margin-right: 10px"><button mat-icon-button color="primary"
                (click)="submitSearch(tripsSearchForm.value)">
                <mat-icon>search</mat-icon>
              </button></span>
            <span><button mat-icon-button color="secondary" (click)="resetSearch()">
                <mat-icon>clear</mat-icon>
              </button></span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4 text-right">
      <span *ngIf="!searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_more</mat-icon>
        </button></span>
      <span *ngIf="searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_less</mat-icon>
        </button></span>
      <span *ngIf="bookings_id"><button mat-icon-button [routerLink]="['/trips/map/booking', bookings_id]">
          <mat-icon>map</mat-icon>
        </button></span>
      <span *ngIf="!bookings_id"><button mat-icon-button (click)="mapView()">
          <mat-icon>map</mat-icon>
        </button></span>

      <!-- <span *ngIf="!bookings_id">
          <a mat-icon-button color="primary" [routerLink]="['/trips/map']" routerLinkActive="active">
            <i class="material-icons">map</i></a>
      </span> -->
      <span><button mat-icon-button [routerLink]="['/trips/add']">
          <mat-icon>add</mat-icon>
        </button></span>
      <!-- <span><button mat-raised-button [routerLink]="
          ['/trips/add']">Add New</button> </span> -->
      <!-- <span><button mat-icon-button [routerLink]="['/trips/map']">
          <mat-icon>map</mat-icon>
        </button></span> -->
      <span><button mat-icon-button color="primary" (click)="download($event)">
          <mat-icon>cloud_download</mat-icon>
        </button></span>
      <span><button mat-icon-button color="primary" (click)="refresh($event)">
          <mat-icon>refresh</mat-icon>
        </button></span>
      <span><button mat-icon-button [matMenuTriggerFor]="menuTop">
          <mat-icon>more_vert</mat-icon>
        </button></span>
      <mat-menu #menuTop="matMenu">
        <button mat-menu-item (click)="getLocationForMulti()">
          <span>Get Multi Mobile Location</span>
        </button>
        <button mat-menu-item (click)="downloadSalesReport($event)">
          <span>Download Sales report</span>
        </button>
        <button mat-menu-item (click)="downloadSalesReportToday($event)">
          <span>Download Placement(Today)</span>
        </button>
        <button mat-menu-item (click)="downloadSalesReportYesterday($event)">
          <span>Download Placement(Yesterday)</span>
        </button>
        <button mat-menu-item (click)="downloadSalesReportMTD($event)">
          <span>Download Placement(MTD)</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div id="AdminlistGW" class="col-md-12 " [ngClass]="flag? 'd-Bnone': 'listBox'">
    <div class="table_list">
      <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
        <mat-table #table class="table_list" [dataSource]="dataSource" matSort multiTemplateDataRows>
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection?.hasValue() && isAllSelected()"
                [indeterminate]="selection?.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(element)  : null"
                [checked]="selection?.isSelected(element)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tripId">
            <mat-header-cell *matHeaderCellDef class="width100"> Trip Id </mat-header-cell>
            <mat-cell *matCellDef="let element" class="width100 {{getRowColor(element)}}">
              <ul>
                <li>{{element.trip_no}} / {{element.bookings?.booking_no}}</li>
                <li>{{element.bookings?.service_type_cd}}</li>
              </ul>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CustomerName">
            <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
              <ul>
                <li>{{element.bookings?.customers?.name_short || element.bookings?.customers?.name}}</li>
                <li>{{element.bookings?.booking_for_dt | date: 'dd MMM yy'}}
                  {{element.bookings?.booking_for_start | date: 'h:mm a' }} </li>
                  <li *ngIf="element.trip_type_cd == 'Short'" class="text-green">Short Haul</li>

              </ul>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="SourceCity">
            <mat-header-cell *matHeaderCellDef> Origin - Destination </mat-header-cell>

            <mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
              <ul *ngIf="element.trip_addresses?.length">
                <li *ngFor="let trip_address of element.trip_addresses;
              let first = first; let last = last; let i = index">
                  {{trip_address.city}} <span *ngIf="trip_address.actual_arrival_dt">ATA:
                    {{trip_address.actual_arrival_dt | date: 'dd MMM yy' }}</span>
                  <span *ngIf="!trip_address.actual_arrival_dt && trip_address.estimated_arrival_dt"
                    [ngClass]="{'text-warning': (isEarlierThanToday(trip_address.estimated_arrival_dt) && element.trips_status_cd != 'TRIP COMPLETE') }">ETA:
                    {{trip_address.estimated_arrival_dt | date: 'dd MMM yy' }}</span>
                  <span
                    *ngIf="!trip_address.actual_arrival_dt && !trip_address.estimated_arrival_dt && trip_address.scheduled_arrival_dt"
                    [ngClass]="{'text-warning': (isEarlierThanToday(trip_address.scheduled_arrival_dt) && element.trips_status_cd != 'TRIP COMPLETE') }">ETA:
                    {{trip_address.scheduled_arrival_dt | date: 'dd MMM yy' }}</span>
                </li>
                <li *ngIf="element.total_distance">{{element.total_distance | number: '1.0-0'}} Km,
                  {{element.getEstimatedTransitDays()}} Day</li>
              </ul>
            </mat-cell>
          </ng-container>
          <!-- <ng-container matColumnDef="destination_city">
        <mat-header-cell *matHeaderCellDef> Destination City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.destination_city}} </mat-cell>
      </ng-container> -->
          <ng-container matColumnDef="ConsignmentNo">
            <mat-header-cell *matHeaderCellDef> Consignment </mat-header-cell>
            <ng-container *matCellDef="let element">
              <mat-cell class="{{getRowColor(element)}}"
                *ngIf="element.consignment_trips && element.consignment_trips.length">
                <ul>
                  <li *ngFor="let consignment_trip of element.consignment_trips;
            let first = first; let last = last; let i = index">
                    <span *ngIf="consignment_trip.consignment_note?.consignment_no">
                      <ul>
                        <li><a [routerLink]=""
                            (click)="openModalConsignment(updateConsignment, consignment_trip.consignment_note.id, element)">{{consignment_trip.consignment_note?.consignment_no}}</a>
                        </li>
                        <li>{{consignment_trip.consignment_note.consignment_status_cd}}</li>
                        <li *ngIf="consignment_trip.consignment_note.eway_valid_upto_dt">Eway:
                          {{consignment_trip.consignment_note.eway_valid_upto_dt | date: 'dd MMM HH:mm'}}</li>
                      </ul>
                    </span>
                    <span *ngIf="!consignment_trip.consignment_note?.consignment_no">
                      <ul>
                        <li><a [routerLink]=""
                            (click)="openModalConsignment(updateConsignment, consignment_trip.consignment_note.id, element)">{{consignment_trip.consignment_note.consignment_status_cd}}</a>
                        </li>
                      </ul>
                    </span>
                  </li>
                </ul>
              </mat-cell>
              <mat-cell *ngIf="!element.consignment_trips || element.consignment_trips.length == 0"> Attach </mat-cell>
            </ng-container>
          </ng-container>
          <!-- <ng-container matColumnDef="CustomerName">
        <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.bookings?.customers?.name}} </mat-cell>
      </ng-container> -->
          <!-- <ng-container matColumnDef="BookingDate">
        <mat-header-cell *matHeaderCellDef> Booking For Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.bookings?.booking_for_dt | date: 'short'}} </mat-cell>
      </ng-container> -->
          <ng-container matColumnDef="trips_status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element" class="{{getRowColor(element)}}">
              <ul>
                <li>{{element.trips_status_cd}}<sup *ngIf="element.trip_last_remark"
                    class="sup-astrick text-warning">**</sup></li>
                <li>{{element.updated_at | date: 'dd MMM yy h:mm a' }}</li>
                <li><a [routerLink]="['/trips/map/trip',element.id]" target="_blank">{{element.location}}</a></li>

                <li> {{element.trip_last_location?.location_provider}}
                  {{element.trip_last_location?.status_at | date: 'dd MMM h:mm a'}}</li>
              </ul>

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef> Location </mat-header-cell>
            <mat-cell *matCellDef="let element;" class="{{getRowColor(element)}}"> {{element.location}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="emp">
            <mat-header-cell *matHeaderCellDef> Driver / LE / Tracking </mat-header-cell>
            <mat-cell *matCellDef="let element;" class="{{getRowColor(element)}}">
              <ul>
                <li *ngIf="element.truck_hire_challans && element.truck_hire_challans[0]?.truck">
                  {{getDriverName(element)}}</li>
                <li *ngIf="element.truck_hire_challans && element.truck_hire_challans[0]?.truck">
                  {{getDriverNumber(element)}} </li>
                <li *ngIf="element.truck_hire_challans && element.truck_hire_challans[0]?.truck">
                  {{getDriverMobileProvider(element)}} {{getDriverConsent(element)}}</li>
                <!-- <li>
                {{element.pricing_executive_name}}
            </li> -->
                <li>
                  {{element.dispatch_executive_name}}
                </li>
                <li>
                  {{element.tracking_executive_name}}
                </li>
              </ul>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="TruckRegistrationNo">
            <mat-header-cell *matHeaderCellDef> Truck Reg. no. </mat-header-cell>
            <mat-cell *matCellDef="let element;" class="{{getRowColor(element)}}">
              <ul *ngIf="element.truck_hire_challans && element.truck_hire_challans[0]">
                <li *ngIf="element.truck_hire_challans[0].truck">{{getTruckRegistrationNo(element)}}</li>
                <li *ngIf="element.truck_hire_challans[0].truck">
                  {{getTruckTypeValue(element.truck_hire_challans[0].truck.truck_type_cd)}}</li>

                <li *ngIf="element.truck_hire_challans[0]">
                  {{element.truck_hire_challans[0].truck_hire_challan_status_cd}}
                </li>
                <li>
                  {{element.truck_hire_challans[0].broker?.partner_company_name}}
                  <!-- {{element.truck_hire_challans[0].broker_contact?.user.name}}
                {{element.truck_hire_challans[0].broker_contact?.user.mobile_no}} -->
                </li>
                <li *ngIf="!element.truck_hire_challans[0].broker?.pan_no">
                  <b>'NO PAN'</b>
                </li>
                <li *ngIf="!element.truck_hire_challans[0].broker?.partner_banks[0]">
                  <b>'NO BANK'</b>
                </li>


              </ul>
              <ul *ngIf="!element.truck_hire_challans || !element.truck_hire_challans[0]">
                <li>{{getTruckTypeValue(element.truck_type_cd)}}</li>
                <!-- <li>{{element.truck_type_cd}}</li> -->
              </ul>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="HireTruck">
            <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp;</mat-header-cell>
            <mat-cell *matCellDef="let element; " class="width60-Center {{getRowColor(element)}}">
              <button mat-icon-button [matMenuTriggerFor]="rootMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #rootMenu="matMenu">
                <button *ngIf="needConfirmTripLink(element)" mat-menu-item
                  (click)="openModalConfirmTrip(confirmTrip, element)">
                  <span>Confirm Trip</span>
                </button>
                <button *ngIf="needAssignTruckLink(element)" mat-menu-item
                  (click)="openModalAssignTruck(assignTruck, element)">
                  <span>Assign Truck</span>
                </button>
                <button *ngIf="needUploadTruckLink(element)" mat-menu-item
                  (click)="openModalTruckDocument(truckDocuments, element)">
                  <span>Upload Truck Documents</span>
                </button>
                <button *ngIf="needConsentSMS(element)" mat-menu-item (click)="getLocationConsent(element)">
                  <span>Send Consent Request</span>
                </button>
                <button *ngIf="needCheckConsent(element)" mat-menu-item (click)="getLocationConsent(element)">
                  <span>Check Consent</span>
                </button>
                <button mat-menu-item (click)="updateToNextStatus(element)"
                  *ngIf="getNextStatus(element) && element.trips_status_cd != 'IN TRANSIT'">
                  <span>Mark {{getNextStatus(element)}}</span>
                </button>
                <button mat-menu-item (click)="updateToInTransit(element)"
                  *ngIf="element.trip_type_cd == 'Short' && element.trips_status_cd == 'VEHICLE ASSIGNED'">
                  <span>Mark In Transit (SH)</span>
                </button>
                <button mat-menu-item (click)="openModalUpdateLocation(updateLocation, element, 'LOCATION')">
                  <span>Update Location</span>
                </button>
                <button mat-menu-item (click)="openModalUpdateLocation(addRemarks, element, 'REMARKS')">
                  <span>Update Progress</span>
                </button>
                <button *ngIf="needStartTripIntugine(element)" mat-menu-item (click)="startTripIntugine(element)">
                  <span>Start Intugine Trip</span>
                </button>
                <button *ngIf="needCheckLocation(element)" mat-menu-item (click)="intugineFetchLocation(element)">
                  <span>Get Mobile Location</span>
                </button>
                <button *ngIf="needEndTripIntugine(element)" mat-menu-item (click)="endTripIntugine(element)">
                  <span>End Intugine Trip</span>
                </button>

                <button mat-menu-item (click)="updateToNextStatus(element)"
                  *ngIf="getNextStatus(element) && element.trips_status_cd == 'IN TRANSIT'">
                  <span>Mark {{getNextStatus(element)}}</span>
                </button>
                <!-- <button mat-menu-item [routerLink]="['/consignmentNotes/add/trip', element.id]"> -->
                <button mat-menu-item (click)="openModalConsignmentAddToTrip(updateConsignment, element)">
                  <span>Add New Consignment</span>
                </button>
                <button *ngIf="needSendLr(element)" mat-menu-item
                  (click)="downloadTripConsignmentPreviewAsPDF(element)">
                  <span>Preview online LR</span>
                </button>
                <button *ngIf="needSendLr(element)" mat-menu-item
                  (click)="openModalSendConsignment(sendConsignment, element)">
                  <span>Send online LR</span>
                </button>
                <button *ngIf="needSendLr(element)" mat-menu-item (click)="downloadTripConsignmentAsPDF(element)">
                  <span>Download online LR</span>
                </button>
                <button *ngIf="needSendLr(element)" mat-menu-item (click)="downloadTripKNConsignmentAsPDF(element)">
                  <span>Download online KN LR</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="subMenu">More..</button>
              </mat-menu>
              <mat-menu #subMenu="matMenu">
                <button mat-menu-item [routerLink]="['/trips/map/trip',element.id]">
                  <span>View on map</span>
                </button>
                <button mat-menu-item [routerLink]="['/tripStatusTrackings/list/trip',element.id]">Track /
                  History</button>
                <!-- <button mat-menu-item [routerLink]="['/truckHireChallans/add/trip',element.id]">
              <span>Hire Truck</span>
            </button> -->
                <button mat-menu-item [routerLink]="['/triplite/cnotelist/trip',element.id]">
                  <span>Add/Update CNote</span>
                </button>
                <button *ngIf="needAssignTruckLink(element)" mat-menu-item
                  [routerLink]="['/nearby_drivers-partners/drivers/Trip',element.id]">
                  <span>Request Driver</span>
                </button>
                <button *ngIf="needAssignTruckLink(element)" mat-menu-item
                  [routerLink]="['/nearby_drivers-partners/partners/Trip',element.id]">
                  <span>Request Partner</span>
                </button>
                <button mat-menu-item (click)="openModalChangeExecutive(changeExecutives, element, false)">
                  <span>Reassign Executives</span>
                </button>
                <button mat-menu-item (click)="openModalUpdateStatus(updateStatus, element, 'STATUS')">
                  <span>Update Status To..</span>
                </button>
                <button *ngIf="!needAssignTruckLink(element)" mat-menu-item
                  (click)="openModalAssignTruck(assignTruck, element)">
                  <span>Assign Truck</span>
                </button>
                <button *ngIf="!needUploadTruckLink(element)" mat-menu-item
                  (click)="openModalTruckDocument(truckDocuments, element)">
                  <span>Upload Truck Documents</span>
                </button>
                <button mat-menu-item (click)="openModalGallery(gallery, element)">
                  <span>Gallery</span>
                </button>
                <button mat-menu-item (click)="openModalTruckDocumentOnly(truckDocumentsOnly, element)">
                  <span>Do not use UTD</span>
                </button>

                <button *ngIf="needEditButton(element)" mat-menu-item [routerLink]="['/trips/edit',element.id]"
                  routerLinkActive="active">
                  <span>Edit</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                *ngIf="element.trip_last_remark">
                <table width="100%">
                  <tr>
                    <td align="left">
                      <p class="expanded_detail_remark text-warning">{{element.trip_last_remark.status_at | date: 'dd
                        MMM yy hh:mm a'}} {{element.trip_last_remark?.updated_by_user?.name}} says: <span
                          class="last_remark">{{element.trip_last_remark.remarks}}</span> </p>
                      <!-- <p class="expanded_detail_remark"><span>At {{element.trip_last_remark.status_at | date: 'dd MMM hh:mm'}}</span> </p> -->
                    </td>
                  </tr>

                </table>
              </div>
            </td>
          </ng-container>
          <!-- edit Column -->
          <!-- <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef class="width60-Center"> </mat-header-cell>
        <mat-cell *matCellDef="let element" class="width60-Center">
          <a mat-icon-button color="primary" [routerLink]="['/trips/edit',element.id]" routerLinkActive="active">
            <i class="ti-pencil-alt"></i>
          </a>
        </mat-cell>
      </ng-container> -->
          <!-- <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp;</mat-header-cell>
        <mat-cell class="width100" *matCellDef="let element">
          <button mat-raised-button color="primary" (click)="openModalForBid1(bidTemplate1, element)">
            Create Bid
          </button>
        </mat-cell>
      </ng-container> -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = row"></mat-row>

          <mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></mat-row>

        </mat-table>
      </div>
    </div>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page"
    (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
<ng-template #listTrips>
  <!-- <div class = "row"> -->
  <!-- <div class="col"> -->
  <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
    <mat-table [dataSource]="dataSource " cellpadding="10 " class="table_list">
      <!-- <ng-container matColumnDef="cbox">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox></mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox></mat-checkbox>
                            </td>
                        </ng-container> -->
      <ng-container matColumnDef="cbox">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            <!-- [aria-label]="Select All" -->
          </mat-checkbox>
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation();false;" (change)="selectCboxOnChange($event, row)"
            [checked]="selection.isSelected(row)">
            <!-- [aria-label]="row.trip_no" -->
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <ul>
            <li>{{element.bookings?.customers?.name_short || element.bookings?.customers?.name}}</li>
            <li>{{element.trip_no}}</li>
          </ul>

        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="origin">
        <mat-header-cell *matHeaderCellDef> Loading </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <ul>
            <li>{{getStringBeforeComma(element.source_city)}}</li>
            <li>{{element.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{element.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="truck_type">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Truck </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <ul>
            <li>{{getTruckType(element)}}</li>
            <li>{{getTruckRegistrationNo(element)}}</li>
          </ul>
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Task </th>
                            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                        </ng-container> -->
      <ng-container matColumnDef="action">
        <mat-header-cell mat-header-cell class="td-task-button" *matHeaderCellDef> Task </mat-header-cell>
        <mat-cell mat-cell class="td-task-button" *matCellDef="let element; " (click)="$event.stopPropagation()">
          <!-- <div class="mat-icon-button" style="text-align: right; vertical-align: -webkit-baseline-middle;">
                                  <button *ngIf="getNextActionButtonText(element)"  style="background-color: #fff;border:none;padding: 0,0,0,0;" (click)="getNextActionButtonClick(element)">
                                      <span class="Rectangle-13"><span class="Mark-Collected">{{getNextActionButtonText(element)}}</span></span>
                                    </button>
                                    <button style="background-color: #fff;border:none;padding: 0,0,0,0;;text-align: right; vertical-align: -webkit-baseline-middle;" [matMenuTriggerFor]="rootMenu" >
                                        <mat-icon >more_vert</mat-icon>
                                    </button>
                              </div> -->
          <!-- <div class="mat-icon-button" style="text-align: right; vertical-align: -webkit-baseline-middle;"> -->
          <button mat-raised-button color="primary" *ngIf="getNextActionButtonText(element)" class="button-task"
            (click)="onClickAction(element); ">
            {{getNextActionButtonText(element)}}
            <!-- {{getNextActionButtonClick(element)}} -->
            <!-- <mat-icon [matMenuTriggerFor]="rootMenu">more_vert</mat-icon> -->
          </button>
          <!-- <button style="background-color: #fff;border:none;padding: 0,0,0,0;;text-align: right; vertical-align: -webkit-baseline-middle;" [matMenuTriggerFor]="rootMenu" >
                                        <mat-icon >more_vert</mat-icon>
                                    </button> -->
          <!-- </div> -->


          <!-- <div [innerHtml]="getNextActionButton(element)"></div> -->

          <!-- {{getNextActionButton(element)}} -->
          <!-- <button mat-menu-item (click)="getNextActionButtonClick(element)">
                                  <span>{{getNextActionButtonText(element)}}</span>
                                </button> -->
          <mat-menu #rootMenu="matMenu" style="display:none;">
            <button *ngIf="needAssignTruckLink(element)" mat-menu-item
              (click)="openModalAssignTruck(assignTruck, element)">
              <span>Assign Truck</span>
            </button>
            <button *ngIf="needUploadTruckLink(element)" mat-menu-item
              (click)="openModalTruckDocument(truckDocuments, element)">
              <span>Upload Truck Documents</span>
            </button>
            <button *ngIf="needCosingnmentUpdate(element)" mat-menu-item (click)="goToConsignment(element)">
              <span>Update LR</span>
            </button>
            <button *ngIf="needConsentSMS(element)" mat-menu-item (click)="getLocationConsent(element)">
              <span>Send Consent Request</span>
            </button>
            <button *ngIf="needCheckConsent(element)" mat-menu-item (click)="getLocationConsent(element)">
              <span>Check Consent</span>
            </button>
            <!-- <button *ngIf="needCheckLocation(element)" mat-menu-item (click)="getTelenityLocation(element)">
            <span>Get Mobile Location</span>
          </button> -->
            <button mat-menu-item (click)="updateToNextStatus(element)" *ngIf="getNextStatus(element)">
              <span>Mark {{getNextStatus(element)}}</span>
            </button>
            <button *ngIf="needStartTripIntugine(element)" mat-menu-item (click)="startTripIntugine(element)">
              <span>Start Intugine Trip</span>
            </button>
            <button *ngIf="needCheckLocation(element)" mat-menu-item (click)="intugineFetchLocation(element)">
              <span>Get Mobile Location</span>
            </button>
            <button *ngIf="needEndTripIntugine(element)" mat-menu-item (click)="endTripIntugine(element)">
              <span>End Intugine Trip</span>
            </button>

            <button mat-menu-item (click)="openModalUpdateLocation(updateLocation, element, 'LOCATION')">
              <span>Update Location</span>
            </button>
            <button mat-menu-item (click)="openModalUpdateLocation(addRemarks, element, 'REMARKS')">
              <span>Update Progress</span>
            </button>
            <button mat-menu-item [routerLink]="['/consignmentNotes/add/trip', element.id]">
              <span>Add New Consignment</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="subMenu">More..</button>
          </mat-menu>
          <mat-menu #subMenu="matMenu">
            <!-- <button *ngIf="!needAssignTruckLink(element)" mat-menu-item (click)="openModalAssignTruck(assignTruck, element)">
                                    <span>Assign Truck</span>
                                </button>
                                <button *ngIf="!needUploadTruckLink(element)" mat-menu-item (click)="openModalTruckDocument(truckDocuments, element)">
                                    <span>Upload Truck Documents</span>
                                </button>
                                <button *ngIf="!needAdvanceRequestLink(element)" mat-menu-item (click)="openModalRequestAdvance(requestAdvance, element)">
                                    <span>Request Advance</span>
                                </button>
                                <button *ngIf="!needAdvancePaidLink(element)" mat-menu-item (click)="openModalAdvancePaid(advancePaid, element)">
                                    <span>Mark Advance Paid</span>
                                </button> -->
            <!-- <button mat-menu-item (click)="updateToNextStatus(element)">
                                  <span>Mark {{getNextStatus(element)}}</span>
                                </button> -->
            <button mat-menu-item [routerLink]="['/trips/map/trip',element.id]">
              <span>View on map</span>
            </button>
            <button mat-menu-item [routerLink]="['/tripStatusTrackings/list/trip',element.id]">Track /
              History</button>
            <!-- <button mat-menu-item [routerLink]="['/truckHireChallans/add/trip',element.id]">
                                  <span>Hire Truck</span>
                                </button> -->
            <button mat-menu-item [routerLink]="['/triplite/cnotelist/trip',element.id]">
              <span>Add/Update CNote</span>
            </button>
            <button *ngIf="needAssignTruckLink(element)" mat-menu-item
              [routerLink]="['/nearby_drivers-partners/drivers/Trip',element.id]">
              <span>Request Driver</span>
            </button>
            <button *ngIf="needAssignTruckLink(element)" mat-menu-item
              [routerLink]="['/nearby_drivers-partners/partners/Trip',element.id]">
              <span>Request Partner</span>
            </button>
            <button mat-menu-item (click)="openModalChangeExecutive(changeExecutives, element, false)">
              <span>Reassign Executives</span>
            </button>
            <button mat-menu-item (click)="openModalUpdateStatus(updateStatus, element, 'STATUS')">
              <span>Update Status To..</span>
            </button>
            <button *ngIf="!needAssignTruckLink(element)" mat-menu-item
              (click)="openModalAssignTruck(assignTruck, element)">
              <span>Assign Truck</span>
            </button>
            <button *ngIf="!needUploadTruckLink(element)" mat-menu-item
              (click)="openModalTruckDocument(truckDocuments, element)">
              <span>Upload Truck Documents</span>
            </button>
            <button *ngIf="!needCosingnmentUpdate(element)" mat-menu-item (click)="goToConsignment(element)">
              <span>Update LR</span>
            </button>
            <!-- <button *ngIf="!needAdvanceRequestLink(element)" mat-menu-item
            (click)="openModalRequestAdvance(requestAdvance, element)">
            <span>Request Advance</span>
          </button> -->
            <!-- <button *ngIf="!needApproveExcessHireLink(element)" mat-menu-item (click)="openModalApproveExcessHire(approveExcessHire, element)">
                                  <span>Approve Excess Hire</span>
                                </button>-->
            <!-- <button *ngIf="!needApproveExcessAdvanceLink(element)" mat-menu-item (click)="openModalApproveExcessAdvance(approveExcessAdvance, element)">
                                  <span>Approve Excess Hire</span>
                                </button>-->
            <!-- <button *ngIf="!needBalanceRequestLink(element)" mat-menu-item
            (click)="openModalRequestBalance(requestBalance, element)">
            <span>Request Balance</span>
          </button> -->
            <!-- <button *ngIf="!needBalancePaidLink(element)" mat-menu-item (click)="openModalBalancePaid(balancePaid, element)">
                                  <span>Mark Balance Paid</span>
                                </button> -->
            <button mat-menu-item (click)="openModalGallery(gallery, element)">
              <span>Gallery</span>
            </button>
            <button mat-menu-item (click)="openModalTruckDocumentOnly(truckDocumentsOnly, element)">
              <span>Do not use UTD</span>
            </button>

            <button *ngIf="needEditButton(element)" mat-menu-item [routerLink]="['/trips/edit',element.id]"
              routerLinkActive="active">
              <span>Edit</span>
            </button>
            <!-- <button mat-menu-item (click)="openModalRating(editDriverRatings, element)">
                                  <span>Rate Trip</span>
                                </button> -->
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;  " (click)="rowOnClick(row)"
        [ngClass]="{hovered: row.hovered, mat_row_highlighted: selection.isSelected(row)}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page"
    (page)="getResponseByPage($event)">
  </mat-paginator>
  <!-- </div> -->
  <!-- </div> -->
</ng-template>
<ng-template #sendConsignment>
  <form [formGroup]="sendConsignmentFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Send LR for Print'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <!-- <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:consignmentNotes,aTripsData:trips}"></ng-container> -->
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Send LR </h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Name" formControlName="to_name">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_name').valid && !sendConsignmentFormGroup.get('to_name').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Email" formControlName="to_email">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_email').valid && !sendConsignmentFormGroup.get('to_email').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="row buttonset">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary"
              (click)="submitSendLr(sendConsignmentFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>
<ng-template #modalHeader let-title="title">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #rootMenu="matMenu">
            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tripSummary let-trip="tripData">
  <!-- <h2>Trip Details</h2> -->
  <div class="fieldset mb-4">
    <h3 class="gwheadline "> Trip Details</h3>
    <div class="conatiner_flow">
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{trip.bookings?.customers?.name_short || trip.bookings?.customers?.name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Truck Type</div>
          {{getTruckTypeValue(trip.truck_type_cd)}}
        </div>
        <!-- <div class="col">
              <div class="labelBox_e3">Transit Hours</div> 12 Hours

            </div> -->
        <!-- <div class="col pb-2">
              <div class="labelBox_e3">Company Details</div> TATA SKY PVT LTD
            </div> -->
        <div class="col">
          <div class="labelBox_e3"> Origin</div>
          {{trip.source_city}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> Destination</div>
          {{trip.destination_city}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> ETA</div>
          <ul>
            <li>{{trip.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{trip.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #confirmTrip>
  <form [formGroup]="tripsChangeExecutiveForm">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Confirm Trip'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Executive Details</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" (change)="dispatchChanged($event)" placeholder="Dispatch(LR) Executive"
                  matInput [matAutocomplete]="autod" formControlName="dispatch_executive_name">
                <mat-autocomplete #autod="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setDispatchEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('dispatch_executive_id').valid && tripsChangeExecutiveForm.get('dispatch_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('dispatch_executive_id').errors.required"> LR Executive is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" (change)="trackingChanged($event)" placeholder="Tracking Executive" matInput
                  [matAutocomplete]="autot" formControlName="tracking_executive_name">
                <mat-autocomplete #autot="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setTrackingEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('tracking_executive_id').valid && tripsChangeExecutiveForm.get('tracking_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('tracking_executive_id').errors.required"> Tracking
                    Executive is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" (change)="pricingChanged($event)" placeholder="Pricing Executive" matInput
                  [matAutocomplete]="autop" formControlName="pricing_executive_name">
                <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setPricingEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('pricing_executive_id').valid && tripsChangeExecutiveForm.get('pricing_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('pricing_executive_id').errors.required"> Traffic Executive
                    is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="text" (change)="arrivalChanged($event)" placeholder="Arrival(POD) Executive"
                  matInput [matAutocomplete]="autoa" formControlName="arrival_executive_name">
                <mat-autocomplete #autoa="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setArrivalEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('arrival_executive_id').valid && tripsChangeExecutiveForm.get('arrival_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('arrival_executive_id').errors.required"> Arrival/POD
                    Executive
                    is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTripConfirm()">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #addRemarks>
  <form [formGroup]="consignmentTripTrackingsFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Add Progress'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <div class="row">
            <div [ngClass]="(trips.trip_last_remark) ?'col-sm-8 pl-0':'col-sm-12 pl-0'">
              <h3 class="gwheadline">Remarks</h3>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <input required type="text" matInput placeholder="Provide your remarks here"
                      formControlName="remarks">
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-slide-toggle matInput formControlName="is_visible_to_customer">Is visible to Customer
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="trips.trip_last_remark">
              <!-- style="text-align: right;" -->
              <h3 class="gwheadline">Remark Logs</h3>
              <ul class="fix-location-log">
                <!-- <li class="fix-location-line" *ngFor="let item of trips.trip_locations">
                <span>{{item.status_at | date: 'dd MMM yy h:mm a'}}</span> &nbsp;
                <span style="display: inline;">{{item.location}}</span>
              </li> -->
                <li class="fix-location-line">
                  <span>{{trips.trip_last_remark.status_at | date: 'dd MMM yy h:mm a'}}</span> &nbsp;
                  <span style="display: inline;">{{trips.trip_last_remark.remarks}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTripRemark()">Add Progress</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #changeExecutives>
  <form [formGroup]="tripsChangeExecutiveForm">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Re Assign Execs'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Assigned Executives</h3>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input required type="text" (change)="dispatchChanged($event)" placeholder="Dispatch(LR) Executive"
                  matInput [matAutocomplete]="autod" formControlName="dispatch_executive_name">
                <mat-autocomplete #autod="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setDispatchEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('dispatch_executive_id').valid && tripsChangeExecutiveForm.get('dispatch_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('dispatch_executive_id').errors.required"> LR Executive is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input required type="text" (change)="trackingChanged($event)" placeholder="Tracking Executive" matInput
                  [matAutocomplete]="autot" formControlName="tracking_executive_name">
                <mat-autocomplete #autot="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setTrackingEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('tracking_executive_id').valid && tripsChangeExecutiveForm.get('tracking_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('tracking_executive_id').errors.required"> Tracking
                    Executive is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input required type="text" (change)="pricingChanged($event)" placeholder="Pricing Executive" matInput
                  [matAutocomplete]="autop" formControlName="pricing_executive_name">
                <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setPricingEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('pricing_executive_id').valid && tripsChangeExecutiveForm.get('pricing_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('pricing_executive_id').errors.required"> Traffic Executive
                    is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input required type="text" (change)="arrivalChanged($event)" placeholder="Arrival(POD) Executive"
                  matInput [matAutocomplete]="autoa" formControlName="arrival_executive_name">
                <mat-autocomplete #autoa="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of employeeListAbs" [value]="option.name"
                    (click)="setArrivalEx(option)">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!tripsChangeExecutiveForm.get('arrival_executive_id').valid && tripsChangeExecutiveForm.get('arrival_executive_name').touched">
                  <span *ngIf="tripsChangeExecutiveForm.get('arrival_executive_id').errors.required"> Arrival/POD
                    Executive
                    is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTripChangeExecutives()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #updateLocation>
  <form [formGroup]="consignmentTripTrackingsFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update Location'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Location</h3>
          <div class="row">
            <div class="col-sm-8">
              <app-map-req [addressType]="'(regions)'" [placeholder]="'Current Location'"
                [value]="consignmentTripTrackingsFormGroup.value.last_location" (onSelect)="selectLocation($event)">
              </app-map-req>
              <mat-error class="mapError"
                *ngIf="!consignmentTripTrackingsFormGroup.get('last_location').valid && consignmentTripTrackingsFormGroup.get('last_location').touched">
                Location is required.
              </mat-error>
              <input type="hidden" value="" formControlName="last_location_lat">
              <input type="hidden" value="" formControlName="last_location_long">
            </div>
            <div class="col-sm-4">
              <mat-slide-toggle formControlName="is_visible_to_customer">Is visible to Customer</mat-slide-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="w-100">
                <input type="text" matInput placeholder="Provide your remarks here" formControlName="remarks">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary" (click)="submitTripLocation()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<!-- <ng-template #updateConsignment>
  <app-consignment-edit #updateConsignmentComponent (onComplete)="doThing()" [consignment_notes_id]="consignment_notes_id">
  </app-consignment-edit>
</ng-template>
<ng-template #updateConsignmentTrip>
  <app-consignment-edit #updateConsignmentComponent (onComplete)="doThing()" [trips]="trips">
  </app-consignment-edit>
</ng-template> -->

<ng-template #updateConsignment>
  <app-consignment-edit #updateConsignmentComponent
  (setConsignmentNotes)="setConsignmentNotes($event)"
  [isExternalCall] = true
  (onComplete)="receivedOnComplete($event)" [consignment_notes_id]="consignment_notes_id">
    <!-- [modalRef]="modalRef" -->
    <!-- [consignment_notes_id]="cNote.id" [consignmentNotes]="cNote"
    [modalRef]="modalRef"> -->
  </app-consignment-edit>
</ng-template>
<ng-template #updateConsignmentOld>
  <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update Consignment'}"></ng-container>
  <div class="modal-body ">
    <div class="details_list_item">
      <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
      <div class="fieldset mb-4">
        <ng-container *ngTemplateOutlet="cNoteDetails"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cNoteDetailsOld>
  <div clas="row">
    <div class="col">
      <form [formGroup]="consignmentNotesFormGroup">
        <h3 class="gwheadline">Consignment Details cNoteDetails3</h3>
        <!-- <mat-horizontal-stepper [linear]="isLinear" #companyDetails>
              <mat-step [stepControl]="companyDetails"> -->
        <!-- <ng-template matStepLabel>Step 1</ng-template> -->
        <div class="row">
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase required type="text" matInput placeholder="LR Number"
                formControlName="consignment_no">
              <!-- <app-mat-error  [control]="consignmentNotesFormGroup.get('consignment_no')" displayName="LR2 No"></app-mat-error> -->

              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('consignment_no').valid && consignmentNotesFormGroup.get('consignment_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('consignment_no').errors.required">Consignment No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase type="text" matInput placeholder="Container No" formControlName="container_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('container_no').valid && consignmentNotesFormGroup.get('container_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('container_no').errors.required">Container No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="text" appUppercase matInput placeholder="Seal No" formControlName="seal_no">
              <mat-error
                *ngIf="!consignmentNotesFormGroup.get('seal_no').valid && consignmentNotesFormGroup.get('seal_no').touched">
                <span *ngIf="consignmentNotesFormGroup.get('seal_no').errors.required">Seal No is
                  required</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="consignment_items" [@visibilityChanged]="visiblityState">
          <ng-template *ngTemplateOutlet="itemDetails2">
          </ng-template>
        </div>
        <div class="table-responsive" *ngIf="consignmentItemList?.length >0">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th>Invoice / Eway</th>
                <th>Invoice Dt / Eway Exp Dt</th>
                <th>Item Description</th>
                <th>Units</th>
                <th>Weight</th>
                <!-- <th>Charged Weight</th> -->

                <!-- <th>Volume</th>							 -->
                <th></th>
              </tr>
              <tr *ngFor="let item of consignmentItemList">
                <td>{{item.invoice_no}} {{ item.eway_bill_no}}</td>
                <td>{{item.invoice_dt | date: 'dd MMM yy'}} / {{item.eway_valid_upto_dt || cNote.eway_valid_upto_dt | date:
                  'dd MMM yy H:mm'}} </td>

                <td>{{item.description}}</td>
                <td>{{item.quantity}} {{item.unit_cd}}</td>
                <td>{{item.actual_wt}}</td>
                <!-- <td>{{item.controls.charged_wt.value}}</td> -->
                <td>
                  <button mat-mini-fab color="primary" (click)="openModalEditItem(addConsignment,item)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <!-- <button mat-mini-fab color="warn">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mTop15 text-right gap-btn">
            <button class="mLft5" mat-raised-button color="secondary" (click)="addMoreInvoices()">Add More
              Invoices</button>
            <button class="mLft5" mat-raised-button color="primary" (click)="addMoreItems()">Add More Items</button>
          </div>
        </div>
        <div class="row m-t-10">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Attachments</label>
            <div class="row">
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="cNote?.invoice_scan"><a href="{{imageUrl}}{{cNote?.invoice_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload required btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Invoice</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadInvoice($event, cNote?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('invoice_scan').valid && consignmentNotesFormGroup.get('invoice_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('invoice_scan').errors.required">Please attach invoice
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="cNote?.eway_bill_scan"><a href="{{imageUrl}}{{cNote?.eway_bill_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload required btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload eWay Bill</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEway($event, cNote?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('eway_bill_scan').valid && consignmentNotesFormGroup.get('eway_bill_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('eway_bill_scan').errors.required">Please attach eWay
                    Bill
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="cNote?.consignment_scan"><a [href]="imageUrl +cNote?.consignment_scan" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload  btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload LR</span><input type="file" style="display: none;"
                    name="file[]" (change)="uploadConsignmentNote($event, cNote?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('consignment_scan').valid && consignmentNotesFormGroup.get('consignment_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('consignment_scan').errors.required">Please attach
                    consignment
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach">
                <label *ngIf="cNote?.truck_scan"><a href="{{imageUrl}}{{cNote?.truck_scan}}" target="_blank"><i
                      class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Truck/Trailer/Driver Photo</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadTruck($event, cNote?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('truck_scan').valid && consignmentNotesFormGroup.get('truck_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('truck_scan').errors.required">Please attach trailer
                    photo
                    scan</span>
                </mat-error>
              </div>
              <div class="col-xs-4 col-sm-4 btn-attach" *ngIf="bookings?.business_type_cd == 'EXIM'">
                <!-- <label class="fileTitle">Empty Letter </label> -->
                <label *ngIf="cNote?.empty_letter_scan"><a href="{{imageUrl}}{{cNote?.empty_letter_scan}}"
                    target="_blank"><i class="material-icons">attachment</i></a></label>
                <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                  <span><img src="assets/img/Landing_01.png">Upload Empty Letter</span><input type="file"
                    style="display: none;" name="file[]" (change)="uploadEmptyLetter($event, cNote?.id)">
                </label>
                <mat-error
                  *ngIf="!consignmentNotesFormGroup.get('empty_letter_scan').valid && consignmentNotesFormGroup.get('empty_letter_scan').touched">
                  <span *ngIf="consignmentNotesFormGroup.get('empty_letter_scan').errors.required">Please attach
                    Empty letter
                    scan</span>
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Insurance</label>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="Insurance By" formControlName="consignment_insured_by">
                    <mat-option value="NOT INSURED">NOT INSURED</mat-option>
                    <mat-option value="CONSIGNOR">CONSIGNOR</mat-option>
                    <mat-option value="CONSIGNEE">CONSIGNEE</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_insured_by').valid && consignmentNotesFormGroup.get('consignment_insured_by').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_insured_by').errors.required">Insured by
                      is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <mat-select placeholder="At Risk of" formControlName="consignment_risk">
                    <mat-option value=""></mat-option>
                    <mat-option value="FLEET OWNER">OWNER</mat-option>
                    <mat-option value="CARRIER">CARRIER</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('consignment_risk').valid && consignmentNotesFormGroup.get('consignment_risk').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('consignment_risk').errors.required">At Risk Of is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Co"
                    formControlName="insurance_provider">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_provider').valid && consignmentNotesFormGroup.get('insurance_provider').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_provider').errors.required">Insurance
                      provider
                      name is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Policy No."
                    formControlName="insurance_poilicy_no">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_poilicy_no').valid && consignmentNotesFormGroup.get('insurance_poilicy_no').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_poilicy_no').errors.required">Insurance
                      policy
                      number is required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input matInput [matDatepicker]="picker2" placeholder="Insurance Date" formControlName="insurance_dt">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('insurance_dt').valid && consignmentNotesFormGroup.get('insurance_dt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('insurance_dt').errors.required">Insurance date is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Insurance Validity"
                    formControlName="insurance_validity">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <label class="fields_t_b pt-3 color_blue"> Freight Details</label>
            <div class="row">

              <div class="col" *ngIf="userIsAdmin()">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Freight Charges" formControlName="freight_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('freight_amt').valid && consignmentNotesFormGroup.get('freight_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('freight_amt').errors.required">Freight is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Loading Charges" formControlName="loading_charges">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('loading_charges').valid && consignmentNotesFormGroup.get('loading_charges').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('loading_charges').errors.required">Loading amount is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="number" matInput placeholder="Misc Charges" formControlName="misc_charges_amt">
                  <mat-error
                    *ngIf="!consignmentNotesFormGroup.get('misc_charges_amt').valid && consignmentNotesFormGroup.get('misc_charges_amt').touched">
                    <span *ngIf="consignmentNotesFormGroup.get('misc_charges_amt').errors.required">Misc charge is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                    <mat-option *ngFor="let item of getConsignmentStatuses()" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="nothingabsurd">
                  <input type="text" appUppercase matInput placeholder="Remarks" formControlName="remarks">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mTop15 text-right gap-btn">
            <!-- <button mat-raised-button color="secondary"
              (click)="openModalSendConsignment(sendConsignment, cNote)" routerLinkActive="active">Send
              LR</button> -->
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value == 'INCOMPLETE'  ">
              <button mat-raised-button color="secondary" (click)="submitConsignment()"
                routerLinkActive="active">Save</button>
              <button mat-raised-button color="primary" (click)="submitConsignmentBooked()"
                routerLinkActive="active">Save & Mark Booked</button>
            </ng-container>
            <ng-container *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value != 'INCOMPLETE'  ">
              <button mat-raised-button color="primary" (click)="submitConsignment()"
                routerLinkActive="active">Save</button>

            </ng-container>

            <!-- <button mat-raised-button color="primary" (click)="submitLoaded()" routerLinkActive="active">Save &
          Mark Picked Up</button> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #tripSummaryCNote let-cNote="cNoteData" let-thc="thcData">
  <!-- <h2>Trip Details</h2> -->
  <div class="fieldset mb-4">
    <h3 class="gwheadline "> Trip Details</h3>
    <div class="conatiner_flow">
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{cNote.bookings?.customers?.name_short || cNote.bookings?.customers?.name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> LR# </div>
          <ng-container>
            {{ cNote.consignment_no }}
          </ng-container>

        </div>
        <!-- <div class="col">
              <div class="labelBox_e3">Transit Hours</div> 12 Hours

            </div> -->
        <!-- <div class="col pb-2">
              <div class="labelBox_e3">Company Details</div> TATA SKY PVT LTD
            </div> -->
        <div class="col">
          <div class="labelBox_e3"> Origin</div>
          {{getStringBeforeComma(cNote.consignor_city)}}

        </div>
        <div class="col">
          <div class="labelBox_e3"> Destination</div>
          {{getStringBeforeComma(cNote.consignee_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> ETA</div>
          <ul>
            <li>{{cNote.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{cNote.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #addConsignmentOld>
  <ng-container *ngIf="consignmentItemsFormGroup.get('id').value || consignmentItemsFormGroup.get('_id').value">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Edit Consignment Item'}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!(consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value)">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Add Consignment Item'}"></ng-container>
  </ng-container>
  <div class="modal-body ">
    <div class="details_list_item">
      <ng-container *ngTemplateOutlet="itemDetails2"></ng-container>
      <!-- <div class="mTop15 text-right gap-btn">
          <button class="mLft5" mat-raised-button color="primary" (click)="addItem()">Add</button>
        </div> -->
    </div>
  </div>
</ng-template>
<ng-template #itemDetails2Old>
  <form [formGroup]="consignmentItemsFormGroup">
    <div class="fieldset mb-4">
      <h3 class="gwheadline">LR Items {{cNote?.consignment_no}}</h3>
      <div class="row">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">
        <label class="fields_t_b pt-3 color_blue"> Invoice Details</label>
        <div class="row">
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="hidden" formControlName="consignment_notes_id" [value]="cNote?.id">
              <input digitOnly type="number" matInput placeholder="eWay Bill" formControlName="eway_bill_no">
              <mat-error *ngIf="!consignmentItemsFormGroup.get('eway_bill_no').valid
                    && consignmentItemsFormGroup.get('eway_bill_no').touched">
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.required">Eway Bill No is
                  required</span>
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.min">Eway Bill No incorrect.
                  Enter 12 digits</span>
                <span *ngIf="consignmentItemsFormGroup.get('eway_bill_no').errors.max">Eway Bill No
                  incorrect.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input appUppercase type="text" matInput placeholder="Invoice No" formControlName="invoice_no">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_no').valid && consignmentItemsFormGroup.get('invoice_no').touched">
                <span *ngIf="consignmentItemsFormGroup.get('invoice_no').errors.required"> Invoice No is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input matInput [matDatepicker]="pickerinv2" placeholder="Invoice Date" formControlName="invoice_dt">
              <mat-datepicker-toggle matSuffix [for]="pickerinv2"></mat-datepicker-toggle>
              <mat-datepicker #pickerinv2></mat-datepicker>
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_dt').valid && consignmentItemsFormGroup.get('invoice_dt').touched">
                <span> Invoice Date is required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Invoice Value" formControlName="invoice_value">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('invoice_value').valid && consignmentItemsFormGroup.get('invoice_value').touched">
                <span *ngIf="consignmentItemsFormGroup.get('invoice_value').errors.required"> Invoice Value is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="fields_t_b pt-3 color_blue"> Item Details</label>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="nothingabsurd">
              <input type="text" required appUppercase matInput placeholder="Item Description"
                formControlName="description">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('description').valid && consignmentItemsFormGroup.get('description').touched">
                <span *ngIf="consignmentItemsFormGroup.get('description').errors.required"> Description is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <mat-select required placeholder="Units of Measure" formControlName="unit_cd">
                <!-- <mat-option value="Tons">Tons</mat-option>
                <mat-option value="Kg">Kg</mat-option> -->
                <mat-option value="Pcs">Pcs / Boxes / Units</mat-option>
                <!-- <mat-option value="UNT">UNT</mat-option>
                <mat-option value="Bags">Bags</mat-option>
                <mat-option value="Bags">Drums</mat-option> -->
              </mat-select>
              <mat-error class="mapError"
                *ngIf="!consignmentItemsFormGroup.get('unit_cd').valid && consignmentItemsFormGroup.get('unit_cd').touched">
                <span>Unit of measure is required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Units" formControlName="quantity">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Actual Weight" formControlName="actual_wt">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('actual_wt').valid && consignmentItemsFormGroup.get('actual_wt').touched">
                <span *ngIf="consignmentItemsFormGroup.get('actual_wt').errors.required"> Actual weight is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="nothingabsurd">
              <input type="number" matInput placeholder="Charged Weight" formControlName="charged_wt">
              <mat-error
                *ngIf="!consignmentItemsFormGroup.get('charged_wt').valid && consignmentItemsFormGroup.get('charged_wt').touched">
                <span *ngIf="consignmentItemsFormGroup.get('charged_wt').errors.required"> Charged weight is
                  required.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="mTop15 text-right gap-btn">
      <button class="mLft5" mat-raised-button color="primary"
        *ngIf="consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value"
        (click)="addItem()">Save</button>
      <button class="mLft5" mat-raised-button color="primary"
        *ngIf="!(consignmentItemsFormGroup.get('id').value  || consignmentItemsFormGroup.get('_id').value) "
        (click)="addItem()">Add</button>
    </div>
  </form>
</ng-template>
<ng-template #updateStatus>
  <form [formGroup]="consignmentTripTrackingsFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update Status'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Status</h3>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <mat-select required placeholder="Next Trip Status" formControlName="trips_status_cd"
                  [(value)]="consignmentTripTrackingsFormGroup.value.trips_status_cd">
                  <mat-option *ngFor="let item of getAllStatuses()" [value]="item">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="consignmentTripTrackingsFormGroup.get('trips_status_cd').value == 'CANCELLED'">
              <mat-form-field class="w-100">
                <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                  <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentTripTrackingsFormGroup.get('cancel_reason_cd').valid  && !consignmentTripTrackingsFormGroup.get('cancel_reason_cd').touched">
                  Cancel reason is required is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <mat-form-field class="w-100">
                <input type="text" matInput placeholder="Remarks" formControlName="remarks">
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-slide-toggle matInput formControlName="is_visible_to_customer">Is visible to Customer
              </mat-slide-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <app-map-req [addressType]="'(regions)'" [placeholder]="'Current Location'"
                [value]="consignmentTripTrackingsFormGroup.value.last_location" (onSelect)="selectLocation($event)">
              </app-map-req>
              <mat-error class="mapError"
                *ngIf="!consignmentTripTrackingsFormGroup.get('last_location').valid && consignmentTripTrackingsFormGroup.get('last_location').touched">
                Location is required.
              </mat-error>
              <input type="hidden" value="" formControlName="last_location_lat">
              <input type="hidden" value="" formControlName="last_location_long">
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input matInput [matDatepicker]="status_at_dt" placeholder="Date" formControlName="status_at">
                <mat-datepicker-toggle matSuffix [for]="status_at_dt"></mat-datepicker-toggle>
                <mat-datepicker #status_at_dt></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="w-100">
                <input mat-timepicker matInput type="time" placeholder="Time" formControlName="status_at_time">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTripStatus()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #editDriverRatings>
  <form [formGroup]="consignmentTripTrackingsFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Drivier Rating'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Driver Rating</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" matInput placeholder="Rating" formControlName="rating">
                <mat-error
                  *ngIf="!driverRatingsFormGroup.get('rating').valid && driverRatingsFormGroup.get('rating').touched">
                  <span *ngIf="driverRatingsFormGroup.get('rating').errors.required"> Rating is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input type="text" matInput placeholder="Remarks" formControlName="remarks">
                <mat-error
                  *ngIf="!driverRatingsFormGroup.get('remarks').valid && driverRatingsFormGroup.get('remarks').touched">
                  <span *ngIf="driverRatingsFormGroup.get('remarks').errors.required"> Remarks is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" required matInput placeholder="Drivers Id" formControlName="drivers_id">
                <mat-error
                  *ngIf="!driverRatingsFormGroup.get('drivers_id').valid && driverRatingsFormGroup.get('drivers_id').touched">
                  <span *ngIf="driverRatingsFormGroup.get('drivers_id').errors.required"> Drivers Id is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" required matInput placeholder="Trips Id" formControlName="trips_id">
                <mat-error
                  *ngIf="!driverRatingsFormGroup.get('trips_id').valid && driverRatingsFormGroup.get('trips_id').touched">
                  <span *ngIf="driverRatingsFormGroup.get('trips_id').errors.required"> Trip is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input type="number" required matInput placeholder="Customers Id" formControlName="customers_id">
                <mat-error
                  *ngIf="!driverRatingsFormGroup.get('customers_id').valid && driverRatingsFormGroup.get('customers_id').touched">
                  <span *ngIf="driverRatingsFormGroup.get('customers_id').errors.required"> Customers Id is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitDriverRatingsForm()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<!-- <ng-template #bidTemplate1>
  <app-bid-details-edit [trips]="trips" [selfModalRef]="modalRef"></app-bid-details-edit>
</ng-template> -->
<ng-template #assignTruck>
  <form [formGroup]="assignTruckFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Assign Vehicle'}"></ng-container>
    <div class="modal-body ">
      <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Vehicle Details</h3>
          <input autocomplete="off" name="hidden" type="text" style="display:none;">
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase digitCharOnly type="text" required matInput [matAutocomplete]="autoat"
                  placeholder="Registration Number" formControlName="registration_no">
                <mat-autocomplete #autoat="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of truckList" [value]="option.registration_no"
                    (click)="selectTruck(option, assignTruckFormGroup)">
                    {{ option.registration_no }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('registration_no').valid && assignTruckFormGroup.get('registration_no').touched">
                  <span *ngIf="assignTruckFormGroup.get('registration_no').errors.required"> Truck registration number
                    is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input autocomplete="off" type="number" required matInput [matAutocomplete]="autoadm"
                  placeholder="Driver Mobile No" formControlName="driver_mobile_no">
                <mat-autocomplete #autoadm="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of driverList" [value]="option.user.mobile_no"
                    (click)="selectDriverByNo(option, assignTruckFormGroup)">
                    {{ option.user.mobile_no }} {{ option.user.name }}
                    <!-- <ul> -->
                    <!-- <li>{{ option.user.mobile_no }}</li> -->
                    <!-- <li>{{ option.user.name }}</li>> -->
                    <!-- </ul> -->
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('driver_mobile_no').valid && assignTruckFormGroup.get('driver_mobile_no').touched">
                  <span *ngIf="assignTruckFormGroup.get('driver_mobile_no').errors.required">Mobile number is
                    required</span>
                  <span *ngIf="assignTruckFormGroup.get('driver_mobile_no').errors.min">Mobile number is incorrect(10
                    digits)</span>
                  <span *ngIf="assignTruckFormGroup.get('driver_mobile_no').errors.max">Mobile number is incorrect(10
                    digits)</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase type="text" [matAutocomplete]="autoadn" required matInput placeholder="Driver Name"
                  formControlName="driver_name">
                <mat-autocomplete #autoadn="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of driverList" [value]="option.user.name"
                    (click)="selectDriverByName(option, assignTruckFormGroup)">
                    <!-- <ul> -->
                    {{ option.user.name }} {{ option.user.mobile_no }}
                    <!-- <li>{{ option.user.mobile_no }}</li>> -->
                    <!-- </ul> -->
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('driver_name').valid && assignTruckFormGroup.get('driver_name').touched">
                  <span *ngIf="assignTruckFormGroup.get('driver_name').errors.required"> Driver name is required</span>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <!-- <mat-form-field class="w-100">
                <mat-select formControlName="mobile_provider_cd" placeholder="Mobile Provider">
                    <mat-option value=""></mat-option>
                    <mat-option value="AIRTEL">AIRTEL</mat-option>
                    <mat-option value="BSNL">BSNL</mat-option>
                    <mat-option value="IDEA">IDEA</mat-option>
                    <mat-option value="JIO">JIO</mat-option>
                    <mat-option value="RELIANCE TELECOM">RELIANCE TELECOM</mat-option>
                    <mat-option value="TATA INDICOM">TATA INDICOM</mat-option>
                    <mat-option value="VODAFHONE">VODAFHONE</mat-option>
                    <mat-option value="OTHERS">OTHERS</mat-option>
                </mat-select>
                <mat-error *ngIf="!assignTruckFormGroup.get('mobile_provider_cd').valid && assignTruckFormGroup.get('mobile_provider_cd').touched">
                  <span *ngIf="assignTruckFormGroup.get('mobile_provider_cd').errors.required"> Mobile provider is required</span>
                </mat-error>
              </mat-form-field> -->
              {{assignTruckFormGroup.get('mobile_provider_cd').value}}
            </div>
            <!-- </div>
          <div class="row"> -->
            <div class="col">
              <app-dropdowns-req [required]=true [type]="'partner_type_cd'"
                [selected]="assignTruckFormGroup.value.partner_type_cd" (onSelect)="setpartnerTypeCd($event)"
                (touched)="assignTruckFormGroup.get('partner_type_cd').markAsTouched()" [placeholder]="'Partner Type'"
                [valid]="assignTruckFormGroup.get('partner_type_cd').valid"></app-dropdowns-req>
              <mat-error class="mapError"
                *ngIf="!assignTruckFormGroup.get('partner_type_cd').valid && assignTruckFormGroup.get('partner_type_cd').touched">
                <span *ngIf="assignTruckFormGroup.get('partner_type_cd').errors.required">Partner Type is
                  required.</span>
              </mat-error>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase required type="text" matInput [matAutocomplete]="autoabn" placeholder="Company Name"
                  formControlName="partner_company_name">
                <mat-autocomplete #autoabn="matAutocomplete" panelWidth="300px" [displayWith]="displayPartnerName"
                  (optionSelected)="selectBrokerName($event.option.value)">
                  <mat-option *ngFor="let option of partnerList" [value]="option">
                    <ul>
                      <li>{{option.partner_company_name }}</li>
                    </ul>
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('partner_company_name').valid && assignTruckFormGroup.get('partner_company_name').touched">
                  <span *ngIf="assignTruckFormGroup.get('partner_company_name').errors.required"> Partner company name
                    is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required autocomplete="off" type="number" [matAutocomplete]="autoabcm" maxlength="10"
                  min=1000000000 max=9999999999 required matInput placeholder="Contact mobile no"
                  formControlName="mobile_no">
                <mat-autocomplete #autoabcm="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of partnerContactList" [value]="option.user.mobile_no"
                    (onSelectionChange)="selectBrokerContactNo(option)">
                    <!-- <ul> -->
                    {{ option.user.mobile_no }} {{ option.user.name }}
                    <!-- </ul> -->
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('mobile_no').valid && assignTruckFormGroup.get('mobile_no').touched">
                  <span *ngIf="assignTruckFormGroup.get('mobile_no').errors.required">Partner mobile number is
                    required</span>
                  <span *ngIf="assignTruckFormGroup.get('mobile_no').errors.min">Mobile number is incorrect(10
                    digits)</span>
                  <span *ngIf="assignTruckFormGroup.get('mobile_no').errors.max">Mobile number is incorrect(10
                    digits)</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase required type="text" [matAutocomplete]="autoabcn" matInput
                  placeholder="Contact Name" formControlName="partner_contact_name">
                <mat-autocomplete #autoabcn="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of partnerContactList" [value]="option.user.name"
                    (onSelectionChange)="selectBrokerContactName(option, $event)">
                    <!-- <ul> -->
                    {{ option.user.name }} {{ option.user.mobile_no }}
                    <!-- <li>{{ option.user.mobile_no }}</li> -->
                    <!-- </ul> -->
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('partner_contact_name').valid && assignTruckFormGroup.get('partner_contact_name').touched">
                  <span *ngIf="assignTruckFormGroup.get('partner_contact_name').errors.required"> Partner contact name
                    is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- </div>
          <div class="row"> -->
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" matInput placeholder="Hire Amount" formControlName="hire_amt">
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('hire_amt').valid && assignTruckFormGroup.get('hire_amt').touched">
                  <span *ngIf="assignTruckFormGroup.get('hire_amt').errors.required">Total hire amount is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" matInput placeholder="Advance Amount" formControlName="ath_amt">
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('ath_amt').valid && assignTruckFormGroup.get('ath_amt').touched">
                  <span *ngIf="assignTruckFormGroup.get('ath_amt').errors.required">Advance amount is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" matInput placeholder="Detention/day" formControlName="detention_amt_per_day">
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('detention_amt_per_day').valid && assignTruckFormGroup.get('detention_amt_per_day').touched">
                  <span *ngIf="assignTruckFormGroup.get('detention_amt_per_day').errors.required">Detention per day
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required matInput [matDatepicker]="picker_ssat" formControlName="reporting_at"
                  placeholder="Reporting At">
                <mat-datepicker-toggle matSuffix [for]="picker_ssat"></mat-datepicker-toggle>
                <mat-datepicker #picker_ssat></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input required type="time" matInput placeholder="Time" formControlName="reporting_at_time">
              </mat-form-field>
            </div>
            <!-- </div>
          <div class="row"> -->
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase type="text" matInput placeholder="Pan Card No" formControlName="pan_no">
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('pan_no').valid && assignTruckFormGroup.get('pan_no').touched">
                  <span *ngIf="assignTruckFormGroup.get('pan_no').errors.required"> PAN Card is required</span>
                  <span *ngIf="!assignTruckFormGroup.get('pan_no').errors.required"> PAN Card is invalid</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <!-- <label class="fileTitle">Pan Card</label> -->
              <label *ngIf="assignTruckFormGroup.get('pan_card_scan').value"><a
                  href="{{imageUrl}}{{assignTruckFormGroup.get('pan_card_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <!-- <img *ngIf="url" [src]="url" height="200"> <br/> -->
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <!-- <span *ngIf="assignTruckFormGroup.get('pan_card_scan').value"><a href="{{imageUrl}}{{assignTruckFormGroup.get('pan_card_scan').value}}"
                      target="_blank">
                      <mat-icon>attachment</mat-icon>
                    </a></span> -->
                <span><img src="assets/img/Landing_01.png">Upload Pan</span> <input type="file" style="display: none;"
                  name="file[]" download="pan_card_scan"
                  (change)="uploadBrokerPanScan($event, assignTruckFormGroup.get('borkers_id'))">
              </label>

              <mat-error
                *ngIf="!assignTruckFormGroup.get('pan_card_scan').valid && assignTruckFormGroup.get('pan_card_scan').touched">
                <span *ngIf="assignTruckFormGroup.get('pan_card_scan').errors.required">PAN Card is required</span>
              </mat-error>
              <!-- <div class="col-md-3  p-2">Upload Documents
                  <div class=" fileUpload btn  btn-dark dark_gary">
                      <span><img src="assets/img/Landing_01.png" >Upload Vendor Slip</span>
                      <input type="file" class="upload">
                  </div>
              </div> -->
            </div>
            <div class="col">
              <!-- <label class="fileTitle">Vendor Slip</label> -->
              <label *ngIf="vendorSlipScan"><a href="{{imageUrl}}{{vendorSlipScan}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Vendor Slip</span><input type="file"
                  style="display: none;" name="file[]" (change)="uploadVendorSlip($event)">
              </label>
            </div>

            <div class="col">
              <!-- <label class="fileTitle">TDS Decl..</label> -->
              <label *ngIf="assignTruckFormGroup.get('tds_declaration_scan').value"><a
                  href="{{imageUrl}}{{assignTruckFormGroup.get('tds_declaration_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload TDS Decl</span><input type="file"
                  style="display: none;" name="file[]"
                  (change)="uploadBrokerTdsScan($event, assignTruckFormGroup.get('borkers_id'))">
              </label>
              <mat-error
                *ngIf="!assignTruckFormGroup.get('tds_declaration_scan').valid && assignTruckFormGroup.get('tds_declaration_scan').touched">
                <span *ngIf="assignTruckFormGroup.get('tds_declaration_scan').errors.required">TDS is required</span>
              </mat-error>
            </div>
            <!-- </div>
          <div class="row"> -->
            <div class="col">
              <mat-form-field class="w-100">
                <input required matInput type="number" formControlName="estimated_transit_time"
                  placeholder="Transit Time(Hours)">
                <mat-error
                  *ngIf="!assignTruckFormGroup.get('estimated_transit_time').valid && assignTruckFormGroup.get('estimated_transit_time').touched">
                  <span *ngIf="assignTruckFormGroup.get('estimated_transit_time').errors.required">Estimated TAT/Transit
                    hours is required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-checkbox formControlName="telenity_enabled">Track</mat-checkbox>
            </div>

          </div>
          <!-- <div class="row" *ngIf="this.isExcessHire">
        <div class="col-sm-3">Hire amount is greater than target({{getTargetHireAmt()}}). Please sepecify a reason</div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input required matInput type="text" formControlName="hire_excess_reason" placeholder="Reason for high freight">
          </mat-form-field>
          <mat-error *ngIf="!assignTruckFormGroup.get('hire_excess_reason').valid && assignTruckFormGroup.get('hire_excess_reason').touched">
            <span *ngIf="assignTruckFormGroup.get('hire_excess_reason').errors.required">Reason for high freight</span>
          </mat-error>
        </div>
      </div>
      <div class="row" *ngIf="this.isExcessAdvance">
        <div class="col-sm-3">Advance amount is greater than target({{getTargetAdvancePercentage()}}%). Please sepecify
          a reason</div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input required matInput type="text" formControlName="advance_excess_reason" placeholder="Reason for high advance amount">
          </mat-form-field>
          <mat-error *ngIf="!assignTruckFormGroup.get('advance_excess_reason').valid && assignTruckFormGroup.get('advance_excess_reason').touched">
            <span *ngIf="assignTruckFormGroup.get('advance_excess_reason').errors.required">Reason for high advance
              amount</span>
          </mat-error>
        </div>
      </div> -->
          <div class="row" *ngIf="isExcessHire">
            <div class="col"></div>
          </div>
        </div>
        <div class="row ">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitAssignTruckForm()">Assign</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #truckDocuments>
  <form [formGroup]="attachTruckDocumentsFormGroup">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Vehicle Documents'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummary;context:{tripData:trips}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Vehicle Documents</h3>
          <input autocomplete="off" name="hidden" type="text" style="display:none;">
          <div class="row">
            <div class="col">
              <span class="aLabel">Truck Registration No:</span>
              <span> {{truckHireChallan.truck.registration_no}} </span>
            </div>
            <div class="col">
              <span class="aLabel">Driver Details</span>
              <span>{{truckHireChallan.truck.drivers[0].user.name}}
                {{truckHireChallan.truck.drivers[0].user.mobile_no}} </span>
            </div>


          </div>
          <div class="row ">
            <div class="col">
              <!-- <label class="fileTitle required">Driving License*</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('driving_license_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('driving_license_scan').value}}"
                  target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload DL</span><input type="file" style="display: none;"
                  name="file[]" (change)="uploadDrivingLicenseScan($event, truckHireChallan.truck.drivers[0].id)">
              </label>
            </div>
            <div class="col">
              <!-- <label class="fileTitle  required">RC*</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('registration_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('registration_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload RC</span> <input type="file" style="display: none;"
                  name="file[]" (change)="uploadRegistrationScan($event, truckHireChallan.truck.id)">
              </label>
            </div>
            <div class="col">
              <!-- <label class="fileTitle">Insurance</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('insurance_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('insurance_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Insurance</span><input type="file"
                  style="display: none;" name="file[]"
                  (change)="uploadInsuranceScan($event, truckHireChallan.truck.id)">
              </label>
            </div>
            <div class="col">
              <!-- <label class="fileTitle">Fitness</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('fitness_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('fitness_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Fitness</span><input type="file"
                  style="display: none;" name="file[]" (change)="uploadFitnessScan($event, truckHireChallan.truck.id)">
              </label>
            </div>

          </div>
          <div class="row">
            <div class="col">
              <!-- <label class="fileTitle">Permit</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('permit_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('permit_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Permit</span><input type="file" style="display: none;"
                  name="file[]" (change)="uploadPermitScan($event, truckHireChallan.truck.id)">
              </label>
            </div>
            <div class="col">
              <!-- <label class="fileTitle">Pollution</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('pollution_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pollution_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Pollution</span> <input type="file"
                  style="display: none;" name="file[]"
                  (change)="uploadPollutionScan($event, truckHireChallan.truck.id)">
              </label>
            </div>

            <div class="col">
              <!-- <label class="fileTitle">Owner Pan Card</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('pan_card_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pan_card_scan').value}}" target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary  fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload Owner Pan Card</span><input type="file"
                  style="display: none;" name="file[]"
                  (change)="uploadOwnerPanScan($event, truckHireChallan.partners.id)">
              </label>
            </div>
            <div class="col">
              <!-- <label class="fileTitle">TDS Decl..</label> -->
              <label *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').value"><a
                  href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('tds_declaration_scan').value}}"
                  target="_blank">
                  <mat-icon>attachment</mat-icon>
                </a></label>
              <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
                <span><img src="assets/img/Landing_01.png">Upload TDS.</span><input type="file" style="display: none;"
                  name="file[]" (change)="uploadOwnerTdsScan($event, truckHireChallan.partners.id)">
              </label>
              <mat-error
                *ngIf="!attachTruckDocumentsFormGroup.get('tds_declaration_scan').valid && attachTruckDocumentsFormGroup.get('tds_declaration_scan').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').errors.required">TDS is
                  required</span>
              </mat-error>
            </div>

          </div>
          <div class="row">
            <div class="col">
              <app-dropdowns-req diabled [required]=true [type]="'partner_type_cd'"
                [selected]="attachTruckDocumentsFormGroup.value.partner_type_cd" (onSelect)="setpartnerTypeCd2($event)"
                (touched)="attachTruckDocumentsFormGroup.get('partner_type_cd').markAsTouched()"
                [placeholder]="'Partner Type'" [valid]="attachTruckDocumentsFormGroup.get('partner_type_cd').valid">
              </app-dropdowns-req>
              <mat-error class="mapError"
                *ngIf="!attachTruckDocumentsFormGroup.get('partner_type_cd').valid && attachTruckDocumentsFormGroup.get('partner_type_cd').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('partner_type_cd').errors.required">Partner Type is
                  required.</span>
              </mat-error>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase type="text" matInput placeholder="Pan Card No" formControlName="pan_no">
                <mat-error
                  *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').valid && attachTruckDocumentsFormGroup.get('pan_no').touched">
                  <span *ngIf="attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is required</span>
                  <span *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is invalid</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input type="number" min=1000000000 max=9999999999 required matInput placeholder="Owner mobile no"
                  formControlName="mobile_no">
                <mat-error
                  *ngIf="!attachTruckDocumentsFormGroup.get('mobile_no').valid && attachTruckDocumentsFormGroup.get('mobile_no').touched">
                  <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.required">Partner mobile number is
                    required</span>
                  <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.min">Mobile number is incorrect(10
                    digits)</span>
                  <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.max">Mobile number is incorrect(10
                    digits)</span>

                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase required type="text" matInput placeholder="Owner Contact Name"
                  formControlName="partner_contact_name">
                <mat-error
                  *ngIf="!attachTruckDocumentsFormGroup.get('partner_contact_name').valid && attachTruckDocumentsFormGroup.get('partner_contact_name').touched">
                  <span *ngIf="attachTruckDocumentsFormGroup.get('partner_contact_name').errors.required"> Partner
                    contact
                    name is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <input appUppercase required type="text" matInput [matAutocomplete]="autoabn"
                  placeholder="Owner RC Name" formControlName="partner_company_name">
                <mat-autocomplete #autoabn="matAutocomplete" panelWidth="300px" [displayWith]="displayPartnerName"
                  (optionSelected)="selectOwnerName($event.option.value)">
                  <mat-option *ngFor="let option of partnerList" [value]="option">
                    <ul>
                      <li>{{option.partner_company_name }}</li>
                    </ul>
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="!attachTruckDocumentsFormGroup.get('partner_company_name').valid && attachTruckDocumentsFormGroup.get('partner_company_name').touched">
                  <span *ngIf="attachTruckDocumentsFormGroup.get('partner_company_name').errors.required"> Owner name is
                    required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- </div>
      <div class="row"> -->
            <div class="col-sm-3">
              <!-- <mat-form-field class="w-100">
          <mat-select required formControlName="mobile_provider_cd" placeholder="Mobile Provider">
              <mat-option value=""></mat-option>
              <mat-option value="AIRTEL">AIRTEL</mat-option>
              <mat-option value="BSNL">BSNL</mat-option>
              <mat-option value="IDEA">IDEA</mat-option>
              <mat-option value="JIO">JIO</mat-option>
              <mat-option value="RELIANCE TELECOM">RELIANCE TELECOM</mat-option>
              <mat-option value="TATA INDICOM">TATA INDICOM</mat-option>
              <mat-option value="VODAFHONE">VODAFHONE</mat-option>
              <mat-option value="OTHERS">OTHERS</mat-option>
          </mat-select>
          <mat-error *ngIf="!attachTruckDocumentsFormGroup.get('mobile_provider_cd').valid && attachTruckDocumentsFormGroup.get('mobile_provider_cd').touched">
            <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_provider_cd').errors.required"> Mobile provider is required</span>
          </mat-error>
        </mat-form-field> -->
              {{attachTruckDocumentsFormGroup.get('mobile_provider_cd').value}}
            </div>
            <div class="col">
              <mat-checkbox formControlName="telenity_enabled">Track</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary" (click)="submitTruckDocument()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>
<ng-template #truckDocumentsOnly>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Attach Truck Documents {{trip_no}} {{trips.source_city}}
      {{trips.destination_city}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fieldset">
    <form [formGroup]="attachTruckDocumentsFormGroup">
      <div class="row  mTop15">
        <div class="col-sm-3">
          <span class="aLabel">Truck Registration No:</span>
          <span> {{truckHireChallan.truck.registration_no}} </span>
        </div>
        <div class="col-sm-3">
          <span class="aLabel">Driver Details</span>
          <span>{{truckHireChallan.truck.drivers[0].user.name}}
            {{truckHireChallan.truck.drivers[0].user.mobile_no}} </span>
        </div>


      </div>
      <div class="row  mTop15">
        <div class="col-sm-2">
          <!-- <label class="fileTitle required">Driving License*</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('driving_license_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('driving_license_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload DL</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadDrivingLicenseScan($event, truckHireChallan.truck.drivers[0].id)">
          </label>
        </div>
        <div class="col-sm-2">
          <!-- <label class="fileTitle  required">RC*</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('registration_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('registration_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary required fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload RC</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadRegistrationScan($event, truckHireChallan.truck.id)">
          </label>
        </div>
        <div class="col-sm-2">
          <!-- <label class="fileTitle">Insurance</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('insurance_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('insurance_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload Insurance</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadInsuranceScan($event, truckHireChallan.truck.id)">
          </label>
        </div>
        <div class="col-sm-2">
          <!-- <label class="fileTitle">Fitness</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('fitness_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('fitness_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload Fitness</span> <input type="file" style="display: none;"
              name="file[]" (change)="uploadFitnessScan($event, truckHireChallan.truck.id)">
          </label>
        </div>

      </div>
      <div class="row mTop15">
        <div class="col-sm-2">
          <!-- <label class="fileTitle">Permit</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('permit_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('permit_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload Permit</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadPermitScan($event, truckHireChallan.truck.id)">
          </label>
        </div>
        <div class="col-sm-2">
          <!-- <label class="fileTitle">Pollution</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('pollution_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pollution_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload Pollution</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadPollutionScan($event, truckHireChallan.truck.id)">
          </label>
        </div>

        <div class="col-sm-2">
          <!-- <label class="fileTitle">Owner Pan Card</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('pan_card_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('pan_card_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload Owner PAN</span><input type="file" style="display: none;"
              name="file[]" (change)="uploadOwnerPanScan($event, truckHireChallan.partners.id)">
          </label>
        </div>
        <div class="col-sm-2">
          <!-- <label class="fileTitle">TDS Decl..</label> -->
          <label *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').value"><a
              href="{{imageUrl}}{{attachTruckDocumentsFormGroup.get('tds_declaration_scan').value}}" target="_blank">
              <mat-icon>attachment</mat-icon>
            </a></label>
          <label class="btn btn-primary fileUpload btn  btn-dark dark_gary">
            <span><img src="assets/img/Landing_01.png">Upload TDS.. </span><input type="file" style="display: none;"
              name="file[]" (change)="uploadOwnerTdsScan($event, truckHireChallan.partners.id)">
          </label>
          <mat-error
            *ngIf="!attachTruckDocumentsFormGroup.get('tds_declaration_scan').valid && attachTruckDocumentsFormGroup.get('tds_declaration_scan').touched">
            <span *ngIf="attachTruckDocumentsFormGroup.get('tds_declaration_scan').errors.required">TDS is
              required</span>
          </mat-error>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-2">
          <app-dropdowns-req diabled [required]=true [type]="'partner_type_cd'"
            [selected]="attachTruckDocumentsFormGroup.value.partner_type_cd" (onSelect)="setpartnerTypeCd2($event)"
            (touched)="attachTruckDocumentsFormGroup.get('partner_type_cd').markAsTouched()"
            [placeholder]="'Partner Type'" [valid]="attachTruckDocumentsFormGroup.get('partner_type_cd').valid">
          </app-dropdowns-req>
          <mat-error class="mapError"
            *ngIf="!attachTruckDocumentsFormGroup.get('partner_type_cd').valid && attachTruckDocumentsFormGroup.get('partner_type_cd').touched">
            <span *ngIf="attachTruckDocumentsFormGroup.get('partner_type_cd').errors.required">Partner Type is
              required.</span>
          </mat-error>
        </div>
        <div class="col-sm-2">
          <mat-form-field class="w-100">
            <input appUppercase type="text" matInput placeholder="Pan Card No" formControlName="pan_no">
            <mat-error
              *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').valid && attachTruckDocumentsFormGroup.get('pan_no').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is required</span>
              <span *ngIf="!attachTruckDocumentsFormGroup.get('pan_no').errors.required"> PAN Card is invalid</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field class="w-100">
            <input type="number" min=1000000000 max=9999999999 required matInput placeholder="Owner mobile no"
              formControlName="mobile_no">
            <mat-error
              *ngIf="!attachTruckDocumentsFormGroup.get('mobile_no').valid && attachTruckDocumentsFormGroup.get('mobile_no').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.required">Partner mobile number is
                required</span>
              <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.min">Mobile number is incorrect(10
                digits)</span>
              <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_no').errors.max">Mobile number is incorrect(10
                digits)</span>

            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input appUppercase required type="text" matInput placeholder="Owner Contact Name"
              formControlName="partner_contact_name">
            <mat-error
              *ngIf="!attachTruckDocumentsFormGroup.get('partner_contact_name').valid && attachTruckDocumentsFormGroup.get('partner_contact_name').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('partner_contact_name').errors.required"> Partner contact
                name is
                required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <!-- <mat-form-field class="w-100">
              <input appUppercase required type="text" matInput placeholder="Owner Company Name" formControlName="partner_company_name">
              <mat-error *ngIf="!attachTruckDocumentsFormGroup.get('partner_company_name').valid && attachTruckDocumentsFormGroup.get('partner_company_name').touched">
                <span *ngIf="attachTruckDocumentsFormGroup.get('partner_company_name').errors.required"> Partner company
                  name is
                  required</span>
              </mat-error>
            </mat-form-field> -->
          <mat-form-field class="w-100">
            <input appUppercase required type="text" matInput [matAutocomplete]="autoabn" placeholder="Owner RC Name"
              formControlName="partner_company_name">
            <mat-autocomplete #autoabn="matAutocomplete" panelWidth="300px">
              <mat-option *ngFor="let option of partnerList" [value]="option.name"
                (onSelectionChange)="selectOwnerName(option)">
                <ul>
                  <li>{{option.webxpress_partner_code}} {{ option.name }}</li>
                </ul>
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="!attachTruckDocumentsFormGroup.get('partner_company_name').valid && attachTruckDocumentsFormGroup.get('partner_company_name').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('partner_company_name').errors.required"> Owner name is
                required</span>
            </mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-3">
          <!-- <mat-form-field class="w-100">
            <mat-select required formControlName="mobile_provider_cd" placeholder="Mobile Provider">
                <mat-option value=""></mat-option>
                <mat-option value="AIRTEL">AIRTEL</mat-option>
                <mat-option value="BSNL">BSNL</mat-option>
                <mat-option value="IDEA">IDEA</mat-option>
                <mat-option value="JIO">JIO</mat-option>
                <mat-option value="RELIANCE TELECOM">RELIANCE TELECOM</mat-option>
                <mat-option value="TATA INDICOM">TATA INDICOM</mat-option>
                <mat-option value="VODAFHONE">VODAFHONE</mat-option>
                <mat-option value="OTHERS">OTHERS</mat-option>
            </mat-select>
            <mat-error *ngIf="!attachTruckDocumentsFormGroup.get('mobile_provider_cd').valid && attachTruckDocumentsFormGroup.get('mobile_provider_cd').touched">
              <span *ngIf="attachTruckDocumentsFormGroup.get('mobile_provider_cd').errors.required"> Mobile provider is required</span>
            </mat-error>
          </mat-form-field> -->
          {{attachTruckDocumentsFormGroup.get('mobile_provider_cd').value}}
        </div>
        <div class="col-sm-3">
          <mat-checkbox formControlName="telenity_enabled">Track</mat-checkbox>
        </div>
      </div>
      <div class="row mBtm10">
        <div class="col-sm-6 text-left">
          <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
        </div>
        <div class="col-sm-6 text-right">
          <button mat-raised-button color="primary" (click)="submitTruckDocumentOnly()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #approveExcessAdvance>approveExcessAdvance Not Implemented</ng-template>
<ng-template #approveExcessHire>approveExcessHire Not Implemented</ng-template>
<ng-template #gallery>
  <div class="popupDialog">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Gallery ' + trips.source_city + ' ' + trips.destination_city}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
      <app-ngx-plain-gallery2-page [trips_id]="trips.id"></app-ngx-plain-gallery2-page>
    </div>
  </div>
</div>
</ng-template>
