import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'
import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class TrucksService {


  constructor(private serviceHttp: HttpService) {

  }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel('trucks/exportList', 'POST', 0, data);
  }
  searchSilent(searchTerm: string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trucks/searchNew', 'POST', 0, {search: searchTerm}, 0, 1);
  }

  getList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trucks', 'GET');
  }
  getTrucksById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trucks/'+ id, 'GET');
  }
  addTrucks(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trucks', 'POST', 0, data);
  }
  updateTrucks(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trucks/'+data.id, 'PUT', 0, data);
  }
  searchTruckList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trucks/search', 'POST', 0, {search:str});
  }
  searchPartnersList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partners/search', 'POST', 0, {search:str});
  }
  getMMILocation(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trucks/getMMILocation', 'POST', 0, data);
 }
}
