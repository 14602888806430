import { Validators } from "@angular/forms";

export class PartnerAddresses {
    id : number;
    street : string;
    map_locality_street: string;
    vicinity: string;
    place_name: string;
    place_id: string;
  
    city : string;
    state : string;
    pincode : string;
    location_type_cd : string;
    location_label : string;
    goods_flow_cd : string;
    is_billing_address : boolean;
    goods_type_cd : string;
    products_list : string;
    destinations : string;
    location_lat : string;
    location_long : string;
    partners_id : number;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : PartnerAddresses[] {
        let partnerAddresses : PartnerAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                partnerAddresses.push(new PartnerAddresses(json));
            }
        }
        return partnerAddresses;
    }
    public fill(json: any){
      if (json != null) {
        this.id = json.id;
        this.street = json.street;
        this.map_locality_street = json.map_locality_street;
        this.vicinity = json.vicinity;
        this.place_name = json.place_name;
        this.place_id = json.place_id;
  
        this.city = json.city;
        this.state = json.state;
        this.pincode = json.pincode;
        this.location_type_cd = json.location_type_cd;
        this.location_label = json.location_label;
        this.goods_flow_cd = json.goods_flow_cd;
        this.is_billing_address = json.is_billing_address;
        this.goods_type_cd = json.goods_type_cd;
        this.products_list = json.products_list;
        this.destinations = json.destinations;
        this.location_lat = json.location_lat;
        this.location_long = json.location_long;
        this.partners_id = json.partners_id;
    }
    }
}
export let partnerAddressesFormControls = {
    id: ['', []],
    street: ['', [Validators.required, Validators.minLength(3)]],
    map_locality_street: ['', []],
    vicinity: ['', []],
    place_name: ['', []],
    place_id: ['', []],
  
    city: ['', [Validators.required, Validators.minLength(3)]],
    state: ['', [Validators.required, Validators.minLength(3)]],
    pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    location_type_cd: ['', []],
    location_label: ['', []],
    goods_flow_cd: ['', []],
    is_billing_address: ['', []],
    goods_type_cd: ['', []],
    products_list: ['', []],
    destinations: ['', []],
    location_lat: ['', []],
    location_long: ['', []],
    partners_id: ['', []],
  };
  