import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class DriversService {
  searchSilent(searchTerm: any): Observable<ResponseModel>  {
    return this.serviceHttp.getResponse('drivers/searchNew', 'POST', 0, {search: searchTerm} , 0, 1);
  }
  constructor(private serviceHttp: HttpService) { }
  getList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers?page=' + pageNo, 'GET');
  }
  getNewList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/listNewDrivers/' + pageNo, 'GET');
  }
  getDriversById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('drivers/' + id, 'GET');
  }
  markDriver(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/markVerify/' + id, 'GET');
  }
  addDrivers(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('drivers', 'POST', 0, data, 1);
  }
  updateDrivers(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/' + data.id, 'PUT', 0, data, 1);
  }
  searchDriverList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/search', 'POST', 0, {search: str});
  }
  searchDriverName(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('driversname/search', 'POST', 0, {search: str});
  }
}
