import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
import { EntityService } from '../../entity/services/entity.service';
@Injectable()
export class PartnersService extends EntityService {
  entityName = 'partners';
  constructor(protected serviceHttp:HttpService) {
    super(serviceHttp);
  }

  // constructor(private serviceHttp:HttpService) { }
  // exportList(data): any {
  //   return this.serviceHttp.getResponseExcel('partners/exportList', 'POST', 0, data);
  // }
  searchNew(searchTerm: any, aPartnersId?: any): Observable<ResponseModel>  {
    return this.serviceHttp.getResponse('partners/search', 'POST', 0, {search: searchTerm, partners_id: aPartnersId});
  }
  // getList(pageNo:number): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('partners?page='+pageNo, 'GET');
  // }
  getNewList(pageNo:number): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partners/listNewPartners/'+pageNo, 'GET');
  }
  getPartnersById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('partners/'+ id, 'GET');
  }
  addPartners(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('partners', 'POST', 0, data,1);
  }
  updatePartners(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partners/'+data.id, 'PUT', 0, data,1);
  }
  searchPartnersList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partners/search', 'POST', 0, {search:str});
  }
  markPartner(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('partners/markVerify/'+id,'GET');
  }
  getEmployees(str: string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('users/employees', 'POST', 0, {search:str});
  }
}
