import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class BookingsService {

  constructor(private serviceHttp:HttpService) { }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel('bookings/exportList', 'POST', 0, data);
  }
  getList(data
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/page', 'POST', 0, data);
  }
  search(data ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/search', 'POST', 0, data);
  }
  getBookingsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bookings/'+ id, 'GET');
  }
  addBookings(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bookings', 'POST', 0, data);
  }
  getLocationCode(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/locationcode', 'POST', 0, data, 0, 1);
  }
  addquote(data):Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/addquote', 'POST', 0, data);
  }
  getFreight(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('freights/search', 'POST', 0, data);
  }
  getTerritoryDefaults(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/getTerritoryDefaults', 'POST', 0, data);
  }
  cancelBooking(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/updateStatus/'+data.id, 'PUT', 0, data);
  }
  updateStatus(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/updateStatus/'+data.id, 'PUT', 0, data);
  }
  updateBookings(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/'+data.id, 'PUT', 0, data);
  }
  searchBookingList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/search', 'POST', 0, {search:str});
  }
}
