import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service'
import { CommonService } from '../services/common.service';
@Injectable()
export class RoleGuard implements CanActivate, CanLoad {
  constructor(private commonService:CommonService,
    private router:Router){ }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const expectedRoles : Array<number> = next.data.roles;
      let result = false;
      if ( !StorageService.isLogin()) {
        // console.log('user not logged in role guard');
        this.commonService.changeHeader(false);
        this.router.navigate(['user/login'], { queryParams: { returnUrl: state.url }});
        result = false;
      } else if ( expectedRoles.indexOf(StorageService.getRole()) > -1 ) {
        this.commonService.changeHeader(true);
        result = true;
      }
    if ( !result && StorageService.isLogin()) {
      alert('You are not authorized for this activity ' + state.url);
      // console.log(state);
    }
    return result;
  }
  canLoad(route: Route): boolean {
    let url: string = route.path;
    if (StorageService.isLogin()) {
	    return true;
    }
    // this.authService.setRedirectUrl(url);
    this.router.navigate(['user/login']);
    return false;
  }
}
