import {Trucks} from '../trucks/trucks';
import {Partners} from '../partners/partners';
import { safeDate } from '../../core/globalfunctions';
export class TruckPartners {
    id : number;
    start_dt : Date;
    end_dt : Date;
    partner_type_cd : string;
    trucks_id : number;
    partners_id : number;
    // trucks : Trucks;
    partner : Partners;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : TruckPartners[] {
        let truckPartners : TruckPartners[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                truckPartners.push(new TruckPartners(json));
            }
        }
        return truckPartners;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            safeDate(this, json,  'start_dt');
            safeDate(this, json,  'end_dt');

            this.partner_type_cd = json.partner_type_cd;
            this.trucks_id = json.trucks_id;
            this.partners_id = json.partners_id;
            if (json.trucks != null) {
                // this.trucks = new Trucks(json.trucks);
            }
            if (json.partner != null) {
                this.partner = new Partners(json.partner);
            }
        }
    }
}
