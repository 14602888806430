<div class=" content_area_list container inne_area_list mobScroll mob-p-default ">
  <div class="row example-header">
    <div class="col-sm-9 text-center">
      <form [formGroup]="searchForm">
        <div class="row" #searchBar *ngIf="searchBarOpen">
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="w-100">
              <input matInput #filter placeholder="Search {{entityName |  titlecase}} " formControlName="search">
            </mat-form-field>
          </div>
          <div class="col-sm-3 action-buttons  text-center">
            <span style="margin-right: 10px"><button mat-icon-button color="primary" (click)="submitSearch()">
                <mat-icon>search</mat-icon>
              </button></span>
            <span><button mat-icon-button color="secondary" (click)="resetSearch()">
                <mat-icon>clear</mat-icon>
              </button></span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-3 text-right">
      <span *ngIf="!searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_more</mat-icon>
        </button></span>
      <span *ngIf="searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_less</mat-icon>
        </button></span>
      <span>
          <button mat-icon-button [routerLink]="['/' + this.entityName + '/add']">
            <mat-icon>add</mat-icon>
          </button>
          </span>
      <span><button mat-icon-button color="primary" (click)="download($event)">
          <mat-icon>cloud_download</mat-icon>
        </button></span>
      <span><button mat-icon-button color="primary" (click)="refresh($event)">
          <mat-icon>refresh</mat-icon>
        </button></span>
        <span><button mat-icon-button [matMenuTriggerFor]="menuTop">
          <mat-icon>more_vert</mat-icon>
        </button></span>
      <mat-menu #menuTop="matMenu">
      </mat-menu>
    </div>
  </div>
  <div class="table_list">
    <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
      <mat-table #table class="table_list" [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="widthm200"> Id </mat-header-cell>
      <mat-cell *matCellDef="let element" class="widthm200"> {{element.id}} </mat-cell>
    </ng-container>
    <!-- edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-icon-button color="primary" (click)="openEditModal(editEntity, element)"><i
            class="ti-pencil-alt"></i></a>
        <!-- <a mat-icon-button color="primary" [routerLink]="['/' + this.entityName + '/edit',element.id]" routerLinkActive="active"><i class="ti-pencil-alt"></i></a> -->
      </mat-cell>
    </ng-container>
    <!-- view Column
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="primary"  [routerLink]="['/' + this.entityName + '/view',element.id]" routerLinkActive="active"><i class="fa fa-eye"></i></a>
	  </mat-cell>
    </ng-container>
	-->
    <!-- Delete Column
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef> Delete</mat-header-cell>
      <mat-cell *matCellDef="let element">
	     <a mat-icon-button color="warn" (click)="deletePayments(element.id)"><i class="ti-trash"></i></a>
	  </mat-cell>
    </ng-container>
    -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page" (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
</div>
<ng-template #editEntity>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{entityName | titlecase}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!--    <div class="alert alert-danger" *ngIf="isError">
            <strong>Alert!</strong>
            <ul>
              <li *ngFor="let message of errorMessages">{{message}}</li>
            </ul>
          </div> -->
    <form [formGroup]="entityFormGroup">
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Id" formControlName="id">
            <mat-error
              *ngIf="!entityFormGroup.get('id').valid && entityFormGroup.get('id').touched">
              <span *ngIf="entityFormGroup.get('id').errors.required">ID is required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mBtm10">
        <div class="col-sm-6 text-left">
          <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
        </div>
        <div class="col-sm-6 text-right">
          <button mat-raised-button color="primary" (click)="submitEntityForm()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
