import {Customers} from '../customers/customers';
import { Validators } from '@angular/forms';
import { gst_pattern, safeDate } from '../../core/globalfunctions';
import { CustomerContacts } from '../customerContacts/customerContacts';
export class CustomerAddresses {
    id : number;
    street : string;
    map_locality_street: string;
    vicinity: string;
    place_name: string;
    place_id: string;
    city : string;
    state : string;
    pincode : string;
    location_type_cd : string;
    location_label : string;
    customers_id : number;
    goods_flow_cd : string;
    is_billing_address : number;
    goods_type_cd : string;
    products_list : string;
    location_lat : number;
    location_long : number;
    deleted_at: Date;
    webxpress_location_cd: String;
    contract_city: string;
    customer_contacts: CustomerContacts[];
    state_location_mappings_id: number;
    branch_cd: string;
    ops_executive_id: number;
    ops_executive_name: string;
    dispatch_executive_id: number;
    dispatch_executive_name: string;
    arrival_executive_id: number;
    arrival_executive_name: string;
    billing_executive_id: number;
    billing_executive_name: string;
    tracking_executive_id: number;
    tracking_executive_name: string;
    pricing_executive_id: number;
    pricing_executive_name: string;
    constructor(json? : any) {
      this.fill(json);
    }
    static toArray(jsons : any[]) : CustomerAddresses[] {
        let customerAddresses : CustomerAddresses[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                customerAddresses.push(new CustomerAddresses(json));
            }
        }
        return customerAddresses;
    }
    fill(json: any){
      if (json != null) {
        if(json.id){
          this.id = json.id;
        }
        this.street = json.street;
        this.map_locality_street = json.map_locality_street;
        this.vicinity = json.vicinity;
        this.place_name = json.place_name;
        this.place_id = json.place_id;
        this.city = json.city;
        this.state = json.state;
        this.pincode = json.pincode;
        this.location_type_cd = json.location_type_cd;
        this.location_label = json.location_label;
        this.customers_id = json.customers_id;
        this.goods_flow_cd = json.goods_flow_cd;
        this.is_billing_address = json.is_billing_address;
        this.goods_type_cd = json.goods_type_cd;
        this.products_list = json.products_list;
        this.location_lat = json.location_lat;
        this.location_long = json.location_long;
        this.contract_city = json.contract_city;
        safeDate(this, json,  'deleted_at');

        this.webxpress_location_cd = json.webxpress_location_cd;
        if (json.customer_contact_addresses != null) {
          this.customer_contacts = CustomerContacts.toArray(json.customer_contacts);
        }
        this.branch_cd = json.branch_cd;
        this.state_location_mappings_id = json.state_location_mappings_id;
        this.ops_executive_id = json.ops_executive_id;
        this.ops_executive_name = json.ops_executive_name;
        this.dispatch_executive_id = json.dispatch_executive_id;
        this.dispatch_executive_name = json.dispatch_executive_name;
        this.arrival_executive_id = json.arrival_executive_id;
        this.arrival_executive_name = json.arrival_executive_name;
        this.dispatch_executive_id = json.dispatch_executive_id;
        this.dispatch_executive_name = json.dispatch_executive_name;
        this.billing_executive_id = json.billing_executive_id;
        this.billing_executive_name = json.billing_executive_name;
        this.tracking_executive_id = json.tracking_executive_id;
        this.tracking_executive_name = json.tracking_executive_name;
        this.pricing_executive_id = json.pricing_executive_id;
        this.pricing_executive_name = json.pricing_executive_name;
      }
    }
}
export const customerAddressesFormControls = {
  id: ['', []],
  street: ['', [Validators.required, Validators.minLength(3)]],
  map_locality_street:['', [Validators.required]],
  vicinity: ['', []],
  place_name: ['', []],
  place_id: ['', []],
  city: ['', [Validators.required, Validators.minLength(3)]],
  state: ['', [Validators.required, Validators.minLength(2)]],
  pincode: ['', [Validators.required, Validators.min(10000), Validators.max(999999)]],
  location_type_cd: ['', [Validators.required]],
  location_label: ['', [Validators.required]],
  goods_flow_cd: ['', []],
  is_billing_address: ['', []],
  goods_type_cd: ['BOTH', []],
  products_list: ['', []],
  destinations: ['', []],
  location_lat: ['', []],
  location_long: ['', []],
  customers_id: ['', []],
  webxpress_location_cd: ['', []],

  gstn_no:['',[Validators.pattern(gst_pattern)]],
  contract_city: ['', []],

  branch_cd: ['', []],
  state_location_mappings_id: ['', []],
  ops_executive_id: ['', []],
  ops_executive_name: ['', []],
  pricing_executive_id: ['', []],
  pricing_executive_name: ['', []],
  dispatch_executive_name: ['', []],
  dispatch_executive_id: ['', []],
  tracking_executive_id: ['', []],
  tracking_executive_name: ['', []],
  billing_executive_id: ['', []],
  billing_executive_name: ['', []],
  arrival_executive_id: ['', []],
  arrival_executive_name: ['', []],
};
