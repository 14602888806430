import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class EntityService {
  entityName = 'payments';
  constructor(protected serviceHttp:HttpService) { }

  getList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse(this.entityName + '/page', 'POST', 0, data);
  }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel(this.entityName + '/exportList', 'POST', 0, data);
  }
  search(data ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse(this.entityName + '/search', 'POST', 0, data);
  }
  lookupEntity(data: any ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse(this.entityName + '/lookup'+ this.entityName, 'POST', 0, data);
  }
  getEntityById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse(this.entityName + '/'+ id, 'GET');
  }
  addEntity(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse(this.entityName, 'POST', 0, data);
  }
  updateEntity(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse(this.entityName + '/' + data.id, 'PUT', 0, data);
  }
}
