import { Bookings } from '../bookings/bookings';
import { ConsignmentItems } from '../consignmentItems/consignmentItems';
import { Trucks } from '../trucks/trucks';
import { Drivers } from '../drivers/drivers';
import { ConsignmentTrips } from '../consignmentTrips/consignmentTrips';
import { Validators } from '@angular/forms';
import { InvoiceLines } from '../invoiceLines/invoiceLines';
import { ConsignmentStatusTrackings } from '../consignmentStatusTrackings/consignmentStatusTrackings';
import { getSafeDate, safeDate } from '../../core/globalfunctions';
import { Observable, of } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
export class ConsignmentNotes {
  static consignmentStatuses = [
    'INCOMPLETE',
    'BOOKED',
    'PICKED UP',
    'IN TRANSIT',
    // 'AT HUB',
    'AT DELIVERY LOCATION',
    'DELIVERED',
    'POD GENERATED',
    'ePOD UPLOADED',
    'HARD POD RECEIVED',
    'POD REVIEWED',
    'BILL GENERATED',
    // 'BILL SUBMITTED',
    'BILLED',
    'PAYMENT RECEIVED',
    'MR COMPLETE',
    'CANCELLED'
  ];
  static consignmentBillingStatuses = [
    'UNBILLED',
    'BILLED',
    'PAYMENT RECEIVED',
    'CANCELLED',
  ];
  static consignmentDeliveryCondition = [
    'IN GOOD CONDITION',
    'PARTIALLY DAMAGED',
    'ITEMS MISSING',
    // 'CANCELLED',
  ];
  static cancelReasons = [
    'CUSTOMER CANCELLED',
    'VEHICLE UNAVAILABLE',
    'TEST TRIP',
  ];
  id: number;
  bookings_id: number;
  consignment_no: string;
  consignment_dt: Date;
  consignee_name: string;
  consignee_street: string;
  consignee_city: string;
  consignee_state_cd: string;
  consignee_pincode: string;
  consignee_mobile_no: string;
  consignee_phone_no: string;
  consignor_name: string;
  consignor_street: string;
  consignor_city: string;
  consignor_state_cd: string;
  consignor_pincode: string;
  consignor_mobile_no: string;
  consignor_phone_no: string;
  consignment_goods: string;
  consignment_insured_by: string;
  consignment_risk: string;
  insurance_provider: string;
  insurance_poilicy_no: string;
  insurance_dt: Date;
  insurance_validity: string;
  purchase_order_no: string;
  invoice_value: number;
  invoice_no: string;
  invoice_dt: Date;
  consignment_gross_wt: string;
  consignment_tare_wt: string;
  consignment_pay_load: string;
  weight_unit: string;
  freight_amt: number;
  handling_amt: number;
  octroi_amt: number;
  misc_charges_amt: number;
  surcharge_amt: number;
  total_amt: number;
  advance_amt: number;
  less_others_amt: number;
  net_amt: number;
  webxpress_billing_party_code: string;
  payment_basis_cd: string;
  billing_branch_cd: string;
  collection_branch_cd: string;
  consignee_gstn: string;
  consignor_gstn: string;
  tax_paid_by: string;
  signed_by: string;
  webxpress_consignor_code: string;
  webxpress_consignee_code: string;
  container_no: string;
  seal_no: string;
  insured_by_cd: string;
  risk_cd: string;
  delivery_date: Date;
  pod_remarks: string;
  unloading_charges: number;
  recieved_by: string;
  consignment_status_cd: string;
  delivery_time: string;
  delivery_condition_cd: string;
  pod_scan: string;
  pod_scan_2: string;
  damage_deduction: string;
  bookings: Bookings;
  consignment_trips: ConsignmentTrips[];
  consignment_items: ConsignmentItems[];
  consignment_scan: string;
  trips_status_cd: string;
  misc1_scan: string;
  misc2_scan: string;
  misc3_scan: string;
  trucks = new Trucks();
  drivers = new Drivers();
  detention_rate: number;
  detention_type: string;
  insured_by: string;
  risk: string;
  vide_me_no: string;
  date: string;
  billed_on: string;
  doc_recieved: string;
  vehicle_reported_date: string;
  vehicle_reported_time: string;
  vehicle_dispatched_date: string;
  vehicle_dispatched_time: string;
  remarks: string;
  for_company: string;
  reciept_condition: string;
  bill_to_party_id: number;
  bill_to_party_city: string;
  pricing_executive_id: number;
  dispatch_executive_id: number;
  tracking_executive_id: number;
  arrival_executive_id: number;
  pricing_executive_name: string;
  dispatch_executive_name: string;
  tracking_executive_name: string;
  arrival_executive_name: string;
  ops_executive_id: number;
  ops_executive_name: string;
  finance_executive_id: number;
  finance_executive_name: string;
  eway_bill_scan: string;
  eway_bill_no: string;
  eway_valid_upto_dt: Date;
  eway_bills: any;
  eway_hsn_cd: string;
  is_skip_eway = false;

  invoice_scan: string;
  gate_in_scan: string;
  empty_letter_scan: string;
  truck_scan: string;
  business_type_cd: string;
  booking_type_cd: string;
  service_type_cd: string;
  movement_type_cd: string;
  cancel_reason_cd: string;
  billing_status_cd: string;
  invoice_lines: InvoiceLines[];
  loading_charges: number;
  detention_days: number;
  detentions_amt_per_day: number;
  detention_charges: number;
  consignment_status_trackings: ConsignmentStatusTrackings[];


  no_of_boxes: number;
  customers_id: number;

  physical_pod_dt: Date;
  epod_dt: Date;
  //TODO: move to consignment trips.
  trucks_selfie_scan: string;
  trucks_front_scan: string;
  trucks_back_scan: string;
  trucks_side_scan: string;
  trucks_tyre_scan: string;
  trucks_nib_scan: string;
  trucks_tarpoline_scan: string;

  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): ConsignmentNotes[] {
    let consignmentNotes: ConsignmentNotes[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        consignmentNotes.push(new ConsignmentNotes(json));
      }
    }
    return consignmentNotes;
  }
  static getStatuses(business_type_cd = '', movement_type_cd = '') {
    return ConsignmentNotes.consignmentStatuses;
  }
  static nextStatus(status_cd: string, business_type_cd = '', movement_type_cd = '') {
    const allStatuses = ConsignmentNotes.getStatuses(business_type_cd, movement_type_cd);
    const statusIndex = allStatuses.indexOf(status_cd);
    let result = '';
    if (statusIndex !== -1 && statusIndex < (allStatuses.length - 1)) {
       result = allStatuses[statusIndex + 1];
    }
    else {
    //  console.log('next status empty of ' + status_cd);
    }
    // console.log('next status is ' + result);
    return result;
  }
  static statusesAllIndex = {
    'INCOMPLETE': 'COMPLETE LR DOCS',
    // 'BOOKED': 'DONE',
    // 'PICKED UP': 'DONE',
    // 'IN TRANSIT': 'DONE',
    // 'AT DELIVERY LOCATION': 'MARK DELIVERED',
    'DELIVERED': 'MARK POD GENERATED',
    'POD GENERATED': 'UPLOAD ePOD',
    // 'ePOD UPLOADED': 'MARK RECEIVED POD',
    'ePOD UPLOADED': 'REVIEW POD',
    //'HARD POD RECEIVED': 'GENERATE BILL',
    'POD REVIEWED': 'GENERATE BILL',
    // 'BILL GENERATED': 'SUBMIT BILL',
    // 'BILLED': 'MARK PAYMENT RECEIVED',
    // 'PAYMENT RECEIVED': ,
    // 'MR COMPLETE': 'DONE',
  };
  static commandsAllIndex = [
    'COMPLETE LR DOCS',
    // 'BOOKED': 'DONE',
    // 'PICKED UP': 'DONE',
    // 'IN TRANSIT': 'DONE',
    // 'AT DELIVERY LOCATION': 'MARK DELIVERED',
    'MARK POD GENERATED',
    'UPLOAD ePOD',
    'MARK RECEIVED POD',
    'REVIEW POD',
    'GENERATE BILL',
    // 'BILL GENERATED': 'SUBMIT BILL',
    // 'BILLED': 'MARK PAYMENT RECEIVED',
    // 'PAYMENT RECEIVED': ,
    // 'MR COMPLETE': 'DONE',
  ];
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.customers_id = json.customers_id;
      this.bookings_id = json.bookings_id;
      this.consignment_no = json.consignment_no;
      this.consignee_name = json.consignee_name;
      this.consignee_street = json.consignee_street;
      this.consignee_city = json.consignee_city;
      this.consignee_state_cd = json.consignee_state_cd;
      this.consignee_pincode = json.consignee_pincode;
      this.consignee_mobile_no = json.consignee_mobile_no;
      this.consignee_phone_no = json.consignee_phone_no;
      this.consignor_name = json.consignor_name;
      this.consignor_street = json.consignor_street;
      this.consignor_city = json.consignor_city;
      this.consignor_state_cd = json.consignor_state_cd;
      this.consignor_pincode = json.consignor_pincode;
      this.consignor_mobile_no = json.consignor_mobile_no;
      this.consignor_phone_no = json.consignor_phone_no;
      this.consignment_goods = json.consignment_goods;
      this.consignment_insured_by = json.consignment_insured_by;
      this.consignment_risk = json.consignment_risk;
      this.insurance_provider = json.insurance_provider;
      this.insurance_poilicy_no = json.insurance_poilicy_no;
      safeDate(this, json,  'insurance_dt');
      safeDate(this, json,  'consignment_dt');

      this.insurance_validity = json.insurance_validity;
      this.purchase_order_no = json.purchase_order_no;
      this.invoice_value = json.invoice_value;
      this.invoice_no = json.invoice_no;
      safeDate(this, json,  'invoice_dt');

      safeDate(this, json, 'epod_dt');
      safeDate(this, json, 'physical_pod_dt');
      this.consignment_gross_wt = json.consignment_gross_wt;
      this.consignment_tare_wt = json.consignment_tare_wt;
      this.consignment_pay_load = json.consignment_pay_load;
      this.weight_unit = json.weight_unit;
      this.freight_amt = json.freight_amt;
      this.handling_amt = json.handling_amt;
      this.octroi_amt = json.octroi_amt;
      this.misc_charges_amt = json.misc_charges_amt;
      this.surcharge_amt = json.surcharge_amt;
      this.total_amt = json.total_amt;
      this.advance_amt = json.advance_amt;
      this.less_others_amt = json.less_others_amt;
      this.net_amt = json.net_amt;
      this.webxpress_billing_party_code = json.webxpress_billing_party_code;
      this.payment_basis_cd = json.payment_basis_cd;
      this.billing_branch_cd = json.billing_branch_cd;
      this.collection_branch_cd = json.collection_branch_cd;
      this.consignee_gstn = json.consignee_gstn;
      this.consignor_gstn = json.consignor_gstn;
      this.tax_paid_by = json.tax_paid_by;
      this.signed_by = json.signed_by;
      this.webxpress_consignor_code = json.webxpress_consignor_code;
      this.webxpress_consignee_code = json.webxpress_consignee_code;
      this.container_no = json.container_no;
      this.seal_no = json.seal_no;
      this.insured_by_cd = json.insured_by_cd;
      this.consignment_scan = json.consignment_scan;
      this.misc1_scan = json.misc1_scan;
      this.misc2_scan = json.misc2_scan;
      this.misc3_scan = json.misc3_scan;
      this.risk_cd = json.risk_cd;
      safeDate(this, json,  'delivery_date');
      this.pod_remarks = json.pod_remarks;
      this.unloading_charges = json.unloading_charges;
      this.recieved_by = json.recieved_by;
      this.consignment_status_cd = json.consignment_status_cd;
      this.delivery_time = json.delivery_time;
      this.pod_scan = json.pod_scan;
      this.pod_scan_2 = json.pod_scan_2;
      this.damage_deduction = json.damage_deduction;
      if (json.bookings != null) {
        this.bookings = new Bookings(json.bookings);
      }
      if (json.consignment_trips != null) {
        this.consignment_trips = ConsignmentTrips.toArray(
          json.consignment_trips
        );
      }

      this.trips_status_cd = json.trips_status_cd;
      this.bill_to_party_id = json.bill_to_party_id;
      this.bill_to_party_city = json.bill_to_party_city;
      this.billing_branch_cd = json.billing_branch_cd;
      this.pricing_executive_id = json.pricing_executive_id;
      this.dispatch_executive_id = json.dispatch_executive_id;
      this.tracking_executive_id = json.tracking_executive_id;
      this.arrival_executive_id = json.arrival_executive_id;
      this.pricing_executive_name = json.pricing_executive_name;
      this.dispatch_executive_name = json.dispatch_executive_name;
      this.tracking_executive_name = json.tracking_executive_name;
      this.arrival_executive_name = json.arrival_executive_name;
      this.ops_executive_id = json.ops_executive_id;
      this.ops_executive_name = json.ops_executive_name;
      this.finance_executive_id = json.finance_executive_id;
      this.finance_executive_name = json.finance_executive_name;
      this.empty_letter_scan = json.empty_letter_scan;

      this.gate_in_scan = json.gate_in_scan;
      this.invoice_scan = json.invoice_scan;
      this.truck_scan = json.truck_scan;
      this.business_type_cd = json.business_type_cd;
      this.booking_type_cd = json.booking_type_cd;
      this.service_type_cd = json.service_type_cd;
      this.movement_type_cd = json.movement_type_cd;
      this.cancel_reason_cd = json.cancel_reason_cd;
      this.billing_status_cd = json.billing_status_cd;
      this.delivery_condition_cd = json.delivery_condition_cd;
      if(json.invoice_lines != null) {
        this.invoice_lines = InvoiceLines.toArray(json.invoice_lines);
      }
      this.loading_charges = json.loading_charges;
      this.detention_days = json.detention_days;
      this.detention_charges = json.detention_charges;
      this.detentions_amt_per_day = json.detention_amt_per_day;
      if(json.consignment_status_trackings != null) {
        this.consignment_status_trackings = ConsignmentStatusTrackings.toArray(json.consignment_status_trackings);
      }

      this.no_of_boxes = json.no_of_boxes;
      this.remarks = json.remarks;

      //TODO: move to consignment trips
      this.trucks_back_scan = json.trucks_back_scan;
      this.trucks_front_scan = json.trucks_front_scan;
      this.trucks_side_scan = json.trucks_side_scan;
      this.trucks_selfie_scan = json.trucks_selfie_scan;
      this.trucks_tyre_scan = json.trucks_tyre_scan;
      this.trucks_nib_scan = json.trucks_nib_scan;
      this.trucks_tarpoline_scan = json.trucks_tarpoline_scan;


      if(json.is_skip_eway) {
        this.is_skip_eway = json.is_skip_eway;
      }
      this.eway_bills = json.eway_bills;
      this.eway_bill_scan = json.eway_bill_scan;
      this.eway_bill_no = json.eway_bill_no;
      safeDate(this, json,  'eway_valid_upto_dt');
      if((!this.eway_valid_upto_dt || this.eway_valid_upto_dt == null) && this.eway_bills ) {
        if(this.eway_bills[0]) {
          this.eway_valid_upto_dt = getSafeDate(this.eway_bills[0], 'validUpto');
        }
      }
      this.eway_hsn_cd = json.eway_hsn_cd;

      if (json.consignment_items != null) {
        this.consignment_items = ConsignmentItems.toArray(
          json.consignment_items
        );
      }

    }
  }
  getDateForStatus(aStatus) {
    let eDateStr: Date;
    if (this.consignment_status_trackings  && this.consignment_status_trackings.length > 0) {
      this.consignment_status_trackings.forEach(element => {
        if (element.consignment_status_cd === aStatus) {
          //"ePOD UPLOADED"
          eDateStr = element.status_at;
        }
      });
    }
    return eDateStr;
  }
  getActualePODDate() {
    const aDate = this.getDateForStatus('ePOD UPLOADED');
    return aDate ? aDate.toLocaleDateString() : '';
  }
  getActualIntransitDateAsString() {
    const aDate = this.getDateForStatus('IN TRANSIT');
    return aDate ? aDate.toLocaleDateString() : '';
  }
  getActualIntransitDate() {
    const aDate = this.getDateForStatus('IN TRANSIT');
    return aDate ;
  }
  getBillGeneratedDate( ) {
    const aDate = this.getDateForStatus('BILL GENERATED');
    return aDate ? aDate.toLocaleDateString() : '';
  }
  getBillSubmittedDate( ) {
    let aDate = this.getDateForStatus('BILL SUBMITTED');
    if(!aDate) {
      aDate = this.getDateForStatus('BILLED');
    }
    return aDate ? aDate.toLocaleDateString() : '';
  }
  getDayRatioBillToTransit () {
    const transitDays = this.getActualTransitDays();
    if(transitDays) {
      return Math.floor(this.getDaysToBill() / transitDays);
    }
  }
  getDaysToBill() {
    const inTransitDate = this.getDateForStatus('IN TRANSIT');
    let billDate = this.getDateForStatus('BILL SUBMITTED');
    if(!billDate) {
      billDate = (new Date());
    }
    if(inTransitDate) {
      return Math.ceil(  ( billDate.getTime() - inTransitDate.getTime() ) / (1000 * 60 * 60 * 24) ) ;
    }

  }
  getDaysToHard() {

    let podReceivedDate = this.getDateForStatus('HARD POD RECEIVED');
    if(!podReceivedDate) {
      podReceivedDate = (new Date());
    }
    if(this.consignment_trips && this.consignment_trips[0] && this.consignment_trips[0].trip){
      const arrival_dt = this.consignment_trips[0].trip.actual_arrival_dt;
      if(arrival_dt) {
        return Math.ceil(  ( podReceivedDate.getTime() - arrival_dt.getTime() ) / (1000 * 60 * 60 * 24) ) ;
      }
    }
    const inTransitDate = this.getDateForStatus('DELIVERED');
    if(inTransitDate) {
      return Math.ceil(  ( podReceivedDate.getTime() - inTransitDate.getTime() ) / (1000 * 60 * 60 * 24) ) ;
    }

  }
  getDayRatioHardToTransit () {
    const transitDays = this.getActualTransitDays();
    if(transitDays) {
      return Math.floor(this.getDaysToHard() / transitDays);
    }
  }
  getLastStatus() {
    if (this.consignment_status_trackings  &&  this.consignment_status_trackings.length > 0) {
      return this.consignment_status_trackings[this.consignment_status_trackings.length - 1];
    }
  }
  getEstimatedTransitDays() {
    if (this.consignment_trips && this.consignment_trips[0] && this.consignment_trips[0].trip.estimated_transit_time) {
      const transitDays = Math.ceil(
        this.consignment_trips[0].trip.estimated_transit_time / 24
      );
      return transitDays;
    }
  }
  getActualTransitDaysCeil() {
    return Math.ceil(this.getActualTransitDays());
  }
  getActualTransitDays() {
    if(this.consignment_trips && this.consignment_trips[0] && this.consignment_trips[0].trip){
      return this.consignment_trips[0].trip.actual_transit_time / 24;

    }
    if(this.delivery_date) {
      const inTransitDate = this.getDateForStatus('IN TRANSIT');
      if(inTransitDate) {
        let transitDiff = Math.ceil(  ( this.delivery_date.getTime()
        - inTransitDate.getTime() ) / (1000 * 60 * 60 * 24) );
        if(transitDiff == 0) {
          transitDiff = 1;
        }
        return transitDiff;
      }

    }
  }
  getEstimatedHardPODDate() {
    let transitDays = 0;
    if(this.consignment_trips && this.consignment_trips[0]) {
      transitDays = Math.ceil(
        this.consignment_trips[0].trip.estimated_transit_time / 24
      );
    }

    let deliveryDate = this.getActualIntransitDate();
    if (this.delivery_date) {
      transitDays = this.getActualTransitDays();
      deliveryDate = new Date((this.delivery_date.valueOf()));
    }
    if(deliveryDate){
      deliveryDate.setDate(deliveryDate.getDate() + 3 * transitDays);
      return deliveryDate.toLocaleDateString();
    }


  }
  getActualHardPODDate() {
    const aDate = this.getDateForStatus('HARD POD RECEIVED');
    return aDate ? aDate.toLocaleDateString() : '';
  }
}

export const consignmentNotesFormControls = {
  id: ['', []],
  bookings_id: ['', [Validators.required]],
  customers_id: ['', []],
  consignment_no: ['', [Validators.required]],
  consignment_dt: ['', []],
  consignee_name: ['', []],
  consignee_street: ['', []],
  consignee_city: ['', []],
  consignee_state_cd: ['', []],
  consignee_pincode: ['', []],
  consignee_mobile_no: [
    '',
    [Validators.min(1000000000), Validators.max(9999999999)]
  ],
  consignee_phone_no: ['', []],
  consignor_name: ['', []],
  consignor_street: ['', []],
  consignor_city: ['', []],
  consignor_state_cd: ['', []],
  consignor_pincode: ['', []],
  consignor_mobile_no: [
    '',
    [Validators.min(1000000000), Validators.max(9999999999)]
  ],
  consignor_phone_no: ['', []],
  consignment_goods: ['', []],
  consignment_insured_by: ['', []],
  consignment_risk: ['', []],
  risk_cd: ['', []],
  insured_by_cd: ['', []],
  insurance_provider: ['', []],
  insurance_poilicy_no: ['', []],
  insurance_dt: ['', []],
  insurance_validity: ['', []],
  purchase_order_no: ['', []],
  invoice_value: ['', [Validators.required,  Validators.min(1)]],
  invoice_no: ['', [Validators.required]],
  invoice_dt: ['', [Validators.required]],
  consignment_gross_wt: ['', []],
  consignment_tare_wt: ['', []],
  consignment_pay_load: ['', [Validators.required,  Validators.min(1)]],
  weight_unit: ['', []],
  freight_amt: ['', []],
  handling_amt: ['', []],
  octroi_amt: ['', []],
  misc_charges_amt: ['', []],
  surcharge_amt: ['', []],
  total_amt: ['', []],
  advance_amt: ['', []],
  less_others_amt: ['', []],
  net_amt: ['', []],
  webxpress_billing_party_code: ['', []],
  payment_basis_cd: ['', []],
  billing_branch_cd: ['', []],
  collection_branch_cd: ['', []],
  consignee_gstn: ['', []],
  consignor_gstn: ['', []],
  tax_paid_by: ['', []],
  signed_by: ['', []],
  webxpress_consignor_code: ['', []],
  webxpress_consignee_code: ['', []],
  container_no: ['', []],
  seal_no: ['', []],
  delivery_date: ['', []],
  pod_remarks: ['', []],
  unloading_charges: ['', []],
  recieved_by: ['', []],
  consignment_status_cd: ['', [Validators.required]],
  delivery_time: ['', []],
  pod_scan: ['', []],
  pod_scan_2: ['', []],
  consignment_scan: ['', [Validators.required]],
  misc1_scan: ['', []],
  misc2_scan: ['', []],
  misc3_scan: ['', []],
  damage_deduction: ['', []],
  trips_id: ['', []],
  searchCustomer: ['', []],
  trips_status_cd: ['', []],
  consignor_location_code: ['', []],
  consignee_location_code: ['', []],
  bill_to_party_id: ['', []],
  bill_to_party_city: ['', []],
  pricing_executive_id: ['', []],
  dispatch_executive_id: ['', []],
  tracking_executive_id: ['', []],
  arrival_executive_id: ['', []],
  pricing_executive_name: ['', []],
  dispatch_executive_name: ['', []],
  tracking_executive_name: ['', []],
  arrival_executive_name: ['', []],
  ops_executive_id: ['', []],
  ops_executive_name: ['', []],
  finance_executive_id: ['', []],
  finance_executive_name: ['', []],
  invoice_scan: ['', []],
  gate_in_scan: ['', []],
  empty_letter_scan: ['', []],
  truck_scan: ['', []],
  business_type_scan: ['', []],
  booking_type_scan: ['', []],
  service_type_scan: ['', []],
  movement_type_cd: ['', []],
  cancel_reason_cd: ['', []],
  billing_status_cd: ['', []],
  delivery_condition_cd: ['', []],
  loading_charges: ['', []],
  detention_charges: ['', []],
  detention_days: ['', []],
  detention_amt_per_day: ['', []],

  remarks:  ['', []],
  no_of_boxes: ['', [Validators.required, Validators.min(1)]],
  physical_pod_dt: ['', []],
  epod_dt: ['', []],
  trucks_selfie_scan: ['', []],
  trucks_front_scan: ['', []],
  trucks_back_scan: ['', []],
  trucks_side_scan: ['', []],
  trucks_tyre_scan: ['', []],
  trucks_nib_scan: ['', []],
  trucks_tarpoline_scan: ['', []],

  is_skip_eway: [false, []],
  eway_bill_scan: ['', []],
  eway_bill_no: ['', [Validators.required, Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]],
  eway_valid_upto_dt: ['', []],
  eway_hsn_cd: ['', []],
};
export class CNoteDataSource extends DataSource<any> {
  data: any[];
  constructor(aData?: any[]) {
    super();
    this.data = aData;
  }
  connect(): Observable<any[]> {
    return of(this.data);
  }
  getIndex(id) {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id == id) {
        return index;
      }
    }
    return -1;
  }
  remove(id) {
    const itemIndex = this.getIndex(id);
 //  console.log('found ' + id + ' at ' + itemIndex);
    this.data = this.data.splice(itemIndex, 1);
 //  console.log('removed');
  }
  replace(id, aData) {
    const anIndex = this.getIndex(id);
    if (anIndex != -1) {
      this.data[anIndex] = aData;
    }
    else {
      this.data.push(aData);
    }
  }
  disconnect() { }
}
