import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class DriverRatingsService {
  constructor(private serviceHttp:HttpService) { }
  getList(pageNo = 1
    ,driverRatings_customers_id?: any
    ,driverRatings_trips_id?: any
    ,driverRatings_drivers_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('driverRatings/page', 'POST', 0, {page: pageNo
        ,customers_id: driverRatings_customers_id
        ,trips_id: driverRatings_trips_id
        ,drivers_id: driverRatings_drivers_id
      });
  }
  search(str:string, driverRatings_customers_id?: any,driverRatings_trips_id?: any,
    driverRatings_drivers_id?: any ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('driverRatings/search', 'POST', 0, {search:str, customers_id: driverRatings_customers_id
        ,trips_id: driverRatings_trips_id
        ,drivers_id: driverRatings_drivers_id
    });
  }
  getDriverRatingsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('driverRatings/'+ id, 'GET');
  }
  addDriverRatings(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('driverRatings', 'POST', 0, data);
  }
  updateDriverRatings(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('driverRatings/' + data.id, 'PUT', 0, data);
  }
}
