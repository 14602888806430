import {ConsignmentNotes} from '../consignmentNotes/consignmentNotes';
import {Trips} from '../trips/trips';
import { safeDate } from '../../core/globalfunctions';
export class ConsignmentTrips {
    id : number;
    trips_id : number;
    consignment_notes_id : number;
    deleted_at: Date;
    created_at: Date;
    updated_at: Date;
    created_by: String;
    updated_by: String;
    consignment_note : ConsignmentNotes;
    trip : Trips;
    trucks_selfie_scan: string;
    trucks_front_scan: string;
    trucks_back_scan: string;
    trucks_side_scan: string;
    trucks_tyre_scan: string;
    trucks_nib_scan: string;
    trucks_tarpoline_scan: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : ConsignmentTrips[] {
        let consignmentTrips : ConsignmentTrips[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                consignmentTrips.push(new ConsignmentTrips(json));
            }
        }
        return consignmentTrips;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.trips_id = json.trips_id;
            this.consignment_notes_id = json.consignment_notes_id;
            safeDate(this, json,  'deleted_at');
            safeDate(this, json,  'created_at');
            safeDate(this, json,  'updated_at');

            this.created_by = json.created_by;
            this.updated_by = json.updated_by;
            if (json.consignment_note != null) {
                this.consignment_note = new ConsignmentNotes(json.consignment_note);
            }
            if (json.trip != null) {
                this.trip = new Trips(json.trip);
            }
            //TODO: move to consignment trips
            this.trucks_back_scan = json.trucks_back_scan;
            this.trucks_front_scan = json.trucks_front_scan;
            this.trucks_side_scan = json.trucks_side_scan;
            this.trucks_selfie_scan = json.trucks_selfie_scan;
            this.trucks_tyre_scan = json.trucks_tyre_scan;
            this.trucks_nib_scan = json.trucks_nib_scan;
            this.trucks_tarpoline_scan = json.trucks_tarpoline_scan;
        }
    }
}
