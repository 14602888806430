import { switchMap, distinctUntilChanged, filter, map, startWith, count } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, TemplateRef, Input, EventEmitter, Output, HostListener, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  AbstractControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsignmentNotesService } from '../../consignmentNotes/services/consignmentNotes.service';
import { Bookings } from '../../bookings/bookings';
import { BookingsService } from '../../bookings/services/bookings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsignmentItems, consignmentItemsFormControls } from '../../consignmentItems/consignmentItems';
import { } from 'rxjs/operators';
import { CustomersService } from '../../customers/services/customers.service';
import { Customers } from '../../customers/customers';
import { Trips } from '../../trips/trips';
import { ConsignmentTrips } from '../../consignmentTrips/consignmentTrips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../layout/dialogs/services/dialog.service';
import * as appGlobals from '../../../core/globalfunctions';
import { TripsService } from '../../trips/services/trips.service';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { AppSettings } from '../../../core/config';
import { Location } from '@angular/common';
import { ConsignmentNotes, consignmentNotesFormControls, CNoteDataSource } from '../../consignmentNotes/consignmentNotes';
import { ConsignmentTripTrackingsService } from '../../consignmentTripTrackings/services/consignmentTripTrackings.service';
import { style, trigger, state, animate, transition } from '@angular/animations';
import { StorageService } from '../../../core/services/storage.service';
import { consignmentTripTrackingsFormControls } from '../../consignmentTripTrackings/consignmentTripTrackings';
import { attachTruckDocumentsFormControls, TruckHireChallans } from '../../truckHireChallans/truckHireChallans';
import { Partners } from '../../partners/partners';
import { PartnersService } from '../../partners/services/partners.service';
import { DataSource } from '@angular/cdk/table';
import { Observable, of, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-consignment-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  providers: [
    ConsignmentNotesService,
    BookingsService,
    CustomersService,
    DialogService,
    TripsService,
    FileTransferService,
    ConsignmentTripTrackingsService,
    StorageService,
    PartnersService
  ],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class ConsignmentEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input('bookings_id')
  public bookings_id: number;

  @Input('customers_id')
  public customers_id: number;

  @Input('consignment_notes_id')
  public consignment_notes_id: number;

  @Input('consignmentNotes')
  public consignmentNotes = new ConsignmentNotes();

  @Input('trips_id')
  public trips_id: number;

  @Input('trips')
  public trips = new Trips();

  @Input('tasks_name')
  public tasks_name: number;

  @Input('modalRef')
  public modalRef: BsModalRef;

  @Input('contentOnly')
  public contentOnly = false;

  @Input('needEmitCompletion')
  public needEmitCompletion = false;

  @Input('isExternalCall')
  public isExternalCall = false;

  @Input('requestedAction')
  public requestedAction: string;

  @Input('hasTabs')
  public hasTabs: false;

  @Input('hasSummary')
  public hasSummary: false;

  @Input('hasStatusGrapics')
  public hasStatusGrapics: false;

  @Output() setConsignmentNotes: EventEmitter<ConsignmentNotes> = new EventEmitter<ConsignmentNotes>();
  @Output() onComplete: EventEmitter<boolean> = new EventEmitter<false>();


  consignmentItemTemplate: TemplateRef<any>;
  @ViewChild('addConsignment', { static: true }) addConsignment: TemplateRef<any>;
  @ViewChild('itemDetails', { static: true }) itemDetails: TemplateRef<any>;
  @ViewChild('cNoteDetails', { static: true }) cNoteDetails: TemplateRef<any>;
  @ViewChild('updateStatus', { static: true }) updateStatus: TemplateRef<any>;
  @ViewChild('truckDocuments', { static: true }) truckDocuments: TemplateRef<any>;
  @ViewChild('cNoteList', { static: true }) cNoteList: TemplateRef<any>;
  @ViewChild('contentTemplate', { static: true }) contentTemplate: TemplateRef<any>; // = this.updateStatus; //: TemplateRef<any>;
  @ViewChild('detailsTopTemplate', { static: true }) detailsTopTemplate: TemplateRef<any>;
  @ViewChild('tripDetailsTopTabs', { static: true }) tripDetailsTopTabs: TemplateRef<any>;
  @ViewChild('cNoteDetailsTopTabs', { static: true }) cNoteDetailsTopTabs: TemplateRef<any>;


  // bookings = new Bookings();
  // selectedCustomer: Customers;
  selectedBooking: Bookings;
  // tripList = new Array<Trips>();
  // customerList = new Array<Customers>();
  // bookingList = new Array<Bookings>();
  consignmentItemList = new Array<ConsignmentItems>(); // ConsignmentItems[];
  consignment_trips = new ConsignmentTrips();

  consignmentEwayBillList = [];

  consignmentNotesList: ConsignmentNotes[];


  // containerType: string;
  // selectedTripValue: string;
  // selectedBookingValue: string;

  consignmentNotesFormGroup: FormGroup;
  consignmentItemsFormGroup: FormGroup;
  consignmentsEwayFormGroup: FormGroup;
  consignmentTripTrackingsFormGroup: FormGroup;
  sendConsignmentFormGroup: FormGroup;

  truckHireChallan: TruckHireChallans;
  partnerList: Partners[];
  attachTruckDocumentsFormGroup: FormGroup;


  isError: boolean = false;
  isLinear = true;
  errorMessages = new Array<string>();
  // consignmentScanUrl: String;
  imageUrl: String = AppSettings.IMAGE_ENDPOINT;

  selectedTabIndex = 0;

  // ewayBillNo: string;
  // showEwayBill = true;
  // tempEwayBill: string;

  onAdd = new EventEmitter();

  currentTemplate: TemplateRef<any>;
  usePreviewPanel = false;
  innerWidth: any;

  // no_of_consignment_items = 0;
  iIndex = 0;
  visiblityState = 'shown';

  next_status: string;
  next_plus_status: string;
  selectedDispatchActivity = 'MARK PAPER WORK DONE';

  currentPage = 1;

  isSingleIncompleteLR = false;
  cNoteListDataSource: CNoteDataSource;
  displayedColumns = [
    // 'cbox',
    'customer',
    'origin',
    'truck_type',
    // 'destination_city',
    'action',
  ];
  allModelRefs: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private consignmentNotesService: ConsignmentNotesService,
    private bookingsService: BookingsService,
    private customerService: CustomersService,
    private tripsService: TripsService,
    private _formBuilder: FormBuilder,
    private _snackBarService: MatSnackBar,
    private _dialogService: DialogService,
    private fileTransferService: FileTransferService,
    private modalService: BsModalService,
    private _location: Location,
    private consignmentTripTrackingsService: ConsignmentTripTrackingsService,
    private partnerService: PartnersService
  ) {
    // console.log('cnote edit constructor');
  }

  onChanges = new Subject<SimpleChanges>();
  ngOnInit() {

    // console.log('ngoninit');
    console.log(this.contentOnly);
    this.sendConsignmentFormGroup = this._formBuilder.group({
      id: ['', []],
      to_email: ['', [Validators.required, Validators.email]],
      to_name: ['', [Validators.required]],
    });

    this.detailsTopTemplate = this.cNoteDetailsTopTabs;
    // this.onChanges.subscribe((data: SimpleChanges) => {
    //   // only when inited
    //   // changes.prop contains the old and the new value...
    //   // console.log('ngonchanges');
    //   // console.log(data);
    //   this.consignmentNotes = new ConsignmentNotes();
    //   this.consignment_notes_id = null;
    //   this.trips = new Trips;
    //   this.trips_id = null;

    //   if (data.consignmentNotes || data.consignment_notes_id
    //     || data.trips_id || data.trips) {
    //     if (data.consignmentNotes) {
    //       this.consignmentNotes = data.consignmentNotes.currentValue;
    //     }
    //     if (data.consignment_notes_id) {
    //       this.consignment_notes_id = data.consignment_notes_id.currentValue;
    //     }
    //     if (data.trips) {
    //       this.trips = data.trips.currentValue;
    //     }
    //     if (data.trips_id) {
    //       this.trips_id = data.trips_id.currentValue;
    //     }
    //     // this.ngOnInit();
    //   }
    // });
    if (this.consignmentNotes && this.consignmentNotes.id) {
      // console.log('got consignment note id as input');
      this.consignment_notes_id = this.consignmentNotes.id;
      // this.prepareUpdateLr();
      this.selectedDispatchActivity = 'UPDATE LR';

      this.prepareLRForm();
      this.setConsignmentResponse(this.consignmentNotes);

    }
    else if (this.consignment_notes_id) {
      // console.log('got consignment id as input');
      // this.consignmentNotes.id = this.consignment_notes_id;
      this.prepareUpdateLr();
      // console.log('get response called');
      // this.getResponse(this.consignment_notes_id);
    }
    else if (this.trips_id) {
      // console.log('got trips id as input');
      this.detailsTopTemplate = this.tripDetailsTopTabs;
      this.getTripData(this.trips_id);
    }
    else if (this.trips && this.trips.id) {

      // console.log('got trips object as input');
      this.trips_id = this.trips.id;
      this.detailsTopTemplate = this.tripDetailsTopTabs;
      this.setDataFromTrip(this.trips);

    }
    else {
      this.route.params.subscribe(params => {
        // console.log('subscribed to route params ' + params);
        if (+params.tripId) {
          // console.log('params subscribe trips id');
          this.detailsTopTemplate = this.tripDetailsTopTabs;
          this.getTripData(+params.tripId);
        }
        if (params.id) {
          this.consignmentNotes.id = params.id;
          this.consignment_notes_id = this.consignmentNotes.id;
          this.selectedDispatchActivity = 'UPDATE LR';
          this.prepareUpdateLr();
          // console.log('get response called');
          // this.getResponse(this.consignmentNotes.id);
          return;
        }
      });
    }
    this.setTabsAndContent();


  }
  ngOnDestroy() {
    this.onChanges.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   // changes.prop contains the old and the new value...
  //  console.log('ngonchanges');
  //  console.log(changes);
  //   this.consignmentNotes = new ConsignmentNotes();
  //   this.consignment_notes_id = null;
  //   this.trips = new Trips;
  //   this.trips_id = null;

  //   if(changes.consignmentNotes || changes.consignment_notes_id
  //     || changes.trips_id || changes.trips){
  //     if(changes.consignmentNotes) {
  //       this.consignmentNotes = changes.consignmentNotes.currentValue;
  //     }
  //     if(changes.consignment_notes_id) {
  //       this.consignment_notes_id = changes.consignment_notes_id.currentValue;
  //     }
  //     if(changes.trips) {
  //       this.trips = changes.trips.currentValue;
  //     }
  //     if(changes.consignmentNotes) {
  //       this.trips_id = changes.trips_id.currentValue;
  //     }
  //     this.ngOnInit();
  //   }
  // }
  addNewConsignment() {
    this.consignmentNotes = new ConsignmentNotes();
    this.consignmentNotes.bookings_id = this.selectedBooking.id;
    this.consignmentNotes.customers_id = this.selectedBooking.customers_id;
    this.consignmentNotes.consignment_trips = [new ConsignmentTrips()];
    this.consignmentNotes.consignment_trips[0].trips_id = this.trips.id;
    this.consignmentNotes.consignment_status_cd = 'BOOKED';
    this.consignmentItemList = [];
    this.consignment_notes_id = null;
    this.selectedDispatchActivity = 'UPDATE LR';
    // this.ngOnInit();
    this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.consignmentNotesFormGroup.addControl(
      'consignment_items',
      this._formBuilder.array([])
    );
    this.getNewConsignmentItemForm();
    this.consignmentNotesFormGroup.patchValue(this.consignmentNotes);
    this.visiblityState = 'shown';
    // this.getResponse(this.consignment_notes_id);
    // this.setEwayBillLookup();
    this.setTabsAndContent();
    // this.ngOnInit();
  }
  prepareLRForm() {
    this.consignmentNotesFormGroup = this._formBuilder.group(consignmentNotesFormControls);
    this.consignmentNotesFormGroup.addControl(
      'consignment_items',
      this._formBuilder.array([])
    );
    this.getNewConsignmentItemForm();
  }
  prepareUpdateLr() {
    this.selectedDispatchActivity = 'UPDATE LR';
    // this.ngOnInit();
    this.prepareLRForm();
    this.getResponse(this.consignment_notes_id);

  }
  openUpdateLr(aData) {
    this.consignmentNotes = new ConsignmentNotes();
    this.consignment_notes_id = aData.id;
    this.prepareUpdateLr();
    //  console.log('get response called');
    this.getResponse(this.consignment_notes_id);
    // this.setEwayBillLookup();
    this.setTabsAndContent();
  }

  setTabsAndContent() {
    console.log('set tabs and content called ' + this.selectedDispatchActivity);
    switch (this.selectedDispatchActivity) {
      case 'UPDATE STATUS': {
        this.prepareUpdateStatus();
        this.contentTemplate = this.updateStatus;
        // this.getResponse();
        break;
      }
      case 'MARK PAPER WORK DONE': {

        this.prepareUpdateConsignment();
        this.contentTemplate = this.cNoteList; // this.cNoteDetails;
        break;
      }
      case 'UPDATE LR': {

        this.prepareUpdateConsignment();
        this.contentTemplate = this.cNoteDetails;

        break;
      }
      case 'ATTACH DOCUMENTS': {
        this.prepareAttachDocuments(this.truckHireChallan);
        this.contentTemplate = this.truckDocuments;
        break;
      }
    }
  }
  get getRightTemplate() {
    if (this.contentTemplate) {
      return this.contentTemplate;
    }
    return false;
  }
  userIsAdmin(): boolean {
    return StorageService.checkRole([10]);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = window.innerWidth;
  }
  // arrayConsignmentItems(){
  //   return Array(this.no_of_consignment_items);
  // }
  setIndex(aData) {
    this.iIndex = aData;
  }
  getvalueofvariable(aData) {
    return aData;
  }
  getFormAtIndex(anIndex): AbstractControl {
    let formarray = <AbstractControl>this.consignmentNotesFormGroup.controls.consignment_items;
    // console.log(this.consignmentNotesFormGroup.controls.consignment_items.get(anIndex));
    return formarray.get(anIndex + '');
  }
  hasValidConsignmentItems() {
    const itemform = this.consignmentItemFormAtIndex;
    return itemform.valid;
  }
  getConsignmentItemControls() {
    return (<FormGroup>this.consignmentNotesFormGroup.controls.consignment_items).controls;
  }
  get consignmentItemFormAtIndex(): FormGroup {
    // const itemform = this.consignmentNotesFormGroup.controls.consignment_items.get(anIndex+'') ;
    const itemform = <FormGroup>this.consignmentNotesFormGroup.controls.consignment_items.get('0');
    //  this.observeEwayLookup(itemform);
    return itemform;
  }
  consignmentItemFormControl(index, fieldName) {
    return this.consignmentNotesFormGroup.get('consignment_items[' + index + '].' + fieldName);
  }
  addMoreItems() {
    // this.no_of_consignment_items = this.no_of_consignment_items + 1;
    this.getNewConsignmentItemForm();
    this.consignmentItemsFormGroup.patchValue({
      eway_bill_no: this.consignmentNotesFormGroup.value.eway_bill_no,
      invoice_no: this.consignmentNotesFormGroup.value.invoice_no,
      invoice_dt: this.consignmentNotesFormGroup.value.invoice_dt,
      invoice_value: this.consignmentNotesFormGroup.value.invoice_value,
      consignment_notes_id: this.consignmentNotesFormGroup.value.id,
      _id: this.consignmentItemList.length + 1
    });
    // this.modalRef = this.modalService.show(this.addConsignment, { class: 'modal-lg' });
    this.openModalGeneric(this.addConsignment);
  }
  updateItemInConsignmentForm() {
    if (!this.consignmentItemsFormGroup.valid) {
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentItemsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentItemsFormGroup);
      return false;
    }
    if (!this.consignmentItemList) {
      this.consignmentItemList = [];
    }
    this.consignmentItemList.push(new ConsignmentItems(
      this.consignmentItemsFormGroup.value));
    (<FormArray>this.consignmentNotesFormGroup.get('consignment_items')).push(this.consignmentItemsFormGroup);
    let currenteWay = this.consignmentNotesFormGroup.value.eway_bill_no;
    let currentInvoiceNo = this.consignmentNotesFormGroup.value.invoice_no;
    let currentInvoiceVal = +this.consignmentNotesFormGroup.value.invoice_value;
    let currentPayload = +this.consignmentNotesFormGroup.value.consignment_pay_load;
    let currentNoOfBoxes = +this.consignmentNotesFormGroup.value.no_of_boxes;

    //if(currenteWay && )
    if (!currenteWay) {
      this.consignmentNotesFormGroup.patchValue({
        eway_bill_no: this.consignmentItemsFormGroup.value.eway_bill_no
      });
    }
    if (!currentInvoiceNo) {
      this.consignmentNotesFormGroup.patchValue({
        invoice_no: this.consignmentItemsFormGroup.value.invoice_no,
        invoice_dt: this.consignmentItemsFormGroup.value.invoice_dt,
      });
    }
    this.consignmentNotesFormGroup.patchValue({
      // eway_bill_no: this.consignmentItemsFormGroup.value.eway_bill_no,
      // invoice_no: this.consignmentItemsFormGroup.value.invoice_no,
      // invoice_dt: this.consignmentItemsFormGroup.value.invoice_dt,
      invoice_value: currentInvoiceVal + (+this.consignmentItemsFormGroup.value.invoice_value),
      consignment_pay_load: currentPayload + (+this.consignmentItemsFormGroup.value.actual_wt),
      no_of_boxes: currentNoOfBoxes + (+this.consignmentItemsFormGroup.value.quantity),
    });
    return true;
  }

  addItem() {
    if (this.updateItemInConsignmentForm()) {
      this.visiblityState = 'hidden';
      if (this.consignmentItemList.length > 1) {
        this.closeModalGeneric();
      }
    }
    else {
      // alert();
    }
  }

  getNewConsignmentItemForm() {
    this.consignmentItemsFormGroup = this._formBuilder.group(consignmentItemsFormControls);
    if (this.consignment_notes_id) {
      this.consignmentItemsFormGroup.patchValue({ consignment_notes_id: this.consignment_notes_id });
    }
    //  console.log(this.consignmentItemsFormGroup.controls.eway_bill_no);
    if ((this.consignmentNotes && this.consignmentNotes.business_type_cd !== 'EXIM')
      || (!this.consignmentNotes && this.selectedBooking && this.selectedBooking.business_type_cd !== 'EXIM')) {
      if (this.selectedBooking?.total_distance && this.selectedBooking?.total_distance >= 50) {
        //  console.log('setting validators on eway bill');
        this.consignmentItemsFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
        Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
        this.consignmentItemsFormGroup.controls['eway_bill_no'].updateValueAndValidity();
      }
    }
    this.observeEwayItemLookup();
    //  console.log(this.consignmentItemsFormGroup.controls.eway_bill_no);
    return this.consignmentItemsFormGroup;
  }
  addMoreInvoices() {
    this.getNewConsignmentItemForm();
    this.consignmentItemsFormGroup.patchValue({
      consignment_notes_id: this.consignmentNotesFormGroup.value.id,
      _id: this.consignmentItemList.length + 1
    });
    // this.modalRef = this.modalService.show(this.addConsignment, { class: 'modal-lg' });
    this.openModalGeneric(this.addConsignment);
  }
  onDispatchActivityChange(event) {
    //  console.log(event);
    this.selectedDispatchActivity = event.value;
    this.setTabsAndContent();
    //  console.log(this.selectedDispatchActivity);
  }
  getTripStatusColor(status_cd) {
    const statusIndex = Trips.statusesIndexForAll.indexOf(status_cd);
    const currIndex = Trips.statusesIndexForAll.indexOf(this.trips.trips_status_cd);
    if (statusIndex <= currIndex) {
      return 'blue';
    }
    return 'grey';
  }
  isDestinationStatus(status_cd) {
    if (status_cd == 'REACHED LOADING POINT' || status_cd == 'REACHED DESTINATION') {
      return true;
    }
    return false;
  }
  isUnloadingCompleteStatus(status_cd) {
    if (status_cd == 'UNLOADING COMPLETE' || status_cd == 'DESTUFFING COMPLETE') {
      return true;
    }
    return false;
  }
  isLoadingCompleteStatus(status_cd) {
    if (status_cd == 'LOADING COMPLETE' || status_cd == 'STUFFING COMPLETE') {
      return true;
    }
    return false;
  }
  getAllStatuses() {
    return Trips.getStatuses(
      this.trips.business_type_cd,
      this.trips.movement_type_cd
    );
  }
  selectLocation(event) {
    this.consignmentNotesFormGroup.patchValue({
      last_location: appGlobals.selectCityRegion(event)
    });
    this.consignmentNotesFormGroup.patchValue({
      last_location_lat: event.geometry.location.lat()
    });
    this.consignmentNotesFormGroup.patchValue({
      last_location_long: event.geometry.location.lng()
    });
  }
  getCancelReasons() {
    return ConsignmentNotes.cancelReasons;
  }
  hasNextStatusSuffx(status_cd, tripData) {
    const nextStatus = Trips.nextStatus(
      status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus && nextStatus != 'CANCELLED') {
      return '';
    }
    return 'no';
  }
  getNextTripsStatus(element) {
    if (element.trips_status_cd === 'TRIP COMPLETE') {
      return '';
    }
    const nextStatus = Trips.nextStatus(
      element.trips_status_cd,
      element.business_type_cd,
      element.movement_type_cd
    );

    if (nextStatus) {
      return nextStatus;
    }
    return '';
  }
  getNextTripsPlusStatus(tripData: Trips) {
    if (tripData.trips_status_cd === 'TRIP COMPLETE') {
      return '';
    }
    const nextStatus = Trips.nextStatus(
      tripData.trips_status_cd,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus === 'TRIP COMPLETE' || nextStatus === 'CANCELLED') {
      return '';
    }

    const nextStatus2 = Trips.nextStatus(
      nextStatus,
      tripData.business_type_cd,
      tripData.movement_type_cd
    );
    if (nextStatus2 === 'CANCELLED') {
      return '';
    }
    return nextStatus2;
  }
  openModalSendConsignment(updateLocation: TemplateRef<any>, cNoteData) {
    this.sendConsignmentFormGroup.reset();
    if (cNoteData) {
      this.consignmentNotes = new ConsignmentNotes(cNoteData);
      this.sendConsignmentFormGroup.patchValue(this.consignmentNotes);
      this.openModalGeneric(updateLocation, cNoteData);
      // this.modalRef = this.modalService.show(updateLocation);
    }
  }
  downloadConsignmentAsPDF(aData) {
    this.consignmentNotesService.downloadConsignmentAsPDF(aData.id).subscribe(
      response => {
        //  console.log('got pdf response');
        this.downloadPdfFile(response, aData.consignment_no);
        this._snackBarService.open('LR ' + aData.consignment_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadKNConsignmentAsPDF(aData) {
    this.consignmentNotesService.downloadKNConsignmentAsPDF(aData.id).subscribe(
      response => {
        //  console.log('got pdf response');
        this.downloadPdfFile(response, aData.consignment_no);
        this._snackBarService.open('LR ' + aData.consignment_no + ' downloaded', 'X', {
          duration: appGlobals.SNACKBAR_TIMEOUT
        });
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  downloadPdfFile(responseData: any, entityDisplayData: any) {
    const options = {
      type: 'application/pdf'
    };
    var blob = new Blob([responseData], { type: "application/pdf" });
    saveAs(blob, entityDisplayData + "_download.pdf");
  }
  submitSendLr(aData) {
    if (!this.sendConsignmentFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.sendConsignmentFormGroup
      );
      appGlobals.markFormGroupTouched(this.sendConsignmentFormGroup);
      return;
    }
    if (aData) {
      // aData.remarks = this.cNote.remarks  + '<\BR>' + aData.remarks;
      this.consignmentNotesService.sendConsignmentAsPDF(aData).subscribe(
        response => {
          this.closeModalGeneric();
          // this.modalRef.hide();
          // this.initializeList();
          this._snackBarService.open('Mail sent to ' + aData.to_name, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  submitTripStatus() {
    if (!this.consignmentTripTrackingsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentTripTrackingsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentTripTrackingsFormGroup);
    } else {
      const formData = this.consignmentTripTrackingsFormGroup.value;
      // new Date('1970-01-01T' + timeString + 'Z');
      // console.log(formData.status_at);
      formData.status_at = new Date((formData.status_at.toDateString() + ' ' + formData.status_at_time).replace(/-/g, "/"));
      //  console.log(formData);
      const default_status_cd = formData['trips_status_cd'];
      const afterInTransit = Trips.isStatusCrossed('IN TRANSIT', this.trips);
      if (!afterInTransit && default_status_cd && default_status_cd != null
        && default_status_cd !== 'IN TRANSIT' && default_status_cd !== 'CANCELLED') {
        const origStatus = this.trips.trips_status_cd;
        this.trips.trips_status_cd = default_status_cd;
        if (Trips.isStatusCrossed('IN TRANSIT', this.trips)) {
          alert('Please mark trip IN TRANSIT before marking it ' + default_status_cd);
          this.trips.trips_status_cd = origStatus;
          return false;
        }
      }
      if (default_status_cd === 'VEHICLE ASSIGNED') {
        if (!this.trips.truck_hire_challans || !this.trips.truck_hire_challans[0]) {
          alert('Please assign truck before marking trip VEHICLE ASSIGNED');
        }
      }
      // console.log(formData.status_at);
      this.consignmentTripTrackingsService.updateStatus(formData).subscribe(
        response => {
          if (response.status === 200) {
            // this.closeModalGeneric();
            // this.getTripData(this.trips.id);
            // this.getResponse(this.currentPage);
            window.scrollTo(0, 0);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
            // this.ngOnInit();
            if (default_status_cd == 'IN TRANSIT') {
              this.onComplete.emit();
            }
            else {
              this.getTripData(this.trips.id);
              this.prepareUpdateStatus();
            }

          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
        }
      );
    }
  }
  uploadRegistrationScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'REGISTRATION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          registration_scan: response.data.filename
        });
      });
  }

  uploadDrivingLicenseScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'driver',
        'DRIVING LICENSE'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: response.data.filename
        });
      });
  }

  uploadFitnessScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'FITNESS'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          fitness_scan: response.data.filename
        });
      });
  }

  uploadInsuranceScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'INSURANCE'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          insurance_scan: response.data.filename
        });
      });
  }

  uploadOwnerPanScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'PAN CARD'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: response.data.filename
        });
      });
  }

  uploadOwnerTdsScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'partner',
        'TDS DECLARATION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          tds_declaration_scan: response.data.filename
        });
      });
  }
  uploadPermitScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'PERMIT'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          permit_scan: response.data.filename
        });
      });
  }

  uploadPollutionScan(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(
        event.srcElement.files[0],
        entityId,
        'truck',
        'POLLUTION'
      )
      .subscribe(response => {
        this.attachTruckDocumentsFormGroup.patchValue({
          pollution_scan: response.data.filename
        });
      });
  }
  setpartnerTypeCd2(event) {
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: event
    });
  }

  // setCustomerLookup() {
  //   const aControlCustomer = this.consignmentNotesFormGroup.controls.searchCustomer;
  //   this.consignmentNotesFormGroup.controls.searchCustomer.valueChanges
  //     .pipe(
  //       debounceTime(400),
  //       filter(value => {
  //         const result = value !== this.searchCustomer && aControlCustomer.dirty;
  //  console.log(result);
  //         return result;
  //       }),
  //       distinctUntilChanged(),
  //       switchMap(term => {
  //         this.searchCustomer = term;
  //         return this.customerService.lookupCustomerWithBooking(term);
  //       }
  //       )
  //     )
  //     .subscribe(
  //       response => {

  //         this.customerList = response.data;

  //       },
  //       error => {
  //         this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
  //       }
  //     );
  // }
  ifEwayMandatory() {
    const inv_value = this.consignmentNotesFormGroup.get('invoice_value').value;
    const total_distance = this.selectedBooking.total_distance;
    if (this.consignmentNotes.business_type_cd === 'EXIM' ||
      (inv_value && inv_value <= 50000) || total_distance <= 50) {
      return false; //  true;
    }
    // if(this.consignmentNotes.business_type_cd !== 'EXIM') {
    //   if(this.selectedBooking.total_distance >= 50) {
    //  console.log('setting validators on eway bill');
    //     this.consignmentNotesFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
    //       Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
    //       this.consignmentNotesFormGroup.controls['eway_bill_no'].updateValueAndValidity();
    //   }
    // }
    return true;
  }
  getConsignmentStatuses() {
    return ConsignmentNotes.consignmentStatuses;
  }
  openModalEditItem(addConsignment: TemplateRef<any>, option = null) {
    // this.consignmentItemsFormGroup.reset();
    this.getNewConsignmentItemForm()
    if (option) {
      this.consignmentItemsFormGroup.patchValue(option);
    } else {
      this.consignmentItemsFormGroup.patchValue({
        eway_bill_no: this.consignmentNotesFormGroup.value.eway_bill_no,
        invoice_no: this.consignmentNotesFormGroup.value.invoice_no,
        invoice_dt: this.consignmentNotesFormGroup.value.invoice_dt,
        invoice_value: this.consignmentNotesFormGroup.value.invoice_value,
        consignment_notes_id: this.consignmentNotesFormGroup.value.id,
        _id: this.consignmentItemList.length + 1
      });

    }
    // console.log(this.consignmentItemsFormGroup.value);
    this.modalRef = this.openModalGeneric(addConsignment);

  }
  handleImage(event) {
    //  console.log(event);
    event.preventDefault();
  }

  getPartnerList() {
    return this.partnerList;
  }

  getPartnerSearchService(searchTerm) {
    return this.partnerService.searchNew(searchTerm);
  }
  setPartnerListOwner(aData) {
    //  console.log('setting orner partner list ');
    this.attachTruckDocumentsFormGroup.controls.partners_id.reset();
    this.attachTruckDocumentsFormGroup.controls.partner_contacts_id.reset();
    this.attachTruckDocumentsFormGroup.controls.pan_no.reset();
    this.attachTruckDocumentsFormGroup.controls.pan_card_scan.reset();
    //  console.log(aData);
    this.partnerList = Partners.toArray(aData.data);
    // this.attachTruckDocumentsFormGroup.controls.partner_company_name.reset();
    //  console.log('setting owner partner list ');

    // this.attachTruckDocumentsFormGroup.controls.tds_declaration_scan.reset();
  }
  observeEwayItemLookup() {
    //  console.log('setting observe on eway ');
    appGlobals.observeChanges(
      this,
      this.consignmentItemsFormGroup.controls.eway_bill_no,
      this.consignmentEwayBillList,
      'getEWaySearchService',
      'setEWay',
      'eway_bill_no'
    );

  }
  showErrorDialog(error) {
    this._dialogService.openAlert({
      title: Error,
      message: appGlobals.getDisplayErrorMessage(error)
    });
  }
  // observeEayBill(){
  //   appGlobals.observeChanges(
  //     this,
  //     this.consignmentNotesFormGroup.controls.eway_bill_no,
  //     this.consignmentEwayBillList,
  //     'getEWaySearchService',
  //     'setEWayBillDetails',
  //     'eway_bill_no', null, null, null, 'getPartnerList'
  //   );
  // }
  checkeWay(searchTerm) {
    //  console.log('checkeway ' + searchTerm);
    return true;
  }
  getEWaySearchService(searchTerm) {
    //  console.log('sending search service ' + searchTerm);
    return this.consignmentNotesService.geteWayBillDetailsFromExternal({ eway_bill_no: searchTerm, consignment_notes_id: this.consignmentNotes.id });
  }
  setEWay(response) {
    //  console.log('setting eway response');
    console.log(response);
    if (response.status === 200) {
      // this.ewayBillNo = response.data.eway_bill_no;
      // this.tempEwayBill = this.ewayBillNo;
      if (!this.consignmentEwayBillList || this.consignmentEwayBillList == null) {
        this.consignmentEwayBillList = [];
      }
      const len = this.consignmentEwayBillList.length;
      let confResult = true;
      if (!response.data || !response.data.eway_bill_raw) {
        // confResult = false;
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Unable to fetch eWay Bill details.'
        });
      }
      else {
        //  console.log(response.data.eway_bill_raw);
        if (!response.data.eway_bill_raw.vehicle_no || response.data.eway_bill_raw.vehicle_no == null) {
          confResult = false;
          alert('Error: Eway does not have Truck Number attached. Please have Part B updated');
        }
        else if (!response.data.eway_bill_raw.trans_doc_no || response.data.eway_bill_raw.trans_doc_no == null) {
          // confResult = false;
          confResult = confirm('Warning: Eway does not have LR number. Please press Ok to Continue or cancel to correct Part B');

        }
        else {
          confResult = confirm('Eway will is attached to LR no '
            + response.data.eway_bill_raw.trans_doc_no + ' and truck '
            + response.data.eway_bill_raw.vehicle_no + '. Please press Ok to Confirm. Press cance to enter another eWay');
        }
      }
      if (confResult && response.data) {
        // this.setConsignmentResponse(response.data);
        if (response.data.eway_bill_raw) {
          let existingEway = false;
          if (this.consignmentEwayBillList.length === 0) {
            // is first eway bill
            this.consignmentNotes.fill(response.data);
          }
          //patch current line item
          this.consignmentItemsFormGroup.patchValue({
            eway_bill_no: response.data.eway_bill_no,
            invoice_no: response.data.invoice_no,
            invoice_dt: appGlobals.getSafeDate(response.data, 'invoice_dt'),
            invoice_value: response.data.invoice_value,
            eway_valid_upto_dt: appGlobals.getSafeDate(response.data, 'eway_valid_upto_dt'),
            eway_hsn_cd: response.data.eway_hsn_cd,
          }
          );
          if (response.data.invoice_dt != null) {

          }

          this.consignmentEwayBillList.forEach(element => {
            if (element['eway_bill_no'] === response.data.eway_bill_no) {
              existingEway = true;
            }
          });
          if (!existingEway) {

            if (response.data.consignment_items && response.data.consignment_items[0]) {
              // this.consignmentItemList || []
              const currentConsignmentItems = this.consignmentNotes.consignment_items || [];
              // adding items to current consignment note..
              const lineItemsInEway = ConsignmentItems.toArray(response.data.consignment_items);
              const newConsignmentItems = currentConsignmentItems.concat(lineItemsInEway);
              let itemDesc = '';
              let itemDescShort = '';
              let itemQuantity = 0;
              let itemUnit = '';
              lineItemsInEway.forEach(element => {
                //  console.log(element.description + element.quantity + element.unit_cd);
                if (itemDesc == '') {
                  itemDesc = element.description;
                  itemDescShort = element.description;
                }
                else if (itemDesc.trim().toLowerCase() == element.description.trim().toLowerCase()) {

                }
                else {
                  itemDesc = itemDesc + ' ,' + element.description;
                }
                const noOfDifferentItems = itemDesc.split(' ,').length;
                if (noOfDifferentItems > 1) {
                  const additionalItems = noOfDifferentItems - 1;
                  itemDescShort = itemDescShort + ' and ' + additionalItems + ' items';
                }
                if (itemUnit == '') {
                  itemUnit = element.unit_cd;
                }
                else if (itemUnit.trim().toLowerCase() == element.unit_cd.trim().toLowerCase()) {

                }
                else {
                  // itemUnit = itemUnit + ' ,' + element.unit_cd;
                }
                itemQuantity = itemQuantity + element.quantity;
              });
              this.consignmentItemsFormGroup.patchValue({
                quantity: itemQuantity,
                description: itemDesc.substr(0, 50),
                unit_cd: itemUnit,
              }
              );
            }


            this.consignmentEwayBillList[len] = {
              consignment_notes_id: this.consignmentNotes.id,
              eway_bill_no: response.data.eway_bill_no,
              eway_valid_upto_dt: appGlobals.getSafeDate(response.data, 'eway_valid_upto_dt'),
              eway_hsn_cd: response.data.eway_hsn_cd,
              invoice_no: response.data.invoice_no,
              invoice_dt: response.data.invoice_dt,
              invoice_value: response.data.invoice_value,
              trans_doc_no: response.data.eway_bill_raw.trans_doc_no,
            };
          }
          //  console.log(this.consignmentEwayBillList);
        }
      }
      else {
        if (this.ifEwayMandatory()) {
          // this.ewayBillNo = '';
          this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
          // this.consignmentNotesFormGroup.controls.eway_bill_no.setValue('');
        }
      }

    }
    else if (response.status === 300 || response.status === 500) {
      // console.log('error in eway search ' + (response.message ? response.message : response['error']));
      this._dialogService.openAlert({
        title: 'Error',
        message: (response.message ? response.message : response['error'])
      });
      if (this.ifEwayMandatory()) {
        // this.tempEwayBill = this.ewayBillNo = '';
        this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
        // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
      }
    }
  }
  observePartnerName() {
    appGlobals.observeChanges(
      this,
      this.attachTruckDocumentsFormGroup.controls.partner_company_name,
      'partnerList',
      'getPartnerSearchService',
      'setPartnerListOwner',
      'name');
  }


  // setEwayBillLookup() {
  //   const aControl = this.consignmentNotesFormGroup.controls.eway_bill_no;
  //   aControl.valueChanges.pipe(
  //     debounceTime(500),
  //     filter(value => {
  //       if (value === '' || value == null) {
  //         return false;
  //       }
  //       let result = value !== aControl.dirty && value && value > 100000000000 && value < 1000000000000;
  //       if (result) {
  //         result = !appGlobals.searchInObjectArrayByField('eway_bill_ no', value, this.consignmentEwayBillList);
  //       }
  //       // this.tempEwayBill = value;
  //       return result;
  //     }),
  //     switchMap(searchTerm =>
  //       this.consignmentNotesService.geteWayBillDetailsFromExternal(
  //         { eway_bill_no: searchTerm, consignment_notes_id: this.consignmentNotes.id })
  //     )
  //   ).subscribe(response => {
  //  console.log(response);
  //       if (response.status === 200) {
  //         this.ewayBillNo = response.data.eway_bill_no;
  //         this.tempEwayBill = this.ewayBillNo;
  //         if (!this.consignmentEwayBillList || this.consignmentEwayBillList == null) {
  //           this.consignmentEwayBillList = [];
  //         }
  //         const len = this.consignmentEwayBillList.length;
  //         let confResult = true;
  //         if (!response.data || !response.data.eway_bill_raw) {
  //           // confResult = false;
  //           this._dialogService.openAlert({
  //             title: 'Error',
  //             message: 'Unable to fetch eWay Bill details.'
  //           });
  //         }
  //         else {
  //  console.log(response.data.eway_bill_raw);
  //           if (!response.data.eway_bill_raw.vehicle_no || response.data.eway_bill_raw.vehicle_no == null) {
  //             confResult = false;
  //             alert('Error: Eway does not have Truck Number attached. Please have Part B updated');
  //           }
  //           else if (!response.data.eway_bill_raw.trans_doc_no || response.data.eway_bill_raw.trans_doc_no == null) {
  //             // confResult = false;
  //             confResult = confirm('Warning: Eway does not have LR number. Please press Ok to Continue or cancel to correct Part B');

  //           }
  //           else {
  //             confResult = confirm('Eway will is attached to LR no '
  //               + response.data.eway_bill_raw.trans_doc_no + ' and truck '
  //               + response.data.eway_bill_raw.vehicle_no + '. Please press Ok to Confirm. Press cance to enter another eWay');
  //           }
  //         }
  //         if (confResult && response.data) {
  //           // this.setConsignmentResponse(response.data);
  //           if (response.data.eway_bill_raw) {
  //             let existingEway = false;
  //             this.consignmentEwayBillList.forEach(element => {
  //               if (element['eway_bill_no'] === response.data.eway_bill_no) {
  //                 existingEway = true;
  //               }
  //             });
  //             if (!existingEway) {
  //               const currentConsignmentItems = this.consignmentNotes.consignment_items || [];
  //               if (this.consignmentEwayBillList.length === 0) {
  //                 this.consignmentNotes.fill(response.data);
  //               }
  //               this.consignmentNotes.consignment_items =
  //                 currentConsignmentItems.concat(ConsignmentItems.toArray(response.data.consignment_items));

  //               this.consignmentEwayBillList[len] = {
  //                 consignment_notes_id: this.consignmentNotes.id,
  //                 eway_bill_no: response.data.eway_bill_no,
  // eway_valid_upto_dt: appGlobals.getSafeDate(response.data, 'eway_valid_upto_dt'),
  // eway_hsn_cd: response.data.eway_hsn_cd,
  //                 invoice_no: response.data.invoice_no,
  //                 invoice_dt: response.data.invoice_dt,
  //                 invoice_value: response.data.invoice_value,
  //                 trans_doc_no: response.data.eway_bill_raw.trans_doc_no,
  //               };

  //             }
  //  console.log(this.consignmentEwayBillList);
  //           }
  //         }
  //         else {
  //           if (this.ifEwayMandatory()) {
  //             this.ewayBillNo = '';
  //             this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
  //             // this.consignmentNotesFormGroup.controls.eway_bill_no.setValue('');
  //           }
  //         }

  //       }
  //       else if (response.status === 300 || response.status === 500) {
  //         // console.log('error in eway search ' + (response.message ? response.message : response['error']));
  //         this._dialogService.openAlert({
  //           title: 'Error',
  //           message: (response.message ? response.message : response['error'])
  //         });
  //         if (this.ifEwayMandatory()) {
  //           this.tempEwayBill = this.ewayBillNo = '';
  //           this.consignmentNotesFormGroup.patchValue({ eway_bill_no: '' });
  //           // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
  //         }
  //       }
  //     },
  //       error => {
  //  console.log(appGlobals.getDisplayErrorMessage(error));
  //         this._dialogService.openAlert({
  //           title: 'Error',
  //           message: appGlobals.getDisplayErrorMessage(error)
  //         });
  //         // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
  //       }
  //     );

  // }

  isDifferent(value: any) {
    //  console.log(value);
    // let result = value !== this.ewayBillNo && value && value > 100000000000 && value < 1000000000000;
    let result = value > 100000000000 && value < 1000000000000;
    //  console.log(result);
    if (result) {
      result = !appGlobals.searchInObjectArrayByField('eway_bill_ no', value, this.consignmentEwayBillList);
      if (!result) {
        //  console.log(this.consignmentEwayBillList);
      }
    }
    return result;

  }

  // getBookingData(id) {
  //   this.bookingsService.getBookingsById(id).subscribe(response => {
  //     this.bookings = response.data;
  //     if (this.bookings) {
  //       this.bookingList = new Array(this.bookings);
  //       if (this.bookings.customers) {
  //         this.customerList = new Array(this.bookings.customers);
  //       }
  //       this.consignmentNotesFormGroup.patchValue({
  //         searchCustomer: this.bookings.customers.name,
  //         customers_id: this.bookings.customers.id,
  //         consignor_location_code: this.bookings.source_location_code,
  //         consignee_location_code: this.bookings.destination_location_code
  //       });
  //     }
  //   });
  // }
  // addEWayBill() {
  //   const value = this.consignmentsEwayFormGroup.controls.eway_bill_no.value;
  //   // let result = value !== this.ewayBillNo && value > 100000000000 && value < 1000000000000;
  //   let result = value > 100000000000 && value < 1000000000000;
  //  console.log(result);
  //   if (result) {
  //     result = !appGlobals.searchInObjectArrayByField('eway_bill_ no', value, this.consignmentEwayBillList);
  //   }
  //   if (!result) {
  //     alert('Invalid eWay or already added');
  //     return;
  //   }
  //   this.consignmentNotesService.geteWayBillDetailsFromExternal(
  //     { eway_bill_no: value, consignment_notes_id: this.consignmentNotes.id })
  //     .subscribe(response => {
  //  console.log(response.data);
  //       if (response.status == 200) {
  //         // this.consignmentNotes = new ConsignmentNotes(response.data);
  //  console.log(response);
  //         // this.ewayBillNo = response.data.eway_bill_no;
  //         if (!this.consignmentEwayBillList || this.consignmentEwayBillList == null) {
  //           this.consignmentEwayBillList = [];
  //         }
  //         const len = this.consignmentEwayBillList.length;
  //         const newCNote = new ConsignmentNotes(response.data);
  //         // this.setConsignmentResponse(response.data);
  //         if (!this.consignmentItemList || this.consignmentItemList == null) {
  //           this.consignmentItemList = [];
  //         }
  //         // console.log(this.consignmentItemList);
  //         // console.log(newCNote.consignment_items);
  //         this.consignmentItemList = this.consignmentItemList.concat(newCNote.consignment_items);
  //         // console.log(this.consignmentItemList);
  //         if (response.data.eway_bill_raw) {
  //           this.consignmentEwayBillList[len] = {
  //             consignment_notes_id: this.consignmentNotes.id,
  //             eway_bill_no: response.data.eway_bill_no,
  //             invoice_no: response.data.invoice_no,
  //             invoice_dt: response.data.invoice_dt,
  //             invoice_value: response.data.invoice_value,
  //             trans_doc_no: response.data.eway_bill_raw.trans_doc_no,
  //           };
  //  console.log(this.consignmentEwayBillList);
  //         }
  //         const originalInvoiceNo = this.consignmentNotesFormGroup.get('invoice_no').value;
  //         const originalInvoiceValue = this.consignmentNotesFormGroup.get('invoice_value').value;
  //         this.consignmentNotesFormGroup.patchValue({ invoice_no: originalInvoiceNo + ',' + newCNote.invoice_no });
  //         this.consignmentNotesFormGroup.patchValue({ invoice_value: originalInvoiceValue + newCNote.invoice_value });
  //         this.modalRef.hide();
  //       }
  //       if (response.status === 300 || response.status === 500) {
  //         this._dialogService.openAlert({
  //           title: 'Error',
  //           message: response.message
  //         });
  //         // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
  //       }
  //     },
  //       error => {
  //  console.log(appGlobals.getDisplayErrorMessage(error));
  //         this._dialogService.openAlert({
  //           title: 'Error',
  //           message: appGlobals.getDisplayErrorMessage(error)
  //         });
  //         // this.consignmentNotesFormGroup.controls.eway_bill_no.reset();
  //       }
  //     );

  // }
  // getTripsForBooking(bookings_id) {
  //   this.tripsService
  //     .getList({
  //       page: 1,
  //       bookings_id: this.bookings_id
  //       //      drivers_id: this.drivers_id, partners_id: this.partners_id, search: this.searchTrips,
  //       //    executive_id: this.searchTripsExecutiveId, territory_cd: this.searchTripsTerritoryCd,
  //     })
  //     .subscribe(response => {
  //       this.tripList = response.data;
  //     });
  // }
  // getBookingsForCustomer(customers_id) {
  //   this.bookingsService.getList({ page: 1, customers_id: customers_id }).subscribe(response => {
  //     this.bookingList = response.data;
  //   });
  // }
  // searchTrips() { }

  setDataFromTrip(aTrip: Trips) {
    this.trips = aTrip;
    this.next_status = this.getNextTripsStatus(this.trips);
    this.next_plus_status = this.getNextTripsPlusStatus(this.trips);
    this.consignmentNotesList = [];
    this.consignmentNotes = null;
    // this.selectedCustomer = null;
    this.selectedBooking = null;
    this.truckHireChallan = null;
    if (this.trips.bookings) {
      // this.customerList = new Array(this.trips.bookings.customers);
      // this.bookingList = new Array(this.trips.bookings);
      // this.tripList = new Array(this.trips);
      // this.selectedCustomer = this.trips.bookings.customers;
      this.selectedBooking = this.trips.bookings;
      this.consignment_trips.trips_id = this.trips.id;
      // if(this.trips.consignment_trips && this.trips.consignment_trips[0]) {
      //   this.consignment_trips = this.trips.consignment_trips[0];
      // }
      if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
        this.truckHireChallan = this.trips.truck_hire_challans[0];
      }
    }
    let hasIncomplete = false;
    // this.consignment_trips.consignment_notes_id = this.consignmentNotes.id;
    if (
      this.trips.consignment_trips &&
      this.trips.consignment_trips[0]) {
      // this.consignment_trips.trips_id = this.trips.id;
      // if(this.trips.consignment_trips && this.trips.consignment_trips[0]) {
      //   this.consignment_trips = this.trips.consignment_trips[0];
      // }

      this.trips.consignment_trips.forEach(aCNoteTrip => {
        if (aCNoteTrip.consignment_note.consignment_status_cd == 'INCOMPLETE') {
          hasIncomplete = true;
        }
        this.consignmentNotesList.push(aCNoteTrip.consignment_note);
      });
      this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
      // this.consignment_trips.id = this.consignmentNotes.consignment_trips[0].id;
    }
    // console.log('setDataFromTrip')
    // console.log(this.consignmentNotesList);
    if (hasIncomplete && this.consignmentNotesList.length == 1) {
      this.isSingleIncompleteLR = true;
      this.consignment_notes_id = this.consignmentNotesList[0].id;
      if (this.selectedDispatchActivity == 'MARK PAPER WORK DONE') {
        // this.selectedDispatchActivity = 'UPDATE LR';
        this.prepareUpdateLr();
      }
      // this.openUpdateLr(this.consignmentNotesList[0]);
      // this.selectedDispatchActivity = 'UPDATE LR';
    }
    // if (this.trips.trip_addresses && this.trips.trip_addresses.length > 0) {
    //   this.trips.trip_addresses.forEach(element => {
    //     if (element.trip_address_type_cd == 'PICKUP') {
    //       this.consignmentNotes.consignor_city = element.city;
    //       this.consignmentNotes.consignor_state_cd = element.state_cd;
    //       this.consignmentNotes.consignor_street = element.street;
    //       this.consignmentNotes.consignor_name = element.company_name;
    //     }
    //     if (element.trip_address_type_cd == 'DROP') {
    //       this.consignmentNotes.consignee_city = element.city;
    //       this.consignmentNotes.consignee_state_cd = element.state_cd;
    //       this.consignmentNotes.consignee_street = element.street;
    //       this.consignmentNotes.consignee_name = element.company_name;
    //     }
    //   });
    // }
    // this.consignmentNotesFormGroup.patchValue({
    //   bookings_id: this.selectedBooking.id,
    //   trips_id: this.trips.id,
    //   container_type_cd: this.selectedBooking.container_type_cd,
    //   searchCustomer: this.selectedBooking.customers.name,
    //   customers_id: this.selectedBooking.customers.id
    // });
    // this.consignmentNotesFormGroup.patchValue({
    //   consignment_status_cd: 'BOOKED'
    // });
    // this.consignmentNotesFormGroup.patchValue({
    //   freight_amt: this.selectedBooking.total_fare
    // });


  }
  getTripData(id: number) {
    this.tripsService.getTripsById(id).subscribe(response => {
      if (response.data) {
        //  console.log(response);
        this.trips = new Trips(response.data);
        this.setDataFromTrip(this.trips);
      }
    });
  }
  // setBookingData(option: Customers) {
  //   this.selectedCustomer = option;
  //   this.getBookingsForCustomer(option.id);
  // }
  // setTripData(book: Bookings) {
  //   this.selectedBooking = book;
  //   this.consignmentNotesFormGroup.patchValue({
  //     freight_amt: this.selectedBooking.total_fare
  //   });
  //   this.getTripsForBooking(this.selectedBooking.id);
  // }
  // setAddressData(trip: Trips) {
  //   this.consignment_trips.trips_id = trip.id;
  //   this.consignment_trips.consignment_notes_id = this.consignmentNotes.id;
  //   if (
  //     this.consignmentNotes.consignment_trips &&
  //     this.consignmentNotes.consignment_trips[0]
  //   ) {
  //     this.consignment_trips.id = this.consignmentNotes.consignment_trips[0].id;
  //   }
  // }
  addConsigMent() {
    if (!this.consignmentItemsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentItemsFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentItemsFormGroup);
      this._dialogService.openAlert({
        title: 'Error',
        message: 'Please complete all details'
      });
      // alert('Please complete all details');
      return;
    }
    let cgItem = new ConsignmentItems(this.consignmentItemsFormGroup.value);
    if (cgItem.id) {
      const index = this.consignmentItemList.findIndex(x => x.id === cgItem.id);
      if (index !== -1) {
        this.consignmentItemList[index] = cgItem;
        // this.modalRef.hide();
        this.closeModalGeneric();
        return;
      }
    } else if (cgItem._id) {
      const index = this.consignmentItemList.findIndex(
        x => x._id === cgItem._id
      );
      if (index !== -1) {
        this.consignmentItemList[index] = cgItem;
        // this.modalRef.hide();
        this.closeModalGeneric();
        return;
      }
    }
    cgItem._id = this.consignmentItemList.length + 1;
    this.consignmentItemList.push(cgItem);
    // this.modalRef.hide();
    this.closeModalGeneric();
  }
  // getBookingById(id) {
  //   this.bookingsService.getBookingsById(id).subscribe(response => {
  //     this.selectedBooking = new Bookings(response.data);
  //     this.selectedCustomer = this.selectedBooking.customers;
  //     this.bookingList = new Array(this.selectedBooking);
  //     this.containerType = this.selectedBooking.container_type_cd;
  //     this.consignmentNotesFormGroup.patchValue({
  //       bookings_id: this.selectedBooking.id,
  //       container_type_cd: this.selectedBooking.container_type_cd,
  //       searchCustomer: this.selectedBooking.customers.name,
  //       customers_id: this.selectedBooking.customers.id
  //     });
  //     this.consignmentNotesFormGroup.patchValue({
  //       freight_amt: this.selectedBooking.total_fare
  //     });
  //   });
  // }
  deleteEway(item) {
    if (this.consignmentEwayBillList && Array.isArray(this.consignmentEwayBillList)) {
      let indexToDelete = 0;
      let foundElement = false;
      let i = 0;
      this.consignmentEwayBillList.forEach(element => {
        if (element.id == item.id) {
          indexToDelete = i;
          foundElement = true;
        }
        i++;
      });
      if (foundElement) {
        this.consignmentEwayBillList.splice(indexToDelete, 1);
      }
    }
  }
  // setFormDataFromTrip() { }
  setConsignmentResponse(aData) {
    // console.log(aData);
    this.consignmentNotes = new ConsignmentNotes(aData);
    // this.consignmentNotesList = [this.consignmentNotes];
    // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);

    if (this.consignmentNotes) {
      //  console.log(this.consignmentNotes);
      this.consignmentItemList = this.consignmentNotes.consignment_items;
      if (this.consignmentItemList && this.consignmentItemList.length > 0) {
        this.visiblityState = 'hidden';
      }
      else {
        this.consignmentItemList = [];
        this.visiblityState = 'shown';
      }
      this.consignmentEwayBillList = this.consignmentNotes.eway_bills;
      if (!this.selectedBooking || !this.selectedBooking.id) {
        this.selectedBooking = this.consignmentNotes.bookings;

      }
      // this.bookingList.push(this.consignmentNotes.bookings);
      // this.customerList = new Array(
      //   this.consignmentNotes.bookings.customers
      // );

      if (
        this.consignmentNotes.consignment_trips &&
        this.consignmentNotes.consignment_trips[0]
      ) {
        // this.tripList = new Array(
        //   this.consignmentNotes.consignment_trips[0].trip
        // );
        this.trips = this.consignmentNotes.consignment_trips[0].trip;
        this.next_status = this.getNextTripsStatus(this.trips);
        this.next_plus_status = this.getNextTripsPlusStatus(this.trips);

        if (this.trips.truck_hire_challans && this.trips.truck_hire_challans[0]) {
          this.truckHireChallan = this.trips.truck_hire_challans[0];
        }
        // this.selectedTripValue =
        //   this.consignmentNotes.consignment_trips[0].trip.id + '';
        // this.consignmentNotesFormGroup.patchValue({
        //   trips_id: this.consignmentNotes.consignment_trips[0].trip.id
        // });
      }
      // this.ewayBillNo = this.consignmentNotes.eway_bill_no;
      // this.tempEwayBill = this.ewayBillNo;
      // this.selectedBooking = this.consignmentNotes.bookings;
      // this.selectedCustomer = this.consignmentNotes.bookings.customers;
      this.consignmentNotesFormGroup.patchValue(this.consignmentNotes);
      // console.log(this.consignmentNotesFormGroup);
      if ((this.consignmentNotes && this.consignmentNotes.business_type_cd !== 'EXIM')
        || (!this.consignmentNotes && this.selectedBooking && this.selectedBooking.business_type_cd !== 'EXIM')) {
        if (this.selectedBooking.total_distance >= 50) {
          this.consignmentNotesFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
          Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
          this.consignmentItemsFormGroup.controls['eway_bill_no'].setValidators([Validators.required,
          Validators.min(100000000000), Validators.max(999999999999), Validators.minLength(12)]);
          this.consignmentNotesFormGroup.controls['eway_bill_no'].updateValueAndValidity();
          this.consignmentItemsFormGroup.controls['eway_bill_no'].updateValueAndValidity();
        }
      }
      // console.log(this.consignmentNotesFormGroup);
      // console.log(this.consignmentNotes.business_type_cd);
      // console.log(this.selectedBooking.total_distance);

      // this.consignmentNotesFormGroup.patchValue(aData);
      this.consignmentNotesFormGroup.patchValue({
        // searchCustomer: this.selectedCustomer.name,
        consignor_location_code: this.selectedBooking
          .source_location_code,
        consignee_location_code: this.selectedBooking
          .destination_location_code
      });
      // this.selectedBookingValue = this.selectedBooking.id + '';
      // console.log(this.consignmentNotesFormGroup.value);
    }
  }
  selectOwnerName(aData: Partners) {
    this.attachTruckDocumentsFormGroup.patchValue({ partner_company_name: aData.name });
    this.attachTruckDocumentsFormGroup.patchValue({ partners_id: aData.id });
    this.attachTruckDocumentsFormGroup.patchValue({ pan_no: aData.pan_no });
    this.attachTruckDocumentsFormGroup.patchValue({
      pan_card_scan: aData.pan_card_scan
    });
    if (aData.tds_declaration_scan) {
      this.attachTruckDocumentsFormGroup.patchValue({
        tds_declaration_scan: aData.tds_declaration_scan
      });
    }

  }
  submitTruckDocument() {
    //  console.log('add truck document form');
    //  console.log(this.attachTruckDocumentsFormGroup);
    if (!this.attachTruckDocumentsFormGroup.valid) {
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.attachTruckDocumentsFormGroup
      );
      appGlobals.markFormGroupTouched(this.attachTruckDocumentsFormGroup);
      if (!this.attachTruckDocumentsFormGroup.get('registration_scan').valid
        && this.attachTruckDocumentsFormGroup.get('registration_scan').errors
          .required
      ) {
        alert('RC scan is required');
      }
      if (!this.attachTruckDocumentsFormGroup.get('driving_license_scan').valid
        && this.attachTruckDocumentsFormGroup.get('driving_license_scan').errors
          .required
      ) {
        alert('Driving License copy is required');
      }
    } else {
      const formData = this.attachTruckDocumentsFormGroup.value;
      //  console.log(formData);
      this.tripsService.attachTruckDocument(formData).subscribe(
        response => {
          if (response.status === 200) {
            this.closeModalGeneric();
            //  console.log('get response called');
            this.getResponse(this.currentPage);
            this._snackBarService.open(response.message, 'X', {
              duration: appGlobals.SNACKBAR_TIMEOUT
            });
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: response.message
            });
          }
        },
        error => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error)
          });
        }
      );
    }
  }
  getStringBeforeComma(str) {
    return appGlobals.getStringBeforeComma(str);
  }
  getTruckTypeValue(key) {
    const value = appGlobals.getDropDownValue('truck_type_cd', key);
    if (value && value != null) {
      return value.substring(0, 20);
    }
    return '';
  }
  openModalGeneric(template: TemplateRef<any>, aData?: any) {
    if (this.usePreviewPanel && this.isDesktop()) {
      this.currentTemplate = template;
      this.modalRef = null;
    }
    else {
      //  console.log('openging modal ' + this.modalService.getModalsCount());
      //  console.log(template.elementRef);
      //  console.log(this.modalService.getModalsCount());
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
      //  console.log(this.modalRef);
      //  console.log(this.modalService.getModalsCount());
      // this.allModelRefs.push(this.modalRef);
      StorageService.modelRefs.push(this.modalRef);
      return this.modalRef;
    }
    return this.modalRef;
  }
  prepareUpdateConsignment() {

  }
  closeModalGeneric(aModelRef?: BsModalRef) {

    if (StorageService.modelRefs.length > 0) {
      this.modalRef = StorageService.modelRefs.pop();
      this.modalRef.hide();

    }
    else if (this.currentTemplate) {
      // this.currentTemplate = aTemplate;
      //  console.log(this.currentTemplate);
      this.currentTemplate = null;
    }
    else if (this.modalRef || this.modalRef != null) {
      //  console.log('model ref is null');
      this.modalRef.hide();
    }
    //  console.log('closing generic modal');
  }
  getConsignmentListForTrip(tripsId) {
    return this.tripsService
      .getTripsById(tripsId)
      .subscribe(response => {
        //  console.log(response);
        // this.setConsignmentResponse(response.data);
        if (response.data.consignment_trips) {
          response.data.consignment_trips.forEach(aCNote => {
            this.consignmentNotesList.push(aCNote);
          });
        }
      });
  }
  getResponse(id) {
    return this.consignmentNotesService
      .getConsignmentNotesById(id)
      .subscribe(response => {
        //  console.log(response);
        this.setConsignmentResponse(response.data);

      });
  }
  isValifForm() {
    let result = true;
    //  console.log('checking  validity');
    if (!this.consignmentNotesFormGroup.valid) {
      //  console.log('form group is  not  valid');
      this.isError = true;
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.consignmentNotesFormGroup
      );
      appGlobals.markFormGroupTouched(this.consignmentNotesFormGroup);
      if (!this.consignmentItemsFormGroup.valid) {
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Please add consignment items'
        });
      } else {
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Please complete all details'
        });
      }

      // alert('Please complete all details');
      result = false;
    } else {
      // this.consignmentNotes = new ConsignmentNotes(
      //   this.consignmentNotesFormGroup.value
      // );
      //  console.log('form group is valid');
      //  console.log(this.consignmentNotesFormGroup.value);
      //  console.log(this.consignmentNotesFormGroup);
      //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').valid);
      //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').value);
      //  console.log(this.consignmentNotesFormGroup.get('eway_bill_no').validator);
      this.consignmentNotes.fill(this.consignmentNotesFormGroup.value);

      this.consignmentNotes.consignment_items = this.consignmentItemList;
      this.consignmentNotes.bookings = this.selectedBooking;
      this.consignmentNotes.consignment_trips = new Array(
        this.consignment_trips
      );
      //  console.log(this.consignmentNotes.consignment_items);
      if (
        !this.consignmentNotes.consignment_items ||
        this.consignmentNotes.consignment_items.length === 0
      ) {
        this._dialogService.openAlert({
          title: 'Error',
          message: 'Please add consignment Items'
        });
        result = false;
      }
    }
    return result;
  }
  getReportingAt(aTrip: Trips) {
    if (!aTrip) {
      aTrip = this.trips;
    }
    if (aTrip && aTrip.truck_hire_challans
      && aTrip.truck_hire_challans[0]) {
      // console.log(aTrip.truck_hire_challans[0].reporting_at);
      return aTrip.truck_hire_challans[0].reporting_at;
    }
  }
  submitLoaded() {

    // if (this.isValifForm()) {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: 'PICKED UP'
    });
    return this.submit();
    // }
  }
  submitBooked() {
    // if (this.isValifForm()) {
    this.consignmentNotesFormGroup.patchValue({
      consignment_status_cd: 'BOOKED'

    });

    return this.submit();
    // }
  }
  submit() {
    //  console.log(this.consignmentNotesFormGroup.value);
    if (this.consignmentItemList.length == 0) {
      this.updateItemInConsignmentForm();
    }
    //return;
    if (!this.isValifForm()) {
      return;
    } else {
      this.consignmentNotes = new ConsignmentNotes(
        this.consignmentNotesFormGroup.value
      );
      this.consignmentNotes.consignment_items = this.consignmentItemList;
      this.consignmentNotes.eway_bills = this.consignmentEwayBillList;
      this.consignmentNotes.bookings = this.selectedBooking;
      if (!this.consignment_trips) {
        this.consignment_trips = new ConsignmentTrips();
        if (this.trips_id) {
          this.consignment_trips.trips_id = this.trips_id;
        }
      }
      this.consignmentNotes.consignment_trips = new Array(
        this.consignment_trips
      );
      //  console.log(this.consignmentNotes);
      if (this.consignmentNotes.id) {
        this.consignmentNotesService
          .updateConsignmentNotes(this.consignmentNotes)
          .subscribe(
            response => {
              if (response.status === 200) {
                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });

                //  console.log(response);
                this.consignmentNotes = new ConsignmentNotes(response.data);
                if (!this.consignmentNotesList) {
                  this.consignmentNotesList = [];
                }
                //  console.log(this.consignmentNotesList);
                appGlobals.replaceAppendObjectInArray(this.consignmentNotes, this.consignmentNotesList);

                if (this.isExternalCall) {
                  this.setConsignmentNotes.emit(this.consignmentNotes);
                  this.onComplete.emit(true);

                }
                else {
                  this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                  this.contentTemplate = this.cNoteList;
                  this.isSingleIncompleteLR = false;
                  this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
                  this.setTabsAndContent();
                }

                // this.openModalGeneric();
                // const confResult = confirm('LR ' + this.consignmentNotes.consignment_no + ' created.'
                //   + ' Do you want to mark PAPER WORK COMPLETE');
                // if (confResult) {
                //   // this.markPaperWorkComplete();
                //   this.onComplete.emit();
                // }
                // this._location.back();
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
            }
          );
      } else {
        this.consignmentNotesService
          .addConsignmentNotes(this.consignmentNotes)
          .subscribe(
            response => {
              if (response.status === 200) {
                // this._snackBarService.open(response.message, 'X', {
                //   duration: appGlobals.SNACKBAR_TIMEOUT
                // });
                // this.consignmentNotes = new ConsignmentNotes(response.data);
                // appGlobals.replaceAppendObjectInArray(this.consignmentNotes, this.consignmentNotesList);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;


                this._snackBarService.open(response.message, 'X', {
                  duration: appGlobals.SNACKBAR_TIMEOUT
                });
                //  console.log(response);
                this.consignmentNotes = new ConsignmentNotes(response.data);
                if (!this.consignmentNotesList) {
                  this.consignmentNotesList = [];
                }
                //  console.log(this.consignmentNotesList);
                appGlobals.replaceAppendObjectInArray(this.consignmentNotes, this.consignmentNotesList);
                this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                this.contentTemplate = this.cNoteList;
                this.isSingleIncompleteLR = false;
                this.selectedDispatchActivity = 'MARK PAPER WORK DONE';
                this.setTabsAndContent();

                // this.consignmentNotesList.push(this.consignmentNotes);
                // this.cNoteListDataSource = new CNoteDataSource(this.consignmentNotesList);
                // console.log(this.consignmentNotesList);
                // this.contentTemplate = this.cNoteList;
                // this.router.navigate(['consignmentNotes/list']);
              } else {
                this._dialogService.openAlert({
                  title: 'Error',
                  message: response.message
                });
              }
            },
            error => {
              this._dialogService.openAlert({
                title: 'Error',
                message: error,
                flag: 1
              });
            }
          );
      }
    }
  }
  markPaperWorkComplete() {

    let formData = {
      trips_id: this.trips_id,
      consignment_notes_id: this.consignment_notes_id,
      trips_status_cd: 'PAPER WORK DONE',
      status_type_cd: 'STATUS',
      status_at: new Date(),
      status_at_time: (new Date()).toTimeString()
    };
    formData.status_at = new Date((formData.status_at.toDateString() + ' ' + formData.status_at_time).replace(/-/g, "/"));
    //  console.log(formData);
    // console.log(formData.status_at);
    this.consignmentTripTrackingsService.updateStatus(formData).subscribe(
      response => {
        if (response.status === 200) {
          // this.closeModalGeneric();
          // this.getResponse(this.currentPage);
          window.scrollTo(0, 0);
          this._snackBarService.open(response.message, 'X', {
            duration: appGlobals.SNACKBAR_TIMEOUT
          });
        } else {
          this._dialogService.openAlert({
            title: 'Error',
            message: response.message
          });
        }
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  openModalEway(modalTag: TemplateRef<any>) {
    this.consignmentsEwayFormGroup.reset();
    this.consignmentsEwayFormGroup.patchValue({ consignment_notes_id: this.consignmentNotes.id });
    this.openModalGeneric(modalTag);
  }

  openModal(addConsignment: TemplateRef<any>, option = null) {
    // this.consignmentItemsFormGroup.reset();
    this.getNewConsignmentItemForm()
    if (option) {
      this.consignmentItemsFormGroup.patchValue(option);
    } else {
      this.consignmentItemsFormGroup.patchValue({
        eway_bill_no: this.consignmentNotesFormGroup.value.eway_bill_no,
        invoice_no: this.consignmentNotesFormGroup.value.invoice_no,
        invoice_dt: this.consignmentNotesFormGroup.value.invoice_dt,
        invoice_value: this.consignmentNotesFormGroup.value.invoice_value,
        consignment_notes_id: this.consignmentNotesFormGroup.value.id,
        _id: this.consignmentItemList.length + 1
      });

    }
    // console.log(this.consignmentItemsFormGroup.value);
    this.openModalGeneric(addConsignment);

  }
  selectconsigneeStateCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      consignee_state_cd: event
    });
  }
  selectconsignorStateCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      consignor_state_cd: event
    });
  }
  selectpaymentBasisCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      payment_basis_cd: event
    });
  }
  selectbillingBranchCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      billing_branch_cd: event
    });
  }
  selectcollectionBranchCd(event) {
    this.consignmentNotesFormGroup.patchValue({
      collection_branch_cd: event
    });
  }
  uploadConsignmentNote(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'CONSIGNMENT')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          consignment_scan: response.data.filename
        });
        this.consignmentNotes.consignment_scan = response.data.filename;
      });
  }
  uploadInvoice(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'INVOICE')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          invoice_scan: response.data.filename
        });
        this.consignmentNotes.invoice_scan = response.data.filename;
      });
  }
  uploadTruck(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'TRUCK')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          truck_scan: response.data.filename
        });
        this.consignmentNotes.truck_scan = response.data.filename;
      });
  }
  uploadEway(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'EWAY')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          eway_bill_scan: response.data.filename
        });
        this.consignmentNotes.eway_bill_scan = response.data.filename;
      });
  }
  uploadEmptyLetter(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'EMPTY LETTER')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          empty_letter_scan: response.data.filename
        });
        this.consignmentNotes.empty_letter_scan = response.data.filename;
      });
  }
  updatePOD(event, cnote: ConsignmentNotes, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'POD')
      .subscribe(response => {
        cnote.pod_scan = response.data.filename;
      });
  }
  fileChange2(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'POD')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          pod_scan: response.data.filename
        });
        this.consignmentNotes.pod_scan = response.data.filename;
      });
  }
  fileChange3(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'POD')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          pod_scan_2: response.data.filename
        });
        this.consignmentNotes.pod_scan_2 = response.data.filename;
      });
  }
  fileChange4(event, entityId) {
    this.fileTransferService
      .uploadEntityDocument(event.srcElement.files[0], entityId, 'consignmentNote', 'GATE IN')
      .subscribe(response => {
        this.consignmentNotesFormGroup.patchValue({
          gate_in_scan: response.data.filename
        });
        this.consignmentNotes.gate_in_scan = response.data.filename;
      });
  }
  selectedTab(event) {
    this.selectedTabIndex = event.index;
  }
  needPickButton() {
    const consignmentStatus = this.consignmentNotesFormGroup.get(
      'consignment_status_cd'
    ).value;
    if (
      !consignmentStatus ||
      consignmentStatus === 'INCOMPLETE' ||
      consignmentStatus === 'BOOKED'
    ) {
      return true;
    }
    return false;
  }
  getStatuses() {
    return ConsignmentNotes.consignmentStatuses;
  }
  makePODMandatory() {
    const consignmentStatus = this.consignmentNotesFormGroup.get('consignment_status_cd').value;
    if (consignmentStatus === 'ePOD UPLOADED' || consignmentStatus === 'HARD POD RECEIVED') {
      this.consignmentNotesFormGroup.controls['pod_scan'].setValidators(Validators.required);
      this.consignmentNotesFormGroup.controls['pod_scan'].updateValueAndValidity();

    }
  }
  getDeliveryConditions() {
    return ConsignmentNotes.consignmentDeliveryCondition;
  }

  prepareAttachDocuments(thc: TruckHireChallans) {
    this.attachTruckDocumentsFormGroup = this._formBuilder.group(attachTruckDocumentsFormControls);

    this.attachTruckDocumentsFormGroup.patchValue({ trips_id: thc.trips_id });
    this.attachTruckDocumentsFormGroup.patchValue({
      partner_type_cd: 'FLEET OWNER'
    });
    this.truckHireChallan = thc;
    //  console.log(this.truckHireChallan);
    this.attachTruckDocumentsFormGroup.patchValue({
      truck_hire_challans_id: this.truckHireChallan.id
    });
    this.attachTruckDocumentsFormGroup.patchValue({
      tds_declaration_scan: this.truckHireChallan.tds_declaration_scan
    });

    if (this.truckHireChallan.partners) {
      const partner = this.truckHireChallan.partners;
      this.attachTruckDocumentsFormGroup.patchValue({
        partners_id: partner.id
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        partner_company_name: partner.name
      });
      this.attachTruckDocumentsFormGroup.patchValue({ pan_no: partner.pan_no });
      this.attachTruckDocumentsFormGroup.patchValue({
        pan_card_scan: partner.pan_card_scan
      });
      if (partner.partner_contacts && partner.partner_contacts[0] && partner.partner_contacts[0].user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: partner.partner_contacts[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: partner.partner_contacts[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: partner.partner_contacts[0].user.mobile_no
        });
      }
    }
    if (this.truckHireChallan.truck) {
      const truck = this.truckHireChallan.truck;
      this.attachTruckDocumentsFormGroup.patchValue({
        trucks_id: truck.id
      });
      if (truck.owner_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partners_id: truck.owner_id
        });
      }
      if (truck.owner_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_company_name: truck.owner_name
        });
      }
      if (truck.owner_contacts_id) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contacts_id: truck.owner_contacts_id
        });
      }
      if (truck.owner_contacts_name) {
        this.attachTruckDocumentsFormGroup.patchValue({
          partner_contact_name: truck.owner_contacts_name
        });
      }
      if (truck.owner_contact && truck.owner_contact.user) {
        this.attachTruckDocumentsFormGroup.patchValue({
          mobile_no: truck.owner_contact.user.mobile_no
        });
      }

      if (truck.owner_partner) {
        this.attachTruckDocumentsFormGroup.patchValue({ pan_no: truck.owner_partner.pan_no });
        this.attachTruckDocumentsFormGroup.patchValue({
          pan_card_scan: truck.owner_partner.pan_card_scan
        });
      }
      if (this.truckHireChallan.truck.drivers && this.truckHireChallan.truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          drivers_id: truck.drivers[0].id
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_name: truck.drivers[0].user.name
        });
        this.attachTruckDocumentsFormGroup.patchValue({
          driver_mobile_no: truck.drivers[0].user.mobile_no
        });
      }
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({ trucks_id: truck.id });
      this.attachTruckDocumentsFormGroup.patchValue({
        registration_scan: truck.registration_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        fitness_scan: truck.fitness_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        insurance_scan: truck.insurance_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        permit_scan: truck.permit_scan
      });
      this.attachTruckDocumentsFormGroup.patchValue({
        pollution_scan: truck.pollution_scan
      });
      if (truck.drivers[0] && truck.drivers[0]) {
        this.attachTruckDocumentsFormGroup.patchValue({
          driving_license_scan: truck.drivers[0].driving_license_scan
        });

        this.attachTruckDocumentsFormGroup.patchValue(
          { mobile_provider_cd: truck.drivers[0].user.mobile_provider_cd },
          { emitEvent: false }
        );
        this.attachTruckDocumentsFormGroup.patchValue(
          { telenity_enabled: truck.drivers[0].user.telenity_enabled },
          { emitEvent: false }
        );
      }
    }
    this.observePartnerName();
    //  console.log(this.attachTruckDocumentsFormGroup.value);

  }
  prepareUpdateStatus() {
    const next_status_cd = this.getNextTripsStatus(this.trips);
    //  console.log('next status ' + next_status_cd);
    this.consignmentTripTrackingsFormGroup = this._formBuilder.group(consignmentTripTrackingsFormControls);
    this.consignmentTripTrackingsFormGroup.patchValue({
      trips_id: this.trips.id
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      tasks_id: this.trips.tasks_id
    });
    if (next_status_cd) {
      this.consignmentTripTrackingsFormGroup.patchValue({
        trips_status_cd: next_status_cd
      });
    } else {
      this.consignmentTripTrackingsFormGroup.patchValue({
        trips_status_cd: this.trips.trips_status_cd
      });
    }
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location: this.trips.location
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_lat: this.trips.location_lat
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      last_location_long: this.trips.location_long
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_type_cd: 'STATUS'
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      is_visible_to_customer: false
    });
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at: new Date()
    });
    // const currentTime = (new Date()).getHours() + ':' + (new Date()).getMinutes();
    // const currentTime = (new Date()).toTimeString();
    // console.log('current time is ' + currentTime);
    let currentHour = (new Date()).getHours() + '';
    if (currentHour.length === 1) {
      currentHour = '0' + currentHour;
    }
    let currentMinutes = (new Date()).getMinutes() + '';
    if (currentMinutes.length === 1) {
      currentMinutes = '0' + currentMinutes;
    }
    const currentTime = currentHour + ':' + currentMinutes;
    this.consignmentTripTrackingsFormGroup.patchValue({
      status_at_time: currentTime
    });
  }


  isDesktop() {
    if (!this.innerWidth) {
      this.innerWidth = window.innerWidth;
    }
    //  console.log(this.innerWidth);
    if (this.innerWidth > 1000) {
      return true;
    }
    return false;
  }

}

