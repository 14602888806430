import { Partners } from '../partners/partners';
import { PartnerContactAddresses } from '../partnerContactAddresses/partnerContactAddresses';
import { Users } from '../users/users';
import { Validators } from '@angular/forms';
import { safeDate } from '../../core/globalfunctions';
export class PartnerContacts {
  id: number;
  designation: string;
  is_active: number;
  operational_status_cd: string;
  reports_to: number;
  verified_by: number;
  verified_at: Date;
  is_descision_maker: number;
  contacts_function_cd: string;
  users_id: number;
  partners_id: number;
  target_hire_amt:number;
  partners: Partners;
  partner_contact_addresses: PartnerContactAddresses[];
  user: Users;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): PartnerContacts[] {
    let partnerContacts: PartnerContacts[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        partnerContacts.push(new PartnerContacts(json));
      }
    }
    return partnerContacts;
  }
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
      this.designation = json.designation;
      this.is_active = json.is_active;
      this.operational_status_cd = json.operational_status_cd;
      this.reports_to = json.reports_to;
      this.verified_by = json.verified_by;
      safeDate(this, json,  'verified_at');

      this.is_descision_maker = json.is_descision_maker;
      this.contacts_function_cd = json.contacts_function_cd;
      this.users_id = json.users_id;
      this.partners_id = json.partners_id;
      if (json.partners != null) {
        this.partners = new Partners(json.partners);
      }
      if (json.partner_contact_addresses) {
        this.partner_contact_addresses = PartnerContactAddresses.toArray(json.partner_contact_addresses);
      }
      if (json.user) {
        this.user = new Users(json.user);
      }
    }
  }
}

export let partnerContactsFormControls = {
  id: ['', []],
  designation: ['', []],
  is_active: ['', []],
  operational_status_cd: ['', []],
  reports_to: ['', []],
  verified_by: ['', []],
  verified_at: ['', []],
  is_descision_maker: ['', []],
  contacts_function_cd: ['', []],
  users_id: ['', []],
  partners_id: ['', []],
};
