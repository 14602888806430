import { TruckDrivers } from '../truckDrivers/truckDrivers';
import { TruckPartners } from '../truckPartners/truckPartners';
import { Gpss } from '../gpss/gpss';
import { Validators } from '@angular/forms';
import { Drivers } from '../drivers/drivers';
import { Partners } from '../partners/partners';
import { PartnerContacts } from '../partnerContacts/partnerContacts';
import { UserBidHistory } from '../userBidHistory/userBidHistory';
import { Users } from '../users/users';
import { safeDate } from '../../core/globalfunctions';
export class Trucks {
  id : number;
  registration_no : string;
  registration_expiry_dt : Date;
  registration_scan : string;
  registered_address : string;
  owner_name : string;
  insurance_provider : string;
  insurance_policy_no : string;
  insurance_expiry_dt : Date;
  insurance_scan : string;
  fitness_expiry_dt : Date;
  fitness_scan : string;
  permit_type_cd : string;
  permit_scan : string;
  permit_validity_dt : Date;
  pollution_scan : string;
  pollution_expiry_dt : Date;
  truck_type_cd : string;
  truck_status_cd : string;
  container_type_cd : string;
  status : number;
  kms_driven : number;
  container_no : string;
  container2_no : string;
  model : string;
  makers_name : string;
  chasis_no : string;
  engine_no : string;
  vehicle_class_cd : string;
  body_type_cd : string;
  leased_agreement_with : string;
  manufacturing_year : string;
  verified_by : string;
  verified_at : Date;
  base_location : string;
  webxpress_truck_code : string;
  current_location : string;
  last_location_lat : number;
  last_location_long : number;
  relations_cd : string;
  truck_drivers: TruckDrivers[];
  drivers: Drivers[];
  truck_partners: TruckPartners[];
  gps: Gpss;
  bids_id: number;
  billing_branch_cd: string;
  tracking_executive_id: number;
  tracking_executive_name: string;
  ops_executive_id: number;
  ops_executive_name: string;
  finance_executive_id: number;
  finance_executive_name: string;
  owner_id: number;
  owner_partner: Partners;
  owner_users_id: number;
  owner_contacts_id: number;
  owner_user: Users;
  owner_contacts_name: string;
  owner_contact: PartnerContacts;
  vahan_checked: number;
  vahan_checked_at: Date;

  trucks_front_scan: string;
  trucks_back_scan: string;
  trucks_side_scan: string;
  trucks_tyre_scan: string;
  trucks_nib_scan: string;
  trucks_tarpoline_scan: string;
  trucks_selfie_scan: string;

    constructor(json? : any) {
        this.fill(json);
        }
        static toArray(jsons : any[]) : Trucks[] {
          let trucks : Trucks[] = [];
          if (jsons != null) {
              for (let json of jsons) {
                trucks.push(new Trucks(json));
              }
          }
          return trucks;
      }

      fill(json: any){
        if (json != null) {
          this.id = json.id;
            this.registration_no = json.registration_no;
            safeDate(this, json,  'registration_expiry_dt');

            this.registration_scan = json.registration_scan;
            this.registered_address = json.registered_address;
            this.owner_name = json.owner_name;
            this.insurance_provider = json.insurance_provider;
            this.insurance_policy_no = json.insurance_policy_no;
            safeDate(this, json,  'insurance_expiry_dt');

            this.insurance_scan = json.insurance_scan;
            safeDate(this, json,  'fitness_expiry_dt');

            this.fitness_scan = json.fitness_scan;
            this.permit_type_cd = json.permit_type_cd;
            this.permit_scan = json.permit_scan;
            safeDate(this, json,  'permit_validity_dt');

            this.pollution_scan = json.pollution_scan;
            safeDate(this, json,  'pollution_expiry_dt');

            this.truck_type_cd = json.truck_type_cd;
            this.truck_status_cd = json.truck_status_cd;
            this.container_type_cd = json.container_type_cd;
            this.status = json.status;
            this.kms_driven = json.kms_driven;
            this.container_no = json.container_no;
            this.container2_no = json.container2_no;
            this.model = json.model;
            this.makers_name = json.makers_name;
            this.chasis_no = json.chasis_no;
            this.engine_no = json.engine_no;
            this.vehicle_class_cd = json.vehicle_class_cd;
            this.body_type_cd = json.body_type_cd;
            this.leased_agreement_with = json.leased_agreement_with;
            this.manufacturing_year = json.manufacturing_year;
            this.verified_by = json.verified_by;
            safeDate(this, json,  'verified_at');

            this.base_location = json.base_location;
            this.webxpress_truck_code = json.webxpress_truck_code;
            this.current_location = json.current_location;
            this.last_location_lat = json.last_location_lat;
            this.last_location_long = json.last_location_long;
            this.relations_cd = json.relations_cd;
            if (json.gps != null) {
              this.gps = new Gpss(json.gps);
           }
          if (json.truck_partners != null) {
             this.truck_partners = TruckPartners.toArray(json.truck_partners);
          }
          if (json.truck_drivers != null) {
            this.truck_drivers = TruckDrivers.toArray(json.truck_drivers);
          }
          if (json.drivers != null) {
            this.drivers = Drivers.toArray(json.drivers);
          }
          this.bids_id = json.bids_id;
          this.billing_branch_cd = json.billing_branch_cd;
          this.tracking_executive_id = json.tracking_executive_id;
          this.tracking_executive_name = json.tracking_executive_name;
          this.ops_executive_id = json.ops_executive_id;
          this.ops_executive_name = json.ops_executive_name;
          this.finance_executive_id = json.finance_executive_id;
          this.finance_executive_name = json.finance_executive_name;
          this.owner_id = json.owner_id;
          this.owner_name = json.owner_name;
          this.owner_users_id = json.owner_users_id;
          this.owner_contacts_name = json.owner_contacts_name;

          if(json.owner_partner){
            this.owner_partner = new Partners(json.owner_partner);
          }
          if(json.owner_contact){
            this.owner_contact = new PartnerContacts(json.owner_contact);
          }
          if(json.owner_user){
            this.owner_user = new Users(json.owner_user);
          }
          this.owner_contacts_id = json.owner_contacts_id;
          this.vahan_checked  =  json.vahan_checked;
          safeDate(this, json,  'vahan_checked_at');

          this.trucks_back_scan = json.trucks_back_scan;
          this.trucks_front_scan = json.trucks_front_scan;
          this.trucks_side_scan = json.trucks_side_scan;
          this.trucks_selfie_scan = json.trucks_selfie_scan;
          this.trucks_tyre_scan = json.trucks_tyre_scan;
          this.trucks_nib_scan = json.trucks_nib_scan;
          this.trucks_tarpoline_scan = json.trucks_tarpoline_scan;


      }
    }
  }
  export let trucksFormControls = {
    id: ['', []],
    registration_no: ['', [Validators.required]],
    registration_expiry_dt: ['', []],
    registration_scan: ['', []],
    registration_scan_url: ['', []],
    registered_address: ['', [Validators.required]],
    owner_name: ['', [Validators.required]],
    owner_id:['', []],
    owner_contacts_id:['', []],
    insurance_provider: ['', []],
    insurance_policy_no: ['', []],
    insurance_expiry_dt: ['', []],
    insurance_scan: ['', []],
    insurance_scan_url: ['', []],
    fitness_expiry_dt: ['', []],
    fitness_scan: ['', []],
    fitness_scan_url: ['', []],
    permit_type_cd: ['', []],
    permit_scan: ['', []],
    permit_scan_url: ['', []],
    permit_validity_dt: ['', []],
    pollution_scan: ['', []],
    pollution_scan_url: ['', []],
    pollution_expiry_dt: ['', []],
    truck_type_cd: ['', [Validators.required]],
    truck_status_cd: ['', []],
    container_type_cd: ['', [Validators.required]],
    status: ['', []],
    kms_driven: ['', []],
    container_no: ['', []],
    container2_no: ['', []],
    model: ['', [Validators.required]],
    makers_name: ['', [Validators.required]],
    chasis_no: ['', [Validators.required]],
    engine_no: ['', [Validators.required]],
    vehicle_class_cd: ['', []],
    body_type_cd: ['', []],
    leased_agreement_with: ['', []],
    manufacturing_year: ['', []],
    verified_by: ['', []],
    verified_at: ['', []],
    base_location: ['', [Validators.required]],
    webxpress_truck_code: ['', []],
    current_location: ['', []],
    last_location_lat: ['', []],
    last_location_long: ['', []],
    relations_cd: ['', []],
    billing_branch_cd: ['', []],
    tracking_executive_id: ['', []],
    tracking_executive_name: ['', []],
    ops_executive_id: ['', []],
    ops_executive_name: ['', []],
    finance_executive_id: ['', []],
    finance_executive_name: ['', []],
    start_dt_1: ['', []],
    end_dt_1: ['', []],
    start_dt_2: ['', []],
    end_dt_2: ['', []],
    driver_1: ['', []],
    driver_2: ['', []],
    owner_contacts_name: ['', []],
    owner_users_id: ['', []],

  }
