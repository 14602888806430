import { DataSource } from '@angular/cdk/table';
import { Observable, of } from 'rxjs';

export class Entity {
  id: number;
  constructor(json?: any) {
    this.fill(json);
  }
  static toArray(jsons: any[]): Entity[] {
    let entities: Entity[] = [];
    if (jsons != null) {
      for (let json of jsons) {
        entities.push(new Entity(json));
      }
    }
    return entities;
  }
  static commandsAllIndex = [
  ];
  static allStatuses = [
  ];
  fill(json?: any) {
    if (json != null) {
      this.id = json.id;
    }
  }
}
export const entityFormControls = {
  id: ['', []],
};
export class EntityDataSource extends DataSource<any> {
  data: any;
  constructor(aData?: Entity[]) {
    super();
    this.data = aData;
  }
  connect(): Observable<Entity[]> {
    return of(this.data);
  }
  getIndex(id) {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id == id) {
        return index;
      }

    }
    return -1;
  }
  remove(id) {
    const itemIndex = this.getIndex(id);
 //  console.log('found ' + id + ' at ' + itemIndex);
    this.data = this.data.splice(itemIndex, 1);
 //  console.log('removed');
  }
  getLength(){
    return this.data.length;
  }
  getAsArray(){
    return this.data;
  }
  disconnect() { }
}
