/// <reference types="@types/googlemaps" />
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Subject } from 'rxjs';
import { AppSettings } from '../config';
import { StorageService } from '../../core/services/storage.service';
import { ErrorsHandlerService } from './errors.handler.service';
// import { MapsAPILoader } from '@agm/core';
// import { } from 'googlemaps';
// import { Router } from '@angular/router';
declare var google: any;
@Injectable()
export class HttpService {
  public loading = new Subject<{
    loading: boolean;
    hasError: boolean;
    hasMsg: string;
  }>();
  url: string;
  lat: number;
  lng: number;
  constructor(
    private _http: HttpClient,
    private errorHandlerService: ErrorsHandlerService
    // private router:Router,
  ) // private mapsAPILoader: MapsAPILoader,private ngZone: NgZone
  { }
  getAsset(assetlocation: string) {
    return this._http.get(assetlocation);
  }
  getResponseExcel(
    second_url: String,
    method: String,
    head: number = 0,
    aData = null,
    flag = 0,
    ignoreLoadingBar = 0
  ): Observable<any> {
    const basicAuth = btoa('glasswing' + ':' + 'pr0gre88');
    // this.url = BASE_URL + second_url;
    this.url = AppSettings.API_ENDPOINT + second_url;
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders
      // .set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8')
      .set('Content-Type', 'application/json; charset=utf-8')
      .append('Authorization', 'Bearer ' + StorageService.getToken())
      .append('Access-Control-Allow-Origin', '*')
      // .append('ngsw-bypass', 'true')
      ;

    if (ignoreLoadingBar === 1) {
      httpHeaders = httpHeaders.append('ignoreLoadingBar', '');
    }
    // return this.http.get(this.getappsecdesignfileurl, {responseType: 'blob', params: getfileparams, headers: getfileheaders});
    if (method === 'POST') {
      return this._http
        .post(this.url, aData,
          { responseType: 'blob' as 'json', headers: httpHeaders });
    }
    if (method === 'GET') {
      if (aData && Array.isArray(aData)) {
        this.url = this.url + '?';
        for (const key in aData) {
          const value = aData[key];
          this.url = this.url + key + '=' + value + '&';
        }
      }
      return this._http
        .get(this.url, { responseType: 'blob', headers: httpHeaders });
    }
  }
  getResponseDirect(
    second_url: string,
    method: String,
    head: number = 0,
    aData = null,
    flag = 0,
    ignoreLoadingBar = 0
  ): Observable<any> {
    const basicAuth = btoa('glasswing' + ':' + 'pr0gre88');
    // this.url = BASE_URL + second_url;
    this.url = second_url;
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders
      // .set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8')
      .set('Content-Type', 'application/json; charset=utf-8')
      .append('Authorization', 'Bearer ' + StorageService.getToken())
      .append('Access-Control-Allow-Origin', '*')
      // .append('ngsw-bypass', 'true')
      ;

    if (ignoreLoadingBar === 1) {
      httpHeaders = httpHeaders.append('ignoreLoadingBar', '');
    }
    // return this.http.get(this.getappsecdesignfileurl, {responseType: 'blob', params: getfileparams, headers: getfileheaders});
    if (method === 'POST') {
      return this._http
        .post(this.url, aData,
          { responseType: 'blob', headers: httpHeaders });
    }
    if (method === 'GET') {
      if (aData && Array.isArray(aData)) {
        this.url = this.url + '?';
        for (const key in aData) {
          const value = aData[key];
          this.url = this.url + key + '=' + value + '&';
        }
      }
      return this._http
        .get(this.url, { responseType: 'blob', headers: httpHeaders });
    }
  }
  getResponse(
    second_url: String,
    method: String,
    head: number = 0,
    data = null,
    flag = 0,
    ignoreLoadingBar = 0
  ): Observable<any> {


    const basicAuth = btoa('glasswing' + ':' + 'pr0gre88');
    // this.url = BASE_URL + second_url;
    this.url = AppSettings.API_ENDPOINT + second_url;
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json; charset=utf-8')
      .append('Authorization', 'Bearer ' + StorageService.getToken())
      .append('Access-Control-Allow-Origin', '*')
      // .append('ngsw-bypass', 'true')
      ;

    // if (StorageService.shouldSendLocation()) {
      const coord = StorageService.getLoc();
      // console.log(coord);
      if (!coord) {
        // console.log('storage service location returned false ');
        // return;
      } else {
        // console.log(' coord are ');
        // console.log( coord);
        httpHeaders = httpHeaders.append('loc', StorageService.getLoc());
        StorageService.locationSend();
      }
    // }
    if (ignoreLoadingBar === 1) {
      httpHeaders = httpHeaders.append('ignoreLoadingBar', '');
    }
    const options = {
      headers: httpHeaders
    };
    if (method === 'GET') {
      if (data && Array.isArray(data)) {
        this.url = this.url + '?';
        for (var key in data) {
          var value = data[key];
          this.url = this.url + key + '=' + value + '&';
        }
      }
      return this._http
        .get(this.url, options);
    } else if (method === 'POST') {
      return this._http
        .post(this.url, data, options);
    } else if (method === 'PUT') {
      return this._http
        .put(this.url, data, options);
    } else if (method === 'DELETE') {
      return this._http
        .delete(this.url, options);
    }
  }
  getGoogleResponse(origin_lat, origin_long, dest_lat, dest_long): any {
    const origin = new google.maps.LatLng(origin_lat, origin_long);
    const destination = new google.maps.LatLng(dest_lat, dest_long);
    const service = new google.maps.DistanceMatrixService();
    return Observable.create(observer => {
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        },
        function (results, status) {
          observer.next(results);
        }
      );
    });
  }
  getResponseFile(second_url: String, data: FormData): any {
    // this.url = BASE_URL + second_url;
    this.url = AppSettings.API_ENDPOINT + second_url;
    // return this._http.post(this.url, data).pipe(catchError(this.handleError));
    return this._http.post(this.url, data);
  }
  private handleError(error: HttpErrorResponse | Error | Response | any, flag) {

    let errMsg: string;
    // for (var key in error) {
    //  console.log(key + ' ' + error[key]);
    // }
    // console.log(error);
    // console.log(error.constructor.name);

    // return this.errorHandlerService.handleError(error);
    throw error;
    // return error;
  }
}
