import { safeDate } from './globalfunctions';

export class Deserializable {
    getTypes(): any {
        return false;
    }
    isPrimitive(obj) {
              switch (typeof obj) {
                  case "string":
                  case "number":
                  case "boolean":
                      return true;
              }
              return !!(obj instanceof String || obj === String ||
              obj instanceof Number || obj === Number ||
              obj instanceof Boolean || obj === Boolean);
    }
    deserialize(json, clazz?) {

        let instance = this;
        if(clazz) {
            instance = new clazz();
        }
        // if(clazz){
        //  console.log(json);
        //  console.log(clazz);
        // }

        let types = instance.getTypes();
        for(let prop in json) {
            if(!json.hasOwnProperty(prop)) {
              // console.log('json has own property retured false ' + prop);
                continue;
            }

            if(types && types[prop]) {
              if(json[prop] && json[prop] != null) {
                if(types[prop] == Date) {
                  // console.log( ' getting date from ' + json[prop] + ' to ' + json[prop].replace(/-/g, "/"));
                  safeDate(instance, json,  prop);
                  // instance[prop] = new types[prop](json[prop].replace(/-/g, "/"));
                  // console.log(instance[prop]);
                }
                else {
                  instance[prop] = new types[prop](json[prop]);
                }

              }

            }
            else if(json[prop] == null) {
              instance[prop] = json[prop];
            }
            else if(this.isPrimitive(json[prop])) {
              instance[prop] = json[prop];
            }
            else if(typeof json[prop] === 'object') {
              // console.log(prop + ' is object in json');
              // console.log(json[prop]);
           //  console.log(prop);
                instance[prop] = this.deserialize(json[prop], types[prop]);
            } else {
                instance[prop] = json[prop];
            }
        }

        return instance;
    }
}

// export class MapUtils {
// 	static isPrimitive(obj) {
//         switch (typeof obj) {
//             case "string":
//             case "number":
//             case "boolean":
//                 return true;
//         }
//         return !!(obj instanceof String || obj === String ||
//         obj instanceof Number || obj === Number ||
//         obj instanceof Boolean || obj === Boolean);
//     }

// 	static getClazz(target: any, propertyKey: string): any {

// 		return Reflect.getMetadata("design:type", target, propertyKey)
// 	}

// 	static getJsonProperty<T>(target: any, propertyKey: string):  IJsonMetaData {
// 		return Reflect.getMetadata(jsonMetadataKey, target, propertyKey);
// 	}

// 	static deserialize<T>(clazz:{new(): T}, jsonObject) {
//         if ((clazz === undefined) || (jsonObject === undefined)) return undefined;
//         let obj = new clazz();
//         Object.keys(obj).forEach((key) => {
//             let propertyMetadataFn:(IJsonMetaData) => any = (propertyMetadata)=> {
//                 let propertyName = propertyMetadata.name || key;
//                 let innerJson = undefined;
// 				innerJson = jsonObject ? jsonObject[propertyName] : undefined;
//                 let clazz = MapUtils.getClazz(obj, key);
//                 if (!MapUtils.isPrimitive(clazz)) {
//                     return MapUtils.deserialize(clazz, innerJson);
//                 } else {
//                     return jsonObject ? jsonObject[propertyName] : undefined;
//                 }
//             };

//             let propertyMetadata:IJsonMetaData = MapUtils.getJsonProperty(obj, key);
//             if (propertyMetadata) {
//                 obj[key] = propertyMetadataFn(propertyMetadata);
//             } else {
//                 if (jsonObject && jsonObject[key] !== undefined) {
//                     obj[key] = jsonObject[key];
//                 }
//             }
//         });
//         return obj;
//     }
// }
