import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http-service.service'

import { ResponseModel } from '../../../core/model/response';
@Injectable()
export class TruckHireChallansService {


  uploadToBank(aData: any) {
    return this.serviceHttp.getResponse('iciciinitiatetransaction', 'POST', 0, aData);
  }
  submitCheckBankTransStatus(aData: any) {
    return this.serviceHttp.getResponse('icicitransactionInquiry', 'POST', 0, aData);
  }
  processBankFile(data: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/processBankFile', 'POST', 0, data);
  }
  exportBankFileCsv(data: any): any {
    return this.serviceHttp.getResponseExcel('truckHireChallans/exportBankFileCsv', 'POST', 0, data);
  }
  exportBankFile(data: any): any {
    return this.serviceHttp.getResponseExcel('truckHireChallans/exportBankFile', 'POST', 0, data);
  }
  exportAdvanceRequestList(data: any): any {
    return this.serviceHttp.getResponseExcel('truckHireChallans/exportAdvanceRequestList', 'POST', 0, data);
  }

  approveAdvanceMultiple(formDataMultiple: any[]): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveAdvanceMultiple', 'POST', 0, formDataMultiple);
  }
  approveAdvance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveAdvance', 'POST', 0, formData);
  }
  cancelAdvanceRequest(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/cancelAdvanceRequest', 'POST', 0, formData);
  }
  cancelBalanceRequest(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/cancelBalanceRequest', 'POST', 0, formData);
  }
  markAdvanceDocsCompleted(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markAdvanceDocsCompleted', 'POST', 0, formData);
  }
  markAdvanceFinApproved(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markAdvanceFinApproved', 'POST', 0, formData);
  }
  markBalanceDocsCompleted(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markBalanceDocsCompleted', 'POST', 0, formData);
  }
  approveBalanceMultiple(formDataMultiple: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveBalanceMultiple', 'POST', 0, formDataMultiple);
  }
  markBalanceFinApproved(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markBalanceFinApproved', 'POST', 0, formData);
  }
  approveBalance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveBalance', 'POST', 0, formData);
  }
  attachTruckDocument(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/attachTruckDocument', 'POST', 0, data);
  }
  requestAdvanceMultiple(selected: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/requestAdvanceMultiple', 'POST', 0, selected);
  }
  exportList(data): any {
    return this.serviceHttp.getResponseExcel('truckHireChallans/exportList', 'POST', 0, data);
  }
  requestBalance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/requestBalance', 'POST', 0, formData);
  }
  markBalancePaid(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markBalancePaid', 'POST', 0, formData);
  }
  approveExcessHire(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveExcessHire', 'POST', 0, formData);
  }
  approveExcessAdvance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveExcessAdvance', 'POST', 0, formData);
  }
  approveExcessBalance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/approveExcessBalance', 'POST', 0, formData);
  }
  markAdvancePaid(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/markAdvancePaid', 'POST', 0, formData);
  }
  requestAdvance(formData: any): any {
    return this.serviceHttp.getResponse('truckHireChallans/requestAdvance', 'POST', 0, formData);
  }
  constructor(private serviceHttp:HttpService) { }
  getList(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/page', 'POST', 0, data);
  }
  getTruckHireChallansById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('truckHireChallans/'+ id, 'GET');
  }
  searchBrokersList(term): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('brokers/list', 'POST', 0, {search: term});
  }
  addTruckHireChallans(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('truckHireChallans', 'POST', 0, data);
  }
  updateTruckHireChallans(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/'+data.id, 'PUT', 0, data);
  }
  searchTruckHireChallansList(searchData): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/search', 'POST', 0, searchData);
  }
  syncThc(aData): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/syncThc', 'POST', 0, aData);
  }
  syncThcArrival(aData): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/syncThcArrival', 'POST', 0, aData);
  }
  syncThcStockUpdate(aData): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('truckHireChallans/syncThcStockUpdate', 'POST', 0, aData);
  }
}
