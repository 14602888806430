<div class="modal-header">
	<h4 class="modal-title pull-left">Create Bid</h4>
	<button type="button" class="close pull-right" aria-label="Close" data-dismiss="modal" (click)="cancel()">

		<span aria-hidden="true">&times;</span>
	</button>
</div>
<!-- <div *ngIf="isError && errorMessages.length" class="alert alert-danger">
	<ul>
		<li *ngFor="let error of errorMessages;">
			<strong>{{error}}</strong>
		</li>
	</ul>
</div> -->
<!-- <div *ngIf="message" class="alert alert-success">
	<strong>{{message}}</strong>
</div> -->
<div class="modal-body">
	<form class="example-form" [formGroup]="bidDetailsFormGroup">
		<div class="row">
			<div class="col-sm-6">
				<mat-form-field class="w-100">
					<input type="text" appUppercase matInput placeholder="Bid Name" formControlName="bid_name">
					<mat-error *ngIf="!bidDetailsFormGroup.get('bid_name').valid && bidDetailsFormGroup.get('bid_name').touched">
						<span *ngIf="bidDetailsFormGroup.get('bid_name').errors.required">Bid Name is required.</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-sm-3">
				<app-dropdowns [placeholder]="'Status'" [type]="'bid_status_cd'" [selected]="bidDetailsFormGroup.value.bid_status_cd" (onSelect)="setstatusCd($event)"></app-dropdowns>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-3">
				<mat-form-field class="w-100">
					<input matInput [min]="minStartDate" [matDatepicker]="bidStart" placeholder="Bid Start Date" formControlName="bid_start">
					<mat-datepicker-toggle matSuffix [for]="bidStart"></mat-datepicker-toggle>
					<mat-datepicker #bidStart></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-sm-3">
				<mat-form-field class="w-100">
						<input mat-timepicker matInput type="time" placeholder="Bid Start Time" formControlName="bid_start_time">
					</mat-form-field>
			</div>
			<div class="col-sm-3">
				<mat-form-field class="w-100">
					<input matInput [min]="minStartDate" [matDatepicker]="bidEnd" placeholder="Bid End Date" formControlName="bid_end">
					<mat-datepicker-toggle matSuffix [for]="bidEnd"></mat-datepicker-toggle>
					<mat-datepicker #bidEnd></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-sm-3">
				<mat-form-field class="w-100">
						<input mat-timepicker matInput type="time" placeholder="Bid End Time" formControlName="bid_end_time">
					</mat-form-field>
			</div>
		</div>
		<div class="row">
				<div class="col-sm-3">
					<app-dropdowns [placeholder]="'Truck Type'" [type]="'truck_type_cd'" [selected]="bidDetailsFormGroup.value.truck_type_cd" (onSelect)="settruckTypeCd($event)"></app-dropdowns>
					<mat-error *ngIf="!bidDetailsFormGroup.get('truck_type_cd').valid && bidDetailsFormGroup.get('truck_type_cd').touched">
							<span *ngIf="bidDetailsFormGroup.get('truck_type_cd').errors.required">Truck Type is Required</span>
						</mat-error>

				</div>
				<!-- <div class="col-sm-3">
					<app-dropdowns [placeholder]="'Weight'" [type]="'weight_cd'" [selected]="bidDetailsFormGroup.value.weight_cd" (onSelect)="setweightCd($event)"></app-dropdowns>
				</div> -->
				<!-- <div class="col-sm-3">
					<mat-form-field class="w-100">
						<input type="number" matInput placeholder="Number of Trucks" formControlName="no_of_trucks">
					</mat-form-field>
				</div> -->

				<div class="col-sm-3">
						<mat-form-field class="w-100">
          <!-- <mat-select required=true placeholder="Rate Metric(Per Ton / Per Truck)" [selected]="bidDetailsFormGroup.value.bids_metrics_cd" (onSelect)="selectbidsMetricsCd($event)"> -->
            <mat-select  required=true placeholder="Rate Metric(Per Ton / Per Truck)" formControlName="bids_metrics_cd">
								<mat-option value="TON">per Mt</mat-option>
								<mat-option value="TRUCK">per Truck</mat-option>
							</mat-select>
							<mat-error *ngIf="!bidDetailsFormGroup.get('bids_metrics_cd').valid && bidDetailsFormGroup.get('bids_metrics_cd').touched">
									<span *ngIf="bidDetailsFormGroup.get('bids_metrics_cd').errors.required">Rate Metric Required</span>
								</mat-error>

						</mat-form-field>

					</div>
					<div class="col-sm-3">
							<mat-form-field class="w-100">
								<input required type="number" min="0" matInput placeholder="Bid Starting Price" formControlName="start_price">
								<mat-error *ngIf="!bidDetailsFormGroup.get('start_price').valid && bidDetailsFormGroup.get('start_price').touched">
									<span *ngIf="bidDetailsFormGroup.get('start_price').errors.required">Bid Starting Price Required</span>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-3">
								<mat-form-field class="w-100">
									<input required type="number" min="0" matInput placeholder="Decrement Steps" formControlName="decrement_by">
									<mat-error *ngIf="!bidDetailsFormGroup.get('decrement_by').valid && bidDetailsFormGroup.get('decrement_by').touched">
										<span *ngIf="bidDetailsFormGroup.get('decrement_by').errors.required">Decrement steps is required</span>
									</mat-error>
								</mat-form-field>
							</div>
			</div>

		<div class="row  mTop15 mBtm10">
				<div class="col-sm-2">
						<!-- <mat-form-field > -->
						Booking:  <input readonly type="text" matInput placeholder="Booking " formControlName="booking_no">
						 / <input readonly type="text" matInput placeholder=" " formControlName="trip_no">
						<!-- </mat-form-field> -->
					</div>
			<div class="col-sm-2">
				<!-- <mat-form-field > -->
				Origin:  <input readonly type="text" matInput placeholder="Source Location " formControlName="source_location">
				<!-- </mat-form-field> -->
			</div>
			<div class="col-sm-2">
				<!-- <mat-form-field > -->
				Destination:  <input readonly type="text" matInput placeholder="Destination Location " formControlName="destination_location">
				<!-- </mat-form-field> -->
			</div>
			<div class="col-sm-2">
					Product Type: <input readonly type="text" matInput placeholder="Product Type " formControlName="goods_type_cd">
				<!-- <app-dropdowns [placeholder]="'Product Type'" [type]="'goods_type_cd'" [selected]="bidDetailsFormGroup.value.goods_type_cd" (onSelect)="setgoodsTypeCd($event)"></app-dropdowns> -->
			</div>
			<div class="col-sm-2">
					Pickup Date: <input readonly type="text" matInput placeholder="Pickup Date & Time" formControlName="pick_up_date">
			 </div>
			 <div class="col-sm-2">
					Pickup time: <input readonly type="text" matInput placeholder="Pickup Date & Time" formControlName="booking_for_slot_cd">
					<!-- <app-dropdowns [type]="'booking_for_slot_cd'" [selected]="bidDetailsFormGroup.value.booking_for_slot_cd" (onSelect)="setbookingForSlotCd($event)" -->
					<!-- (touched)="bidDetailsFormGroup.get('booking_for_slot_cd').markAsTouched()" [placeholder]="'Booking For Slot Cd'" [valid]="bidDetailsFormGroup.get('booking_for_slot_cd').valid"></app-dropdowns> -->

					<!-- <app-dropdowns [placeholder]="'Product Type'" [type]="'goods_type_cd'" [selected]="bidDetailsFormGroup.value.goods_type_cd" (onSelect)="setgoodsTypeCd($event)"></app-dropdowns> -->
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-sm-3">
				<mat-form-field class="w-100">
					<input matInput [matDatepicker]="picker" placeholder="Pickup Date & Time" formControlName="pick_up_date">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-sm-2">
				<app-dropdowns [type]="'booking_for_slot_cd'" [selected]="bidDetailsFormGroup.value.booking_for_slot_cd" (onSelect)="setbookingForSlotCd($event)"
					(touched)="bidDetailsFormGroup.get('booking_for_slot_cd').markAsTouched()" [placeholder]="'Booking For Slot Cd'" [valid]="bidDetailsFormGroup.get('booking_for_slot_cd').valid"></app-dropdowns>
				<mat-error class="mapError" *ngIf="!bidDetailsFormGroup.get('booking_for_slot_cd').valid && bidDetailsFormGroup.get('booking_for_slot_cd').touched">
					<span>Booking For Slot is required.</span>
				</mat-error>
			</div>
		</div> -->
		<div class="row mBtm10">
			<div class="col-sm-6 text-left">
				<button mat-raised-button color="warn" data-dismiss="modal" (click)="cancel()">Cancel</button>
				<!-- <span class="button" , data-dismiss="modal" aria-label="Close">cancel</span> -->
			</div>
			<div class="col-sm-6 text-right">
				<button mat-raised-button color="primary" (click)="submitBidDetailsForm()">Save</button>
			</div>
		</div>
	</form>
</div>
