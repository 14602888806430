<div class=" content_area_list container inne_area_list mobScroll mob-p-default ">
  <div class="row example-header">
    <div class="col-sm-9 text-center">
      <form [formGroup]="searchForm">
        <div class="row" #searchBar *ngIf="searchBarOpen">
          <div class="col-sm-3">
            <mat-form-field floatPlaceholder="never" class="notingabsurd">
              <input matInput #filter placeholder="Search Consignment Notes" formControlName="search">
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <!-- <app-dropdowns [multiple]=true [placeholder]="'Consignment Status'" [required]=true [type]="'consignment_status_cd'"
              [selected]="searchForm.value.consignment_status_cd" (onSelect)="setSearchStatusCd($event)"></app-dropdowns> -->
            <mat-form-field class="w-100">
              <mat-select multiple required placeholder="Consignment Status" formControlName="consignment_status_cd">
                <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
              </mat-select>
              <mat-error class="mapError"
                *ngIf="!searchForm.get('consignment_status_cd').valid  && !searchForm.get('consignment_status_cd').touched">
                Consignment Status is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [required]=true [placeholder]="'Territory'" [type]="'territory_cd'"
              [selected]="searchForm.value.territory_cd" [valid]="searchForm.get('territory_cd').valid"
              (onSelect)="setterritoryCd($event)" (touched)="searchForm.get('territory_cd').markAsTouched()">
            </app-dropdowns>
            <mat-error class="mapError"
              *ngIf="!searchForm.get('territory_cd').valid && searchForm.get('territory_cd').touched">
              Territory selection is required.
            </mat-error>
          </div>
          <div class="col-sm-3">
            <app-dropdowns [multiple]=true [required]=true [placeholder]="'Branch'" [type]="'branch_cd'"
              [selected]="searchForm.value.branch_cd" [valid]="searchForm.get('branch_cd').valid"
              (onSelect)="setbranchCd($event)" (touched)="searchForm.get('branch_cd').markAsTouched()"></app-dropdowns>
            <mat-error class="mapError"
              *ngIf="!searchForm.get('branch_cd').valid && searchForm.get('branch_cd').touched">
              Territory selection is required.
            </mat-error>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="w-100">
              <input matInput [matDatepicker]="consignment_dt_from" placeholder="From LR Date"
                formControlName="consignment_dt_from">
              <mat-datepicker-toggle matSuffix [for]="consignment_dt_from"></mat-datepicker-toggle>
              <mat-datepicker #consignment_dt_from></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="w-100">
              <input matInput [matDatepicker]="consignment_dt_to" placeholder="To LR Date"
                formControlName="consignment_dt_to">
              <mat-datepicker-toggle matSuffix [for]="consignment_dt_to"></mat-datepicker-toggle>
              <mat-datepicker #consignment_dt_to></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="notingabsurd">
              <input type="text" placeholder="Executive" matInput [matAutocomplete]="autop"
                formControlName="executive_name">
              <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
                <mat-option *ngFor="let option of employeeListAbs" [value]="option.name" (click)="setExecutive(option)">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-sm-3 action-buttons  text-center">
            <span style="margin-right: 10px"><button mat-icon-button color="primary" (click)="submitSearch()">
                <mat-icon>search</mat-icon>
              </button></span>
            <span><button mat-icon-button color="secondary" (click)="resetSearch()">
                <mat-icon>clear</mat-icon>
              </button></span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-3 text-right">
      <span *ngIf="!searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_more</mat-icon>
        </button></span>
      <span *ngIf="searchBarOpen"><button mat-icon-button (click)="toggleSearchBar()">
          <mat-icon>expand_less</mat-icon>
        </button></span>
      <span *ngIf="!bookings_id">
        <button mat-icon-button [routerLink]="['/consignmentNotes/add']">
          <mat-icon>add</mat-icon>
        </button>
      </span>
      <span *ngIf="bookings_id">
        <button mat-icon-button [routerLink]="['/consignmentNotes/add/booking' ,bookings_id]">
          <mat-icon>add</mat-icon>
        </button>
      </span>
      <span><button mat-icon-button color="primary" (click)="download($event)">
          <mat-icon>cloud_download</mat-icon>
        </button></span>
      <span><button mat-icon-button color="primary" (click)="refresh($event)">
          <mat-icon>refresh</mat-icon>
        </button></span>
      <span><button mat-icon-button [matMenuTriggerFor]="menuTop">
          <mat-icon>more_vert</mat-icon>
        </button></span>
      <mat-menu #menuTop="matMenu">
        <button mat-menu-item (click)="openModalAddBillToMultiple(addBillToMultiple)">
          <span>Add bill to selected</span>
        </button>
        <button mat-menu-item (click)="downloadPODStatusReport()">
          <span>POD Status Report</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="table_list">
    <div [ngClass]="{'example-container1': dataSource  ,'example-container2': !dataSource  }">
      <mat-table #table class="table_list" [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection?.hasValue() && isAllSelected()"
              [indeterminate]="selection?.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(element)  : null"
              [checked]="selection?.isSelected(element)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef class="width100"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" class="width100"> {{element.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> <span
              *ngIf="element.bookings?.customers">{{element.bookings.customers.name_short ||
              element.bookings.customers.name}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef> CNote Number </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ul>
              <li>{{element.consignment_no ? element.consignment_no : 'Not Assigned' }}</li>
              <li>{{element.consignment_no && element.consignment_no?.trim() !=='' ? element.eway_bill_no : 'Not eWay'
                }}</li>
            </ul>

          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="source">
          <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.bookings?.source_city}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef> Destination </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.bookings?.destination_city}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{element.consignment_status_cd}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Truck">
          <mat-header-cell *matHeaderCellDef> Truck </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              <span *ngIf="hasTrucks(element)">{{element.consignment_trips[0].trip.trucks[0].registration_no}}</span>
              <span *ngIf="!hasTrucks(element)">Truck/THC not assigned</span>
            </span>
          </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef class="width100">&nbsp; </mat-header-cell>
        <mat-cell class="width100" *matCellDef="let element">
          <button *ngIf="needDispatchButton(element)" mat-raised-button color="primary" [routerLink]="['/consignmentNotes/edit',element.id]">Dispatch</button>
          <button *ngIf="needDeliverButton(element)" mat-raised-button color="primary" (click)="openModal(deliverPopup,element)">Deliver</button>
        </mat-cell>
      </ng-container> -->
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp;</mat-header-cell>
          <mat-cell *matCellDef="let element" class="width60-Center">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item *ngIf="needDispatchButton(element)" [routerLink]="['/consignmentNotes/edit',element.id]">Dispatch</button> -->

              <button mat-menu-item *ngIf="needNextStatusButton(element)"
                (click)="updateToNextStatus(statusPopup,element, getNextStatus(element))">Mark
                {{getNextStatus(element)}}</button>

              <button mat-menu-item *ngIf="needSkipEWayButton(element)"
                (click)="updateSkipEWayStatus(skipEWayPopup,element)">Make eWay Optional</button>
              <button mat-menu-item *ngIf="needCompleteButton(element)"
                (click)="openModalConsignment(updateConsignment, element)">Complete LR Doc
              </button>
              <button mat-menu-item *ngIf="needDeliverButton(element)"
                (click)="openModalPOD(deliverPopup,element, 'ePOD UPLOADED')">Upload
                ePOD</button>
              <button mat-menu-item *ngIf="needReviewPODButton(element)"
                (click)="openModalPOD(deliverPopup3, element, 'POD REVIEWED')">Review POD</button>
              <button mat-menu-item *ngIf="needHardPODButton(element)"
                (click)="openModalPOD(deliverPopup2, element, 'HARD POD RECEIVED')">Mark Hard POD Received</button>


              <button mat-menu-item *ngIf="needBillButton(element)" (click)="openModalBill(billEdit,element)">Mark Bill
                Generated</button>
              <button mat-menu-item *ngIf="needBillSubmissionButton(element)"
                (click)="openModalSubmitted(submittedPopup,element)">Mark
                Bill Submitted</button>
              <button mat-menu-item *ngIf="needPaymentButton(element)"
                (click)="openModalPayment(paymentPopup,element)">Mark
                Payment Received</button>


              <button mat-menu-item [routerLink]="['/consignmentNotes/edit',element.id]">Edit</button>
              <!-- <button mat-menu-item *ngIf="!needNextStatusButton(element)" (click)="updateToNextStatus(statusPopup,element, getNextStatus(element))">Mark
                {{getNextStatus(element)}}</button> -->
              <button mat-menu-item *ngIf="needDownloadAsPDF(element)"
                (click)="downloadConsignmentPreviewAsPDF(element)">Preview As PDF</button>
              <button mat-menu-item *ngIf="needDownloadAsPDF(element)"
                (click)="downloadConsignmentAsPDF(element)">Download As PDF</button>
              <button mat-menu-item *ngIf="needDownloadAsPDF(element)"
                (click)="downloadKNConsignmentAsPDF(element)">Download As KN PDF</button>
              <button mat-menu-item *ngIf="needDownloadAsPDF(element)"
                (click)="openModalSendConsignment(sendConsignment, element)">Send as PDF</button>
              <button mat-menu-item *ngIf="needDownloadAsPDF(element)"
                (click)="downloadConsignmentPreviewAsHtml(element)">Preview As Html</button>
              <button mat-menu-item [routerLink]="['/consignmentStatusTrackings/list/consignment',element.id]">Track /
                History</button>
              <button mat-menu-item *ngIf="needEPODButton(element)"
                (click)="openModalPOD(deliverPopup,element, 'ePOD UPLOADED')">Upload ePOD</button>
              <button mat-menu-item *ngIf="needReviewPODButton(element)"
                (click)="openModalPOD(deliverPopup3, element, 'POD REVIEWED')">Review POD</button>

              <button mat-menu-item *ngIf="needHardPODButton(element)"
                (click)="openModalPOD(deliverPopup2, element, 'HARD POD RECEIVED')">Mark Hard POD Received</button>

            </mat-menu>
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="print">
     <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp; </mat-header-cell>
        <mat-cell class="width60-Center" *matCellDef="let element">
         <button mat-icon-button color="primary" [routerLink]="['/consignmentNotesPrint/view',element.id]">
        <i class="fa fa-print" aria-hidden="true"></i>
       </button>
      </mat-cell>
      </ng-container> -->
        <!-- <ng-container matColumnDef="moreLinks">
    <mat-header-cell *matHeaderCellDef class="width60-Center">&nbsp;</mat-header-cell>
        <mat-cell class="width60-Center" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/consignmentTracking']">Track</button>
        <button mat-menu-item (click)="openModalUpdateLocation(updateLocation,element,1)">Update Location</button>
        <button mat-menu-item>Update Status</button>
        <div class="podAttach"><label>Attach POD</label><input class="podAttach" type="file"></div>
      </mat-menu>
      </mat-cell>
      </ng-container> -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
  <mat-paginator [showFirstLastButtons]="true" [length]="total" [pageSize]="per_page"
    (page)="getResponseByPage($event)">
  </mat-paginator>
</div>
<ng-template #addBillToMultiple>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add bill to multiple consignments</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span *ngIf="selection?.isEmpty()"> No Consignments selected. Please select consignment to add bill</span>

    <div class="row mTop15" *ngFor="let item of selection?.selected">
      <div class="col-sm-2">
        {{item.consignment_no}}
      </div>
      <div class="col-sm-2">
        {{item.consignor_city}} {{item.consignee_city}}
      </div>

    </div>
    <div class="row mBtm10">
      <div class="col-sm-6 text-left">
        <button mat-raised-button color="warn" (click)="modalRef.hide()">Cancel</button>
      </div>
      <div class="col-sm-6 text-right" *ngIf="!selection?.isEmpty()">
        <button mat-raised-button color="primary" (click)="submitAddBillToMultiple()">Submit</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- <ng-template #approveAdvance>
  <app-thc-templates-list requestedAction="Approve Advance"
    [truckHireChallan]="truckHireChallan"
    needEmitCompletion=true isExternalCall=true
    (thc)="setTruckHireChallan($event)"
    (onComplete)="receivedOnComplete($event)">
  </app-thc-templates-list>
</ng-template> -->

<ng-template #updateConsignment>
  <app-consignment-edit #updateConsignmentComponent
  (setConsignmentNotes)="setConsignmentNotes($event)"
  [isExternalCall] = true
  (onComplete)="receivedOnComplete($event)" [consignmentNotes]="cNote">
    <!-- [modalRef]="modalRef" -->
    <!-- [consignment_notes_id]="cNote.id" [consignmentNotes]="cNote"
    [modalRef]="modalRef"> -->
  </app-consignment-edit>
</ng-template>
<ng-template #previewAsHtml>
  <div [innerHTML]="htmlPreview"></div>
</ng-template>
<ng-template #updateLocation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Location</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="consignmentNotesFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <app-map [placeholder]="'Location'" [value]="consignmentNotesFormGroup.value.last_location"
            (onSelect)="selectLocation($event)"></app-map>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="notingabsurd">
            <input type="text" matInput placeholder="Remarks" formControlName="remarks">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <app-dropdowns [type]="'trips_status_cd'" [selected]="consignmentNotesFormGroup.value.trips_status_cd"
            (onSelect)="setTripsStatusCd($event)"></app-dropdowns>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <button mat-raised-button color="primary"
            (click)="submitTripLocation(consignmentNotesFormGroup.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #statusPopup>
  <form [formGroup]="consignmentNotesFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Update LR Progress'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Update Progress LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <div class="col-sm-6">
              <!-- <mat-form-field class="notingabsurd"> -->
              <!-- <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
              <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
            </mat-select> -->
              <!-- <app-dropdowns-req [placeholder]="'Consignment Status'" [required]=true [type]="'consignment_status_cd'"
              [selected]="consignmentNotesFormGroup.value.consignment_status_cd" (onSelect)="setConsignmentStatusCd($event)"></app-dropdowns-req>

            <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup.get('consignment_status_cd').valid  && !consignmentNotesFormGroup.get('consignment_status_cd').touched">
              Consignment Status is required.
            </mat-error> -->
              <mat-form-field class="w-100">
                <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                  <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup.get('consignment_status_cd').valid  && !consignmentNotesFormGroup.get('consignment_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
              <!-- </mat-form-field> -->
            </div>
            <div class="col-sm-6" *ngIf="consignmentNotesFormGroup.get('consignment_status_cd').value == 'CANCELLED'">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                  <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup.get('cancel_reason_cd').valid  && !consignmentNotesFormGroup.get('cancel_reason_cd').touched">
                  Cancel reason is required is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="StatusDate" placeholder="Status Date" formControlName="status_at">
                <mat-datepicker-toggle matSuffix [for]="StatusDate"></mat-datepicker-toggle>
                <mat-datepicker #StatusDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup.get('status_at').valid && !consignmentNotesFormGroup.get('status_at').touched">
                  Delivery Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="time" matInput placeholder="Status Time" formControlName="status_at_time">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup.get('status_at_time').valid && !consignmentNotesFormGroup.get('status_at_time').touched">
                  Status time is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">

            <div class="col-sm-12">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Remarks" formControlName="remarks">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup.get('remarks').valid && !consignmentNotesFormGroup.get('remarks').touched">
                  remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary"
              (click)="submitStatus(consignmentNotesFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #modalHeader let-title="title">
  <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
  <div class="modal-header">
    <div class="row TopB-T inne_area_list_r align-items-center" style="width: -webkit-fill-available;">
      <div class="col-10">
        <h1 style="margin-left: 30px;">{{title}}</h1>
      </div>
      <div class="col-2">
        <div class="ml-auto topbar-more-vert">
          <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #rootMenu="matMenu">
            <button mat-menu-item (click)="closeModalGeneric()">
              <span>Close</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tripSummaryCNote let-cNote="cNoteData" let-thc="thcData">
  <h2>Trip Details</h2>
  <div class="fieldset mb-4">
    <div class="conatiner_flow">
      <div class="row  ">
        <div class="col">
          <div class="labelBox_e3">Customer </div>
          {{cNote.bookings?.customers?.name_short || cNote.bookings?.customers?.name}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> LR# </div>
          <ng-container>
            {{ cNote.consignment_no }}
          </ng-container>

        </div>
        <!-- <div class="col">
              <div class="labelBox_e3">Transit Hours</div> 12 Hours

            </div> -->
        <!-- <div class="col pb-2">
              <div class="labelBox_e3">Company Details</div> TATA SKY PVT LTD
            </div> -->
        <div class="col">
          <div class="labelBox_e3"> Origin</div>
          {{getStringBeforeComma(cNote.consignor_city)}}

        </div>
        <div class="col">
          <div class="labelBox_e3"> Destination</div>
          {{getStringBeforeComma(cNote.consignee_city)}}
        </div>
        <div class="col">
          <div class="labelBox_e3"> ETA</div>
          <ul>
            <li>{{cNote.bookings?.booking_for_dt | date: 'dd MMM yy'}} </li>
            <li>{{cNote.bookings?.booking_for_start | date: 'h:mm a'}} </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deliverPopup>
  <form [formGroup]="consignmentNotesFormGroup2">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Upload ePOD'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">POD Details / Update Progress</h3>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Consignment No." formControlName="consignment_no">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_no').valid && !consignmentNotesFormGroup2.get('consignment_no').touched">
                  Consignment no is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                  <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
              <!-- <div class="status2Radio">
            <label class="lBl">Status</label>
            <mat-radio-group formControlName="consignment_status_cd">
              <mat-radio-button value="DELIVERED">DELIVERED</mat-radio-button>
              <mat-radio-button value="PARTIALLY DELIVERED">PARTIALLY DELIVERED</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
              Consignment Status is required.
            </mat-error> -->
              <!-- </div> -->
            </div>
            <div class="col-sm-3" *ngIf="consignmentNotesFormGroup2.get('consignment_status_cd').value == 'CANCELLED'">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                  <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('cancel_reason_cd').valid  && !consignmentNotesFormGroup2.get('cancel_reason_cd').touched">
                  Cancel reason is required is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="DeliveryDate" placeholder="Delivery Date"
                  formControlName="delivery_date">
                <mat-datepicker-toggle matSuffix [for]="DeliveryDate"></mat-datepicker-toggle>
                <mat-datepicker #DeliveryDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_date').valid && !consignmentNotesFormGroup2.get('delivery_date').touched">
                  Delivery Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="time" matInput placeholder="Delivery Time" formControlName="delivery_time">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_time').valid && !consignmentNotesFormGroup2.get('delivery_time').touched">
                  Delivery time is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Attach POD</label>

              <label *ngIf="cNote.pod_scan"><a href="{{imageUrl}}{{cNote.pod_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange2($event)">
              </label>
              <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('pod_scan').valid ">
                POD attachment is required.
              </mat-error>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Front Page POD</label>

              <label *ngIf="cNote.pod_scan_2"><a href="{{imageUrl}}{{cNote.pod_scan_2}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange3($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('pod_scan_2').valid  && consignmentNotesFormGroup2.get('pod_scan_2').touched">
                POD front is required.
              </mat-error>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-3">
              <label class="fileTitle"> Gate In</label>

              <label *ngIf="cNote.gate_in_scan"><a href="{{imageUrl}}{{cNote.gate_in_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange4($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('gate_in_scan').valid  && consignmentNotesFormGroup2.get('gate_in_scan').touched">
                Gate In is required.
              </mat-error>
            </div>
            <div class="col-sm-9">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="POD Remarks" formControlName="pod_remarks">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('pod_remarks').valid && !consignmentNotesFormGroup2.get('pod_remarks').touched">
                  POD remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-sm-6">
              <label class="fileTitle">AttachPOD</label>
              <input class="chooseFile" type="file" name = "file[]" (change)="fileChange2($event)" placeholder="Upload file">
            </div> -->

          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Loading Charges" formControlName="loading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Unloading Charges" formControlName="unloading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Days" formControlName="detention_days">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Charges" formControlName="detention_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Damage Deduction" formControlName="damage_deduction">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Goods Received By" formControlName="recieved_by">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('recieved_by').valid  && !consignmentNotesFormGroup2.get('recieved_by').touched">
                  Received By is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Delivery Condition" formControlName="delivery_condition_cd">
                  <mat-option *ngFor="let item of getDeliveryConditions()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_condition_cd').valid  && !consignmentNotesFormGroup2.get('delivery_condition_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary"
              (click)="submitDeliver(consignmentNotesFormGroup2.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #deliverPopup2>
  <form [formGroup]="consignmentNotesFormGroup2">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Mark Physical POD Received'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">POD Details / Update Progress</h3>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Consignment No." formControlName="consignment_no">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_no').valid && !consignmentNotesFormGroup2.get('consignment_no').touched">
                  Consignment no is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                  <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
              <!-- <div class="status2Radio">
            <label class="lBl">Status</label>
            <mat-radio-group formControlName="consignment_status_cd">
              <mat-radio-button value="DELIVERED">DELIVERED</mat-radio-button>
              <mat-radio-button value="PARTIALLY DELIVERED">PARTIALLY DELIVERED</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
              Consignment Status is required.
            </mat-error> -->
              <!-- </div> -->
            </div>
            <div class="col-sm-3" *ngIf="consignmentNotesFormGroup2.get('consignment_status_cd').value == 'CANCELLED'">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                  <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('cancel_reason_cd').valid  && !consignmentNotesFormGroup2.get('cancel_reason_cd').touched">
                  Cancel reason is required is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="DeliveryDate" placeholder="Delivery Date"
                  formControlName="delivery_date">
                <mat-datepicker-toggle matSuffix [for]="DeliveryDate"></mat-datepicker-toggle>
                <mat-datepicker #DeliveryDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_date').valid && !consignmentNotesFormGroup2.get('delivery_date').touched">
                  Delivery Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="time" matInput placeholder="Delivery Time" formControlName="delivery_time">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_time').valid && !consignmentNotesFormGroup2.get('delivery_time').touched">
                  Delivery time is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Attach POD</label>

              <label *ngIf="cNote.pod_scan"><a href="{{imageUrl}}{{cNote.pod_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange2($event)">
              </label>
              <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('pod_scan').valid ">
                POD attachment is required.
              </mat-error>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Front Page POD</label>

              <label *ngIf="cNote.pod_scan_2"><a href="{{imageUrl}}{{cNote.pod_scan_2}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange3($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('pod_scan_2').valid  && consignmentNotesFormGroup2.get('pod_scan_2').touched">
                POD front is required.
              </mat-error>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-3">
              <label class="fileTitle"> Gate In</label>

              <label *ngIf="cNote.gate_in_scan"><a href="{{imageUrl}}{{cNote.gate_in_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange4($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('gate_in_scan').valid  && consignmentNotesFormGroup2.get('gate_in_scan').touched">
                Gate In is required.
              </mat-error>
            </div>
            <div class="col-sm-9">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="POD Remarks" formControlName="pod_remarks">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('pod_remarks').valid && !consignmentNotesFormGroup2.get('pod_remarks').touched">
                  POD remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-sm-6">
              <label class="fileTitle">AttachPOD</label>
              <input class="chooseFile" type="file" name = "file[]" (change)="fileChange2($event)" placeholder="Upload file">
            </div> -->

          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" required matInput placeholder="Loading Charges" formControlName="loading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" required matInput placeholder="Unloading Charges"
                  formControlName="unloading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Days" formControlName="detention_days">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Charges" formControlName="detention_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Damage Deduction" formControlName="damage_deduction">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Goods Received By" formControlName="recieved_by">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('recieved_by').valid  && !consignmentNotesFormGroup2.get('recieved_by').touched">
                  Received By is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Delivery Condition" formControlName="delivery_condition_cd">
                  <mat-option *ngFor="let item of getDeliveryConditions()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_condition_cd').valid  && !consignmentNotesFormGroup2.get('delivery_condition_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary"
              (click)="submitDeliver(consignmentNotesFormGroup2.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #deliverPopup3>
  <form [formGroup]="consignmentNotesFormGroup2">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Review POD'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">POD Details / Update Progress</h3>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Consignment No." formControlName="consignment_no">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_no').valid && !consignmentNotesFormGroup2.get('consignment_no').touched">
                  Consignment no is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Consignment Status" formControlName="consignment_status_cd">
                  <mat-option *ngFor="let item of getStatuses()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
              <!-- <div class="status2Radio">
            <label class="lBl">Status</label>
            <mat-radio-group formControlName="consignment_status_cd">
              <mat-radio-button value="DELIVERED">DELIVERED</mat-radio-button>
              <mat-radio-button value="PARTIALLY DELIVERED">PARTIALLY DELIVERED</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('consignment_status_cd').valid  && !consignmentNotesFormGroup2.get('consignment_status_cd').touched">
              Consignment Status is required.
            </mat-error> -->
              <!-- </div> -->
            </div>
            <div class="col-sm-3" *ngIf="consignmentNotesFormGroup2.get('consignment_status_cd').value == 'CANCELLED'">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Cancel Reason" formControlName="cancel_reason_cd">
                  <mat-option *ngFor="let item of getCancelReasons()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('cancel_reason_cd').valid  && !consignmentNotesFormGroup2.get('cancel_reason_cd').touched">
                  Cancel reason is required is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="DeliveryDate" placeholder="Delivery Date"
                  formControlName="delivery_date">
                <mat-datepicker-toggle matSuffix [for]="DeliveryDate"></mat-datepicker-toggle>
                <mat-datepicker #DeliveryDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_date').valid && !consignmentNotesFormGroup2.get('delivery_date').touched">
                  Delivery Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="time" matInput placeholder="Delivery Time" formControlName="delivery_time">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_time').valid && !consignmentNotesFormGroup2.get('delivery_time').touched">
                  Delivery time is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Attach POD</label>

              <label *ngIf="cNote.pod_scan"><a href="{{imageUrl}}{{cNote.pod_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange2($event)">
              </label>
              <mat-error class="mapError" *ngIf="!consignmentNotesFormGroup2.get('pod_scan').valid ">
                POD attachment is required.
              </mat-error>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle">Front Page POD</label>

              <label *ngIf="cNote.pod_scan_2"><a href="{{imageUrl}}{{cNote.pod_scan_2}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange3($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('pod_scan_2').valid  && consignmentNotesFormGroup2.get('pod_scan_2').touched">
                POD front is required.
              </mat-error>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-3">
              <label class="fileTitle"> Gate In</label>

              <label *ngIf="cNote.gate_in_scan"><a href="{{imageUrl}}{{cNote.gate_in_scan}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]" (change)="fileChange4($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!consignmentNotesFormGroup2.get('gate_in_scan').valid  && consignmentNotesFormGroup2.get('gate_in_scan').touched">
                Gate In is required.
              </mat-error>
            </div>
            <div class="col-sm-9">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="POD Remarks" formControlName="pod_remarks">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('pod_remarks').valid && !consignmentNotesFormGroup2.get('pod_remarks').touched">
                  POD remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-sm-6">
              <label class="fileTitle">AttachPOD</label>
              <input class="chooseFile" type="file" name = "file[]" (change)="fileChange2($event)" placeholder="Upload file">
            </div> -->

          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" required matInput placeholder="Loading Charges" formControlName="loading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" required matInput placeholder="Unloading Charges"
                  formControlName="unloading_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Days" formControlName="detention_days">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention Charges" formControlName="detention_charges">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Damage Deduction" formControlName="damage_deduction">
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Goods Received By" formControlName="recieved_by">
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('recieved_by').valid  && !consignmentNotesFormGroup2.get('recieved_by').touched">
                  Received By is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select placeholder="Delivery Condition" formControlName="delivery_condition_cd">
                  <mat-option *ngFor="let item of getDeliveryConditions()" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!consignmentNotesFormGroup2.get('delivery_condition_cd').valid  && !consignmentNotesFormGroup2.get('delivery_condition_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary" (click)="submitPODReview(consignmentNotesFormGroup2.value)">Mark
              Reviewed</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #billEdit>
  <app-invoices-edit (onCloseCall)="closeModalGeneric()" (onEntitySaveComplete)="doOnEntitySaveComplete()"
    [cNoteBillIn]="cNote">
  </app-invoices-edit>
</ng-template>
<ng-template #billPopup>
  <form [formGroup]="invoicesFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Upload Generated Bill'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Upload Bill for LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <!-- <div class="col-sm-3">
            <mat-form-field class="notingabsurd">
              <input type="text" appUppercase required matInput placeholder="Bill Ref No" formControlName="invoice_ref">
              <mat-error class="mapError" *ngIf="!invoicesFormGroup.get('invoice_ref').valid && !invoicesFormGroup.get('invoice_ref').touched">
                Bill ref is required.
              </mat-error>
            </mat-form-field>
          </div> -->
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input required type="text" matInput appUppercase placeholder="Bill WebXpress No"
                  [matAutocomplete]="autowi" formControlName="webxpress_invoice_code">
                <mat-autocomplete #autowi="matAutocomplete" panelWidth="300px">
                  <mat-option *ngFor="let option of invoiceList" [value]="option.webxpress_invoice_code"
                    (click)="setInvoice(option)">
                    {{ option.webxpress_invoice_code }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('webxpress_invoice_code').valid && !invoicesFormGroup.get('webxpress_invoice_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option>-->
                  <mat-option value="BILL GENERATED">BILL GENERATED</mat-option>
                  <!-- <mat-option value="BILL SUBMITTED" selected>BILL SUBMITTED</mat-option> -->
                  <!-- <mat-option value="PAYMENT RECEIVED">PAYMENT RECEIVED</mat-option> -->
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_status_cd').valid  && !invoicesFormGroup.get('invoice_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input required type="number" matInput placeholder="Total Invoice Amt"
                  formControlName="invoice_total_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_total_amt').valid && !invoicesFormGroup.get('invoice_total_amt').touched">
                  Invoice Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="InvoiceDate" placeholder="Bill Generation Date"
                  formControlName="invoice_dt">
                <mat-datepicker-toggle matSuffix [for]="InvoiceDate"></mat-datepicker-toggle>
                <mat-datepicker #InvoiceDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_dt').valid && !invoicesFormGroup.get('invoice_dt').touched">
                  Bill Date is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <label class="fileTitle"> Bill Copy</label>
              <label *ngIf="invoicesFormGroup.get('invoice_scan').value"><a
                  href="{{imageUrl}}{{invoicesFormGroup.get('invoice_scan').value}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]"
                  (change)="uploadInvoiceScan($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!invoicesFormGroup.get('invoice_scan').valid  && invoicesFormGroup.get('invoice_scan').touched">
                Bill is required.
              </mat-error>
            </div>
            <div class="col-sm-9">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill remarks" formControlName="invoice_remarks">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_remarks').valid && !invoicesFormGroup.get('invoice_remarks').touched">
                  Bill remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 section-seperator">
              <label>Consignment Charges for {{cNote.consignment_no}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="LR Freight Charges" formControlName="freight_amt"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('freight_amt').valid && !invoicesFormGroup.get('freight_amt').touched">
                  Loading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Loading charges" formControlName="loading_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('loading_charges').valid && !invoicesFormGroup.get('loading_charges').touched">
                  Loading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Un Loading charges" formControlName="unloading_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('unloading_charges').valid && !invoicesFormGroup.get('unloading_charges').touched">
                  Unloading charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Detention days" formControlName="detention_days">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('detention_days').valid && !invoicesFormGroup.get('detention_days').touched">
                  No of days of detention is required.
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Detention charges" formControlName="detention_charges"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('detention_charges').valid && !invoicesFormGroup.get('detention_charges').touched">
                  Detention charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Other charges" formControlName="misc_charges_amt"
                  (change)="getInvoiceLineTotal($event)">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('misc_charges_amt').valid && !invoicesFormGroup.get('misc_charges_amt').touched">
                  Other charges is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input required type="number" matInput placeholder="Total LR Amt" formControlName="invoice_line_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_line_amt').valid && !invoicesFormGroup.get('invoice_line_amt').touched">
                  Consignment Invoice Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary" (click)="submitBill(invoicesFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #submittedPopup>
  <form [formGroup]="invoicesFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Submit Bill'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Bill submitted for LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill Ref No" formControlName="invoice_ref">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_ref').valid && !invoicesFormGroup.get('invoice_ref').touched">
                  Bill ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option>-->
                  <!-- <mat-option value="BILL GENERATED">BILL GENERATED</mat-option> -->
                  <mat-option value="BILL SUBMITTED" selected>BILL SUBMITTED</mat-option>
                  <!-- <mat-option value="PAYMENT RECEIVED">PAYMENT RECEIVED</mat-option> -->
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_status_cd').valid  && !invoicesFormGroup.get('invoice_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="WebXpress Code" formControlName="webxpress_invoice_code">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('webxpress_invoice_code').valid && !invoicesFormGroup.get('webxpress_invoice_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Invoice Amt" formControlName="invoice_total_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_total_amt').valid && !invoicesFormGroup.get('invoice_total_amt').touched">
                  Invoice Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="SubmittedDate" placeholder="Submitted Date"
                  formControlName="submitted_dt">
                <mat-datepicker-toggle matSuffix [for]="SubmittedDate"></mat-datepicker-toggle>
                <mat-datepicker #SubmittedDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('submitted_dt').valid && !invoicesFormGroup.get('submitted_dt').touched">
                  Submitted Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="PaymentDueDate" placeholder="Payment Due Date"
                  formControlName="payment_due_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDueDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDueDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_due_dt').valid && !invoicesFormGroup.get('payment_due_dt').touched">
                  Payment Due Date is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <label class="fileTitle"> Submission Proof</label>
              <label *ngIf="invoicesFormGroup.get('submission_scan').value"><a
                  href="{{imageUrl}}{{invoicesFormGroup.get('submission_scan').value}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]"
                  (change)="uploadSubmissionScan($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!invoicesFormGroup.get('submission_scan').valid  && invoicesFormGroup.get('submission_scan').touched">
                Submission proof is required.
              </mat-error>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill remarks" formControlName="invoice_remarks">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_remarks').valid && !invoicesFormGroup.get('invoice_remarks').touched">
                  Bill remarks is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary"
              (click)="submitBillSubmission(invoicesFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>
<ng-template #paymentPopup>
  <form [formGroup]="invoicesFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Payment Information'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Payment information for LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill Ref No" formControlName="invoice_ref">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_ref').valid && !invoicesFormGroup.get('invoice_ref').touched">
                  Bill ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option> -->
                  <!-- <mat-option value="BILL GENERATED">BILL GENERATED</mat-option> -->
                  <!-- <mat-option value="BILL SUBMITTED">BILL SUBMITTED</mat-option> -->
                  <mat-option value="PAYMENT RECEIVED" selected>PAYMENT RECEIVED</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_status_cd').valid  && !invoicesFormGroup.get('invoice_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="WebXpress Code" formControlName="webxpress_payment_code">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('webxpress_payment_code').valid && !invoicesFormGroup.get('webxpress_payment_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle"> Payment Scan</label>
              <label *ngIf="invoicesFormGroup.get('payment_scan').value"><a
                  href="{{imageUrl}}{{invoicesFormGroup.get('payment_scan').value}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]"
                  (change)="uploadPaymentScan($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!invoicesFormGroup.get('payment_scan').valid  && invoicesFormGroup.get('payment_scan').touched">
                Payment scan is required.
              </mat-error>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Ref" formControlName="payment_ref">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_ref').valid && !invoicesFormGroup.get('payment_ref').touched">
                  Payment Reference is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="PaymentDate" placeholder="Payment Date" formControlName="payment_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_dt').valid && !invoicesFormGroup.get('payment_dt').touched">
                  Submitted Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Payment Amt" formControlName="payment_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_amt').valid && !invoicesFormGroup.get('payment_amt').touched">
                  Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Consignment Payment Amt"
                  formControlName="invoice_line_payment_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_line_payment_amt').valid && !invoicesFormGroup.get('invoice_line_payment_amt').touched">
                  Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Remarks" formControlName="payment_remarks">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_remarks').valid && !invoicesFormGroup.get('payment_remarks').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Difference reason" formControlName="diff_reason">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('diff_reason').valid && !invoicesFormGroup.get('diff_reason').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary" (click)="submitPayment(invoicesFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #sendConsignment>
  <form [formGroup]="sendConsignmentFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'Send LR for Print'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">Send LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Name" formControlName="to_name">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_name').valid && !sendConsignmentFormGroup.get('to_name').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Email" formControlName="to_email">
                <mat-error class="mapError"
                  *ngIf="!sendConsignmentFormGroup.get('to_email').valid && !sendConsignmentFormGroup.get('to_email').touched">
                  Email to is required.
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="row buttonset">
          <div class="col-sm-12 text-right">
            <button mat-raised-button color="primary"
              (click)="submitSendLr(sendConsignmentFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>
<ng-template #mrPopup>
  <form [formGroup]="invoicesFormGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <ng-container *ngTemplateOutlet="modalHeader;context:{title:'MR Information'}"></ng-container>
    <div class="modal-body ">
      <div class="details_list_item">
        <ng-container *ngTemplateOutlet="tripSummaryCNote;context:{cNoteData:cNote}"></ng-container>
        <div class="fieldset mb-4">
          <h3 class="gwheadline">MR Information for LR {{cNote.consignment_no}}</h3>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Bill Ref No" formControlName="invoice_ref">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_ref').valid && !invoicesFormGroup.get('invoice_ref').touched">
                  Bill ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <mat-select required placeholder="Invoice Status" formControlName="invoice_status_cd">
                  <!-- <mat-option value="DRAFT">DRAFT</mat-option> -->
                  <!-- <mat-option value="BILL GENERATED">BILL GENERATED</mat-option> -->
                  <!-- <mat-option value="BILL SUBMITTED">BILL SUBMITTED</mat-option> -->
                  <mat-option value="MR COMPLETE" selected>MR COMPLETE</mat-option>
                </mat-select>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_status_cd').valid  && !invoicesFormGroup.get('invoice_status_cd').touched">
                  Consignment Status is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input required type="text" matInput placeholder="WebXpress Code"
                  formControlName="webxpress_payment_code">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('webxpress_payment_code').valid && !invoicesFormGroup.get('webxpress_payment_code').touched">
                  WebXpress ref is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <label class="fileTitle"> Payment Scan</label>
              <label *ngIf="invoicesFormGroup.get('payment_scan').value"><a
                  href="{{imageUrl}}{{invoicesFormGroup.get('payment_scan').value}}" target="_blank"><i
                    class="material-icons">attachment</i></a></label>
              <label class="btn btn-primary">
                Browse..... <input type="file" style="display: none;" name="file[]"
                  (change)="uploadPaymentScan($event)">
              </label>
              <mat-error class="mapError"
                *ngIf="!invoicesFormGroup.get('payment_scan').valid  && invoicesFormGroup.get('payment_scan').touched">
                Payment scan is required.
              </mat-error>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Ref" formControlName="payment_ref">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_ref').valid && !invoicesFormGroup.get('payment_ref').touched">
                  Payment Reference is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input matInput [matDatepicker]="PaymentDate" placeholder="Payment Date" formControlName="payment_dt">
                <mat-datepicker-toggle matSuffix [for]="PaymentDate"></mat-datepicker-toggle>
                <mat-datepicker #PaymentDate></mat-datepicker>
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_dt').valid && !invoicesFormGroup.get('payment_dt').touched">
                  Submitted Date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Total Payment Amt" formControlName="payment_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_amt').valid && !invoicesFormGroup.get('payment_amt').touched">
                  Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="notingabsurd">
                <input type="number" matInput placeholder="Consignment Payment Amt"
                  formControlName="invoice_line_payment_amt">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('invoice_line_payment_amt').valid && !invoicesFormGroup.get('invoice_line_payment_amt').touched">
                  Payment Amount is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Payment Remarks" formControlName="payment_remarks">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('payment_remarks').valid && !invoicesFormGroup.get('payment_remarks').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="notingabsurd">
                <input type="text" matInput placeholder="Difference reason" formControlName="diff_reason">
                <mat-error class="mapError"
                  *ngIf="!invoicesFormGroup.get('diff_reason').valid && !invoicesFormGroup.get('diff_reason').touched">
                  Difference reason is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <button mat-raised-button color="primary"
              (click)="submitMrComplete(invoicesFormGroup.value)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #skipEWayPopup>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Skip eWay for {{cNote.consignment_no}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="consignmentNotesSkipWayForm">
      <div class="row">
        <div class="col-sm-6">
          <mat-slide-toggle formControlName="is_skip_eway">Skip eWay</mat-slide-toggle>
          <mat-error
            *ngIf="!consignmentNotesSkipWayForm.get('is_skip_eway').valid && consignmentNotesSkipWayForm.get('is_skip_eway').touched">
            Please toggle skip eway flag.
          </mat-error>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="notingabsurd">
            <input matInput required placeholder="Remarks" formControlName="remarks">
            <mat-error class="mapError"
              *ngIf="!consignmentNotesSkipWayForm.get('remarks').valid && !consignmentNotesSkipWayForm.get('remarks').touched">
              Remarks is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <button mat-raised-button color="primary"
            (click)="submitSkipeWay(consignmentNotesSkipWayForm.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
